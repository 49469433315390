import moment from 'moment'
import {FC} from 'react'
import {KTSVG} from '../../../_jmh/helpers'
import {useIntl} from 'react-intl'

const EventDetails: FC<any> = ({event, onClose}) => {
  const intl = useIntl()
  return (
    <>
      <div
        className='modal fade show d-block '
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3>{event.eventTitle}</h3>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={onClose}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y '>
              <p>
                <strong>{intl.formatMessage({id: 'GENERAL.PATIENT.NAME'})}:</strong>
                {event.patientName}
              </p>
              <p>
                <strong>{intl.formatMessage({id: 'GENERAL.DOCTOR.NAME'})}:</strong>
                {event.doctorName}
              </p>
              <p>
                <strong>{intl.formatMessage({id: 'OPERATION_THEATRE.START.DATE.TIME'})}: </strong>
                {moment(event.start?.toString()).format('DD-MM-YYYY & HH:mm')}
              </p>
              <p>
                <strong>{intl.formatMessage({id: 'OPERATION_THEATRE.END.DATE.TIME'})}: </strong>
                {moment(event.end?.toString()).format('DD-MM-YYYY & HH:mm')}
              </p>
              {event.eventTitle === 'Patient Operation Details' ? (
                <p>
                  <strong>{intl.formatMessage({id: 'GENERAL.ROOM.NUMBER'})}: </strong> {event.venue}
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default EventDetails
