/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {PatientDoc} from '../../core/_models'
import {toast} from 'react-toastify'

type Props = {
  user: PatientDoc
}

const PatientDocInfoCell: FC<Props> = ({user}) => {
  const url: any = user.path
  const extension = url?.split('.').pop()?.toLowerCase()
  const handleDownload = async (e: any) => {
    e.preventDefault()

    if (url) {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        const downloadUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')

        let fileName = url
          ? url
              .substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'))
              .split('_')
              .pop()
          : ''

        if (extension === 'jpg' || extension === 'jpeg') {
          fileName += '.jpg'
        } else if (extension === 'pdf') {
          fileName += '.pdf'
        } else {
          toast.error('Unsupported file format')
          return
        }

        link.href = downloadUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error('Failed to download file:', error)
      }
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {user && extension !== undefined && (
          <>
            <a href={user?.path} target='_blank' className='text-gray-800 mb-1'>
              {extension === 'pdf' ? (
                <>
                  <a
                    href='#'
                    onClick={handleDownload}
                    className='image-input-wrapper attachment_pdf w-70px h-70px d-flex align-items-center justify-content-center'
                  >
                    <KTSVG
                      path='/media/svg/files/pdf.svg'
                      className='svg-icon-primary svg-icon-3hx'
                    />
                  </a>
                  {/* <div className='image-input-wrapper attachment_pdf w-70px h-70px d-flex align-items-center justify-content-center'>
                    <KTSVG path='/media/svg/files/pdf.svg' className='svg-pdf svg-icon-primary' />
                  </div> */}
                </>
              ) : (
                <div className='image-input-wrapper w-70px h-70px'>
                  <img src={user?.path} className='w-100 h-100 object-fit-cover' />
                </div>
              )}
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export {PatientDocInfoCell}
