import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {SalarysListWrapper} from './salarys-list/SalarysList'

const SalaryPage = () => (
  <>
    <div className='row g-5 g-xl-8'>
      <SalarysListWrapper />
    </div>
  </>
)

const SalaryWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'STAFF.SALARY.STRUCTURE'})}</PageTitle>
      <SalaryPage />
    </>
  )
}

export {SalaryWrapper}
