import axios, {AxiosError, AxiosResponse} from 'axios'
import {PatientsQueryResponse} from '../../../../../app/pages/Patient-management/PatientList/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_NOTIFICATION_URL = `${API_URL}/notifications`
const NEW_NOTIFICATION_URL = `${API_URL}/notifications/change/is-new/flag`
const GET_PATIENT_NAME = `${API_URL}/patient`

const getNotificationData = (appQuery: string, to: string): Promise<any> => {
  return axios
    .get(`${GET_NOTIFICATION_URL}?${appQuery}to=${to}`)
    .then((response: AxiosResponse<any>) => response)
    .catch((error: AxiosError<any>) => {
      if (error.response && error.response.status === 401) {
        return error.response
      }
      throw error
    })
}

const updatedNewNotification = (notificationIds: any) => {
  return axios.post(NEW_NOTIFICATION_URL, {ids: notificationIds}).then((response) => response.data)
}

const deleteNotification = (notificationIds: any): Promise<void> => {
  const requests = notificationIds.map((ids: any) => axios.delete(`${GET_NOTIFICATION_URL}/${ids}`))
  return axios.all(requests).then(() => {})
}

const getPatientName = (): Promise<PatientsQueryResponse> => {
  return axios.get(`${GET_PATIENT_NAME}`).then((d: AxiosResponse<PatientsQueryResponse>) => d.data)
}

export {getNotificationData, deleteNotification, updatedNewNotification, getPatientName}
