import {AssignInvListToolbar} from './AssignInvListToolbar'
import {AssignInvListSearchComponent} from './AssignInvListSearchComponent'

const AssignInvListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <AssignInvListSearchComponent />
      <div className='card-toolbar'>
        <AssignInvListToolbar />
      </div>
    </div>
  )
}

export {AssignInvListHeader}
