import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialUser, ATD, TransferData} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {AtdsListLoading} from '../components/loading/AtdsListLoading'
import {getAtdTransferPatienById, getDoctors, getHospitalRoom} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {loginContext} from '../../../../context/context'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'

type TransferModalProps = {
  show: boolean
  onClose: () => void
  id: any
  isUserLoading: boolean
  user: ATD
  transfer: TransferData
}

function mapDataToNodesDoctor(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({value: item?.full_name, label: item?.full_name, id: item?.id})
  })

  return parent
}

function mapDataToNodesRoom(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({value: item?.room_number, label: item?.room_number, id: item?.id})
  })

  return parent
}

const TransferModal: FC<TransferModalProps> = ({
  show,
  onClose,
  isUserLoading,
  id,
  user,
  transfer,
}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [doctorData, setDoctorData] = useState([])
  const [roomData, setRoomData] = useState([])
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null)
  const [selectedRoom, setSelectedRoom] = useState<any>(null)
  const [selDateTime, setSelDateTime] = useState<Date | null>(null)
  const navigate = useNavigate()
  const {selectedHospitalId} = useContext(loginContext)

  const [userForEdit] = useState<TransferData>({
    ...transfer,
    doctor_id: transfer?.doctor_id || initialUser?.doctor_id,
    hospital_room_id: transfer?.hospital_room_id || initialUser?.hospital_room_id,
    transfer_reason: transfer?.transfer_reason || initialUser?.reason,
    transfer_date: transfer?.transfer_date,
  })

  const cancel = (withRefresh?: boolean) => {
    formik.resetForm()
    setSelectedDoctor(null)
    setSelectedRoom(null)
    setSelDateTime(null)

    onClose()

    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: Yup.object().shape({
      doctor_id: Yup.string().required(intl.formatMessage({id: 'VALIDATION.ATD.SELECT_DOCTOR'})),
      hospital_room_id: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.ATD.SELECT_ROOM'})
      ),
      transfer_date: Yup.date().required(
        intl.formatMessage({id: 'VALIDATION.ATD.TRANSFER_DATE_TIME'})
      ),
    }),
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      const TransferDateTime = moment(values['transfer_date'], 'DD-MM-YYYY H:mm').format(
        'DD-MM-YYYY H:mm'
      )
      setSubmitting(true)
      try {
        values['transfer_date'] = TransferDateTime
        await getAtdTransferPatienById(values, id).then((res: any) => {
          toast.success(res?.data?.message)
          resetForm()
          onClose()
          navigate('/patient-data/ad-patient')
        })
      } catch (ex: any) {
        toast.warning(ex.response?.data?.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const fetchDoctor = async () => {
    const allDoctorData: any = await getDoctors()

    const activeDoctorData = allDoctorData?.data?.filter((item: any) => {
      return item?.status === 'ACTIVE' && item?.user?.hospital_id == selectedHospitalId
    })

    setDoctorData(activeDoctorData)
  }

  const fetchAllRoom = async () => {
    const allRoomData: any = await getHospitalRoom()

    const activeRoomData = allRoomData?.data?.filter((item: any) => {
      return (
        item?.availability_status === 'AVAILABLE' && item?.hospital_id?.id == selectedHospitalId
      )
    })

    setRoomData(activeRoomData)
  }

  useEffect(() => {
    fetchDoctor()
  }, [selectedHospitalId])

  useEffect(() => {
    fetchAllRoom()
  }, [selectedHospitalId])

  const optionsDoctor: any = mapDataToNodesDoctor(doctorData)
  const optionsRoom: any = mapDataToNodesRoom(roomData)

  const handleDoctorChange = (selectedDoctor: any) => {
    formik.setFieldValue('doctor_id', Number(selectedDoctor.id))
    setSelectedDoctor(selectedDoctor?.id)
  }

  const handleRoomChange = (selectedRoom: any) => {
    formik.setFieldValue('hospital_room_id', Number(selectedRoom.id))
    setSelectedRoom(selectedRoom?.id)
  }

  const maxDate = new Date(moment().format('YYYY-MM-DD'))

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  return (
    <>
      <div
        className={`modal fade ${show ? 'show' : ''}`}
        style={{display: show ? 'block' : 'none'}}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {intl.formatMessage({id: 'PATIENT_ATD.TRANSFER.PATIENT'})}
              </h5>
              <button type='button' className='btn-close' onClick={() => cancel()}></button>
            </div>
            <div className='modal-body text-start'>
              <>
                <form
                  id='kt_modal_add_user_form'
                  className='form'
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <div className='d-flex flex-column me-n7 pe-7'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'PATIENT_APPOINTMENT.DOCTORS'})}
                      </label>
                      <Select
                        value={
                          selectedDoctor
                            ? optionsDoctor.find((option: any) => option.id === selectedDoctor)
                            : null
                        }
                        onChange={handleDoctorChange}
                        options={optionsDoctor}
                        styles={customStyles}
                        placeholder={intl.formatMessage({id: 'DOCTOR.APPOINTMENT.SELECT.DOCTOR'})}
                      />
                      {formik.touched.doctor_id && formik.errors.doctor_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.doctor_id}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.ROOM.NUMBER'})}
                      </label>
                      <Select
                        value={
                          selectedRoom
                            ? optionsRoom.find((option: any) => option.id === selectedRoom)
                            : null
                        }
                        onChange={handleRoomChange}
                        options={optionsRoom}
                        styles={customStyles}
                        placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.ROOM'})}
                      />
                      {formik.touched.hospital_room_id && formik.errors.hospital_room_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.hospital_room_id}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-7'>
                      <label className={`required fw-bold fs-6 mb-2`}>
                        {intl.formatMessage({id: 'GENERAL.TRANSFER.DATE.TIME'})}
                      </label>
                      <div className='position-relative'>
                        <DatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          {...formik.getFieldProps('transfer_date')}
                          dropdownMode='select'
                          className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                          dateFormat='dd-MM-yyyy H:mm'
                          name='transfer_date'
                          timeInputLabel='Time:'
                          selected={selDateTime}
                          onChange={(date) => {
                            formik.setFieldValue('transfer_date', date)
                            setSelDateTime(date)
                          }}
                          maxDate={maxDate}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isUserLoading}
                          showTimeInput
                          shouldCloseOnSelect={false}
                          placeholderText={intl.formatMessage({id: 'GENERAL.TRANSFER.DATE.TIME'})}
                          onKeyDown={(e) => {
                            e.preventDefault()
                          }}
                        />
                        <span
                          className='DatePicker_icon cursor-pointer'
                          onClick={() => document.getElementsByName('transfer_date')[0].focus()}
                        >
                          <i className='fa fa-calendar'></i>
                        </span>
                      </div>
                      {formik.touched.transfer_date && formik.errors.transfer_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.transfer_date}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'PATIENT_ATD.REASON.FOR.TRANSFER'})}
                      </label>

                      <textarea
                        {...formik.getFieldProps('transfer_reason')}
                        className='form-control'
                        name='transfer_reason'
                        id='exampleFormControlTextarea1'
                        disabled={formik.isSubmitting || isUserLoading}
                        rows={3}
                        placeholder={intl.formatMessage({id: 'PATIENT_ATD.REASON.FOR.TRANSFER'})}
                      ></textarea>

                      {formik.touched.transfer_reason && formik.errors.transfer_reason && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.transfer_reason}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => cancel()}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                      disabled={formik.isSubmitting || isUserLoading}
                    >
                      {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                      disabled={
                        isUserLoading ||
                        formik.isSubmitting ||
                        !formik.isValid ||
                        !formik.touched ||
                        !formik.dirty
                      }
                    >
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'GENERAL.SAVE'})}
                      </span>
                      {(formik.isSubmitting || isUserLoading) && (
                        <span className='indicator-progress'>
                          {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
                {(formik.isSubmitting || isUserLoading) && <AtdsListLoading />}
              </>
            </div>
          </div>
        </div>
      </div>
      {show && <div className='modal-backdrop fade show'></div>}
    </>
  )
}

export default TransferModal
