import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {HospitalRoom, HospitalRoomQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const HOSPITAL_ROOM_URL = `${API_URL}/hospital-room`
const GET_HOSPITAL_ROOM_URL = `${API_URL}/hospital-room/`

export const getRoomStatus = (): Promise<HospitalRoomQueryResponse> => {
  return axios
    .get(`${HOSPITAL_ROOM_URL}`)
    .then((d: AxiosResponse<HospitalRoomQueryResponse>) => d.data)
}

const getHospitalRoom = (query: string,id:number): Promise<HospitalRoomQueryResponse> => {
  return axios
    .get(`${GET_HOSPITAL_ROOM_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)

    .then((d: AxiosResponse<HospitalRoomQueryResponse>) => d.data)
}


const getHospitalRoomById = (id: ID): Promise<HospitalRoom | undefined> => {
  return axios
    .get(`${HOSPITAL_ROOM_URL}/${id}`)
    .then((response: AxiosResponse<Response<HospitalRoom>>) => response.data)
    .then((response: Response<HospitalRoom>) => response.data)
}

const createHospitalRoom = (user: HospitalRoom): Promise<HospitalRoom | undefined> => {
  return axios
    .post(HOSPITAL_ROOM_URL, user)
}

const updateHospitalRoom = (user: HospitalRoom): Promise<HospitalRoom | undefined> => {
 
  return axios
    .patch(`${HOSPITAL_ROOM_URL}/${user.id}`, user)
}

export {getHospitalRoom, getHospitalRoomById, createHospitalRoom, updateHospitalRoom}
