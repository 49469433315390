import axios, {AxiosResponse} from 'axios'
import { AssetQueryResponse } from '../../../../app/pages/asset-maintenance/assetMaintenance-List/core/_models'
import { InventoryQueryResponse } from '../../../../app/pages/Inventory-management/InventoryManagementList/core/_models'
import { UsersQueryResponse } from '../../../../app/pages/Patient-appointment/appointments-list/core/_models'
import { DoctorsQueryResponse } from '../../../../app/pages/Doctor/DoctorList/core/_models'
// import { DoctorAvalabilityQueryResponse } from './model'
const API_URL = process.env.REACT_APP_API_URL
const GET_ASSETMAINTENANCE_URL = `${API_URL}/asset-maintenance`
const GET_INVENTORY_URL = `${API_URL}/inventory`
const APPOINTMENT_URL = `${API_URL}/patient-appointment`
const CREATE_DOCTOR_URL = `${API_URL}/doctor-availability`
const GET_TOTAL_DATA = `${API_URL}/reports`


const getAssetMaintedData = (query: string,id:number): Promise<AssetQueryResponse> => {
  return axios
  .get(`${GET_ASSETMAINTENANCE_URL}?${query ? `${query}&hospital_id=${id}` : `hospital_id=${id}`}`)
    .then((response: AxiosResponse<AssetQueryResponse>) => response.data);
};

// const getAssetFilterData = (start_date?:any,end_date?:any) => {
//   return axios
//     .get(`${GET_ASSETMAINTENANCE_URL}?${`start_date=${start_date}&end_date=${end_date}`}`)
//     .then((d: any) => d.data)
//     .then((response: any) => response)
// }

  const getInventoryData = (query: string,id:number): Promise<InventoryQueryResponse> => {
    return axios
      .get(`${GET_INVENTORY_URL}?${query ? `${query}&hospital_id=${id}` : `hospital_id=${id}`}`)
      .then((d: AxiosResponse<InventoryQueryResponse>) => d.data)
  }

  const getAppointmentData = (query: string,id:number): Promise<UsersQueryResponse> => {
    return axios
    .get(`${APPOINTMENT_URL}?${query ? `${query}&hospital_id=${id}` : `hospital_id=${id}`}`)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  const getDoctorAvailabilityData = (query: string,hos_id:number,dateData?:any): Promise<DoctorsQueryResponse> => {
    return axios
      .get(`${CREATE_DOCTOR_URL}?${`hospital_id=${hos_id}`}&date=${dateData}`)
      .then((d: AxiosResponse<DoctorsQueryResponse>) => d.data)
      .then((d: any) => d.data)
  }

  const getTotalData = (id:number): Promise<any> => {
    return axios
      .get(`${GET_TOTAL_DATA}?hospital_id=${id}`)
      .then((d: AxiosResponse<any>) => d.data)
  }
 

export {getAssetMaintedData,getTotalData,getInventoryData,getAppointmentData, getDoctorAvailabilityData}