import {FC} from 'react'
import {AssetMaintenanceModel} from '../../core/_models'

type Props = {
  asset: AssetMaintenanceModel
}

const AssetMaintenanceType: FC<Props> = ({asset}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <div className='text-gray-800  mb-1'>{asset?.type}</div>
      </div>
    </div>
  )
}
export {AssetMaintenanceType}
