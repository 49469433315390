import {useEffect, useState} from 'react'
import SubmitConsultation from './SubmitConsultation'

const PrescriptionPreview = ({handlePreviewClose, isCheckboxChecked}: any) => {
  const [prescriptionData, setPrescriptionData] = useState<any>([{}])

  useEffect(() => {
    const getData = JSON.parse(sessionStorage.getItem('prescriptionData') || '{}')
    setPrescriptionData(getData)
  }, [])

  return (
    <div className='presc_preview_wrapper shadow-lg'>
      <div className='presc_preview_head'>
        <h2 className='m-0'>Prescription Preview</h2>
      </div>
      <div className='presc_preview_body'>
        {prescriptionData?.chief_complaints?.length > 0 && (
          <div className='presc_item_chief'>
            <h4>Chief Complaints</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData?.chief_complaints?.map((item: any, index: number) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
        )}
        {(prescriptionData?.examination?.temperature?.temperature_value ||
          prescriptionData?.examination?.pulse ||
          prescriptionData?.examination?.respiratory_rate ||
          prescriptionData?.examination?.blood_pressure ||
          prescriptionData?.examination?.spO2 ||
          prescriptionData?.examination?.height?.height_value ||
          prescriptionData?.examination?.weight?.weight_value) && (
          <div className='presc_item_examination'>
            <h4>Examination</h4>
            <ul className='p-0 d-flex list-unstyled flex-wrap'>
              {prescriptionData?.examination?.temperature?.temperature_unit && (
                <li>
                  <span>Temperature:</span>
                  <span>
                    {prescriptionData?.examination?.temperature?.temperature_value}{' '}
                    {prescriptionData?.examination?.temperature?.temperature_unit}
                  </span>
                </li>
              )}
              {prescriptionData?.examination?.pulse && (
                <li>
                  <span>Pulse:</span>
                  <span>{prescriptionData?.examination?.pulse}</span>
                </li>
              )}
              {prescriptionData?.examination?.respiratory_rate && (
                <li>
                  <span>Respiratory Rate:</span>
                  <span>{prescriptionData?.examination?.respiratory_rate}</span>
                </li>
              )}
              {prescriptionData?.examination?.blood_pressure && (
                <li>
                  <span>Blood Pressure:</span>
                  <span>{prescriptionData?.examination?.blood_pressure}</span>
                </li>
              )}
              {prescriptionData?.examination?.spO2 && (
                <li>
                  <span>SP02:</span>
                  <span>{prescriptionData?.examination?.spO2}</span>
                </li>
              )}
              {prescriptionData?.examination?.height?.height_value && (
                <li>
                  <span>Height:</span>
                  <span>
                    {prescriptionData?.examination?.height?.height_value}
                    {prescriptionData?.examination?.height?.height_unit}
                  </span>
                </li>
              )}
              {prescriptionData?.examination?.weight?.weight_value && (
                <li>
                  <span>Weight:</span>
                  <span>
                    {prescriptionData?.examination?.weight?.weight_value}
                    {prescriptionData?.examination?.weight?.weight_unit}
                  </span>
                </li>
              )}
            </ul>
          </div>
        )}
        {prescriptionData?.examination_notes?.length > 0 && (
          <div className='presc_item_examination_notes'>
            <h4>Examination Notes</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData?.examination_notes?.map((item: any, index: number) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
        )}

        {prescriptionData?.diagnosis?.length > 0 && (
          <div className='presc_item_diagnosis'>
            <h4>Diagnosis</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData?.diagnosis?.map((item: any, index: number) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
        )}

        {prescriptionData?.medication?.length > 0 && (
          <div className='presc_item_medication'>
            <h4>Medication</h4>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Medicine Name</th>
                  <th>Regime and Instruction</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionData?.medication?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.prescribed_medicine?.label}</td>
                      <td>
                        <p className='m-0'>{item?.frequency}</p>
                        <p className='m-0'> For {item?.duration + ' ' + item?.duration_unit}</p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        {prescriptionData?.procedure?.length > 0 && (
          <div className='presc_item_procedure'>
            <h4>Procedure</h4>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Name of Procedure</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionData?.procedure?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.procedure_name}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        {prescriptionData?.investigation?.length > 0 && (
          <div className='presc_item_investigation'>
            <h4>Investigation</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData?.investigation?.map((item: any, index: number) => {
                return (
                  <li className='w-100' key={index}>
                    <b>{item?.test_name}</b> - {item?.instruction}
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        {prescriptionData?.advice?.length > 0 && (
          <div className='presc_item_advice'>
            <h4>Advice</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData?.advice?.map((item: any, index: number) => {
                return (
                  <li className='w-100' key={index}>
                    {item}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
      <div className='presc_preview_footer shadow-lg'>
        <div className='d-flex justify-content-end align-items-center gap-3'>
          <div className='presc_footer_button'>
            <button className='btn btn-outline me-3' onClick={handlePreviewClose}>
              Cancel
            </button>
            <SubmitConsultation isCheckboxChecked={isCheckboxChecked} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrescriptionPreview
