/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ShiftModel} from '../../core/_models'

type Props = {
  shift: ShiftModel
}

const ShiftWorkingHoursCell: FC<Props> = ({shift}) => {
  // Convert the working hours to the desired format
  const formattedWorkingHours = shift?.working_hours?.toFixed(2)

  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <div className='text-gray-800 mb-1'>{formattedWorkingHours}</div>
        </div>
      </div>
    </>
  )
}

export {ShiftWorkingHoursCell}
