// @ts-nocheck
import {Column} from 'react-table'
import {AssignInvInfoCell} from './AssignInvInfoCell'
import {AssignInvActionsCell} from './AssignInvActionsCell'
import {AssignInvCustomHeader} from './AssignInvCustomHeader'
import {AssignInv} from '../../core/_models'
import {AssignedQuantityInfo} from './AssignedQuantityInfo'
import {AssignedRoomInfo} from './AssignedRoomInfo'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<AssignInv>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssignInvCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'inventory_id',
    Cell: ({...props}) => <AssignInvInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssignInvCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ASSIGNED.QUANTITY'})}
          className='min-w-125px'
        />
      )
    },
    id: 'assigned_quantity',
    Cell: ({...props}) => <AssignedQuantityInfo user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssignInvCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ASSIGNED.ROOM.NO'})}
          className='min-w-125px'
        />
      )
    },
    id: 'room_id',
    Cell: ({...props}) => <AssignedRoomInfo user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'assign-inventory') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <AssignInvCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'assign-inventory') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? <AssignInvActionsCell id={props.data[props.row.index].id} /> : null
    },
  },
]

export {usersColumns}
