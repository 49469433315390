/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC} from 'react'
import {PatientVital} from '../../core/_models'

type Props = {
  user: PatientVital
}

const PatientVitalDateTimeInfo: FC<Props> = ({user}) => {
  const createdAt = moment.utc(user?.date_time)

  const date = createdAt.format('DD-MM-YYYY')
  const time = createdAt.format('HH:mm')
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <p className='text-gray-800 mb-1'>
          {date} {time}
        </p>
      </div>
    </div>
  )
}

export {PatientVitalDateTimeInfo}
