/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Medicine} from '../../core/_models'

type Props = {
  medicine: Medicine
}

const MedicineStatusCell: FC<Props> = ({medicine}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{medicine?.instruction}</div>
    </div>
  )
}

export {MedicineStatusCell}
