import { ID, Response } from "../../../../../_jmh/helpers"

export type Payroll = {
  id?: ID
  salary_structure_id?: number;
  gross_salary?: number | null;
  staff_id?: number;
  hospital_id?: number;
  professional_tax?: number | null;
  salary_month?: number | null;
  salary_year?: number;
  working_days?: number;
  employee_working_days?: number;
  unpaid_leaves?: number;
  leave_encashment?: number;
  approval?: string;
  basic_salary?: number;
  house_rent_allowance?: number;
  medical_insurance_allowance?: number;
  conveyance_transportation_allowance?: number;
  meal_allowance?: number;
  entertainment_allowance?: number;
  salary_year_and_month?: number | null
  paid_leaves?: number;
  payable_salary?: number;
  bonus?: number | null ;
  incentive?:number | null;
  EPF_deduction? : number | null
  staff?: StaffData
  payslip?: any
  calender_days?:number
  week_offs?:number
}

interface StaffData {
  id: string;
  full_name: string;
  gender: string;
  image: string;
  date_of_birth: string;
  permanent_address: string;
  contact_number: string;
  job_title: string;
  speciality_id: string;
  user_id: string;
  shift_id: string;
  status: string;
  created_at: string;
  updated_at: string;
}



export type UsersQueryResponse = Response<Array<Payroll>>

export const initialUser: Payroll = {
  gross_salary: null,
  professional_tax :null,
  salary_month :null,
  salary_year_and_month:null
}
