import {useContext, useState} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {getInventoryReport} from '../../core/_requests'
import {InvManagementListLoading} from '../../components/loading/InvManagementListLoading'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

const InvManagementListToolbar = () => {
  const intl = useIntl()
  const tabledata = useQueryResponseData()
  const {selectedHospitalId} = useContext(loginContext)
  const [isLoading, setIsLoading] = useState(false)

  const handleReportDownload = async () => {
    try {
      const getReportData: any = await getInventoryReport(selectedHospitalId)
      const uint8Array = new Uint8Array(getReportData?.data)

      const blob = new Blob([uint8Array], {type: 'text/csv'})

      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = 'inventory_report.csv'

      link.click()

      URL.revokeObjectURL(url)
      link.remove()
    } catch (error: any) {
      toast.error(error?.data?.response?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className='btn btn-primary me-3'
        disabled={tabledata?.length > 0 ? false : true}
        onClick={handleReportDownload}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'GENERAL.EXPORT'})}
      </button>
      {isLoading && <InvManagementListLoading />}
    </div>
  )
}

export {InvManagementListToolbar}
