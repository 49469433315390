/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AuditLog} from '../../core/_models'

type Props = {
  user: AuditLog
}

const AuditLogsUserNameCell: FC<Props> = ({user}) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <p className='text-gray-800  mb-1'>
            {user?.user?.user_type === 'STAFF'
              ? user?.user?.staff?.[0]?.full_name
              : user?.user?.user_type === 'DOCTOR'
              ? user?.user?.doctor?.[0]?.full_name
              : user?.user?.user_type}
          </p>
        </div>
      </div>
    </>
  )
}

export {AuditLogsUserNameCell}
