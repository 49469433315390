/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AuditLog} from '../../core/_models'

type Props = {
  user: AuditLog
}

const AuditLogsUserRoleCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>
        {typeof user.user === 'object' ? user?.user?.user_type : ''}
      </p>
    </div>
  </div>
)

export {AuditLogsUserRoleCell}
