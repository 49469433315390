import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, KTSVG} from '../../../../../_jmh/helpers'
import {initialUser, PatientBiling} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {PatientBillingListLoading} from '../components/loading/PatientBillingListLoading'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useNavigate, useParams} from 'react-router-dom'
import {
  createPatientBiling,
  getPatientBillingById,
  getPatientName,
  updatePatientBiling,
} from '../core/_requests'
import {PageTitle} from '../../../../../_jmh/layout/core'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: PatientBiling
}

const BillingForm: FC<any> = ({user, isUserLoading}) => {
  const intl = useIntl()
  let editUserSchema
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [patientFullname, setPatientFullName] = useState([])
  const [idSelectedPatient, setIdSelectedPatient] = useState(null)
  const [isFormValid, setIsFormValid] = useState(false)
  const [items, setItems] = useState([
    {billing_head: '', cost_per_head: 0, quantity: 0, total_cost: 0},
  ])
  const [isDirty, setIsDirty] = useState(false)

  const [isbillingValid, setIsbillingValid] = useState(
    Array(items.length).fill({
      billing_head: false,
      quantity: false,
      cost_per_head: false,
    })
  )

  const [totalPayableAmount, setTotalPayableAmount] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const navigate = useNavigate()
  const currentDate = new Date()
  const {id} = useParams()
  const {selectedPatientId, selectedHospitalId} = useContext(loginContext)
  const [billData, SetBillData] = useState<any>(null)
  const [fieldErrors, setFieldErrors] = useState<{[key: string]: string}>({})
  const [existingItemsCount, setExistingItemsCount] = useState(0)
  const [isEffectExecuted, setIsEffectExecuted] = useState(false)

  const [userForEdit] = useState<PatientBiling>({
    ...user,
    full_name: user?.full_name || initialUser.full_name,
    taxes: user?.taxes || initialUser.taxes,
    total_billing_cost: user?.total_billing_cost || initialUser.total_billing_cost,
    total_payable_amount: user?.total_payable_amount || initialUser.total_payable_amount,
    payment_method: user?.payment_method || initialUser.payment_method,
    status: user?.status || initialUser.status,
    patient: user?.patient || initialUser.patient,
    billing_date: moment(user?.billing_date).format('DD-MM-YYYY HH:mm'),
    insurance: user?.insurance || initialUser.insurance,
  })

  if (id) {
    editUserSchema = Yup.object().shape({
      // billing_head: Yup.string().required('Billing Head is required'),
      // quantity: Yup.number().min(1, 'Quantity must be greater than 0').required('Quantity is required'),
    })
  } else {
    editUserSchema = Yup.object().shape({
      // billing_head: Yup.string().required('Billing Head is required'),
      // quantity: Yup.number().min(1, 'Quantity must be greater than 0').required('Quantity is required'),
      // cost_per_head: Yup.number()
      //   .min(0, 'Cost per head must be greater than or equal to 0')
      //   .required('Cost per head is required'),

      full_name: Yup.string().required('Patient Name is required'),

      payment_method: Yup.string().required('Payment method is required'),
      status: Yup.string().required('Payment status required'),
    })
  }

  useEffect(() => {
    ;(async () => {
      if (id) {
        const singleBillData: any = await getPatientBillingById(Number(id))
        SetBillData(singleBillData)
      }
    })()
  }, [id])

  const handleFieldChange = () => {
    setIsDirty(true)
  }

  const cancel = (withRefresh?: boolean) => {
    window.history.back()
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    ;(async () => {
      const patientNameData: any = await getPatientName()

      const activePatientData = patientNameData?.data?.filter((item: any) => {
        return item?.status === 'ACTIVE' && item?.hospital_id == selectedHospitalId
      })

      if (activePatientData) {
        setPatientFullName(activePatientData)
      }
    })()
  }, [selectedHospitalId])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        // Calculate total billing cost and total payable amount
        const totalBillingCost = Number(calculateSubtotal())
        const totalPayableAmount2 = Number(totalPayableAmount.toFixed(2))
        // Add the total billing cost and total payable amount to the form values
        values.total_billing_cost = totalBillingCost
        values.total_payable_amount = totalPayableAmount2
        values.billing_date = moment(currentDate).format('DD-MM-YYYY HH:mm')
        if (isNotEmpty(values?.bill_number)) {
          await updatePatientBiling(values).then((res: any) => {
            toast.success(res?.data?.message)
            navigate('/patient-data/patient-billing')
          })
        } else {
          await createPatientBiling({
            ...values,
            patient_id: Number(idSelectedPatient ? idSelectedPatient : selectedPatientId),
            hospital_id: Number(selectedHospitalId),
            billing_detail: items,
          }).then((res: any) => {
            toast.success(res?.data?.message)
            navigate('/patient-data/patient-billing')
          })
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  const addNewItem = () => {
    const newItem = {
      billing_head: '',
      cost_per_head: 0,
      quantity: 0,
      total_cost: 0,
    }

    const updatedItems = [...items, newItem]
    setItems(updatedItems)

    const updatedIsbillingValid = [
      ...isbillingValid,
      {
        billing_head: false,
        quantity: false,
        cost_per_head: false,
      },
    ]
    setIsbillingValid(updatedIsbillingValid)

    if (billData) {
      const updatedBillingDetail = [...billData.billing_detail, newItem]
      SetBillData({...billData, billing_detail: updatedBillingDetail})
    }
    // const updatedItems = [...items, newItem]
    // setItems(updatedItems)
  }

  const removeItem = (index: number) => {
    setIsDirty(true)
    const updatedItems = [...items]
    updatedItems.splice(index, 1)
    setItems(updatedItems)

    const updatedIsbillingValid = [...isbillingValid]
    updatedIsbillingValid.splice(index, 1)
    setIsbillingValid(updatedIsbillingValid)

    if (billData) {
      const updatedBillingDetail = [...billData.billing_detail]
      updatedBillingDetail.splice(index, 1)
      SetBillData({...billData, billing_detail: updatedBillingDetail})
    }
  }

  const calculateSubtotal: any = () => {
    let total_billing_cost = 0
    items.forEach((item) => {
      total_billing_cost += item.quantity * item.cost_per_head
    })
    return total_billing_cost.toFixed(2) // Adjust decimal places if needed
  }
  const calculateTotalPayableAmount = () => {
    const subtotal = calculateSubtotal()
    const taxPercentage = parseFloat(formik.values.taxes?.toString() || '0')
    const taxAmount = (Number(subtotal) * taxPercentage) / 100
    const totalPayableAmount1 = Number(subtotal) + taxAmount
    setTotalPayableAmount(totalPayableAmount1)
  }

  useEffect(() => {
    const newSubtotal = calculateSubtotal() // Calculate subtotal
    setSubtotal(Number(newSubtotal)) // Update subtotal state
    calculateTotalPayableAmount() // Update totalPayableAmount
  }, [items, formik.values.taxes, isbillingValid])

  const handleCheckboxChange = (e: any) => {
    const {name, checked} = e.target
    // Update the field value based on the selected checkbox
    formik.setFieldValue(name, checked ? e.target.value : '')
    handleFieldChange()
  }

  const handleChangePatient = (e: any) => {
    const {name, value} = e.target
    setIdSelectedPatient(value)
    const selectedValue = e.target.value
    if (selectedValue != '') {
      formik.setFieldValue('full_name', selectedValue)
    }
    handleFieldChange()
  }

  useEffect(() => {
    // ...
    if (billData) {
      formik.setValues({
        ...billData,
        billing_date: moment.utc(billData.billing_date).format('DD-MM-YYYY H:mm'),

        // Populate other fields from billData
      })

      setItems(billData.billing_detail || [])

      const count = billData.billing_detail ? billData.billing_detail.length : 0
      setExistingItemsCount(count)

      if (!isEffectExecuted) {
        const updatedIsbillingValid = Array(count).fill({
          billing_head: true,
          quantity: true,
          cost_per_head: true,
        })
        setIsbillingValid(updatedIsbillingValid)
        setIsEffectExecuted(true)
      }
      // Calculate and set totalPayableAmount and subtotal here
    }
  }, [billData, isEffectExecuted])

  useEffect(() => {
    if (billData) {
      let isValid = true
      for (const valid of isbillingValid) {
        if (
          valid.billing_head === false ||
          valid.cost_per_head === false ||
          valid.quantity === false
        ) {
          isValid = false
          break
        }
      }
      setIsFormValid(isValid)
    } else {
      let isValid = true
      for (const valid of isbillingValid) {
        if (
          valid.billing_head === false ||
          valid.cost_per_head === false ||
          valid.quantity === false
        ) {
          isValid = false
          break
        }
      }
      setIsFormValid(isValid)
    }
  }, [isbillingValid])

  const handleBackClick = () => {
    window.history.back() // Redirect to the previous page
  }
  const [isSpacingValid, setIsSpacingValid] = useState(true)

  return (
    <>
      <div>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PATIENT.BILLING'})}</PageTitle>
        <div>
          <div className='card card-custom card-flush h-auto'>
            <div className='card-header'>
              <h3 className='card-title' style={{fontSize: '30px'}}>
                {billData?.bill_number ? `Invoice # ${billData?.bill_number}` : ''}
              </h3>
              <div className='card-toolbar'>
                <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                  {intl.formatMessage({id: 'GENERAL.BACK'})}
                </button>
              </div>
            </div>
            <div className='card-body py-5'>
              <form
                id='kt_modal_add_user_form3'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                >
                  <div className='row'>
                    <div className='fv-row mb-7 col-4 px-0'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.PATIENT.FULL.NAME'})}
                      </label>
                      <select
                        className='form-select form_patient_select'
                        aria-label='Select example2'
                        {...formik.getFieldProps('full_name')}
                        value={idSelectedPatient || billData?.patient_id}
                        name='full_name'
                        onChange={handleChangePatient}
                        disabled={billData ? true : false}
                      >
                        <option value=''>
                          {intl.formatMessage({id: 'PATIENT_INSURANCE.SELECT.PATIENT.NAME'})}
                        </option>
                        {patientFullname &&
                          patientFullname?.map((item: any) => {
                            return (
                              <option key={item?.id} value={item?.id}>
                                {item?.full_name}
                              </option>
                            )
                          })}
                      </select>
                      {formik.touched.full_name && formik.errors.full_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.full_name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-7 col-4'>
                      <label className={`fw-bold fs-6 mb-2`}>
                        {intl.formatMessage({id: 'PATIENT_BILLING.BILLING.DATE.TIME'})}
                      </label>
                      <div className='position-relative'>
                        <DatePicker
                          {...formik.getFieldProps('billing_date')}
                          className='form-control mb-3 mb-lg-0 cursor-pointer'
                          selected={currentDate}
                          showTimeSelect
                          name='billing_date'
                          timeFormat='HH:mm'
                          dateFormat='dd/MM/yyyy HH:mm'
                          // readOnly
                          disabled
                        />
                        <span
                          className='DatePicker_icon cursor-pointer'
                          onClick={() => document.getElementsByName('billing_date')[0].focus()}
                        >
                          {/* <i className='fa fa-calendar'></i> */}
                        </span>
                      </div>
                      {formik.touched.billing_date && formik.errors.billing_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.billing_date}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <table className='table'>
                      <thead>
                        <tr className='row fw-bold fs-6'>
                          <th className='col-5 required'>
                            {intl.formatMessage({id: 'PATIENT_BILLING.BILLING.HEAD'})}
                          </th>
                          <th className='col-2 required'>
                            {intl.formatMessage({id: 'PATIENT_BILLING.QUANTITY'})}
                          </th>
                          <th className='col-2 required'>
                            {intl.formatMessage({id: 'PATIENT_BILLING.COST.PER.HEAD'})}
                          </th>
                          <th className='col-2'>
                            {intl.formatMessage({id: 'PATIENT_BILLING.TOTAL.COST'})}
                          </th>
                          <th className='col-1 text-end'>
                            {intl.formatMessage({id: 'GENERAL.ACTION'})}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items &&
                          items?.map((item: any, index: number) => (
                            <tr key={index} className='row'>
                              <td className='col-5'>
                                <input
                                  placeholder='Enter Billing Head'
                                  value={item.billing_head}
                                  onChange={(e) => {
                                    const updatedItems = [...items]
                                    const spacingItems = [...items]
                                    const inputValue = e.target.value
                                    updatedItems[index].billing_head = inputValue
                                    spacingItems[index].billing_head = inputValue.trimStart()
                                    setItems(updatedItems)
                                    setItems(spacingItems)
                                    handleFieldChange()
                                    const hasLeadingSpaces = inputValue !== inputValue.trimStart()
                                    setIsSpacingValid(!hasLeadingSpaces)

                                    if (!items[index].billing_head) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].billing_head`]:
                                          'Billing Head is required',
                                      }))

                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {...newValid[index], billing_head: false}
                                        return newValid
                                      })
                                    } else {
                                      if (items[index].billing_head.length > 0) {
                                        setIsbillingValid((prevValid) => {
                                          const newValid = [...prevValid]
                                          newValid[index] = {
                                            ...newValid[index],
                                            billing_head: true,
                                          }
                                          return newValid
                                        })
                                      }
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          billing_head: true,
                                        }
                                        return newValid
                                      })
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].billing_head`]: '',
                                      }))
                                    }
                                  }}
                                  onBlur={() => {
                                    const inputValue = items[index].billing_head

                                    // Check for spacing error
                                    const hasLeadingSpaces = inputValue !== inputValue.trimStart()
                                    setIsSpacingValid(!hasLeadingSpaces)

                                    if (!items[index].billing_head) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].billing_head`]:
                                          'Billing Head is required',
                                      }))

                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          billing_head: false,
                                        }
                                        return newValid
                                      })
                                    } else {
                                      if (items[index].billing_head.length > 0) {
                                        setIsbillingValid((prevValid) => {
                                          const newValid = [...prevValid]
                                          newValid[index] = {
                                            ...newValid[index],
                                            billing_head: true,
                                          }
                                          return newValid
                                        })
                                      }
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          billing_head: true,
                                        }
                                        return newValid
                                      })
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].billing_head`]: '',
                                      }))
                                    }
                                  }}
                                  type='text'
                                  name={`items[${index}].billing_head`}
                                  className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',
                                    fieldErrors[`items[${index}].billing_head`] &&
                                      !item.billing_head
                                      ? 'is-invalid'
                                      : '',
                                    !isSpacingValid ? 'has-spacing-error' : '' // Add class for spacing error
                                  )}
                                  autoComplete='off'
                                />
                                {fieldErrors[`items[${index}].billing_head`] &&
                                  !item.billing_head && (
                                    <div className='fv-help-block1'>
                                      {fieldErrors[`items[${index}].billing_head`]}
                                    </div>
                                  )}
                              </td>
                              <td className='col-2'>
                                <input
                                  placeholder='Enter qty'
                                  value={item.quantity}
                                  min={0}
                                  onChange={(e) => {
                                    const updatedItems = [...items]
                                    updatedItems[index].quantity = parseFloat(e.target.value)
                                    updatedItems[index].total_cost =
                                      parseFloat(e.target.value) * item.cost_per_head // Update total_cost
                                    setItems(updatedItems)
                                    handleFieldChange()

                                    if (!items[index].quantity) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].quantity`]: 'Quantity is required',
                                      }))
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          quantity: false,
                                        }
                                        return newValid
                                      })
                                    } else if (items[index].quantity <= 0) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].quantity`]:
                                          'Quantity must be greater than 0',
                                      }))
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          quantity: false,
                                        }
                                        return newValid
                                      })
                                    } else {
                                      if (items[index].quantity > 0) {
                                        setIsbillingValid((prevValid) => {
                                          const newValid = [...prevValid]
                                          newValid[index] = {
                                            ...newValid[index],
                                            quantity: true,
                                          }
                                          return newValid
                                        })
                                      }
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].quantity`]: '',
                                      }))
                                    }
                                  }}
                                  onBlur={(e) => {
                                    if (!items[index].quantity) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].quantity`]: 'Quantity is required',
                                      }))
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          quantity: false,
                                        }
                                        return newValid
                                      })
                                    } else if (items[index].quantity <= 0) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].quantity`]:
                                          'Quantity must be greater than 0',
                                      }))
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          quantity: false,
                                        }
                                        return newValid
                                      })
                                    } else {
                                      if (items[index].quantity > 0) {
                                        setIsbillingValid((prevValid) => {
                                          const newValid = [...prevValid]
                                          newValid[index] = {
                                            ...newValid[index],
                                            quantity: true,
                                          }
                                          return newValid
                                        })
                                      }
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].quantity`]: '',
                                      }))
                                    }
                                  }}
                                  type='number'
                                  name={`items[${index}].quantity`}
                                  className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',

                                    fieldErrors[`items[${index}].quantity`] ? 'is-invalid' : ''
                                  )}
                                  autoComplete='off'
                                />
                                {fieldErrors[`items[${index}].quantity`] && (
                                  <div className='fv-help-block1'>
                                    {fieldErrors[`items[${index}].quantity`]}
                                  </div>
                                )}
                              </td>
                              <td className='col-2'>
                                <input
                                  placeholder='Enter cost'
                                  value={item.cost_per_head}
                                  onChange={(e) => {
                                    const updatedItems = [...items]
                                    updatedItems[index].cost_per_head = parseFloat(e.target.value)
                                    updatedItems[index].total_cost =
                                      item.quantity * parseFloat(e.target.value) // Update total_cost
                                    setItems(updatedItems)
                                    handleFieldChange()

                                    if (isNaN(items[index].cost_per_head)) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].cost_per_head`]:
                                          'Cost per head is required',
                                      }))
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          cost_per_head: false,
                                        }
                                        return newValid
                                      })
                                    } else if (items[index].cost_per_head < 0) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].cost_per_head`]:
                                          'Cost per head must be greater than 0',
                                      }))
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          cost_per_head: false,
                                        }
                                        return newValid
                                      })
                                    } else {
                                      if (items[index].cost_per_head >= 0) {
                                        setIsbillingValid((prevValid) => {
                                          const newValid = [...prevValid]
                                          newValid[index] = {
                                            ...newValid[index],
                                            cost_per_head: true,
                                          }
                                          return newValid
                                        })
                                      }
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].cost_per_head`]: '',
                                      }))
                                    }
                                  }}
                                  onBlur={() => {
                                    if (isNaN(items[index].cost_per_head)) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].cost_per_head`]:
                                          'Cost per head is required',
                                      }))
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          cost_per_head: false,
                                        }
                                        return newValid
                                      })
                                    } else if (items[index].cost_per_head < 0) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].cost_per_head`]:
                                          'Cost per head must be greater than 0',
                                      }))
                                      setIsbillingValid((prevValid) => {
                                        const newValid = [...prevValid]
                                        newValid[index] = {
                                          ...newValid[index],
                                          cost_per_head: false,
                                        }
                                        return newValid
                                      })
                                    } else {
                                      if (items[index].cost_per_head > 0) {
                                        setIsbillingValid((prevValid) => {
                                          const newValid = [...prevValid]
                                          newValid[index] = {
                                            ...newValid[index],
                                            cost_per_head: true,
                                          }
                                          return newValid
                                        })
                                      }
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].cost_per_head`]: '',
                                      }))
                                    }
                                  }}
                                  type='number'
                                  name={`items[${index}].cost_per_head`}
                                  className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',
                                    fieldErrors[`items[${index}].cost_per_head`] ? 'is-invalid' : ''
                                  )}
                                  autoComplete='off'
                                />
                                {fieldErrors[`items[${index}].cost_per_head`] && (
                                  <div className='fv-help-block1'>
                                    {fieldErrors[`items[${index}].cost_per_head`]}
                                  </div>
                                )}
                              </td>
                              <td className='col-2'>
                                <input
                                  value={Number(item.cost_per_head * item.quantity)}
                                  name={`items[${index}].total_cost`}
                                  type='number'
                                  className='form-control  mb-3 mb-lg-0'
                                  disabled
                                  readOnly
                                />
                              </td>
                              <td className='col-1 text-end'>
                                <div
                                  className='costperhead_delete btn btn-light-danger btn-active-danger btn-sm px-4 py-3'
                                  style={{minHeight: '43px'}}
                                  onClick={() => removeItem(index)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-muted m-0 fs-3'
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div className='row mb-5'>
                    <div className='col-2'>
                      <div className=''>
                        <button type='button' className='btn btn-success' onClick={addNewItem}>
                          {intl.formatMessage({id: 'PATIENT_BILLING.ADD.NEW.ITEM'})}
                        </button>
                      </div>
                    </div>
                    <div className='col-10'>
                      <div className='row mb-5'>
                        <div className='col-6'></div>
                        <div className='col-5'>
                          <div className='row align-items-center'>
                            <div className='col-6'>
                              <label className='fw-bold fs-6 mb-0 me-3'>
                                {intl.formatMessage({id: 'PATIENT_BILLING.TOTAL.BILLING.COST'})}
                              </label>
                            </div>
                            <div className='col-6 ps-2 pe-2'>
                              <input
                                {...formik.getFieldProps('total_billing_cost')}
                                value={calculateSubtotal()}
                                readOnly
                                type='text'
                                name='total_billing_cost'
                                className='form-control w-100'
                                disabled
                                // style={{maxWidth: '132px'}}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col-1'></div>
                      </div>
                      <div className='row mb-5'>
                        <div className='col-6'></div>
                        <div className='col-5'>
                          <div className='row align-items-center'>
                            <div className='col-6'>
                              <div className='d-flex align-items-center'>
                                <label className='fw-bold fs-6 mb-0 me-3 w-50'>
                                  {intl.formatMessage({id: 'PATIENT_BILLING.TAX(%)'})}:
                                </label>
                                <input
                                  value={formik.values.taxes}
                                  // onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  type='number'
                                  name='taxes'
                                  min={0}
                                  className='form-control form-control-solid w-50'
                                  onChange={(e) => {
                                    formik.handleChange(e) // Handle formik's field change
                                    handleFieldChange()
                                  }}
                                />
                              </div>
                            </div>

                            <div className='col-6 ps-2 pe-2'>
                              <input
                                value={
                                  formik.values.taxes &&
                                  (calculateSubtotal() * formik.values.taxes) / 100
                                }
                                className='form-control  w-100'
                                disabled
                                // onChange={(e) => {
                                //   formik.handleChange(e) // Handle formik's field change
                                //   handleFieldChange()
                                // }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col-1'></div>
                      </div>
                      <div className='row mb-5'>
                        <div className='col-6'></div>
                        <div className='col-5'>
                          <div className='row align-items-center'>
                            <div className='col-6'>
                              <label className='fw-bold fs-6 mb-0 me-3'>
                                {intl.formatMessage({id: 'PATIENT_BILLING.TOTAL.PAYABLE.AMOUNT'})}:
                              </label>
                            </div>
                            <div className='col-6 ps-2 pe-2'>
                              <input
                                {...formik.getFieldProps('total_payable_amount')}
                                value={totalPayableAmount.toFixed(2)}
                                readOnly
                                name='total_payable_amount'
                                type='text'
                                className='form-control w-100'
                                disabled
                                // style={{maxWidth: '132px'}}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col-1'></div>
                      </div>
                    </div>
                  </div>

                  <div className='row justify-content-between align-items-center'>
                    <div className='fv-row  col-3'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.STATUS'})}
                      </label>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        {...formik.getFieldProps('status')}
                        name='status'
                        onChange={(e) => {
                          formik.handleChange(e) // Handle formik's field change
                          handleFieldChange()
                        }}
                      >
                        <option value=''>
                          {intl.formatMessage({id: 'PATIENT_BILLING.SELECT.PAYMENT.STATUS'})}
                        </option>
                        <option value='PENDING'>
                          {intl.formatMessage({id: 'GENERAL.PENDING.STATUS'})}
                        </option>
                        <option value='PROCESSING'>
                          {intl.formatMessage({id: 'GENERAL.PROCESSING.STATUS'})}
                        </option>
                        <option value='COMPLETED'>
                          {intl.formatMessage({id: 'GENERAL.COMPLETED'})}
                        </option>
                      </select>
                      {formik.touched.status && formik.errors.status && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.status}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='fv-row  col-3'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'PATIENT_BILLING.PAYMENT.METHOD'})}
                      </label>
                      <select
                        className='form-select'
                        aria-label='Select example1'
                        {...formik.getFieldProps('payment_method')}
                        name='payment_method'
                        onChange={(e) => {
                          formik.handleChange(e) // Handle formik's field change
                          handleFieldChange()
                        }}
                      >
                        <option value=''>
                          {intl.formatMessage({id: 'PATIENT_BILLING.SELECT.PAYMENT.METHOD'})}
                        </option>
                        <option value='CASH'>{intl.formatMessage({id: 'GENERAL.CASH'})}</option>
                        <option value='CARD'>{intl.formatMessage({id: 'GENERAL.CARD'})}</option>
                        <option value='CHEQUE'>{intl.formatMessage({id: 'GENERAL.CHEQUE'})}</option>
                      </select>
                      {formik.touched.payment_method && formik.errors.payment_method && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.payment_method}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='fv-row  col-5'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.INSURANCE'})}
                      </label>
                      <div className='row'>
                        <div className='form-check form-check-custom form-check-solid col-6'>
                          <input
                            className='form-check-input h-30px w-30px'
                            type='checkbox'
                            value='PAID TO HOSPITAL' // Set the value for the first checkbox
                            id='flexCheckbox30'
                            name='insurance'
                            checked={formik.values.insurance === 'PAID TO HOSPITAL'} // Check if the value matches the first checkbox value
                            onChange={handleCheckboxChange}
                          />

                          <label className='form-check-label' htmlFor='flexCheckbox30'>
                            {intl.formatMessage({id: 'PATIENT_BILLING.PAID.TO.HOSPITAL'})}
                          </label>
                        </div>
                        <div className='form-check form-check-custom form-check-solid col-6 '>
                          <input
                            className='form-check-input h-30px w-30px'
                            type='checkbox'
                            value='PAID TO PATIENT' // Set the value for the second checkbox
                            id='flexCheckbox31'
                            name='insurance'
                            checked={formik.values.insurance === 'PAID TO PATIENT'} // Check if the value matches the second checkbox value
                            onChange={handleCheckboxChange}
                          />
                          <label className='form-check-label' htmlFor='flexCheckbox31'>
                            {intl.formatMessage({id: 'PATIENT_BILLING.PAID.TO.PATIENT'})}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => cancel()}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={formik.isSubmitting || isUserLoading}
                  >
                    {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={
                      !isFormValid || isbillingValid.length === 0 || formik.isSubmitting || !isDirty
                    }
                  >
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'GENERAL.SAVE'})}
                    </span>
                    {(formik.isSubmitting || isUserLoading) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
              {(formik.isSubmitting || isUserLoading) && <PatientBillingListLoading />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {BillingForm}
