import {useContext} from 'react'
import {KTSVG} from '../../../../../_jmh/helpers'
import {loginContext} from '../../../../context/context'
import {useListView} from '../core/ListViewProvider'
import {useIntl} from 'react-intl'

const AppointmentEditModalHeader = ({showModal}: any) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {tabSelection, setTabSelection} = useContext(loginContext)

  const handleChnage = () => {
    setItemIdForUpdate(undefined)
    setTabSelection('NOW')
    showModal(false)
  }

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>
        {itemIdForUpdate
          ? intl.formatMessage({id: 'PATIENT_APPOINTMENT.EDIT.APPOINTMENT'})
          : intl.formatMessage({id: 'PATIENT_APPOINTMENT.ADD.APPOINTMENT'})}{' '}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={handleChnage}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {AppointmentEditModalHeader}
