import {useEffect, useState} from 'react'
import {KTSVG} from '../../../_jmh/helpers'
import Slider from 'react-slick'
import {getLandingSpecialityData} from './core/_request'
import {useIntl} from 'react-intl'

const LandingSpeciality = () => {
  const [specialityData, setSpecialityData] = useState<any>(null)
  const [specialityGroups, setSpecialityGroups] = useState<any[]>([])
  const intl = useIntl()

  const fetchSpecialityData = () => {
    getLandingSpecialityData().then((res) => {
      setSpecialityData(res?.data?.data?.[0])
    })
  }
  useEffect(() => {
    fetchSpecialityData()
  }, [])

  const chunkArray = (array: any, size: any) => {
    const chunkedArray = []
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size))
    }
    return chunkedArray
  }

  useEffect(() => {
    if (specialityData) {
      const {specilities} = specialityData
      const groupedSpecialities = chunkArray(specilities, 3)
      setSpecialityGroups(groupedSpecialities)
    }
  }, [specialityData])

  //parellex
  const [parallaxOffset, setParallaxOffset] = useState({x: 0, y: 0})

  const handleMouseEnter = () => {
    setParallaxOffset({x: 0, y: 0})
  }

  const handleMouseMove = (event: any) => {
    const amountMovedX = (event.clientX * -0.3) / 8
    const amountMovedY = (event.clientY * -0.3) / 8
    setParallaxOffset({x: amountMovedX, y: amountMovedY})
  }

  const handleMouseLeave = () => {
    setParallaxOffset({x: 0, y: 0})
  }

  const sliderSettings = {
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 2000,
    vertical: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <section className='landing_our_speciality section-padding'>
      <div className='landing_container'>
        <div className='speciality_container'>
          <div className='row align-items-center'>
            <div className='col-6'>
              <div
                className='os-wrapper'
                onMouseEnter={handleMouseEnter}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              >
                <div className='os_content_main_wrapper'>
                  <div className='os_content_sub_wrapper'>
                    <div className='os_content'>
                      <h3>{intl.formatMessage({id: 'LANDING_PAGE.SPECIALITY.TITLE'})}</h3>
                    </div>
                  </div>
                </div>
                <div
                  className='os_element os_element1'
                  style={{transform: `translate(${parallaxOffset.x}px, ${parallaxOffset.y}px)`}}
                >
                  <KTSVG path='/media/landing_asset/os_element1.svg' />
                </div>
                <div
                  className='os_element os_element2'
                  style={{transform: `translate(${parallaxOffset.x}px, ${parallaxOffset.y}px)`}}
                >
                  <KTSVG path='/media/landing_asset/os_element2.svg' />
                </div>

                <div
                  className='os_element os_element3'
                  style={{transform: `translate(${parallaxOffset.x}px, ${parallaxOffset.y}px)`}}
                >
                  <KTSVG path='/media/landing_asset/os_element3.svg' />
                </div>
                <div
                  className='os_element os_element4'
                  style={{transform: `translate(${parallaxOffset.x}px, ${parallaxOffset.y}px)`}}
                >
                  <KTSVG path='/media/landing_asset/os_element4.svg' />
                </div>
                <div
                  className='os_element os_element5'
                  style={{transform: `translate(${parallaxOffset.x}px, ${parallaxOffset.y}px)`}}
                >
                  <KTSVG path='/media/landing_asset/os_element5.svg' />
                </div>
                <div
                  className='os_element os_element6'
                  style={{transform: `translate(${parallaxOffset.x}px, ${parallaxOffset.y}px)`}}
                >
                  <KTSVG path='/media/landing_asset/os_element6.svg' />
                </div>
                <div
                  className='os_element os_element7'
                  style={{transform: `translate(${parallaxOffset.x}px, ${parallaxOffset.y}px)`}}
                >
                  <KTSVG path='/media/landing_asset/os_element7.svg' />
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='os_right_contaniner'>
                <div className='os_top_content'>
                  <h4>{specialityData?.title}</h4>
                  <p>{specialityData?.description}</p>
                </div>
                <div className='os_bottom_content'>
                  <div className='os_slider_row'>
                    <Slider {...sliderSettings}>
                      {specialityGroups?.map((item: any, index: number) => {
                        return (
                          <div key={index}>
                            <div className='os_slider_row_item'>
                              {item?.map((item2: any, innerIndex: number) => {
                                return (
                                  <div key={innerIndex}>
                                    <div className='os_slider_row_item_inner'>
                                      <span>{item2}</span>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingSpeciality
