import {ID, Response} from '../../../../../_jmh/helpers'
import {User} from '../../../roles-and-permissions/Roleslist/core/_models'

export type Doctor = {
  id?: ID
  full_name?: string | undefined
  email?: string
  date_of_birth?: string
  gender?: string
  permanent_address?: string
  contact_number?: string
  visiting_doctor?: any
  qualification?: string | undefined
  years_of_experience?: number
  new_case_fee?: number
  old_case_fee?: number
  role_id?: number
  hospital_id?: number
  speciality_ids?: string[]
  image?: any
  signature?:any
  status?: string
  initials?: {
    label: string
    state: string
  }
  speciality?: {
    id?: number
    name?: string
    description?: string
    status?: string
  }
  user?: {
    id?: number
    email?: string
    is_password_change?: boolean
    user_type?: string
    status?: string
    hospital_id?: string
    role?: User
    hospital?: {
      id: number
      name: string
      address: string
      contact_person: string
      contact_number: string
      status: string
    }
  }
}

export type DoctorsQueryResponse = Response<Array<Doctor>>

export const initialUser: Doctor = {
  full_name: '',
  gender: '',
  date_of_birth: '',
  permanent_address: '',
  contact_number: '',
  email: '',
  visiting_doctor: false,
  qualification: '',
  image: null,
  signature:null,
  status: '',
}
