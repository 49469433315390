import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import TagsInput from 'react-tagsinput'

const ChiefComplaints = () => {
  const intl = useIntl()
  const [compaliantTags, setComplaintsTags] = useState<any>([])
  const [compaintInputValue, setComplaintInputValue] = useState<any>('')

  const defaultComplaintsMain = [
    'Cough',
    'Allergic rhinitis',
    'Fatigue',
    'Vomiting',
    'Nausea',
    'Cold',
    'Viral Fever',
    'Common Cold',
    'Loss of appetite',
    'Weakness',
    'Sneezing',
  ]
  const [defaultComplaintHistory, setDefaultComplaintHistory] = useState<any>(defaultComplaintsMain)

  useEffect(() => {
    const prescriptionData = sessionStorage.getItem('prescriptionData')
    if (prescriptionData) {
      const parsedPrescriptionData = JSON.parse(prescriptionData)
      if (parsedPrescriptionData.chief_complaints) {
        setComplaintsTags(parsedPrescriptionData.chief_complaints)
      }
    }
  }, [])

  const handleComplaintHisoryTagRemove = (tag: any) => {
    setComplaintsTags(compaliantTags.filter((t: any) => t !== tag))
    if (defaultComplaintsMain.includes(tag)) {
      const updatedDefaultComplaintHistory = Array.from(new Set([...defaultComplaintHistory, tag]))
      setDefaultComplaintHistory(updatedDefaultComplaintHistory)
    }
  }
  const defaultComplaintHistoryFiltered = defaultComplaintHistory.filter(
    (value: any) => !compaliantTags.includes(value)
  )

  const handleComplaintHistoryTagClick = (tag: any) => {
    setComplaintsTags([...compaliantTags, tag])
    setDefaultComplaintHistory(defaultComplaintHistory.filter((value: any) => value !== tag))
  }

  const handleComplaintInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComplaintInputValue(e.target.value)
  }

  const handleComplaintHistoryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (compaintInputValue.trim() !== '') {
        setComplaintsTags([...compaliantTags, compaintInputValue.trim()])
        setComplaintInputValue('')
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    sessionStorage.setItem(
      'prescriptionData',
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('prescriptionData') || '{}'),
        chief_complaints: compaliantTags,
      })
    )
  }, [compaliantTags])

  return (
    <div>
      <h4>
        {intl.formatMessage({
          id: 'PRESCRIPTION_CHIEF_COMPLAINTS_TITLE',
        })}
      </h4>
      <div className='fv-row mb-2 col-12'>
        <TagsInput
          value={compaliantTags}
          onChange={(newTags) => {
            setComplaintsTags(newTags)
          }}
          tagProps={{
            className:
              'react-tagsinput-tag btn btn-sm btn-light-primary btn-active-light-primary shadow',
            onRemove: (index: any) => handleComplaintHisoryTagRemove(compaliantTags[index]),
          }}
          inputProps={{
            placeholder: intl.formatMessage({
              id: 'GENERAL.ADD.COMPLAINTS',
            }),
            value: compaintInputValue,
            onChange: handleComplaintInputChange,
            onKeyDown: handleComplaintHistoryKeyDown,
          }}
          className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
        />

        <div className='preMedicalValueWrapper my-5'>
          {defaultComplaintHistoryFiltered?.map((value: any) => (
            <span
              className='medicalValueTag btn btn-sm'
              key={value}
              onClick={() => handleComplaintHistoryTagClick(value)}
            >
              {value}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChiefComplaints
