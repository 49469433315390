import {FC, useContext, useEffect, useMemo} from 'react'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {MedicalNotesListLoading} from '../components/loading/MedicalNotesListLoading'
import {MedicalNotesListPagination} from '../components/pagination/MedicalNotesListPagination'
import {KTCardBody, QUERIES} from '../../../../../_jmh/helpers'
import moment from 'moment'
import {MenuComponent} from '../../../../../_jmh/assets/ts/components'
import {useMutation, useQueryClient} from 'react-query'
import {deleteMedicalNote} from '../core/_requests'
import {useListView} from '../core/ListViewProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  id: any
}

const MedicalNotesTable: FC<Props> = ({id}) => {
  const intl = useIntl()
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const colors = ['primary', 'success', 'danger', 'warning', 'info']
  const groupedData = useMemo(() => {
    const grouped: any = {}

    data.forEach((item) => {
      const year = moment(item.date).format('YYYY')
      const month = moment(item.date).format('MMM')

      if (!grouped[year]) {
        grouped[year] = {}
      }

      if (!grouped[year][month]) {
        grouped[year][month] = []
      }

      grouped[year][month].push(item)
    })

    return grouped
  }, [data])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (id: any) => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation((id) => deleteMedicalNote(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      toast.error('Patient-Medical-Note Deleted')
    },
  })

  const {loginData, setLoginData} = useContext(loginContext)

  const noteData = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-medical-notes') {
      return item?.module_id
    }
  })

  const edit = noteData?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  const deleteData = noteData?.filter((item: any) => {
    if (item.permission.method === 'DELETE') {
      return item
    }
  })

  const sortedYears = Object.keys(groupedData).sort((a: any, b: any) => b - a) // Sort years in descending order

  return (
    <KTCardBody className='py-4 px-0'>
      <div className='timeline'>
        {sortedYears.length > 0 ? (
          <>
            {sortedYears.map((year, index: any) => (
              <div key={`year-${year}`} className='timeline-item'>
                {/* Move timeline-item here */}
                <div
                  className='timeline-line w-20px'
                  style={{marginTop: '18px', left: '-1px'}}
                ></div>
                <div className='timeline-badge'>
                  <i className={`fa fa-genderless text-${colors[index % colors.length]} fs-1`}></i>
                </div>
                <div className='timeline-content'>
                  <div className='mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      <h4>{year}</h4>
                      {Object.keys(groupedData[year])
                        .sort((a, b) => moment(b, 'MMM').diff(moment(a, 'MMM'))) // Sort months in descending order
                        .map((month) => (
                          <div key={`month-${year}-${month}`} className='mb-4'>
                            <h5>{month}</h5>
                            {groupedData[year][month].map((item: any, i: any) => (
                              <div key={`row-${i}`} className='row'>
                                <div className=''>
                                  <div
                                    className={`row border border-dashed border-gray-300 rounded min-w-800px px-1 py-2 mb-3 bg-light-${
                                      colors[index % colors.length]
                                    }`}
                                  >
                                    <div className='col-9'>
                                      <div className='fs-4 text-dark fw-semibold'>{item.title}</div>
                                      <div className='pe-2'>
                                        <span className='fs-7 text-muted fw-semibold'>
                                          {item.description}
                                        </span>
                                      </div>
                                    </div>
                                    <div className='col-3'>
                                      <div className='d-flex mt-5'>
                                        <span
                                          className={`fs-5 text-${
                                            colors[index % colors.length]
                                          } fw-semibold w-200px min-w-100px`}
                                        >
                                          {moment(item.date).format('DD-MM-YYYY')}
                                        </span>
                                        <div className='d-flex gap-2 cursor-pointer'>
                                          {edit.length ? (
                                            <div
                                              className='border-0'
                                              onClick={() => {
                                                openEditModal(item.id)
                                              }}
                                            >
                                              <i
                                                className={`fa-regular fa-pen-to-square text-${
                                                  colors[index % colors.length]
                                                } fs-2`}
                                              ></i>
                                            </div>
                                          ) : (
                                            ''
                                          )}

                                          {deleteData.length ? (
                                            <div
                                              className=' border-0 '
                                              onClick={async () =>
                                                await deleteItem.mutateAsync(item.id)
                                              }
                                            >
                                              <i className='fa-regular fa-trash-can text-danger fs-2'></i>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
            {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
          </div>
        )}
      </div>
      <MedicalNotesListPagination />
      {isLoading && <MedicalNotesListLoading />}
    </KTCardBody>
  )
}

export {MedicalNotesTable}
