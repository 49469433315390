/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {loginContext} from '../../../../app/context/context'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const {logout} = useAuth()
  const {loginData} = useContext(loginContext)
  const {email} = loginData?.data || {}
  const {full_name, image} = (loginData?.data && loginData?.data.personal_details) || {}
  const [previewUrl, setPreviewUrl] = useState('')
  const [previewKey, setPreviewKey] = useState('')
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        {/* {loginData?.data?.user_type === 'SUPER_ADMIN' ? (
          ''
        ) : ( */}
        <>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='user_profile_icon me-5'>
              {previewUrl || image ? (
                <img
                  className='image-input-wrapper'
                  src={previewUrl || image}
                  alt='Preview'
                  key={previewKey}
                />
              ) : (
                <div className='image-input-wrapper'>
                  <img
                    src={toAbsoluteUrl('/media/landing_asset/admin_profile_pic.png')}
                    alt='user_profile'
                  />
                </div>
              )}
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5 text-black'>{full_name}</div>
              <div className='fw-bold text-muted  fs-7'>{email}</div>
            </div>
          </div>
        </>
        {/* )} */}
      </div>
      {/* {loginData?.data?.user_type === 'SUPER_ADMIN' ? (
        ''
      ) : ( */}
      <>
        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <Link to='my-profile' className='menu-link px-5'>
            {intl.formatMessage({id: 'GENERAL.MY_PROFILE'})}
          </Link>
        </div>
      </>
      {/* )} */}
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to='change-password' className='menu-link px-5'>
          <span className='menu-text'>{intl.formatMessage({id: 'GENERAL.CHANGE_PASSWORD'})}</span>
          <span className='menu-badge'></span>
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to='/' onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: 'GENERAL.SIGN_OUT'})}
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
