import {SalaryListToolbar} from './SalaryListToolbar'
import {SalarysListSearchComponent} from './SalarysListSearchComponent'

const SalarysListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <SalarysListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <SalaryListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SalarysListHeader}
