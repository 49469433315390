import {InvManagementListToolbar} from './InvManagementListToolbar'
import {InvManagementListSearchComponent} from './InvManagementListSearchComponent'

const InvManagementListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <InvManagementListSearchComponent />
      <div className='card-toolbar'>
        <InvManagementListToolbar />
      </div>
    </div>
  )
}

export {InvManagementListHeader}
