import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'

const InventoryCategoryEditModalHeader = () => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>
        {itemIdForUpdate
          ? `${intl.formatMessage({id: 'INVENTORY.EDIT_INVENTORY_CATEGORY'})}`
          : `${intl.formatMessage({id: 'INVENTORY.ADD_INVENTORY_CATEGORY'})}`}
      </h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

export {InventoryCategoryEditModalHeader}
