import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SpecialityListHeader} from './components/header/SpecialityListHeader'
import {SpecialityTable} from './table/SpecialityTable'
import {SpecialityEditModal} from './Speciality-edit-modal/SpecialityEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const SpecialityList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <SpecialityListHeader />
        <SpecialityTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <SpecialityEditModal />}
    </>
  )
}

const SpecialityListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SpecialityList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SpecialityListWrapper}
