import {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_jmh/helpers'
import {getLandingAboutData} from './core/_request'
import {useIntl} from 'react-intl'

const LandingAbout = () => {
  const [aboutData, setAboutData] = useState<any>(null)
  const intl = useIntl()
  const fetchAboutData = () => {
    getLandingAboutData().then((res) => {
      setAboutData(res?.data?.data?.[0])
    })
  }
  useEffect(() => {
    fetchAboutData()
  }, [])

  return (
    <section className='landing_about mt-50 position-relative section-padding' id='about'>
      <div className='landing_about_inner_parallelx'></div>
      <div className='landing_container'>
        <div className='row align-items-center'>
          <div className='col-6'>
            <div className='landing_about_left'>
              <div className='landing_about_back_main'>
                <div
                  className='landing_about_back'
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      '/media/landing_asset/landing_about_back.jpg'
                    )})`,
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                    <h3>{intl.formatMessage({id: 'LANDING_PAGE.ABOUT.TITLE'})}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='landing_about_right_container'>
              <h4>{aboutData?.title}</h4>
              <div className='landing_about_items_container'>
                <div className='row'>
                  <div className='col-4'>
                    <div className='landing_about_item d-flex align-items-center justify-content-center'>
                      <div className='landing_about_item_inner_deco'>
                        <h3>+{aboutData?.clinics}</h3>
                        <p>{intl.formatMessage({id: 'LANDING_PAGE.ABOUT.CLINICS'})}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='landing_about_item d-flex align-items-center justify-content-center'>
                      <div className='landing_about_item_inner_deco'>
                        <h3>+{aboutData?.doctors}</h3>
                        <p>{intl.formatMessage({id: 'GENERAL.DOCTORS'})}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='landing_about_item d-flex align-items-center justify-content-center'>
                      <div className='landing_about_item_inner'>
                        <h2>{intl.formatMessage({id: 'GENERAL.HOURS_OPEN'})}</h2>
                        <p>{intl.formatMessage({id: 'LANDING_PAGE.ABOUT.HOURS_OPEN'})}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <blockquote>{aboutData?.description}</blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingAbout
