import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_jmh/layout/core'
import {useNavigate} from 'react-router-dom'
import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialUser, Salary} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {SalarysListLoading} from '../components/loading/SalarysListLoading'
import {createSalary, getStaffData} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {SalaryStructure} from './SalaryConstant'
import {loginContext} from '../../../../context/context'
import moment from 'moment'
import {toast} from 'react-toastify'

type Props = {
  isUserLoading: boolean
  user: Salary
}

const editUserSchema = Yup.object().shape({
  staff_id: Yup.string().required('Staff is required'),
  salary_year_and_month: Yup.mixed().test(
    'valid-date',
    'Year & Month is required',
    function (value) {
      if (!value) {
        return false // Required field
      }
      const date: any = new Date(value)
      return !isNaN(date) && date instanceof Date
    }
  ),
  gross_salary: Yup.number()
    .required('Gross Salary is required')
    .min(0, 'Gross Salary must be greater than or equal to 0')
    .typeError('Gross Salary is required'),

  professional_tax: Yup.number()
    .required('Professional Tax is required')
    .typeError('Professional Tax is required')
    .min(0, 'Professional Tax must be greater than or equal to 0')
    .test(
      'professional-tax-validation',
      'Professional Tax must be less than the gross salary',
      function (value: any) {
        const grossSalary: any = this.parent.gross_salary
        return value < grossSalary
      }
    ),
  other_allowance: Yup.number()
    .typeError('Other allowance must be a valid number')
    .min(0, 'Other allowance must be greater than or equal to 0'),
  bonus: Yup.number()
    .typeError('Bonus must be a valid number')
    .min(0, 'Bonus must be greater than or equal to 0'),
  incentive: Yup.number()
    .typeError('Incentive must be a valid number')
    .min(0, 'Incentive must be greater than or equal to 0'),
  EPF_deduction: Yup.number()
    .min(0, 'Epf  must be greater than or equal to 0')
    .when('gross_salary', (grossSalary, schema) =>
      grossSalary
        ? schema
            .min(0, 'EPF deduction must be greater than or equal to 0')
            .test(
              'Epf-validation',
              'EPF deduction must be less than the gross salary',
              function (value: any) {
                return value === null || value === undefined || value < grossSalary
              }
            )
        : schema
    ),
})

const iniData = {
  basicSalary: null,
  houseRentAllowance: null,
  medicalInsuranceAllowance: null,
  conveyanceTransportationAllowance: null,
  mealAllowance: null,
  entertainmentAllowance: null,
  variablePay: null,
  epfDeduction: null,
  throughPaySlip: null,
}

const SalaryAddForm: FC<any> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [staffAll, setSatffAll] = useState<any>([])
  const [startDate, setStartDate] = useState<Date | null>()
  const [grossSalary, setGrossSalary] = useState<any>(null)
  const [otherAllowance, setOtherAllowance1] = useState<any>(null)
  const [professionalTax, setProfessionalTax] = useState<any>(null)
  const [calculation, setCalculation] = useState(iniData)
  const [epfDeduction1, setEpfDeduction1] = useState<any>(calculation?.epfDeduction || null)
  const {selectedHospitalId} = useContext(loginContext)

  const [userForEdit] = useState<Salary>({
    ...user,
    staff_id: user?.staff_id || initialUser?.staff_id,
    salary_year_and_month: user?.salary_year_and_month || initialUser?.salary_year_and_month,
    gross_salary: user?.gross_salary || initialUser?.gross_salary,
    basic_salary: user?.basic_salary || initialUser?.basic_salary,
    house_rent_allowance: user?.house_rent_allowance || initialUser?.house_rent_allowance,
    medical_insurance_allowance:
      user?.medical_insurance_allowance || initialUser?.medical_insurance_allowance,
    conveyance_transportation_allowance:
      user?.conveyance_transportation_allowance || initialUser?.conveyance_transportation_allowance,
    meal_allowance: user?.meal_allowance || initialUser?.meal_allowance,
    entertainment_allowance: user?.entertainment_allowance || initialUser?.entertainment_allowance,
    variable_pay: user?.variable_pay || initialUser?.variable_pay,
    other_allowance: user?.other_allowance || initialUser?.other_allowance,
    bonus: user?.bonus || initialUser?.bonus,
    incentive: user?.incentive || initialUser?.incentive,
    through_pay_slip: user?.through_pay_slip || initialUser?.through_pay_slip,
    professional_tax: user?.professional_tax || initialUser?.professional_tax,
    EPF_deduction: user?.EPF_deduction || initialUser?.EPF_deduction,
  })

  const cancel = (withRefresh?: boolean) => {
    window.history.back()
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const getStaff = async () => {
    const allData = await getStaffData()

    const activeData: any =
      allData?.data &&
      allData?.data.filter(
        (item: any) => item?.status === 'ACTIVE' && item?.user?.hospital_id == selectedHospitalId
      )
    setSatffAll(activeData)
  }

  useEffect(() => {
    getStaff()
  }, [selectedHospitalId])

  const handleGrossSalaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    const parsedValue = parseFloat(value)

    if (!isNaN(parsedValue)) {
      setGrossSalary(parsedValue)
    } else {
      setGrossSalary(null)
    }

    formik.setFieldValue('gross_salary', !isNaN(parsedValue) ? parsedValue : null)
  }

  const handleOtherAllowance = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    const parsedValue = parseFloat(value)

    if (!isNaN(parsedValue)) {
      setOtherAllowance1(parsedValue)
    } else {
      setOtherAllowance1(null)
    }

    formik.setFieldValue('other_allowance', parsedValue ? parsedValue : undefined)
  }

  const handleProfessionalTax = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    const parsedValue = parseFloat(value)

    if (!isNaN(parsedValue)) {
      setProfessionalTax(parsedValue)
    } else {
      setProfessionalTax(null)
    }

    formik.setFieldValue('professional_tax', parsedValue ? parsedValue : 0)
  }

  const handleEpfDeduction = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    const parsedValue = parseFloat(value)

    if (!isNaN(parsedValue)) {
      setEpfDeduction1(parsedValue)
    } else {
      setEpfDeduction1(null)
    }

    formik.setFieldValue('EPF_deduction', parsedValue ? parsedValue : 0)
  }

  useEffect(() => {
    const basicSalary: any = grossSalary * SalaryStructure['basic_salary']
    const houseRentAllowance: any = grossSalary * SalaryStructure['house_rent_allowance']
    const medicalInsuranceAllowance: any =
      grossSalary * SalaryStructure['medical_insurance_allowance']
    const conveyanceTransportationAllowance: any =
      grossSalary * SalaryStructure['conveyance_transportation_allowance']
    const mealAllowance: any = grossSalary * SalaryStructure['meal_allowance']
    const entertainmentAllowance: any = grossSalary * SalaryStructure['entertainment_allowance']
    const variablePay: any = grossSalary * SalaryStructure['variable_pay']
    const epfDeduction: any = basicSalary * SalaryStructure['medical_insurance_allowance']

    let throughPaySlip: any

    throughPaySlip =
      basicSalary +
      houseRentAllowance +
      medicalInsuranceAllowance +
      conveyanceTransportationAllowance +
      mealAllowance +
      entertainmentAllowance +
      otherAllowance -
      professionalTax -
      epfDeduction1

    if (grossSalary) {
      setCalculation({
        basicSalary,
        houseRentAllowance,
        medicalInsuranceAllowance,
        conveyanceTransportationAllowance,
        mealAllowance,
        entertainmentAllowance,
        variablePay,
        epfDeduction,
        throughPaySlip,
      })
    }
    setEpfDeduction1(epfDeduction)
  }, [grossSalary])

  useEffect(() => {
    const basicSalary: any = grossSalary * SalaryStructure['basic_salary']
    const houseRentAllowance: any = grossSalary * SalaryStructure['house_rent_allowance']
    const medicalInsuranceAllowance: any =
      grossSalary * SalaryStructure['medical_insurance_allowance']
    const conveyanceTransportationAllowance: any =
      grossSalary * SalaryStructure['conveyance_transportation_allowance']
    const mealAllowance: any = grossSalary * SalaryStructure['meal_allowance']
    const entertainmentAllowance: any = grossSalary * SalaryStructure['entertainment_allowance']
    const variablePay: any = grossSalary * SalaryStructure['variable_pay']
    const epfDeduction: any = basicSalary * SalaryStructure['medical_insurance_allowance']

    let throughPaySlip: any

    throughPaySlip =
      basicSalary +
      houseRentAllowance +
      medicalInsuranceAllowance +
      conveyanceTransportationAllowance +
      mealAllowance +
      entertainmentAllowance +
      otherAllowance -
      professionalTax -
      epfDeduction1

    if (grossSalary) {
      setCalculation({
        basicSalary,
        houseRentAllowance,
        medicalInsuranceAllowance,
        conveyanceTransportationAllowance,
        mealAllowance,
        entertainmentAllowance,
        variablePay,
        epfDeduction,
        throughPaySlip,
      })
    }
    setEpfDeduction1(epfDeduction)
  }, [otherAllowance])

  useEffect(() => {
    const basicSalary: any = grossSalary * SalaryStructure['basic_salary']
    const houseRentAllowance: any = grossSalary * SalaryStructure['house_rent_allowance']
    const medicalInsuranceAllowance: any =
      grossSalary * SalaryStructure['medical_insurance_allowance']
    const conveyanceTransportationAllowance: any =
      grossSalary * SalaryStructure['conveyance_transportation_allowance']
    const mealAllowance: any = grossSalary * SalaryStructure['meal_allowance']
    const entertainmentAllowance: any = grossSalary * SalaryStructure['entertainment_allowance']
    const variablePay: any = grossSalary * SalaryStructure['variable_pay']
    const epfDeduction: any = basicSalary * SalaryStructure['medical_insurance_allowance']

    let throughPaySlip: any

    throughPaySlip =
      basicSalary +
      houseRentAllowance +
      medicalInsuranceAllowance +
      conveyanceTransportationAllowance +
      mealAllowance +
      entertainmentAllowance +
      otherAllowance -
      professionalTax -
      epfDeduction1

    if (grossSalary) {
      setCalculation({
        basicSalary,
        houseRentAllowance,
        medicalInsuranceAllowance,
        conveyanceTransportationAllowance,
        mealAllowance,
        entertainmentAllowance,
        variablePay,
        epfDeduction,
        throughPaySlip,
      })
    }
    setEpfDeduction1(epfDeduction)
  }, [professionalTax])

  useEffect(() => {
    const basicSalary: any = grossSalary * SalaryStructure['basic_salary']
    const houseRentAllowance: any = grossSalary * SalaryStructure['house_rent_allowance']
    const medicalInsuranceAllowance: any =
      grossSalary * SalaryStructure['medical_insurance_allowance']
    const conveyanceTransportationAllowance: any =
      grossSalary * SalaryStructure['conveyance_transportation_allowance']
    const mealAllowance: any = grossSalary * SalaryStructure['meal_allowance']
    const entertainmentAllowance: any = grossSalary * SalaryStructure['entertainment_allowance']
    const variablePay: any = grossSalary * SalaryStructure['variable_pay']
    const epfDeduction: any = basicSalary * SalaryStructure['medical_insurance_allowance']

    let throughPaySlip: any

    throughPaySlip =
      basicSalary +
      houseRentAllowance +
      medicalInsuranceAllowance +
      conveyanceTransportationAllowance +
      mealAllowance +
      entertainmentAllowance +
      otherAllowance -
      professionalTax -
      epfDeduction1

    if (grossSalary) {
      setCalculation({
        basicSalary,
        houseRentAllowance,
        medicalInsuranceAllowance,
        conveyanceTransportationAllowance,
        mealAllowance,
        entertainmentAllowance,
        variablePay,
        epfDeduction,
        throughPaySlip,
      })
    }
  }, [epfDeduction1])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const dateStr = values?.salary_year_and_month
      const date = moment(dateStr)
      const month = date.format('MM') // Full month name (e.g., January)
      const year = date.format('YYYY') // 4-digit year (e.g., 2023)

      values['basic_salary'] = Number(calculation?.basicSalary)
      values['conveyance_transportation_allowance'] = Number(
        calculation?.conveyanceTransportationAllowance
      )
      values['bonus'] = Number(values?.bonus)
      values['entertainment_allowance'] = Number(calculation?.entertainmentAllowance)
      values['hospital_id'] = Number(selectedHospitalId)
      values['house_rent_allowance'] = Number(calculation?.houseRentAllowance)
      values['incentive'] = Number(values?.incentive)
      values['meal_allowance'] = Number(calculation?.mealAllowance)
      values['medical_insurance_allowance'] = Number(calculation?.medicalInsuranceAllowance)
      values['other_allowance'] = Number(values?.other_allowance)
      values['salary_month'] = Number(month)
      values['salary_year'] = Number(year)
      values['staff_id'] = Number(values?.staff_id)
      values['through_pay_slip'] = Number(calculation?.throughPaySlip)
      values['variable_pay'] = Number(calculation?.variablePay)
      values['EPF_deduction'] = epfDeduction1

      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          // await updateSalary(values)
        } else {
          delete values?.id
          delete values?.initials
          delete values?.salary_year_and_month
          await createSalary(values).then((res: any) => {
            toast.success(res?.data?.message)
            navigate('/staff/salary-structure')
          })
        }
      } catch (ex: any) {
        toast.error(ex.response.data.message)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleBackClick = () => {
    window.history.back()
  }

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ADD.EMPLOYEE.SALARY'})}</PageTitle>
      <div>
        <div className='card card-custom card-flush h-auto'>
          <div className='card-header justify-content-end'>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </button>
            </div>
          </div>
          <div className='card-body  py-5'>
            <form id='kt_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='d-flex flex-column me-n7 pe-7' id='kt_add_user_scroll'>
                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.SELECT.STAFF.MEMBER'})}
                      </label>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        {...formik.getFieldProps('staff_id')}
                      >
                        <option>
                          {intl.formatMessage({id: 'SALARY.STRUCTURE.SELECT.STAFF.MEMBER'})}
                        </option>
                        {staffAll &&
                          staffAll?.map((item: any) => {
                            return (
                              <option value={item?.id} key={item?.id}>
                                {item?.full_name}
                              </option>
                            )
                          })}
                      </select>

                      {formik.touched.staff_id && formik.errors.staff_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.staff_id}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-3'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.SALARY.MONTH.YEAR'})}
                      </label>
                      <div className='position-relative'>
                        <DatePicker
                          showMonthYearPicker
                          {...formik.getFieldProps('salary_year_and_month')}
                          dropdownMode='select'
                          className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer bg-white border-secondary'
                          dateFormat='MM/yyyy'
                          name='salary_year_and_month'
                          selected={startDate}
                          onChange={(date) => {
                            formik.setFieldValue('salary_year_and_month', date)
                            setStartDate(date)
                          }}
                          autoComplete='off'
                          onKeyDown={(e) => {
                            e.preventDefault()
                          }}
                          disabled={formik.isSubmitting || isUserLoading}
                          placeholderText={intl.formatMessage({
                            id: 'SALARY.STRUCTURE.SALARY.MONTH.YEAR',
                          })}
                        />
                        <span
                          className='DatePicker_icon cursor-pointer'
                          onClick={() =>
                            document.getElementsByName('salary_year_and_month')[0].focus()
                          }
                        >
                          <div className='fa fa-calendar'></div>
                        </span>
                      </div>
                      {formik.touched.salary_year_and_month && formik.errors.salary_year_and_month && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.salary_year_and_month}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({
                          id: 'SALARY.STRUCTURE.ADD.GROSS.SALARY',
                        })}
                      </label>

                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.TOTAL.SALARY',
                        })}
                        {...formik.getFieldProps('gross_salary')}
                        type='number'
                        name='gross_salary'
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isUserLoading}
                        onChange={handleGrossSalaryChange}
                        onKeyPress={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                            e.preventDefault()
                          }
                        }}
                      />
                      {formik.touched.gross_salary && formik.errors.gross_salary && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.gross_salary}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.BASIC.SALARY.50%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.BASIC.SALARY'})}
                        {...formik.getFieldProps('basic_salary')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        value={calculation?.basicSalary ? calculation?.basicSalary : ''}
                        type='text'
                        name='basic_salary'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.HOUSE.RENT.ALLOWANCE.13%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.HOUSE.RENT.ALLOWANCE',
                        })}
                        {...formik.getFieldProps('house_rent_allowance')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        value={
                          calculation?.houseRentAllowance ? calculation?.houseRentAllowance : ''
                        }
                        type='text'
                        name='house_rent_allowance'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.MEDICAL.ALLOWANCE.12%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.MEDICAL.ALLOWANCE',
                        })}
                        {...formik.getFieldProps('medical_insurance_allowance')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        value={
                          calculation?.medicalInsuranceAllowance
                            ? calculation?.medicalInsuranceAllowance
                            : ''
                        }
                        type='text'
                        name='medical_insurance_allowance'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.CONVEYANCE.ALLOWANCE.11%'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.CONVEYANCE.ALLOWANCE',
                        })}
                        {...formik.getFieldProps('conveyance_transportation_allowance')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='text'
                        name='conveyance_transportation_allowance'
                        autoComplete='off'
                        value={
                          calculation?.conveyanceTransportationAllowance
                            ? calculation?.conveyanceTransportationAllowance
                            : ''
                        }
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.MEAL.ALLOWANCE.9%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.MEAL.ALLOWANCE'})}
                        {...formik.getFieldProps('meal_allowance')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='text'
                        name='meal_allowance'
                        autoComplete='off'
                        value={calculation?.mealAllowance ? calculation?.mealAllowance : ''}
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.ENTERTAINMENT.ALLOWANCE.5%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.ENTERTAINMENT.ALLOWANCE',
                        })}
                        {...formik.getFieldProps('entertainment_allowance')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='text'
                        name='entertainment_allowance'
                        autoComplete='off'
                        value={
                          calculation?.entertainmentAllowance
                            ? calculation?.entertainmentAllowance
                            : ''
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.VARIABLE.PAY.10%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.VARIABLE.PAY'})}
                        {...formik.getFieldProps('variable_pay')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        value={calculation?.variablePay ? calculation?.variablePay : ''}
                        type='text'
                        name='variable_pay'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.OTHER.ALLOWANCE'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.OTHER.ALLOWANCE'})}
                        {...formik.getFieldProps('other_allowance')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        name='other_allowance'
                        autoComplete='off'
                        // onChange={(e: any) => {
                        //   formik.setFieldValue('other_allowance', Number(e.target.value))
                        //   setOtherAllowance1(Number(e.target.value))
                        // }}
                        onChange={handleOtherAllowance}
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.other_allowance && formik.errors.other_allowance && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.other_allowance}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.BONUS'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.BONUS'})}
                        {...formik.getFieldProps('bonus')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        name='bonus'
                        autoComplete='off'
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.bonus && formik.errors.bonus && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.bonus}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.INCENTIVE'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.INCENTIVE'})}
                        {...formik.getFieldProps('incentive')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        name='incentive'
                        autoComplete='off'
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.incentive && formik.errors.incentive && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.incentive}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.PAY.THROUGH.SLIP'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.PAY.THROUGH.SLIP'})}
                        {...formik.getFieldProps('through_pay_slip')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='text'
                        value={calculation?.throughPaySlip ? calculation?.throughPaySlip : ''}
                        name='through_pay_slip'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.PROFESSIONAL.TAX'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.PROFESSIONAL.TAX'})}
                        {...formik.getFieldProps('professional_tax')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        name='professional_tax'
                        autoComplete='off'
                        onChange={handleProfessionalTax}
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.professional_tax && formik.errors.professional_tax && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.professional_tax}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.EPF.DEDUCTION.12%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.EPF.DEDUCTION'})}
                        {...formik.getFieldProps('EPF_deduction')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='text'
                        name='EPF_deduction'
                        autoComplete='off'
                        value={epfDeduction1 || ''}
                        onChange={handleEpfDeduction}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.EPF_deduction && formik.errors.EPF_deduction && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.EPF_deduction}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => cancel()}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                  disabled={formik.isSubmitting || isUserLoading}
                >
                  {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={
                    isUserLoading ||
                    formik.isSubmitting ||
                    !formik.isValid ||
                    !formik.touched ||
                    !formik.dirty
                  }
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'GENERAL.SAVE'})}
                  </span>
                  {(formik.isSubmitting || isUserLoading) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
            {(formik.isSubmitting || isUserLoading) && <SalarysListLoading />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalaryAddForm
