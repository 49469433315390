import {useContext} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const PatientUploadDocListToolbar = () => {
  const intl = useIntl()
  const {selectedAppointment} = useContext(loginContext)
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const {loginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient') {
      return item?.module_id
    }
  })

  const add = data?.filter((item: any) => {
    if (item.permission.method === 'POST' && item.permission.route === 'upload-document') {
      return item
    }
  })

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <SpecialityListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {add.length ? (
        <button
          type='button'
          className='btn btn-primary'
          onClick={openAddUserModal}
          disabled={selectedAppointment?.value == 'all'}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'PATIENT_DOCS.ADD.ATTACHMENT'})}
        </button>
      ) : (
        ''
      )}
    </div>
  )
}

export {PatientUploadDocListToolbar}
