export const SalaryStructure = {
  basic_salary: 0.5,
  house_rent_allowance: 0.13,
  medical_insurance_allowance: 0.12,
  conveyance_transportation_allowance: 0.11,
  meal_allowance: 0.09,
  entertainment_allowance: 0.05,
  variable_pay: 0.1,
}

export const calculateSalaryPayrollComponents = (
  grossSalary: any,
  professionalTax: any,
  bonus: any,
  incentive: any,
  employeeWorkingDays: any,
  pl: any,
  upl: any,
  lc: any,
  wd?: any,
  epf?: any
) => {
  const SalaryStructure = {
    basic_salary: 0.5,
    house_rent_allowance: 0.13,
    medical_insurance_allowance: 0.12,
    conveyance_transportation_allowance: 0.11,
    meal_allowance: 0.09,
    entertainment_allowance: 0.05,
  }

  const upl1 = upl || 0
  const pl1 = pl || 0
  const employeeWorkingDays1 = employeeWorkingDays
  const perDay = grossSalary / Number(wd)
  const paid = (pl1 + employeeWorkingDays1) * perDay

  const basicSalary =
    paid * SalaryStructure['basic_salary']
      ? paid * SalaryStructure['basic_salary']
      : grossSalary * SalaryStructure['basic_salary']
  const houseRentAllowance = grossSalary * SalaryStructure['house_rent_allowance']
  const medicalInsuranceAllowance = grossSalary * SalaryStructure['medical_insurance_allowance']
  const conveyanceTransportationAllowance =
    grossSalary * SalaryStructure['conveyance_transportation_allowance']
  const mealAllowance = grossSalary * SalaryStructure['meal_allowance']
  const entertainmentAllowance = grossSalary * SalaryStructure['entertainment_allowance']
  const bonus1 = bonus || 0
  const incentive1 = incentive || 0
  const professionalTax1 = professionalTax

  let epfDeduction1: any
  if (epf) {
    epfDeduction1 = Number(epf) ? Number(epf) : 0
  } else if (epf === '' || epf === 0) {
    epfDeduction1 = 0
  } else {
    epfDeduction1 =
      epf === null ? null : basicSalary * SalaryStructure['medical_insurance_allowance']
  }

  const Unpaid = upl1 * perDay

  const leaveEncashment1 = lc || 0
  const payableSalary =
    paid + bonus1 + incentive1 + leaveEncashment1 - professionalTax - epfDeduction1

  return {
    basicSalary,
    houseRentAllowance,
    medicalInsuranceAllowance,
    conveyanceTransportationAllowance,
    mealAllowance,
    entertainmentAllowance,
    bonus1,
    incentive1,
    professionalTax1,
    employeeWorkingDays1,
    epfDeduction1,
    upl1,
    pl1,
    Unpaid,
    leaveEncashment1,
    payableSalary,
    wd,
  }
}
