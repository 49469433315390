import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {UpdateHospital} from './core/_requests'
import {isNotEmpty, KTSVG} from '../../../_jmh/helpers'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {CardProps} from './core/_models'
import {useIntl} from 'react-intl'

interface EditCardModalProps {
  showModal: boolean
  cardData: CardProps
  onSave: (data: any) => void
  onClose: () => void
}

const getToken: any = localStorage.getItem('kt-auth-react-v')
const updateToken = JSON.parse(getToken)

const HospitalModal: React.FC<EditCardModalProps> = ({showModal, cardData, onSave, onClose}) => {
  const intl = useIntl()
  const [preview, setPreview] = useState('')
  const [formChanged, setFormChanged] = useState(false)

  const handleImageChange = (event: any, formik: any) => {
    const file = event.currentTarget.files[0]

    formik.setFieldValue('logo', file)
    setFormChanged(true)

    setPreview(URL.createObjectURL(file))
  }

  const handleRemoveImage = () => {
    if (!formik.values.logo && cardData.cardData.logo) {
      formik.setFieldValue('logo', cardData.cardData.logo)
    }

    setFormChanged(true)

    setPreview('')
    formik.setFieldValue('logo', null)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .required(intl.formatMessage({id: 'VALIDATION.REQ.HOSPITAL_NAME'}))
      .matches(/^[A-Za-z\s]+$/, intl.formatMessage({id: 'VALIDATION.TEXT_INPUT'})),
    address: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .required(intl.formatMessage({id: 'VALIDATION.REQ.HOSPITAL_ADDRESS'})),
    contact_person: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .required(intl.formatMessage({id: 'VALIDATION.REQ.CONTACT_PERSON_NAME'}))
      .matches(/^[A-Za-z\s]+$/, intl.formatMessage({id: 'VALIDATION.TEXT_INPUT'})),
    contact_number: Yup.string()
      .matches(/^\d{10}$/, intl.formatMessage({id: 'VALIDATION.CONTACT.NUMBER'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQ.CONTACT.NUMBER'})),
  })

  const formik = useFormik({
    initialValues: {
      name: cardData.cardData.name,
      address: cardData.cardData.address,
      contact_person: cardData.cardData.contact_person,
      contact_number: cardData.cardData.contact_number,
      id: cardData.cardData.id,
      logo: cardData.cardData.logo,
    },
    validationSchema,

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const data = new FormData()
          data.append('name', values?.name as string)
          data.append('address', values?.address as string)
          data.append('contact_person', values?.contact_person as string)
          data.append('contact_number', values?.contact_number as string)
          if (values.logo instanceof File) {
            data.append('logo', values.logo)
          }

          await UpdateHospital(data, values.id, updateToken?.access_token).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (error: any) {
        if (error.response.data.status) {
          toast.error(error.response.data.status)
        }
      } finally {
        setPreview('')
        setSubmitting(true)
        formik.resetForm()
      }
      onSave(values)
    },
  })

  const handleModalClose = () => {
    formik.resetForm()
    onClose()
  }

  useEffect(() => {
    const formValues = formik.values
    const isFormChanged =
      formValues.name !== cardData.cardData.name ||
      formValues.address !== cardData.cardData.address ||
      formValues.contact_person !== cardData.cardData.contact_person ||
      formValues.contact_number !== cardData.cardData.contact_number ||
      (formik.values.logo instanceof File && formValues.logo !== cardData.cardData.logo)

    setFormChanged(isFormChanged)
  }, [formik.values, cardData.cardData])

  return (
    <>
      <div
        className={`modal  ${showModal ? 'show' : ''}`}
        id={`kt_modal_${cardData.cardData.id}`}
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        style={{display: showModal ? 'block' : 'none'}}
      >
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header  '>
              <h3 className='modal-title'>
                {intl.formatMessage({id: 'HOSPITAL.EDIT.HOSPITAL.DETAILS'})}
              </h3>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={handleModalClose}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body mx-5 mx-xl-10 my-7'>
              <form onSubmit={formik.handleSubmit} noValidate encType='multipart/form-data'>
                <div className='fv-row mb-7'>
                  <label className='d-block fw-bold fs-6 mb-5'>
                    {intl.formatMessage({id: 'GENERAL.PROFILE'})}
                  </label>
                  <div className='image-input image-input-outline' data-kt-image-input='true'>
                    {preview || cardData?.cardData?.logo ? (
                      <div
                        className='image-input-wrapper w-100px h-100px'
                        style={{backgroundImage: `url('${preview || cardData?.cardData?.logo}')`}}
                      ></div>
                    ) : (
                      <div className='image-input-wrapper w-100px h-100px'></div>
                    )}
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      title='Change avatar'
                    >
                      <i className='bi bi-pencil-fill fs-7'></i>
                      <input
                        key={preview}
                        type='file'
                        name='logo'
                        id='logo'
                        accept='image/png, image/jpg, image/jpeg'
                        onChange={(event) => handleImageChange(event, formik)}
                      />
                      <input type='hidden' name='logo' value={cardData?.cardData?.logo} />
                    </label>
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      title='Cancel avatar'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                    {preview !== '' ? (
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        title='Remove avatar'
                        id='logo'
                        onClick={handleRemoveImage}
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='form-text'>
                    {intl.formatMessage({id: 'GENERAL.ALLOWED.FILE.TYPES'})}
                  </div>
                </div>
                <div className='fv-row mb-10'>
                  <label htmlFor='name' className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'HOSPITAL.HOSPITAL.NAME'})}
                  </label>
                  <input
                    placeholder={intl.formatMessage({id: 'HOSPITAL.ENTER.HOSPITAL.NAME'})}
                    {...formik.getFieldProps('name')}
                    className={clsx('form-control form-control-lg form-control-solid')}
                    type='text'
                    name='name'
                    autoComplete='off'
                    value={formik.values.name}
                    onKeyPress={(e) => {
                      if (!/[A-Za-z\s]/.test(e.key)) {
                        e.preventDefault()
                      }
                    }}
                  />

                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <label htmlFor='address' className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'HOSPITAL.ADDRESS'})}
                  </label>
                  <textarea
                    placeholder={intl.formatMessage({id: 'HOSPITAL.ENTER.HOSPITAL.ADDRESS'})}
                    {...formik.getFieldProps('address')}
                    className={clsx('form-control form-control-lg form-control-solid no-resize')}
                    name='address'
                    autoComplete='off'
                    value={formik.values.address}
                  />

                  {formik.touched.address && formik.errors.address && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.address}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='row'>
                  <div className='mb-10 col-12 col-sm-6'>
                    <label htmlFor='contact_person' className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'HOSPITAL.CONTACT.PERSON.NAME'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({id: 'HOSPITAL.ENTER.CONTACT.PERSON.NAME'})}
                      {...formik.getFieldProps('contact_person')}
                      className={clsx('form-control form-control-lg form-control-solid')}
                      type='text'
                      name='contact_person'
                      autoComplete='off'
                      value={formik.values.contact_person}
                      onKeyPress={(e) => {
                        if (!/[A-Za-z\s]/.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />

                    {formik.touched.contact_person && formik.errors.contact_person && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.contact_person}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-10 col-12 col-sm-6'>
                    <label htmlFor='contactNumber' className='form-label fs-6 fw-bolder text-dark'>
                      {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({id: 'HOSPITAL.ENTER.CONTACT.NUMBER'})}
                      {...formik.getFieldProps('contact_number')}
                      className={clsx('form-control form-control-lg form-control-solid')}
                      type='text'
                      name='contact_number'
                      autoComplete='off'
                      value={formik.values.contact_number}
                      onChange={formik.handleChange}
                      onKeyPress={(e) => {
                        if (/[A-Za-z\s]/.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.contact_number && formik.errors.contact_number && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.contact_number}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-end'>
                  <button
                    type='reset'
                    // onClick={() => cancel()}
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    onClick={handleModalClose}
                  >
                    {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                  </button>
                  <button
                    type='submit'
                    id='update_hospital'
                    className='btn btn-lg btn-primary ms-5'
                    disabled={formik.isSubmitting || !formik.isValid || !formChanged}
                  >
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'GENERAL.SAVE'})}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HospitalModal
