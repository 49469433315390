import {FC, useContext, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTSVG, toAbsoluteUrl} from '../../../../../_jmh/helpers'
import {initialUser, MedicalBackground} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {MedicalBackgroundListLoading} from '../components/loading/MedicalBackgroundListLoading'
import {updateMedicalBackground} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useParams} from 'react-router-dom'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: MedicalBackground
}

interface EditCardModalProps {
  showModal: boolean
  cardData: MedicalBackground
  onSave: (data: any) => void
  onClose: () => void
  isUserLoading: boolean
}

const editUserSchema = Yup.object().shape({})

const MedicalBackgroundEditModalForm: FC<EditCardModalProps> = ({
  showModal,
  onSave,
  cardData,
  onClose,
  isUserLoading,
}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {selectedPatientId} = useContext(loginContext)
  const {id} = useParams()
  const modalRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<any>(null)
  const [medicalInputValue, setMedicalInputValue] = useState<any>('')
  const [familyInputValue, setFamilyInputValue] = useState<any>('')
  const [ongoingInputValue, setOngoingInputValue] = useState<any>('')
  const [allergiesInputValue, setAllergiesInputValue] = useState<any>('')
  const [habitInputValue, setHabitInputValue] = useState<any>('')
  const [surgeriesInputValue, setSurgeriesInputValue] = useState<any>('')
  const [immunizationInputValue, setImmunizationInputValue] = useState<any>('')

  // Medical History
  const [medicalHistorytags, setMedicalHistoryTags] = useState<any>([])
  const defaultMedicalHistoryMain = [
    'Diabetes',
    'High Blood Pressure',
    'Heart Disease',
    'High Cholesterol',
    'Dipression/Anxiety',
    'Hyperthyroidism',
    'Stroke',
    'Cancer',
    'Kidney Disease',
    'Migraine',
    'Artthritis',
    'Emphysema',
    'Drug abuse',
    'Epilepsy',
  ]
  const [defaultMedicalHistory, setDefaultMedicalHistory] = useState<any>(defaultMedicalHistoryMain)

  // Family History
  const [familyHistorytags, setFamilyHistoryTags] = useState<any>([])
  const defaultFamilyHistoryMain = [
    'High Cholesterol',
    'Heart Attack',
    'Cancer',
    'Stroke',
    'Genetic Disease ',
    'Osteoporosis',
    'Mental illness',
    'Dementia',
    'Epilepsy/Seizures',
    'Diabetes',
    'High Blood Pressure',
    'Heart Disease',
  ]
  const [defaultFamilyHistory, setDefaultFamilyHistory] = useState<any>(defaultFamilyHistoryMain)

  // Ongoing medication
  const [onGoingtags, setOnGoingTags] = useState<any>([])
  const defaultOnGoingMain = [
    'Diabetes',
    'High Blood Pressure',
    'Heart Disease',
    'High Cholesterol',
    'Stroke',
    'Cancer',
  ]
  const [defaultOnGoing, setDefaultOnGoing] = useState<any>(defaultOnGoingMain)

  // Allergies
  const [allergytags, setAllergyTags] = useState<any>([])
  const defaultAllergyMain = [
    'Pollen Allery',
    'Dust Mite Allergy',
    'Pet Allergy',
    'Mold Allergy',
    'Food Allergy',
    'Insect Sting Allergy',
    'Latex Allergy',
    'Medication Allergy',
    'Cosmetics and Fragrance Allergies',
  ]
  const [defaultAllergy, setDefaultAllergy] = useState<any>(defaultAllergyMain)

  // Habit
  const [habitTags, setHabitTags] = useState<any>([])
  const defaultHabitMain = [
    'Smoking',
    'Alcohol Consumption',
    'Substance use',
    'Physical Activity',
    'Depression',
    'Sleep Problems',
  ]
  const [defaultHabit, setDefaultHabit] = useState<any>(defaultHabitMain)

  // surgeries
  const [surgeryTags, setSurgeryTags] = useState<any>([])
  const defaultSurgeryMain = [
    'Appendectomy',
    'Cholecystectomy',
    'Hernia repair',
    'Mastectomy',
    'Cataract surgery',
    'Coronary artery bypass graft (CABG) surgery',
    'Knee replacement surgery',
    'Hip replacement surgery',
    'Hysterectomy',
    'Prostatectomy',
    'Tonsillectomy',
    'Rhinoplasty',
    'Gastric bypass surgery',
    'Cesarean section (C-section)',
    'Spinal fusion surgery',
    'Kidney transplant',
    'Lung resection',
    'Open-heart surgery',
  ]
  const [defaultSurgery, setDefaultSurgery] = useState<any>(defaultSurgeryMain)

  //immunization

  const [immunizationTags, setImmunizationTags] = useState<any>([])
  const defaultImmunizationMain = [
    'Hepatitis B',
    'Rotavirus',
    'Diphtheria',
    'Tetanus',
    'Pertussis',
    'Haemophilus influenzae type b',
    'Pneumococcal conjugate (PCV13)',
    'Inactivated poliovirus (IPV)',
    'Influenza, Measles',
    'Mumps',
    'Rubella',
    'chickenpox',
    'Polio',
    'Hepatitis A',
    'Meningococcal conjugate',
    'Human papillomavirus (HPV)',
    'Meningococcal B',
  ]
  const [defaultImmunization, setDefaultImmunization] = useState<any>(defaultImmunizationMain)

  const [isTagChanged, setTagChanged] = useState(false)

  const handleMedicalHistoryTagClick = (tag: any) => {
    setMedicalHistoryTags([...medicalHistorytags, tag])

    setDefaultMedicalHistory(defaultMedicalHistory.filter((value: any) => value !== tag))
    setTagChanged(true)
  }

  const handleMedicalHisoryTagRemove = (tag: any) => {
    setMedicalHistoryTags(medicalHistorytags.filter((t: any) => t !== tag))
    if (defaultMedicalHistoryMain.includes(tag)) {
      const updatedDefaultMedicalHistory = Array.from(new Set([...defaultMedicalHistory, tag]))
      setDefaultMedicalHistory(updatedDefaultMedicalHistory)
    }
    setTagChanged(true)
  }
  const defaultMedicalHistoryFiltered = defaultMedicalHistory.filter(
    (value: any) => !medicalHistorytags.includes(value)
  )

  const handleFamilyHistoryTagClick = (tag: any) => {
    setFamilyHistoryTags([...familyHistorytags, tag])
    setDefaultFamilyHistory(defaultMedicalHistory.filter((value: any) => value !== tag))
    setTagChanged(true)
  }

  const handleFamilyHisoryTagRemove = (tag: any) => {
    setFamilyHistoryTags(familyHistorytags.filter((t: any) => t !== tag))
    if (defaultFamilyHistoryMain.includes(tag)) {
      const updatedDefaultFamilyHistory = Array.from(new Set([...defaultFamilyHistory, tag]))
      setDefaultFamilyHistory(updatedDefaultFamilyHistory)
    }
    setTagChanged(true)
  }
  const defaultFamilyHistoryFiltered = defaultFamilyHistory.filter(
    (value: any) => !familyHistorytags.includes(value)
  )

  const handleOnGoingTagClick = (tag: any) => {
    setOnGoingTags([...onGoingtags, tag])
    setDefaultOnGoing(defaultOnGoing.filter((value: any) => value !== tag))
    setTagChanged(true)
  }
  const handleOnGoingTagRemove = (tag: any) => {
    setOnGoingTags(onGoingtags.filter((t: any) => t !== tag))
    if (defaultOnGoingMain.includes(tag)) {
      const updatedDefaultOnGoing = Array.from(new Set([...defaultOnGoing, tag]))
      setDefaultOnGoing(updatedDefaultOnGoing)
    }
    setTagChanged(true)
  }
  const defaultOnGoingFiltered = defaultOnGoing.filter((value: any) => !onGoingtags.includes(value))

  const handleAllergyTagClick = (tag: any) => {
    setAllergyTags([...allergytags, tag])
    setDefaultAllergy(defaultAllergy.filter((value: any) => value !== tag))
    setTagChanged(true)
  }
  const handleAllergyTagRemove = (tag: any) => {
    setAllergyTags(allergytags.filter((t: any) => t !== tag))
    if (defaultAllergyMain.includes(tag)) {
      const updatedDefaultAllergy = Array.from(new Set([...defaultAllergy, tag]))
      setDefaultAllergy(updatedDefaultAllergy)
    }
    setTagChanged(true)
  }
  const defaultAllergyFiltered = defaultAllergy.filter((value: any) => !allergytags.includes(value))

  const handleHabitTagClick = (tag: any) => {
    setHabitTags([...habitTags, tag])
    setDefaultHabit(defaultHabit.filter((value: any) => value !== tag))
    setTagChanged(true)
  }
  const handleHabitTagRemove = (tag: any) => {
    setHabitTags(habitTags.filter((t: any) => t !== tag))
    if (defaultHabitMain.includes(tag)) {
      const updatedDefaultHabit = Array.from(new Set([...defaultHabit, tag]))
      setDefaultHabit(updatedDefaultHabit)
    }
    setTagChanged(true)
  }
  const defaultHabitFiltered = defaultHabit.filter((value: any) => !allergytags.includes(value))

  const handleSurgeryTagClick = (tag: any) => {
    setSurgeryTags([...surgeryTags, tag])
    setDefaultSurgery(defaultSurgery.filter((value: any) => value !== tag))
    setTagChanged(true)
  }
  const handleSurgeryTagRemove = (tag: any) => {
    setSurgeryTags(surgeryTags.filter((t: any) => t !== tag))
    if (defaultSurgeryMain.includes(tag)) {
      const updatedDefaultSurgery = Array.from(new Set([...defaultSurgery, tag]))
      setDefaultSurgery(updatedDefaultSurgery)
    }
    setTagChanged(true)
  }
  const defaultSurgeryFiltered = defaultSurgery.filter((value: any) => !surgeryTags.includes(value))

  const handleImmunizationTagClick = (tag: any) => {
    setImmunizationTags([...immunizationTags, tag])
    setDefaultImmunization(defaultImmunization.filter((value: any) => value !== tag))
    setTagChanged(true)
  }
  const handleImmunizationTagRemove = (tag: any) => {
    setImmunizationTags(immunizationTags.filter((t: any) => t !== tag))
    if (defaultImmunizationMain.includes(tag)) {
      const updatedDefaultImmunization = Array.from(new Set([...defaultImmunization, tag]))
      setDefaultImmunization(updatedDefaultImmunization)
    }
    setTagChanged(true)
  }
  const defaultImmunizationFiltered = defaultImmunization.filter(
    (value: any) => !immunizationTags.includes(value)
  )

  useEffect(() => {
    if (cardData?.id && cardData?.patient_id?.id == selectedPatientId) {
      // setUserForEdit(cardData)
      setMedicalHistoryTags([])
      setFamilyHistoryTags([])
      setOnGoingTags([])
      setAllergyTags([])
      setHabitTags([])
      setSurgeryTags([])
      setImmunizationTags([])
      if (cardData.medical_history) {
        const medicalHistoryArray = cardData.medical_history.split(',')
        setMedicalHistoryTags(medicalHistoryArray)
      }

      if (cardData.family_history) {
        const familyHistoryArray = cardData.family_history.split(',')
        setFamilyHistoryTags(familyHistoryArray)
      }
      if (cardData.ongoing_medication) {
        const onGoingArray = cardData.ongoing_medication.split(',')
        setOnGoingTags(onGoingArray)
      }
      if (cardData.allergies) {
        const allergyArray = cardData.allergies.split(',')
        setAllergyTags(allergyArray)
      }
      if (cardData.habit_history) {
        const habitArray = cardData.habit_history.split(',')
        setHabitTags(habitArray)
      }
      if (cardData.surgeries) {
        const surgeryArray = cardData.surgeries.split(',')
        setSurgeryTags(surgeryArray)
      }
      if (cardData.immunization) {
        const immunizationArray = cardData.immunization.split(',')
        setImmunizationTags(immunizationArray)
      }
    } else {
      setMedicalHistoryTags([])
      setFamilyHistoryTags([])
      setOnGoingTags([])
      setAllergyTags([])
      setHabitTags([])
      setSurgeryTags([])
      setImmunizationTags([])
      // setUserForEdit(initialUser)
    }
  }, [
    selectedPatientId,
    cardData.medical_history,
    cardData.family_history,
    cardData.ongoing_medication,
    cardData.allergies,
    cardData.habit_history,
    cardData.surgeries,
    cardData.immunization,
  ])

  const cancel = (withRefresh?: boolean) => {
    onClose()
    if (withRefresh) {
      refetch()
    }
    setTagChanged(false)
    setItemIdForUpdate(undefined)

    //To Reset Form After something Edit
    setMedicalHistoryTags(cardData.medical_history ? cardData.medical_history.split(',') : [])
    setFamilyHistoryTags(cardData.family_history ? cardData.family_history.split(',') : [])
    setOnGoingTags(cardData.ongoing_medication ? cardData.ongoing_medication.split(',') : [])
    setAllergyTags(cardData.allergies ? cardData.allergies.split(',') : [])
    setHabitTags(cardData.habit_history ? cardData.habit_history.split(',') : [])
    setSurgeryTags(cardData.surgeries ? cardData.surgeries.split(',') : [])
    setImmunizationTags(cardData.immunization ? cardData.immunization.split(',') : [])

    //Re-init Tags on click close
    setDefaultMedicalHistory(defaultMedicalHistoryMain)
    setDefaultFamilyHistory(defaultFamilyHistoryMain)
    setDefaultOnGoing(defaultOnGoingMain)
    setDefaultAllergy(defaultAllergyMain)
    setDefaultHabit(defaultHabitMain)
    setDefaultSurgery(defaultSurgeryMain)
    setDefaultImmunization(defaultImmunizationMain)

    setMedicalInputValue('')
    setFamilyInputValue('')
    setOngoingInputValue('')
    setAllergiesInputValue('')
    setHabitInputValue('')
    setSurgeriesInputValue('')
    setImmunizationInputValue('')
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialUser,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        values['medical_history'] = medicalHistorytags.join(',')
        values['family_history'] = familyHistorytags.join(',')
        values['ongoing_medication'] = onGoingtags.join(',')
        values['allergies'] = allergytags.join(',')
        values['habit_history'] = habitTags.join(',')
        values['surgeries'] = surgeryTags.join(',')
        values['immunization'] = immunizationTags.join(',')

        await updateMedicalBackground({
          ...values,
          patient_id: Number(selectedPatientId ? selectedPatientId : id),
        }).then((res: any) => {
          toast.success(res?.data?.message)
        })
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
        setTagChanged(false)
        cancel(true)
      }

      onSave(values)
      // Remove Background Effect of Modal
      const modalBackdrop = document.querySelector('.modal-backdrop.show')
      if (modalBackdrop && modalBackdrop.parentNode) {
        modalBackdrop.parentNode.removeChild(modalBackdrop)
      }
      document.body.classList.remove('modal-open')

      if (!document.body.classList.contains('modal-open')) {
        // Remove "overflow:hidden" style from body element
        document.body.style.overflow = ''
      }
    },
  })

  //set input value in custom tag
  const handleMedicalInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMedicalInputValue(e.target.value)
  }
  const handleFamilyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFamilyInputValue(e.target.value)
  }
  const handleOngoingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOngoingInputValue(e.target.value)
  }
  const handleAllergiesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAllergiesInputValue(e.target.value)
  }
  const handleHabitInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHabitInputValue(e.target.value)
  }
  const handleSurgeryInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSurgeriesInputValue(e.target.value)
  }
  const handleImmunizationInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImmunizationInputValue(e.target.value)
  }

  // create tag of input value from keyboard and set Save button enable on create
  const handleMedicalHistoryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (medicalInputValue.trim() !== '') {
        setMedicalHistoryTags([...medicalHistorytags, medicalInputValue.trim()])
        setMedicalInputValue('')
        setTagChanged(true)
      }
      e.preventDefault()
    }
  }

  const handleFamilyHistoryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (familyInputValue.trim() !== '') {
        setFamilyHistoryTags([...familyHistorytags, familyInputValue.trim()])
        setFamilyInputValue('')
        setTagChanged(true)
      }
      e.preventDefault()
    }
  }

  const handleOngoingKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (ongoingInputValue.trim() !== '') {
        setOnGoingTags([...onGoingtags, ongoingInputValue.trim()])
        setOngoingInputValue('')
        setTagChanged(true)
      }
      e.preventDefault()
    }
  }

  const handleAllergiesKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (allergiesInputValue.trim() !== '') {
        setAllergyTags([...allergytags, allergiesInputValue.trim()])
        setAllergiesInputValue('')
        setTagChanged(true)
      }
      e.preventDefault()
    }
  }
  const handleHabitKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (habitInputValue.trim() !== '') {
        setHabitTags([...allergytags, habitInputValue.trim()])
        setHabitInputValue('')
        setTagChanged(true)
      }
      e.preventDefault()
    }
  }

  const handleSurgeryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (surgeriesInputValue.trim() !== '') {
        setSurgeryTags([...surgeryTags, surgeriesInputValue.trim()])
        setSurgeriesInputValue('')
        setTagChanged(true)
      }
      e.preventDefault()
    }
  }

  const handleImmunizationKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (immunizationInputValue.trim() !== '') {
        setImmunizationTags([...immunizationTags, immunizationInputValue.trim()])
        setImmunizationInputValue('')
        setTagChanged(true)
      }
      e.preventDefault()
    }
  }

  return (
    <>
      <>
        {showModal && (
          <div>
            <div
              className={`modal medicalBackForm fade  ${showModal ? 'show' : ''}`}
              id={`kt_modal_${selectedPatientId ? selectedPatientId : id}`}
              tabIndex={-1}
              style={{display: showModal ? 'block' : 'none'}}
              role='dialog'
            >
              <div className='modal-dialog modal-lg' ref={modalRef}>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h2 className='modal-title'>
                      {intl.formatMessage({id: 'MEDICAL_BACKGROUND.EDIT.MEDICAL.BACKGROUND'})}
                    </h2>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      // onClick={handleModalClose}
                      onClick={() => cancel()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <form
                      id={`kt_modal_add_user_form`}
                      className='form'
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      {/* begin::Scroll */}
                      <div
                        className='d-flex flex-column mh-100 me-n7 pe-7'
                        id='kt_modal_add_user_scroll'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                        data-kt-scroll-offset='300px'
                      >
                        <div className='row'>
                          <div className='fv-row mb-2 col-12'>
                            <label className='fw-bold fs-6 mb-2'>
                              {intl.formatMessage({
                                id: 'MEDICAL_BACKGROUND.MEDICAL.HISTORY',
                              })}
                            </label>

                            <TagsInput
                              {...formik.getFieldProps('medical_history')}
                              value={medicalHistorytags}
                              onChange={(newTags) => {
                                setMedicalHistoryTags(newTags)
                              }}
                              tagProps={{
                                className:
                                  'react-tagsinput-tag text-dark text-hover-white transition-none btn btn-sm btn-bg-light btn-active-primary',
                                onRemove: (index: any) =>
                                  handleMedicalHisoryTagRemove(medicalHistorytags[index]),
                              }}
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: 'GENERAL.ADD.MORE',
                                }),
                                value: medicalInputValue,
                                onChange: handleMedicalInputChange,
                                onKeyDown: handleMedicalHistoryKeyDown,
                              }}
                              className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                            />

                            <div className='preMedicalValueWrapper my-5'>
                              {defaultMedicalHistoryFiltered?.map((value: any) => (
                                <span
                                  className='medicalValueTag btn btn-sm btn-light-primary '
                                  key={value}
                                  onClick={() => handleMedicalHistoryTagClick(value)}
                                >
                                  {value}
                                </span>
                              ))}
                            </div>

                            {/* <textarea
                          placeholder='Medical History'
                          {...formik.getFieldProps('medical_history')}
                          name='medical_history'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
                          autoComplete='off'
                        /> */}
                          </div>
                          <div className='fv-row mb-2 col-12'>
                            <label className='fw-bold fs-6 mb-2'>
                              {intl.formatMessage({
                                id: 'MEDICAL_BACKGROUND.FAMILY.HISTORY',
                              })}
                            </label>
                            <TagsInput
                              {...formik.getFieldProps('family_history')}
                              value={familyHistorytags}
                              onChange={(newTags) => setFamilyHistoryTags(newTags)}
                              tagProps={{
                                className:
                                  'react-tagsinput-tag text-dark text-hover-white transition-none btn btn-sm btn-bg-light btn-active-primary',
                                onRemove: (index: any) =>
                                  handleFamilyHisoryTagRemove(familyHistorytags[index]),
                              }}
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: 'GENERAL.ADD.MORE',
                                }),
                                value: familyInputValue,
                                onChange: handleFamilyInputChange,
                                onKeyDown: handleFamilyHistoryKeyDown,
                              }}
                              className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                            />

                            <div className='preMedicalValueWrapper my-5'>
                              {defaultFamilyHistoryFiltered?.map((value: any) => (
                                <span
                                  className='medicalValueTag btn btn-sm btn-light-primary'
                                  key={value}
                                  onClick={() => handleFamilyHistoryTagClick(value)}
                                >
                                  {value}
                                </span>
                              ))}
                            </div>
                            {/* <textarea
                          placeholder='Family History'
                          {...formik.getFieldProps('family_history')}
                          name='family_history'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
                          autoComplete='off'
                        /> */}
                          </div>
                          <div className='fv-row mb-2 col-12'>
                            <label className='fw-bold fs-6 mb-2'>
                              {intl.formatMessage({
                                id: 'MEDICAL_BACKGROUND.ONGOING.MEDICATION',
                              })}
                            </label>
                            <TagsInput
                              {...formik.getFieldProps('ongoing_medication')}
                              value={onGoingtags}
                              onChange={(newTags) => setOnGoingTags(newTags)}
                              tagProps={{
                                className:
                                  'react-tagsinput-tag text-dark text-hover-white transition-none btn btn-sm btn-bg-light btn-active-primary',
                                onRemove: (index: any) =>
                                  handleOnGoingTagRemove(onGoingtags[index]),
                              }}
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: 'GENERAL.ADD.MORE',
                                }),
                                value: ongoingInputValue,
                                onChange: handleOngoingInputChange,
                                onKeyDown: handleOngoingKeyDown,
                              }}
                              className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                            />

                            <div className='preMedicalValueWrapper my-5'>
                              {defaultOnGoingFiltered?.map((value: any) => (
                                <span
                                  className='medicalValueTag btn btn-sm btn-light-primary'
                                  key={value}
                                  onClick={() => handleOnGoingTagClick(value)}
                                >
                                  {value}
                                </span>
                              ))}
                            </div>

                            {/* <textarea
                          placeholder='Ongoing Medication'
                          {...formik.getFieldProps('ongoing_medication')}
                          name='ongoing_medication'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
                          autoComplete='off'
                        /> */}
                          </div>
                          <div className='fv-row mb-2 col-12'>
                            <label className='fw-bold fs-6 mb-2'>
                              {intl.formatMessage({
                                id: 'MEDICAL_BACKGROUND.ALLERGIES',
                              })}
                            </label>
                            <TagsInput
                              {...formik.getFieldProps('allergies')}
                              value={allergytags}
                              onChange={(newTags) => setAllergyTags(newTags)}
                              tagProps={{
                                className:
                                  'react-tagsinput-tag text-dark text-hover-white transition-none btn btn-sm btn-bg-light btn-active-primary',
                                onRemove: (index: any) =>
                                  handleAllergyTagRemove(allergytags[index]),
                              }}
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: 'GENERAL.ADD.MORE',
                                }),
                                value: allergiesInputValue,
                                onChange: handleAllergiesInputChange,
                                onKeyDown: handleAllergiesKeyDown,
                              }}
                              className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                            />

                            <div className='preMedicalValueWrapper my-5'>
                              {defaultAllergyFiltered?.map((value: any) => (
                                <span
                                  className='medicalValueTag btn btn-sm btn-light-primary'
                                  key={value}
                                  onClick={() => handleAllergyTagClick(value)}
                                >
                                  {value}
                                </span>
                              ))}
                            </div>
                            {/* <textarea
                          placeholder='Allergies'
                          {...formik.getFieldProps('allergies')}
                          name='allergies'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
                          autoComplete='off'
                        /> */}
                          </div>
                          <div className='fv-row mb-2 col-12'>
                            <label className='fw-bold fs-6 mb-2'>
                              {intl.formatMessage({
                                id: 'MEDICAL_BACKGROUND.HABIT.HISTORY',
                              })}
                            </label>
                            <TagsInput
                              {...formik.getFieldProps('habit_history')}
                              value={habitTags}
                              onChange={(newTags) => setHabitTags(newTags)}
                              tagProps={{
                                className:
                                  'react-tagsinput-tag text-dark text-hover-white transition-none btn btn-sm btn-bg-light btn-active-primary',
                                onRemove: (index: any) => handleHabitTagRemove(habitTags[index]),
                              }}
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: 'GENERAL.ADD.MORE',
                                }),
                                value: habitInputValue,
                                onChange: handleHabitInputChange,
                                onKeyDown: handleHabitKeyDown,
                              }}
                              className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                            />

                            <div className='preMedicalValueWrapper my-5'>
                              {defaultHabitFiltered?.map((value: any) => (
                                <span
                                  className='medicalValueTag btn btn-sm btn-light-primary'
                                  key={value}
                                  onClick={() => handleHabitTagClick(value)}
                                >
                                  {value}
                                </span>
                              ))}
                            </div>
                            {/* <textarea
                          placeholder='Habit History'
                          {...formik.getFieldProps('habit_history')}
                          name='habit_history'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
                          autoComplete='off'
                        /> */}
                          </div>
                          <div className='fv-row mb-2 col-12'>
                            <label className='fw-bold fs-6 mb-2'>
                              {intl.formatMessage({
                                id: 'MEDICAL_BACKGROUND.SURGERIES',
                              })}
                            </label>

                            <TagsInput
                              {...formik.getFieldProps('surgeries')}
                              value={surgeryTags}
                              onChange={(newTags) => setSurgeryTags(newTags)}
                              tagProps={{
                                className:
                                  'react-tagsinput-tag text-dark text-hover-white transition-none btn btn-sm btn-bg-light btn-active-primary',
                                onRemove: (index: any) =>
                                  handleSurgeryTagRemove(surgeryTags[index]),
                              }}
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: 'GENERAL.ADD.MORE',
                                }),
                                value: surgeriesInputValue,
                                onChange: handleSurgeryInputChange,
                                onKeyDown: handleSurgeryKeyDown,
                              }}
                              className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                            />

                            <div className='preMedicalValueWrapper my-5'>
                              {defaultSurgeryFiltered?.map((value: any) => (
                                <span
                                  className='medicalValueTag btn btn-sm btn-light-primary'
                                  key={value}
                                  onClick={() => handleSurgeryTagClick(value)}
                                >
                                  {value}
                                </span>
                              ))}
                            </div>
                            {/* <textarea
                          placeholder='Surgeries'
                          {...formik.getFieldProps('surgeries')}
                          name='surgeries'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
                          autoComplete='off'
                        /> */}
                          </div>
                          <div className='fv-row mb-2 col-12'>
                            <label className='fw-bold fs-6 mb-2'>
                              {intl.formatMessage({
                                id: 'MEDICAL_BACKGROUND.IMMUNIZATION',
                              })}
                            </label>
                            <TagsInput
                              {...formik.getFieldProps('immunization')}
                              value={immunizationTags}
                              onChange={(newTags) => setImmunizationTags(newTags)}
                              tagProps={{
                                className:
                                  'react-tagsinput-tag text-dark text-hover-white transition-none btn btn-sm btn-bg-light btn-active-primary',
                                onRemove: (index: any) =>
                                  handleImmunizationTagRemove(immunizationTags[index]),
                              }}
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: 'GENERAL.ADD.MORE',
                                }),
                                value: immunizationInputValue,
                                onChange: handleImmunizationInputChange,
                                onKeyDown: handleImmunizationKeyDown,
                              }}
                              className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                            />

                            <div className='preMedicalValueWrapper my-5'>
                              {defaultImmunizationFiltered?.map((value: any) => (
                                <span
                                  className='medicalValueTag btn btn-sm btn-light-primary'
                                  key={value}
                                  onClick={() => handleImmunizationTagClick(value)}
                                >
                                  {value}
                                </span>
                              ))}
                            </div>
                            {/* <textarea
                          placeholder='Immunization'
                          {...formik.getFieldProps('immunization')}
                          name='immunization'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
                          autoComplete='off'
                        /> */}
                          </div>
                        </div>
                      </div>

                      {/* begin::Actions */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          // onClick={handleModalClose}
                          onClick={() => cancel()}
                          className='btn btn-light me-3'
                          // data-bs-dismiss='modal'
                          // aria-label='Close'
                          data-kt-users-modal-action='cancel'
                          disabled={formik.isSubmitting || isUserLoading}
                        >
                          {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                        </button>

                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                          disabled={formik.isSubmitting || isUserLoading || !isTagChanged}
                        >
                          <span className='indicator-label'>
                            {intl.formatMessage({id: 'GENERAL.SAVE'})}
                          </span>
                          {(formik.isSubmitting || isUserLoading) && (
                            <span className='indicator-progress'>
                              {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                      {/* end::Actions */}
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {showModal && <div className='modal-backdrop show'></div>}
          </div>
        )}
      </>

      {formik.isSubmitting && <MedicalBackgroundListLoading />}
    </>
  )
}

export {MedicalBackgroundEditModalForm}
