import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {Inventory, InventoryQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL


const INVENTORY_URL = `${API_URL}/inventory`
const GET_INVENTORY_URL = `${API_URL}/inventory/`

export const getInvStatus = (): Promise<InventoryQueryResponse> => {
  return axios
    .get(`${INVENTORY_URL}`)
    .then((d: AxiosResponse<InventoryQueryResponse>) => d.data)
}

const getInventory = (query: string,id:number): Promise<InventoryQueryResponse> => {
  return axios
    .get(`${GET_INVENTORY_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<InventoryQueryResponse>) => d.data)
}

const getInventoryById = (id: ID): Promise<Inventory | undefined> => {
  return axios
    .get(`${INVENTORY_URL}/${id}`)
    .then((response: AxiosResponse<Response<Inventory>>) => response.data)
    .then((response: Response<Inventory>) => response.data)
}

const getInventoryReport = (id: ID): Promise<Inventory | undefined> => {
  return axios
    .get(`${INVENTORY_URL}/csv?hospital_id=${id}`)
    .then((response: AxiosResponse<Response<Inventory>>) => response.data)
    .then((response: Response<Inventory>) => response.data)
}

const createInventory = (user: Inventory): Promise<Inventory | undefined> => {
  return axios
    .post(INVENTORY_URL, user)
}

const updateInventory = (user: Inventory): Promise<Inventory | undefined> => {
  return axios
    .patch(`${INVENTORY_URL}/${user.id}`, user)
}

export {getInventory, getInventoryById,getInventoryReport, createInventory, updateInventory}
