import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import TagsInput from 'react-tagsinput'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const DpProcedure = () => {
  const intl = useIntl()
  const [selDateTime, setSelDateTime] = useState<Date[]>([])
  const [procedureTags, setProcedureTags] = useState<any>([])
  const [procedureData, setProcedureData] = useState([
    {procedure_name: '', procedure_details: '', procedure_date: '', procedure_price: ''},
  ])
  const [procedureInputValue, setProcedureInputValue] = useState<any>('')

  const defaultProcedureMain = [
    'ECG',
    'Repeating dressing',
    'Injection',
    'Endoscopy',
    'Dressing',
    'Diet chart',
    'Spirometry',
    'Gastric lavage',
    'MRI',
  ]
  const [defaultProcedureHistory, setDefaultProcedureHistory] = useState<any>(defaultProcedureMain)

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem('prescriptionData')
    if (sessionStorageData) {
      const prescriptionData = JSON.parse(sessionStorageData)
      const updatedPrescriptionData = {
        ...prescriptionData,
        procedure: procedureData.filter(
          (procedure) =>
            procedure.procedure_name ||
            procedure.procedure_details ||
            procedure.procedure_date ||
            procedure.procedure_price
        ),
      }
      sessionStorage.setItem('prescriptionData', JSON.stringify(updatedPrescriptionData))
    }
  }, [procedureData, procedureTags])

  const handleProcedureHisoryTagRemove = (removedTag: any, index: number) => {
    setProcedureTags(procedureTags.filter((t: any) => t !== removedTag))
    if (defaultProcedureMain.includes(removedTag)) {
      const updatedDefaultInvestigationHistory = Array.from(
        new Set([...defaultProcedureHistory, removedTag])
      )
      setDefaultProcedureHistory(updatedDefaultInvestigationHistory)
    }

    const updatedInvestigationData = procedureData.filter((_, i) => i !== index)
    setProcedureData(updatedInvestigationData)

    const updatedSelDateTime = [...selDateTime]
    updatedSelDateTime.splice(index, 1)
    setSelDateTime(updatedSelDateTime)
  }

  const defaultProcedureHistoryFiltered = defaultProcedureHistory.filter(
    (value: any) => !procedureTags.includes(value)
  )

  const handleProcedureHistoryTagClick = (tag: any) => {
    const allData = procedureData
    allData[allData.length - 1] = {
      procedure_name: tag,
      procedure_details: '',
      procedure_date: '',
      procedure_price: '',
    }
    allData.push({
      procedure_name: '',
      procedure_details: '',
      procedure_date: '',
      procedure_price: '',
    })

    const updatedInvestigationTags = [...procedureTags]
    updatedInvestigationTags[allData?.length - 2] = tag
    setProcedureTags(updatedInvestigationTags)

    const updatedSelDateTime = [...selDateTime, null] as Date[]
    setSelDateTime(updatedSelDateTime)

    setDefaultProcedureHistory(defaultProcedureHistory.filter((value: any) => value !== tag))
  }

  const handleProcedureInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedInputValues = [...procedureInputValue]
    updatedInputValues[index] = e.target.value
    setProcedureInputValue(updatedInputValues)
  }

  const handleProcedureHistoryKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (procedureInputValue[index].trim() !== '') {
        setProcedureInputValue([])
        const allData = procedureData

        allData[allData.length - 1] = {
          procedure_name: procedureInputValue[index].trim(),
          procedure_details: '',
          procedure_date: '',
          procedure_price: '',
        }
        allData.push({
          procedure_name: '',
          procedure_details: '',
          procedure_date: '',
          procedure_price: '',
        })
        const updatedProcedureTags = [...procedureTags]
        updatedProcedureTags[allData?.length - 2] = procedureInputValue[index].trim()

        setProcedureTags(updatedProcedureTags)
        const updatedInputValues = [...procedureInputValue]
        updatedInputValues[index] = ''
        setProcedureInputValue(updatedInputValues)

        const updatedSelDateTime = [...selDateTime, null] as Date[]
        setSelDateTime(updatedSelDateTime)
      }
      e.preventDefault()
    }
  }

  const past_date_disable = new Date(moment().format('YYYY-MM-DD'))

  return (
    <div className='procedure'>
      <h4>Procedures</h4>
      <table className='table table-bordered table-light dp-procedure-table'>
        <thead className=''>
          <tr>
            <th scope='col'>Name of Procedure</th>
            <th scope='col'>Procedure Details</th>
            <th scope='col'>Procedure Date</th>
            <th scope='col'>Procedure Price</th>
          </tr>
        </thead>
        <tbody>
          {procedureData.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <TagsInput
                  value={procedureTags[index] ? [procedureTags[index]] : []}
                  onChange={(newTags) => {
                    const updatedTags = [...procedureTags]
                    updatedTags[index] = newTags[0]
                    setProcedureTags(updatedTags)
                  }}
                  tagProps={{
                    className:
                      'react-tagsinput-tag btn btn-sm btn-light-primary btn-active-light-primary shadow',
                    onRemove: (index2: any) =>
                      handleProcedureHisoryTagRemove(procedureTags[index], index),
                  }}
                  inputProps={{
                    disabled: procedureData[index].procedure_name.length > 0,
                    placeholder:
                      procedureData[index].procedure_name.length > 0
                        ? ''
                        : intl.formatMessage({id: 'GENERAL.ADD.PROCEDURE'}),
                    value: procedureInputValue[index],
                    onChange: (e: any) => handleProcedureInputChange(e, index),
                    onKeyDown: (e: any) => handleProcedureHistoryKeyDown(e, index),
                  }}
                  className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                  maxTags={1}
                />
              </td>
              <td>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Details of the procedure'
                  id='procedure_details'
                  value={item?.procedure_details}
                  onChange={(e) => {
                    const updatedProcedureData = [...procedureData]
                    updatedProcedureData[index] = {
                      ...updatedProcedureData[index],
                      procedure_details: e.target.value,
                    }
                    setProcedureData(updatedProcedureData)
                  }}
                />
              </td>
              <td>
                <div className='fv-row'>
                  <div className='position-relative'>
                    <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      className='form-control form-control-solid mb-0 cursor-pointer'
                      dateFormat='d MMMM yyyy'
                      name='date_time'
                      minDate={past_date_disable}
                      selected={selDateTime[index]}
                      onChange={(date: any) => {
                        const updatedProcedureData = [...procedureData]
                        updatedProcedureData[index] = {
                          ...updatedProcedureData[index],
                          procedure_date: moment(date).format('YYYY-MM-DD'),
                        }
                        setProcedureData(updatedProcedureData)
                        const updatedSelDateTime = [...selDateTime]
                        updatedSelDateTime[index] = date
                        setSelDateTime(updatedSelDateTime)
                      }}
                      autoComplete='off'
                      shouldCloseOnSelect={false}
                      placeholderText={intl.formatMessage({
                        id: 'GENERAL.PROCEDURE_DATE_PLACEHOLDER',
                      })}
                      onKeyDown={(e: any) => {
                        e.preventDefault()
                      }}
                    />
                    <span
                      className='DatePicker_icon cursor-pointer'
                      onClick={() => document.getElementsByName('date_time')[0].focus()}
                    >
                      <i className='fa fa-calendar'></i>
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <input
                  className='form-control'
                  type='number'
                  placeholder='5000'
                  id='procedure_price'
                  value={item?.procedure_price}
                  onChange={(e) => {
                    const updatedProcedureData = [...procedureData]
                    updatedProcedureData[index] = {
                      ...updatedProcedureData[index],
                      procedure_price: e.target.value,
                    }
                    setProcedureData(updatedProcedureData)
                  }}
                  onKeyPress={(e) => {
                    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                      e.preventDefault()
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='preMedicalValueWrapper my-5 mx-2'>
        {defaultProcedureHistoryFiltered.map((value: any) => (
          <span
            className='medicalValueTag btn btn-sm'
            key={value}
            onClick={() => handleProcedureHistoryTagClick(value)}
          >
            {value}
          </span>
        ))}
      </div>
    </div>
  )
}

export default DpProcedure
