/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC} from 'react'

import {Inventory} from '../../core/_models'

type Props = {
  user: Inventory
}

const InvManagementMinQuantityCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>{user?.minimum_quantity}</p>
    </div>
  </div>
)

export {InvManagementMinQuantityCell}
