import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {MedicationListHeader} from './components/header/MedicationListHeader'
import {MedicationTable} from './table/MedicationTable'
import {MedicationEditModal} from './Medication-edit-modal/MedicationEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const MedicationList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard>
        <MedicationListHeader />
        <MedicationTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <MedicationEditModal />}
    </>
  )
}

const MedicationListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <MedicationList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MedicationListWrapper}
