import React, {useContext, useState} from 'react'
import {CardProps} from './core/_models'
import HospitalModal from './HospitalModal'

import {useHospitalContext} from './HospitalPage'
import {loginContext} from '../../context/context'
import {useIntl} from 'react-intl'

const HospitalCard: React.FC<any> = (cardData: CardProps) => {
  const intl = useIntl()
  const {loginData} = useContext(loginContext)
  const [showModal, setShowModal] = useState(false)
  const {handleState} = useHospitalContext()

  const handleSave = async (updatedCardData: any) => {
    handleState(updatedCardData)
    setShowModal(false)
    const modalBackdrop = document.querySelector('.modal-backdrop.show')
    if (modalBackdrop && modalBackdrop.parentNode) {
      modalBackdrop.parentNode.removeChild(modalBackdrop)
    }
    document.body.classList.remove('modal-open')
    if (!document.body.classList.contains('modal-open')) {
      document.body.style.overflow = ''
    }
  }

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'hospital') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  return (
    <div className='card hospital_card h-100 shadow'>
      {edit.length ? (
        <div className='hospital_edit_button'>
          <button
            className='btn btn-sm btn-primary fw-bolder'
            data-bs-toggle='modal'
            onClick={() => setShowModal(true)}
            data-bs-target={`#kt_modal_${cardData.cardData.id}`}
          >
            <span>
              <i className='bi bi-pencil-square fs-2'></i>
            </span>
          </button>
        </div>
      ) : (
        ''
      )}

      <div className='card-body d-flex flex-column px-9 py-9 '>
        <div className='card-content px-5 py-5 '>
          <div className='hospital_top_content mb-10'>
            <div className='hospital_logo text-center mb-5'>
              {cardData?.cardData?.logo && (
                <>
                  <img
                    src={cardData?.cardData?.logo}
                    style={{width: '100px', height: '100px'}}
                    alt=''
                  />
                </>
              )}
            </div>
            <div className='hospital_name mb-2 text-center'>
              <h1 className='card-title text-dark mb-0 fw-bolder'>{cardData.cardData.name}</h1>
            </div>
          </div>
          <div className='hospital_bottom_content'>
            <h3 className='mb-8 fw-bolder'>{intl.formatMessage({id: 'HOSPITAL.DETAILS'})}</h3>
            <div className='hospital_address'>
              <h4>{intl.formatMessage({id: 'HOSPITAL.ADDRESS'})}</h4>
              <p>{cardData.cardData.address}</p>
            </div>

            <div className='contact_person_name'>
              <h4>{intl.formatMessage({id: 'HOSPITAL.CONTACT.PERSON.NAME'})}</h4>
              <p>{cardData.cardData.contact_person}</p>
            </div>
            <div className='contact_person_number'>
              <h4>{intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}</h4>
              <p className='mb-0'>{cardData.cardData.contact_number}</p>
            </div>
          </div>
        </div>

        <HospitalModal
          showModal={showModal}
          cardData={cardData}
          onSave={handleSave}
          onClose={() => setShowModal(false)}
        />
      </div>
    </div>
  )
}

export default HospitalCard
