/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Staff} from '../../core/_models'

type Props = {
  user: Staff
}

const StaffStatusCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <div className='text-gray-800 mb-1'>
        <div className='text-center'>
          <span className={`badge ${user?.status === 'INACTIVE' ? 'badge-light-warning' : user?.status === 'ACTIVE' ? 'badge-light-success' : ''}`}>
              {user?.status}
          </span>
        </div>
        </div>
      </div>
    </div>
  )
}

export {StaffStatusCell}
