import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {AssetListWrapper} from './ShiftList/ShiftList'
import {useIntl} from 'react-intl'

const ShiftPageWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'SHIFT.SHIFT.MANAGEMENT'})}</PageTitle>
      <AssetListWrapper />
    </>
  )
}

export default ShiftPageWrapper
