import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {MedicineMasterListWrapper} from './MedicineMasterList/MedicineMasterList'

const MedicinePage = () => (
  <>
    <div className=''>
      <MedicineMasterListWrapper />
    </div>
  </>
)

const MedicineMasterWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MEDICINE_MANAGEMENT'})}</PageTitle>
      <MedicinePage />
    </>
  )
}

export {MedicineMasterWrapper}
