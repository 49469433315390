/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PatientBiling} from '../../core/_models'

type Props = {
  user: PatientBiling
}

const PatientBillingStatus: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p
        className={`mb-1 badge ${
          user?.status === 'COMPLETED'
            ? 'badge-light-success'
            : user?.status === 'PENDING'
            ? 'badge-light-danger'
            : user?.status === 'PROCESSING'
            ? 'badge-light-warning'
            : ''
        }`}
      >
        {user?.status}
      </p>
    </div>
  </div>
)

export {PatientBillingStatus}
