/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PatientVital} from '../../core/_models'

type Props = {
  user: PatientVital
}

const PatientPulseInfo: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <p className='text-gray-800 mb-1'>{user?.pulse}</p>
      </div>
    </div>
  )
}

export {PatientPulseInfo}
