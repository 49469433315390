/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {NotificationModel} from '../../core/_models'

type Props = {
  user: NotificationModel
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='symbol symbol-circle symbol-50px overflow-hidden'></div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 mb-1'>
        {user.notification_type}
      </a>
      <span>{user.payload?.email}</span>
    </div>
  </div>
)

export {UserInfoCell}
