import React, {useContext, useState} from 'react'
import {toast} from 'react-toastify'
import {DpContext} from '../DpLayoutWrapper'
import {useIntl} from 'react-intl'

const DpAttachment: React.FC = () => {
  const intl = useIntl()
  const [filesData, setFilesData] = useState<File[]>([])
  const [inputKey, setInputKey] = useState<number>(Date.now())
  const {setAttachmentFiles} = useContext(DpContext)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files)
      const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0)
      const maxSizeAllowed = 5 * 1024 * 1024
      if (filesData.length + selectedFiles.length > 5) {
        toast.error(
          intl.formatMessage({
            id: 'PRESCRIPTION_ATTACHMENT_MAX_FILE_ERROR',
          })
        )
      } else if (totalSize > maxSizeAllowed) {
        toast.error(
          intl.formatMessage({
            id: 'PRESCRIPTION_ATTACHMENT_MAX_FILE_SIZE_ERROR',
          })
        )
      } else {
        setFilesData((prevFiles) => [...prevFiles, ...selectedFiles])
        setAttachmentFiles((prevFiles: any) => [...(prevFiles ?? []), ...selectedFiles])
      }
    }
    setInputKey(Date.now())
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (e.dataTransfer.files) {
      const droppedFiles = Array.from(e.dataTransfer.files)
      const totalSize = droppedFiles.reduce((acc, file) => acc + file.size, 0)
      const maxSizeAllowed = 5 * 1024 * 1024
      if (filesData.length + droppedFiles.length > 5) {
        toast.error(
          intl.formatMessage({
            id: 'PRESCRIPTION_ATTACHMENT_MAX_FILE_ERROR',
          })
        )
      } else if (totalSize > maxSizeAllowed) {
        toast.error(
          intl.formatMessage({
            id: 'PRESCRIPTION_ATTACHMENT_MAX_FILE_SIZE_ERROR',
          })
        )
      } else {
        setFilesData((prevFiles) => [...prevFiles, ...droppedFiles])
        setAttachmentFiles((prevFiles: any) => [...(prevFiles ?? []), ...droppedFiles])
      }
    }
    setInputKey(Date.now())
  }

  const removeFile = (index: number) => {
    setFilesData((prevFiles) => {
      const newFiles = [...prevFiles]
      newFiles.splice(index, 1)
      return newFiles
    })
    setAttachmentFiles((prevFiles: any) => {
      const newFiles = [...prevFiles]
      newFiles.splice(index, 1)
      return newFiles
    })
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-column flex-lg-row align-items-lg-center justify-content-start'>
        <h4 className='m-0 me-5 mb-3 mb-lg-0'>
          {intl.formatMessage({
            id: 'PRESCRIPTION_ATTACHMENT_TITLE',
          })}
        </h4>
        <span className='dp_attachment_warning d-inline-block mb-2 mb-lg-0'>
          {intl.formatMessage({
            id: 'PRESCRIPTION_ATTACHMENT_MAX_FILE_UPLOAD_WARNING',
          })}
        </span>
        <span className='dp_attachment_accept ms-0 ms-lg-5'>
          {intl.formatMessage({
            id: 'PRESCRIPTION_ATTACHMENT_ACCEPTABLE_FORMATS',
          })}
        </span>
      </div>

      {filesData.length > 0 && (
        <div
          style={{border: '1px dashed #ccc', padding: '5px', marginTop: '10px'}}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div>
            {filesData.map((file, index) => (
              <div key={index} className='d-flex justify-content-between align-items-center p-3'>
                <span>{file.name}</span>
                <span className='cursor-pointer' onClick={() => removeFile(index)}>
                  <i className='fa-solid fa-trash-can' style={{color: '#264e95'}}></i>
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      {filesData?.length <= 4 && (
        <div
          style={{border: '1px dashed #ccc', padding: '5px', marginTop: '10px'}}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <label className='w-100 text-center p-3' style={{cursor: 'pointer'}} htmlFor='fileInput'>
            {intl.formatMessage({
              id: 'PRESCRIPTION_ATTACHMENT_ADD_OR_DRAG_FILES',
            })}
          </label>
        </div>
      )}

      <input
        key={inputKey}
        type='file'
        id='fileInput'
        accept='.jpeg,.jpg,.png,.doc,.docx'
        multiple
        style={{display: 'none'}}
        onChange={handleFileChange}
      />
    </>
  )
}

export default DpAttachment
