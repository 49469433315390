import React from 'react'
import {Oval} from 'react-loader-spinner'

const CommonLoader = () => {
  return (
    <>
      <div className='loader-container'>
        <Oval
          height={50}
          width={50}
          color='white'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor='#009ef7'
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      </div>
    </>
  )
}

export default CommonLoader
