import { Response } from "../../../../../_jmh/helpers"

export type PatientBiling = {
  bill_number?: string, 
  hospital_id?: number | null,
  doctor_id?: number,
  billing_head?:string,
  quantity?:number | null,
  total_cost?:number,
  patient_id?: number,
  appointment_id?: null,
  full_name?:string,
  cost_per_head?:number | null,
  patient_ad_id?: null,
  insurance_id?: null,
  operation_theatre_id?: null,
  billing_date?: string ,
  total_billing_cost?: number,
  taxes?: number,
  total_payable_amount?: number,
  payment_method?: string,
  status?: string,
  insurance?: string,
  created_at?: string,
  updated_at?: string,
  doctor?: {
      id?: string,
      full_name?: string,
      gender?: string,
      image?: string,
      date_of_birth?: string,
      permanent_address?: string,
      contact_number?: string,
      visiting_doctor?: false,
      qualification?: string,
      years_of_experience?: number,
      user_id?: string,
      speciality_id?: string,
      status?: string,
      created_at?: string,
      updated_at?: string
  },
  hospital?: {
      id?: string,
      name?: string,
      address?: string,
      contact_person?: string,
      contact_number?: string,
      status?: string,
      created_at?: string,
      updated_at?: string
  },
  patient?: {
      id?: string,
      hospital_id?: string,
      full_name?: string,
      gender?: string,
      contact_number?: string,
      date_of_birth?: string,
      permanent_address?: null,
      email?: string,
      alternate_contact_number?: null,
      blood_group?: string,
      status?: string,
      created_at?: string,
      updated_at?: string
  } ,
  patient_appointment?: null,
  ad_patient?: null,
  patient_insurance?: null,
  operation_theatre?: null,
  billing_detail?: Array<{
    billing_head?: string,
    cost_per_head?: number,
    quantity?: number,
    total_cost?: number,
  }>
items?: Array<{         
  billing_head: string,
  cost_per_head: number,
  quantity: number,
  total_cost: number,
}>
bill?:any
}

export type PatientBillingQueryResponse = Response<Array<PatientBiling>>

export const initialUser: PatientBiling = {
  bill_number: "",
  hospital_id: null,
  doctor_id: 0,
  patient_id: 0,
  billing_head:"",
  quantity :0,
  full_name:"",
  total_cost:0,
  cost_per_head:0,
  appointment_id: null,
  patient_ad_id: null,
  insurance_id: null,
  operation_theatre_id: null,
  billing_date: "",
  total_billing_cost: 0,
  taxes: 0,
  total_payable_amount: 0,
  payment_method: "",
  status: "",
  insurance: "",
  created_at: "",
  updated_at: "",
  doctor: {
    id: "",
    full_name: "",
    gender: "",
    image: "",
    date_of_birth: "",
    permanent_address: "",
    contact_number: "",
    visiting_doctor: false,
    qualification: "",
    years_of_experience: 0,
    user_id: "",
    speciality_id: "",
    status: "",
    created_at: "",
    updated_at: ""
  },
  hospital: {
    id: "",
    name: "",
    address: "",
    contact_person: "",
    contact_number: "",
    status: "",
    created_at: "",
    updated_at: ""
  },
  patient: {
    id: "",
    hospital_id: "",
    full_name: "",
    gender: "",
    contact_number: "",
    date_of_birth: "",
    permanent_address: null,
    email: "",
    alternate_contact_number: null,
    blood_group: "",
    status: "",
    created_at: "",
    updated_at: ""
  },
  patient_appointment: null,
  ad_patient: null,
  patient_insurance: null,
  operation_theatre: null,
  billing_detail: [
    {
      billing_head: "",
      cost_per_head: 0,
      quantity: 0,
      total_cost: 0,
    }
  ],
  items: [{   
    billing_head: "",
    cost_per_head: 0,
    quantity: 0,
    total_cost: 0,
  }]
}
