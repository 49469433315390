import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_jmh/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_jmh/layout/core'
import {MasterInit} from '../_jmh/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {Provider} from 'react-redux'
import store, {persistor} from './redux/store'
import {PersistGate} from 'redux-persist/integration/react'
import {resetAsideMinimize} from './redux/asideMinimizeSlice' // Import the action creator

const App = () => {
  useEffect(() => {
    // Reset the asideMinimize state to false when the app is loaded
    store.dispatch(resetAsideMinimize())
  }, []) // Run this effect only once, when the component mounts

  return (
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      </Provider>
    </PersistGate>
  )
}

export {App}
