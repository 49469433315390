import React from 'react'
import {PatientChangePassword} from '../../../modules/auth/components/PatientComponents/PatientChangePassword'
import {useIntl} from 'react-intl'

type PatientChangePasswordModalProps = {
  show: boolean
  onClose: () => void
  onSubmit: () => void
}

const PatientChangePasswordModal: React.FC<PatientChangePasswordModalProps> = ({
  show,
  onClose,
  onSubmit,
}) => {
  const intl = useIntl()
  return (
    <>
      <div
        className={`modal fade${show ? ' show' : ''}`}
        onClick={onClose}
        style={{display: show ? 'block' : 'none'}}
      >
        <div className='modal-dialog'>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='modal-header'>
              <h2 className='modal-title'>{intl.formatMessage({id: 'GENERAL.CHANGE_PASSWORD'})}</h2>
              <button type='button' className='btn-close' onClick={onClose}></button>
            </div>
            <div className='modal-body'>
              <PatientChangePassword onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
      {show && <div className='modal-backdrop fade show'></div>}
    </>
  )
}

export default PatientChangePasswordModal
