import {KTCard} from '../../../../_jmh/helpers'
import {MedicineMasterListHeader} from './components/header/MedicineMasterListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {MedicineMasterEditModal} from './medicine-master-edit-modal/MedicineMasterEditModal'
import {MedicineMasterTable} from './table/MedicineMasterTable'

const MedicineMasterList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard className='shadow'>
        <MedicineMasterListHeader />
        <MedicineMasterTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <MedicineMasterEditModal />}
    </>
  )
}

const MedicineMasterListWrapper = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <MedicineMasterList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {MedicineMasterListWrapper}
