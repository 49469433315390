export const SalaryStructure = {
    'basic_salary': 0.5,
    'house_rent_allowance': 0.13,
    'medical_insurance_allowance': 0.12,
    'conveyance_transportation_allowance': 0.11,
    'meal_allowance': 0.09,
    'entertainment_allowance': 0.05,
    'variable_pay': 0.1,
  };

  export const calculateSalaryComponents = (grossSalary?:any, otherAllowance? :any , professionalTax? :any, epf? :any) => {
    const SalaryStructure = {
      'basic_salary': 0.5,
      'house_rent_allowance': 0.13,
      'medical_insurance_allowance': 0.12,
      'conveyance_transportation_allowance': 0.11,
      'meal_allowance': 0.09,
      'entertainment_allowance': 0.05,
      'variable_pay': 0.1,
    };
  
    const basicSalary = grossSalary * SalaryStructure['basic_salary'];
    const houseRentAllowance = grossSalary * SalaryStructure['house_rent_allowance'];
    const medicalInsuranceAllowance = grossSalary * SalaryStructure['medical_insurance_allowance'];
    const conveyanceTransportationAllowance = grossSalary * SalaryStructure['conveyance_transportation_allowance'];
    const mealAllowance = grossSalary * SalaryStructure['meal_allowance'];
    const entertainmentAllowance = grossSalary * SalaryStructure['entertainment_allowance'];
    const variablePay = grossSalary * SalaryStructure['variable_pay'];
    let epfDeduction: any

    if(epf){
       epfDeduction = Number(epf) ? Number(epf) : 0  
    }
    else if(epf === '' || epf === 0){
      epfDeduction = 0
    }
    else{
      epfDeduction = epf === null ? null : basicSalary * SalaryStructure['medical_insurance_allowance'];
    }
  
    if( professionalTax === undefined){
     
      professionalTax = 0
    }

    const throughPaySlip =
      basicSalary +
      houseRentAllowance +
      medicalInsuranceAllowance +
      conveyanceTransportationAllowance +
      mealAllowance +
      entertainmentAllowance +
      Number(otherAllowance) -
      professionalTax -
      epfDeduction;
      
  Number(grossSalary)

    return {
      basicSalary,
      houseRentAllowance,
      medicalInsuranceAllowance,
      conveyanceTransportationAllowance,
      mealAllowance,
      entertainmentAllowance,
      variablePay,
      epfDeduction,
      throughPaySlip,
      professionalTax,
      otherAllowance, grossSalary
    };
  }
  

