import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import TagsInput from 'react-tagsinput'

const Investigation = () => {
  const intl = useIntl()
  const [investigationData, setInvestigationData] = useState([{test_name: '', instruction: ''}])
  const [investigationTags, setInvestigationTags] = useState<any>([])
  const [investigationInputValue, setInvestigationInputValue] = useState<any>('')

  const defaultInvestigationMain = [
    'Serum Ferritin level',
    'X ray of rt knee ap lat',
    'Lipid profile',
    'LFT',
    'CRP',
    'MP',
    'CBC',
    'Chest x-ray',
  ]
  const [defaultInvestigationHistory, setDefaultInvestigationHistory] =
    useState<any>(defaultInvestigationMain)

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem('prescriptionData')
    if (sessionStorageData) {
      const prescriptionData = JSON.parse(sessionStorageData)
      const updatedPrescriptionData = {
        ...prescriptionData,
        investigation: investigationData.filter(
          (investigation) => investigation.test_name || investigation.instruction
        ),
      }
      sessionStorage.setItem('prescriptionData', JSON.stringify(updatedPrescriptionData))
    }
  }, [investigationData, investigationTags])

  const handleInvestigationHisoryTagRemove = (removedTag: any, index: number) => {
    setInvestigationTags(investigationTags.filter((t: any) => t !== removedTag))
    if (defaultInvestigationMain.includes(removedTag)) {
      const updatedDefaultInvestigationHistory = Array.from(
        new Set([...defaultInvestigationHistory, removedTag])
      )
      setDefaultInvestigationHistory(updatedDefaultInvestigationHistory)
    }
    const updatedInvestigationData = investigationData.filter((_, i) => i !== index)
    setInvestigationData(updatedInvestigationData)
  }

  const defaultInvestigationHistoryFiltered = defaultInvestigationHistory.filter(
    (value: any) => !investigationTags.includes(value)
  )

  const handleInvestigationHistoryTagClick = (tag: any) => {
    const allData = investigationData
    allData[allData.length - 1] = {test_name: tag, instruction: ''}
    allData.push({test_name: '', instruction: ''})
    const updatedInvestigationTags = [...investigationTags]
    updatedInvestigationTags[allData?.length - 2] = tag
    setInvestigationTags(updatedInvestigationTags)

    setDefaultInvestigationHistory(
      defaultInvestigationHistory.filter((value: any) => value !== tag)
    )
  }

  const handleInvestigationInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedInputValues = [...investigationInputValue]
    updatedInputValues[index] = e.target.value
    setInvestigationInputValue(updatedInputValues)
  }

  const handleInvestigationHistoryKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (investigationInputValue[index].trim() !== '') {
        setInvestigationInputValue([])
        const allData = investigationData

        allData[allData.length - 1] = {
          test_name: investigationInputValue[index].trim(),
          instruction: '',
        }
        allData.push({test_name: '', instruction: ''})
        const updatedInvestigationTags = [...investigationTags]
        updatedInvestigationTags[allData?.length - 2] = investigationInputValue[index].trim()

        setInvestigationTags(updatedInvestigationTags)
        const updatedInputValues = [...investigationInputValue]
        updatedInputValues[index] = ''
        setInvestigationInputValue(updatedInputValues)
      }
      e.preventDefault()
    }
  }

  return (
    <div className='overflow-y-hidden'>
      <h4>Investigation</h4>
      <table className='table table-bordered table-light dp-investigation-table'>
        <thead className=''>
          <tr>
            <th scope='col'>Test Prescribed</th>
            <th scope='col'>Instruction</th>
          </tr>
        </thead>
        <tbody>
          {investigationData.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <TagsInput
                  value={investigationTags[index] ? [investigationTags[index]] : []}
                  onChange={(newTags) => {
                    const updatedTags = [...investigationTags]
                    updatedTags[index] = newTags[0]
                    setInvestigationTags(updatedTags)
                  }}
                  tagProps={{
                    className:
                      'react-tagsinput-tag btn btn-sm btn-light-primary btn-active-light-primary shadow',
                    onRemove: (index2: any) =>
                      handleInvestigationHisoryTagRemove(investigationTags[index], index),
                  }}
                  inputProps={{
                    disabled: investigationData[index].test_name.length > 0,
                    placeholder:
                      investigationData[index].test_name.length > 0
                        ? ''
                        : intl.formatMessage({id: 'GENERAL.ADD.TEST_NAME'}),
                    value: investigationInputValue[index],
                    onChange: (e: any) => handleInvestigationInputChange(e, index),
                    onKeyDown: (e: any) => handleInvestigationHistoryKeyDown(e, index),
                  }}
                  className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
                  maxTags={1}
                />
              </td>
              <td>
                <input
                  className='form-control'
                  type='text'
                  list='instruction_values'
                  name='instruction_values'
                  placeholder='Enter instruction here'
                  id='instruction_id'
                  value={item?.instruction}
                  onChange={(e) => {
                    const updatedInvestigationData = [...investigationData]
                    updatedInvestigationData[index] = {
                      ...updatedInvestigationData[index],
                      instruction: e.target.value,
                    }
                    setInvestigationData(updatedInvestigationData)
                  }}
                />
                <datalist id='instruction_values'>
                  <option value='Avoid alcohol 1-2 days before the test' />
                  <option value='Avoid physical activity before the test' />
                  <option value='After one week' />
                  <option value='To be repeated every 2 weeks' />
                  <option value='To be done on empty stomach' />
                  <option value='Do not smoke prior to the test' />
                </datalist>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='preMedicalValueWrapper my-5 mx-2'>
        {defaultInvestigationHistoryFiltered.map((value: any) => (
          <span
            className='medicalValueTag btn btn-sm'
            key={value}
            onClick={() => handleInvestigationHistoryTagClick(value)}
          >
            {value}
          </span>
        ))}
      </div>
    </div>
  )
}

export default Investigation
