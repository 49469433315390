import {InventoryCategoryListToolbar} from './InventoryCategoryListToolbar'
import {InventoryCategoryListSearchComponent} from './InventoryCategoryListSearchComponent'

const InventoryCategoryListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <InventoryCategoryListSearchComponent />
      <div className='card-toolbar'>
        <InventoryCategoryListToolbar />
      </div>
    </div>
  )
}

export {InventoryCategoryListHeader}
