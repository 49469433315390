import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialAppointment, Appointment} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createAppointment, getDoctorDataAva, getPatientsforAppointment} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {AppointmentsListLoading} from '../components/loading/AppointmentsListLoading'
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import {getPatientById} from '../../../Patient-management/PatientList/core/_requests'
import {toast} from 'react-toastify'
import {LaterAppointment} from '../Later/LaterAppointment'
import {LaterNewPatient} from '../LaterNewPatient/LaterNewPatient'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: Appointment
  showModal: (value: boolean) => void
  fetchAppointments: (value: object) => void
}

function mapDataToNodes(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({value: item?.full_name, label: item?.full_name, id: item?.id})
  })

  return parent
}

function mapDataToNodesDoctor(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({
      value: item?.doctor?.full_name,
      label: item?.doctor?.full_name,
      id: item?.doctor?.id,
    })
  })
  return parent
}

const AppointmentEditModalForm: FC<Props> = ({
  user,
  isUserLoading,
  showModal,
  fetchAppointments,
}: any) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [newPatient, setNewPatient] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [patientData, setPatientData] = useState(null)
  const [dob, setDob] = useState<Date | null>(null)
  const [doctorData, setDoctorData] = useState([])
  const [selectedData, setSelectedData] = useState<any>(null)
  const {selectedHospitalId} = useContext(loginContext)
  const [filteredDoctorData, setFilteredDoctorData] = useState<any>(null)
  const {tabSelection, setTabSelection} = useContext(loginContext)
  const [userForEdit] = useState<Appointment>({
    ...user,
    patient_id: user.patient_id || initialAppointment.patient_id,
    date: user.date || initialAppointment.date,
    slot: user.slot || initialAppointment.slot,
    description: user.description || initialAppointment.description,
    doctor_id: user.doctor_id || initialAppointment.doctor_id,
    full_name: user?.patient?.full_name || initialAppointment.patient?.full_name,
    gender: user?.patient?.gender || initialAppointment.patient?.gender,
    contact_number: user?.patient?.contact_number || initialAppointment.patient?.contact_number,

    date_of_birth: user?.patient?.date_of_birth || initialAppointment?.patient?.date_of_birth,
    email: user?.patient?.email || initialAppointment?.patient?.email,
    blood_group: user?.patient?.blood_group || initialAppointment?.patient?.blood_group,
  })

  const {loginData} = useContext(loginContext)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    } else {
      showModal(false)
    }
    setItemIdForUpdate(undefined)
  }

  const handlesave = () => {
    showModal(false)
  }

  let editUserSchema
  if (newPatient === false) {
    editUserSchema = Yup.object().shape({
      patient_id: Yup.string().required('Please select Patient'),
      description: Yup.string().required('Description is required'),
      // doctor_id: Yup.string().required('Please select Doctor'),
    })
  } else {
    editUserSchema = Yup.object().shape({
      full_name: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .min(3, 'Minimum 3 Characters')
        .max(50, 'Maximum 50 Characters')
        .required('Patient Full Name is required')
        .matches(/^[A-Za-z\s]+$/, 'Number not allowed'),
      email: Yup.string()
        .email('Invalid email format')
        .min(3, 'Minimum 3 Characters')
        .max(50, 'Maximum 50 Characters')
        .required('Email is required'),

      descriptions: Yup.string().test(
        'noLeadingSpaces',
        'Invalid Input, Please avoid leading spaces at the beginning of the field',
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      values['contact_number'] = values?.contact_number && values?.contact_number.toString()
      if (checkUserType === 'DOCTOR') {
        values['doctor_id'] = Number(loginData?.data?.personal_details?.id)
      }
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          // await updateAppointment(values)
        } else {
          await createAppointment(
            {
              ...values,
              hospital_id: Number(selectedHospitalId),
              type: tabSelection,
            },
            {doctor_appointment_id: filteredDoctorData?.id}
          ).then((res: any) => {
            toast.success(res?.data?.message)
            fetchAppointments()
            showModal(false)
          })
        }
      } catch (ex: any) {
        toast.warning(ex.response?.data?.message)
        showModal(false)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleChange = (e: any) => {
    setNewPatient(e.target.checked)
  }

  const handleChangeOption = (selectedOption: any) => {
    formik.setFieldValue('patient_id', Number(selectedOption.id))
    setSelectedOption(selectedOption)
  }

  const handleDoctorChange = (selectedDoctor: any) => {
    const selectedDoctorData = doctorData.find(
      (doctorEntry: any) => doctorEntry?.doctor?.id === selectedDoctor?.id
    )

    setFilteredDoctorData(selectedDoctorData)
    formik.setFieldValue('doctor_id', Number(selectedDoctor.id))
    setSelectedDoctor(selectedDoctor)
  }

  const fecthPatient = async () => {
    const allPatient: any = await getPatientsforAppointment()

    const activePatientData = allPatient?.data?.filter((item: any) => {
      return item?.status === 'ACTIVE' && item?.hospital_id == selectedHospitalId
    })
    if (activePatientData) {
      setPatientData(activePatientData)
    }
  }

  // const fetchDoctor = async () => {
  //   const allDoctorData: any = await getDoctors()

  //   const activeDoctorData = allDoctorData?.data?.data?.filter((item: any) => {
  //     return item?.hospital?.id == selectedHospitalId
  //   })
  //   if (allDoctorData) {
  //     setDoctorData(activeDoctorData)
  //   }
  // }

  const fetchDoctor = async () => {
    const allDoctorData: any = await getDoctorDataAva(selectedHospitalId, tabSelection)

    const uniqueAppointments = Object.values(
      allDoctorData?.data?.data.reduce((acc: any, appointment: any) => {
        if (!acc[appointment.doctor_id]) {
          acc[appointment.doctor_id] = appointment
        }
        return acc
      }, {})
    )

    const activeDoctorData: any = uniqueAppointments?.filter((item: any) => {
      return item?.hospital_id == selectedHospitalId
    })
    if (allDoctorData) {
      setDoctorData(activeDoctorData)
    }
  }

  const options: any = mapDataToNodes(patientData)
  const optionsDoctor: any = mapDataToNodesDoctor(doctorData)

  useEffect(() => {
    fetchDoctor()
  }, [tabSelection])

  useEffect(() => {
    fecthPatient()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (selectedOption) {
        const allDoctorData: any = await getPatientById(selectedOption?.id)
        setSelectedData(allDoctorData)
      }
    })()
  }, [selectedOption])

  const past_date_disable = new Date(moment().format('YYYY-MM-DD'))
  const future_date_disable = new Date(moment().format('YYYY-MM-DD'))

  const tabHandleChange = (value: any) => {
    setTabSelection(value)
  }

  let bloodGroupText = ''
  if (selectedData?.blood_group === 'A_POSITIVE') {
    bloodGroupText = 'A+'
  } else if (selectedData?.blood_group === 'B_POSITIVE') {
    bloodGroupText = 'B+'
  } else if (selectedData?.blood_group === 'O_POSITIVE') {
    bloodGroupText = 'O+'
  } else if (selectedData?.blood_group === 'AB_POSITIVE') {
    bloodGroupText = 'AB+'
  } else if (selectedData?.blood_group === 'A_NEGATIVE') {
    bloodGroupText = 'A-'
  } else if (selectedData?.blood_group === 'B_NEGATIVE') {
    bloodGroupText = 'B-'
  } else if (selectedData?.blood_group === 'O_NEGATIVE') {
    bloodGroupText = 'O-'
  } else if (selectedData?.blood_group === 'AB_NEGATIVE') {
    bloodGroupText = 'AB-'
  }

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  const checkUserType = loginData?.data?.user_type

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 border-0'>
          <li className='nav-item me-3' onClick={() => tabHandleChange('NOW')}>
            <a className='nav-link active m-0' data-bs-toggle='tab' href='#kt_tab_pane_4'>
              {intl.formatMessage({id: 'GENERAL.NOW'})}
            </a>
          </li>
          <li className='nav-item ms-3' onClick={() => tabHandleChange('LATER')}>
            <a className='nav-link m-0' data-bs-toggle='tab' href='#kt_tab_pane_5'>
              {intl.formatMessage({id: 'GENERAL.LATER'})}
            </a>
          </li>
        </ul>

        <div>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input h-20px w-30px'
              type='checkbox'
              name='newPatient'
              checked={newPatient}
              onChange={handleChange}
            />
            <label className='form-check-label' htmlFor='flexSwitchDefault'>
              {intl.formatMessage({id: 'GENERAL.NEW.PATIENT'})}
            </label>
          </div>
        </div>
      </div>

      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div
              className='d-flex flex-column  me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {(checkUserType === 'SUPER_ADMIN' || checkUserType === 'STAFF') && (
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>
                    {intl.formatMessage({id: 'PATIENT_APPOINTMENT.DOCTORS'})}
                  </label>
                  <Select
                    value={selectedDoctor}
                    onChange={handleDoctorChange}
                    options={optionsDoctor}
                    styles={customStyles}
                    placeholder={intl.formatMessage({id: 'DOCTOR.APPOINTMENT.SELECT.DOCTOR'})}
                  />
                  {formik.touched.doctor_id && formik.errors.doctor_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.doctor_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {newPatient === true ? (
                <>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'GENERAL.PATIENT.FULL.NAME'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'GENERAL.PATIENT.FULL.NAME'})}
                      {...formik.getFieldProps('full_name')}
                      type='text'
                      name='full_name'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.full_name && formik.errors.full_name},
                        {
                          'is-valid': formik.touched.full_name && !formik.errors.full_name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.full_name && formik.errors.full_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.full_name}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-7'>
                    <label className='fw-bold fs-6 mb-5'>
                      {intl.formatMessage({id: 'GENERAL.GENDER'})}
                    </label>

                    <div className='d-flex gap-4'>
                      <div className='d-flex fv-row'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input me-3'
                            {...formik.getFieldProps('gender')}
                            name='gender'
                            type='radio'
                            value='MALE'
                            id='kt_modal_update_role_option_0'
                            checked={formik.values.gender === 'MALE'}
                            disabled={formik.isSubmitting || isUserLoading}
                          />

                          <label
                            className='form-check-label'
                            htmlFor='kt_modal_update_role_option_0'
                          >
                            <div className='fw-bolder text-gray-800'>
                              {intl.formatMessage({id: 'GENERAL.MALE'})}
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className='d-flex fv-row'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input me-3'
                            {...formik.getFieldProps('gender')}
                            name='gender'
                            type='radio'
                            value='FEMALE'
                            id='kt_modal_update_role_option_1'
                            checked={formik.values.gender === 'FEMALE'}
                            disabled={formik.isSubmitting || isUserLoading}
                          />

                          <label
                            className='form-check-label'
                            htmlFor='kt_modal_update_role_option_1'
                          >
                            <div className='fw-bolder text-gray-800'>
                              {intl.formatMessage({id: 'GENERAL.FEMALE'})}
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className='d-flex fv-row'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input me-3'
                            {...formik.getFieldProps('gender')}
                            name='gender'
                            type='radio'
                            value='OTHER'
                            id='kt_modal_update_role_option_2'
                            checked={formik.values.gender === 'OTHER'}
                            disabled={formik.isSubmitting || isUserLoading}
                          />

                          <label
                            className='form-check-label'
                            htmlFor='kt_modal_update_role_option_2'
                          >
                            <div className='fw-bolder text-gray-800'>
                              {intl.formatMessage({id: 'GENERAL.OTHER'})}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2 label_email'>
                        {intl.formatMessage({id: 'GENERAL.EMAIL'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'GENERAL.EMAIL'})}
                        {...formik.getFieldProps('email')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.email && formik.errors.email},
                          {
                            'is-valid': formik.touched.email && !formik.errors.email,
                          },
                          'width_email'
                        )}
                        type='email'
                        name='email'
                        autoComplete='off'
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block msg_email'>
                            <span role='alert'>{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2 label_contact'>
                        {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                        {...formik.getFieldProps('contact_number')}
                        type='number'
                        name='contact_number'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          // {
                          //   'is-invalid':
                          //     formik.touched.contact_number && formik.errors.contact_number,
                          // },
                          // {
                          //   'is-valid':
                          //     formik.touched.contact_number && !formik.errors.contact_number,
                          // },
                          'width_contact'
                        )}
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.contact_number && formik.errors.contact_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.contact_number}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'GENERAL.PATIENT'})}
                    </label>

                    <Select
                      // {...formik.getFieldProps('patient_id')}
                      value={selectedOption}
                      onChange={handleChangeOption}
                      // name='patient_id'
                      options={options}
                      styles={customStyles}
                      placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.PATIENT'})}
                    />
                    {formik.touched.patient_id && formik.errors.patient_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.patient_id}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  {selectedData ? (
                    <>
                      <div className='row'>
                        <div className='fv-row mb-7 col-6'>
                          <label className='fw-bold fs-6 mb-2'>
                            {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                          </label>
                          <div>{selectedData?.contact_number}</div>

                          {/* <input
                            placeholder='Contact Number'
                            value={selectedData?.contact_number}
                            // {...formik.getFieldProps('contact_number')}
                            type='text'
                            className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                            autoComplete='off'
                            disabled
                          /> */}
                        </div>

                        <div className='fv-row mb-7 col-6'>
                          <label className='fw-bold fs-6 mb-2'>
                            {intl.formatMessage({id: 'GENERAL.DOB'})}
                          </label>
                          <div>{moment(selectedData?.date_of_birth).format('DD-MM-YYYY')}</div>
                          {/* <div className='position-relative'>
                            <ReactDatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              value={moment(selectedData?.date_of_birth).format('DD-MM-YYYY')}
                              // {...formik.getFieldProps('date_of_birth')}
                              dropdownMode='select'
                              className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                              dateFormat='dd-MM-yyyy'
                              // minDate={past_date_disable}
                              // selected={dob}
                              onChange={(date: any) => {
                                setDob(date)
                              }}
                              autoComplete='off'
                              disabled
                              placeholderText='Date Of Birth'
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='fv-row mb-7 col-6'>
                          <label className='fw-bold fs-6 mb-2'>
                            {intl.formatMessage({id: 'GENERAL.EMAIL'})}
                          </label>
                          <div>{selectedData?.email === null ? '' : selectedData?.email}</div>
                          {/* <input
                            placeholder='Email'
                            // {...formik.getFieldProps('email')}
                            value={selectedData?.email === null ? '' : selectedData?.email}
                            className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                            type='email'
                            autoComplete='off'
                            disabled
                          /> */}
                        </div>

                        <div className='fv-row mb-7 col-6'>
                          <label className='fw-bold fs-6 mb-2'>
                            {intl.formatMessage({id: 'GENERAL.BLOOD.GROUP'})}
                          </label>
                          <div>{bloodGroupText}</div>
                          {/* <select
                            className='form-select bg-light'
                            aria-label='Select example'
                            value={selectedData?.blood_group}
                            disabled
                          >
                            <option>Blood Group</option>
                            <option value='A_POSITIVE'>A+</option>
                            <option value='B_POSITIVE'>B+</option>
                            <option value='O_POSITIVE'>O+</option>
                            <option value='AB_POSITIVE'>AB+</option>
                            <option value='A_NEGATIVE'>A-</option>
                            <option value='B_NEGATIVE'>B-</option>
                            <option value='O_NEGATIVE'>O-</option>
                            <option value='AB_NEGATIVE'>AB-</option>
                          </select> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}

              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'PATIENT_APPOINTMENT.APPOINTMENT.DESCRIPTION'})}
                </label>

                <textarea
                  {...formik.getFieldProps('descriptions')}
                  className='form-control'
                  name='description'
                  id='exampleFormControlTextarea1'
                  disabled={formik.isSubmitting || isUserLoading}
                  rows={3}
                  placeholder={intl.formatMessage({
                    id: 'PATIENT_APPOINTMENT.APPOINTMENT.DESCRIPTION',
                  })}
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.description}</span>
                    </div>
                  </div>
                )}
                {/* <input
                  placeholder='Appointment Description'
                  {...formik.getFieldProps('description')}
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  type='text'
                  name='description'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                /> */}

                {/* {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.description}</span>
                  </div>
                )} */}
              </div>
            </div>
            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => {
                  cancel()
                  showModal(false)
                }}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={formik.isSubmitting || isUserLoading}
              >
                {intl.formatMessage({id: 'GENERAL.CLOSE'})}
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={
                  isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
                {(formik.isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
          {(formik.isSubmitting || isUserLoading) && <AppointmentsListLoading />}
        </div>

        <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
          {tabSelection === 'LATER' && newPatient === true ? (
            <LaterNewPatient
              isUserLoading={false}
              user={{
                id: undefined,
                hospital_id: undefined,
                patient_id: undefined,
                doctor_id: undefined,
                date: undefined,
                slot: undefined,
                full_name: undefined,
                description: undefined,
                gender: undefined,
                contact_number: undefined,
                // alternate_contact_number: undefined,
                date_of_birth: undefined,
                email: undefined,
                blood_group: undefined,
                status: undefined,
                initials: undefined,
                patient: undefined,
                appointment_details: undefined,
              }}
              showModal={showModal}
              fetchAppointments={fetchAppointments}
              // tabSelection={tabSelection}
            />
          ) : (
            <LaterAppointment
              isUserLoading={false}
              user={{
                id: undefined,
                hospital_id: undefined,
                patient_id: undefined,
                doctor_id: undefined,
                date: undefined,
                slot: undefined,
                full_name: undefined,
                description: undefined,
                gender: undefined,
                contact_number: undefined,
                // alternate_contact_number: undefined,
                date_of_birth: undefined,
                email: undefined,
                blood_group: undefined,
                status: undefined,
                initials: undefined,
                patient: undefined,
                appointment_details: undefined,
              }}
              showModal={showModal}
              fetchAppointments={fetchAppointments}
              // tabSelection={tabSelection}
            />
          )}
        </div>
      </div>
    </>
  )
}

export {AppointmentEditModalForm}
