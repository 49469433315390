import {MedicationListToolbar} from './MedicationListToolbar'
import {MedicationListSearchComponent} from './MedicationListSearchComponent'

const MedicationListHeader = () => {
  return (
    <div className='card-header border-0 px-0'>
      <MedicationListSearchComponent />
      <div className='card-toolbar'>
        <MedicationListToolbar />
      </div>
    </div>
  )
}

export {MedicationListHeader}
