import React, {FC, useState} from 'react'
import DatePicker from 'react-datepicker'
import {useIntl} from 'react-intl'
interface Props {
  setAppEndDate: any
  setAppStartDate: any
  appstartDate: any
  appendDate: any
  handleApply: any
}

const AppointmentFilter: FC<Props> = ({
  setAppEndDate,
  setAppStartDate,
  appstartDate,
  appendDate,
}) => {
  const [dateRange, setDateRange] = useState<Date[] | null>([appstartDate, appendDate])
  const intl = useIntl()

  const handleDateChange = (update: Date | [Date | null, Date | null] | null) => {
    if (Array.isArray(update)) {
      const [appstartDate, appendDate] = update
      setAppStartDate(appstartDate)
      setAppEndDate(appendDate)
      setDateRange(update as Date[] | null)
    }
  }
  return (
    <>
      <label className='form-label fw-bold'>
        {intl.formatMessage({id: 'GENERAL.APPOINTMENT.TITLE'})}
      </label>
      <div className='position-relative mb-3'>
        <DatePicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          selectsRange={true}
          startDate={appstartDate}
          endDate={appendDate}
          name='dateRange'
          selectsEnd={appendDate}
          selectsStart={appstartDate}
          dateFormat='dd-MM-yyyy'
          className='form-control form-control-solid cursor-pointer mb-3 mb-lg-0'
          onChange={handleDateChange}
          shouldCloseOnSelect={false}
          autoComplete='off'
          onKeyDown={(e) => {
            e.preventDefault()
          }}
        />
        <span
          className='DatePicker_icon cursor-pointer'
          onClick={() => document.getElementsByName('dateRange')[0].focus()}
        >
          <div className='fa fa-calendar'></div>
        </span>
      </div>
    </>
  )
}

export {AppointmentFilter}
