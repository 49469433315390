import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PatientBook, initialPatientBook} from './core/_model'

import {
  createAppointment,
  getDoctorData1,
  getDoctorDataAvail,
  getPatientAppointmentById,
  updatePatientAppointment,
} from './core/_request'
import Select from 'react-select'
import moment from 'moment'
import {toast} from 'react-toastify'
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  startOfDay,
  isSameWeek,
} from 'date-fns'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {getHospitals} from '../landing-page/core/_request'
import OpLayout from '../outer-patient-modules/OpLayout'

type Props = {
  isUserLoading: boolean
  user: PatientBook
}
interface LocationState {
  doctorId?: string
  patientSelectedHospitalId?: string
  appoint_id?: string
  appoint_date?: string
}

const PatientBooking: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth))
  // const [selectedDate, setSelectedDate] = useState(new Date())
  const [timeSlot, setTimeSlot] = useState<any>(null)
  const [selectedSlots, setSelectedSlots] = useState<string[]>([])
  const [accessToken, setAccessToken] = useState<any>(null)
  const [localData, setLocalData] = useState<any>(null)
  //hospital and doctor states
  const [hospitalData, setHospitalData] = useState<any>(null)
  const [patientSelectedHospital, setPatientSelectedHospital] = useState<any>(null)
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null)
  const [doctorData, setDoctorData] = useState([])
  const [defaultDoctor, setDefaultDoctor] = useState<any>(null)
  const currentDate = moment().format('YYYY-MM-DD')
  const [patientAppData, setPatientAppData] = useState<any>(null)
  const [alreadyBookedSlot, setAlreadyBookedSlot] = useState<any>([])

  const {state} = location
  const {doctorId, patientSelectedHospitalId, appoint_id, appoint_date}: LocationState =
    (state as LocationState) || {}
  const [selectedDate, setSelectedDate] = useState(
    appoint_date ? moment(appoint_date).toDate() : new Date()
  )

  const [userForEdit] = useState<PatientBook>({
    ...user,
    doctor_id: user.doctor_id || initialPatientBook.doctor_id,
    patient_id: user.patient_id || initialPatientBook?.patient_id,
    description: user.description || initialPatientBook.description,
    slot: user.slot || initialPatientBook.slot,
    date: user.date || initialPatientBook.date,
  })

  const cancel = (withRefresh?: boolean) => {
    window.history.back()
  }

  useEffect(() => {
    if (appoint_id !== undefined) {
      getPatientAppointmentById(appoint_id).then((res: any) => {
        setPatientAppData(res)
        formik.setFieldValue('description', res?.description)
      })
    }
  }, [])

  const getThemeFromLocalStorage = () => {
    return localStorage.getItem('kt_theme_mode_menu')
  }
  const theme = getThemeFromLocalStorage()
  // const customStyles = {
  //   option: (provided: any, state: any) => ({
  //     ...provided,
  //     backgroundColor: theme === 'dark' ? '#424242' : state.isSelected ? '#009ef7' : '#fff',
  //     color: theme === 'dark' ? '#fff' : state.isSelected ? '#000' : '#000',
  //   }),
  // }
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  useEffect(() => {
    const storedData = localStorage?.getItem('loginData')
    if (storedData) {
      const parsedData = JSON.parse(storedData)
      setAccessToken(parsedData?.data?.access_token)
      setLocalData(parsedData)
    }
  }, [])

  let editUserSchema

  editUserSchema = Yup.object().shape({
    description: Yup.string().required('Desctiption is required'),
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const date_Data = moment(selectedDate).format('DD-MM-YYYY')
      values['appointment_date'] = date_Data

      if (patientSelectedHospital === null || selectedDoctor === null) {
        return false
      } else if (selectedSlots?.length > 0) {
        values['slot'] = selectedSlots
      } else {
        toast.error('Please select atleast one slot before saving')
        return false
      }

      values['hospital_id'] =
        Number(patientSelectedHospital?.value) || Number(patientSelectedHospitalId)
      values['doctor_id'] = Number(selectedDoctor?.value) || Number(doctorId)

      // Find the appropriate doctor appointment id based on current time
      const matchingSlots = timeSlot?.data?.data.filter((slot: any) => {
        const startTime = moment(`${slot.date} ${slot.start_time}`, 'YYYY-MM-DD HH:mm')
        const endTime = moment(`${slot.date} ${slot.end_time}`, 'YYYY-MM-DD HH:mm')

        return selectedSlots.some((selectedTime) => {
          const selectedDateTime = moment(`${slot.date} ${selectedTime}`, 'YYYY-MM-DD HH:mm')
          return selectedDateTime.isBetween(startTime, endTime, null, '[]')
        })
      })
      let doctorAppointmentId
      if (matchingSlots?.length > 0) {
        doctorAppointmentId = matchingSlots[0]?.id
      }

      setSubmitting(true)
      try {
        if (appoint_id) {
          const data = {
            ...values,
            doctor_appointment_id: doctorAppointmentId,
          }
          await updatePatientAppointment(Number(appoint_id), data).then((res: any) => {
            toast.success(res?.data?.message)
            navigate('/patient-appointment')
          })
        } else {
          await createAppointment(
            {
              ...values,
              patient_id: Number(localData?.data?.patient_id),
              type: 'LATER',
            },
            accessToken,
            {doctor_appointment_id: doctorAppointmentId}
          ).then((res: any) => {
            toast.success(res?.data?.message)
            navigate('/patient-appointment')
          })
        }
      } catch (ex: any) {
        toast.warning(ex.response?.data?.message)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleDoctorChange = (selectedDoctor: any) => {
    setSelectedDoctor(selectedDoctor)
  }

  const handleHospitalChange = (selectedOption: any) => {
    setPatientSelectedHospital(selectedOption)
  }

  const fetchDoctor = async () => {
    let newHospitalId: any
    if (patientSelectedHospital !== null) {
      newHospitalId = patientSelectedHospital?.value
    } else {
      newHospitalId = patientSelectedHospitalId
    }

    const allDoctorData: any = await getDoctorDataAvail(newHospitalId, accessToken)

    const uniqueAppointments = Object.values(
      allDoctorData?.data?.data.reduce((acc: any, appointment: any) => {
        if (!acc[appointment.doctor_id]) {
          acc[appointment.doctor_id] = appointment
        }
        return acc
      }, {})
    )
    const activeDoctorData: any = uniqueAppointments?.filter((item: any) => {
      return item?.hospital_id == newHospitalId
    })

    const mappedDoctorData = activeDoctorData?.map((item: any) => {
      return {value: item?.doctor_id, label: item?.doctor?.full_name, id: item?.doctor_id}
    })

    if (mappedDoctorData) {
      if (doctorId) {
        const selectedDoctorOption = mappedDoctorData?.find((item: any) => item?.id === doctorId)
        setSelectedDoctor(selectedDoctorOption)
        formik.setFieldValue('doctor_id', Number(selectedDoctorOption?.value))
        setDefaultDoctor(selectedDoctorOption)
      } else {
        setSelectedDoctor(null)
      }
      setDoctorData(mappedDoctorData)
    }
  }

  const fetchHospitals = () => {
    getHospitals().then((res) => {
      const mappedData = res?.data?.data?.map((item: any) => {
        return {value: item?.id, label: item?.name, id: item?.id}
      })

      let newHospitalId: any
      if (patientSelectedHospital !== null) {
        newHospitalId = patientSelectedHospital?.value
      } else {
        newHospitalId = patientSelectedHospitalId
      }

      if (newHospitalId === undefined) {
        setPatientSelectedHospital(null)
      } else {
        const selectedHospitalOption = mappedData?.find(
          (hospital: any) => hospital?.id === newHospitalId
        )
        setPatientSelectedHospital(selectedHospitalOption)
      }
      setHospitalData(mappedData)
    })
  }

  useEffect(() => {
    fetchHospitals()
  }, [])

  useEffect(() => {
    if (accessToken) {
      fetchDoctor()
    }
  }, [accessToken, patientSelectedHospital, patientSelectedHospitalId])

  // const changeWeekHandle = (btnType: any) => {
  //   if (btnType === 'prev') {
  //     setCurrentMonth((prevMonth) => {
  //       const newSelectedDate = isCurrentWeek(subWeeks(prevMonth, 1))
  //         ? startOfDay(new Date())
  //         : startOfWeek(subWeeks(prevMonth, 1), {weekStartsOn: 1})
  //       setCurrentWeek(getWeek(subWeeks(prevMonth, 1)))
  //       setSelectedDate(newSelectedDate)
  //       return subWeeks(prevMonth, 1)
  //     })
  //   }

  //   if (btnType === 'next') {
  //     setCurrentMonth((prevMonth) => {
  //       const newSelectedDate = isCurrentWeek(addWeeks(prevMonth, 1))
  //         ? startOfDay(new Date())
  //         : startOfWeek(addWeeks(prevMonth, 1), {weekStartsOn: 1})
  //       setCurrentWeek(getWeek(addWeeks(prevMonth, 1)))
  //       setSelectedDate(newSelectedDate)
  //       return addWeeks(prevMonth, 1)
  //     })
  //   }
  // }
  // const isCurrentWeek = (date: Date): boolean => {
  //   const currentWeek = getWeek(new Date())
  //   const weekToCheck = getWeek(date)
  //   return currentWeek === weekToCheck
  // }

  const changeWeekHandle = (btnType: 'prev' | 'next') => {
    setSelectedSlots([])
    setCurrentMonth((prevMonth) => {
      const today = startOfDay(new Date())
      const currentSelectedDate = selectedDate ?? today

      let newSelectedDate: Date = today

      if (btnType === 'prev') {
        const prevWeek = subWeeks(currentSelectedDate, 1)
        newSelectedDate = isSameWeek(prevWeek, prevMonth)
          ? today
          : startOfWeek(prevWeek, {weekStartsOn: 1})
      } else if (btnType === 'next') {
        const nextWeek = addWeeks(currentSelectedDate, 1)
        newSelectedDate = isSameWeek(nextWeek, prevMonth)
          ? today
          : startOfWeek(nextWeek, {weekStartsOn: 1})
      }

      setCurrentWeek(getWeek(newSelectedDate, {weekStartsOn: 1}))
      setSelectedDate(newSelectedDate)

      return prevMonth
    })
  }

  const onDateClickHandle = (day: any, dayStr: any) => {
    setSelectedSlots([])
    setSelectedDate(day)
  }

  const renderHeader = () => {
    const dateFormat = 'MMM yyyy'
    const currentWeek = getWeek(selectedDate, {weekStartsOn: 1})

    return (
      <div className='row flex-middle mt-5'>
        {/* <div className='col col-center'>
          <span>{format(currentMonth, dateFormat)}</span>
        </div> */}
        <div className='col-4 col-start'>
          <div className='icon' onClick={() => changeWeekHandle('prev')}>
            <i className='fa-solid fa-circle-arrow-left fs-2'></i>
          </div>
        </div>
        <div className='col-4 col-center'>
          <span>Week {currentWeek} &nbsp;</span>
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className='col-4 col-end' onClick={() => changeWeekHandle('next')}>
          <div className='icon'>
            <i className='fa-solid fa-circle-arrow-right fs-2'></i>
          </div>
        </div>
      </div>
    )
  }

  const renderDays = () => {
    const dateFormat = 'EEE'
    const days = []
    let startDate = startOfWeek(currentMonth, {weekStartsOn: 1})
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className='col col-center' key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      )
    }
    return <div className='days row'>{days}</div>
  }

  const renderCells = () => {
    const startDate = startOfWeek(selectedDate, {weekStartsOn: 1})
    const endDate = lastDayOfWeek(selectedDate, {weekStartsOn: 1})
    // const startDate = startOfWeek(currentMonth, {weekStartsOn: 1})
    // const endDate = lastDayOfWeek(currentMonth, {weekStartsOn: 1})
    const dateFormat = 'd'
    const rows = []
    let days = []
    let day = startDate
    let formattedDate = ''
    const currentDate = new Date()

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat)
        const cloneDay = day
        days.push(
          <div
            className={`col cell ${
              isSameDay(day, currentDate) || day > currentDate ? '' : 'disabled'
            } ${isSameDay(day, selectedDate) ? 'selected' : ''}`}
            onClick={() => {
              if (day > currentDate) {
                const dayStr = format(cloneDay, 'ccc dd MMM yy')
                onDateClickHandle(cloneDay, dayStr)
                setSelectedDate(cloneDay)
              }
            }}
          >
            <span className='number'>{formattedDate}</span>
            <span className='bg'>{formattedDate}</span>
          </div>
        )
        day = addDays(day, 1)
      }

      rows.push(<div className='row'>{days}</div>)
      days = []
    }

    return <div className='body'>{rows}</div>
  }

  const getPatientavailabilityData = async () => {
    if (selectedDoctor?.id) {
      const day = moment(selectedDate).format('dddd').toUpperCase()
      const date = moment(selectedDate).format('DD-MM-YYYY')

      if (selectedDoctor?.id && date) {
        const doctorSlotsData: any = await getDoctorData1(
          selectedDoctor?.id,
          accessToken,
          // date
          date
        )
        setTimeSlot(doctorSlotsData)
      } else {
        const doctorSlotsData = await getDoctorData1(selectedDoctor?.id, accessToken)
        setTimeSlot(doctorSlotsData)
      }
    }
  }

  useEffect(() => {
    getPatientavailabilityData()
  }, [selectedDoctor, selectedDate])

  const now = new Date()
  const start: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
  const end: any = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0)

  // Calculate the total number of 1-hour intervals between start and end times
  const totalIntervals = Math.floor((end - start) / (60 * 60 * 1000))

  // Generate an array of timestamps with 1-hour intervals
  const timestamps: any = []
  for (let i = 0; i < totalIntervals; i++) {
    const timestamp = new Date(start.getTime() + i * 60 * 60 * 1000)
    timestamps.push(timestamp)
  }

  // Group timestamps by hour
  const timestampsByHour: any = {}
  timestamps.forEach((timestamp: any) => {
    const hour = timestamp.getHours()
    if (!timestampsByHour[hour]) {
      timestampsByHour[hour] = []
    }
    timestampsByHour[hour].push(timestamp)
  })

  let test: any = []

  timestamps?.map((timestamps: any) => {
    test.push({
      time: moment(timestamps).format('HH:mm'),
    })
  })

  let dayForCheck: any
  dayForCheck = moment(selectedDate).format('YYYY-MM-DD').toUpperCase()

  const timestamps1 = test
    .filter((timeObj: any) => {
      const time = timeObj.time
      return timeSlot?.data?.data?.some((timeSlot: any) => {
        const startHour = timeSlot.start_time.split(':')[0]
        const startMinute = timeSlot.start_time.split(':')[1]
        return (
          (startHour <= time && timeSlot.end_time > time && timeSlot.date === dayForCheck) ||
          (startHour < time &&
            startMinute >= '30' &&
            timeSlot.end_time > time &&
            timeSlot.date === dayForCheck)
        )
      })
    })
    .map((timeObj: any) => timeObj.time)

  function generateSlotForFifteenMin(selectedDate: any) {
    const slotForFifteenmin = []

    for (let i = 0; i < timestamps1?.length; i++) {
      const hourSlots = []
      const [hour, minute] = timestamps1[i].split(':')
      const startTime = moment(selectedDate).hour(hour).minute(minute).second(0)
      const endTime = moment(startTime).add(1, 'hour')

      while (startTime < endTime) {
        const slotStartTime = startTime.format('HH:mm')
        startTime.add(15, 'minutes')
        let slotEndTime = startTime.format('HH:mm')

        if (slotEndTime.slice(-2) === '30') {
          slotEndTime = moment(slotEndTime, 'HH:mm').subtract(15, 'minutes').format('HH:mm')
        } else if (slotEndTime.slice(-2) === '00') {
          slotEndTime = moment(slotEndTime, 'HH:mm').subtract(1, 'minute').format('HH:mm')
        }

        if (checkTimeSlot(selectedDate, slotStartTime, slotEndTime)) {
          hourSlots.push(slotStartTime)
        }
      }

      slotForFifteenmin.push(hourSlots)
    }

    return slotForFifteenmin
  }

  function checkTimeSlot(selectedDate: any, slotStartTime: any, slotEndTime: any) {
    const date = moment(selectedDate, 'YYYY-MM-DD')
    for (const slot of timeSlot?.data?.data) {
      const slotDate = moment(slot.date, 'YYYY-MM-DD')
      const start = moment(slot.start_time, 'HH:mm')
      const end = moment(slot.end_time, 'HH:mm')
      const slotStart = moment(slotStartTime, 'HH:mm')
      const slotEnd = moment(slotEndTime, 'HH:mm')

      if (
        slotStart.isSameOrAfter(start) &&
        slotEnd.isSameOrBefore(end) &&
        slotDate.isSame(date, 'day')
      ) {
        return true
      }
    }

    return false
  }
  const date = moment(selectedDate).format('YYYY-MM-DD')

  const selectedDate1 = date
  const slotForFifteenmin = generateSlotForFifteenMin(selectedDate1)

  const matchingAppointment = patientAppData?.appointment_details?.find((item: any) => {
    const matchingDoctor = doctorData.find((doctor: any) => item.doctor_id === doctor?.doctor?.id)
    return matchingDoctor !== undefined
  })

  const matchingDoctor: any = doctorData.find(
    (doctor: any) => matchingAppointment?.doctor_id === doctor?.doctor?.id
  )

  let filteredData: any =
    matchingDoctor !== undefined
      ? {
          id: matchingDoctor?.doctor?.id,
          full_name: matchingDoctor?.doctor?.full_name,
          label: matchingDoctor?.doctor?.full_name,
        }
      : {}

  function timeToMinutes(timeStr: any) {
    const [hours, minutes] = timeStr.split(':').map(Number)
    return hours * 60 + minutes
  }
  const shift: any = []
  let currentShiftStart = null
  let currentShiftEnd = null

  for (const timeRange of slotForFifteenmin) {
    if (timeRange?.length === 0) continue

    const currentStartTime = timeRange[0]
    const currentEndTime = timeRange[timeRange?.length - 1]

    if (currentShiftStart === null) {
      currentShiftStart = currentStartTime
      currentShiftEnd = currentEndTime
    } else {
      const gapMinutes = timeToMinutes(currentStartTime) - timeToMinutes(currentShiftEnd)
      if (gapMinutes > 15) {
        shift.push([currentShiftStart, currentShiftEnd])
        currentShiftStart = currentStartTime
      }
      currentShiftEnd = currentEndTime
    }
  }

  if (currentShiftStart !== null && currentShiftEnd !== null) {
    shift.push([currentShiftStart, currentShiftEnd])
  }

  const handleSlotClick = (slot: any) => {
    const isSelected = selectedSlots?.includes(slot)
    let updatedSelectedSlots = []
    let selectedShift: any = null
    let selectedSlotShift = null
    let isDifferentShiftSelected = false
    let inBetweenBooked

    const sameDateBookedSlots = timeSlot?.data?.slots.map((s: any) => s.slot).sort()

    if (isSelected) {
      updatedSelectedSlots = selectedSlots?.filter((selectedSlot) => selectedSlot < slot).sort()
    } else if (selectedSlots?.length === 0) {
      updatedSelectedSlots = [slot].sort()
    } else {
      const sortedSlots = selectedSlots?.concat(slot).sort()
      const reverseSortedSlot = selectedSlots?.concat(slot)
      const clickedSlotIndex = sortedSlots?.findIndex((selectedSlot) => selectedSlot === slot)

      if (clickedSlotIndex > 0 && clickedSlotIndex < sortedSlots?.length - 1) {
        updatedSelectedSlots = [slot].sort()
      } else {
        const startSlot = sortedSlots?.[0]

        const endSlot = sortedSlots[sortedSlots?.length - 1]

        const startSlotIndex = slotForFifteenmin?.findIndex((timeSlots) =>
          timeSlots?.includes(startSlot)
        )

        const endSlotIndex = slotForFifteenmin?.findIndex((timeSlots) =>
          timeSlots?.includes(endSlot)
        )

        // Find the selected shift for the clicked slot
        selectedShift = shift.find(
          (shiftRange: any) =>
            timeToMinutes(shiftRange[0]) <= timeToMinutes(slot) &&
            timeToMinutes(shiftRange[1]) >= timeToMinutes(slot)
        )

        // Find the selected shift for each currently selected slot
        const selectedSlotShifts = selectedSlots.map((selectedSlot) =>
          shift.find(
            (shiftRange: any) =>
              timeToMinutes(shiftRange[0]) <= timeToMinutes(selectedSlot) &&
              timeToMinutes(shiftRange[1]) >= timeToMinutes(selectedSlot)
          )
        )

        // Check if any selected slots belong to a different shift
        isDifferentShiftSelected = selectedSlotShifts?.some(
          (slotShift) => slotShift !== selectedShift
        )

        inBetweenBooked = sameDateBookedSlots.filter(
          (bookedSlot: any) => bookedSlot > startSlot && bookedSlot < endSlot
        )
        if (alreadyBookedSlot.length > 0) {
          inBetweenBooked = inBetweenBooked.filter((slot: any) => !alreadyBookedSlot.includes(slot))
        }

        const lastInBetweenBooked = inBetweenBooked[inBetweenBooked.length - 1]
        const firstInBetweenBooked = inBetweenBooked[0]

        // If different shift is selected, remove slots from the first shift
        if (isDifferentShiftSelected) {
          updatedSelectedSlots = selectedSlots?.filter(
            (selectedSlot) =>
              selectedSlotShifts[selectedSlots.indexOf(selectedSlot)] === selectedShift
          )
        } else {
          const slotsInRange = slotForFifteenmin
            .slice(startSlotIndex, endSlotIndex + 1)
            .flatMap((timeSlots, index) => {
              if (index === 0 && startSlotIndex < endSlotIndex) {
                return timeSlots.filter((timeSlot) => timeSlot >= startSlot && timeSlot < endSlot)
              } else if (index === endSlotIndex - startSlotIndex) {
                if (startSlotIndex === endSlotIndex) {
                  return timeSlots?.filter(
                    (timeSlot) => timeSlot > startSlot && timeSlot <= endSlot
                  )
                } else {
                  return timeSlots?.filter((timeSlot) => timeSlot <= endSlot)
                }
              } else {
                return timeSlots
              }
            })
            .filter((timeSlot, index, arr) => {
              return (
                timeSlot !== startSlot &&
                timeSlot !== endSlot &&
                index === arr?.findIndex((slot) => slot === timeSlot)
              )
            })

          if (firstInBetweenBooked || lastInBetweenBooked) {
            if (reverseSortedSlot[0] > reverseSortedSlot[reverseSortedSlot?.length - 1]) {
              updatedSelectedSlots = [startSlot, ...slotsInRange, endSlot]
                .sort()
                .filter((timeSlot) => timeSlot < firstInBetweenBooked)
            } else {
              updatedSelectedSlots = [startSlot, ...slotsInRange, endSlot]
                .sort()
                .filter((timeSlot) => timeSlot > lastInBetweenBooked)
            }
          } else {
            updatedSelectedSlots = [startSlot, ...slotsInRange, endSlot]
          }
        }
      }
    }

    setSelectedSlots(updatedSelectedSlots)
  }

  // const b: any = patientAppData?.appointment_details

  // useEffect(() => {
  //   setSelectedSlots(b?.map((item: any) => item?.slot))
  // }, [])

  const timeSlots = timeSlot?.data?.slots
  const appointmentDetails = patientAppData?.appointment_details

  const selectedSlot: any = []
  const disabledSlot: any = []
  const avaId = selectedDoctor?.id ? selectedDoctor?.id : filteredData?.id

  timeSlots?.forEach((availabilitySlot: any) => {
    let slotMatched = false
    appointmentDetails?.forEach((pSlot: any) => {
      if (availabilitySlot.slot === pSlot.slot && pSlot?.doctor_id === avaId) {
        selectedSlot.push(availabilitySlot.slot)
        slotMatched = true
      }
    })

    if (!slotMatched) {
      disabledSlot.push(availabilitySlot.slot)
    }
  })

  useEffect(() => {
    const bookedSlots = timeSlot?.data?.slots?.map((slotData: any) => slotData.slot)
    const isToday = moment(selectedDate).isSame(moment(), 'day')

    let matchingSlots

    if (isToday) {
      matchingSlots = patientAppData?.appointment_details
        ?.filter((appointment: any) => bookedSlots?.includes(appointment.slot))
        .map((appointment: any) => appointment.slot)
        .filter((matchingSlot: any) => {
          const currentDateTime = moment()
          const matchingDateTime = moment(matchingSlot, 'HH:mm')
          return matchingDateTime.isAfter(currentDateTime)
        })
    } else {
      matchingSlots = patientAppData?.appointment_details
        ?.filter((appointment: any) => bookedSlots?.includes(appointment.slot))
        .map((appointment: any) => appointment.slot)
    }

    if (matchingSlots && matchingSlots?.length > 0) {
      setSelectedSlots(matchingSlots)
      setAlreadyBookedSlot(matchingSlots)
    }
  }, [timeSlot, patientAppData, setSelectedSlots])

  return (
    <>
      <OpLayout>
        <div className='patient_booking_wrapper p-5'>
          <h1 className='text-capitalize mb-5'>Booking Appointment</h1>
          <div className='row'>
            <div className='col-md-6 appointment-form'>
              <form
                id='kt_modal_add_user_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div className='d-flex flex-column me-n7 pe-7' id='kt_modal_add_user_scroll'>
                  <div className='row'>
                    <div className='fv-row mb-7 col-6 ps-0'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.HOSPITAL.SELECT_HOSPITAL'})}
                      </label>
                      <Select
                        name='hospital_id'
                        value={patientSelectedHospital}
                        onChange={handleHospitalChange}
                        options={hospitalData}
                        styles={customStyles}
                        placeholder={intl.formatMessage({id: 'GENERAL.HOSPITAL.SELECT_HOSPITAL'})}
                      />

                      {patientSelectedHospital === null && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Hospital is required</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-7 col-6'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'PATIENT_APPOINTMENT.DOCTORS'})}
                      </label>
                      <Select
                        name='doctor_id'
                        value={selectedDoctor || defaultDoctor}
                        onChange={handleDoctorChange}
                        options={doctorData}
                        styles={customStyles}
                        placeholder={intl.formatMessage({id: 'DOCTOR.APPOINTMENT.SELECT.DOCTOR'})}
                      />
                      {selectedDoctor == null && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Doctor is required</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'PATIENT_APPOINTMENT.APPOINTMENT.DESCRIPTION'})}
                    </label>

                    <textarea
                      {...formik.getFieldProps('description')}
                      className='form-control'
                      name='description'
                      id='exampleFormControlTextarea1'
                      disabled={formik.isSubmitting || isUserLoading}
                      rows={3}
                      placeholder={intl.formatMessage({
                        id: 'PATIENT_APPOINTMENT.APPOINTMENT.DESCRIPTION',
                      })}
                    ></textarea>

                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => cancel()}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={formik.isSubmitting || isUserLoading}
                  >
                    {intl.formatMessage({id: 'GENERAL.BACK'})}
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={
                      isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched
                    }
                  >
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'GENERAL.SAVE'})}
                    </span>
                    {(formik.isSubmitting || isUserLoading) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div className='col-md-6'>
              <div className='calendar m-5'>
                {renderHeader()}
                {renderDays()}
                {renderCells()}
              </div>

              <ul className='nav nav-tabs nav-line-tabs border-bottom border-secondary m-5 fs-6 justify-content-between'>
                <li className='nav-item'>
                  <label className='fw-bold fs-6 mb-2'>
                    {intl.formatMessage({id: 'PATIENT_APPOINTMENT.SLOT.TIME'})}
                  </label>
                </li>
                <li className='nav-item'>
                  <label className='fw-bold fs-6 mb-2'>Available Slots</label>
                </li>
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
                  {timestamps1?.length > 0 ? (
                    <div className='timeline custom_timeline'>
                      {timestamps1.map((timestamp: any, index: number) => (
                        <div className='timeline-item' key={index}>
                          <div className='timeline-label'>{timestamp}</div>
                          <div className='timeline-bar'></div>
                          <div className='d-flex gap-4'>
                            {timeSlot?.data?.slots?.length > 0 ? (
                              <>
                                {slotForFifteenmin[index].map((slot: string) => {
                                  const slotDateTime = moment(
                                    `${currentDate} ${slot}`,
                                    'YYYY-MM-DD HH:mm'
                                  )

                                  const isBooked = timeSlot?.data?.slots?.some((slotData: any) => {
                                    const slotDateTime = slotData.slot

                                    return slot === slotDateTime
                                  })

                                  const isSelected = selectedSlots?.includes(slot)

                                  const bookedSlots =
                                    appointmentDetails?.map((item: any) => item?.slot) || []

                                  // Filter out booked slots from disabled slots
                                  const editableSlots = disabledSlot.filter(
                                    (slot: string) => !bookedSlots.includes(slot)
                                  )

                                  const isEditable = editableSlots.includes(slot)

                                  let slotClassName = isSelected
                                    ? 'border bg-primary aaa rounded-3 btn btn-active-primary px-10 py-3'
                                    : isBooked
                                    ? 'border border-secondary rounded-3 btn btn-active-primary px-10 py-3'
                                    : 'border border-secondary rounded-3 btn btn-active-primary px-10 py-3'

                                  // const currentTime = moment()
                                  // const isSlotPassed = slotDateTime.isBefore(currentTime)
                                  // const isWithin45Minutes = slotDateTime
                                  //   .add(45, 'minutes')
                                  //   .isAfter(currentTime)

                                  const isSlotDisabled =
                                    slotDateTime.isBefore(moment()) &&
                                    moment(selectedDate).isSame(moment(), 'day')

                                  if (isSelected && isBooked) {
                                    slotClassName =
                                      'border bg-primary bbb rounded-3 btn btn-active-primary px-10 py-3'
                                  }

                                  if (isSelected) {
                                    slotClassName = isSelected
                                      ? 'border bg-primary ccc rounded-3 btn btn-active-primary px-10 py-3'
                                      : 'border border-secondary rounded-3 btn btn-active-primary px-10 py-3'
                                  }

                                  if (isEditable) {
                                    slotClassName =
                                      'border bg-primary ddd rounded-3 btn btn-active-primary px-10 py-3'
                                  }

                                  if (isSlotDisabled) {
                                    slotClassName =
                                      'border bg-gray rounded-3 btn btn-active-primary px-10 py-3 disabled'
                                  }

                                  if (isBooked && isSlotDisabled) {
                                    slotClassName =
                                      'border bg-secondary rounded-3 btn btn-active-primary px-10 py-3 disabled'
                                  }

                                  if (disabledSlot?.includes(slot)) {
                                    slotClassName +=
                                      'border bg-secondary rounded-3 btn btn-active-primary px-10 py-3 disabled'
                                  }

                                  return (
                                    <div
                                      className={slotClassName}
                                      onClick={() => {
                                        handleSlotClick(slot)
                                      }}
                                      key={slot}
                                    >
                                      {slot}
                                    </div>
                                  )
                                })}
                              </>
                            ) : (
                              <>
                                {slotForFifteenmin[index].map((slot: string, index2: number) => {
                                  const slotDateTime = moment(
                                    `${currentDate} ${slot}`,
                                    'YYYY-MM-DD HH:mm'
                                  )

                                  const isBooked = timeSlot?.data?.slots?.some((slotData: any) => {
                                    const slotDateTime = slotData.slot
                                    return slot === slotDateTime
                                  })

                                  // const isSlotDisabled = slotDateTime.isBefore(moment())
                                  const isSlotDisabled =
                                    slotDateTime.isBefore(moment()) &&
                                    moment(selectedDate).isSame(moment(), 'day')

                                  const slotClassName = isBooked
                                    ? 'border bg-gray-500 rounded-3 btn px-10 py-3'
                                    : selectedSlots.includes(slot)
                                    ? 'border bg-primary fff rounded-3 btn btn-active-primary px-10 py-3'
                                    : isSlotDisabled
                                    ? 'border bg-gray rounded-3 btn px-10 py-3'
                                    : 'border border-secondary rounded-3 btn btn-active-primary px-10 py-3'

                                  // const slotClassName = isBooked
                                  //   ? 'border bg-gray-400 rounded-3 btn px-10 py-3'
                                  //   : selectedSlots.includes(slot)
                                  //   ? 'border bg-primary rounded-3 btn btn-active-primary px-10 py-3'
                                  //   : 'border border-secondary rounded-3 btn btn-active-primary px-10 py-3'
                                  return (
                                    <div
                                      key={index2}
                                      className={`${slotClassName} ${
                                        isBooked || isSlotDisabled ? 'text-secondary' : ''
                                      }`}
                                      style={{
                                        pointerEvents: isBooked || isSlotDisabled ? 'none' : 'auto',
                                      }}
                                      onClick={() => handleSlotClick(slot)}
                                    >
                                      {slot}
                                    </div>
                                  )
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='timeline'>
                      <label className='fw-bold fs-6 m-auto'>
                        {intl.formatMessage({id: 'PATIENT_APPOINTMENT.TIME.SLOT.NOT.AVAILABLE'})}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </OpLayout>
    </>
  )
}

export default PatientBooking
