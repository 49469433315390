import {FC} from 'react'
import {AssetMaintenanceModel} from '../../core/_models'
import moment from 'moment'

type Props = {
  asset: AssetMaintenanceModel
}

const AssetMaintenanceDate: FC<Props> = ({asset}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <div className='text-gray-800 mb-1'>{moment(asset?.date).format('DD-MM-YYYY')}</div>
      </div>
    </div>
  )
}

export {AssetMaintenanceDate}
