import {PatientInsuranceListToolbar} from './PatientInsuranceListToolbar'
import {PatientInsuranceListSearchComponent} from './PatientInsuranceListSearchComponent'

const PatientInsuranceListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <PatientInsuranceListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <PatientInsuranceListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PatientInsuranceListHeader}
