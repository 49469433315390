/* eslint-disable no-lone-blocks */
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import {toast} from 'react-toastify'
import {profileDetailsInitValues} from '../core/_models'
import {updateMyProfile} from '../core/_requests'
import {AccountHeader} from './ProfileHeader'
import {PageTitle} from '../../../../../_jmh/layout/core'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_jmh/helpers'
import {RxCross2} from 'react-icons/rx'
import {Modal} from 'react-bootstrap'
import ReactSignatureCanvas from 'react-signature-canvas'
import {loginContext} from '../../../../context/context'
import clsx from 'clsx'
import Select from 'react-select'
import {getSpeciality} from '../../../landing-page/core/_request'

const profileDetailsSchema = Yup.object().shape({
  full_name: Yup.string().required('Full name is required'),
  gender: Yup.string().required('Gender is required'),
  date_of_birth: Yup.date()
    .nullable()
    .test('dob', 'Should be greater than 18', function (value: any, ctx) {
      const dob = new Date(value)
      const validDate = new Date()
      const valid = validDate.getFullYear() - dob.getFullYear() >= 18
      return !valid ? ctx.createError() : valid
    })
    .required('Required'),
  // permanent_address: Yup.string().required('Company site is required'),
  contact_number: Yup.string()
    .matches(/^\d{10}$/, 'Contact number must be a valid 10-digit number')
    .required('Contact number is required'),
})

const ProfileDetailsSettings: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const storedLoginData = localStorage.getItem('loginData')
  const loginData = storedLoginData ? JSON.parse(storedLoginData) : null

  const {
    contact_number,
    date_of_birth,
    full_name,
    gender,
    permanent_address,
    qualification,
    speciality_ids,
    new_case_fee,
  } = loginData.data.personal_details

  console.log('DATA', loginData.data.personal_details)

  console.log('speciality_ids', speciality_ids)

  const initialValues: profileDetailsInitValues = {
    full_name: full_name,
    contact_number: contact_number,
    date_of_birth: moment(date_of_birth).format('YYYY-MM-DD'),
    gender: gender,
    permanent_address: permanent_address,
    image: undefined,
    qualification: qualification,
    speciality_ids: speciality_ids,
    signature: undefined,
    new_case_fee: new_case_fee,
  }
  const [birthdate, setBirthDate] = useState<Date | null>(null)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSignatureSaved, setIsSignatureSaved] = useState(false)
  const signatureRef = useRef<ReactSignatureCanvas | null>(null)

  // const {loginData} = useContext(loginContext)

  const formik = useFormik<profileDetailsInitValues>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('values', values)
      setSubmitting(true)
      try {
        const formData = new FormData()
        formData.append('full_name', values.full_name)
        formData.append('gender', values.gender)
        formData.append('date_of_birth', values.date_of_birth)
        formData.append('permanent_address', values.permanent_address)
        formData.append('contact_number', values.contact_number)
        {
          loginData?.data?.role?.id === '1' &&
            formData.append('signature', values.signature as Blob)
        }
        formData.append('qualification', values.qualification)
        formData.append('speciality_ids', values?.speciality_ids as any)
        formData.append('new_case_fee', values?.new_case_fee as any)

        const response = await updateMyProfile(formData)

        if (response) {
          const personalDetailsObject = response
          localStorage.setItem(
            'loginData',
            JSON.stringify({
              ...loginData,
              data: {
                ...loginData?.data,
                personal_details: personalDetailsObject,
              },
            })
          )
          setTimeout(() => {
            navigate('/my-profile')
          }, 200)

          toast.success('Profile updated successfully')
        } else {
          toast.error('Failed to update profile')
        }
      } catch (ex: any) {
        toast.error(ex.response.data.message)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const maxDate = new Date(moment().subtract(18, 'years').format('YYYY-MM-DD'))

  const handleClose = () => {
    setIsModalOpen(false)
    window.history.back()
  }

  const handleOpen = (event: any) => {
    setIsModalOpen(true)
    event.preventDefault()
  }

  const handleCloseImage = () => {
    setIsSignatureSaved(false)
  }

  const handleClearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear()
    }
  }

  const handleSignatureChange = () => {
    if (signatureRef.current) {
      const signatureImage = signatureRef.current.toDataURL('image/png')

      fetch(signatureImage)
        .then((res) => res.blob())
        .then((blob) => {
          formik.setFieldValue('signature', blob)
          setIsSignatureSaved(true)
          setIsModalOpen(false) // Close the modal
        })
        .catch((error) => {
          console.error('Failed to convert data URL to Blob:', error)
        })
    }
  }

  const [specialityData, setSpecialityData] = useState([])
  const [speciality, setSpeciality] = useState([])
  const [specialityError, setSpecialityError] = useState('')
  const {selectedHospitalId} = useContext(loginContext)

  useEffect(() => {
    ;(async () => {
      const specialityData1: any = await getSpeciality()

      console.log('specialityData1', specialityData1)

      const filterSpeciality = specialityData1?.data?.data?.filter(
        (item: any) => item?.hospital_id?.id === selectedHospitalId
      )
      setSpeciality(filterSpeciality)

      setSpecialityData(
        filterSpeciality?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
            id: item?.id,
          }
        })
      )
    })()
  }, [])

  const handleSpecialityBlur = () => {
    if (
      formik?.values?.speciality_ids === undefined ||
      formik?.values?.speciality_ids.length === 0
    ) {
      setSpecialityError('Speciality is required')
    } else {
      setSpecialityError('')
    }
  }

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  console.log(' formik?.values?.speciality_ids', formik?.values?.speciality_ids)
  console.log('specialityData', specialityData)
  console.log('speciality', speciality)
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'GENERAL.PROFILE'})}</PageTitle>

      <div className='user_profile_back'>
        <img src={toAbsoluteUrl('/media/landing_asset/user_profile_back.jpg')} alt='Logo' />
      </div>
      <div className='row user_profile_row'>
        <div className='col-4'>
          <AccountHeader />
        </div>
        <div className='col-8'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>
                  {intl.formatMessage({id: 'PROFILE.EDIT_DETAILS'})}
                </h3>
              </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                className='form'
                encType='multipart/form-data'
              >
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder={intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
                        {...formik.getFieldProps('full_name')}
                      />
                      {formik.touched.full_name && formik.errors.full_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.full_name}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>{intl.formatMessage({id: 'GENERAL.GENDER'})}</span>
                    </label>

                    <div className='d-flex gap-4 col-lg-8 fv-row'>
                      <div className='d-flex fv-row'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input me-3'
                            {...formik.getFieldProps('gender')}
                            name='gender'
                            type='radio'
                            value='MALE'
                            id='kt_modal_update_role_option_0'
                            checked={formik.values.gender === 'MALE'}
                            disabled={formik.isSubmitting}
                          />

                          <label
                            className='form-check-label'
                            htmlFor='kt_modal_update_role_option_0'
                          >
                            <div className='fw-bolder text-gray-800'>
                              {intl.formatMessage({id: 'GENERAL.MALE'})}
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className='d-flex fv-row'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input me-3'
                            {...formik.getFieldProps('gender')}
                            name='gender'
                            type='radio'
                            value='FEMALE'
                            id='kt_modal_update_role_option_1'
                            checked={formik.values.gender === 'FEMALE'}
                            disabled={formik.isSubmitting}
                          />

                          <label
                            className='form-check-label'
                            htmlFor='kt_modal_update_role_option_1'
                          >
                            <div className='fw-bolder text-gray-800'>
                              {intl.formatMessage({id: 'GENERAL.FEMALE'})}
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className='d-flex fv-row'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input me-3'
                            {...formik.getFieldProps('gender')}
                            name='gender'
                            type='radio'
                            value='OTHER'
                            id='kt_modal_update_role_option_2'
                            checked={formik.values.gender === 'OTHER'}
                            disabled={formik.isSubmitting}
                          />

                          <label
                            className='form-check-label'
                            htmlFor='kt_modal_update_role_option_2'
                          >
                            <div className='fw-bolder text-gray-800'>
                              {intl.formatMessage({id: 'GENERAL.OTHER'})}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    {/* begin::Label */}
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'> {intl.formatMessage({id: 'GENERAL.DOB'})}</span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='position-relative '>
                        <ReactDatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          {...formik.getFieldProps('date_of_birth')}
                          dropdownMode='select'
                          className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                          dateFormat='dd-MM-yyyy'
                          name='date_of_birth'
                          selected={birthdate}
                          maxDate={maxDate}
                          onChange={(date: any) => {
                            formik.setFieldValue('date_of_birth', moment(date).format('YYYY-MM-DD'))
                            setBirthDate(date)
                          }}
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          placeholderText={intl.formatMessage({id: 'GENERAL.DOB'})}
                        />

                        <span
                          className='DatePicker_icon cursor-pointer'
                          onClick={() => document.getElementsByName('date_of_birth')[0].focus()}
                        >
                          <div className='fa fa-calendar'></div>
                        </span>
                      </div>
                      {formik.touched.date_of_birth && formik.errors.date_of_birth && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.date_of_birth}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>
                        {' '}
                        {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
                      </span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder={intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
                        {...formik.getFieldProps('permanent_address')}
                      />
                      {formik.touched.permanent_address && formik.errors.permanent_address && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.permanent_address}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>
                        {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                      </span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='number'
                        className='form-control form-control-lg form-control-solid'
                        placeholder={intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                        onKeyPress={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                            e.preventDefault()
                          }
                        }}
                        {...formik.getFieldProps('contact_number')}
                      />
                      {formik.touched.contact_number && formik.errors.contact_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.contact_number}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {loginData?.data?.role?.id === '1' && (
                    <div className='row mb-6' style={{marginLeft: '3px'}}>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        {intl.formatMessage({id: 'DOCTOR.QUALIFICATION'})}
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          placeholder={intl.formatMessage({id: 'DOCTOR.QUALIFICATION'})}
                          {...formik.getFieldProps('qualification')}
                          type='text'
                          name='qualification'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                          autoComplete='off'
                        />
                      </div>
                      {/* {formik.touched.qualification && formik.errors.qualification && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.qualification}</span>
                        </div>
                      </div>
                    )} */}
                    </div>
                  )}

                  {loginData?.data?.role?.id === '1' && (
                    <div className='row mb-6' style={{marginLeft: '3px'}}>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        {intl.formatMessage({id: 'DOCTOR.NEW_CASE.FEE'})}
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          placeholder={intl.formatMessage({id: 'PLACEHOLDER.ENTER.CASE_FEE'})}
                          {...formik.getFieldProps('new_case_fee')}
                          type='number'
                          name='new_case_fee'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                          autoComplete='off'
                        />
                      </div>
                      {formik.touched.new_case_fee && formik.errors.new_case_fee && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.new_case_fee}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {loginData?.data?.role?.id === '1' && (
                    <div className='row mb-6' style={{marginLeft: '3px'}}>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        {intl.formatMessage({id: 'STAFF.SPECIALITY'})}
                      </label>
                      {/* <select
                        className='form-select select_arrow_remove'
                        aria-label='Select example'
                        {...formik.getFieldProps('speciality_id')}
                        disabled={itemIdForUpdate ? true : false}
                        name='speciality_id'
                      >
                        <option value=''>{intl.formatMessage({id: 'STAFF.SELECT.SPECIALITY'})}</option>
                        {speciality &&
                          speciality?.map((item: any) => {
                            return (
                              <option key={item?.id} value={item?.id}>
                                {item?.name}
                              </option>
                            )
                          })}
                      </select> */}
                      <div className='col-lg-8 fv-row'>
                        <Select
                          isMulti
                          id='speciality_ids'
                          name='speciality_ids'
                          options={specialityData}
                          value={specialityData.filter((option: any) =>
                            formik?.values?.speciality_ids?.includes(option?.value)
                          )}
                          styles={customStyles}
                          onChange={(selectedOptions: any) => {
                            const selectedSpecialityIds = selectedOptions.map(
                              (option: any) => option?.id
                            )
                            formik.setFieldValue('speciality_ids', selectedSpecialityIds)
                            if (selectedSpecialityIds.length > 0) {
                              setSpecialityError('')
                            } else {
                              setSpecialityError('Speciality is required')
                            }
                          }}
                          onBlur={handleSpecialityBlur}
                          className={`basic-multi-select select_arrow_remove`}
                          classNamePrefix='select'
                          placeholder={intl.formatMessage({id: 'STAFF.SELECT.SPECIALITY'})}
                          // isDisabled={itemIdForUpdate ? true : false}
                        />
                      </div>
                      {specialityError && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{specialityError}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {loginData?.data?.role?.id === '1' && (
                    <div className='row mb-6' style={{marginLeft: '3px'}}>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        {intl.formatMessage({id: 'GENERAL.DIGITAL.SIGNATURE'})}
                      </label>
                      <div className='col-lg-8  gap-2 align-items-center'>
                        <div className=''>
                          <button
                            className='modal-button py-3'
                            onClick={handleOpen}
                            name='signature'
                            id='signature'
                          >
                            {intl.formatMessage({id: 'GENERAL.ADD_SIGNATURE'})}
                          </button>
                          <Modal show={isModalOpen} onHide={handleClose}>
                            <Modal.Header closeButton>
                              {intl.formatMessage({id: 'GENERAL.ADD_SIGNATURE'})}
                              <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <ReactSignatureCanvas
                                ref={signatureRef}
                                canvasProps={{width: 400, height: 200, className: 'sigCanvas'}}
                              />
                            </Modal.Body>
                            <Modal.Footer>
                              <div className='button-container'>
                                <button
                                  className='modal-button clear'
                                  onClick={handleClearSignature}
                                >
                                  {intl.formatMessage({id: 'GENERAL.CLEAR_SIGNATURE'})}
                                </button>
                                <button
                                  className='modal-button save'
                                  onClick={handleSignatureChange}
                                >
                                  {intl.formatMessage({id: 'GENERAL.SAVE_SIGNATURE'})}
                                </button>
                                <button className='modal-button close' onClick={handleClose}>
                                  {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                                </button>
                              </div>
                            </Modal.Footer>
                          </Modal>
                        </div>
                        {isSignatureSaved && signatureRef.current && (
                          <div className='modal-content2 m-0'>
                            <div style={{position: 'relative'}}>
                              <img
                                src={signatureRef.current.getTrimmedCanvas().toDataURL('image/png')}
                                alt='Signature'
                              />
                              <RxCross2
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  margin: '10px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleCloseImage()}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && intl.formatMessage({id: 'GENERAL.SAVE'})}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button type='button' className='btn btn-primary mx-2' onClick={handleClose}>
                    {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ProfileDetailsSettings}
