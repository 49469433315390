import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SalarysListHeader} from './components/header/SalarysListHeader'
import {SalarysTable} from './table/SalarysTable'
import {SalaryEditModal} from './salary-edit-modal/SalaryEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const SalarysList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <SalarysListHeader />
        <SalarysTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <SalaryEditModal />}
    </>
  )
}

const SalarysListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SalarysList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SalarysListWrapper}
