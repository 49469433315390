import { ID, Response } from "../../../../../_jmh/helpers"

export type AssetCategoryModule = {
  id?: ID
  name?: string
  hospital_id?: number | null
}

export type UsersQueryResponse = Response<Array<AssetCategoryModule>>

export const initialAssetCategory: AssetCategoryModule = {
    name: '',
    hospital_id:null
}