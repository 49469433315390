import { ID, Response } from "../../../../../_jmh/helpers"

export type Patient = {
  id?: ID
  full_name?: string
  gender?: string
  contact_number?: string
  alternate_contact_number?: string
  date_of_birth?: string
  permanent_address?: string
  blood_group?: string
  email?: string
  hospital_id?:number
  status?:string
  initials?: {
    label: string
    state: string
  }
}

export type PatientsQueryResponse = Response<Array<Patient>>

export const initialPatient: Patient = {
  full_name: '',
  gender: '',
  contact_number: '',
  alternate_contact_number:undefined,
  blood_group:'',
  date_of_birth: '',
  permanent_address: undefined,
  email: undefined,
  status: ''
}
