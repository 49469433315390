import React, {useEffect, useState} from 'react'
import GeneratePrescription from './components/GeneratePrescription'
import DpMedicalBackground from './components/DpMedicalBackground'
import DpPastVisits from './components/DpPastVisits'
import {useLocation} from 'react-router-dom'

const DpBody = () => {
  const location = useLocation()
  const params = (location.state as {tab?: string})?.tab
  const [activeTab, setActiveTab] = useState('nav-prescription-tab')

  useEffect(() => {
    if (params === 'nav-past-visit') {
      setActiveTab('nav-past-visit-tab')
    } else {
      setActiveTab('nav-prescription-tab')
    }
  }, [params])

  return (
    <div className='dp-body'>
      <nav className='overflow-y-hidden'>
        <div className='nav nav-tabs dp-main-tab' id='nav-tab' role='tablist'>
          <button
            className={`nav-link ${activeTab === 'nav-prescription-tab' ? 'active' : ''}`}
            id='nav-prescription-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-prescription'
            type='button'
            role='tab'
            aria-controls='nav-home'
            aria-selected={activeTab === 'nav-prescription-tab'}
            onClick={() => setActiveTab('nav-prescription-tab')}
          >
            Create new prescription
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-medical-background-tab' ? 'active' : ''}`}
            id='nav-medical-background-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-medical-background'
            type='button'
            role='tab'
            aria-controls='nav-medical-background'
            aria-selected={activeTab === 'nav-medical-background-tab'}
            onClick={() => setActiveTab('nav-medical-background-tab')}
          >
            Medical background
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-past-visit-tab' ? 'active' : ''}`}
            id='nav-past-visit-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-past-visit'
            type='button'
            role='tab'
            aria-controls='nav-past-visit'
            aria-selected={activeTab === 'nav-past-visit-tab'}
            onClick={() => setActiveTab('nav-past-visit-tab')}
          >
            Past visits
          </button>
        </div>
      </nav>
      <div className='tab-content dp-main-tab-content px-3 py-5' id='nav-tabContent'>
        <div
          className={`tab-pane fade ${activeTab === 'nav-prescription-tab' ? 'show active' : ''}`}
          id='nav-prescription'
          role='tabpanel'
          aria-labelledby='nav-prescription-tab'
        >
          <GeneratePrescription />
        </div>
        <div
          className={`tab-pane fade ${
            activeTab === 'nav-medical-background-tab' ? 'show active' : ''
          }`}
          id='nav-medical-background'
          role='tabpanel'
          aria-labelledby='nav-medical-background-tab'
        >
          <DpMedicalBackground />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'nav-past-visit-tab' ? 'show active' : ''}`}
          id='nav-past-visit'
          role='tabpanel'
          aria-labelledby='nav-past-visit-tab'
        >
          <DpPastVisits />
        </div>
      </div>
    </div>
  )
}

export default DpBody
