/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toast} from 'react-toastify'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_jmh/helpers'
import {PatientInsurance} from '../../core/_models'

type Props = {
  user: PatientInsurance
}

const PatientInsuranceUpload: FC<Props> = ({user}) => {
  const url: any = user.insurance_upload
  const extension = url?.split('.').pop()?.toLowerCase()
  const handleDownload = async (e: any) => {
    e.preventDefault()

    if (url) {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        const downloadUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')

        let fileName = url
          ? url
              .substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'))
              .split('_')
              .pop()
          : ''
        // let fileName = 'insurance'

        if (extension === 'jpg' || extension === 'jpeg') {
          fileName += '.jpg'
        } else if (extension === 'pdf') {
          fileName += '.pdf'
        } else {
          toast.error('Unsupported file format')
          return
        }

        link.href = downloadUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error('Failed to download file:', error)
      }
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {user.insurance_upload &&
        user.insurance_upload !== null &&
        user.insurance_upload?.length > 0 ? (
          <>
            {extension === 'pdf' ? (
              <a href='#' onClick={handleDownload}>
                <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-primary svg-icon-3hx' />
              </a>
            ) : (
              <>
                {extension === 'jpg' || extension === 'jpeg' ? (
                  <>
                    <a
                      href={user.insurance_upload}
                      target='_blank'
                      style={{width: '50px', height: '50px'}}
                    >
                      <img className='w-100 h-100 object-fit-cover' src={user.insurance_upload} />
                    </a>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export {PatientInsuranceUpload}
