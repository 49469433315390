import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AssignInvListHeader} from './components/header/AssignInvListHeader'
import {AssignInvTable} from './table/AssignInvTable'
import {AssignInvEditModal} from './assignInv-edit-modal/AssignInvEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const AssignInvList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <AssignInvListHeader />
        <AssignInvTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AssignInvEditModal />}
    </>
  )
}

const AssignInvListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AssignInvList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AssignInvListWrapper}
