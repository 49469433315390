import {FC, useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialPatient, Patient} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createPatient, updatePatient} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_jmh/helpers'
import {PatientsListLoading} from '../components/loading/PatientsListLoading'
import {toast} from 'react-toastify'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  patient: Patient
}

const editUserSchema = Yup.object().shape({
  full_name: Yup.string()
    .test(
      'noLeadingSpaces',
      'Invalid Input, Please avoid leading spaces at the beginning of the field',
      (value) => {
        return !value || value.trimStart() === value
      }
    )
    .min(3, 'Minimum 3 Characters')
    .max(50, 'Maximum 50 Characters')
    .required('Patient Full Name is required')
    .matches(/^[A-Za-z\s]+$/, 'Number not allowed'),
  email: Yup.string()
    .email('Invalid email format')
    .min(3, 'Minimum 3 Characters')
    .max(50, 'Maximum 50 Characters')
    .required('Email is required'),
  contact_number: Yup.string()
    .matches(/^\d{10}$/, 'Contact number must be a valid 10-digit number')
    .required('Contact number is required'),
  alternate_contact_number: Yup.string().matches(
    /^\d{10}$/,
    'Alternate contact number must be a valid 10-digit number'
  ),
  date_of_birth: Yup.string().required('Date Of Birth is required'),
  blood_group: Yup.string()
    .required('Blood Group is required')
    .notOneOf([''], 'Blood Group is required'),
  status: Yup.string().required('Status is required').notOneOf([''], 'Status is required'),
})

const PatientEditModalForm: FC<Props> = ({patient, isUserLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [dob, setDob] = useState<Date | null>(null)
  const {refetch} = useQueryResponse()
  const {selectedHospitalId} = useContext(loginContext)
  const intl = useIntl()

  const [userForEdit] = useState<Patient>({
    ...patient,
    full_name: patient.full_name || initialPatient.full_name,
    gender: patient.gender || initialPatient.gender,
    contact_number: patient.contact_number || initialPatient.contact_number,
    alternate_contact_number:
      patient.alternate_contact_number || initialPatient.alternate_contact_number,
    date_of_birth: patient.date_of_birth || initialPatient.date_of_birth,
    permanent_address: patient.permanent_address || initialPatient.permanent_address,
    email: patient.email || initialPatient.email,
    blood_group: patient.blood_group || initialPatient.blood_group,
    status: patient.status || initialPatient.status,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      values['date_of_birth'] = moment(values['date_of_birth'], 'DD-MM-YYYY').format('DD-MM-YYYY')
      values['hospital_id'] = Number(selectedHospitalId)
      values['contact_number'] = values?.contact_number && values?.contact_number.toString()
      values['alternate_contact_number'] =
        values?.alternate_contact_number && values?.alternate_contact_number.toString()

      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updatePatient(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          await createPatient({...values, hospital_id: Number(selectedHospitalId)}).then(
            (res: any) => {
              toast.success(res?.data?.message)
            }
          )
        }
      } catch (ex: any) {
        toast.error(ex.response?.data?.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const future_date_disable = new Date(moment().format('YYYY-MM-DD'))

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column  me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.PATIENT.FULL.NAME'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.PATIENT.FULL.NAME'})}
                {...formik.getFieldProps('full_name')}
                type='text'
                name='full_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.full_name && formik.errors.full_name},
                  // {
                  //   'is-valid': formik.touched.full_name && !formik.errors.full_name,
                  // }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.full_name && formik.errors.full_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.full_name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.EMAIL'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.EMAIL'})}
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.email && formik.errors.email},
                  // {
                  //   'is-valid': formik.touched.email && !formik.errors.email,
                  // }
                )}
                type='email'
                name='email'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-5'>
                {intl.formatMessage({id: 'GENERAL.GENDER'})}
              </label>

              <div className='d-flex gap-4'>
                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-1'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='MALE'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.gender === 'MALE'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.MALE'})}
                      </div>
                    </label>
                  </div>
                </div>

                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-1'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='FEMALE'
                      id='kt_modal_update_role_option_1'
                      checked={formik.values.gender === 'FEMALE'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.FEMALE'})}
                      </div>
                    </label>
                  </div>
                </div>

                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-1'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='OTHER'
                      id='kt_modal_update_role_option_2'
                      checked={formik.values.gender === 'OTHER'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.OTHER'})}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.DOB'})}
              </label>

              <div className='position-relative'>
                <ReactDatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('date_of_birth')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy'
                  name='date_of_birth'
                  maxDate={future_date_disable}
                  selected={dob}
                  onChange={(date: any) => {
                    formik.setFieldValue('date_of_birth', date)
                    setDob(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  placeholderText={intl.formatMessage({id: 'GENERAL.DOB'})}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('date_of_birth')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>
              {formik.touched.date_of_birth && formik.errors.date_of_birth && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.date_of_birth}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                {...formik.getFieldProps('contact_number')}
                type='number'
                name='contact_number'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.contact_number && formik.errors.contact_number},
                  // {
                  //   'is-valid': formik.touched.contact_number && !formik.errors.contact_number,
                  // }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault() // Prevent the 'e' character from being entered
                  }
                }}
              />
              {formik.touched.contact_number && formik.errors.contact_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.contact_number}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.ALTERNATE.CONTACT.NUMBER'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.ALTERNATE.CONTACT.NUMBER'})}
                {...formik.getFieldProps('alternate_contact_number')}
                type='number'
                name='alternate_contact_number'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault() // Prevent the 'e' character from being entered
                  }
                }}
              />

              {formik.touched.alternate_contact_number && formik.errors.alternate_contact_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.alternate_contact_number}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
              </label>

              <textarea
                {...formik.getFieldProps('permanent_address')}
                className='form-control no-resize'
                name='permanent_address'
                id='exampleFormControlTextarea1'
                disabled={formik.isSubmitting || isUserLoading}
                rows={3}
                placeholder={intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
              ></textarea>
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.BLOOD.GROUP'})}
              </label>
              <select
                className='form-select'
                {...formik.getFieldProps('blood_group')}
                aria-label='Select example'
              >
                <option value=''>{intl.formatMessage({id: 'GENERAL.SELECT.BLOOD.GROUP'})}</option>
                <option value='A_POSITIVE'>A+</option>
                <option value='B_POSITIVE'>B+</option>
                <option value='O_POSITIVE'>O+</option>
                <option value='AB_POSITIVE'>AB+</option>
                <option value='A_NEGATIVE'>A-</option>
                <option value='B_NEGATIVE'>B-</option>
                <option value='O_NEGATIVE'>O-</option>
                <option value='AB_NEGATIVE'>AB-</option>
              </select>
              {formik.touched.blood_group && formik.errors.blood_group && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.blood_group}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.STATUS'})}
              </label>
              <select
                className='form-select'
                aria-label='Select example'
                {...formik.getFieldProps('status')}
              >
                <option value=''>{intl.formatMessage({id: 'GENERAL.SELECT.STATUS'})}</option>
                <option value='ACTIVE'>{intl.formatMessage({id: 'GENERAL.ACTIVE.STATUS'})}</option>
                <option value='INACTIVE'>
                  {intl.formatMessage({id: 'GENERAL.IN.ACTIVE.STATUS'})}
                </option>
              </select>

              {formik.touched.status && formik.errors.status && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <PatientsListLoading />}
    </>
  )
}

export {PatientEditModalForm}
