import React, {useContext, useEffect, useMemo, useState} from 'react'

import {Calendar, momentLocalizer, View} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import {QUERIES, stringifyRequestQuery} from '../../../_jmh/helpers'
import {useQuery} from 'react-query'
import {useQueryRequest} from '../Patient-appointment/appointments-list/core/QueryRequestProvider'
import {loginContext} from '../../context/context'
import {getOperations} from '../OperationTheater-Management/Operations-list/core/_requests'
import {getAppointments} from '../PatientAppointment-Doctor/Appointments-list/core/_requests'
import {Operationlist} from '../OperationTheater-Management/Operations-list/core/_models'
import {CustomToolbar} from '../doctor-dashboard/CustomToolbar'
import EventDetails from '../doctor-dashboard/EventDetails'
import {FilterButton} from '../doctor-dashboard/FilterButton'
import ShowAllEvents from '../staff-dashboard/ShowAllEvents'
import {useIntl} from 'react-intl'

const DoctorDashboardCalendar = () => {
  const [appstartDate, setAppStartDate] = useState<Date | null>(null)
  const [appendDate, setAppEndDate] = useState<Date | null>(null)
  const [opestartDate, setOpeStartDate] = useState<Date | null>(null)
  const [opeendDate, setOpeEndDate] = useState<Date | null>(null)
  const [patientName, setPatientName] = useState('')
  const localizer = momentLocalizer(moment)
  const {state} = useQueryRequest()
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const {loginData, doctorAppointmentId, selectedHospitalId} = useContext(loginContext)
  const [currentView, setCurrentView] = useState<string>('month')
  const [currentDate, setCurrentDate] = useState(moment())
  const [selectedDay, setSelectedDay] = useState<Date | null>(null)
  const [selectedEvent, setSelectedEvent] = useState<any>([])
  const intl = useIntl()

  const {
    selectedAppointmentData,
    setSelectedAppointmentData,
    setSelectedOperationData,
    selectedOperationData,
  } = useContext(loginContext)
  const [appQuery] = useState<string>(
    stringifyRequestQuery({
      ...state,
      start_date: setAppStartDate,
      end_date: setAppEndDate,
      patient_id: setPatientName,
    })
  )
  const [opeQuery] = useState<string>(
    stringifyRequestQuery({
      ...state,
      start_date: setOpeStartDate,
      end_date: setOpeEndDate,
      patient_id: setPatientName,
    })
  )

  const [showEventDetails, setShowEventDetails] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const openEventDetails = (event: any) => {
    setSelectedEvent(event)
    setShowEventDetails(true)
  }
  const openMoreEvents = (event: any) => {
    setSelectedDay(event)
    setShowMore(true)
    setShowEventDetails(false)
  }

  const closeEventDetails = () => {
    setShowEventDetails(false)
  }
  const handleClose = () => {
    setShowMore(false)
  }

  const viewMapping: {[key: string]: View} = {
    month: 'month',
    week: 'week',
    day: 'day',
    agenda: 'agenda',
  }
  const handleNextButtonClick = () => {
    if (currentView === 'month') {
      const nextMonth = moment(currentDate).add(1, 'month')
      setCurrentDate(nextMonth)
    } else if (currentView === 'week') {
      const nextWeek = moment(currentDate).add(1, 'week')
      setCurrentDate(nextWeek)
    } else if (currentView === 'day') {
      const nextDay = moment(currentDate).add(1, 'day')
      setCurrentDate(nextDay)
    } else if (currentView === 'agenda') {
      const nextAppoinment = moment(currentDate).add(1, 'month')
      setCurrentDate(nextAppoinment)
    }
  }
  const handlePervButtonClick = () => {
    if (currentView === 'month') {
      const pervMonth = moment(currentDate).add(-1, 'month')
      setCurrentDate(pervMonth)
    } else if (currentView === 'week') {
      const pervWeek = moment(currentDate).add(-1, 'week')
      setCurrentDate(pervWeek)
    } else if (currentView === 'day') {
      const pervDay = moment(currentDate).add(-1, 'day')
      setCurrentDate(pervDay)
    } else if (currentView === 'agenda') {
      const pervAppoinment = moment(currentDate).add(-1, 'month')
      setCurrentDate(pervAppoinment)
    }
  }
  const handleTodayButtonClick = () => {
    if (currentView === 'month') {
      setCurrentDate(moment())
    } else if (currentView === 'week') {
      setCurrentDate(moment())
    } else if (currentView === 'day') {
      setCurrentDate(moment())
    } else if (currentView === 'agenda') {
      setCurrentDate(moment())
    }
  }
  const handleViewChange = (view: string) => {
    if (view === 'NEXT_MONTH') {
      handleNextButtonClick()
    } else if (view === 'PREV') {
      handlePervButtonClick()
    } else if (view === 'TODAY') {
      handleTodayButtonClick()
    } else {
      setCurrentView(view)
    }
  }

  const {data: appointmentResponse} = useQuery(
    `${QUERIES.USERS_LIST}-${query}-${selectedHospitalId}`,
    () => {
      return getAppointments(query, loginData?.data?.personal_details?.id, selectedHospitalId)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: operationsResponse,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${opeQuery}-${doctorAppointmentId}`,
    () => {
      return getOperations(opeQuery, selectedHospitalId)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    let appointFiltered = appointmentResponse?.data
    let operatFiltered = operationsResponse?.data
    setSelectedAppointmentData(appointFiltered)
    setSelectedOperationData(operatFiltered)
  }, [appointmentResponse?.data, operationsResponse?.data])

  const handleApply = () => {
    let appointFiltered = appointmentResponse?.data
    let operatFiltered = operationsResponse?.data
    if (appstartDate && appendDate) {
      appointFiltered = appointFiltered?.filter((appoint: any) =>
        moment(appoint.appointment_details[0].appointment_date).isBetween(
          moment(appstartDate),
          moment(appendDate),
          null,
          '[]'
        )
      )
    }
    if (patientName) {
      appointFiltered = appointFiltered?.filter((name: any) => name.patient_id === patientName)
    }
    if (opestartDate && opeendDate) {
      operatFiltered = operatFiltered?.filter((operat: any) => {
        const operationStartDate = moment(operat?.operation_start_date)
        const operationEndDate = moment(operat?.operation_end_date)
        return (
          operationStartDate.isSameOrAfter(moment(opestartDate), 'day') &&
          operationEndDate.isSameOrBefore(moment(opeendDate), 'day')
        )
      })
    }

    if (patientName) {
      operatFiltered = operatFiltered?.filter((name: any) => name.patient_id === patientName)
    }

    setSelectedAppointmentData(appointFiltered)
    setSelectedOperationData(operatFiltered)
  }

  useEffect(() => {
    refetch()
  }, [selectedHospitalId])

  const extractedData: any =
    selectedAppointmentData?.map((item: any) => {
      const appointmentDetails = item?.appointment_details

      if (Array.isArray(appointmentDetails) && appointmentDetails.length > 0) {
        const firstAppointment = appointmentDetails[0]
        const lastAppointment = appointmentDetails[appointmentDetails.length - 1]

        if (firstAppointment.appointment_date && firstAppointment.slot && lastAppointment.slot) {
          const appointmentDateTime = new Date(firstAppointment.appointment_date)
          const slotTime = firstAppointment.slot.split(':')
          appointmentDateTime.setHours(parseInt(slotTime[0]))
          appointmentDateTime.setMinutes(parseInt(slotTime[1]))

          const endDate = new Date(appointmentDateTime)
          const lastSlotTime = lastAppointment.slot.split(':')
          endDate.setHours(parseInt(lastSlotTime[0]))
          endDate.setMinutes(parseInt(lastSlotTime[1]))

          return {
            title: item.patient?.full_name,
            start: appointmentDateTime,
            end: endDate,
            patientName: item.patient?.full_name,
            color: '#6cb1ed',
            doctorName: firstAppointment?.doctor?.full_name,
            eventTitle: 'Patient Appointment Details',
          }
        }
      }
      return null
    }) || []

  const operations =
    selectedOperationData?.map((item: Operationlist) => {
      const startDate = new Date(
        moment.utc(item?.operation_start_date).format('MM-DD-YYYY HH:mm:ss')
      )
      const endDate = new Date(moment.utc(item?.operation_end_date).format('MM-DD-YYYY HH:mm:ss'))
      const filteredDoctors = item?.allocated_doctor?.filter(
        (item) => item?.doctor?.full_name.length > 1
      )
      const fullNames = filteredDoctors?.map((item) => item?.doctor?.full_name).join(', ')
      return {
        title: item.patient?.full_name,
        start: startDate,
        end: endDate,
        patientName: item.patient?.full_name,
        color: 'rgb(205 163 237)',
        doctorName: fullNames,
        eventTitle: 'Patient Operation Details',
        venue: item?.hospital_room?.room_number,
      }
    }) || []

  const events = [...extractedData, ...operations]

  const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      border: '1px solid #DDD',
      display: 'block',
    }

    return {
      style,
    }
  }

  const handleReset = () => {
    setAppStartDate(null)
    setAppEndDate(null)
    setOpeStartDate(null)
    setOpeEndDate(null)
    setPatientName('')
    return (
      setSelectedAppointmentData(appointmentResponse?.data),
      setSelectedOperationData(operationsResponse?.data)
    )
  }
  useEffect(() => {
    const showMoreButton = document.querySelector('.rbc-button-link.rbc-show-more')

    if (showMoreButton) {
      showMoreButton.addEventListener('click', handleEventClick)
    }

    return () => {
      if (showMoreButton) {
        showMoreButton.removeEventListener('click', handleEventClick)
      }
    }
  })

  const handleEventClick = (event: any) => {
    setSelectedEvent(event)
    openEventDetails(event)
  }
  const handleShowMore = (event: any) => {
    setSelectedDay(event)
    openMoreEvents(event)
  }
  return (
    <div>
      <div className='card'>
        <div className='card-header'>
          <h2 className='card-title fw-bold'>
            {intl.formatMessage({id: 'DOCTOR_DASHBOARD.APPOINTMENTS.OPERATIONS'})}
          </h2>
          <div className='card-title d-flex gap-3'>
            <div className='d-flex gap-1'>
              <i className='fa-solid fa-square fs-4 mt-1' style={{color: '#6cb1ed'}}></i>
              <span className='fs-5'>{intl.formatMessage({id: 'PATIENT.APPOINTMENT'})}</span>
            </div>
            <div className='d-flex gap-1'>
              <i className='fa-solid fa-square fs-4 mt-1' style={{color: 'rgb(205 163 237)'}}></i>
              <span className='fs-5'>{intl.formatMessage({id: 'PATIENT.OPERATIONS'})}</span>
            </div>
          </div>

          <div className='card-title fw-bold gap-2'>
            <FilterButton
              appstartDate={appstartDate}
              appendDate={appendDate}
              setAppStartDate={setAppStartDate}
              setAppEndDate={setAppEndDate}
              handleApply={handleApply}
              opestartDate={opestartDate}
              opeendDate={opeendDate}
              setOpeStartDate={setOpeStartDate}
              setOpeEndDate={setOpeEndDate}
              patientName={patientName}
              setPatientName={setPatientName}
            />
            <button
              type='reset'
              onClick={handleReset}
              className='btn btn-sm text-white btn-primary btn-active-primary'
            >
              {intl.formatMessage({id: 'RESET'})}
            </button>
          </div>
        </div>
        <div className='card-body'>
          <Calendar
            components={{
              toolbar: (props) => <CustomToolbar {...props} onViewChange={handleViewChange} />,
            }}
            style={{height: 600}}
            className='text-gray-800 fw-bold'
            view={viewMapping[currentView]}
            date={currentDate.toDate()}
            localizer={localizer}
            events={events}
            startAccessor='start'
            endAccessor='end'
            eventPropGetter={eventStyleGetter}
            onSelectEvent={handleEventClick}
            onShowMore={handleShowMore}
          />
          {showMore && <ShowAllEvents selectedDay={selectedDay} onClose={handleClose} />}
          {showEventDetails && <EventDetails event={selectedEvent} onClose={closeEventDetails} />}
        </div>
      </div>
    </div>
  )
}

export {DoctorDashboardCalendar}
