import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {NotificationModel, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const NOTIFICATON_URL = `${API_URL}/notifications`
const GET_NOTIFICATION_URL = `${API_URL}/notifications`

const getNotification = (query: string, readNotification: any,id:number,): Promise<UsersQueryResponse> => {
  const url =`${GET_NOTIFICATION_URL}?${query ? `${readNotification}&${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`
  return axios.get(url)
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data);
}


const updateNotification = (user: any): Promise<NotificationModel | undefined> => {
  return axios.post(`${NOTIFICATON_URL}/read`, { ids: [user] })
    .then((response: AxiosResponse<Response<NotificationModel>>) => response.data)
    .then((response: Response<NotificationModel>) => response.data)
}

const deleteNotification = (id: ID): Promise<void> => {
  return axios.delete(`${NOTIFICATON_URL}`, {data: { ids: [id]}})
}

const deleteSelectedNotification = (userIds: any): Promise<void> => {
  return axios.delete(`${NOTIFICATON_URL}`,{data: { ids: [userIds]}})
}

export {getNotification,deleteNotification, deleteSelectedNotification,  updateNotification}
