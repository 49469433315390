import {useQuery} from 'react-query'
import {PatientVitalEditModalForm} from './PatientVitalEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getPatientVitalById} from '../core/_requests'
import moment from 'moment'

const PatientVitalEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getPatientVitalById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (user && user.date_time) {
    const startDate = moment.utc(user.date_time).format('DD-MM-YYYY H:mm')
    user.date_time = startDate
  }

  if (!itemIdForUpdate) {
    return <PatientVitalEditModalForm isUserLoading={isLoading} user={{id: undefined}} />
  }

  if (!isLoading && !error && user) {
    return <PatientVitalEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export {PatientVitalEditModalFormWrapper}
