import {FC} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_jmh/layout/MasterLayout'
import {Dashboard} from '../pages/dashboard/dashboard'
import {ProfileWrapper} from '../pages/my-profile/MyProfileWrapper'
import {ChnagepasswordWrapper} from '../pages/Changepassword/Changepassword'
import {SupportFeedbackWrapper} from '../pages/support-feedback/SupportFeedbackWrapper'
import {ProfileDetailsSettings} from '../pages/my-profile/Profile/components/ProfileDetailsSettings'
import NotificationPageView from '../pages/notification/NotificationPageView'
import HospitalPageWrapper from '../pages/hospitals/HospitalPageWrapper'
import SpecialityPageWrapper from '../pages/Speciality-management/SpecialityPageWrapper'
import AssetCategoryPageWrapper from '../pages/asset-category/AssetCategoryPageWrapper'
import AssetPageWrapper from '../pages/asset-management/AssetPageWrapper'
import {AssetView} from '../pages/asset-management/ViewAsset/AssetView'
import {AssetMaintenanceWrapper} from '../pages/asset-maintenance/AssetMaintenance'
import {RolesPermissionsWrapper} from '../pages/roles-and-permissions/Rolespermission'
import {DoctorWrapper} from '../pages/Doctor/DoctorWrapper'
import {StaffWrapper} from '../pages/staff-management/StaffWrapper'
import StaffView from '../pages/staff-management/ViewPage/StaffView'
import Rolespermission from '../pages/roles-and-permissions/Permission/Rolespermission'
import DoctorView from '../pages/Doctor/ViewPage/DoctorView'
import InventoryCategoryPageWrapper from '../pages/inventory-category/InventoryCategoryPageWrapper'
import ShiftPageWrapper from '../pages/shift-management/shiftPageWrapper'
import InvManagementPageWrapper from '../pages/Inventory-management/InvManagementListWrapper'
import PurchaseInvPageWrapper from '../pages/purchase-inventory/PurchaseInvPageWrapper'
import HospitalRoomPageWrapper from '../pages/HospitalRoom/HospitalRoomPageWrapper'
import AssignInvPageWrapper from '../pages/assign-inventory/AssignInvPageWrapper'
import {PatientWrapper} from '../pages/Patient-management/PatientWrapper'
import PatientInsurancePageWrapper from '../pages/patient-insurance/PatientInsurancePageWrapper'
import {PatientDetails} from '../pages/Patient-management/PatientDetails/PatientDetails'
import PatientView from '../pages/Patient-management/ViewPatient/PatientView'
import {DoctorAvalabilityWrapper} from '../pages/DoctorAvalability/DoctorAvalabilityWrapper'
import {PatientAppointmentWrapper} from '../pages/Patient-appointment/PatientAppointmentWrapper'
import {PatientAppointmentDoctorWrapper} from '../pages/PatientAppointment-Doctor/PatientAppointmentDoctorWrapper'
import {OperationTheaterWrapper} from '../pages/OperationTheater-Management/OperationTheater'
import {SalaryWrapper} from '../pages/Staff Salary/SalaryWrapper'
import SalaryAddForm from '../pages/Staff Salary/salarys-list/Pages/SalaryAddForm'
import EditSalaryForm from '../pages/Staff Salary/salarys-list/Pages/EditSalaryForm'
import ViewSalary from '../pages/Staff Salary/salarys-list/Pages/ViewSalary'
import {AtdWrapper} from '../pages/ATD-Patient/AtdWrapper'
import Adtview from '../pages/ATD-Patient/ATDS-list/ViewPage/Adtview'
import AuditLogsPageWrapper from '../pages/AuditLogs/AuditLogsPageWrapper'
import PatientBillingPageWrapper from '../pages/Patient-Billing/PatientBillingPageWrapper'
import {BillingForm} from '../pages/Patient-Billing/PatientBillingList/PatientBillEditPages/BillingForm'
import {PayrollWrapper} from '../pages/Staff-Payroll/PayrollWrapper'
import PayrollForm from '../pages/Staff-Payroll/Payrolls-list/Pages/PayrollForm'
import StaffPayroll from '../pages/Staff-Payroll/Payrolls-list/Pages/StaffPayrollView'
import {MedicineMasterWrapper} from '../pages/medicine-master/MedicineMasterWrapper'
import PatientBooking from '../pages/patient-booking/PatientBooking'
import OpPatientAppointmentList from '../pages/outer-patient-modules/OpPatientAppointmentList'
import {OpProfileWrapper} from '../pages/patient-profile/OpProfileWrapper'
import {OpProfileDetailsSettings} from '../pages/patient-profile/profile/components/OpProfileDetailsSettings'
import OpPatientInsurance from '../pages/outer-patient-modules/OpPatientInsurance'
import OpMedication from '../pages/outer-patient-modules/OpMedication'
import OpMedicalNotes from '../pages/outer-patient-modules/OpMedicalNotes'
import OpVitals from '../pages/outer-patient-modules/OpVitals'
import OpMedicalBackground from '../pages/outer-patient-modules/OpMedicalBackground'
import OpAttachments from '../pages/outer-patient-modules/OpAttachments'
import OpPatientDetails from '../pages/outer-patient-modules/OpPatientDetails'
import DpLayoutWrapper from '../pages/doctor-prescription/DpLayoutWrapper'
import DoctorAllInformation from '../pages/doctor-all-information/DoctorInfoWrapper'

type Props = {
  loginData: any
  checkPermission: any
}

const CommonRoute: FC<Props> = ({loginData, checkPermission}) => {
  return (
    <Routes>
      {loginData?.data?.user_type == 'PATIENT' ? (
        <>
          <Route
            path='/patient-booking'
            element={
              <PatientBooking
                isUserLoading={false}
                user={{
                  id: undefined,
                  hospital_id: undefined,
                  patient_id: undefined,
                  doctor_id: undefined,
                  date: undefined,
                  slot: undefined,
                  full_name: undefined,
                  description: undefined,
                  gender: undefined,
                  contact_number: undefined,
                  date_of_birth: undefined,
                  email: undefined,
                  blood_group: undefined,
                  status: undefined,
                  patient: undefined,
                  appointment_details: undefined,
                }}
              />
            }
          />
          <Route path='/profile' element={<OpProfileWrapper />} />
          <Route path='/profile/update-profile' element={<OpProfileDetailsSettings />} />
          <Route path='/patient-details/' element={<OpPatientDetails />} />
          <Route path='patient-appointment' element={<OpPatientAppointmentList />} />
          <Route path='patient-insurance' element={<OpPatientInsurance />} />
          <Route path='patient-medication' element={<OpMedication />} />
          <Route path='patient-medical-notes' element={<OpMedicalNotes />} />
          <Route path='patient-vitals' element={<OpVitals />} />
          <Route path='patient-medical-background' element={<OpMedicalBackground />} />
          <Route path='patient-attachment' element={<OpAttachments />} />
        </>
      ) : (
        <>
          <Route element={<MasterLayout />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/my-profile' element={<ProfileWrapper />} />
            <Route path='/change-password' element={<ChnagepasswordWrapper />} />
            <Route path='/support-feedback' element={<SupportFeedbackWrapper />} />
            <Route path='/my-profile/update-profile' element={<ProfileDetailsSettings />} />
            <Route path='/notification' element={<NotificationPageView />} />

            <Route
              path='master/hospitals'
              element={
                checkPermission(loginData, 'hospital') ? (
                  <HospitalPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='master/specialities'
              element={
                checkPermission(loginData, 'speciality') ? (
                  <SpecialityPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='asset-category'
              element={
                checkPermission(loginData, 'asset-category') ? (
                  <AssetCategoryPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='asset-management'
              element={
                checkPermission(loginData, 'asset') ? (
                  <AssetPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route path='asset-management/asset-view/:id' element={<AssetView />} />
            <Route
              path='asset-maintenance'
              element={
                checkPermission(loginData, 'asset-maintenance') ? (
                  <AssetMaintenanceWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='roles-permissions'
              element={
                checkPermission(loginData, 'roles') ? (
                  <RolesPermissionsWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='doctor-management'
              element={
                checkPermission(loginData, 'doctor') ? (
                  <DoctorWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='staff-management'
              element={
                checkPermission(loginData, 'staff') ? (
                  <StaffWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route path='/staff-management/staff-view/:id' element={<StaffView />} />
            <Route path='/roles-permissions/permissions/:id' element={<Rolespermission />} />
            <Route
              path='doctor-management/view/:id'
              element={
                checkPermission(loginData, 'doctor', ':id', 'GET') ? (
                  <DoctorView />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='inventory/inventory-category'
              element={
                checkPermission(loginData, 'inventory-category') ? (
                  <InventoryCategoryPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='staff/shift-management'
              element={
                checkPermission(loginData, 'shift') ? (
                  <ShiftPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='inventory/inventory-management'
              element={
                checkPermission(loginData, 'inventory') ? (
                  <InvManagementPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='inventory/purchase-inventory'
              element={
                checkPermission(loginData, 'inventory') ? (
                  <PurchaseInvPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='master/hospital-room'
              element={
                checkPermission(loginData, 'hospital-room') ? (
                  <HospitalRoomPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='inventory/assign-inventory'
              element={
                checkPermission(loginData, 'assign-inventory') ? (
                  <AssignInvPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/patient-data/patient-management'
              element={
                checkPermission(loginData, 'patient') ? (
                  <PatientWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/patient-data/patient-insurance'
              element={
                checkPermission(loginData, 'patient-insurance') ? (
                  <PatientInsurancePageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/patient-data/patient-details/:id'
              element={
                checkPermission(loginData, 'patient') ? (
                  <PatientDetails />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/patient-data/patient-management/patient-view/:id'
              element={
                checkPermission(loginData, 'patient', ':id', 'GET') ? (
                  <PatientView />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='doctor-availability'
              element={
                checkPermission(loginData, 'doctor-availability') ? (
                  <DoctorAvalabilityWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/patient-data/patient-appointment'
              element={
                checkPermission(loginData, 'patient-appointment') ? (
                  <PatientAppointmentWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            {/* <Route
              path='doctor-appointment'
              element={
                checkPermission(loginData, 'doctor-appointment') ? (
                  <PatientAppointmentDoctorWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            /> */}
            {/* 
            <Route
              path='all-appointment'
              element={
                checkPermission(loginData, 'doctor-all-information') ? (
                  <DoctorAllInformation />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            /> */}

            <Route
              path='master/operation-theatre'
              element={
                checkPermission(loginData, 'operation-theatre') ? (
                  <OperationTheaterWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/staff/salary-structure'
              element={
                checkPermission(loginData, 'staff-salary-strcuture') ? (
                  <SalaryWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/staff/salary-structure/add-employee-salary'
              element={
                checkPermission(loginData, 'staff-salary-strcuture') ? (
                  <SalaryAddForm />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/staff/salary-structure/edit-employee-salary/:id'
              element={
                checkPermission(loginData, 'staff-salary-strcuture') ? (
                  <EditSalaryForm />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/staff/salary-structure/view-employee-salary/:id'
              element={
                checkPermission(loginData, 'staff-salary-strcuture') ? (
                  <ViewSalary />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='patient-data/ad-patient'
              element={
                checkPermission(loginData, 'ad-patient') ? (
                  <AtdWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='patient-data/ad-patient-view/:id'
              element={
                checkPermission(loginData, 'ad-patient') ? (
                  <Adtview />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='master/audit-logs'
              element={
                checkPermission(loginData, 'audit-logs') ? (
                  <AuditLogsPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/patient-data/patient-billing'
              element={
                checkPermission(loginData, 'patient-billing') ? (
                  <PatientBillingPageWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/patient-data/patient-billing/billingForm'
              element={
                checkPermission(loginData, 'patient-billing') ? (
                  <BillingForm />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/patient-data/patient-billing/billingForm/:id'
              element={
                checkPermission(loginData, 'patient-billing') ? (
                  <BillingForm />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='staff/payroll'
              element={
                checkPermission(loginData, 'staff-payroll') ? (
                  <PayrollWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/staff/payroll/add-payroll'
              element={
                checkPermission(loginData, 'staff-payroll') ? (
                  <PayrollForm />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/staff/payroll/edit-payroll/:id'
              element={
                checkPermission(loginData, 'staff-payroll') ? (
                  <PayrollForm />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='/staff/payroll/view-payroll/:id'
              element={
                checkPermission(loginData, 'staff-payroll') ? (
                  <StaffPayroll />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route
              path='master/patient-medicine-master'
              element={
                checkPermission(loginData, 'patient-medicine-master') ? (
                  <MedicineMasterWrapper />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/patient-data/patient-details/medications'
              element={
                checkPermission(loginData, 'patient-medication') ? (
                  <PatientDetails />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/patient-data/patient-details/attachments'
              element={
                checkPermission(loginData, 'patient') ? (
                  <PatientDetails />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/patient-data/patient-details/medical-notes'
              element={
                checkPermission(loginData, 'patient-medical-notes') ? (
                  <PatientDetails />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/patient-data/patient-details/patient-vital'
              element={
                checkPermission(loginData, 'patient-vitals') ? (
                  <PatientDetails />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />

            <Route
              path='/patient-data/patient-details/medical-background'
              element={
                checkPermission(loginData, 'patient-medical-background') ? (
                  <PatientDetails />
                ) : (
                  <Navigate to='/error/404' />
                )
              }
            />
            <Route path='doctor-all-information' element={<DoctorAllInformation />} />
          </Route>
          <Route path='doctor-prescription' element={<DpLayoutWrapper />} />
        </>
      )}

      {loginData?.data?.user_type == 'PATIENT' ? (
        <Route path='*' element={<Navigate to='/page-not-found' />} />
      ) : (
        <Route path='error/*' element={<Navigate to='/error/404' />} />
      )}
    </Routes>
  )
}

export default CommonRoute
