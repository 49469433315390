import { ID, Response } from "../../../../../_jmh/helpers"

export type PatientDoc = {
  id?: ID
  path?: any
  status?: string
  created_at?: string
  updated_at?: string
  initials?: {
    label: string
    state: string
  }
  patient_id?: {
    id: string,
    hospital_id:string
    full_name: string,
    gender: string,
    contact_number:number
    date_of_birth:string
    permanent_address: string,
    email:string,
    alternate_contact_number:string,
    blood_group:string
    status:string
  } |number
  
}

export type PatientDocQueryResponse = Response<Array<PatientDoc>>

export const initialUser: PatientDoc = {
  path: undefined,
  
}
