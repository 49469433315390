// @ts-nocheck
import {Column} from 'react-table'
import {HospitalRoomNumberCell} from './HospitalRoomNumberCell'
import {HospitalRoomActionsCell} from './HospitalRoomActionsCell'
import {HospitalRoomCustomHeader} from './HospitalRoomCustomHeader'
import {HospitalRoom} from '../../core/_models'
import {HospitalMaximumCapacity} from './HospitalMaximumCapacity'
import {HospitalRoomCostCell} from './HospitalRoomCostCell'
import {HospitalRoomTypeInfoCell} from './HospitalRoomTypeInfoCell'
import {loginContext} from '../../../../../context/context'
import {useContext} from 'react'
import {HospitalRoomStatusCell} from './HospitalRoomStatusCell'
import {useIntl} from 'react-intl'

const HospitalRoomColumns: ReadonlyArray<Column<HospitalRoom>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <HospitalRoomCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ROOM.NUMBER'})}
          className='min-w-125px'
        />
      )
    },
    id: 'room_number',
    Cell: ({...props}) => <HospitalRoomNumberCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <HospitalRoomCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ROOM.TYPE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'type',
    Cell: ({...props}) => <HospitalRoomTypeInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <HospitalRoomCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.COST'})}
          className='min-w-125px'
        />
      )
    },
    id: 'cost',
    Cell: ({...props}) => <HospitalRoomCostCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <HospitalRoomCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.MAXIMUM.CAPACITY'})}
          className='min-w-125px'
        />
      )
    },
    id: 'maximum_capacity',
    Cell: ({...props}) => <HospitalMaximumCapacity user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <HospitalRoomCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.STATUS'})}
          className='min-w-125px'
        />
      )
    },
    id: 'availability_status',
    Cell: ({...props}) => <HospitalRoomStatusCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <HospitalRoomCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px pe-5'
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'hospital-room') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH'
            // item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <HospitalRoomCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px'
          />
        ) : (
          ''
        )
      }
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <HospitalRoomActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'hospital-room') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH'
            // item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <HospitalRoomActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        ) : (
          ''
        )
      }
    },
  },
]

export {HospitalRoomColumns}
