// @ts-nocheck
import {Column} from 'react-table'
import {AssetMaintenanceInfoCell} from './AssetMaintenanceInfoCell'
import {AssetMaintenanceActionsCell} from './AssetMaintenanceActionsCell'
import {AssetMaintenanceCustomHeader} from './AssetMaintenanceCustomHeader'
import {AssetMaintenanceModel} from '../../core/_models'
import {AssetMaintenanceDate} from './AssetMaintenanceDate'
import {AssetMaintenanceStatus} from './AssetMaintenanceStatus'
import {AssetMaintenanceType} from './AssetMaintenanceType'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'
const assetsColumns: ReadonlyArray<Column<AssetMaintenanceModel>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetMaintenanceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ASSET.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'asset_id',
    Cell: ({...props}) => <AssetMaintenanceInfoCell asset={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetMaintenanceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.MAINTENANCE.TYPE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'type',
    Cell: ({...props}) => <AssetMaintenanceType asset={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetMaintenanceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DATE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'date',
    Cell: ({...props}) => <AssetMaintenanceDate asset={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetMaintenanceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.STATUS'})}
          className=' min-w-100px'
        />
      )
    },

    accessor: 'maintenance_status',
    Cell: ({...props}) => <AssetMaintenanceStatus asset={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'asset-maintenance') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH'
          // item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <AssetMaintenanceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'asset-maintenance') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH'
          // item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? <AssetMaintenanceActionsCell id={props.data[props.row.index].id} /> : null
    },
  },
]
export {assetsColumns}
