import {FC, useContext, useEffect, useState} from 'react'
import {getPatientName} from '../core/request'
import {loginContext} from '../../../../../app/context/context'
import {useIntl} from 'react-intl'
interface Props {
  patientName: any
  setPatientName: any
}

const PatientNameFilter: FC<Props> = ({patientName, setPatientName}) => {
  const [test, setTest] = useState([])
  const {selectedHospitalId} = useContext(loginContext)
  const intl = useIntl()

  useEffect(() => {
    ;(async () => {
      const patientName: any = await getPatientName()
      const patientNameList: any = patientName?.data?.filter((item: any) => {
        return item?.hospital_id == selectedHospitalId
      })
      setTest(patientNameList)
    })()
  }, [selectedHospitalId])

  return (
    <div className='fv-row'>
      <label className='form-label fw-bold'>
        {intl.formatMessage({id: 'GENERAL.PATIENT.TITLE'})}
      </label>
      <select
        className='form-select border-0 mb-3 cursor-pointer '
        aria-label='Select example'
        value={patientName}
        onChange={(e) => setPatientName(e.target.value)}
      >
        <option value=''>
          {intl.formatMessage({id: 'PATIENT_INSURANCE.SELECT.PATIENT.NAME'})}
        </option>
        {test &&
          test?.map((item: any) => {
            return (
              <option value={item?.id} key={item?.id}>
                {item?.full_name}
              </option>
            )
          })}
      </select>
    </div>
  )
}

export {PatientNameFilter}
