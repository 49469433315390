import {PatientVitalListToolbar} from './PatientVitalListToolbar'

const PatientVitalListHeader = () => {
  return (
    <div className='card-header border-0 px-0 justify-content-end'>
      {/* <PatientVitalListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar '>
        {/* begin::Group actions */}
        <PatientVitalListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PatientVitalListHeader}
