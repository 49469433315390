/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC} from 'react'
import {ATD} from '../../core/_models'

type Props = {
  user: ATD
}

const DischargeDate: FC<Props> = ({user}) => {
  let dicharge
  if (user?.discharge_date !== null) {
    dicharge = moment.utc(user?.discharge_date).format('DD-MM-YYYY  H:mm')
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>{dicharge ? dicharge : ''}</span>
      </div>
    </div>
  )
}

export {DischargeDate}
