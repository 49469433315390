import {useListView} from '../../core/ListViewProvider'
import {NotificationListToolbar} from './NotificationListToolbar'
import {UsersListGrouping} from './NotificationListGrouping'
import {UsersListSearchComponent} from './NotificationListSearchComponent'

const UsersListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent />
      <div className='card-toolbar'>
        {selected.length > 0 ? (
          <UsersListGrouping notification={undefined} />
        ) : (
          <NotificationListToolbar />
        )}
      </div>
    </div>
  )
}

export {UsersListHeader}
