import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {dischargeDate} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {AtdsListLoading} from '../components/loading/AtdsListLoading'
import {getAtdDidchargePatienById} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'

type DischargeModalProps = {
  show: boolean
  onClose: () => void
  id: any
  isUserLoading: boolean
  discharge: dischargeDate
}

const DischargeModal: FC<DischargeModalProps> = ({show, onClose, isUserLoading, id, discharge}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [selDateTime, setSelDateTime] = useState<Date | null>(null)
  const navigate = useNavigate()

  const [userForEdit] = useState<dischargeDate>({
    ...discharge,
    discharge_date: discharge?.discharge_date,
  })

  const cancel = (withRefresh?: boolean) => {
    setSelDateTime(null)
    formik.resetForm()
    onClose()
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: Yup.object().shape({
      discharge_date: Yup.date().required(
        intl.formatMessage({id: 'VALIDATION.ATD.SELECT_DISCHARGE_DATE_TIME'})
      ),
    }),
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      const DischargeDateTime = moment(values['discharge_date'], 'DD-MM-YYYY H:mm').format(
        'DD-MM-YYYY H:mm'
      )

      setSubmitting(true)
      try {
        values['discharge_date'] = DischargeDateTime
        await getAtdDidchargePatienById(values, id).then((res: any) => {
          toast.success(res?.data?.message)
          resetForm()
          onClose()
          navigate('/patient-data/ad-patient')
        })
      } catch (ex: any) {
        toast.warning(ex.response?.data?.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const maxDate = new Date(moment().format('YYYY-MM-DD'))
  return (
    <>
      <div
        className={`modal fade ${show ? 'show' : ''}`}
        style={{display: show ? 'block' : 'none'}}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {intl.formatMessage({id: 'PATIENT_ATD.DISCHARGE.PATIENT'})}
              </h5>
              <button type='button' className='btn-close' onClick={() => cancel()}></button>
            </div>
            <div className='modal-body text-start'>
              <>
                <form
                  id='kt_modal_add_user_form'
                  className='form'
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <div className='d-flex flex-column me-n7 pe-7'>
                    <div className='fv-row mb-7'>
                      <label className={`required fw-bold fs-6 mb-2`}>
                        {intl.formatMessage({id: 'GENERAL.DISCHARGE.DATE.TIME'})}
                      </label>
                      <div className='position-relative'>
                        <DatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          {...formik.getFieldProps('discharge_date')}
                          dropdownMode='select'
                          className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                          dateFormat='dd-MM-yyyy H:mm'
                          name='discharge_date'
                          timeInputLabel='Time:'
                          maxDate={maxDate}
                          selected={selDateTime}
                          onChange={(date) => {
                            formik.setFieldValue('discharge_date', date)
                            setSelDateTime(date)
                          }}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isUserLoading}
                          showTimeInput
                          shouldCloseOnSelect={false}
                          placeholderText={intl.formatMessage({id: 'GENERAL.DISCHARGE.DATE.TIME'})}
                          onKeyDown={(e) => {
                            e.preventDefault()
                          }}
                        />
                        <span
                          className='DatePicker_icon cursor-pointer'
                          onClick={() => document.getElementsByName('discharge_date')[0].focus()}
                        >
                          <i className='fa fa-calendar'></i>
                        </span>
                      </div>
                      {formik.touched.discharge_date && formik.errors.discharge_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.discharge_date}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => cancel()}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                      disabled={formik.isSubmitting || isUserLoading}
                    >
                      {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                      disabled={
                        isUserLoading ||
                        formik.isSubmitting ||
                        !formik.isValid ||
                        !formik.touched ||
                        !formik.dirty
                      }
                    >
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'GENERAL.SAVE'})}
                      </span>
                      {(formik.isSubmitting || isUserLoading) && (
                        <span className='indicator-progress'>
                          {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
                {(formik.isSubmitting || isUserLoading) && <AtdsListLoading />}
              </>
            </div>
          </div>
        </div>
      </div>
      {show && <div className='modal-backdrop fade show'></div>}
    </>
  )
}

export default DischargeModal
