import React, {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import OtpInput from 'react-otp-input'
import {toast} from 'react-toastify'
import {verify_register} from '../../core/_requests'
import {useAuth} from '../../core/Auth'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

interface OtpVerifyProps {
  emailValue?: string
  doctorId?: any
  patientSelectedHospitalId?: any
}

const OtpVerify: React.FC<OtpVerifyProps> = ({emailValue, doctorId, patientSelectedHospitalId}) => {
  const navigate = useNavigate()
  const initialValues = {}
  const [code, setCode] = useState<string | null>(null)
  const {saveAuth, setCurrentUser} = useAuth()
  const {setLoginData} = useContext(loginContext)
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const handleChange = (code: string) => {
    setCode(code)
  }
  const validationSchema = Yup.object({})

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      verifyOtp()
    },
  })

  const verifyOtp = async () => {
    setLoading(true)
    try {
      if (typeof code !== 'string' || code.toString().length !== 4) {
        throw new Error(intl.formatMessage({id: 'VALIDATION.OTP_VARIFICATION'}))
      }

      if (typeof emailValue === 'string') {
        await verify_register(emailValue, code).then((res: any) => {
          const auth: any = res?.data
          const {data} = auth
          setLoginData(auth)

          const userData: any = {
            id: data?.id,
            email: data?.email,
            isPasswordChange: data?.is_password_change,
            userType: data?.user_type,
            access_token: data?.access_token,
            patient_id: data?.patient_id,
          }

          localStorage.setItem('loginData', JSON.stringify(auth))
          saveAuth(userData)
          setCurrentUser(userData?.access_token)
          navigate('/patient-booking', {
            state: {doctorId: doctorId, patientSelectedHospitalId: patientSelectedHospitalId},
          })
        })
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='login-form'>
        <div className='row'>
          <div className='col-sm-12 p-0'>
            <form
              className={clsx('form w-100', {
                'form-not-clickable': loading,
              })}
              onSubmit={formik.handleSubmit}
            >
              <div className='text-center'>
                <h1 className='mt-5 font-weight-bold'>
                  {intl.formatMessage({id: 'GENERAL.OTP.TITLE'})}
                </h1>
                <p>
                  {intl.formatMessage({id: 'LANDING_PAGE.OTP.TEXT'})} {emailValue}
                </p>
              </div>

              <div className='form-group'>
                <div className='w-100 d-flex align-align-items-center justify-content-center'>
                  <OtpInput
                    value={code !== null ? code.toString() : ''}
                    onChange={handleChange}
                    numInputs={4}
                    // @ts-ignore
                    renderInput={(props: any) => <input {...props} />}
                    // @ts-ignore
                    isInputNum={true}
                    renderSeparator={<span className='mx-2'>-</span>}
                    shouldAutoFocus={true}
                    inputType='number'
                    inputStyle={{
                      border: '1px solid',
                      borderRadius: '8px',
                      width: '54px',
                      height: '54px',
                      fontSize: '12px',
                      color: '#000',
                      fontWeight: '400',
                      caretColor: 'blue',
                    }}
                    focusStyle={{
                      border: '1px solid #CFD3DB',
                      outline: 'none',
                    }}
                  />
                </div>
              </div>
              <div className='input-group text-center w-auto mt-50 justify-content-center'>
                <button type='submit' disabled={!code} className='btn btn-lg btn-primary'>
                  {!loading && (
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'GENERAL.OTP.VERIFY'})}
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default OtpVerify
