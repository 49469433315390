import {ID, Response} from '../../../../../_jmh/helpers'

export type PurchaseInventory = {
  id?: ID
  name?: string
  category_name?: string
  available_quantity?: number
  description?: string
  purchase_date?: string
  purchase_cost?: number
  manufacturer_name?: string
  status?: string
  created_at?: string
  updated_at?: string
  hospital_id?: number
  initials?: {
    label: string
    state: string
  }
  category_id?:
    | {
        id?: ID
        name?: string
        status?: string
        created_at?: string
        updated_at?: string
      }
    | number
}

export type PurchaseInvQueryResponse = Response<Array<PurchaseInventory>>

export const initialUser: PurchaseInventory = {
  name: '',
  // category_name: '',
  // available_quantity: 0,
  purchase_date: '',
  purchase_cost: 0,
  manufacturer_name: '',
  status: '',
  description: '',
}
