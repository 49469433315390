import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {InvManagementListHeader} from './components/header/InvManagementListHeader'
import {InvManagementTable} from './table/InvManagementTable'
import {InvManagementEditModal} from './InvManagement-edit-modal/InvManagementEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const InvManagementList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <InvManagementListHeader />
        <InvManagementTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <InvManagementEditModal />}
    </>
  )
}

const InvManagementListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <InvManagementList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {InvManagementListWrapper}
