import {ID, Response} from '../../../../../_jmh/helpers'

export type Operationlist = {
  id?: ID
  hospital_id?: number
  patient_id?: number
  doctor_ids?: number[]
  hospital_room_id?: number
  operation_start_date?: string
  operation_end_date?: string
  operation_type?: string
  operation_description?: string
  created_at?: string
  updated_at?: string
  initials?: {
    label: string
    state: string
  }
  patient?: {
    full_name?: string
    gender?: string
    contact_number?: string
    alternate_contact_number?: string
    date_of_birth?: string
    permanent_address?: string
    blood_group?: string
    email?: string
    hospital_id?: number
    status?: string
  }
  doctor?: {
    full_name?: string | undefined
    email?: string
    date_of_birth?: string
    gender?: string
    permanent_address?: string
    contact_number?: string
    visiting_doctor?: any
    qualification?: string | undefined
    years_of_experience?: number
    role_id?: number
    hospital_id?: number
    speciality_id?: number
    image?: any
    status?: string
  }
  hospital_room?: {
    id: string
    room_number: string
    type: string
    description: string
    cost: number
    maximum_capacity: number
    availability_status: string
    status: string
    created_at: string
    updated_at: string
  }
  allocated_doctor?: {
    id: string
    operation_theatre_id: string
    doctor_id: string
    doctor: {
      id: string
      full_name: string
      gender: string
      image: string
      date_of_birth: string
      permanent_address: string
      contact_number: string
      visiting_doctor: boolean
      qualification: string
      years_of_experience: number
      user_id: string
      speciality_id: string
      status: string
      created_at: string
      updated_at: string
    }
  }[]
  hospital?: {
    id: string
    name: string
    address: string
    contact_person: string
    contact_number: string
    status: 'ACTIVE' | 'INACTIVE'
    created_at: string
    updated_at: string
  }
}

export type UsersQueryResponse = Response<Array<Operationlist>>

export const initialUser: Operationlist = {
  operation_start_date: '',
  operation_type: '',
  operation_description: '',
}
