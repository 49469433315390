import {useContext, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTCardBody, KTSVG} from '../../../_jmh/helpers'
import {getPatientMedication} from './core/_request'
import {loginContext} from '../../context/context'
import moment from 'moment'
import ReactPaginate from 'react-paginate'

const OpMedication = ({selectedAppointment}: any) => {
  const intl = useIntl()
  const {loginData} = useContext(loginContext)
  const [medicationData, setMedicationData] = useState<any>(null)
  const limit = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const pageCount = Math.max(Math.ceil(totalCount / limit), 1)
  const colors = ['primary', 'success', 'danger', 'warning', 'info']
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    fetchMedicationData()
  }, [currentPage, searchTerm, selectedAppointment])

  const fetchMedicationData = () => {
    getPatientMedication(
      searchTerm,
      loginData?.data?.patient_id,
      loginData?.data?.hospital_id,
      selectedAppointment
        ? selectedAppointment?.value === 'all'
          ? ''
          : selectedAppointment?.value
        : ''
    ).then((res: any) => {
      setMedicationData(res?.data?.data)
      setTotalCount(res?.data?.pagination?.meta?.totalItems)
    })
  }

  const groupedData = useMemo(() => {
    const grouped: any = {}

    medicationData &&
      medicationData.forEach((item: any) => {
        const year = moment(item.assigned_date).format('YYYY')
        const month = moment(item.assigned_date).format('MMM')

        if (!grouped[year]) {
          grouped[year] = {}
        }

        if (!grouped[year][month]) {
          grouped[year][month] = []
        }

        grouped[year][month].push(item)
      })

    return grouped
  }, [medicationData])

  const sortedYears = Object.keys(groupedData).sort((a: any, b: any) => b - a)

  const handlePageClick = (selectedPage: any) => {
    setCurrentPage(selectedPage?.selected + 1)
  }

  return (
    <div className='op_inner_wrapper py-5'>
      <KTCardBody className=' py-0 px-0'>
        <div className='d-flex justify-content-between align-items-center mb-25'>
          <h2 className='op_head_title m-0'>Your medication</h2>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='search'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className='ps-10 px-1'>
          <div
            className={`row border border-dashed border-gray-300 rounded min-w-800px px-1 py-2 mb-3 bg-light`}
          >
            <div className='col-4'>
              <div className='fs-6 text-muted'>
                {intl.formatMessage({id: 'GENERAL.MEDICATION.NAME'})}
              </div>
            </div>
            <div className='col-2'>
              <div className='fs-6 text-muted'>Hospital name</div>
            </div>
            <div className='col-2'>
              <div className='fs-6 text-muted'>
                {intl.formatMessage({id: 'MEDICATION.ASSIGN.DATE'})}
              </div>
            </div>
            <div className='col-1'>
              <div className='fs-6 text-muted'>{intl.formatMessage({id: 'GENERAL.DOSAGE'})}</div>
            </div>
            <div className='col-2'>
              <div className='fs-6 text-muted'>{intl.formatMessage({id: 'GENERAL.FREQUENCY'})}</div>
            </div>
            <div className='col-1'>
              <div className='fs-6 text-muted'>{intl.formatMessage({id: 'GENERAL.DURATION'})}</div>
            </div>
          </div>
        </div>
        <div className='timeline'>
          {sortedYears.length > 0 ? (
            <>
              {sortedYears.map((year, index: any) => (
                <div key={`year-${year}`} className='timeline-item'>
                  {' '}
                  {/* Move timeline-item here */}
                  <div
                    className='timeline-line w-20px'
                    style={{marginTop: '18px', left: '-1px'}}
                  ></div>
                  <div className='timeline-badge'>
                    <i
                      className={`fa fa-genderless text-${colors[index % colors.length]} fs-1`}
                    ></i>
                  </div>
                  <div className='timeline-content '>
                    <div className='mb-5'>
                      <div className='fs-5 fw-semibold mb-2'>
                        <h4>{year}</h4>

                        {Object.keys(groupedData[year])
                          .sort((a, b) => moment(b, 'MMM').diff(moment(a, 'MMM')))
                          .map((month) => (
                            <div key={`month-${year}-${month}`} className='mb-4'>
                              <h5>{month}</h5>

                              {groupedData[year][month].map((item: any, i: any) => (
                                <div key={`row-${i}`} className='row  '>
                                  <div
                                    className={`row border border-dashed border-gray-300 rounded min-w-800px px-1 py-2 mb-3 bg-light-${
                                      colors[index % colors.length]
                                    } bg-hover-secondary text-hover-dark text-${
                                      colors[index % colors.length]
                                    }
                                  } `}
                                  >
                                    <div className='col-4'>
                                      <span className='fs-4 fw-semibold'>
                                        {item.medication_name}
                                      </span>
                                    </div>
                                    <div className='col-2'>
                                      <div className='fs-4  fw-semibold'>
                                        {item?.hospital_id?.name}
                                      </div>
                                    </div>
                                    <div className='col-2'>
                                      <span className={`fs-4   fw-semibold `}>
                                        {moment(item.assigned_date).format('DD-MM-YYYY')}
                                      </span>
                                    </div>
                                    <div className='col-1'>
                                      <div className='fs-4  fw-semibold'>{item.dosage}</div>
                                    </div>
                                    <div className='col-2'>
                                      <div className='fs-4  fw-semibold'>{item.frequency}</div>
                                    </div>
                                    <div className='col-1'>
                                      <div className='fs-4  fw-semibold'>{item.duration}</div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className='d-flex bg-light fs-5 py-3 text-dark fw-bold text-center w-100 align-content-center justify-content-center'>
              {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
            </div>
          )}
        </div>
      </KTCardBody>
      {medicationData?.length >= 1 && (
        <nav className='op_pagination'>
          <ReactPaginate
            breakLabel='...'
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName='pagination'
            activeClassName='active'
            previousLabel='Previous'
            nextLabel='Next'
          />
        </nav>
      )}
    </div>
  )
}

export default OpMedication
