import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {RolesListWrapper} from './Roleslist/Rolelist'

const Rolespermission = () => (
  <>
    <div className=''>
      <RolesListWrapper />
    </div>
  </>
)

const RolesPermissionsWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ROLE.PERMISSION'})}</PageTitle>
      <Rolespermission />
    </>
  )
}

export {RolesPermissionsWrapper}
