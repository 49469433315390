import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {PayrollsListWrapper} from './Payrolls-list/PayrollsList'

const PayrollPage = () => (
  <>
    <div className='row g-5 g-xl-8'>
      <PayrollsListWrapper />
    </div>
  </>
)

const PayrollWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'STAFF.PAYROLL'})}</PageTitle>
      <PayrollPage />
    </>
  )
}

export {PayrollWrapper}
