import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {AssetLists} from '../../../_jmh/partials/widgets/lists/AssetLists'
import {DashboardCard} from '../../../_jmh/partials/widgets/statistics/DashboardCard'
import {Link} from 'react-router-dom'
import {InventoryLists} from '../../../_jmh/partials/widgets/lists/InventoryLists'
import {DoctorLists} from '../../../_jmh/partials/widgets/lists/DoctorLists'
import {StaffDashboardWrapper} from './StaffDashboard'
import {StaffChartsWidget} from '../../../_jmh/partials/widgets/charts/StaffChartsWidget'
import {useContext} from 'react'
import {loginContext} from '../../context/context'
import {toast} from 'react-toastify'
import {forEach, forIn} from 'lodash'

const DashboardPage = () => {
  const {loginData} = useContext(loginContext)
  const intl = useIntl()

  function checkPermission(
    loginData: any,
    moduleName: string,
    route?: string,
    method?: string
  ): boolean {
    const permissions = loginData?.data?.permissions

    const modulePermissions = permissions?.filter((permission: any) => {
      if (route && method) {
        return (
          permission?.module?.name === moduleName &&
          permission?.permission?.route === route &&
          permission?.permission?.method === method
        )
      }
      return permission?.module?.name === moduleName
    })

    return modulePermissions?.length > 0
  }

  const handleDashboardCardClick = () => {
    toast.error('You do not have permission to access this module.')
  }

  function getAllModuleNames(modulenames: any): boolean {
    const permissions = loginData?.data?.permissions
    if (!permissions) return false

    const moduleNames = permissions.map((permission: any) => permission?.module?.name)
    // const check = moduleNames.filter((moduleName: any) => modulenames.includes(moduleName))
    for (const mn of moduleNames) {
      if (mn === modulenames) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          {checkPermission(loginData, 'patient-management') ||
          loginData?.data?.user_type === 'SUPER_ADMIN' ? (
            <>
              <Link to='/patient-data/patient-management'>
                <DashboardCard
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/icons/duotune/medicine/med013.svg'
                  color='primary'
                  iconColor='#009ef7'
                  title={intl.formatMessage({id: 'PATIENT.DETAILS'})}
                  description=''
                  titleColor='white'
                  descriptionColor='white'
                />
              </Link>
            </>
          ) : (
            <>
              <Link to='#'>
                <DashboardCard
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/icons/duotune/medicine/med013.svg'
                  color='primary'
                  iconColor='white'
                  title={intl.formatMessage({id: 'PATIENT.DETAILS'})}
                  description=''
                  titleColor='white'
                  descriptionColor='white'
                  onClick={handleDashboardCardClick}
                />
              </Link>
            </>
          )}
        </div>

        <div className='col-xl-4'>
          {checkPermission(loginData, 'doctor-management') ||
          loginData?.data?.user_type === 'SUPER_ADMIN' ? (
            <Link to='/doctor-management'>
              <DashboardCard
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/medicine/med011.svg'
                color='success'
                iconColor=''
                title={intl.formatMessage({id: 'DOCTOR.DETAILS'})}
                description=''
                titleColor='white'
                descriptionColor='white'
              />
            </Link>
          ) : (
            <Link to='#'>
              <DashboardCard
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/medicine/med011.svg'
                color='success'
                iconColor=''
                title={intl.formatMessage({id: 'DOCTOR.DETAILS'})}
                description=''
                titleColor='white'
                descriptionColor='white'
                onClick={handleDashboardCardClick}
              />
            </Link>
          )}
        </div>

        <div className='col-xl-4'>
          {checkPermission(loginData, 'staff') || loginData?.data?.user_type === 'SUPER_ADMIN' ? (
            <Link to='/staff-management'>
              <DashboardCard
                className='card-xl-stretch mb-5 mb-xl-8'
                svgIcon='/media/icons/duotune/medicine/med012.svg'
                color='danger'
                iconColor='white'
                title={intl.formatMessage({id: 'STAFF.DETAILS'})}
                description=''
                titleColor='white'
                descriptionColor='white'
              />
            </Link>
          ) : (
            <Link to='#'>
              <DashboardCard
                className='card-xl-stretch mb-5 mb-xl-8'
                svgIcon='/media/icons/duotune/medicine/med012.svg'
                color='danger'
                iconColor='white'
                title={intl.formatMessage({id: 'STAFF.DETAILS'})}
                description=''
                titleColor='white'
                descriptionColor='white'
                onClick={handleDashboardCardClick}
              />
            </Link>
          )}
        </div>
      </div>
      {getAllModuleNames('doctor-appointment') || loginData?.data?.user_type === 'SUPER_ADMIN' ? (
        <div className='row g-5 g-xl-8'>
          <StaffDashboardWrapper />
        </div>
      ) : null}

      <div className='row g-5 g-xl-8'>
        {getAllModuleNames('doctor-availability') ||
        loginData?.data?.user_type === 'SUPER_ADMIN' ? (
          <div className='col-xl-12'>
            <DoctorLists className='card-xl-stretch mb-xl-8' />
          </div>
        ) : null}

        {getAllModuleNames('asset-maintenance') || loginData?.data?.user_type === 'SUPER_ADMIN' ? (
          <div className='col-xl-6'>
            <AssetLists className='card-xl-stretch mb-xl-8' />
          </div>
        ) : null}

        {getAllModuleNames('inventory') || loginData?.data?.user_type === 'SUPER_ADMIN' ? (
          <div className='col-xl-6'>
            <InventoryLists className='card-xl-stretch mb-xl-8' />
          </div>
        ) : null}

        {getAllModuleNames('patient-billing') || loginData?.data?.user_type === 'SUPER_ADMIN' ? (
          <div className='col-xl-12'>
            <StaffChartsWidget className='card-xl-stretch mb-5 mb-xl-8' />
          </div>
        ) : null}
      </div>
      {/* <div className='row g-5 g-xl-8'>
        <StaffDashboardWrapper />
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <DoctorLists className='card-xl-stretch mb-xl-8' />
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <AssetLists className='card-xl-stretch mb-xl-8' />
        </div>

        <div className='col-xl-6'>
          <InventoryLists className='card-xl-stretch mb-xl-8' />
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <StaffChartsWidget className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
