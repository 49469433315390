// @ts-nocheck
import {useContext} from 'react'
import {Column} from 'react-table'
import {loginContext} from '../../../../../context/context'
import {Doctor} from '../../core/_models'
import {DoctorActionsCell} from './DoctorActionsCell'
import {DoctorCustomHeader} from './DoctorCustomHeader'
import {DoctorEmailCell} from './DoctorEmailCell'
import {DoctorInfoCell} from './DoctorInfoCell'
import {DoctorSpecialtyCell} from './DoctorSpecialtyCell'
import {DoctorStatusCell} from './DoctorStatusCell'
import {useIntl} from 'react-intl'

const doctorsColumns: Array<Column<Doctor>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <DoctorCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DOCTOR.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'full_name',
    Cell: ({...props}) => <DoctorInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <DoctorCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.EMAIL'})}
          className='min-w-125px'
        />
      )
    },
    id: 'user',
    Cell: ({...props}) => <DoctorEmailCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <DoctorCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
          className='min-w-125px'
        />
      )
    },
    id: 'contact_number',
    Cell: ({...props}) => <DoctorSpecialtyCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <DoctorCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.STATUS'})}
          className='min-w-30px'
        />
      )
    },
    id: 'Status',
    Cell: ({...props}) => <DoctorStatusCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <DoctorCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px pe-5'
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'doctor') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH' ||
            item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <DoctorCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px'
          />
        ) : (
          ''
        )
      } else {
        return null
      }
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <DoctorActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'doctor') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH' ||
            item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <DoctorActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        ) : null
      } else {
        return null
      }
    },
  },
]

export {doctorsColumns}
