import axios, {AxiosResponse} from 'axios'
import { UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const AUDIT_LOGS_URL = `${API_URL}/audit-logs`

const getAuditLogs = (query: string,id:number): Promise<UsersQueryResponse> => {
  return axios
    // .get(`${AUDIT_LOGS_URL}?${query}`)
    .get(`${AUDIT_LOGS_URL}?${query ? `${query}&hospital_id=${id}` : `&hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}




export {getAuditLogs}
