import {FC, useState, useEffect, useContext} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialAssetMaintenance, AssetMaintenanceModel} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {AssetMaintenanceLoading} from '../components/loading/AssetMaintenanceLoading'
import {createAssetMaintenance, getAsset, updateAssetMaintenance} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import moment from 'moment'
import {toast} from 'react-toastify'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'
type Props = {
  isAssetLoading: boolean
  assetMaintenance: AssetMaintenanceModel
}
const AssetMaintenanceEditModalForm: FC<Props> = ({assetMaintenance, isAssetLoading}) => {
  const intl = useIntl()
  const [assetDate, setAssetDate] = useState<Date | null>(null)
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [test, setTest] = useState([])
  const {refetch} = useQueryResponse()
  const {selectedHospitalId} = useContext(loginContext)

  let editUserSchema
  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      type: Yup.string()
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        })
        .required(intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.TYPE'})),
      maintenance_status: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.STATUS'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.STATUS'})),
      description: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  } else {
    editUserSchema = Yup.object().shape({
      asset_id: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.ASSET_NAME'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.ASSET_NAME'})),
      type: Yup.string()
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        })
        .required(intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.TYPE'})),
      maintenance_status: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.STATUS'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.STATUS'})),

      date: Yup.date().required(intl.formatMessage({id: 'VALIDATION.DATE'})),
      description: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  }
  const [assetForEdit] = useState<AssetMaintenanceModel>({
    ...assetMaintenance,
    asset_id: assetMaintenance.asset_id || initialAssetMaintenance.asset_id,
    type: assetMaintenance.type || initialAssetMaintenance.type,
    date: assetMaintenance.date || initialAssetMaintenance.date,
    maintenance_status:
      assetMaintenance.maintenance_status || initialAssetMaintenance.maintenance_status,
    cost: assetMaintenance.cost || initialAssetMaintenance.cost,
    description: assetMaintenance.description || initialAssetMaintenance.description,
  })
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  useEffect(() => {
    ;(async () => {
      const assetData: any = await getAsset()

      const filterAsset = assetData?.data?.filter((item: any) => {
        return item?.hospital_id?.id == selectedHospitalId
      })

      setTest(filterAsset)
    })()
  }, [])

  const formik = useFormik({
    initialValues: assetForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          delete values.asset_id
          const formData = new FormData()
          formData.append(
            'date',
            (values['date'] = moment(values['date'], 'DD-MM-YYYY').format('DD-MM-YYYY') as any)
          )
          await updateAssetMaintenance(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          const formData = new FormData()
          formData.append(
            'date',
            (values['date'] = moment(values['date']).format('DD-MM-YYYY') as any)
          )
          await createAssetMaintenance({
            ...values,
            asset_id: Number(values.asset_id),
            hospital_id: Number(selectedHospitalId),
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        if (ex?.response?.status) {
          toast.error(ex?.response?.data?.message)
        }
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const past_date_disable = new Date(moment().format('YYYY-MM-DD'))

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label id='asset' className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.ASSET.NAME'})}
              </label>
              <div>
                <select
                  className='form-select select_arrow_remove'
                  aria-label='Select example'
                  {...formik.getFieldProps('asset_id.id')}
                  name='asset_id'
                  disabled={itemIdForUpdate ? true : false}
                >
                  <option value=''>
                    {intl.formatMessage({id: 'ASSET.MAINTENANCE.SELECT.ASSET.NAME'})}
                  </option>
                  {test &&
                    test?.map((item: any) => {
                      return (
                        <option value={item?.id} key={item?.id}>
                          {item?.name}
                        </option>
                      )
                    })}
                </select>
                {formik.touched.asset_id && formik.errors.asset_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.asset_id}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.MAINTENANCE.TYPE'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.MAINTENANCE.TYPE'})}
                {...formik.getFieldProps('type')}
                type='text'
                name='type'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0', {
                  'is-invalid': formik.touched.type && formik.errors.type,
                })}
                autoComplete='off'
                disabled={formik.isSubmitting || isAssetLoading}
              />
              {formik.touched.type && formik.errors.type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.type}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.DATE'})}
              </label>
              <div className='position-relative'>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('date')}
                  dropdownMode='select'
                  dateFormat='dd-MM-yyyy'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0 cursor-pointer')}
                  name='date'
                  minDate={past_date_disable}
                  selected={assetDate}
                  onChange={(date) => {
                    formik.setFieldValue('date', date)
                    setAssetDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isAssetLoading}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  placeholderText={intl.formatMessage({id: 'GENERAL.DATE'})}
                />
                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('date')[0].focus()}
                >
                  <i className='fa fa-calendar'></i>
                </span>
              </div>
              {formik.touched.date && formik.errors.date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.date}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.STATUS'})}
              </label>
              <div className='fv-row mb-7'>
                <select
                  className='form-select'
                  aria-label='Select example'
                  {...formik.getFieldProps('maintenance_status')}
                  name='maintenance_status'
                >
                  <option value=''>{intl.formatMessage({id: 'GENERAL.SELECT.STATUS'})}</option>
                  <option value='PENDING'>
                    {intl.formatMessage({id: 'GENERAL.PENDING.STATUS'})}
                  </option>
                  <option value='IN MAINTENANCE'>
                    {intl.formatMessage({id: 'GENERAL.IN.MAINTENANCE.STATUS'})}
                  </option>
                  <option value='COMPLETED'>{intl.formatMessage({id: 'GENERAL.COMPLETED'})}</option>
                </select>
                {formik.touched.maintenance_status && formik.errors.maintenance_status && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.maintenance_status}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.COST'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.COST'})}
                {...formik.getFieldProps('cost')}
                type='number'
                name='cost'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting || isAssetLoading}
              />
              {formik.touched.cost && formik.errors.cost && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.cost}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 ps-0'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
              </label>
              <textarea
                placeholder={intl.formatMessage({id: 'ASSET.ENTER.ASSET.DESCRIPTION'})}
                {...formik.getFieldProps('description')}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
                name='description'
                autoComplete='off'
              />
              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.description}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='text-center'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isAssetLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isAssetLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isAssetLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isAssetLoading) && <AssetMaintenanceLoading />}
    </>
  )
}
export {AssetMaintenanceEditModalForm}
