/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-dupe-keys */
import {FC, useState, useEffect, useContext} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialAsset, AssetModel} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {AssetListLoading} from '../components/loading/AssetListLoading'
import {createAsset, updateAsset, getAssetName} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: AssetModel
}
const AssetEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const [purchaseDate, setPurchaseDate] = useState<Date | null>(null)
  const [disposalDate, setDisposalDate] = useState<Date | null>(null)
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [test, setTest] = useState([])
  const {refetch} = useQueryResponse()
  const {selectedHospitalId} = useContext(loginContext)

  let editUserSchema

  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      name: Yup.string()
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        })
        .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
        .matches(/^[A-Za-z\s]+$/, intl.formatMessage({id: 'VALIDATION.TEXT_INPUT'}))
        .required(intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.ASSET_NAME'})),
      purchase_date: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.REQ.PURCHASE_DATE'})
      ),
      disposal_date: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.REQ.DISPOSAL_DATE'})
      ),
      asset_status: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.ASSET_STATUS'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.REQ.ASSET_STATUS'})),
      description: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  } else {
    editUserSchema = Yup.object().shape({
      name: Yup.string()
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        })
        .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
        .matches(/^[A-Za-z\s]+$/, intl.formatMessage({id: 'VALIDATION.TEXT_INPUT'}))
        .required(intl.formatMessage({id: 'VALIDATION.ASSET_MAIN.ASSET_NAME'})),
      category_id: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.ASSET_CATEGORY.NAME'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.ASSET_CATEGORY.NAME'})),

      purchase_date: Yup.date().required(intl.formatMessage({id: 'VALIDATION.REQ.PURCHASE_DATE'})),
      disposal_date: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.REQ.DISPOSAL_DATE'})
      ),

      asset_status: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.ASSET_STATUS'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.REQ.ASSET_STATUS'})),
      description: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  }

  const [userForEdit] = useState<AssetModel>({
    ...user,
    name: user.name || initialAsset.name,
    purchase_date: user.purchase_date || initialAsset.purchase_date,
    purchase_cost: user.purchase_cost || initialAsset.purchase_cost,
    disposal_date: user.disposal_date || initialAsset.disposal_date,
    current_value: user.current_value || initialAsset.current_value,
    description: user.description || initialAsset.description,
    category_id: user.category_id || initialAsset.category_id,
    asset_status: user.asset_status || initialAsset.asset_status,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    ;(async () => {
      const assetCategoryData: any = await getAssetName()

      const filterAsset = assetCategoryData?.data?.filter((item: any) => {
        return item?.hospital_id?.id == selectedHospitalId
      })

      setTest(filterAsset)
    })()
  }, [])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const formData = new FormData()
          formData.append(
            'purchase_date',
            (values['purchase_date'] = moment(values['purchase_date'], 'DD-MM-YYYY').format(
              'DD-MM-YYYY'
            ) as any)
          )
          formData.append(
            'disposal_date',
            (values['disposal_date'] = moment(values['disposal_date'], 'DD-MM-YYYY').format(
              'DD-MM-YYYY'
            ) as any)
          )
          await updateAsset({
            ...values,
            category_id:
              typeof values.category_id === 'object' ? Number(values.category_id.id) : undefined,
            hospital_id: Number(selectedHospitalId),
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          const formData = new FormData()
          formData.append(
            'purchase_date',
            (values['purchase_date'] = moment(values['purchase_date']).format('DD-MM-YYYY') as any)
          )
          formData.append(
            'disposal_date',
            (values['disposal_date'] = moment(values['disposal_date']).format('DD-MM-YYYY') as any)
          )
          await createAsset({
            ...values,
            category_id: Number(values.category_id),
            hospital_id: Number(selectedHospitalId),
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const maxDate = new Date(moment().format('YYYY-MM-DD'))

  return (
    <>
      <form id='kt_modal_add_user_form3' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column  me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.ASSET.NAME'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.ASSET.NAME'})}
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>

            {itemIdForUpdate ? (
              ''
            ) : (
              <div className='fv-row mb-7 col-12 col-sm-6'>
                <label id='asset-category' className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GENERAL.ASSET.CATEGORY.NAME'})}
                </label>

                <select
                  className=' form-select select_arrow_remove'
                  aria-label='Select example'
                  // value={formik.values.category_id?.name}
                  {...formik.getFieldProps('category_id')}
                  name='category_id'
                  // disabled={itemIdForUpdate ? true : false}
                >
                  <option value=''>
                    {intl.formatMessage({id: 'ASSET.SELECT.ASSET.CATEGORY.NAME'})}
                  </option>
                  {test &&
                    test?.map((item: any) => {
                      return (
                        <option value={item?.id} key={item?.id}>
                          {item?.name}
                        </option>
                      )
                    })}
                </select>
                {formik.touched.category_id && formik.errors.category_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.category_id}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className='row justify-content-between'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.PURCHASE.DATE'})}
              </label>

              <div className='position-relative'>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('purchase_date')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy'
                  name='purchase_date'
                  maxDate={maxDate}
                  selected={purchaseDate}
                  onChange={(date) => {
                    formik.setFieldValue('purchase_date', date)
                    setPurchaseDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  placeholderText={intl.formatMessage({id: 'GENERAL.PURCHASE.DATE'})}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('purchase_date')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>

              {formik.touched.purchase_date && formik.errors.purchase_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.purchase_date}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className=' required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'ASSET.DISPOSAL.DATE'})}
              </label>

              <div className='position-relative'>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('disposal_date')}
                  dropdownMode='select'
                  className='form-control form-control-solid cursor-pointer mb-3 mb-lg-0'
                  dateFormat='dd-MM-yyyy'
                  name='disposal_date'
                  minDate={purchaseDate}
                  selected={disposalDate}
                  onChange={(date) => {
                    formik.setFieldValue('disposal_date', date)
                    setDisposalDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  placeholderText={intl.formatMessage({id: 'ASSET.DISPOSAL.DATE'})}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('disposal_date')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>

              {formik.touched.disposal_date && formik.errors.disposal_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.disposal_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row justify-content-between'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.PURCHASE.COST'})}
              </label>
              <input
                {...formik.getFieldProps('purchase_cost')}
                placeholder={intl.formatMessage({id: 'GENERAL.PURCHASE.COST'})}
                type='number'
                name='purchase_cost'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className=' fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'ASSET.CURRENT.VALUE'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'ASSET.CURRENT.VALUE'})}
                {...formik.getFieldProps('current_value')}
                type='number'
                name='current_value'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.current_value && formik.errors.current_value && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.current_value}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'ASSET.ASSET.DESCRIPTION'})}
            </label>

            <textarea
              rows={3}
              cols={50}
              style={{resize: 'none'}}
              placeholder={intl.formatMessage({id: 'ASSET.ENTER.ASSET.DESCRIPTION'})}
              {...formik.getFieldProps('description')}
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='row'>
          <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'GENERAL.STATUS'})}
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              {...formik.getFieldProps('asset_status')}
              name='asset_status'
            >
              <option value=''>{intl.formatMessage({id: 'GENERAL.SELECT.STATUS'})}</option>
              <option value='ACTIVE'>{intl.formatMessage({id: 'GENERAL.ACTIVE.STATUS'})}</option>
              <option value='INACTIVE'>
                {intl.formatMessage({id: 'GENERAL.IN.ACTIVE.STATUS'})}
              </option>
              <option value='IN MAINTENANCE'>
                {intl.formatMessage({id: 'GENERAL.IN.MAINTENANCE.STATUS'})}
              </option>
              <option value='BROKEN'>{intl.formatMessage({id: 'GENERAL.BROKEN.STATUS'})}</option>
            </select>
            {formik.touched.asset_status && formik.errors.asset_status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.asset_status}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <AssetListLoading />}
    </>
  )
}

export {AssetEditModalForm}
