import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {NotificationModel} from '../../core/_models'

type Props = {
  row: Row<NotificationModel>
}

const CustomRow: FC<Props> = ({row}) => {
  const isRead = row?.original?.is_read === true

  return (
    <tr {...row.getRowProps()} className={clsx({'bg-white': isRead, 'bg-secondary': !isRead})}>
      {row.cells.map((cell) => (
        <td
          {...cell.getCellProps()}
          className={clsx({
            'text-end min-w-130px': cell.column.id === 'actions',
          })}
        >
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  )
}

export {CustomRow}
