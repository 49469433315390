import OpLayout from './OpLayout'
import {Tab, Tabs} from 'react-bootstrap'
import Select from 'react-select'
import OpMedication from './OpMedication'
import OpMedicalNotes from './OpMedicalNotes'
import OpVitals from './OpVitals'
import OpMedicalBackground from './OpMedicalBackground'
import OpAttachments from './OpAttachments'
import {useContext, useEffect, useState} from 'react'
import {getAppointmentByPatientId} from './core/_request'
import {loginContext} from '../../context/context'

const OpPatientDetails = () => {
  const [appointmentList, setAppointmentList] = useState<any>([])
  const [selectedAppointment, setSelectedAppointment] = useState<any>([])
  const {loginData} = useContext(loginContext)

  const fetchAppointments = () => {
    getAppointmentByPatientId(loginData?.data?.patient_id).then((res: any) => {
      const formattedAppointments = [
        {value: 'all', label: 'All'},
        ...res.data.map((appointment: any) => ({
          value: appointment.id,
          label: `${appointment.id}(${appointment.description})`,
        })),
      ]

      setAppointmentList(formattedAppointments)
      setSelectedAppointment(formattedAppointments[0])
    })
  }

  const handleAppointmentChange = (selectedOption: any) => {
    setSelectedAppointment(selectedOption)
  }

  useEffect(() => {
    fetchAppointments()
  }, [])

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  return (
    <OpLayout>
      <div className='op_patient_details_wrapper p-5'>
        <div className='d-flex justify-content-between align-items-center mb-5'>
          <h1 className='text-capitalize'>Medical Details</h1>
          <div className=' d-flex gap-3 patient-select'>
            <label className='d-flex align-items-center fw-bold fs-5'>Select Appointment :</label>
            <Select
              placeholder='Appointmemt'
              className='w-250px'
              options={appointmentList}
              onChange={handleAppointmentChange}
              value={selectedAppointment}
              defaultValue={selectedAppointment}
              styles={customStyles}
            />
          </div>
        </div>

        <Tabs defaultActiveKey='patient-medication' transition={false}>
          <Tab eventKey='patient-medication' title='Medication'>
            <OpMedication selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey='patient-medical-notes' title='Medical Notes'>
            <OpMedicalNotes selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey='patient-vitals' title='Vitals'>
            <OpVitals selectedAppointment={selectedAppointment} />
          </Tab>
          <Tab eventKey='patient-medical-background' title='Medical Background'>
            <OpMedicalBackground />
          </Tab>
          <Tab eventKey='patient-attachment' title='Attachment'>
            <OpAttachments selectedAppointment={selectedAppointment} />
          </Tab>
        </Tabs>
      </div>
    </OpLayout>
  )
}

export default OpPatientDetails
