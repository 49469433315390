import {useContext, useState} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {MedicineMasterLoading} from '../loading/MedicineMasterLoading'
import {MedicineUploadDoc} from '../../../Medicine-Doc/MedicineUploadDoc'
import {useIntl} from 'react-intl'

const MedicineMasterListToolbar = () => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const [isLoading, setIsLoading] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const openUploadModal = () => {
    setShowUploadModal(true)
  }

  const handleCloseModal = () => {
    setShowUploadModal(false)
  }

  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const {loginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-medicine-master') {
      return item?.module_id
    }
  })

  const method = data?.filter((item: any) => {
    if (item.permission.method === 'POST' && item.permission.route === '') {
      return item
    }
  })

  const handleReportDownload = async () => {
    try {
      const url =
        'https://wappnet-systems.s3.amazonaws.com/public/jmh/medicine/sample/1688732664796_medicineSampleFile.csv'

      const link = document.createElement('a')
      link.href = url
      link.download = 'medicine_report.csv'

      link.click()

      URL.revokeObjectURL(url)
      link.remove()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='d-flex justify-content-end gap-3' data-kt-user-table-toolbar='base'>
      {method.length ? (
        <div
          className='d-flex flex-center bg-light-primary mt-1 border border-1 border-primary rounded-3 w-35px h-35px  fs-7 fw-bold text-primary cursor-pointer'
          onClick={openUploadModal}
        >
          <i className='fa-solid fa-upload text-primary fs-2' title='Import File'></i>
        </div>
      ) : (
        ''
      )}
      <div
        className='d-flex flex-center bg-light-primary mt-1 border border-1 border-primary rounded-3 w-35px h-35px  fs-7 fw-bold text-primary cursor-pointer'
        onClick={handleReportDownload}
      >
        <i className='fa-solid fa-download text-primary fs-2' title='Download Sample File'></i>
      </div>
      {method.length ? (
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'MEDICINE_MANAGEMENT.ADD.MEDICINE'})}
        </button>
      ) : (
        ''
      )}
      {isLoading && <MedicineMasterLoading />}
      {showUploadModal && <MedicineUploadDoc isLoading={false} onClose={handleCloseModal} />}
    </div>
  )
}

export {MedicineMasterListToolbar}
