import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {useRef, useState} from 'react'
import PatientRegistrationModal from './modals/PatientRegistrationModal'
import PatientVerifyOtpModal from './modals/PatientVerifyOtpModal'
import PatientLoginModal from './modals/PatientLoginModal'
import PatientForgotPasswordModal from './modals/PatientForgotPasswordModal'
import PatientChangePasswordModal from './modals/PatientChangePasswordModal'
import {useIntl} from 'react-intl'

type RegistrationRef = {
  resetForm: () => void
}

const LandingCallToAction = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false)
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const [showPatientLoginModal, setShowPatientLoginModal] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const registrationFormRef = useRef<RegistrationRef | null>(null)
  const [emailValue, setEmailValue] = useState('')
  const intl = useIntl()

  const handleAppointmentClick = () => {
    if (currentUser) {
      navigate('/patient-booking')
    } else {
      setShowPatientLoginModal(true)
    }
  }

  const handleRegistrationSuccess = (email: string) => {
    setEmailValue(email)
    setShowRegistrationModal(false)
    setShowVerifyOtpModal(true)
  }

  const closeVerifyOtpModal = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.resetForm()
    }
    setShowVerifyOtpModal(false)
  }

  const closeRegistrationModal = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.resetForm()
    }
    setShowRegistrationModal(false)
  }

  const handleLoginLinkClick = () => {
    setShowRegistrationModal(false)
    setShowPatientLoginModal(true)
  }

  const handleRegisterLinkClick = () => {
    setShowPatientLoginModal(false)
    setShowRegistrationModal(true)
  }

  const handleForgotPasswordLinkClick = () => {
    setShowPatientLoginModal(false)
    setShowForgotPasswordModal(true)
  }

  const handlePasswordChangeModal = () => {
    setShowPatientLoginModal(false)
    setShowChangePasswordModal(true)
  }

  return (
    <section className='call-to-action section-padding'>
      <div className='landing_container'>
        <div className='landing_action_card'>
          <div className='row align-items-center mb-0'>
            <div className='col-md-8'>
              <h4>{intl.formatMessage({id: 'GENERAL.STARTED'})}</h4>
              <p>{intl.formatMessage({id: 'LANDING_PAGE.NO_WAITING_ROOM'})}</p>
            </div>
            <div className='col-md-4'>
              <div className='d-flex align-items-center justify-content-end'>
                <button className='landing_appointment_btn' onClick={handleAppointmentClick}>
                  {intl.formatMessage({id: 'GENERAL.CREATED.APPOINTMENT'})}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showRegistrationModal && (
        <PatientRegistrationModal
          show={showRegistrationModal}
          onClose={closeRegistrationModal}
          onSuccess={handleRegistrationSuccess}
          onLoginClick={handleLoginLinkClick}
          registrationFormRef={registrationFormRef}
        />
      )}

      {showVerifyOtpModal && (
        <PatientVerifyOtpModal
          show={showVerifyOtpModal}
          onClose={closeVerifyOtpModal}
          emailValue={emailValue}
        />
      )}

      {showPatientLoginModal && (
        <PatientLoginModal
          show={showPatientLoginModal}
          onClose={() => setShowPatientLoginModal(false)}
          onRegisterClick={handleRegisterLinkClick}
          onForgotPasswordClick={handleForgotPasswordLinkClick}
          onPasswordChangeClick={handlePasswordChangeModal}
        />
      )}

      {showChangePasswordModal && (
        <PatientChangePasswordModal
          show={showChangePasswordModal}
          onClose={() => setShowChangePasswordModal(false)}
          onSubmit={() => {
            setShowChangePasswordModal(false)
          }}
        />
      )}

      {showForgotPasswordModal && (
        <PatientForgotPasswordModal
          show={showForgotPasswordModal}
          onClose={() => setShowForgotPasswordModal(false)}
        />
      )}
    </section>
  )
}

export default LandingCallToAction
