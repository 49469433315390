import {FC, useContext, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Doctor, initialUser} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createDoctor, getRoles, getSpeciality, updateDoctor} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {DoctorsListLoading} from '../components/loading/DoctorsListLoading'
import {toast} from 'react-toastify'
import Select from 'react-select'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import ReactSignatureCanvas from 'react-signature-canvas'
import {RxCross2} from 'react-icons/rx'

type Props = {
  isUserLoading: boolean
  doctor: Doctor
}

const DoctorEditModalForm: FC<Props> = ({doctor, isUserLoading}) => {
  const intl = useIntl()
  const [speciality, setSpeciality] = useState([])
  const [roleData, setRoleData] = useState([])
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [preview, setPreview] = useState('')
  const {refetch} = useQueryResponse()
  const [dob, setDob] = useState<Date | null>(null)
  const {selectedHospitalId} = useContext(loginContext)
  const [specialityData, setSpecialityData] = useState([])
  const [specialityError, setSpecialityError] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSignatureSaved, setIsSignatureSaved] = useState(false)
  const signatureRef = useRef<ReactSignatureCanvas | null>(null)

  const [userForEdit] = useState<Doctor>({
    ...doctor,
    full_name: doctor.full_name || initialUser.full_name,
    email: doctor.user?.email || initialUser.user?.email,
    date_of_birth: doctor.date_of_birth || initialUser.date_of_birth,
    permanent_address: doctor.permanent_address || initialUser.permanent_address,
    contact_number: doctor.contact_number || initialUser.contact_number,
    qualification: doctor.qualification || initialUser.qualification,
    gender: doctor.gender || initialUser.gender,
    years_of_experience: doctor.years_of_experience || initialUser.years_of_experience,
    speciality_ids: doctor.speciality_ids || initialUser.speciality_ids,
    role_id: doctor?.role_id || initialUser.role_id,
    hospital_id: doctor?.hospital_id || initialUser.hospital_id,
    image: doctor.image || initialUser.image,
    signature: doctor.signature || initialUser.signature,
    visiting_doctor: doctor.visiting_doctor || initialUser.visiting_doctor,
    status: doctor.status || initialUser.status,
    new_case_fee: doctor.new_case_fee || initialUser.new_case_fee,
    old_case_fee: doctor.old_case_fee || initialUser.old_case_fee,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    ;(async () => {
      const specialityData: any = await getSpeciality()

      const filterSpeciality = specialityData?.data?.filter(
        (item: any) => item?.hospital_id?.id == selectedHospitalId
      )
      setSpeciality(filterSpeciality)

      setSpecialityData(
        filterSpeciality?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
            id: item?.id,
          }
        })
      )

      const rolesData: any = await getRoles()

      const filterRoles = rolesData?.data?.filter(
        (item: any) => item?.hospital_id?.id == selectedHospitalId
      )

      setRoleData(filterRoles)
    })()
  }, [])

  useEffect(() => {
    if (initialUser.image) {
      setPreview(initialUser.image)
    }
  }, [initialUser])

  let editUserSchema: any
  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      full_name: Yup.string()
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        })
        .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
        .required(intl.formatMessage({id: 'VALIDATION.REQ.FULL.NAME'}))
        .matches(/^[A-Za-z\s]+$/, intl.formatMessage({id: 'VALIDATION.TEXT_INPUT'})),
      contact_number: Yup.string()
        .matches(/^\d{10}$/, intl.formatMessage({id: 'VALIDATION.CONTACT.NUMBER'}))
        .required(intl.formatMessage({id: 'VALIDATION.REQ.CONTACT.NUMBER'})),
      years_of_experience: Yup.string()
        .matches(/^\d{1,2}(\.\d+)?$/, intl.formatMessage({id: 'VALIDATION.ONE.TO.TWO.DIGITS'}))
        .required(intl.formatMessage({id: 'VALIDATION.ONLY.NUMBERS.ALLOWED'})),
      new_case_fee: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.ONLY.NUMBERS.ALLOWED'})
      ),
      old_case_fee: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.ONLY.NUMBERS.ALLOWED'})
      ),
      permanent_address: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
      qualification: Yup.string()
        .required('Qualification is required')
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        }),
      status: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.STATUS'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.REQ.STATUS'})),
    })
  } else {
    editUserSchema = Yup.object().shape({
      email: Yup.string()
        .email(intl.formatMessage({id: 'VALIDATION.EMAIL.ERROR'}))
        .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
        .required(intl.formatMessage({id: 'VALIDATION.REQ.EMAIL'})),
      full_name: Yup.string()
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        })
        .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
        .required(intl.formatMessage({id: 'VALIDATION.REQ.FULL.NAME'}))
        .matches(/^[A-Za-z\s]+$/, intl.formatMessage({id: 'VALIDATION.TEXT_INPUT'})),
      contact_number: Yup.string()
        .matches(/^\d{10}$/, intl.formatMessage({id: 'VALIDATION.CONTACT.NUMBER'}))
        .required(intl.formatMessage({id: 'VALIDATION.REQ.CONTACT.NUMBER'})),
      permanent_address: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
      qualification: Yup.string()
        .required('Qualification is required')
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        }),

      years_of_experience: Yup.string()
        .matches(/^\d{1,2}(\.\d+)?$/, intl.formatMessage({id: 'VALIDATION.ONE.TO.TWO.DIGITS'}))
        .required(intl.formatMessage({id: 'VALIDATION.ONLY.NUMBERS.ALLOWED'})),
      new_case_fee: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.ONLY.NUMBERS.ALLOWED'})
      ),
      old_case_fee: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.ONLY.NUMBERS.ALLOWED'})
      ),
      date_of_birth: Yup.date()
        .nullable()
        .test(
          'dob',
          intl.formatMessage({id: 'VALIDATION.DOB.GREATER_22'}),
          function (value: any, ctx) {
            const dob = new Date(value)
            const validDate = new Date()
            const valid = validDate.getFullYear() - dob.getFullYear() >= 22
            return !valid ? ctx.createError() : valid
          }
        )
        .required(intl.formatMessage({id: 'VALIDATION.DATE'})),

      role_id: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.ROLE'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.REQ.ROLE'})),
      status: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.STATUS'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.REQ.STATUS'})),
    })
  }

  const formik = useFormik({
    initialValues: {
      ...userForEdit, // Include the signature field from userForEdit
      // signature: userForEdit.signature || null, // Ensure signature is not undefined
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      if (values?.speciality_ids === undefined || values?.speciality_ids?.length === 0) {
        setSpecialityError('Speciality is required')
        return false
      }
      try {
        if (isNotEmpty(values.id)) {
          delete values?.speciality
          delete values.role_id
          delete values.email
          delete values.hospital_id
          delete values.user

          const formData = new FormData()
          formData.append('full_name', values?.full_name as string)
          formData.append(
            'date_of_birth',
            (values['date_of_birth'] = moment(values['date_of_birth'], 'DD-MM-YYYY').format(
              'DD-MM-YYYY'
            ) as any)
          )
          formData.append('permanent_address', values?.permanent_address as string)
          formData.append('contact_number', values?.contact_number as string)
          formData.append('qualification', values?.qualification as string)
          formData.append('gender', values?.gender as string)
          formData.append('visiting_doctor', values['visiting_doctor'] as any)
          formData.append('years_of_experience', values?.years_of_experience as any)
          formData.append('new_case_fee', values?.new_case_fee as any)
          formData.append('old_case_fee', values?.old_case_fee as any)
          formData.append('image', values?.image as Blob)
          formData.append('signature', values?.signature as Blob)
          formData.append('status', values?.status as any)
          formData.append('speciality_ids', values?.speciality_ids as any)

          await updateDoctor(formData, itemIdForUpdate).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          const formData = new FormData()
          formData.append('full_name', values?.full_name as string)
          formData.append('email', values?.email as string)
          formData.append(
            'date_of_birth',
            (values['date_of_birth'] = moment(values['date_of_birth'], 'DD-MM-YYYY').format(
              'DD-MM-YYYY'
            ) as any)
          )
          formData.append('permanent_address', values?.permanent_address as string)
          formData.append('contact_number', values?.contact_number as string)
          formData.append('qualification', values?.qualification as string)
          formData.append('gender', values?.gender as string)
          formData.append('visiting_doctor', values['visiting_doctor'] as any)
          formData.append('years_of_experience', values?.years_of_experience as any)
          formData.append('new_case_fee', values?.new_case_fee as any)
          formData.append('old_case_fee', values?.old_case_fee as any)
          formData.append('speciality_ids', values?.speciality_ids as any)
          formData.append('role_id', Number(values?.role_id) as any)
          formData.append('image', values?.image as Blob)
          formData.append('status', values?.status as any)
          formData?.append('signature', values?.signature as Blob)
          formData.append('hospital_id', Number(selectedHospitalId) as any)
          Number(values?.years_of_experience)

          await createDoctor(formData).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        toast.error(ex.response.data.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleImageChange = (event: any, formik: any) => {
    const file = event.currentTarget.files[0]
    if (file) {
      if (file.name === preview?.split('/').pop()) {
        formik.setFieldValue('image', file)
      } else {
        formik.setFieldValue('image', file)
        setPreview(URL.createObjectURL(file))
      }
    }
  }

  const handleRemoveImage = () => {
    setPreview('')
    formik.setFieldValue('image', '')
  }

  const handleSpecialityBlur = () => {
    if (
      formik?.values?.speciality_ids === undefined ||
      formik?.values?.speciality_ids.length === 0
    ) {
      setSpecialityError('Speciality is required')
    } else {
      setSpecialityError('')
    }
  }

  const maxDate = new Date(moment().subtract(22, 'years').format('YYYY-MM-DD'))

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  // const handleSignatureChange = (event: any, formik: any) => {
  //   const file = event.currentTarget.files[0]
  //   if (file) {
  //     formik.setFieldValue('signature', file)
  //   }
  // }

  // signature

  const handleOpen = (event: any) => {
    setIsModalOpen(true)
    event.preventDefault()
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }
  const handleCloseImage = () => {
    setIsSignatureSaved(false)
  }

  const handleClearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear()
    }
  }

  const handleSignatureChange = () => {
    if (signatureRef.current) {
      const signatureImage = signatureRef.current.toDataURL('image/png')

      fetch(signatureImage)
        .then((res) => res.blob())
        .then((blob) => {
          formik.setFieldValue('signature', blob)
          setIsSignatureSaved(true)
          handleClose()
        })
        .catch((error) => {
          console.error('Failed to convert data URL to Blob:', error)
        })
    }
  }

  return (
    <>
      <form
        id='kt_modal_add_user_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
        encType='multipart/form-data'
      >
        <div className='d-flex flex-column me-n7 pe-7' id='kt_modal_add_user_scroll'>
          {/* <div className='fv-row mb-7'>
            <div className='doctor-file-upload'>
              <input type='hidden' name='image' value={initialUser.image} />
              Photo Upload
              <input
                type='file'
                id='image'
                name='image'
                onChange={(event) => handleImageChange(event, formik)}
                className='hidden cursor-pointer'
              />
            </div>
            {preview || doctor.image ? (
              <div className='image-input-wrapper w-125px h-125px'>
                <img
                  className='w-125px h-125px'
                  src={preview || doctor?.image}
                  alt='Selected Image'
                />
              </div>
            ) : (
              ''
            )}
          </div> */}

          <div className='fv-row mb-7'>
            <label className='d-block fw-bold fs-6 mb-5'>
              {intl.formatMessage({id: 'GENERAL.PROFILE'})}
            </label>

            <div className='image-input image-input-outline' data-kt-image-input='true'>
              {preview || doctor.image ? (
                <div
                  className='image-input-wrapper w-100px h-100px'
                  style={{backgroundImage: `url('${preview || doctor.image}')`}}
                ></div>
              ) : (
                <div className='image-input-wrapper w-100px h-100px'></div>
              )}

              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({id: 'GENERAL.CHANGE.AVATAR'})}
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input
                  key={preview}
                  type='file'
                  name='image'
                  id='image'
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => handleImageChange(event, formik)}
                />
                <input type='hidden' name='image' value={initialUser.image} />
              </label>

              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({id: 'GENERAL.CANCEL.AVATAR'})}
              >
                <i className='bi bi-x fs-2'></i>
              </span>

              {preview ? (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='remove'
                  data-bs-toggle='tooltip'
                  title={intl.formatMessage({id: 'GENERAL.REMOVE.AVATAR'})}
                  onClick={handleRemoveImage}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              ) : (
                ''
              )}
            </div>

            <div className='form-text'>
              {intl.formatMessage({id: 'GENERAL.ALLOWED.FILE.TYPES'})}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
                {...formik.getFieldProps('full_name')}
                type='text'
                name='full_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.full_name && formik.errors.full_name},
                  {
                    'is-valid': formik.touched.full_name && !formik.errors.full_name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.full_name && formik.errors.full_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.full_name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.EMAIL'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.EMAIL'})}
                {...formik.getFieldProps('email')}
                className={clsx(
                  `form-control ${itemIdForUpdate ? '' : 'form-control-solid'} mb-3 mb-lg-0`,
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading || itemIdForUpdate ? true : false}
              />

              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.DOB'})}
              </label>

              <div className='position-relative'>
                <ReactDatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('date_of_birth')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy'
                  name='date_of_birth'
                  maxDate={maxDate}
                  selected={dob}
                  onChange={(date: any) => {
                    formik.setFieldValue('date_of_birth', date)
                    setDob(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  placeholderText={intl.formatMessage({id: 'GENERAL.DOB'})}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('date_of_birth')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>
            </div>
            <div className='mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-5'>
                {intl.formatMessage({id: 'GENERAL.GENDER'})}
              </label>

              <div className='d-flex gap-4'>
                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='MALE'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.gender === 'MALE'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.MALE'})}
                      </div>
                    </label>
                  </div>
                </div>

                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='FEMALE'
                      id='kt_modal_update_role_option_1'
                      checked={formik.values.gender === 'FEMALE'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.FEMALE'})}
                      </div>
                    </label>
                  </div>
                </div>

                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='OTHER'
                      id='kt_modal_update_role_option_2'
                      checked={formik.values.gender === 'OTHER'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.OTHER'})}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
            </label>

            <input
              placeholder={intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
              {...formik.getFieldProps('permanent_address')}
              type='text'
              name='permanent_address'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.permanent_address && formik.errors.permanent_address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.permanent_address}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row align-items-center'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                {...formik.getFieldProps('contact_number')}
                type='number'
                name='contact_number'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.contact_number && formik.errors.contact_number},
                  {
                    'is-valid': formik.touched.contact_number && !formik.errors.contact_number,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault()
                  }
                }}
              />
              {formik.touched.contact_number && formik.errors.contact_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.contact_number}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row col-12 col-sm-6'>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  {...formik.getFieldProps('visiting_doctor')}
                  className='form-check-input'
                  type='checkbox'
                  name='visiting_doctor'
                  id='flexSwitchDefault'
                  checked={formik.values.visiting_doctor}
                  onChange={formik.handleChange}
                />
                <label className='form-check-label fw-bold fs-6' htmlFor='flexSwitchDefault'>
                  {intl.formatMessage({id: 'DOCTOR.VISITING.DOCTOR'})}
                </label>
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'DOCTOR.QUALIFICATION'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'DOCTOR.QUALIFICATION'})}
                {...formik.getFieldProps('qualification')}
                type='text'
                name='qualification'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.qualification && formik.errors.qualification && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.qualification}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'DOCTOR.YEARS.OF.EXPERIENCE'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'DOCTOR.YEARS.OF.EXPERIENCE'})}
                {...formik.getFieldProps('years_of_experience')}
                type='number'
                name='years_of_experience'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.years_of_experience && formik.errors.years_of_experience,
                  },
                  {
                    'is-valid':
                      formik.touched.years_of_experience && !formik.errors.years_of_experience,
                  }
                )}
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault()
                  }
                }}
              />
              {formik.touched.years_of_experience && formik.errors.years_of_experience && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.years_of_experience}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'DOCTOR.OLD_CASE.FEE'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'PLACEHOLDER.ENTER.CASE_FEE'})}
                {...formik.getFieldProps('old_case_fee')}
                type='number'
                name='old_case_fee'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.old_case_fee && formik.errors.old_case_fee,
                  },
                  {
                    'is-valid': formik.touched.old_case_fee && !formik.errors.old_case_fee,
                  }
                )}
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault()
                  }
                }}
              />
              {formik.touched.old_case_fee && formik.errors.old_case_fee && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.old_case_fee}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6 '>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'DOCTOR.NEW_CASE.FEE'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'PLACEHOLDER.ENTER.CASE_FEE'})}
                {...formik.getFieldProps('new_case_fee')}
                type='number'
                name='new_case_fee'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.new_case_fee && formik.errors.new_case_fee && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.new_case_fee}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'STAFF.SPECIALITY'})}
              </label>
              {/* <select
                className='form-select select_arrow_remove'
                aria-label='Select example'
                {...formik.getFieldProps('speciality_id')}
                disabled={itemIdForUpdate ? true : false}
                name='speciality_id'
              >
                <option value=''>{intl.formatMessage({id: 'STAFF.SELECT.SPECIALITY'})}</option>
                {speciality &&
                  speciality?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    )
                  })}
              </select> */}
              <Select
                isMulti
                id='speciality_ids'
                name='speciality_ids'
                options={specialityData}
                value={specialityData.filter((option: any) =>
                  formik?.values?.speciality_ids?.includes(option.id)
                )}
                styles={customStyles}
                onChange={(selectedOptions) => {
                  const selectedSpecialityIds = selectedOptions.map((option: any) => option?.id)
                  formik.setFieldValue('speciality_ids', selectedSpecialityIds)
                  if (selectedSpecialityIds.length > 0) {
                    setSpecialityError('')
                  } else {
                    setSpecialityError('Speciality is required')
                  }
                }}
                onBlur={handleSpecialityBlur}
                className={`basic-multi-select select_arrow_remove`}
                classNamePrefix='select'
                placeholder={intl.formatMessage({id: 'STAFF.SELECT.SPECIALITY'})}
                // isDisabled={itemIdForUpdate ? true : false}
              />

              {specialityError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{specialityError}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.ROLE'})}
              </label>
              <select
                className='form-select select_arrow_remove'
                aria-label='Select example'
                {...formik.getFieldProps('user.role.id')}
                disabled={itemIdForUpdate ? true : false}
                name='role_id'
              >
                <option value=''>{intl.formatMessage({id: 'STAFF.SELECT.ROLE'})}</option>
                {roleData &&
                  roleData?.map((item: any) => {
                    return (
                      <>
                        <option value={item?.id}>{item?.name}</option>
                      </>
                    )
                  })}
              </select>

              {formik.touched.role_id && formik.errors.role_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.role_id}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.STATUS'})}
              </label>
              <select
                className='form-select'
                aria-label='Select example'
                {...formik.getFieldProps('status')}
              >
                <option value=''>{intl.formatMessage({id: 'GENERAL.SELECT.STATUS'})}</option>
                <option value='ACTIVE'>{intl.formatMessage({id: 'GENERAL.ACTIVE.STATUS'})}</option>
                <option value='INACTIVE'>
                  {intl.formatMessage({id: 'GENERAL.IN.ACTIVE.STATUS'})}
                </option>
              </select>

              {formik.touched.status && formik.errors.status && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6 '>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.DIGITAL.SIGNATURE'})}
              </label>
              <div className='d-flex gap-2 flex-column '>
                <div className=''>
                  <button
                    className='modal-button py-3'
                    onClick={handleOpen}
                    name='signature'
                    id='signature'
                  >
                    {intl.formatMessage({id: 'GENERAL.ADD_SIGNATURE'})}
                  </button>
                  <Modal show={isModalOpen} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>{intl.formatMessage({id: 'GENERAL.ADD_SIGNATURE'})}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ReactSignatureCanvas
                        ref={signatureRef}
                        canvasProps={{width: 400, height: 200, className: 'sigCanvas'}}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <div className='button-container'>
                        <button className='modal-button clear' onClick={handleClearSignature}>
                          {intl.formatMessage({id: 'GENERAL.CLEAR_SIGNATURE'})}
                        </button>
                        <button className='modal-button save' onClick={handleSignatureChange}>
                          {intl.formatMessage({id: 'GENERAL.SAVE_SIGNATURE'})}
                        </button>
                        <button className='modal-button close' onClick={handleClose}>
                          {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>

                {isSignatureSaved && signatureRef.current && (
                  <div className='modal-content2 m-0'>
                    <div style={{position: 'relative'}}>
                      <img
                        src={signatureRef.current.getTrimmedCanvas().toDataURL('image/png')}
                        alt='Signature'
                      />
                      <RxCross2
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          margin: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleCloseImage()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty ||
              specialityError.length > 0
            }
          >
            <span className='indicator-label'> {intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <DoctorsListLoading />}
    </>
  )
}

export {DoctorEditModalForm}
