/* eslint-disable jsx-a11y/img-redundant-alt */
import {toAbsoluteUrl} from '../../../_jmh/helpers'
import moment from 'moment'

const DpHeader = ({selectedPatientData}: any) => {
  return (
    <div className='dp-header row px-2 px-sm-3 py-2 my-3 rounded'>
      <div className='dp-user-profile d-flex align-items-center justify-content-sm-start gap-3 gap-sm-5 col-6'>
        <img
          src={
            selectedPatientData?.patient?.image ||
            toAbsoluteUrl('/media/landing_asset/user_placeholder.png')
          }
          alt='Patient Image'
        />
        <h4 className='m-0'>{selectedPatientData?.patient?.full_name || 'User Name'}</h4>
      </div>
      <div className='d-flex align-items-center justify-content-end col-6'>
        <div className='consultation-date-box'>
          <h4 className='m-0'>
            1st consultation{' '}
            {moment(selectedPatientData?.appointment_details?.[0]?.appointment_date).format(
              'DD MMM YYYY'
            )}
          </h4>
        </div>
      </div>
    </div>
  )
}

export default DpHeader
