/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useEffect, useState} from 'react'
import {getAppointmentById} from './core/_request'
import {loginContext} from '../../context/context'
import ReactPaginate from 'react-paginate'
import {Table} from 'react-bootstrap'
import moment from 'moment'
import {KTSVG} from '../../../_jmh/helpers'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import OpLayout from './OpLayout'

const OpPatientAppointmentList = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [patientData, setPatientData] = useState<any>(null)
  const {loginData} = useContext(loginContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const limit = 10
  const pageCount = Math.max(Math.ceil(totalCount / limit), 1)

  const handleBookAppointment = () => {
    navigate('/patient-booking')
  }

  const openEditModal = (e: any, item: any) => {
    e.preventDefault()
    navigate('/patient-booking', {
      state: {
        patientSelectedHospitalId: loginData?.data?.hospital_id.toString(),
        appoint_id: item?.id,
        doctorId: item?.appointment_details?.[0]?.doctor?.id,
        appoint_date: item?.appointment_details?.[0]?.appointment_date,
      },
    })
  }

  const handlePageClick = (selectedPage: any) => {
    setCurrentPage(selectedPage?.selected + 1)
  }

  const fetchPatientData = () => {
    getAppointmentById(loginData?.data?.patient_id, currentPage, searchTerm).then((res: any) => {
      const sortedData = res?.data.sort((a: any, b: any) => {
        const dateTimeA: any = moment(
          `${a.appointment_details[0]?.appointment_date} ${a.appointment_details[0]?.slot}`,
          'YYYY-MM-DD HH:mm'
        )
        const dateTimeB: any = moment(
          `${b.appointment_details[0]?.appointment_date} ${b.appointment_details[0]?.slot}`,
          'YYYY-MM-DD HH:mm'
        )
        return dateTimeB - dateTimeA
      })
      setPatientData(sortedData)
      setTotalCount(res?.pagination?.meta?.totalItems)
    })
  }

  useEffect(() => {
    fetchPatientData()
  }, [currentPage, searchTerm])

  return (
    <OpLayout>
      <div className='op_inner_wrapper p-5'>
        <div className='d-flex justify-content-between align-items-center mb-25'>
          <h2 className='op_head_title m-0'>Your Appointments</h2>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='search'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button type='button' className='btn btn-primary' onClick={handleBookAppointment}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {/* {intl.formatMessage({id: 'PATIENT_APPOINTMENT.ADD.APPOINTMENT'})} */}
            Book Appointment
          </button>
        </div>
        <Table striped className='table-striped'>
          <thead>
            <tr>
              <th>Doctor Name</th>
              <th>Hospital Name</th>
              <th>Date</th>
              <th>Slot time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {patientData && patientData.length > 0 ? (
              patientData.map((item: any) => {
                const sortedAppointments = item?.appointment_details.sort((a: any, b: any) => {
                  const timeA = moment(a.slot, 'HH:mm')
                  const timeB = moment(b.slot, 'HH:mm')

                  return timeA.diff(timeB)
                })
                return (
                  <tr key={item.id}>
                    <td className='text-capitalize'>
                      {item?.appointment_details[0]?.doctor?.full_name}
                    </td>
                    <td>{item?.hospital?.name}</td>
                    <td>{item?.appointment_details[0]?.appointment_date}</td>
                    <td>
                      {item?.appointment_details.length > 0 && (
                        <>
                          {moment(
                            sortedAppointments[0]?.slot === null
                              ? moment().format('HH:mm')
                              : sortedAppointments[0]?.slot,
                            'HH:mm'
                          ).format('HH:mm')}{' '}
                          -
                          {moment(
                            sortedAppointments[item.appointment_details.length - 1]?.slot === null
                              ? moment().add(15, 'minutes').format('HH:mm')
                              : sortedAppointments[item.appointment_details.length - 1]?.slot,
                            'HH:mm'
                          )
                            .add(15, 'minutes')
                            .format('HH:mm')}
                        </>
                      )}
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex flex-column'>
                          {item.status === 'BOOKED' && (
                            <span className='badge badge-light-success'>{item?.status}</span>
                          )}
                          {item.status === 'RESCHEDULED' && (
                            <span className='badge badge-light-warning'>{item?.status}</span>
                          )}
                          {item.status === 'COMPLETED' && (
                            <span className='badge badge-light-primary'>{item?.status}</span>
                          )}
                          {item.status === 'CANCELLED' && (
                            <span className='badge badge-light-danger'>{item?.status}</span>
                          )}
                          {item.status === 'ACTIVE' && (
                            <span className='badge badge-light-success'>{item?.status}</span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      {/* <a
                    href='#'
                    className='btn btn-light btn-active-light-primary btn-sm px-4 me-4 justify-content-end'
                    onClick={(e) => openEditModal(e, item)}
                  >
                    <span>
                      <i className='bi bi-pencil-square fs-2'></i>
                    </span>
                  </a> */}

                      <a
                        href='#'
                        className={`btn btn-light btn-active-light-primary btn-sm px-4 me-4 justify-content-end ${
                          moment(
                            `${item?.appointment_details[0]?.appointment_date} ${item?.appointment_details[0]?.slot}`,
                            'YYYY-MM-DD HH:mm'
                          )
                            .add(45, 'minutes') // Add 45 minutes to the appointment time
                            .isBefore(moment())
                            ? 'disabled'
                            : ''
                        }`}
                        onClick={(e) =>
                          !moment(
                            `${item?.appointment_details[0]?.appointment_date} ${item?.appointment_details[0]?.slot}`,
                            'YYYY-MM-DD HH:mm'
                          )
                            .add(45, 'minutes') // Add 45 minutes to the appointment time
                            .isBefore(moment()) && openEditModal(e, item)
                        }
                      >
                        <span>
                          <i className='bi bi-pencil-square fs-2'></i>
                        </span>
                      </a>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className='d-flex text-center fs-5 text-dark fw-bold w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {patientData?.length >= 1 && (
          <nav className='op_pagination'>
            <ReactPaginate
              breakLabel='...'
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName='pagination'
              activeClassName='active'
              previousLabel='Previous'
              nextLabel='Next'
            />
          </nav>
        )}
      </div>
    </OpLayout>
  )
}

export default OpPatientAppointmentList
