import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PurchaseInvListHeader} from './components/header/PurchaseInvListHeader'
import {PurchaseInvTable} from './table/PurchaseInvTable'
import {PurchaseInvEditModal} from './PurchaseInv-edit-modal/PurchaseInvEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const PurchaseInvList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <PurchaseInvListHeader />
        <PurchaseInvTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <PurchaseInvEditModal />}
    </>
  )
}

const PurchaseInvListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PurchaseInvList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PurchaseInvListWrapper}
