import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_HOSPITAL =  `${API_URL}/hospital`
const GET_SPECIALITY_URL = `${API_URL}/speciality`
const GET_AVAILABLE_DOCTOR_URL = `${API_URL}/doctor/available-doctor`
const GET_LANDING_ABOUT_URL = `${API_URL}/cms/about`
const GET_LANDING_FACILITY_URL = `${API_URL}/cms/facility`
const GET_LANDING_SPECIALITY_URL = `${API_URL}/cms/speciality`
const GET_REVIEW_URL = `${API_URL}/review`
const GET_PATIENT_APPOINTMENT_URL = `${API_URL}/patient-appointment/patients`


export const getHospitals = () =>{
  return axios.get(GET_HOSPITAL,{
  })
}

export const getSpeciality = () => {
  return axios
    .get(`${GET_SPECIALITY_URL}`)
}

export const getDoctors = (query?:string) => {
  return axios
    .get(`${GET_AVAILABLE_DOCTOR_URL}?search=${query ? query : ''}`)
}

export const getLandingAboutData = () =>{
  return axios.get(GET_LANDING_ABOUT_URL,{
  })
}

export const getLandingFacilityData = () =>{
  return axios.get(GET_LANDING_FACILITY_URL,{
  })
}
export const getLandingSpecialityData = () =>{
  return axios.get(GET_LANDING_SPECIALITY_URL,{
  })
}

export const getLandingreviewData = () =>{
  return axios.get(GET_REVIEW_URL,{
  })
}

export const getPatientAppointmentData = (token:string,id:number) =>{
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.get( `${GET_PATIENT_APPOINTMENT_URL}/${id}`,{headers})
 
}  


  

  