import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {AtdsListWrapper} from './ATDS-list/AtdsList'

const AtdPage = () => (
  <>
    <div className=''>
      <AtdsListWrapper />
    </div>
  </>
)

const AtdWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PATIENT.ADT'})}</PageTitle>
      <AtdPage />
    </>
  )
}

export {AtdWrapper}
