import {KTCard} from '../../../../_jmh/helpers'
import {RolesListHeader} from './components/header/RoleslistHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {RoleEditModal} from './role-edit-modal/RoleEditModal'
import {RolesTable} from './table/RolesTable'

const Rolelist = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <RolesListHeader />
        <RolesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <RoleEditModal />}
    </>
  )
}

const RolesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <Rolelist />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RolesListWrapper}
