import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PayrollsListHeader} from './components/header/PayrollsListHeader'
import {UsersTable} from './table/PayrollsTable'
import {KTCard} from '../../../../_jmh/helpers'

const PayrollsList = () => {
  return (
    <>
      <KTCard className='shadow'>
        <PayrollsListHeader />
        <UsersTable />
      </KTCard>
    </>
  )
}

const PayrollsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PayrollsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PayrollsListWrapper}
