/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {completedUser, deleteUser} from '../../core/_requests'
import {toast} from 'react-toastify'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {loginContext} from '../../../../../context/context'
import {Appointment} from '../../core/_models'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import moment from 'moment'

type Props = {
  id: ID
  user: Appointment
}

const UserActionsCell: FC<Props> = ({id, user}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const pagination = useQueryResponsePagination()
  const [currentPage, setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const [isDelete, setIsDelete] = useState(false)
  const {loginData} = useContext(loginContext)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const page = pagination?.pagination?.meta?.currentPage || 1

  const handleRemove = (id: any) => {
    if (id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          title: `Are you sure you want to delete Patient Appointment ?`,
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Close',
          reverseButtons: true,
        })
        .then(async (result: any) => {
          if (result.isConfirmed) {
            try {
              await deleteUser(id)
              // Make the API call to delete the doctor's availability
              swalWithBootstrapButtons.fire(
                'Deleted!',
                `Patient Appointment has been deleted.`,
                'success'
              )
              setIsDelete((val) => !val)
              if (page > 1) {
                const updatedData = await queryClient.getQueryData<any>([
                  `${QUERIES.USERS_LIST}-${query}`,
                ])
                if (updatedData?.data?.length > 0) {
                  setCurrentPage(page)
                } else {
                  setCurrentPage(page - 1)
                  updateState({
                    page: page - 1,
                    items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
                  })
                }
              }
            } catch (ex: any) {
              if (ex.response.data.message) {
                toast.error(ex.response.data.message)
              }
            }
          }
        })
    }
  }

  const handleCompleted = async (id: any) => {
    if (id) {
      try {
        await completedUser(id).then((res: any) => {
          toast.success(res?.data?.message)
        })
        setIsDelete((val) => !val)
        // toast.success('Patient Appointment Completed')
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      }
    }
  }

  useEffect(() => {
    refetch()
  }, [refetch, isDelete])

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-appointment') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE') {
      return item
    }
  })

  return (
    <>
      <button
        className={
          user.status === 'COMPLETED'
            ? 'btn btn-secondary  btn-sm'
            : 'btn btn-secondary btn-active-light-primary btn-sm'
        }
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        disabled={user.status === 'COMPLETED' || user.status === 'CANCELLED'}
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </button>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {/* {edit.length ? (
          <div className='menu-item px-3'>
            <button
              className='menu-link px-3 fw-bold w-100 btn text-hover-primary btn-active-light-primary btn-sm'
              disabled={moment(
                `${user?.appointment_details?.[0]?.appointment_date} ${user?.appointment_details?.[0]?.slot}`,
                'YYYY-MM-DD HH:mm'
              )
                .add(45, 'minutes') // Add 45 minutes to the appointment time
                .isBefore(moment())}
              onClick={openEditModal}
            >
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </button>
          </div>
        ) : (
          ''
        )} */}

        {edit.length ? (
          <div className='menu-item px-3'>
            <button
              className='menu-link px-3 fw-bold w-100 btn text-hover-primary btn-active-light-primary btn-sm'
              onClick={openEditModal}
            >
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </button>
          </div>
        ) : (
          ''
        )}

        {/* end::Menu item */}

        {deleteData.length ? (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={() => handleCompleted(user?.id)}
            >
              {intl.formatMessage({id: 'GENERAL.COMPLETED'})}
            </a>
          </div>
        ) : (
          ''
        )}

        {/* begin::Menu item */}
        {deleteData.length ? (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={() => handleRemove(user?.id)}
            >
              {intl.formatMessage({id: 'GENERAL.DELETE'})}
            </a>
          </div>
        ) : (
          ''
        )}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}
