import { ID, Response } from "../../../../../_jmh/helpers"

export type Appointment = {
  id?: ID
  hospital_id?: number
  patient_id?:number
  doctor_id?:number
  date?: string
  slot?:any
  full_name?:string
  description?:string
  gender?: string
  contact_number?:string
  alternate_contact_number?:string
  date_of_birth?:string
  email?: string
  blood_group?: string
  status?:string
  type?:string
  initials?: {
    label: string
    state: string
  }
 patient?: { 
  full_name?: string
  gender?: string
  contact_number?: string
  alternate_contact_number?: string
  date_of_birth?: string
  permanent_address?: string
  blood_group?: string
  email?: string
  hospital_id?:number
  status?:string
 }
 appointment_details?: AppointmentDetail[];
 appointment_date?:any
}


interface AppointmentDetail {
  id: string;
  appointment_id: string;
  opd_id: string | null;
  doctor_id: string;
  appointment_date: string;
  slot: string | null;
  status: "BOOKED" | "CANCELLED" | "RESCHEDULED";
  created_at: string;
  updated_at: string;
  doctor:  {
    full_name?: string | undefined
    email?: string
    date_of_birth?: string
    gender?: string
    permanent_address?: string
    contact_number?: string
    visiting_doctor?: any
    qualification?: string | undefined
    years_of_experience?: number
    role_id?: number
    hospital_id?:number
    speciality_id?:number
    image?: any,
    status?:string
   };

}

export type UsersQueryResponse = Response<Array<Appointment>>

export const initialAppointment: Appointment = {
  date: undefined,
  slot:undefined,
  description:undefined,
}
