import React from 'react'
import {PatientForgotPassword} from '../../../modules/auth/components/PatientComponents/PatientForgotPassword'
import {useIntl} from 'react-intl'

type ForgotPasswordModalProps = {
  show: boolean
  onClose: () => void
}

const PatientForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({show, onClose}) => {
  const intl = useIntl()
  return (
    <>
      <div
        className={`modal fade${show ? ' show' : ''}`}
        onClick={onClose}
        style={{display: show ? 'block' : 'none'}}
      >
        <div className='modal-dialog'>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='modal-header'>
              <h2 className='modal-title' id='forgotPasswordModalLabel'>
                {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})}
              </h2>
              <button type='button' className='btn-close' onClick={onClose}></button>
            </div>
            <div className='modal-body'>
              <PatientForgotPassword />
            </div>
          </div>
        </div>
      </div>
      {show && <div className='modal-backdrop fade show'></div>}
    </>
  )
}

export default PatientForgotPasswordModal
