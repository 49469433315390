/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AssignInv} from '../../core/_models'

type Props = {
  user: AssignInv
}

const AssignInvInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>
        {typeof user?.inventory_id === 'object' ? user?.inventory_id?.name : ''}
      </p>
    </div>
  </div>
)

export {AssignInvInfoCell}
