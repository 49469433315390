import {OperationListToolbar} from './OperationListToolbar'
import {OperationsListSearchComponent} from './OperationsListSearchComponent'

const OperationsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6 justify-content-between'>
      <OperationsListSearchComponent />
      <div className='card-toolbar'>
        <OperationListToolbar />
      </div>
    </div>
  )
}

export {OperationsListHeader}
