import React, {useState} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {Link} from 'react-router-dom'
type Props = {
  to: string
  title?: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  subItems?: Array<{to: string; title: string}>
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  subItems,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleMenu = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div
      className={clsx('menu-item', {'here show': isExpanded}, 'menu-accordion')}
      // data-kt-menu-trigger='click'
    >
      <span className='menu-link' onClick={toggleMenu}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isExpanded})}>
        {children}
        {subItems && (
          <ul className='menu-sub-sub'>
            {subItems.map((subItem) => (
              <li key={subItem.to} className='menu-item'>
                <Link to={subItem.to} className='menu-link'>
                  <span className='menu-bullet'>
                    <span className='bullet bullet-dot'></span>
                  </span>
                  <span className='menu-title'>{subItem.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
