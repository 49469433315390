import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import clsx from 'clsx'
import {changePassword} from '../../modules/auth/core/_requests'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {toAbsoluteUrl} from '../../../_jmh/helpers'

interface ChangePasswordFormValues {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const initialValues: ChangePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const ChangePassword: React.FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShownewPassword] = useState(false)
  const [showconfirmPassword, setShowconfirmPassword] = useState(false)
  let navigate = useNavigate()
  const {setCurrentUser, currentUser} = useAuth()

  const handleOldPassword = () => {
    setShowOldPassword(!showOldPassword)
  }

  const handleNewPassword = () => {
    setShownewPassword(!showNewPassword)
  }

  const handleConfirmPassword = () => {
    setShowconfirmPassword(!showconfirmPassword)
  }

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQ.CURRENT.PASSWORD'})),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        intl.formatMessage({id: 'VALIDATION.NEW_PASSWORD'})
      )
      .required(intl.formatMessage({id: 'VALIDATION.REQ.NEW_PASSWORD'})),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({id: 'VALIDATION.PASSWORD_MATCHED'})
      )
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED.CONFIRM_PASSWORD'})),
  })

  const tokenForChangePassword: any = localStorage.getItem('kt-auth-react-v')
  const token = JSON.parse(tokenForChangePassword)

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        await changePassword(
          values.oldPassword,
          values.newPassword,
          values.confirmPassword,
          token?.access_token
        ).then((res: any) => {
          const getLocalKey: any = localStorage.getItem('kt-auth-react-v')
          const responseLocalSorage = JSON.parse(getLocalKey)

          responseLocalSorage.isPasswordChange = false
          localStorage.setItem('kt-auth-react-v', JSON.stringify(responseLocalSorage))

          setCurrentUser(responseLocalSorage?.access_token)
          toast.success(res?.data?.message)
          setLoading(false)
          setSubmitting(false)
          resetForm()
          navigate('/dashboard')
        })
      } catch (error: any) {
        if (error) {
          setStatus(error?.response?.data?.message)
        }

        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    const getLocalKey: any = localStorage.getItem('loginData')
    const responseLocalSorage = JSON.parse(getLocalKey)
    if (!responseLocalSorage) {
      navigate('/')
    }
  }, [])

  return (
    <>
      {currentUser ? (
        <>
          <div className='d-flex justify-content-center  align-items-center h-100'>
            <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <form
                className='form justify-content-center'
                style={{margin: '0 auto'}}
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className='card card-custom'>
                  <div className='text-center mb-10 mt-5'>
                    <h1 className='mb-3'>{intl.formatMessage({id: 'GENERAL.CHANGE_PASSWORD'})}</h1>
                  </div>

                  {formik.status ? (
                    <div className='mb-10 alert alert-danger mx-5'>
                      <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='card-toolbar p-5'>
                    <div className='fv-row mb-10'>
                      <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                            {intl.formatMessage({id: 'GENERAL.CURRENT_PASSWORD'})}
                          </label>
                        </div>
                      </div>
                      <div className='position-relative'>
                        <input
                          type={showOldPassword ? 'text' : 'password'}
                          autoComplete='off'
                          {...formik.getFieldProps('oldPassword')}
                          className={clsx('form-control form-control-lg form-control-solid pe-15')}
                        />

                        <button
                          className='btn position-absolute top-0 end-0'
                          type='button'
                          id='show-password'
                          onClick={handleOldPassword}
                        >
                          {showOldPassword ? (
                            <i className='bi bi-eye-fill'></i>
                          ) : (
                            <i className='bi bi-eye-slash-fill'></i>
                          )}
                        </button>
                      </div>

                      {formik.touched.oldPassword && formik.errors.oldPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.oldPassword}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-10'>
                      <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                            {intl.formatMessage({id: 'GENERAL.NEW_PASSWORD'})}
                          </label>
                        </div>
                      </div>
                      <div className='position-relative'>
                        <input
                          type={showNewPassword ? 'text' : 'password'}
                          autoComplete='off'
                          {...formik.getFieldProps('newPassword')}
                          className={clsx('form-control form-control-lg form-control-solid pe-15')}
                        />

                        <button
                          className='btn position-absolute top-0 end-0'
                          type='button'
                          id='show-password'
                          onClick={handleNewPassword}
                        >
                          {showNewPassword ? (
                            <i className='bi bi-eye-fill'></i>
                          ) : (
                            <i className='bi bi-eye-slash-fill'></i>
                          )}
                        </button>
                      </div>

                      {formik.touched.newPassword && formik.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.newPassword}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-10'>
                      <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                            {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
                          </label>
                        </div>
                      </div>
                      <div className='position-relative'>
                        <input
                          type={showconfirmPassword ? 'text' : 'password'}
                          autoComplete='off'
                          {...formik.getFieldProps('confirmPassword')}
                          className={clsx('form-control form-control-lg form-control-solid pe-15')}
                        />

                        <button
                          className='btn position-absolute top-0 end-0'
                          type='button'
                          id='show-password'
                          onClick={handleConfirmPassword}
                        >
                          {showconfirmPassword ? (
                            <i className='bi bi-eye-fill'></i>
                          ) : (
                            <i className='bi bi-eye-slash-fill'></i>
                          )}
                        </button>
                      </div>

                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-lg btn-primary w-50 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          {intl.formatMessage({id: 'GENERAL.SAVE'})}
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='change-form'>
            <div className='container'>
              <div className='row change-form-bg'>
                <div className='col-md-6 col-sm-12 p-0'>
                  <div className='change-vector'>
                    <img
                      src={toAbsoluteUrl('/media/auth/login.png')}
                      alt='Doctor Vector'
                      className='img-fluid'
                    />
                  </div>
                </div>
                <div className=' col-md-6 col-sm-12 p-0'>
                  <form
                    className='change-input-form form w-100'
                    style={{margin: '0 auto'}}
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id='kt_login_signin_form'
                  >
                    <div className='text-center'>
                      <h1 className='mt-5 font-weight-bold'>
                        {intl.formatMessage({id: 'GENERAL.CHANGE_PASSWORD'})}
                      </h1>
                    </div>

                    {formik.status ? (
                      <div className='mb-10 alert alert-danger mx-5'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className='input-group'>
                      <label className='form-label fs-6 fw-bolder'>
                        {intl.formatMessage({id: 'GENERAL.CURRENT_PASSWORD'})}
                      </label>

                      <div className='position-relative'>
                        <input
                          type={showOldPassword ? 'text' : 'password'}
                          autoComplete='off'
                          {...formik.getFieldProps('oldPassword')}
                          className={clsx('form-control form-control-lg form-control-solid pe-15')}
                          placeholder={intl.formatMessage({id: 'GENERAL.CURRENT_PASSWORD'})}
                        />

                        <button
                          className='btn position-absolute top-0 end-0'
                          type='button'
                          id='show-password'
                          onClick={handleOldPassword}
                        >
                          {showOldPassword ? (
                            <i className='bi bi-eye-fill'></i>
                          ) : (
                            <i className='bi bi-eye-slash-fill'></i>
                          )}
                        </button>

                        {formik.touched.oldPassword && formik.errors.oldPassword && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.oldPassword}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='input-group'>
                      <label className='form-label fw-bolder fs-6 mb-0'>
                        {intl.formatMessage({id: 'GENERAL.NEW_PASSWORD'})}
                      </label>
                      <div className='position-relative'>
                        <input
                          type={showNewPassword ? 'text' : 'password'}
                          autoComplete='off'
                          {...formik.getFieldProps('newPassword')}
                          className={clsx('form-control form-control-lg form-control-solid pe-15')}
                          placeholder={intl.formatMessage({id: 'GENERAL.NEW_PASSWORD'})}
                        />

                        <button
                          className='btn position-absolute top-0 end-0'
                          type='button'
                          id='show-password'
                          onClick={handleNewPassword}
                        >
                          {showNewPassword ? (
                            <i className='bi bi-eye-fill'></i>
                          ) : (
                            <i className='bi bi-eye-slash-fill'></i>
                          )}
                        </button>

                        {formik.touched.newPassword && formik.errors.newPassword && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.newPassword}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='input-group'>
                      <label className='form-label fw-bolder fs-6 mb-0'>
                        {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
                      </label>

                      <div className='position-relative'>
                        <input
                          type={showconfirmPassword ? 'text' : 'password'}
                          autoComplete='off'
                          {...formik.getFieldProps('confirmPassword')}
                          className={clsx('form-control form-control-lg form-control-solid pe-15')}
                          placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
                        />

                        <button
                          className='btn position-absolute top-0 end-0'
                          type='button'
                          id='show-password'
                          onClick={handleConfirmPassword}
                        >
                          {showconfirmPassword ? (
                            <i className='bi bi-eye-fill'></i>
                          ) : (
                            <i className='bi bi-eye-slash-fill'></i>
                          )}
                        </button>

                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.confirmPassword}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='input-group text-center w-50'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-lg btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            {intl.formatMessage({id: 'GENERAL.SAVE'})}
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>{' '}
        </>
      )}
    </>
  )
}

const ChnagepasswordWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'GENERAL.CHANGE_PASSWORD'})}</PageTitle>
      <ChangePassword />
    </>
  )
}

export {ChnagepasswordWrapper}
