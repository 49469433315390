/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router'

import {toast} from 'react-toastify'
import {KTSVG} from '../../../../../_jmh/helpers'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {profilePicture} from '../core/_models'
import {updateProfilePicture} from '../core/_requests'

const AccountHeader: React.FC = () => {
  const navigate = useNavigate()
  const storedLoginData = localStorage.getItem('loginData')
  const initialLoginData = storedLoginData ? JSON.parse(storedLoginData) : null
  const [loginData] = useState(initialLoginData)

  const {email, user_type} = loginData?.data || {}
  const {id, image} = loginData?.data?.personal_details || {}
  const [previewUrl, setPreviewUrl] = useState('')
  const [previewKey, setPreviewKey] = useState('')

  const initialValues: profilePicture = {
    image: image,
    userType: user_type,
    id: id,
  }

  const formik = useFormik<profilePicture>({
    initialValues,
    // validationSchema: profileDetailsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
    },
  })

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      openConfirmationDialog(file)
    }
  }

  const openConfirmationDialog = (file: File) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to update your profile picture?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            uploadProfilePicture(file)
          },
        },
        {
          label: 'No',
          onClick: () => {
            toast.info('Profile picture is not updated')
          },
        },
      ],
    })
  }

  const uploadProfilePicture = async (file: File) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string)
      setPreviewKey(Date.now().toString())
    }
    reader.readAsDataURL(file)
    try {
      formik.setFieldValue('image', file)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('userType', user_type as string)
      formData.append('id', loginData?.data?.patient_id as any)
      const response = await updateProfilePicture(formData)

      localStorage.setItem(
        'loginData',
        JSON.stringify({
          ...loginData,
          data: {
            ...loginData.data,
            personal_details: response[0],
          },
        })
      )
      navigate('/profile')
      toast.success('Profile picture updated successfully')
    } catch (error: any) {
      toast.error(error.message)
      setPreviewUrl('')
    }
  }

  return (
    <div className='card shadow-lg mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              className='form'
              encType='multipart/form-data'
            >
              <div
                className='image-input image-input-outline symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'
                data-kt-image-input='true'
              >
                {previewUrl || formik.values?.image ? (
                  <img
                    className='image-input-wrapper w-100px h-100px'
                    src={previewUrl || formik.values?.image}
                    alt='Preview'
                    key={previewKey}
                    onClick={() => openConfirmationDialog}
                  />
                ) : (
                  <div className='image-input-wrapper w-100px h-100px'></div>
                )}

                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title='Change avatar'
                >
                  <i className='btn btn-icon btn-sm btn-active-icon-primary bi bi-pencil-fill fs-7'></i>

                  <input
                    type='file'
                    name='image'
                    id='image'
                    accept='.png, .jpg, .jpeg'
                    onChange={handleImageChange}
                  />
                  <input type='hidden' name='image' />
                </label>
              </div>
            </form>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-2 fw-bolder me-1'>
                    {loginData?.data?.full_name}
                  </span>
                </div>

                <div className='flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    {user_type}
                  </span>
                  <span className='d-flex align-items-center text-gray-400 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {email}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AccountHeader}
