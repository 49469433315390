import {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {PayrollsListLoading} from '../loading/PayrollsListLoading'
import {getPayrollReport} from '../../core/_requests'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'

const PayrollListToolbar = () => {
  const intl = useIntl()
  const tabledata = useQueryResponseData()
  const {selectedHospitalId} = useContext(loginContext)
  const [isLoading, setIsLoading] = useState(false)
  const {loginData} = useContext(loginContext)

  const handleReportDownload = async () => {
    try {
      const getReportData: any = await getPayrollReport(selectedHospitalId)
      const uint8Array = new Uint8Array(getReportData?.data)
      const blob = new Blob([uint8Array], {type: 'text/csv'})
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'staff_payroll_report.csv'
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    } catch (error) {
      console.log('Error occurred while downloading inventory report:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'staff-payroll') {
      return item?.module_id
    }
  })

  const add = data?.filter((item: any) => {
    if (item.permission.method === 'POST') {
      return item
    }
  })

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className='btn btn-primary me-3'
        disabled={tabledata?.length > 0 ? false : true}
        onClick={handleReportDownload}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'GENERAL.EXPORT'})}
      </button>
      {add.length ? (
        <Link to='/staff/payroll/add-payroll' type='button' className='btn btn-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'STAFF.PAYROLL.ADD.PAYROLL'})}
        </Link>
      ) : (
        ''
      )}
      {isLoading && <PayrollsListLoading />}
    </div>
  )
}

export {PayrollListToolbar}
