import axios from 'axios'
import {PatientBook} from './_model'
import { ID } from '../../../../_jmh/helpers'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_BOOKING_APPOINTMENT_URL = `${API_URL}/patient-appointment`
const BOOKING_APPOINTMENT_URL = `${API_URL}/patient-appointment`
const CREATE_DOCTOR_URL = `${API_URL}/doctor-availability`


  const getDoctorDataAvail = (hos_id: any, token:any) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  return axios
    .get(`${CREATE_DOCTOR_URL}?${`hospital_id=${hos_id}&type=LATER`}`,{headers})
    .then((d: any) => d.data)
    .then((response: any) => response)
  }

  const createAppointment = (user: PatientBook,token:any,doctor_appointment_id?:any): Promise<PatientBook | undefined> => {
    const headers = {
      Authorization: `Bearer ${token}`, 
    };
    const data = {...user, ...doctor_appointment_id}
    return axios
      .post(CREATE_BOOKING_APPOINTMENT_URL, data,{headers})
  }

  const getPatientAppointmentById = (id: any): Promise<PatientBook | undefined> => {
    return axios
      .get(`${BOOKING_APPOINTMENT_URL}/${id}`)
      .then((response: any) => response.data)
      .then((response: any) => response.data)
  }

  const getDoctorData1 = (id: number,token:any, date?: any ) => {
    const headers = {
      Authorization: `Bearer ${token}`, 
    };
    return axios
      .get(`${CREATE_DOCTOR_URL}?${`doctorId=${id}&date=${date}`}`,{headers})
      .then((d: any) => d.data)
      .then((response: any) => response)
  }

  const updatePatientAppointment = (id:number,user: any): Promise<PatientBook | undefined> => {
    return axios
      .patch(`${BOOKING_APPOINTMENT_URL}/${id}`, user)
  }

  export {
    getDoctorDataAvail,
    createAppointment,
    getDoctorData1,
    getPatientAppointmentById,
    updatePatientAppointment
  }