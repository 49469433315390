import {FC, useContext, useState} from 'react'
import {KTSVG} from '../../../../_jmh/helpers'
import {Medicine} from '../MedicineMasterList/core/_models'
import {useListView} from '../MedicineMasterList/core/ListViewProvider'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {uploadDocument} from '../MedicineMasterList/core/_requests'
import {toast} from 'react-toastify'
import {loginContext} from '../../../context/context'
import {useParams} from 'react-router-dom'
import {useQueryResponse} from '../MedicineMasterList/core/QueryResponseProvider'
import {useIntl} from 'react-intl'

type Props = {
  isLoading: boolean
  onClose: any
}

const MedicineUploadDoc: FC<Props> = ({isLoading, onClose}) => {
  const intl = useIntl()
  const [errorStatus, setErrorStatus] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const SUPPORTED_FILE_TYPES = ['text/csv']
  const {selectedHospitalId} = useContext(loginContext)
  const {id} = useParams()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0]

    if (file) {
      if (SUPPORTED_FILE_TYPES.includes(file.type)) {
        setSelectedFile(file)
        formik.setFieldValue('path', file)
        setErrorStatus('')
      } else {
        setErrorStatus(intl.formatMessage({id: 'VALIDATION.UNSUPPORTED_FILE'}))
      }
    }
  }

  const DisplaySelectedFile = ({file, onRemove}: any) => {
    if (!file) return null
    if (file) {
      return (
        <div className='form-selected-file my-5'>
          <p>{file.name}</p>

          <span className='remove-file' onClick={onRemove}>
            <i className='fa-solid fa-xmark fs-2'></i>
          </span>
        </div>
      )
    }
    return null
  }

  const handleRemoveFile = () => {
    setSelectedFile('')
  }
  const handleClose = () => {
    onClose()
  }
  const [userForEdit] = useState<Medicine>({})

  const editUserSchema = Yup.object().shape({})

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (values) {
          const formData = new FormData()

          formData.append('file', values?.path as any)
          formData.append('hospital_id', selectedHospitalId ? selectedHospitalId : id)

          await uploadDocument(formData)
          toast.success(intl.formatMessage({id: 'VALIDATION.ATTACHMENT_ADDED.MESSAGE'}))
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
        cancel(true)
        onClose()
      }
    },
  })

  return (
    <>
      <div className='modal fade show d-block ' id='kt_modal_add_user' role='dialog' tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered mw-650px '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>
                {intl.formatMessage({id: 'MEDICINE_MASTER.UPLOAD.CSV.FILE'})}
              </h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={handleClose}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y '>
              <form
                id='kt_modal_add_user_form3'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className='d-flex flex-column scroll-y'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7'>
                    <div className='patient_attachment d-flex'>
                      <label
                        htmlFor='file'
                        className='patient_attachment_btn w-100 btn btn-active-light-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil022.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                        <span>{intl.formatMessage({id: 'GENERAL.UPLOAD'})}</span>
                      </label>

                      <input
                        key={selectedFile}
                        type='file'
                        id='file'
                        name='path'
                        onChange={handleFileChange}
                        accept='.csv'
                      />
                    </div>
                    <div className='mt-2 text-muted'>
                      {intl.formatMessage({id: 'MEDICINE_MASTER.CSV.FILE.FORMATS'})}
                    </div>

                    {errorStatus === '' ? (
                      <>
                        <DisplaySelectedFile file={selectedFile} onRemove={handleRemoveFile} />
                      </>
                    ) : (
                      <div className='text-danger my-2'>
                        <div className='font-weight-bold'>{errorStatus}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='text-center pt-5'>
                  <button
                    type='reset'
                    onClick={() => onClose()}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={formik.isSubmitting || isLoading}
                  >
                    {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={
                      isLoading ||
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !formik.touched ||
                      !formik.dirty ||
                      selectedFile === null
                    }
                  >
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'GENERAL.SAVE'})}
                    </span>
                    {(formik.isSubmitting || isLoading) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {MedicineUploadDoc}
