import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import TagsInput from 'react-tagsinput'

const Advice = () => {
  const intl = useIntl()
  const [adviceTags, setAdviceTags] = useState<any>([])
  const [adviceInputValue, setAdviceInputValue] = useState<any>('')

  const defaultAdviceMain = [
    'Have a nice and healthy day',
    'Follow up after 5 days',
    'Avoid fried and oily food',
    'Follow up after 30 days',
    'Avoid fried food',
  ]
  const [defaultAdviceHistory, setDefaultAdviceHistory] = useState<any>(defaultAdviceMain)

  useEffect(() => {
    const prescriptionData = sessionStorage.getItem('prescriptionData')
    if (prescriptionData) {
      const parsedPrescriptionData = JSON.parse(prescriptionData)
      if (parsedPrescriptionData.advice) {
        setAdviceTags(parsedPrescriptionData.advice)
      }
    }
  }, [])

  const handleAdviceHisoryTagRemove = (tag: any) => {
    setAdviceTags(adviceTags.filter((t: any) => t !== tag))
    if (defaultAdviceMain.includes(tag)) {
      const updatedDefaultAdviceHistory = Array.from(new Set([...defaultAdviceHistory, tag]))
      setDefaultAdviceHistory(updatedDefaultAdviceHistory)
    }
  }
  const defaultAdviceHistoryFiltered = defaultAdviceHistory.filter(
    (value: any) => !adviceTags.includes(value)
  )

  const handleAdviceHistoryTagClick = (tag: any) => {
    setAdviceTags([...adviceTags, tag])
    setDefaultAdviceHistory(defaultAdviceHistory.filter((value: any) => value !== tag))
  }

  const handleAdviceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdviceInputValue(e.target.value)
  }

  const handleAdviceHistoryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (adviceInputValue.trim() !== '') {
        setAdviceTags([...adviceTags, adviceInputValue.trim()])
        setAdviceInputValue('')
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    sessionStorage.setItem(
      'prescriptionData',
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('prescriptionData') || '{}'),
        advice: adviceTags,
      })
    )
  }, [adviceTags])

  return (
    <div>
      <h4>
        {intl.formatMessage({
          id: 'PRESCRIPTION_ADVICE_TITLE',
        })}
      </h4>
      <div className='fv-row mb-2 col-12'>
        <TagsInput
          value={adviceTags}
          onChange={(newTags) => {
            setAdviceTags(newTags)
          }}
          tagProps={{
            className:
              'react-tagsinput-tag btn btn-sm btn-light-primary btn-active-light-primary shadow',
            onRemove: (index: any) => handleAdviceHisoryTagRemove(adviceTags[index]),
          }}
          inputProps={{
            placeholder: intl.formatMessage({
              id: 'GENERAL.ADD.ADVICE',
            }),
            value: adviceInputValue,
            onChange: handleAdviceInputChange,
            onKeyDown: handleAdviceHistoryKeyDown,
          }}
          className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
        />

        <div className='preMedicalValueWrapper my-5'>
          {defaultAdviceHistoryFiltered?.map((value: any) => (
            <span
              className='medicalValueTag btn btn-sm'
              key={value}
              onClick={() => handleAdviceHistoryTagClick(value)}
            >
              {value}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Advice
