import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_jmh/layout/core'
import {useNavigate} from 'react-router-dom'
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialUser, Salary} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {SalarysListLoading} from '../components/loading/SalarysListLoading'
import {getSalaryById, updateSalary} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import 'react-datepicker/dist/react-datepicker.css'
import {calculateSalaryComponents} from './SalaryConstant'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'

type Props = {
  isUserLoading: boolean
  user: Salary
}

const editUserSchema = Yup.object().shape({
  // gross_salary: Yup.number()
  //   .required('Gross Salary is required')
  //   .typeError('Gross Salary must be a number'),
  // professional_tax: Yup.number()
  //   .required('Professional Tax is required')
  //   .typeError('Professional Tax must be a number'),
})

const iniData = {
  basicSalary: null,
  houseRentAllowance: null,
  medicalInsuranceAllowance: null,
  conveyanceTransportationAllowance: null,
  mealAllowance: null,
  entertainmentAllowance: null,
  variablePay: null,
  epfDeduction: null,
  throughPaySlip: null,
}

const EditSalaryForm: FC<any> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [grossSalary, setGrossSalary] = useState<any>(null)
  const [otherAllowance, setOtherAllowance1] = useState<any>(0)
  const [professionalTax, setProfessionalTax] = useState<any>(null)
  const [calculation, setCalculation] = useState<any>(iniData)
  const [epfDeduction1, setEpfDeduction1] = useState<any>(0)
  const [allData, setAllData] = useState<any>(user)
  const {id} = useParams()
  const [ischeck, setIscheck] = useState(false)

  useEffect(() => {
    ;(async () => {
      const allsalaryData: any = await getSalaryById(Number(id))
      setAllData(allsalaryData)
    })()
  }, [])

  const [userForEdit] = useState<Salary>({
    ...user,

    gross_salary: user?.gross_salary || initialUser?.gross_salary,
    basic_salary: user?.basic_salary || initialUser?.basic_salary,
    house_rent_allowance: user?.house_rent_allowance || initialUser?.house_rent_allowance,
    medical_insurance_allowance:
      user?.medical_insurance_allowance || initialUser?.medical_insurance_allowance,
    conveyance_transportation_allowance:
      user?.conveyance_transportation_allowance || initialUser?.conveyance_transportation_allowance,
    meal_allowance: user?.meal_allowance || initialUser?.meal_allowance,
    entertainment_allowance: user?.entertainment_allowance || initialUser?.entertainment_allowance,
    variable_pay: user?.variable_pay || initialUser?.variable_pay,
    other_allowance: user?.other_allowance || initialUser?.other_allowance,
    bonus: user?.bonus || initialUser?.bonus,
    incentive: user?.incentive || initialUser?.incentive,
    through_pay_slip: user?.through_pay_slip || initialUser?.through_pay_slip,
    professional_tax: user?.professional_tax || initialUser?.professional_tax,
    EPF_deduction: user?.EPF_deduction || initialUser?.EPF_deduction,
  })

  const cancel = (withRefresh?: boolean) => {
    window.history.back()
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    const salaryComponents = calculateSalaryComponents(
      allData?.gross_salary,
      Number(allData?.other_allowance),
      allData?.professional_tax
    )

    salaryComponents.epfDeduction = ischeck ? salaryComponents.epfDeduction : allData?.EPF_deduction
    setCalculation(salaryComponents)

    setGrossSalary(allData?.gross_salary)
    setOtherAllowance1(allData?.other_allowance)
    setProfessionalTax(allData?.professional_tax)
    setEpfDeduction1(allData?.EPF_deduction)
  }, [allData])

  useEffect(() => {
    const salaryComponents = calculateSalaryComponents(
      Number(grossSalary),
      otherAllowance,
      professionalTax
    )

    setCalculation(salaryComponents)
  }, [grossSalary])

  useEffect(() => {
    const gs = grossSalary

    const salaryComponents = calculateSalaryComponents(
      gs,
      otherAllowance,
      professionalTax,
      epfDeduction1
    )

    setCalculation(salaryComponents)

    setEpfDeduction1(salaryComponents?.epfDeduction)
  }, [otherAllowance])

  useEffect(() => {
    const salaryComponents: any = calculateSalaryComponents(
      grossSalary,
      otherAllowance,
      professionalTax,
      epfDeduction1
    )

    setCalculation(salaryComponents)

    setEpfDeduction1(salaryComponents?.epfDeduction)
  }, [professionalTax])

  useEffect(() => {
    const salaryComponents: any = calculateSalaryComponents(
      grossSalary,
      otherAllowance,
      professionalTax,
      epfDeduction1
    )

    setCalculation(salaryComponents)
  }, [epfDeduction1])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      let hasErrors = false

      values['gross_salary'] = Number(grossSalary)
      // Validation for gross_salary field
      if (values['gross_salary'] === 0) {
        formik.setFieldError('gross_salary', 'Gross salary value cannot be zero')
        hasErrors = true
      } else if (values['gross_salary'] < 0) {
        formik.setFieldError('gross_salary', 'Gross salary value cannot be negative')
        hasErrors = true
      } else {
        if (Number(allData?.gross_salary)) {
          formik.setFieldError('gross_salary', '')
        } else if (values['gross_salary'] === null || values['gross_salary'] === undefined) {
          formik.setFieldError('gross_salary', 'Gross salary is required')
          hasErrors = true
        } else if (!/^\d+(\.\d{1,2})?$/.test(values['gross_salary'].toString())) {
          formik.setFieldError('gross_salary', 'Invalid Gross Salary value')
          hasErrors = true
        }
      }

      if (Number(allData?.professional_tax)) {
        formik.setFieldError('professional_tax', '')
      } else {
        if (values.professional_tax === null || values.professional_tax === undefined) {
          formik.setFieldError('professional_tax', 'Professional tax is required')
          hasErrors = true
        } else if (
          Number(values.professional_tax) === 0 ||
          !/^\d+(\.\d{1,2})?$/.test(values.professional_tax.toString())
        ) {
          formik.setFieldError('professional_tax', 'Invalid professional tax value')
          hasErrors = true
        }
      }

      if (values.other_allowance === null || values.other_allowance === undefined) {
      } else if (values.other_allowance < 0) {
        formik.setFieldError('other_allowance', 'Other allowance value cannot be negative')
        hasErrors = true
      }

      if (values.bonus === null || values.bonus === undefined) {
      } else if (values.bonus < 0) {
        formik.setFieldError('bonus', 'Bonus value cannot be negative')
        hasErrors = true
      }

      if (values.incentive === null || values.incentive === undefined) {
      } else if (values.incentive < 0) {
        formik.setFieldError('incentive', 'Incentive value cannot be negative')
        hasErrors = true
      }

      if (values.professional_tax === null || values.professional_tax === undefined) {
      } else if (values.professional_tax < 0) {
        formik.setFieldError('professional_tax', 'Professional tax value cannot be negative')
        hasErrors = true
      } else if (values.professional_tax >= values['gross_salary']) {
        formik.setFieldError(
          'professional_tax',
          'Professional tax value cannot be greater than gross salary'
        )
        hasErrors = true
      }

      if (values.EPF_deduction === null || values.EPF_deduction === undefined) {
      } else if (values.EPF_deduction < 0) {
        formik.setFieldError('EPF_deduction', 'EPF value cannot be negative')
        hasErrors = true
      } else if (values.EPF_deduction >= values['gross_salary']) {
        formik.setFieldError('EPF_deduction', 'EPF value cannot be greater than gross salary')
        hasErrors = true
      }

      values['basic_salary'] = Number(calculation?.basicSalary)
      values['conveyance_transportation_allowance'] = Number(
        calculation?.conveyanceTransportationAllowance
      )

      values['entertainment_allowance'] = Number(calculation?.entertainmentAllowance)
      values['house_rent_allowance'] = Number(calculation?.houseRentAllowance)
      if (typeof values['bonus'] != 'number') {
        delete values['bonus']
      } else {
        values['bonus'] = Number(values['bonus'])
      }
      if (typeof values['incentive'] != 'number') {
        delete values['incentive']
      } else {
        values['incentive'] = Number(values['incentive'])
      }
      values['meal_allowance'] = Number(calculation?.mealAllowance)
      values['medical_insurance_allowance'] = Number(calculation?.medicalInsuranceAllowance)
      values['other_allowance'] = Number(otherAllowance)
      values['through_pay_slip'] = Number(calculation?.throughPaySlip)
      values['variable_pay'] = Number(calculation?.variablePay)
      values['EPF_deduction'] = Number(epfDeduction1)
      values['gross_salary'] = Number(grossSalary)
      values['professional_tax'] = Number(professionalTax)

      setSubmitting(true)
      try {
        if (isNotEmpty(id)) {
          delete values?.id
          delete values?.salary_month
          delete values?.salary_year
          delete values?.salary_year_and_month
          delete values?.staff_id
          delete values?.hospital_id
          delete values?.initials

          if (hasErrors === false) {
            await updateSalary(values, id).then((res: any) => {
              toast.success(res?.data?.message)
              navigate('/staff/salary-structure')
            })
          }
        }
      } catch (ex: any) {
        toast.error(ex.response.data.message)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleBackClick = () => {
    window.history.back()
  }

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'EDIT.EMPLOYEE.SALARY'})}</PageTitle>
      <div>
        <div className='card card-custom card-flush h-auto'>
          <div className='card-header'>
            <h3 className='card-title fw-bold fs-2'>
              {intl.formatMessage({id: 'SALARY.STRUCTURE.STAFF.MEMBER'})}(
              {allData?.staff?.full_name})
            </h3>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </button>
            </div>
          </div>
          <div className='card-body  py-5'>
            <form id='kt_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='d-flex flex-column me-n7 pe-7' id='kt_add_user_scroll'>
                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({
                          id: 'SALARY.STRUCTURE.ADD.GROSS.SALARY',
                        })}
                      </label>

                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.TOTAL.SALARY',
                        })}
                        type='number'
                        name='gross_salary'
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        autoComplete='off'
                        value={formik.values.gross_salary ?? calculation?.grossSalary ?? ''}
                        onChange={(e) => {
                          formik.handleChange(e)
                          setGrossSalary(e.target.value)
                          setIscheck(true)
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                      />

                      {formik.touched.gross_salary && formik.errors.gross_salary && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.gross_salary}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.BASIC.SALARY.50%'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.BASIC.SALARY'})}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        value={calculation?.basicSalary}
                        type='text'
                        name='basic_salary'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.HOUSE.RENT.ALLOWANCE.13%'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.HOUSE.RENT.ALLOWANCE',
                        })}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        value={calculation?.houseRentAllowance}
                        type='text'
                        name='house_rent_allowance'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.MEDICAL.ALLOWANCE.12%'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.MEDICAL.ALLOWANCE',
                        })}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        value={calculation?.medicalInsuranceAllowance}
                        type='text'
                        name='medical_insurance_allowance'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.CONVEYANCE.ALLOWANCE.11%'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.CONVEYANCE.ALLOWANCE',
                        })}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='text'
                        name='conveyance_transportation_allowance'
                        autoComplete='off'
                        value={calculation?.conveyanceTransportationAllowance}
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.MEAL.ALLOWANCE.9%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.MEAL.ALLOWANCE'})}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='text'
                        name='meal_allowance'
                        autoComplete='off'
                        value={calculation?.mealAllowance}
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.ENTERTAINMENT.ALLOWANCE.5%'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({
                          id: 'SALARY.STRUCTURE.ENTERTAINMENT.ALLOWANCE',
                        })}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='text'
                        name='entertainment_allowance'
                        autoComplete='off'
                        value={calculation?.entertainmentAllowance}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.VARIABLE.PAY.10%'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.VARIABLE.PAY'})}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        value={calculation?.variablePay}
                        type='text'
                        name='variable_pay'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.OTHER.ALLOWANCE'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.OTHER.ALLOWANCE'})}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        name='other_allowance'
                        autoComplete='off'
                        value={formik.values.other_allowance || otherAllowance}
                        onChange={(e) => {
                          formik.handleChange(e)
                          setOtherAllowance1(e.target.value)
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.other_allowance && formik.errors.other_allowance && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.other_allowance}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.BONUS'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.BONUS'})}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        value={formik.values.bonus ?? allData?.bonus ?? ''}
                        name='bonus'
                        onChange={formik.handleChange}
                        autoComplete='off'
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.bonus && formik.errors.bonus && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.bonus}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.INCENTIVE'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.INCENTIVE'})}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        name='incentive'
                        value={formik.values.incentive ?? allData?.incentive ?? ''}
                        onChange={formik.handleChange}
                        autoComplete='off'
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.incentive && formik.errors.incentive && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.incentive}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.PAY.THROUGH.SLIP'})}
                      </label>
                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.PAY.THROUGH.SLIP'})}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        value={calculation?.throughPaySlip}
                        name='through_pay_slip'
                        autoComplete='off'
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.PROFESSIONAL.TAX'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.PROFESSIONAL.TAX'})}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        // value={allData?.professional_tax || ''}
                        name='professional_tax'
                        autoComplete='off'
                        value={formik.values.professional_tax || professionalTax}
                        onChange={(e) => {
                          formik.handleChange(e)
                          setProfessionalTax(e.target.value)
                          // setIsDirty(true)
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                      />
                      {formik.touched.professional_tax && formik.errors.professional_tax && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.professional_tax}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='fv-row mb-7'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.EPF.DEDUCTION.12%'})}
                      </label>

                      <input
                        placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.EPF.DEDUCTION'})}
                        {...formik.getFieldProps('EPF_deduction')}
                        className={clsx('form-control form-control mb-3 mb-lg-0')}
                        type='number'
                        onKeyPress={(event) => {
                          if (event.key === 'e' || event.key === 'E') {
                            event.preventDefault()
                          }
                        }}
                        name='EPF_deduction'
                        autoComplete='off'
                        value={formik.values.EPF_deduction ?? epfDeduction1 ?? ''}
                        onChange={(e) => {
                          formik.handleChange(e)
                          setEpfDeduction1(e.target.value)
                          setIscheck(true)
                        }}
                        disabled={formik.isSubmitting || isUserLoading}
                      />
                      {formik.touched.EPF_deduction && formik.errors.EPF_deduction && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.EPF_deduction}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => cancel()}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                  disabled={formik.isSubmitting || isUserLoading}
                >
                  {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={
                    isUserLoading ||
                    formik.isSubmitting ||
                    !formik.isValid ||
                    !formik.touched ||
                    !formik.dirty
                  }
                  // disabled={
                  //   isUserLoading ||
                  //   formik.isSubmitting ||
                  //   (!formik.isValid && Object.keys(formik.touched).length > 0) ||
                  //   (!isDirty && Object.keys(formik.touched).length === 0)
                  // }
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'GENERAL.SAVE'})}
                  </span>
                  {(formik.isSubmitting || isUserLoading) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
            {(formik.isSubmitting || isUserLoading) && <SalarysListLoading />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditSalaryForm
