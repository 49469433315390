import { ID, Response } from "../../../../../_jmh/helpers"

export type Inventory = {
  id?: ID
  name?: string
  available_quantity:number
  assigned_quantity : number
  minimum_quantity : number
  maximum_quantity :number
  status?:string
  created_at?: string
  updated_at?: string
  hospital_id?: number
  initials?: {
    label: string
    state: string
  }
  category_id?:number
}

export type InventoryQueryResponse = Response<Array<Inventory>>

export const initialUser: Inventory = {
    name: '',
    available_quantity : 0,
    assigned_quantity : 0,
    minimum_quantity: 0,
    maximum_quantity : 0,
    status: ''
}
