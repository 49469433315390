import React, {useState} from 'react'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {profileDetailsInitValues} from '../core/_models'
import {updateMyProfile} from '../core/_requests'
import {AccountHeader} from './OpProfileHeader'
import OpLayout from '../../../outer-patient-modules/OpLayout'
import {toAbsoluteUrl} from '../../../../../_jmh/helpers'

const profileDetailsSchema = Yup.object().shape({
  full_name: Yup.string().required('Full name is required'),
  gender: Yup.string().required('Gender is required'),
  date_of_birth: Yup.date().nullable(),
  // .test('dob', 'Should be greater than 18', function (value: any, ctx) {
  //   const dob = new Date(value)
  //   const validDate = new Date()
  //   const valid = validDate.getFullYear() - dob.getFullYear() >= 18
  //   return !valid ? ctx.createError() : valid
  // })
  // .required('Required')
  // permanent_address: Yup.string().required('Company site is required'),
  contact_number: Yup.string()
    .matches(/^\d{10}$/, 'Contact number must be a valid 10-digit number')
    .required('Contact number is required'),
})

const OpProfileDetailsSettings: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const storedLoginData = localStorage.getItem('loginData')
  const loginData = storedLoginData ? JSON.parse(storedLoginData) : null

  const {contact_number, image, date_of_birth, full_name, gender, permanent_address} =
    loginData?.data?.personal_details || {}

  const initialValues: profileDetailsInitValues = {
    full_name: full_name || '',
    contact_number: contact_number || '',
    date_of_birth: date_of_birth || '',
    gender: gender || '',
    permanent_address: permanent_address || '',
    image: image || undefined,
  }
  const [birthdate, setBirthDate] = useState<Date | null>(null)
  const [loading, setLoading] = useState(false)

  const formik = useFormik<profileDetailsInitValues>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      values['contact_number'] = values?.contact_number?.toString()
      try {
        const response = await updateMyProfile(values)

        if (response && Array.isArray(response)) {
          const personalDetailsObject = response[0]
          localStorage.setItem(
            'loginData',
            JSON.stringify({
              ...loginData,
              data: {
                ...loginData?.data,
                personal_details: personalDetailsObject,
              },
            })
          )
          navigate('/profile')
          toast.success('Profile updated successfully')
        } else {
          console.error('Invalid response format')
          toast.error('Failed to update profile')
        }
      } catch (ex: any) {
        toast.error(ex.response.data.message)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleClose = () => {
    window.history.back()
  }

  // const maxDate = new Date(moment().subtract(18, 'years').format('YYYY-MM-DD'))
  const maxDate = new Date(moment().format('YYYY-MM-DD'))

  return (
    <>
      <OpLayout>
        <div className='user_profile_back'>
          <img src={toAbsoluteUrl('/media/landing_asset/user_profile_back.jpg')} alt='Logo' />
        </div>
        <div className='row user_profile_row'>
          <div className='col-4'>
            <AccountHeader />
          </div>
          <div className='col-8'>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-header'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>
                    {intl.formatMessage({id: 'PROFILE.EDIT_DETAILS'})}
                  </h3>
                </div>
              </div>

              <div id='kt_account_profile_details' className='collapse show'>
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  className='form'
                  encType='multipart/form-data'
                >
                  <div className='card-body border-top p-9'>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        {intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder={intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
                          {...formik.getFieldProps('full_name')}
                        />
                        {formik.touched.full_name && formik.errors.full_name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.full_name}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>
                          {intl.formatMessage({id: 'GENERAL.GENDER'})}
                        </span>
                      </label>

                      <div className='d-flex gap-4 col-lg-8 fv-row'>
                        <div className='d-flex fv-row'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input me-3'
                              {...formik.getFieldProps('gender')}
                              name='gender'
                              type='radio'
                              value='MALE'
                              id='kt_modal_update_role_option_0'
                              checked={formik.values.gender === 'MALE'}
                              disabled={formik.isSubmitting}
                            />

                            <label
                              className='form-check-label'
                              htmlFor='kt_modal_update_role_option_0'
                            >
                              <div className='fw-bolder text-gray-800'>
                                {intl.formatMessage({id: 'GENERAL.MALE'})}
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className='d-flex fv-row'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input me-3'
                              {...formik.getFieldProps('gender')}
                              name='gender'
                              type='radio'
                              value='FEMALE'
                              id='kt_modal_update_role_option_1'
                              checked={formik.values.gender === 'FEMALE'}
                              disabled={formik.isSubmitting}
                            />

                            <label
                              className='form-check-label'
                              htmlFor='kt_modal_update_role_option_1'
                            >
                              <div className='fw-bolder text-gray-800'>
                                {intl.formatMessage({id: 'GENERAL.FEMALE'})}
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className='d-flex fv-row'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input me-3'
                              {...formik.getFieldProps('gender')}
                              name='gender'
                              type='radio'
                              value='OTHER'
                              id='kt_modal_update_role_option_2'
                              checked={formik.values.gender === 'OTHER'}
                              disabled={formik.isSubmitting}
                            />

                            <label
                              className='form-check-label'
                              htmlFor='kt_modal_update_role_option_2'
                            >
                              <div className='fw-bolder text-gray-800'>
                                {intl.formatMessage({id: 'GENERAL.OTHER'})}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      {/* begin::Label */}
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'> {intl.formatMessage({id: 'GENERAL.DOB'})}</span>
                      </label>
                      <div className='col-lg-8'>
                        <div className='position-relative '>
                          <ReactDatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            {...formik.getFieldProps('date_of_birth')}
                            dropdownMode='select'
                            className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                            dateFormat='dd-MM-yyyy'
                            name='date_of_birth'
                            selected={birthdate}
                            maxDate={maxDate}
                            onChange={(date: any) => {
                              formik.setFieldValue(
                                'date_of_birth',
                                moment(date).format('YYYY-MM-DD')
                              )
                              setBirthDate(date)
                            }}
                            autoComplete='off'
                            disabled={formik.isSubmitting}
                            placeholderText={intl.formatMessage({id: 'GENERAL.DOB'})}
                          />

                          <span
                            className='DatePicker_icon cursor-pointer'
                            onClick={() => document.getElementsByName('date_of_birth')[0].focus()}
                          >
                            <div className='fa fa-calendar'></div>
                          </span>
                        </div>
                        {formik.touched.date_of_birth && formik.errors.date_of_birth && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.date_of_birth}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className=''>
                          {' '}
                          {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
                        </span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder={intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
                          {...formik.getFieldProps('permanent_address')}
                        />
                        {formik.touched.permanent_address && formik.errors.permanent_address && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.permanent_address}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>
                          {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                        </span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid'
                          placeholder={intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                          onKeyPress={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                              e.preventDefault()
                            }
                          }}
                          {...formik.getFieldProps('contact_number')}
                        />
                        {formik.touched.contact_number && formik.errors.contact_number && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.contact_number}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                      {!loading && intl.formatMessage({id: 'GENERAL.SAVE'})}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <button type='button' className='btn btn-primary mx-2' onClick={handleClose}>
                      {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </OpLayout>
    </>
  )
}

export {OpProfileDetailsSettings}
