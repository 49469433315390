// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {ATD} from '../../core/_models'
import {RoomNumber} from './RoomNumber'
import {DoctorData} from './DoctorData'
import {AdmissionDate} from './AdmissionDate'
import {DischargeDate} from './DischargeDate'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<ATD>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.PATIENT.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'patient',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DOCTOR.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'doctor',
    Cell: ({...props}) => <DoctorData user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ROOM.NUMBER'})}
          className='min-w-125px'
        />
      )
    },
    id: 'room',
    Cell: ({...props}) => <RoomNumber user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ADMISSION.DATE.TIME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'admission_date',
    Cell: ({...props}) => <AdmissionDate user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DISCHARGE.DATE.TIME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'discharge_date',
    Cell: ({...props}) => <DischargeDate user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'ad-patient') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px pe-5'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'ad-patient') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? (
        <UserActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />
      ) : null
    },
  },
]

export {usersColumns}
