import {FC, useContext, useEffect} from 'react'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {Link} from 'react-router-dom'
import {loginContext} from '../../../../app/context/context'
import {AsideMenuIconMain} from './AsideMenuIconMain'
import {useSelector} from 'react-redux'

interface Props {
  asideMinimize: boolean
  setAsideMinimize: (value: boolean) => void
}

const AsideDefault: FC<Props> = ({asideMinimize, setAsideMinimize}) => {
  const {classes} = useLayout()
  const {loginData} = useContext(loginContext)

  // const handleMouseEnter = () => {
  //   document.body.removeAttribute('data-kt-aside-minimize')
  //   setAsideMinimize(false)
  //   const asideToggleElement = document.getElementById('kt_aside_toggle')
  //   if (asideToggleElement) {
  //     asideToggleElement.classList.remove('active')
  //   }
  // }

  // // Function to handle mouse leave event on aside
  // const handleMouseLeave = () => {
  //   if (asideMinimize) {
  //     document.body.setAttribute('data-kt-aside-minimize', 'on')
  //   }
  // }

  // Effect to clean up attributes when component unmounts
  // useEffect(() => {
  //   return () => {
  //     document.body.removeAttribute('data-kt-aside-minimize')
  //   }
  // }, [])

  return (
    <div
      id='kt_aside'
      className={`${asideMinimize ? 'sidemini' : 'sidemax'} aside`}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      // data-kt-drawer-width="{default:'200px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    >
      {/* <div className='aside-toolbar flex-column-auto' id='kt_aside_toolbar'></div> */}

      <div
        className={`aside-menu flex-column-fluid ${
          asideMinimize ? 'aside_menu_icon Tsidemini' : 'sidemax'
        }`}
      >
        {asideMinimize ? (
          <AsideMenuIconMain />
        ) : (
          <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
        )}
      </div>

      {loginData?.data?.user_type === 'SUPER_ADMIN' ? (
        ''
      ) : (
        <div className='aside-footer pb-5' id='kt_aside_footer'>
          <Link to='/support-feedback'>
            <div
              className='btn btn-custom btn-primary mb-5 w-100'
              // data-bs-toggle='tooltip'
              // data-bs-trigger='hover'
              // data-bs-dismiss-='click'
            >
              <KTSVG path='/media/icons/duotune/general/gen070.svg' className='svg-icon-2' />
              {/* <span className='btn-label'>Support and Feedback</span> */}
            </div>
          </Link>
        </div>
      )}
    </div>
  )
}

export {AsideDefault}
