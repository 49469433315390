/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {deleteRoom} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
}

const AssignInvActionsCell: FC<Props> = ({id}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const pagination = useQueryResponsePagination()
  const [currentPage, setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()
  const {assignData} = useContext(loginContext)
  const {refetch} = useQueryResponse()
  const {loginData} = useContext(loginContext)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const page = pagination?.pagination?.meta?.currentPage || 1

  const deleteItem = useMutation(() => deleteRoom(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      if (page > 1) {
        const updatedData = await queryClient.getQueryData<any>([`${QUERIES.USERS_LIST}-${query}`])
        if (updatedData?.data?.length > 0) {
          setCurrentPage(page)
        } else {
          setCurrentPage(page - 1)
          updateState({
            page: page - 1,
            items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
          })
        }
      }

      toast.error('Assigned Inventory Deleted Successfully')
    },
  })

  useEffect(() => {
    refetch()
  }, [assignData, refetch])

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'assign-inventory') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE') {
      return item
    }
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm px-4 justify-content-end'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-100px py-2'
        data-kt-menu='true'
      >
        {edit.length ? (
          <div className='menu-item px-2'>
            <a className='menu-link px-4 justify-content-between' onClick={openEditModal}>
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </a>
          </div>
        ) : (
          ''
        )}

        {deleteData.length ? (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              {intl.formatMessage({id: 'GENERAL.DELETE'})}
            </a>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export {AssignInvActionsCell}
