import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Staff, initialStaff} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createStaff, getRoles, getShift, getSpeciality, updateStaff} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {StaffListLoading} from '../components/loading/StaffListLoading'
import {toast} from 'react-toastify'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'
import Select from 'react-select'

type Props = {
  isUserLoading: boolean
  Staff: Staff
}

const StaffEditModalForm: FC<Props> = ({Staff, isUserLoading}) => {
  const intl = useIntl()
  const [birthdate, setBirthDate] = useState<Date | null>(null)
  const [speciality, setSpeciality] = useState([])
  const [shiftData, setShiftData] = useState([])
  const [roleData, setRoleData] = useState([])
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [preview, setPreview] = useState('')
  const {refetch} = useQueryResponse()
  const {selectedHospitalId} = useContext(loginContext)
  const [specialityData, setSpecialityData] = useState([])
  const [specialityError, setSpecialityError] = useState('')

  let editUserSchema
  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      full_name: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Full Name is required')
        .matches(/^[A-Za-z\s]+$/, 'Number not allowed'),
      job_title: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Job Title is required')
        .matches(/^[A-Za-z\s]+$/, 'Number not allowed'),
      contact_number: Yup.string()
        .matches(/^\d{10}$/, 'Contact number must be a valid 10-digit number')
        .required('Contact Number is required'),
      shift_id: Yup.string().required('Shift is required').notOneOf([''], 'Shift is required'),
      status: Yup.string().required('Status is required').notOneOf([''], 'Status is required'),
      role_id: Yup.string().required('Role is required').notOneOf([''], 'Role is required'),
      permanent_address: Yup.string().test(
        'noLeadingSpaces',
        'Invalid Input, Please avoid leading spaces at the beginning of the field',
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  } else {
    editUserSchema = Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Email is required'),
      full_name: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Full Name is required')
        .matches(/^[A-Za-z\s]+$/, 'Number not allowed'),
      job_title: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Job Title is required')
        .matches(/^[A-Za-z\s]+$/, 'Number not allowed'),
      contact_number: Yup.string()
        .matches(/^\d{10}$/, 'Contact number must be a valid 10-digit number')
        .required('Contact Number is required'),
      date_of_birth: Yup.date()
        .nullable()
        .test('dob', 'Should be greater than 18', function (value: any, ctx) {
          const dob = new Date(value)
          const validDate = new Date()
          const valid = validDate.getFullYear() - dob.getFullYear() >= 18
          return !valid ? ctx.createError() : valid
        })
        .required('Required'),
      shift_id: Yup.string().required('Shift is required').notOneOf([''], 'Shift is required'),
      role_id: Yup.string().required('Role is required').notOneOf([''], 'Role is required'),
      status: Yup.string().required('Status is required').notOneOf([''], 'Status is required'),
      permanent_address: Yup.string().test(
        'noLeadingSpaces',
        'Invalid Input, Please avoid leading spaces at the beginning of the field',
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  }

  const [userForEdit] = useState<Staff>({
    ...Staff,
    full_name: Staff.full_name || initialStaff.full_name,
    image: Staff.image || initialStaff.image,
    date_of_birth: Staff.date_of_birth || initialStaff.date_of_birth,
    permanent_address: Staff.permanent_address || initialStaff.permanent_address,
    contact_number: Staff.contact_number || initialStaff.contact_number,
    gender: Staff.gender || initialStaff.gender,
    speciality_ids: Staff.speciality_ids || initialStaff.speciality_ids,
    shift_id: Staff.shift_id || initialStaff.shift_id,
    email: Staff.email || initialStaff.email,
    status: Staff.status || initialStaff.status,
    role_id: Staff.user?.role_id || initialStaff.role_id,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    ;(async () => {
      const specialityData: any = await getSpeciality()
      const filterSpeciality = specialityData?.data?.filter(
        (item: any) => item?.hospital_id?.id == selectedHospitalId
      )
      setSpeciality(filterSpeciality)

      setSpecialityData(
        filterSpeciality?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
            id: item?.id,
          }
        })
      )

      const rolesData: any = await getRoles()
      const filterRoles = rolesData?.data?.filter(
        (item: any) => item?.hospital_id?.id == selectedHospitalId
      )
      setRoleData(filterRoles)

      const shiftsData: any = await getShift()

      const filterShift = shiftsData?.data?.filter(
        (item: any) => item?.hospital_id?.id == selectedHospitalId
      )
      setShiftData(filterShift)
    })()
  }, [])

  useEffect(() => {
    if (initialStaff.image) {
      setPreview(initialStaff.image)
    }
  }, [initialStaff])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      if (values?.speciality_ids === undefined || values?.speciality_ids?.length === 0) {
        setSpecialityError('Speciality is required')
        return false
      }
      try {
        if (isNotEmpty(values.id)) {
          // delete values?.speciality
          // delete values?.user?.role_id
          // delete values?.user?.hospital_id
          // delete values.user
          const formData = new FormData()
          formData.append('full_name', values?.full_name as string)
          formData.append(
            'date_of_birth',
            (values['date_of_birth'] = moment(values['date_of_birth'], 'DD-MM-YYYY').format(
              'DD-MM-YYYY'
            ) as any)
          )
          formData.append('contact_number', values?.contact_number as any)
          formData.append('gender', values?.gender as string)
          formData.append('image', values?.image as string)
          formData.append('permanent_address', values?.permanent_address as string)
          formData.append('status', values?.status as any)
          formData.append('shift_id', values?.shift_id as any)
          formData.append('job_title', values.job_title as string)
          formData.append('speciality_ids', values?.speciality_ids as any)
          formData.append('role_id', values?.role_id as any)
          await updateStaff(formData, itemIdForUpdate).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          const formData = new FormData()

          formData.append('full_name', values?.full_name as string)
          formData.append(
            'date_of_birth',
            (values['date_of_birth'] = moment(values['date_of_birth'], 'DD-MM-YYYY').format(
              'DD-MM-YYYY'
            ) as any)
          )
          formData.append('permanent_address', values?.permanent_address as string)
          formData.append('contact_number', values?.contact_number as any)
          formData.append('gender', values?.gender as string)
          formData.append('speciality_ids', values.speciality_ids as any)
          formData.append('role_id', values?.role_id as any)
          formData.append('job_title', values.job_title as string)
          formData.append('image', values?.image as string)
          formData.append('hospital_id', Number(selectedHospitalId) as any)
          formData.append('shift_id', values?.shift_id as any)
          formData.append('email', values.email as any)
          formData.append('status', values?.status as any)
          await createStaff(formData).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        toast.error(ex.response.data.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleImageChange = (event: any, formik: any) => {
    const file = event.currentTarget.files[0]

    formik.setFieldValue('image', file)
    setPreview(URL.createObjectURL(file))
  }

  const handleRemoveImage = () => {
    setPreview('')
    formik.setFieldValue('image', null)
  }

  const maxDate = new Date(moment().subtract(18, 'years').format('YYYY-MM-DD'))

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  const handleSpecialityBlur = () => {
    if (
      formik?.values?.speciality_ids === undefined ||
      formik?.values?.speciality_ids.length === 0
    ) {
      setSpecialityError('Speciality is required')
    } else {
      setSpecialityError('')
    }
  }

  return (
    <>
      <form
        id='kt_modal_add_user_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
        encType='multipart/form-data'
      >
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='d-block fw-bold fs-6 mb-5'>
              {intl.formatMessage({id: 'GENERAL.PROFILE'})}
            </label>
            <div className='image-input image-input-outline' data-kt-image-input='true'>
              {preview || Staff.image ? (
                <div
                  className='image-input-wrapper w-100px h-100px'
                  style={{backgroundImage: `url('${preview || Staff.image}')`}}
                ></div>
              ) : (
                <div className='image-input-wrapper w-100px h-100px'></div>
              )}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                <input
                  key={preview}
                  type='file'
                  name='image'
                  id='image'
                  accept='image/png, image/jpg, image/jpeg'
                  onChange={(event) => handleImageChange(event, formik)}
                />
                <input type='hidden' name='image' value={initialStaff.image} />
              </label>
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {preview ? (
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='remove'
                  data-bs-toggle='tooltip'
                  title='Remove avatar'
                  id='image'
                  onClick={handleRemoveImage}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              ) : (
                ''
              )}
            </div>
            <div className='form-text'>
              {intl.formatMessage({id: 'GENERAL.ALLOWED.FILE.TYPES'})}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
                {...formik.getFieldProps('full_name')}
                type='text'
                name='full_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.full_name && formik.errors.full_name},
                  {
                    'is-valid': formik.touched.full_name && !formik.errors.full_name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.full_name && formik.errors.full_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.full_name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.EMAIL'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.EMAIL'})}
                {...formik.getFieldProps('user.email')}
                className={clsx(
                  `form-control ${itemIdForUpdate ? '' : 'form-control-solid'}  mb-3 mb-lg-0`,
                  {'is-invalid': formik.touched.user && formik.errors.user},
                  {
                    'is-valid': formik.touched.user && !formik.errors.user,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading || itemIdForUpdate ? true : false}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
              {...formik.getFieldProps('permanent_address')}
              type='text'
              name='permanent_address'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.permanent_address && formik.errors.permanent_address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.permanent_address}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.DOB'})}
              </label>
              <div className='position-relative'>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('date_of_birth')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy'
                  name='date_of_birth'
                  maxDate={maxDate}
                  selected={birthdate}
                  onChange={(date) => {
                    formik.setFieldValue('date_of_birth', date)
                    setBirthDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  placeholderText={intl.formatMessage({id: 'GENERAL.DOB'})}
                />
                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('date_of_birth')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>
              {formik.touched.date_of_birth && formik.errors.date_of_birth && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.date_of_birth}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-7 col-12 col-sm-6 '>
              <label className='required fw-bold fs-6 mb-5'>
                {intl.formatMessage({id: 'GENERAL.GENDER'})}
              </label>
              <div className='d-flex gap-4'>
                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='MALE'
                      id='kt_modal_update_role_option_0'
                      checked={formik.values.gender === 'MALE'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.MALE'})}
                      </div>
                    </label>
                  </div>
                </div>
                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='FEMALE'
                      id='kt_modal_update_role_option_1'
                      checked={formik.values.gender === 'FEMALE'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.FEMALE'})}
                      </div>
                    </label>
                  </div>
                </div>
                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('gender')}
                      name='gender'
                      type='radio'
                      value='OTHER'
                      id='kt_modal_update_role_option_2'
                      checked={formik.values.gender === 'OTHER'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                      <div className='fw-bolder text-gray-800'>
                        {intl.formatMessage({id: 'GENERAL.OTHER'})}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'STAFF.JOB.TITLE'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'STAFF.JOB.TITLE'})}
                {...formik.getFieldProps('job_title')}
                type='text'
                name='job_title'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.job_title && formik.errors.job_title},
                  {
                    'is-valid': formik.touched.job_title && !formik.errors.job_title,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.job_title && formik.errors.job_title && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.job_title}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                {...formik.getFieldProps('contact_number')}
                type='number'
                name='contact_number'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.contact_number && formik.errors.contact_number},
                  {
                    'is-valid': formik.touched.contact_number && !formik.errors.contact_number,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault()
                  }
                }}
              />
              {formik.touched.contact_number && formik.errors.contact_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.contact_number}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'STAFF.SHIFT'})}
              </label>
              <select
                className='form-select'
                aria-label='Select example'
                {...formik.getFieldProps('shift_id')}
                name='shift_id'
              >
                <option value=''>{intl.formatMessage({id: 'STAFF.SELECT.SHIFT'})}</option>
                {shiftData &&
                  shiftData?.map((item: any) => {
                    return (
                      <option value={item?.id} key={item?.id}>
                        {item?.type}
                      </option>
                    )
                  })}
              </select>
              {formik.touched.shift_id && formik.errors.shift_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.shift_id}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'STAFF.SPECIALITY'})}
              </label>
              {/* <select
                className='form-select'
                aria-label='Select example'
                {...formik.getFieldProps('speciality_id')}
              >
                <option value=''>{intl.formatMessage({id: 'STAFF.SELECT.SPECIALITY'})}</option>
                {speciality &&
                  speciality?.map((item: any) => {
                    return (
                      <option value={item?.id} key={item?.id}>
                        {item?.name}
                      </option>
                    )
                  })}
              </select> */}
              <Select
                isMulti
                id='speciality_ids'
                name='speciality_ids'
                options={specialityData}
                value={specialityData.filter((option: any) =>
                  formik?.values?.speciality_ids?.includes(option.id)
                )}
                styles={customStyles}
                onChange={(selectedOptions) => {
                  const selectedSpecialityIds = selectedOptions.map((option: any) => option?.id)
                  formik.setFieldValue('speciality_ids', selectedSpecialityIds)
                  if (selectedSpecialityIds.length > 0) {
                    setSpecialityError('')
                  } else {
                    setSpecialityError('Speciality is required')
                  }
                }}
                onBlur={handleSpecialityBlur}
                className={`basic-multi-select select_arrow_remove`}
                classNamePrefix='select'
                placeholder={intl.formatMessage({id: 'STAFF.SELECT.SPECIALITY'})}
                // isDisabled={itemIdForUpdate ? true : false}
              />
              {specialityError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{specialityError}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.ROLE'})}
              </label>
              <select
                className='form-select select_arrow_remove'
                aria-label='Select example'
                {...formik.getFieldProps('role_id')}
                disabled={itemIdForUpdate ? true : false}
              >
                <option value=''>{intl.formatMessage({id: 'STAFF.SELECT.ROLE'})}</option>
                {roleData &&
                  roleData?.map((item: any) => {
                    return (
                      <option value={item?.id} key={item?.id}>
                        {item?.name}
                      </option>
                    )
                  })}
              </select>
              {formik.touched.role_id && formik.errors.role_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.role_id}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.STATUS'})}
              </label>
              <select
                className='form-select'
                aria-label='Select example'
                {...formik.getFieldProps('status')}
              >
                <option value=''>{intl.formatMessage({id: 'GENERAL.SELECT.STATUS'})}</option>
                <option value='ACTIVE'>{intl.formatMessage({id: 'GENERAL.ACTIVE.STATUS'})}</option>
                <option value='INACTIVE'>
                  {intl.formatMessage({id: 'GENERAL.IN.ACTIVE.STATUS'})}
                </option>
              </select>
              {formik.touched.status && formik.errors.status && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <StaffListLoading />}
    </>
  )
}
export {StaffEditModalForm}
