import {useQuery} from 'react-query'
import {PatientInsuranceEditModalForm} from './PatientInsuranceEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getIPatientInsuranceById} from '../core/_requests'
import moment from 'moment'
import {useQueryResponseData} from '../core/QueryResponseProvider'

const PatientInsuranceEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const users = useQueryResponseData()

  const selectedInsurance = (users as any[]).find((item: any) => item.id === itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getIPatientInsuranceById(selectedInsurance?.patient_id?.id, itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (user && user['starting_date']) {
    const formatStartingDate = moment(user.starting_date).format('DD-MM-YYYY')
    user['starting_date'] = formatStartingDate
  }
  if (user && user['ending_date']) {
    const formatStartingDate = moment(user.ending_date).format('DD-MM-YYYY')
    user['ending_date'] = formatStartingDate
  }

  if (!itemIdForUpdate) {
    return <PatientInsuranceEditModalForm isUserLoading={isLoading} user={{id: undefined}} />
  }

  if (!isLoading && !error && user) {
    return <PatientInsuranceEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export {PatientInsuranceEditModalFormWrapper}
