import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {PatientInsurance, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const PATIENT_INSURANCE_URL = `${API_URL}/patient-insurance`
const GET_PATIENT_INSURANCE_URL = `${API_URL}/patient-insurance/`
const GET_PATIENT_LIST =`${API_URL}/patient`

export  const getPatientList = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_PATIENT_LIST}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getPatientInsuranceList = (query: string,id:number): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_PATIENT_INSURANCE_URL}?${query ? `${query}&hospital_id=${id}` : `hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}


const getIPatientInsuranceById = (patient_id: number,ins_id:ID): Promise<PatientInsurance | undefined> => {
  return axios
  .get(`${PATIENT_INSURANCE_URL}/${patient_id}/insurance?insurance_id=${ins_id}`)
    .then((response: AxiosResponse<Response<PatientInsurance>>) => response.data)
    .then((response: Response<PatientInsurance>) => response.data)
}

const createPatientInsurance = (user: any): Promise<PatientInsurance | undefined> => {
  return axios
    .post(PATIENT_INSURANCE_URL, user)
}

const updatePatientInsurance = (id: any,user: any): Promise<PatientInsurance | undefined> => {
  
  return axios
    .patch(`${PATIENT_INSURANCE_URL}/${id}`, user)
}

const deletePatientInsuranceDoc = (Id: ID): Promise<void> => {
  return axios.delete(`${PATIENT_INSURANCE_URL}/${Id}`,{data: { id: [Id]}}).then(() => {})
}

export {getPatientInsuranceList, getIPatientInsuranceById, createPatientInsurance, updatePatientInsurance,deletePatientInsuranceDoc}
