import {useEffect, useRef, useState} from 'react'
import Slider from 'react-slick'
import {KTSVG} from '../../../_jmh/helpers'
import {getLandingreviewData} from './core/_request'
import {useIntl} from 'react-intl'

const LandingTestimonials = () => {
  const sliderRef = useRef<Slider | null>(null)
  const intl = useIntl()
  const [reviewData, setReviewData] = useState<any>(null)

  const fetchReviewData = () => {
    getLandingreviewData().then((res) => {
      setReviewData(res?.data?.data)
    })
  }
  useEffect(() => {
    fetchReviewData()
  }, [])

  var settings = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    centerPadding: '50px',
    centerMode: true,
    className: 'center',
  }

  const generateStars = (value: number) => {
    const stars = []
    for (let i = 1; i <= 5; i++) {
      const starClass = i <= value ? 'fas' : 'fas f-regular'
      stars.push(<i key={i} className={`${starClass} fa-star`} title={i.toString()}></i>)
    }
    return stars
  }

  const getAvatarContent = (fullName: string) => {
    const initials = fullName
      .split(' ')
      .map((name) => name.charAt(0))
      .join('')
      .toUpperCase()
    return <div className='testi_avatar_name'>{initials}</div>
  }

  return (
    <section className='landing_testimonials section-padding'>
      <div className='landing_container'>
        <div className='lt_container'>
          <div className='row'>
            <div className='col-8'>
              <div className='row'>
                <Slider ref={sliderRef} {...settings}>
                  {reviewData?.map((testimonial: any, index: any) => (
                    <div key={index} className='col-4'>
                      <div className='lt_item'>
                        <div className='lt_testi_card'>
                          <div className='card paper'>
                            <p>
                              {testimonial?.description.split(' ').slice(0, 15).join(' ')}
                              {testimonial?.description.split(' ').length > 15 ? ' ...' : ''}
                            </p>
                            <div className='rating'>{generateStars(testimonial?.rating)}</div>
                          </div>
                          <div className='person'>
                            <div className='name'>
                              <h6 className='text-capitalize'>{testimonial?.full_name}</h6>
                            </div>
                            <div className='avatar-img avatar'>
                              {getAvatarContent(testimonial?.full_name)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className='col-4'>
              <div className='position-relative h-100'>
                <div className='of_right'></div>
                <div className='of_content position-relative'>
                  <KTSVG path='/media/landing_asset/of_icon.svg' />
                  <h3>{intl.formatMessage({id: 'LANDING_PAGE.TESTIMONIALS_TITLE'})}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingTestimonials
