import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import { DoctorsQueryResponse } from '../../../Doctor/DoctorList/core/_models'
import { HospitalRoomQueryResponse } from '../../../HospitalRoom/HospitalRoomList/core/_models'
import { PatientsQueryResponse } from '../../../Patient-management/PatientList/core/_models'
import {ATD, dischargeDate, TransferData, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL 
const USER_URL = `${API_URL}/user`

const PATIENT_URL = `${API_URL}/patient`
const GET_DOCTOR_URL = `${API_URL}/doctor`
const GET_ROOM_URL = `${API_URL}/hospital-room`

const ATD_URL = `${API_URL}/ad-patient`
const ATD_TRANSFER_URL = `${API_URL}/ad-patient/transfer`
const ATD_DISCHARGE_URL = `${API_URL}/ad-patient/discharge`

const getAtdPatient = (query: string,id:number): Promise<UsersQueryResponse> => {
  return axios
    // .get(`${ATD_URL}?${query}`)
    .get(`${ATD_URL}?${query ? `${query}&hospital_id=${id}` : `hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getAtdPatienById = (id: ID): Promise<ATD | undefined> => {
  return axios
    .get(`${ATD_URL}/${id}`)
    .then((response: AxiosResponse<Response<ATD>>) => response.data)
    .then((response: Response<ATD>) => response.data)
}

const createAdtPatient = (user: ATD): Promise<ATD | undefined> => {
  return axios
    .post(ATD_URL, user)
}

const updateAtdPatient = (user: ATD): Promise<ATD | undefined> => {
  return axios
    .patch(`${ATD_URL}/${user.id}`, user)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getPatientsforAppointment = (query?: string): Promise<PatientsQueryResponse> => {
  return axios
    .get(`${PATIENT_URL}`)
    .then((d: AxiosResponse<PatientsQueryResponse>) => d.data)
}

const getDoctors = (query?: string): Promise<DoctorsQueryResponse> => {
  return axios
    .get(`${GET_DOCTOR_URL}`)
    .then((d: AxiosResponse<DoctorsQueryResponse>) => d.data)
}

const getHospitalRoom = (query?: string): Promise<HospitalRoomQueryResponse> => {
  return axios
    .get(`${GET_ROOM_URL}`)
    .then((d: AxiosResponse<HospitalRoomQueryResponse>) => d.data)
}

const getAtdTransferPatienById = (user: TransferData, id?: any): Promise<TransferData | undefined> => {
  return axios
    .patch(`${ATD_TRANSFER_URL}/${id}`, user)
}


const getAtdDidchargePatienById = (user: dischargeDate, id?: any): Promise<dischargeDate | undefined> => {
  return axios
    .patch(`${ATD_DISCHARGE_URL}/${id}`, user)
}

// const getAtdTransferPatienById = (id: ID): Promise<TransferData | undefined> => {
//   return axios
//     .patch(`${ATD_TRANSFER_URL}/${id}`)
//     .then((response: AxiosResponse<Response<TransferData>>) => response.data)
//     .then((response: Response<TransferData>) => response.data)
// }


export {getAtdPatient, deleteUser, deleteSelectedUsers, getAtdPatienById, createAdtPatient, updateAtdPatient,getPatientsforAppointment,getDoctors,getHospitalRoom,getAtdTransferPatienById ,getAtdDidchargePatienById}
