/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {deleteStaff} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import Swal from 'sweetalert2'
import {Staff} from '../../core/_models'
import {useIntl} from 'react-intl'
type Props = {
  id: ID
  user: Staff
}
const StaffActionsCell: FC<Props> = ({id, user}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {loginData} = useContext(loginContext)
  const pagination = useQueryResponsePagination()
  const [setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const [isDelete, setIsDelete] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const page = pagination?.pagination?.meta?.currentPage || 1

  // const deleteItem = useMutation(() => deleteStaff(id), {
  //   onSuccess: async () => {
  //    await queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //    if (page > 1) {
  //     const updatedData = await queryClient.getQueryData<any>([`${QUERIES.USERS_LIST}-${query}`]);
  //     if (updatedData?.data?.length > 0) {
  //       setCurrentPage(page);
  //     } else {
  //       setCurrentPage(page - 1);
  //       updateState({ page: page - 1, items_per_page: pagination?.pagination?.meta?.items_per_page || 10 });
  //     }
  //   }
  //   toast.error('Staff Deleted');
  //   },
  // })

  const handleRemove = (id: any) => {
    if (id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          title: `Are you sure you want to delete staff ?`,
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Close',
          reverseButtons: true,
        })
        .then(async (result: any) => {
          if (result.isConfirmed) {
            try {
              await deleteStaff(id)
              swalWithBootstrapButtons.fire('Deleted!', `Staff has been deleted.`, 'success')
              setIsDelete((val) => !val)
              if (page > 1) {
                const updatedData = await queryClient.getQueryData<any>([
                  `${QUERIES.USERS_LIST}-${query}`,
                ])
                if (updatedData?.data?.length > 0) {
                  setCurrentPage(page)
                } else {
                  setCurrentPage(page - 1)
                  updateState({
                    page: page - 1,
                    items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
                  })
                }
              }
            } catch (error) {
              swalWithBootstrapButtons.fire('Error', 'Failed to delete Staff', 'error')
            }
          }
        })
    }
  }

  useEffect(() => {
    refetch()
  }, [refetch, isDelete])

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'staff') {
      return item?.module_id
    }
  })
  // eslint-disable-next-line array-callback-return
  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })
  // eslint-disable-next-line array-callback-return
  const view = data?.filter((item: any) => {
    if (item.permission.route === ':id' && item.permission.method === 'GET') {
      return item
    }
  })
  // eslint-disable-next-line array-callback-return
  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE') {
      return item
    }
  })
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {edit.length ? (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </a>
          </div>
        ) : (
          ''
        )}
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {view.length ? (
          <div className='menu-item px-3'>
            <Link to={`/staff-management/staff-view/${id}`} className='menu-link px-3'>
              {intl.formatMessage({id: 'GENERAL.VIEW'})}
            </Link>
          </div>
        ) : (
          ''
        )}
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {deleteData.length ? (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              // onClick={async () => await deleteItem.mutateAsync()}
              onClick={() => handleRemove(user?.id)}
            >
              {intl.formatMessage({id: 'GENERAL.DELETE'})}
            </a>
          </div>
        ) : (
          ''
        )}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}
export {StaffActionsCell}
