// @ts-nocheck
import {Column} from 'react-table'
import {PatientInsuranceSubcriberNameCell} from './PatientInsuranceSubcriberNameCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {PatientInsuranceActionsCell} from './PatientInsuranceActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {PatientInsuranceCustomHeader} from './PatientInsuranceCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {PatientInsurance} from '../../core/_models'
import {PatientNameCell} from './PatientNameCell'
import {PatientInsuranceCompanyName} from './PatientInsuranceCompanyName'
import {PatientInsuranceType} from './PatientInsuranceType'
import {PatientInsurancePolicyNumber} from './PatientInsurancePolicyNumber'
import {PatientInsurancestartDate} from './PatientInsuranceStartDate'
import {PatientInsuranceEndDate} from './PatientInsuranceEndDate'
import {PatientInsuranceUpload} from './PatientInsuranceUpload'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<PatientInsurance>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientInsuranceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.PATIENT.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'patient_id',
    Cell: ({...props}) => <PatientNameCell user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <PatientInsuranceCustomHeader
  //       tableProps={props}
  //       title='Subcriber Name'
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'subscriber_name',
  //   Cell: ({...props}) => <PatientInsuranceSubcriberNameCell user={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientInsuranceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.COMPANY.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'company_name',
    Cell: ({...props}) => <PatientInsuranceCompanyName user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientInsuranceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.TYPE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'type',
    Cell: ({...props}) => <PatientInsuranceType user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientInsuranceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.POLICY.NUMBER'})}
          className='min-w-125px'
        />
      )
    },
    id: 'policy_number',
    Cell: ({...props}) => <PatientInsurancePolicyNumber user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientInsuranceCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DOCUMENTS'})}
          className='min-w-125px'
        />
      )
    },
    id: 'insurance_upload',
    Cell: ({...props}) => <PatientInsuranceUpload user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'patient-insurance') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (item.permission.method === 'PATCH') {
          return item
        }
      })
      return (
        Get?.length > 0 && (
          <PatientInsuranceCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px pe-5'
          />
        )
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'patient-insurance') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (item.permission.method === 'PATCH') {
          return item
        }
      })

      return Get?.length > 0 && <PatientInsuranceActionsCell id={props.data[props.row.index].id} />
    },
  },
]

export {usersColumns}
