import { ID, Response } from "../../../../../_jmh/helpers"

export type ATD = {
  id?: ID
  hospital_id?: number;
  patient_id?: number;
  reason?: string;
  admission_date?: string;
  hospital_room_id?: number;
  discharge_date?: string;
  doctor_id?: number;
  patient?:PatientData
  ad_patient_transfer?:AdmissionRecord[]
  hospital?:HospitalData
}

interface PatientData {
  id: string;
  hospital_id: string;
  full_name: string;
  gender: "MALE" | "FEMALE" | "OTHER";
  contact_number: string;
  date_of_birth: string;
  permanent_address: string | null;
  email: string | null;
  alternate_contact_number: string | null;
  blood_group: string;
  status: "ACTIVE" | "INACTIVE";
  created_at: string;
  updated_at: string;
}

interface AdmissionRecord {
  id: string;
  ad_patient_id: string;
  hospital_room_id: string;
  is_occupied: boolean;
  doctor_id: string;
  admission_date: string;
  discharge_date:string;
  transfer_date: string | null;
  transfer_reason: string | null;
  created_at: string;
  updated_at: string;
  doctor: {
    id: string;
    full_name: string;
    gender: "MALE" | "FEMALE" | "OTHER";
    image: string;
    date_of_birth: string;
    permanent_address: string;
    contact_number: string;
    visiting_doctor: boolean;
    qualification: string;
    years_of_experience: number;
    user_id: string;
    speciality_id: string;
    status: "ACTIVE" | "INACTIVE";
    created_at: string;
    updated_at: string;
  };
  hospital_room: {
    id: string;
    room_number: string;
    type: string;
    description: string;
    cost: number;
    maximum_capacity: number;
    availability_status: "AVAILABLE" | "UNAVAILABLE";
    status: "ACTIVE" | "INACTIVE";
    created_at: string;
    updated_at: string;
  };
}

interface HospitalData {
  id: string;
  name: string;
  address: string;
  contact_person: string;
  contact_number: string;
  status: "ACTIVE" | "INACTIVE";
  created_at: string;
  updated_at: string;
}


export type TransferData = {
  transfer_reason?: string;
  transfer_date?: string;
  hospital_room_id?: number;
  doctor_id?: number;
}


export type dischargeDate = {
  discharge_date?: string
}


export type UsersQueryResponse = Response<Array<ATD>>

export const initialUser: ATD = {
  reason: '',
}
