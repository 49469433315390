import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {deleteSelectedNotification, updateNotification} from '../../core/_requests'
import {FC, useContext, useEffect, useState} from 'react'
import {loginContext} from '../../../../../context/context'
import {toast} from 'react-toastify'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {updatedNewNotification} from '../../../../../../_jmh/partials/content/dropdown/core/request'
import {useIntl} from 'react-intl'
type Props = {
  notification: any
}

const UsersListGrouping: FC<Props> = ({notification}) => {
  const intl = useIntl()
  const {selected, clearSelected} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {readNotification} = useContext(loginContext)
  const {refetch} = useQueryResponse()
  const [deleteMultiData, setDeleteMultiData] = useState(false)
  const pagination = useQueryResponsePagination()
  const [currentPage, setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const page = pagination?.pagination?.meta?.currentPage || 1
  const queryKey = `${QUERIES.USERS_LIST}-${query}-${readNotification}`

  const deleteSelectedItems = useMutation(
    () =>
      deleteSelectedNotification(selected).then((res: any) => {
        toast.error(res?.data?.message)
        clearSelected()
        refetch()
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKey)
        if (page > 1) {
          const updatedData = await queryClient.getQueryData<any>(queryKey)
          if (updatedData?.data?.length > 0) {
            setCurrentPage(page)
          } else {
            setCurrentPage(page - 1)
            updateState({
              page: page - 1,
              items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
            })
          }
        }
        setDeleteMultiData(!deleteMultiData)
      },
    }
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleNotificationClick = async (notification: any) => {
    if (!notification.is_read) {
      await updateNotification(selected)
      await updatedNewNotification([selected]) // Store the selected notification
      clearSelected()
      refetch() // Fetch the updated notifications
    }
  }

  return (
    <div className='d-flex gap-3 justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span>
        {intl.formatMessage({id: 'NOTIFICATION.SELECTED'})}
      </div>

      <button
        type='button'
        className='btn btn-success'
        onClick={() => handleNotificationClick(selected)}
      >
        {intl.formatMessage({id: 'NOTIFICATION.READ.NOTIFICATION'})}
      </button>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        {intl.formatMessage({id: 'NOTIFICATION.DELETE.SELECTED'})}
      </button>
    </div>
  )
}

export {UsersListGrouping}
