import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import TagsInput from 'react-tagsinput'

const Diagnosis = () => {
  const intl = useIntl()
  const [diagnosisTags, setDiagnosisTags] = useState<any>([])
  const [diagnosisInputValue, setDiagnosisInputValue] = useState<any>('')

  const defaultDiagnosisMain = [
    'Allergic rhinitis',
    'Viral Fever',
    'Fever',
    'Cough',
    'Fever with rigors',
    'Rigor',
    'Sinusities',
    'Headache',
    'Weakness',
    'Food poisoning',
    'Acute sinusities',
    'Pneumonia',
  ]
  const [defaultDiagnosisHistory, setDefaultDiagnosisHistory] = useState<any>(defaultDiagnosisMain)

  useEffect(() => {
    const prescriptionData = sessionStorage.getItem('prescriptionData')
    if (prescriptionData) {
      const parsedPrescriptionData = JSON.parse(prescriptionData)
      if (parsedPrescriptionData.diagnosis) {
        setDiagnosisTags(parsedPrescriptionData.diagnosis)
      }
    }
  }, [])

  const handleDiagnosisHisoryTagRemove = (tag: any) => {
    setDiagnosisTags(diagnosisTags.filter((t: any) => t !== tag))
    if (defaultDiagnosisMain.includes(tag)) {
      const updatedDefaultDiagnosisHistory = Array.from(new Set([...defaultDiagnosisHistory, tag]))
      setDefaultDiagnosisHistory(updatedDefaultDiagnosisHistory)
    }
  }
  const defaultDiagnosisHistoryFiltered = defaultDiagnosisHistory.filter(
    (value: any) => !diagnosisTags.includes(value)
  )

  const handleDiagnosisHistoryTagClick = (tag: any) => {
    setDiagnosisTags([...diagnosisTags, tag])
    setDefaultDiagnosisHistory(defaultDiagnosisHistory.filter((value: any) => value !== tag))
  }

  const handleDiagnosisInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnosisInputValue(e.target.value)
  }

  const handleDiagnosisHistoryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (diagnosisInputValue.trim() !== '') {
        setDiagnosisTags([...diagnosisTags, diagnosisInputValue.trim()])
        setDiagnosisInputValue('')
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    sessionStorage.setItem(
      'prescriptionData',
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('prescriptionData') || '{}'),
        diagnosis: diagnosisTags,
      })
    )
  }, [diagnosisTags])

  return (
    <div>
      <h4>
        {intl.formatMessage({
          id: 'PRESCRIPTION_DIAGNOSIS_TITLE',
        })}
      </h4>
      <div className='fv-row mb-2 col-12'>
        <TagsInput
          value={diagnosisTags}
          onChange={(newTags) => {
            setDiagnosisTags(newTags)
          }}
          tagProps={{
            className:
              'react-tagsinput-tag btn btn-sm btn-light-primary btn-active-light-primary shadow',
            onRemove: (index: any) => handleDiagnosisHisoryTagRemove(diagnosisTags[index]),
          }}
          inputProps={{
            placeholder: intl.formatMessage({
              id: 'GENERAL.ADD.DIAGNOSIS',
            }),
            value: diagnosisInputValue,
            onChange: handleDiagnosisInputChange,
            onKeyDown: handleDiagnosisHistoryKeyDown,
          }}
          className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
        />

        <div className='preMedicalValueWrapper my-5'>
          {defaultDiagnosisHistoryFiltered?.map((value: any) => (
            <span
              className='medicalValueTag btn btn-sm'
              key={value}
              onClick={() => handleDiagnosisHistoryTagClick(value)}
            >
              {value}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Diagnosis
