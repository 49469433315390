import {AssetMaintenanceListToolbar} from './AssetMaintenanceListToolbar'
import {AssetMaintenanceListSearchComponent} from './AssetMaintenanceListSearchComponent'

const AssetMaintenanceListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <AssetMaintenanceListSearchComponent />
      <div className='card-toolbar'>
        <AssetMaintenanceListToolbar />
      </div>
    </div>
  )
}

export {AssetMaintenanceListHeader}
