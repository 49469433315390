import {KTCard} from '../../../../_jmh/helpers'
import {DoctorsListHeader} from './components/header/DoctorsListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {DoctorEditModal} from './doctor-edit-modal/DoctorEditModal'
import {DoctorsTable} from './table/DoctorsTable'

const DoctorsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <DoctorsListHeader />
        <DoctorsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <DoctorEditModal />}
    </>
  )
}

const DoctorListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DoctorsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DoctorListWrapper}
