/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useContext, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_jmh/layout/core'
import {loginContext} from '../../../context/context'
import {MedicationListWrapper} from '../../medications/medicationList/MedicationList'
import {MedicalBackgroundListWrapper} from '../../Patient-Medical-background/MedicalBackgroundList/MedicalBackgroundList'
import {MedicalNotesListWrapper} from '../../Patient-Medical-Notes/MedicalNotesList/MedicalNotesListWrapper'
import {PatientUploadDocListWrapper} from '../../Patient-Upload-Documents/PatientUploadDocList/PatientUploadDocList'
import {PatientVitalListWrapper} from '../../Patient-vitals/PatientVitalsList/PatientVitalList'
import Select from 'react-select'
import {getPatientName} from '../../Patient-Billing/PatientBillingList/core/_requests'
import {Patient} from '../PatientList/core/_models'
import {useIntl} from 'react-intl'
import {getAppointmentByPatientId} from '../PatientList/core/_requests'

const PatientDetails: React.FC<any> = () => {
  const intl = useIntl()
  // const [pageTitle, setPageTitle] = useState('Medications')
  const location = useLocation()
  const [isTabChange, setIsTabChange] = useState(false)
  const {selectedPatientTab, setSelectedPatientTab, selectedAppointment, setSelectedAppointment} =
    useContext(loginContext)
  const [tab, setTab] = useState(selectedPatientTab)
  const [patientData, setPatientData] = useState<Patient[]>([])
  const [appointmentList, setAppointmentList] = useState<any>([])
  const {selectedPatient, setSelectedPatient} = useContext(loginContext)
  const {selectedPatientId, setSelectedPatientId, selectedHospitalId} = useContext(loginContext)

  useEffect(() => {
    setSelectedPatientTab(tab)
  }, [tab, isTabChange])

  // const allowedTabs = [
  //   {value: 'Medications', label: 'Medications'},
  //   {value: 'Attachments', label: 'Attachments'},
  //   {value: 'MedicalNotes', label: 'Medical Notes'},
  //   {value: 'PatientVital', label: 'Patient Vitals'},
  //   {value: 'MedicalBackground', label: 'Medical Background'},
  // ]

  const fetchPatient = async () => {
    const allPatientData: any = await getPatientName(selectedHospitalId)

    const sortedPatients = allPatientData?.data.sort((a: any, b: any) => {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    })

    if (sortedPatients?.length > 0) {
      setSelectedPatient(
        sortedPatients[0]
          ? {value: sortedPatients[0]?.id, label: sortedPatients[0]?.full_name}
          : null,
        // handlePatientIdChange(sortedPatients[0].id)
        setSelectedPatientId(sortedPatients[0]?.id)
      )
      if (
        sortedPatients[0].label !== selectedPatient?.label &&
        sortedPatients[0].value !== selectedPatient?.value
      ) {
        setSelectedPatient({
          value: selectedPatient?.value,
          label: selectedPatient?.label,
        })
        // handlePatientIdChange(selectedPatientId)
        setSelectedPatientId(sortedPatients[0].id)
      }
      setPatientData(allPatientData?.data)
    } else {
      setSelectedPatient('')
    }
  }

  const fetchAppointments = () => {
    getAppointmentByPatientId(selectedPatient?.value).then((res: any) => {
      const formattedAppointments = [
        {value: 'all', label: 'All'},
        ...res.data.map((appointment: any) => ({
          value: appointment.id,
          label: `${appointment.id}(${appointment.description})`,
        })),
      ]

      setAppointmentList(formattedAppointments)
      setSelectedAppointment(formattedAppointments[0])
    })
  }

  useEffect(() => {
    fetchPatient()
  }, [])

  useEffect(() => {
    if (selectedPatient) {
      fetchAppointments()
    }
  }, [selectedPatient])

  const handleChange = (selectedOption: any) => {
    setSelectedPatient(selectedOption as Patient | null)

    // if (selectedOption) {
    //   const id = selectedOption.value
    //   handlePatientIdChange(id)
    // }

    setSelectedPatientId(selectedOption?.value)
  }

  const handleAppointmentChange = (selectedOption: any) => {
    setSelectedAppointment(selectedOption)
  }

  // const handlePatientIdChange = (id: any) => {
  //   setSelectedPatientId(id)
  // }

  const {loginData} = useContext(loginContext)

  function checkPermission(
    loginData: any,
    moduleName: string,
    route?: string,
    method?: string
  ): boolean {
    const permissions = loginData?.data?.permissions

    const modulePermissions = permissions?.filter((permission: any) => {
      if (route && method) {
        return (
          permission?.module?.name === moduleName &&
          permission?.permission?.route === route &&
          permission?.permission?.method === method
        )
      }
      return permission?.module?.name === moduleName
    })
    return modulePermissions?.length > 0
  }

  const defaultAllowTabs = [
    'Medications',
    'Medical-Notes',
    'Patient-Vital',
    'Medical-Background',
    'Attachments',
  ]

  const [allowedTabs, setAllowedTabs] = useState<string[]>(defaultAllowTabs)

  useEffect(() => {
    const tabFromUrl = location.pathname.split('/').pop()

    if (tabFromUrl) {
      const formattedTab = tabFromUrl
        .split('-')
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join('-')

      if (allowedTabs.includes(formattedTab)) {
        setTab(formattedTab)
        setSelectedPatientTab(formattedTab)
      }
    }
  }, [allowedTabs, location.pathname, setSelectedPatientTab])

  const hasPermission1 = checkPermission(loginData, 'patient-medication')
  const hasPermission2 = checkPermission(loginData, 'patient-medical-notes')
  const hasPermission3 = checkPermission(loginData, 'patient-vitals')
  const hasPermission4 = checkPermission(loginData, 'patient-medical-background')
  const hasPermission5 = checkPermission(loginData, 'patient')

  useEffect(() => {
    const updatedAllowedTabs = []

    if (hasPermission1) {
      updatedAllowedTabs.push('Medications')
    }
    if (hasPermission2) {
      updatedAllowedTabs.push('Medical-Notes')
    }
    if (hasPermission3) {
      updatedAllowedTabs.push('Patient-Vital')
    }
    if (hasPermission4) {
      updatedAllowedTabs.push('Medical-Background')
    }
    if (hasPermission5) {
      updatedAllowedTabs.push('Attachments')
    }

    setAllowedTabs(updatedAllowedTabs)

    if (selectedPatientTab === null && updatedAllowedTabs.length > 0) {
      setTab(updatedAllowedTabs[0])
      setSelectedPatientTab(updatedAllowedTabs[0])
    }

    // setTab(updatedAllowedTabs[0])
    // setPageTitle(updatedAllowedTabs[0])
  }, [
    hasPermission1,
    hasPermission2,
    hasPermission3,
    hasPermission4,
    hasPermission5,
    defaultAllowTabs,
  ])

  const handleTab = (tabTitle: any, Headertitle: any) => {
    setTab(tabTitle)

    setSelectedPatientTab(Headertitle)

    setIsTabChange((val) => !val)
  }

  return (
    <>
      <div className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'>
        <div className='card-header'>
          <div className='card-toolbar w-100 justify-content-between'>
            <div className=' d-flex gap-3 patient-select'>
              <label className='d-flex align-items-center fw-bold fs-5'> Selected Patient : </label>
              <Select
                placeholder='Select Patient'
                className='w-250px'
                options={
                  patientData.map((patient) => ({
                    value: patient?.id,
                    label: patient?.full_name,
                  })) as readonly Patient[]
                }
                onChange={handleChange}
                value={selectedPatient}
                defaultValue={selectedPatient}
              />
            </div>
            <div className=' d-flex gap-3 patient-select'>
              <label className='d-flex align-items-center fw-bold fs-5'>Select Appointment :</label>
              <Select
                placeholder='Appointmemt'
                className='w-250px'
                options={appointmentList}
                onChange={handleAppointmentChange}
                value={selectedAppointment}
                defaultValue={selectedAppointment}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card card-custom shadow'>
        <div className='card-header overflow-auto'>
          <ul
            className='card-toolbar nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            {allowedTabs.map((allowedTab) => (
              <li key={allowedTab} className='nav-item'>
                <Link to={`/patient-data/patient-details/${allowedTab.toLowerCase()}`}>
                  <span
                    className={clsx('nav-link cursor-pointer', {
                      active: selectedPatientTab === allowedTab,
                    })}
                    onClick={() => handleTab(allowedTab, allowedTab)}
                    role='tab'
                  >
                    {allowedTab?.replace(/-/g, ' ')}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
          <div className='card-toolbar'>
            <a
              type='button'
              className='btn btn-sm btn-primary'
              onClick={() => window.history.back()}
            >
              {intl.formatMessage({id: 'GENERAL.BACK'})}
            </a>
          </div>
        </div>
        <div className=''>
          <PageTitle breadcrumbs={[]}>{selectedPatientTab?.replace(/-/g, ' ')}</PageTitle>
          <div className='card-body'>
            <div className='tab-content'>
              <div className={clsx('tab-pane', {active: selectedPatientTab === 'Medications'})}>
                <MedicationListWrapper />
              </div>
              <div className={clsx('tab-pane', {active: selectedPatientTab === 'Medical-Notes'})}>
                <MedicalNotesListWrapper />
              </div>
              <div className={clsx('tab-pane', {active: selectedPatientTab === 'Attachments'})}>
                <PatientUploadDocListWrapper />
              </div>
              <div className={clsx('tab-pane', {active: selectedPatientTab === 'Patient-Vital'})}>
                <PatientVitalListWrapper />
              </div>
              <div
                className={clsx('tab-pane', {active: selectedPatientTab === 'Medical-Background'})}
              >
                <MedicalBackgroundListWrapper />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {PatientDetails}
