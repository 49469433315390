import {AtdListToolbar} from './AtdListToolbar'
import {AtdsListSearchComponent} from './AtdsListSearchComponent'

const AtdsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <AtdsListSearchComponent />
      <div className='card-toolbar'>
        <AtdListToolbar />
      </div>
    </div>
  )
}

export {AtdsListHeader}
