import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ADD_HOSPITAL = `${API_URL}/hospital`
export const GET_HOSPITAL =  `${API_URL}/hospital`

  // Add Hospital API
  export function AddHospital(
    name: string,
    address: string,
    contact_person: string,
    contact_number: string,
    token: string
  ) {
    return axios.post(ADD_HOSPITAL, {
       headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      name,
      address,
      contact_person,
      contact_number,
    })
  }

  export function GetHospitals(){
    return axios.get(GET_HOSPITAL,{
    })
  }

  export const GetHospitalById = (id :number)  => {
    return axios
      .get(`${GET_HOSPITAL}/${id}`)
      .then((response) => response.data).then(response => response.data) 
  }
  export function UpdateHospital(data: FormData, id: number, token: string) {
    return axios.patch(`${API_URL}/hospital/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // Set the Content-Type header for FormData
      },
      transformRequest: [(data) => data], // Use a custom transformRequest function to prevent Axios from automatically converting the FormData object
    })
  }
  


  

  