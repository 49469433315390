import React from 'react'

import {PageTitle} from '../../../_jmh/layout/core'

import {useIntl} from 'react-intl'
import {OpOverview} from './profile/components/OpOverview'
import OpLayout from '../outer-patient-modules/OpLayout'

const OpMyProfileWrapper = () => {
  return (
    <OpLayout>
      <OpOverview />
    </OpLayout>
  )
}

const OpProfileWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'GENERAL.PROFILE'})}</PageTitle>
      <OpMyProfileWrapper />
    </>
  )
}
export {OpProfileWrapper}
