/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {deleteNotification, updateNotification} from '../../core/_requests'
import {toast} from 'react-toastify'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {loginContext} from '../../../../../context/context'
import {updatedNewNotification} from '../../../../../../_jmh/partials/content/dropdown/core/request'

type Props = {
  id: ID
}

const UserActionsCell: FC<Props> = ({id}) => {
  const queryClient = useQueryClient()
  const pagination = useQueryResponsePagination()
  const [currentPage, setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()
  const {readNotification} = useContext(loginContext)
  const [deleteSingleData, setDeleteSingleData] = useState(false)
  const {refetch} = useQueryResponse()
  const {query} = useQueryResponse()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const page = pagination?.pagination?.meta?.currentPage || 1
  const queryKey = `${QUERIES.USERS_LIST}-${query}-${readNotification}`

  const deleteItem = useMutation(
    () =>
      deleteNotification(id).then((res: any) => {
        toast.error(res?.data?.message)
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKey)
        if (page > 1) {
          const updatedData = await queryClient.getQueryData<any>(queryKey)
          if (updatedData?.data?.length > 0) {
            setCurrentPage(page)
          } else {
            setCurrentPage(page - 1)
            updateState({
              page: page - 1,
              items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
            })
          }
        }
        setDeleteSingleData(!deleteSingleData)
      },
    }
  )

  const handleNotificationClick = async (notification: any) => {
    if (!notification.is_read) {
      await updateNotification(id)
      await updatedNewNotification([id]) // Store the selected notification
      refetch() // Fetch the updated notifications
    }
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <>
      <div className='d-flex gap-2 '>
        <a
          className={`menu-link px-3 btn  btn-sm btn-light-success btn-active-success`}
          data-kt-users-table-filter='delete_row'
          onClick={() => handleNotificationClick(id)}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen075.svg'
            className='svg-icon-muted m-0 fs-3'
          />
        </a>

        <a
          className='menu-link px-3 btn  btn-sm btn-light-danger btn-active-danger '
          data-kt-users-table-filter='delete_row'
          onClick={async () => await deleteItem.mutateAsync()}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-muted m-0 fs-3'
            data-kt-users-table-filter='delete_row'
          />
        </a>
      </div>
    </>
  )
}

export {UserActionsCell}
