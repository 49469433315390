// @ts-nocheck
import {useContext} from 'react'
import {Column} from 'react-table'
import {loginContext} from '../../../../../context/context'
import {Staff} from '../../core/_models'
import {StaffActionsCell} from './StaffActionsCell'
import {StaffCustomHeader} from './StaffCustomHeader'
import {StaffEmailCell} from './StaffEmailCell'
import {StaffInfoCell} from './StaffInfoCell'
import {StaffRoleCell} from './StaffRoleCell'
import {StaffStatusCell} from './StaffStatusCell'
import {StaffContactNumber} from './StaffContactNumber'
import {useIntl} from 'react-intl'

const StaffColumns: Array<Column<Staff>> = [
  {
    Header: (props) => {
      const intl = useIntl()

      return (
        <StaffCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'full_name',
    Cell: ({...props}) => <StaffInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <StaffCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
          className='min-w-125px'
        />
      )
    },
    id: 'contact_number',
    Cell: ({...props}) => <StaffContactNumber user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <StaffCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.EMAIL'})}
          className='min-w-125px'
        />
      )
    },
    id: 'user',
    Cell: ({...props}) => <StaffEmailCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <StaffCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ROLE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'role',
    Cell: ({...props}) => <StaffRoleCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <StaffCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.STATUS'})}
          className='min-w-30px'
        />
      )
    },
    id: 'status',
    Cell: ({...props}) => <StaffStatusCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'staff') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <StaffCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'staff') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? (
        <StaffActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />
      ) : null
    },
  },

  // {
  //   Header: (props) => {
  //     const {loginData} = useContext(loginContext)

  //     const data1: any = loginData?.data?.permissions?.filter((item: any) => {
  //       if (item.module.name === 'Staff') {
  //         return item?.module_id
  //       }
  //     })

  //     const Get = data1?.filter((item: any) => {
  //       if (
  //         (item.permission.method === 'GET' && item.permission.route === ':id') ||
  //         item.permission.method === 'POST' ||
  //         item.permission.method === 'PATCH' ||
  //         item.permission.method === 'DELETE'
  //       ) {
  //         return item
  //       }
  //     })
  //     return Get.length ? (
  //       ''
  //     ) : (
  //       <StaffCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //     )
  //   },
  //   id: 'actions',
  //   Cell: ({...props}) => {
  //     const {loginData} = useContext(loginContext)

  //     const data1: any = loginData?.data?.permissions?.filter((item: any) => {
  //       if (item.module.name === 'Staff') {
  //         return item?.module_id
  //       }
  //     })

  //     const Get = data1?.filter((item: any) => {
  //       if (
  //         (item.permission.method === 'GET' && item.permission.route === ':id') ||
  //         item.permission.method === 'POST' ||
  //         item.permission.method === 'PATCH' ||
  //         item.permission.method === 'DELETE'
  //       ) {
  //         return item
  //       }
  //     })

  //     return Get.length ? <StaffActionsCell id={props.data[props.row.index].id} /> : null
  //   },
  // },
]

export {StaffColumns}
