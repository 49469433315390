import {useQuery} from 'react-query'
import {PatientBillingEditModalForm} from './PatientBillingEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getPatientBillingById} from '../core/_requests'

const PatientBillingEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getPatientBillingById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  // if (!itemIdForUpdate) {
  //   return <PatientBillingEditModalForm isUserLoading={isLoading} user={{bill_number: undefined}} />
  // }

  if (!isLoading && !error && user) {
    return <PatientBillingEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export {PatientBillingEditModalFormWrapper}
