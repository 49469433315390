import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import DocInfoHeader from './Infolist/components/header/DocInfoHeader'

const DoctorInfoWrapper = () => {
  const intl = useIntl()

  return (
    <div className='doctorinfo_back'>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'GENERAL.ALL_APPOINTMENTS'})}</PageTitle>
      <DocInfoHeader />
    </div>
  )
}

export default DoctorInfoWrapper
