import React, {useContext, useEffect, useState} from 'react'
import {getPastVisitsData} from '../core/_request'
import {DpContext} from '../DpLayoutWrapper'
// import SubmitConsultation from './SubmitConsultation'

const PastPrescriptionPreview = ({appointmentId, handlePreviewClose}: any) => {
  const [prescriptionData, setPrescriptionData] = useState<any>([{}])
  // const {selectedPatientData} = useContext(DpContext)

  const fetchPastvisitsData = () => {
    getPastVisitsData(appointmentId)
      .then((res: any) => {
        const data = res?.data?.data
        setPrescriptionData(data)
      })
      .catch((error: any) => {
        console.error('Error fetching past visits data:', error)
      })
  }

  useEffect(() => {
    fetchPastvisitsData()
  }, [appointmentId])

  return (
    <div className='presc_preview_wrapper shadow-lg'>
      <div className='presc_preview_head'>
        <h2 className='m-0'>Past Prescription Preview</h2>
      </div>
      <div className='presc_preview_body'>
        {prescriptionData?.procedure?.chief_complaints && (
          <div className='presc_item_chief'>
            <h4>Chief Complaints</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData.procedure.chief_complaints
                .split(',')
                .map((item: string, index: number) => (
                  <li key={index}>{item}</li>
                ))}
            </ul>
          </div>
        )}
        {prescriptionData?.vital && (
          <div className='presc_item_examination'>
            <h4>Examination</h4>
            <ul className='p-0 d-flex list-unstyled flex-wrap'>
              {prescriptionData.vital.temprature && (
                <li>
                  <span>Temperature:</span>
                  <span>
                    {prescriptionData.vital.temprature}{' '}
                    {/* Check if temperature_unit exists before accessing */}
                    {prescriptionData.vital.temperature_unit &&
                      prescriptionData.vital.temperature_unit}
                  </span>
                </li>
              )}
              {prescriptionData.vital.pulse && (
                <li>
                  <span>Pulse:</span>
                  <span>{prescriptionData.vital.pulse}</span>
                </li>
              )}
              {prescriptionData.vital.Respiratory_rate && (
                <li>
                  <span>Respiratory Rate:</span>
                  <span>{prescriptionData.vital.Respiratory_rate}</span>
                </li>
              )}
              {prescriptionData.vital.blood_pressure && (
                <li>
                  <span>Blood Pressure:</span>
                  <span>{prescriptionData.vital.blood_pressure}</span>
                </li>
              )}
              {prescriptionData.vital.spo2 && (
                <li>
                  <span>SP02:</span>
                  <span>{prescriptionData.vital.spo2}</span>
                </li>
              )}
              {prescriptionData.vital.height && (
                <li>
                  <span>Height:</span>
                  <span>
                    {prescriptionData.vital.height} {prescriptionData.vital.height_unit}
                  </span>
                </li>
              )}
              {prescriptionData.vital.weight && (
                <li>
                  <span>Weight:</span>
                  <span>
                    {prescriptionData.vital.weight} {prescriptionData.vital.weight_unit}
                  </span>
                </li>
              )}
            </ul>
          </div>
        )}

        {prescriptionData?.examination_notes?.length > 0 && (
          <div className='presc_item_examination_notes'>
            <h4>Examination Notes</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData?.examination_notes?.map((item: any, index: number) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
        )}

        {prescriptionData?.procedure?.diagnosis && (
          <div className='presc_item_diagnosis'>
            <h4>Diagnosis</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData.procedure.diagnosis
                .split(',')
                .map((item: string, index: number) => (
                  <li key={index}>{item}</li>
                ))}
            </ul>
          </div>
        )}

        {prescriptionData?.medication?.length > 0 && (
          <div className='presc_item_medication'>
            <h4>Medication</h4>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Medicine Name</th>
                  <th>Regime and Instruction</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionData?.medication?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.medication_name}</td> {/* Use medication_name directly */}
                      <td>
                        <p className='m-0'>{item?.frequency}</p>
                        <p className='m-0'> For {item?.duration + ' ' + item?.duration_unit}</p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        {prescriptionData?.procedure?.length > 0 && (
          <div className='presc_item_procedure'>
            <h4>Procedure</h4>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Name of Procedure</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionData.procedure.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.procedure_name || 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {prescriptionData?.investigation?.length > 0 && (
          <div className='presc_item_investigation'>
            <h4>Investigation</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              {prescriptionData?.investigation?.map((item: any, index: number) => {
                return (
                  <li className='w-100' key={index}>
                    <b>{item?.prescribe_name}</b> - {item?.instruction}
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        {prescriptionData?.advice?.advice_name && (
          <div className='presc_item_advice'>
            <h4>Advice</h4>
            <ul className='p-0 d-flex flex-wrap list-unstyled'>
              <li className='w-100'>{prescriptionData.advice.advice_name}</li>
            </ul>
          </div>
        )}
      </div>
      <div className='presc_preview_footer shadow-lg'>
        <div className='d-flex justify-content-end align-items-center gap-3'>
          <div className='presc_footer_button'>
            <button className='btn btn-outline me-3' onClick={handlePreviewClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PastPrescriptionPreview
