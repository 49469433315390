import React from 'react'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {isNotEmpty, QUERIES} from '../../../../_jmh/helpers'
import {getAssetById} from '../AssetList/core/_requests'
import moment from 'moment'
import {useIntl} from 'react-intl'

const AssetView: React.FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const pathname = location.pathname
  const id: any = pathname.split('/').pop()
  const enabledQuery: boolean = isNotEmpty(id)

  const {
    isLoading,
    data: assetData,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${id}`,
    () => {
      return getAssetById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {},
    }
  )

  const handleBackClick = () => {
    window.history.back() // Redirect to the previous page
  }
  return (
    <>
      {assetData && (
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title fs-2 fw-bold'>
              {intl.formatMessage({id: 'ASSET.VIEW.ASSET'})}
            </h3>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </button>
            </div>
          </div>
          <div className='card-body card-scroll'>
            <div className='col-md-6 col-sm-12 text-start'>
              <div className='symbol symbol-100px  mb-7 text-center'></div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <div className='text-gray-600 fs-4 mt-2'>
                  {intl.formatMessage({id: 'GENERAL.ASSET.NAME'})}
                </div>
                <div className='fw-bold fs-5 mt-5'>{assetData?.name}</div>
                <div className='text-gray-600 fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.ASSET.CATEGORY.NAME'})}
                </div>
                <div className='fw-bold fs-5 mt-5 '>
                  {typeof assetData.category_id === 'object' ? assetData.category_id.name : ''}
                </div>
                <div className='text-gray-600 fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.PURCHASE.DATE'})}
                </div>
                <div className='fw-bold fs-5 mt-5'>
                  {moment(assetData?.purchase_date).format('DD-MM-YYYY')}
                </div>
                <div className='text-gray-600 fs-4 mt-5'>
                  {intl.formatMessage({id: 'ASSET.ASSET.DESCRIPTION'})}
                </div>
                <div className='fw-bold fs-5 mt-5'>{assetData?.description}</div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='text-gray-600 fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.PURCHASE.COST'})}
                </div>
                <div className='fw-bold fs-5 mt-5'>
                  {assetData?.purchase_cost
                    ? intl.formatMessage({id: 'GENERAL.CURRENCY'}) + assetData?.purchase_cost
                    : ''}
                </div>
                <div className='text-gray-600 fs-4 mt-5'>
                  {intl.formatMessage({id: 'ASSET.DISPOSAL.DATE'})}
                </div>
                <div className='fw-bold fs-5 mt-5'>
                  {moment(assetData?.disposal_date).format('DD-MM-YYYY')}
                </div>
                <div className='text-gray-600 fs-4 mt-5'>
                  {intl.formatMessage({id: 'ASSET.CURRENT.VALUE'})}
                </div>
                <div className='fw-bold fs-5 mt-5'>
                  {assetData?.current_value
                    ? intl.formatMessage({id: 'GENERAL.CURRENCY'}) + assetData?.current_value
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {AssetView}
