

import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import activeIndexReducer from './activeIndexSlice';
import asideMinimizeReducer from './asideMinimizeSlice';

const persistConfig = {
  key: 'root',
  version : 1,
  storage,
  whitelist : ["asideMinimize"]
};

const persistedReducer = persistReducer(persistConfig, asideMinimizeReducer);

const store = configureStore({
  reducer: {
    activeIndex: activeIndexReducer,
    asideMinimize: persistedReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
