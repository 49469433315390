import {FC, useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialMedicine, Medicine} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {MedicineMasterLoading} from '../components/loading/MedicineMasterLoading'
import {createMedicine, updateMedicine} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  medicine: Medicine
}

const defaultFrequencies = ['1-1-0', '1-0-0', '1-1-1', '0-0-1', '5ml-0-5ml', '2.5ml-0-2.5ml']

const defaultDuration = [1, 3, 5, 7, 9, 15, 30]

const defaultInstruction = ['Before meal', 'After meal']

const MedicineMasterEditModalForm: FC<Props> = ({medicine, isUserLoading}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {selectedHospitalId} = useContext(loginContext)
  const editUserSchema = Yup.object().shape({
    name: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQ.MEDICINE_NAME'})),

    type: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQ.MEDICINE_TYPE'})),

    frequency: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .required(intl.formatMessage({id: 'VALIDATION.REQ.FREQUENCY'}))
      .matches(
        /^(\d+(?:\.\d+)?[a-zA-Z]*-){2}\d+(?:\.\d+)?[a-zA-Z]*$/,
        intl.formatMessage({id: 'VALIDATION.INVALID.FREQUENCY'})
      ),
    duration: Yup.number()
      .min(1, intl.formatMessage({id: 'VALIDATION.DURATION.POSITIVE_NUMBER'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQ.DURATION'})),
  })

  const [userForEdit] = useState<Medicine>({
    ...medicine,
    name: medicine.name || initialMedicine.name,
    type: medicine.type || initialMedicine.type,
    frequency: medicine.frequency || initialMedicine.frequency,
    duration: medicine.duration || initialMedicine.duration,
    instruction: medicine.instruction || initialMedicine.instruction,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateMedicine(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          await createMedicine({
            ...values,
            hospital_id: Number(selectedHospitalId),
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleFrequencyChange = (frequency: string) => {
    formik.setFieldValue('frequency', frequency)
  }

  const handleDurationChange = (duration: number) => {
    formik.setFieldValue('duration', duration)
  }

  const handleInstructionChange = (instruction: string) => {
    formik.setFieldValue('instruction', instruction)
  }

  return (
    <>
      <form id='kt_modal_add_user_form5' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.MEDICINE.NAME'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.MEDICINE.NAME'})}
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.MEDICINE.TYPE'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.MEDICINE.TYPE'})}
                {...formik.getFieldProps('type')}
                type='text'
                name='type'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.type && formik.errors.type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.type}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7 d-flex gap-2'>
            <label className='required fw-bold fs-6 mt-3'>
              {intl.formatMessage({id: 'GENERAL.FREQUENCY'})}
            </label>
            <div className='d-flex'>
              <div className='d-flex flex-wrap gap-2'>
                {defaultFrequencies.map((item) => (
                  <button
                    key={item}
                    type='button'
                    className={clsx(
                      'btn btn-light-primary btn-active-primary rounded-pill btn-sm',
                      formik.values.frequency === item && 'active'
                    )}
                    onClick={() => handleFrequencyChange(item)}
                  >
                    {item}
                  </button>
                ))}
                <input
                  className={clsx(
                    'form-control form-control-solid btn-sm ms-2 rounded-4 mw-160',
                    formik.values.frequency &&
                      !defaultFrequencies.includes(formik.values.frequency) &&
                      'active'
                  )}
                  {...formik.getFieldProps('frequency')}
                  placeholder={intl.formatMessage({id: 'MEDICINE_MANAGEMENT.CUSTOM.FREQUENCY'})}
                  type='text'
                  name='frequency'
                  onChange={formik.handleChange}
                  autoComplete='off'
                />
              </div>
            </div>
          </div>
          {formik.touched.frequency && formik.errors.frequency && (
            <div className='fv-plugins-message-container mb-7'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.frequency}</span>
              </div>
            </div>
          )}

          <div className='fv-row mb-7 d-flex gap-2'>
            <label className='required fw-bold fs-6 mt-3'>
              {intl.formatMessage({id: 'GENERAL.DURATION'})}
            </label>
            <div className='d-flex'>
              <div className='d-flex flex-wrap gap-2'>
                {defaultDuration.map((item: any) => (
                  <button
                    key={item}
                    type='button'
                    className={clsx(
                      'btn btn-light-primary btn-active-primary rounded-pill btn-sm',
                      formik.values.duration === item && 'active'
                    )}
                    onClick={() => handleDurationChange(item)}
                  >
                    {item}
                  </button>
                ))}
                <input
                  className={clsx(
                    'form-control form-control-solid btn-sm ms-2 rounded-4 mw-160',
                    formik.values.duration &&
                      !defaultDuration.includes(formik.values.duration) &&
                      'active'
                  )}
                  {...formik.getFieldProps('duration')}
                  placeholder={intl.formatMessage({id: 'MEDICINE_MANAGEMENT.CUSTOM.DURATION'})}
                  type='number'
                  name='duration'
                  onChange={formik.handleChange}
                  autoComplete='off'
                />
              </div>
            </div>
          </div>
          {formik.touched.duration && formik.errors.duration && (
            <div className='fv-plugins-message-container mb-7'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.duration}</span>
              </div>
            </div>
          )}

          <div className='fv-row mb-7 d-flex gap-2'>
            <label className='fw-bold fs-6 mt-3'>
              {intl.formatMessage({id: 'GENERAL.INSTRUCTION'})}
            </label>
            <div className='d-flex'>
              <div className='d-flex flex-wrap gap-2'>
                {defaultInstruction.map((item: any) => (
                  <button
                    key={item}
                    type='button'
                    className={clsx(
                      'btn btn-light-primary btn-active-primary rounded-pill btn-sm',
                      formik.values.instruction === item && 'active'
                    )}
                    onClick={() => handleInstructionChange(item)}
                  >
                    {item}
                  </button>
                ))}
                <input
                  className={clsx(
                    'form-control form-control-solid btn-sm ms-2 rounded-4 mw-160',
                    formik.values.instruction &&
                      !defaultInstruction.includes(formik.values.instruction) &&
                      'active'
                  )}
                  {...formik.getFieldProps('instruction')}
                  placeholder={intl.formatMessage({id: 'MEDICINE_MANAGEMENT.CUSTOM.INSTRUCTIONS'})}
                  type='text'
                  name='instruction'
                  onChange={formik.handleChange}
                  autoComplete='off'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <MedicineMasterLoading />}
    </>
  )
}

export {MedicineMasterEditModalForm}
