import {useEffect} from 'react'
import {OperationEditModalHeader} from './OperationEditModalHeader'
import {OperationEditModalFormWrapper} from './OperationEditModalFormWrapper'

const OperationEditModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block modal-lg'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <OperationEditModalHeader />
            <div className='modal-body scroll-y  mx-xl-7 my-3'>
              <OperationEditModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {OperationEditModal}
