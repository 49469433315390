import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {SupportFeedbackEditModalForm} from './SupportFeedbackEditModalForm'

const SupportFeedbackPage = () => (
  <>
    <div className='row g-5 g-xl-8'>
      <SupportFeedbackEditModalForm />
    </div>
  </>
)

const SupportFeedbackWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'SUPPORT.FEEDBACK'})}</PageTitle>
      <SupportFeedbackPage />
    </>
  )
}

export {SupportFeedbackWrapper}
