/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useRef, useState} from 'react'
import {KTSVG, QUERIES, stringifyRequestQuery, toAbsoluteUrl} from '../../../helpers'
import {AssetDropdown} from '../../content/dropdown/AssetDropdownButton'
import {loginContext} from '../../../../app/context/context'
import {useQuery} from 'react-query'
import moment from 'moment'
import {getDoctorAvailabilityData} from '../core/request'
import {useQueryRequest} from '../../../../app/pages/asset-maintenance/assetMaintenance-List/core/QueryRequestProvider'
import {useListView} from '../../../../app/pages/Doctor/DoctorList/core/ListViewProvider'
import {useIntl} from 'react-intl'
import CommonLoader from '../../../Utils/CommonLoader'

type Props = {
  className: string
}

const DoctorLists: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const {state} = useQueryRequest()
  const {itemIdForUpdate} = useListView()
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState<string>(
    stringifyRequestQuery({...state, start_date: startDate, end_date: endDate})
  )
  const {selectedDoctorData, setSelectedDoctorData, selectedHospitalId} = useContext(loginContext)
  const [dateRange, setDateRange] = useState<Date[] | null>([])
  const [currentDate, setCurrentDate] = useState(moment().startOf('day'))
  const [selectedDate, setSelectedDate] = useState(moment().format('DD-MM-YYYY')) // Updated initial state

  const colors = ['primary', 'success', 'danger', 'warning', 'info']

  const {data: response, refetch} = useQuery(
    `${QUERIES.USERS_LIST}-${itemIdForUpdate},${selectedDate}`,
    () => {
      setLoading(true)
      return getDoctorAvailabilityData(query, selectedHospitalId, selectedDate)
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: () => setLoading(false),
    }
  )

  useEffect(() => {
    refetch()
  }, [selectedHospitalId])

  useEffect(() => {
    let filtered = response?.data
    setSelectedDoctorData(filtered)
  }, [response?.data, setSelectedDoctorData])

  const handleApplyFilter = () => {
    let filtered = response?.data
    if (Array.isArray(filtered)) {
      if (startDate && endDate) {
        filtered = filtered.filter((doctor: any) =>
          moment(doctor?.date).isBetween(moment(startDate), moment(endDate), null, '[]')
        )
      }
      setSelectedDoctorData(filtered)
    }
  }

  const handleReset = () => {
    setStartDate(null)
    setEndDate(null)
    setDateRange(null)
    return setSelectedDoctorData(response?.data)
  }

  const getCurrentWeek = () => {
    const startOfWeek = moment().startOf('day')
    const days = []

    for (let i = 0; i < 7; i++) {
      const currentDate = startOfWeek.clone().add(i, 'days')
      const isActive = currentDate.isSame(moment(), 'day')

      days.push(
        <li key={i} className={`nav-item p-0 ms-0 ${isActive ? 'active' : ''}`} role='presentation'>
          <a
            className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-primary ${
              isActive ? 'active' : ''
            }`}
            data-bs-toggle='tab'
            href={`#kt_timeline_widget_3_tab_content_${i + 1}`}
            aria-selected='false'
            role='tab'
            onClick={() => handleDateClick(currentDate)}
          >
            <span className='fs-7 fw-semibold'>{currentDate.format('ddd')}</span>
            <span className='fs-6 fw-bold'>{currentDate.format('D')}</span>
          </a>
        </li>
      )
    }

    return days
  }

  const handleDateClick = (date: any) => {
    const ds: any = moment(date?._d).format('DD-MM-YYYY')
    setSelectedDate(ds)
  }

  const dataArray: any = []
  selectedDoctorData &&
    selectedDoctorData?.forEach((doctorData: any) => {
      let resObj = {
        doctorId: doctorData.doctor_id,
        timeRange: [`${doctorData.start_time}-${doctorData.end_time}`],
        doctorName: doctorData?.doctor?.full_name,
      }
      selectedDoctorData.forEach((dData: any) => {
        if (
          doctorData.doctor_id === dData.doctor_id &&
          doctorData.start_time !== dData.start_time
        ) {
          resObj.timeRange.push(`${dData.start_time}-${dData.end_time}`)
        }
      })
      if (!dataArray.map((d: any) => d.doctorId).includes(resObj.doctorId)) {
        dataArray.push(resObj)
      }
    })

  // selectedDoctorData &&
  //   selectedDoctorData?.map((item: any) => {
  //     doctorId.push(item?.doctor_id)
  //   })

  // const doctorAppointments: any = []
  // doctorId.forEach((id: any) => {
  //   const doctorData = selectedDoctorData.filter((appointment: any) => appointment.doctor_id === id)
  //   const timeRanges = doctorData.map(
  //     (appointment: any) => `${appointment.start_time} - ${appointment.end_time}`
  //   )
  //   doctorAppointments.push({doctor_id: id, time_ranges: timeRanges})
  // })

  return (
    <>
      <>
        <div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0 m-auto w-100'>
          <div className='card h-md-100'>
            <img
              src={toAbsoluteUrl('/media/landing_asset/img-status-9.svg')}
              alt='img'
              className='img-fluid img-bg-abs'
            />
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark'>
                  {intl.formatMessage({id: 'DOCTOR.DOCTOR.AVAILABILITY'})}
                </span>
              </h3>

              <div className='card-toolbar'></div>
            </div>

            <div className='card-body py-0 px-0  '>
              <ul
                className='nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-center gap-10 mb-8 px-5'
                role='tablist'
              >
                {getCurrentWeek()}
              </ul>

              <div className='tab-content mb-2 px-9 py-3 overflow-y-scroll h-100 mh-300px d-block w-100 p-0'>
                {loading ? (
                  <div className='d-flex align-items-center justify-content-center h-100 mh-300px'>
                    <CommonLoader />
                  </div>
                ) : dataArray && dataArray?.length > 0 ? (
                  <div id='kt_timeline_widget_3_tab_content_1' role='tabpanel'>
                    {dataArray?.map((doctor: any, ind: any) => {
                      const timeSlots = doctor?.timeRange.slice(0, 2).join(', ')
                      return (
                        <div className='d-flex align-items-center mb-6' key={doctor.doctorId}>
                          <span
                            data-kt-element='bullet'
                            className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-${
                              colors[ind % colors.length]
                            }`}
                          ></span>

                          <div className='flex-grow-1 me-5'>
                            <div className='text-gray-800 fw-semibold fs-2'>{timeSlots}</div>

                            <div className='text-gray-700 fw-semibold fs-6'>
                              {doctor?.doctorName}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div className='text-center fw-bold fs-6'>
                    {intl.formatMessage({id: 'STAFF_DASHBOARD.DOCTOR.AVAILABLE'})}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>

      {/* ============================ */}

      {/* <div className={`card ${className}`}>
        <div className='table-responsive'>
          <table className='table table-striped gy-7 gs-7'>
            <div
              className='d-flex align-item-center justify-content-between border-0'
              style={{padding: '2rem 2.25rem 0rem'}}
            >
              <h4 className=' d-flex align-items-center justify-content-center flex-column mb-0'>
                <span className='card-label fw-bold text-dark'>Doctor Availability</span>
              </h4>
              <div className='d-flex'>
                <div className='d-flex'>
                  <div className='d-flex gap-1 card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-color-muted btn-active-light-primary p-1'
                      onClick={() => {
                        if (response?.data && Array.isArray(response?.data)) {
                          const filter = response.data.filter((doctor: any) =>
                            moment(doctor?.date).isAfter(moment())
                          )
                          setSelectedDoctorData(filter)
                        }
                      }}
                    >
                      Upcoming
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm  btn-color-muted btn-active-light-primary p-1'
                      onClick={() => {
                        if (response?.data && Array.isArray(response?.data)) {
                          const filter = response?.data?.filter((doctor: any) =>
                            moment(doctor?.date).isSame(moment(), 'day')
                          )
                          setSelectedDoctorData(filter)
                        }
                      }}
                    >
                      Today
                    </button>

                    <button
                      type='reset'
                      onClick={handleReset}
                      className='btn btn-sm btn-icon btn-color-muted btn-active-light-primary'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen072.svg'
                        className='svg-icon-2'
                      />
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen071.svg'
                        className='svg-icon-2'
                      />
                    </button>
                    <AssetDropdown
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      onApply={handleApplyFilter}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='card-body pt-5'>
              <table className='dashbord-table-data table table-rounded table-striped border gy-7 gs-7'>
                <thead className=' d-block w-100'>
                  <tr className='text-start text-muted fw-bolder fs-7  border-0 text-uppercase gs-0'>
                    <th className='ps-2'>Doctor Name</th>
                    <th className='w-150px text-center'>Start Time</th>
                    <th className='text-center'>End Time</th>
                  </tr>
                </thead>
                {selectedDoctorData && selectedDoctorData?.length > 0 ? (
                  <tbody className='overflow-y-scroll h-100 mh-300px d-block w-100 p-0'>
                    {selectedDoctorData?.map((doctor: any) => (
                      <tr key={doctor.id} className='text-dark fw-bold fs-7'>
                        <td>{doctor?.doctor?.full_name}</td>
                        <td className='text-center'>{doctor?.start_time}</td>
                        <td className='text-center'>{doctor?.end_time}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tr>
                    <td colSpan={3} className='text-center'>
                      No Doctors Available
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </table>
        </div>
      </div> */}
    </>
  )
}

export {DoctorLists}
