// @ts-nocheck
import {Column} from 'react-table'
import {ShiftInfoCell} from './ShiftInfoCell'
import {ShiftActionsCell} from './ShiftActionsCell'
import {ShiftCustomHeader} from './ShiftCustomHeader'
import {Shift} from '../../core/_models'
import {ShiftStartTimeCell} from './ShiftStartTimeCell'
import {ShiftEndTimeCell} from './ShiftEndTimeCell'
import {ShiftWorkingHoursCell} from './ShiftWorkingHoursCell'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<Shift>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <ShiftCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.SHIFT.TYPE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'type',
    Cell: ({...props}) => <ShiftInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <ShiftCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.START.TIME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'start_time',
    Cell: ({...props}) => <ShiftStartTimeCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <ShiftCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.END.TIME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'end_time',
    Cell: ({...props}) => <ShiftEndTimeCell Shift={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <ShiftCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.WORKING.HOURS'})}
          className='min-w-125px'
        />
      )
    },
    id: 'working_hours',
    Cell: ({...props}) => <ShiftWorkingHoursCell shift={props.data[props.row.index]} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'shift') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <ShiftCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px pe-5'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'shift') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? (
        <ShiftActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />
      ) : null
    },
  },
]

export {usersColumns}
