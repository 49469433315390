import {FC, useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialAssetCategory, AssetCategoryModule} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {AssetCategoryListLoading} from '../components/loading/AssetCategoryListLoading'
import {createAssetCategory, updateAssetCategory} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: AssetCategoryModule
}

const AssetCategoryEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {selectedHospitalId} = useContext(loginContext)
  const [userForEdit] = useState<AssetCategoryModule>({
    ...user,
    name: user.name || initialAssetCategory.name,
  })

  const editUserSchema = Yup.object().shape({
    name: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .matches(/^[A-Za-z\s]+$/, intl.formatMessage({id: 'VALIDATION.TEXT_INPUT'}))
      .required(intl.formatMessage({id: 'VALIDATION.ASSET_CATEGORY.NAME'})),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateAssetCategory(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          await createAssetCategory({...values, hospital_id: Number(selectedHospitalId)}).then(
            (res: any) => {
              toast.success(res?.data?.message)
            }
          )
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form3' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'GENERAL.ASSET.CATEGORY.NAME'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'GENERAL.ASSET.CATEGORY.NAME'})}
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0'
                // {'is-invalid': formik.touched.name && formik.errors.name},
                // {
                //   'is-valid': formik.touched.name && !formik.errors.name,
                // }
              )}
              autoComplete='off'
              // disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <AssetCategoryListLoading />}
    </>
  )
}

export {AssetCategoryEditModalForm}
