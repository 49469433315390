/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC,  useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  setEndDate: any;
  setStartDate: any;
  startDate: any;
  endDate: any;
  onApply: any
}

const AssetDropdown: FC<Props> = ({
  setEndDate,
  setStartDate,
  startDate,
  endDate,
  onApply
}) => {
  const [dateRange, setDateRange] = useState<Date[] | null>([startDate, endDate]);

  // const handleReset = () => {
  //   setStartDate(null);
  //   setEndDate(null);
  //   setDateRange(null);
  //   return setSelectedAssetMaitenance(response?.data)
  // };

  const handleDateChange = (update: Date | [Date | null, Date | null] | null) => {
    if (Array.isArray(update)) {
      const [startDate, endDate] = update;
      setStartDate(startDate);
      setEndDate(endDate);
      setDateRange(update as Date[] | null);
    }
  };

  const handleApply = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate); 
    onApply(startDate,endDate)
  };

  return (
    <div
      className='dashboard-dropdown menu menu-sub menu-sub-dropdown w-250px w-md-300px'
      data-kt-menu='true'
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Select Date</label>

          <div className='position-relative'>
            <DatePicker
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              selectsRange={true}  
              startDate={startDate}
              endDate={endDate}
              name='dateRange'
              selectsEnd={endDate}
              selectsStart={startDate}
              dateFormat='dd-MM-yyyy'
              className='form-control form-control-solid cursor-pointer mb-3 mb-lg-0'
              onChange={handleDateChange}
              shouldCloseOnSelect={false}
              autoComplete='off'
            />
            <span
              className='DatePicker_icon cursor-pointer'
              onClick={() => document.getElementsByName('dateRange')[0].focus()}
            >
              <div className='fa fa-calendar'></div>
            </span>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {/* <button
            type='reset'
            data-kt-menu-dismiss='true'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            onClick={handleReset}
          >
            Reset
          </button> */}

          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={() => handleApply(startDate, endDate)}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export { AssetDropdown };
