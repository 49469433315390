import {ID, Response} from '../../../../../_jmh/helpers'

export type Medicine = {
  id?: ID
  path?: any
  name?: string
  frequency?: string
  duration?: number
  type?: string
  instruction?: string
  created_at?: string
  updated_at?: string

  hospital_id?: number
  status?: string
}

export type PatientsQueryResponse = Response<Array<Medicine>>

export const initialMedicine: Medicine = {
  name: '',
  frequency: '',
  type: '',
  instruction: '',
  path: undefined,
}
