import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {DoctorDashboardCalendar} from './DoctorDashboardCalendar'

const DoctorDashboardPage = () => (
  <>
    <div>
      <DoctorDashboardCalendar />
    </div>
  </>
)

const DoctorDashboardWrapper = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DoctorDashboardPage />
    </>
  )
}

export {DoctorDashboardWrapper}
