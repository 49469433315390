import {useContext} from 'react'
import {loginContext} from '../context/context'
import CommonRoute from './CommonRoute'
import SuperAdminPrivateRoute from './SuperAdminPrivateRoute'

const PrivateRoutes = () => {
  const {loginData} = useContext(loginContext)

  function checkPermission(
    loginData: any,
    moduleName: string,
    route?: string,
    method?: string
  ): boolean {
    const permissions = loginData?.data?.permissions

    const modulePermissions = permissions?.filter((permission: any) => {
      if (route && method) {
        return (
          permission?.module?.name === moduleName &&
          permission?.permission?.route === route &&
          permission?.permission?.method === method
        )
      }
      return permission?.module?.name === moduleName
    })
    return modulePermissions?.length > 0
  }

  return (
    <>
      {loginData?.data?.user_type === 'SUPER_ADMIN' ? (
        <SuperAdminPrivateRoute />
      ) : (
        <CommonRoute loginData={loginData} checkPermission={checkPermission} />
      )}
    </>
  )
}

export default PrivateRoutes
