/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_jmh/helpers'
import {getMedicalNotes} from './_requests'
import {MedicalNotes} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import {useNavigate, useParams} from 'react-router-dom'
import {loginContext} from '../../../../context/context'

const QueryResponseContext = createResponseContext<MedicalNotes>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {selectedPatientId, selectedHospitalId, selectedPatientTab, selectedAppointment} =
    useContext(loginContext)
  const {id} = useParams()
  const [prevHospitalId, setPrevHospitalId] = useState(selectedHospitalId)
  const navigate = useNavigate()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    async () => {
      // Check if selectedPatientId is defined before calling the API
      if (selectedPatientId !== undefined) {
        const result = await getMedicalNotes(
          query,
          selectedPatientId,
          selectedHospitalId,
          selectedAppointment
            ? selectedAppointment?.value === 'all'
              ? ''
              : selectedAppointment?.value
            : ''
        )
        return result
      } else {
        // Return a resolved promise with no data if selectedPatientId is undefined
        return Promise.resolve({data: []}) // Adjust the structure based on your MedicalNotesQueryResponse
      }
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  useEffect(() => {
    if (selectedPatientTab === 'Medical-Notes') {
      refetch()
    }
  }, [response, selectedPatientTab, selectedPatientId, selectedAppointment])

  useEffect(() => {
    if (selectedPatientTab === 'Medical-Notes' && selectedHospitalId !== prevHospitalId) {
      // window.history.back()
      navigate('/patient-management')
    }
    setPrevHospitalId(selectedHospitalId)
  }, [selectedHospitalId])

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: any = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }

  return response
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
