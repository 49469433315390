import React from 'react'

import {PageTitle} from '../../../_jmh/layout/core'

import {Overview} from './Profile/components/Overview'
import {useIntl} from 'react-intl'
import {KTCard} from '../../../_jmh/helpers'

const MyProfileWrapper = () => {
  return (
    <>
      <KTCard>
        <Overview />
      </KTCard>
    </>
  )
}

const ProfileWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'GENERAL.PROFILE'})}</PageTitle>
      <MyProfileWrapper />
    </>
  )
}
export {ProfileWrapper}
