import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {PatientListWrapper} from './PatientList/Patientlist'

const PatientPage = () => (
  <>
    <div className=''>
      <PatientListWrapper />
    </div>
  </>
)

const PatientWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'PATIENT.PATIENT_MANAGEMENT'})}
      </PageTitle>
      <PatientPage />
    </>
  )
}

export {PatientWrapper}
