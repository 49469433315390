import {FC, useContext, useState, useRef, useEffect} from 'react'
import {useQuery} from 'react-query'
import {KTSVG, QUERIES, stringifyRequestQuery} from '../../../helpers'
import {getNotificationData, updatedNewNotification} from './core/request' // Import the updatedNewNotification function
import {useQueryRequest} from '../../../../app/pages/asset-maintenance/assetMaintenance-List/core/QueryRequestProvider'
import {loginContext} from '../../../../app/context/context'
import {Link} from 'react-router-dom'
import {convertCreatedAtToTimeAgo} from '../../../Utils/HelperFunction'
import {updateNotification} from '../../../../app/pages/notification/notification-list/core/_requests'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  created_at?: string
}

const DEFAULT_PROFILE_IMAGE = 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'

const NotificationDropdown: FC<Props> = ({created_at}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const {loginData} = useContext(loginContext)
  const {state} = useQueryRequest()
  const [query] = useState<string>(stringifyRequestQuery({...state}))
  const {readNotification, setReadNotification} = useContext(loginContext)
  const [storeNotification, setStoreNotification] = useState<any>(null)
  const {logout} = useAuth()

  const cacheKey = `${QUERIES.USERS_LIST}-user-${query}`

  const {data: response, refetch: refetchNotifications} = useQuery(
    cacheKey,
    () => {
      return getNotificationData(readNotification, loginData?.data?.personal_details?.id)
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  )

  // const [notifications, setNotifications] = useState(response?.data || [])

  const handleViewAllClick = () => {
    setIsPopupOpen(false)
    setStoreNotification([])
    // if (notifications?.length) {
    //   const notificationIds = notifications.map((notification: any) => notification.id)
    //   // setReadNotification(true)
    //   updatedNewNotification(notificationIds) // Store the selected notifications
    //   // refetchNotifications() // Fetch the updated notifications
    // }
  }

  const handleNotificationClick = async (notification: any) => {
    if (!notification.is_read) {
      await updateNotification(notification.id)
      await updatedNewNotification([notification.id]) // Store the selected notification
      refetchNotifications() // Fetch the updated notifications
    }
  }

  useEffect(() => {
    const unreadNotifications = response?.data?.data?.filter(
      (notification: any) => !notification.is_read
    )
    setStoreNotification(unreadNotifications)
  }, [response?.data])

  useEffect(() => {
    const interval = setInterval(() => {
      refetchNotifications()

      if (Array.isArray(response?.data?.data)) {
        const logoutNotification = response?.data?.data.find(
          (notification: any) =>
            notification.notification_type === 'DE-ACTIVATE' && notification?.is_new === true
        )
        if (logoutNotification) {
          logout()
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [logout, refetchNotifications, response?.data])

  useEffect(() => {
    const interval = setInterval(() => {
      refetchNotifications()
        .then((response) => {
          if (response?.data?.status === 401) {
            localStorage.removeItem('kt-auth-react-v')
            localStorage.removeItem('loginData')
            logout()
          } else {
          }
        })
        .catch((error) => {
          console.error('Error fetching notifications:', error)
        })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [logout, refetchNotifications, response?.data])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsPopupOpen(false) // Close the dropdown
        setStoreNotification([]) // Clear the notifications
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div
      className={`dashboard-dropdown menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary rounded-20 fw-bold w-300px ${
        isPopupOpen ? 'show' : ''
      }`}
      data-kt-menu='true'
      ref={dropdownRef}
    >
      <div
        className=' menu-item px-3 d-flex justify-content-between rounded-top '
        style={{
          backgroundImage: `url("https://preview.keenthemes.com/metronic8/demo1/assets/media/misc/menu-header-bg.jpg")`,
        }}
      >
        <div className='menu-content fs-6 text-white fw-bolder px-3 py-4'>Notifications</div>
        <div className='px-3 py-4'>
          <Link to='/notification' className='text-white' onClick={handleViewAllClick}>
            View All
          </Link>
        </div>
      </div>
      <div className='separator border-gray-400 my-0'></div>
      <div className='menu-item' style={{maxHeight: '300px', overflowY: 'auto'}}>
        <div className='menu-content'>
          {storeNotification?.length ? (
            storeNotification?.map((notification: any) => (
              <div
                key={notification.id}
                className={`menu-item rounded px-3 py-3 mb-3 ${
                  notification.is_new ? '' : 'bg-light-primary'
                } ${notification.is_read ? 'bg-light-success' : ''} `}
              >
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-circle symbol-40px me-4'>
                    <img
                      src={notification?.payload?.user?.photo || DEFAULT_PROFILE_IMAGE}
                      alt='user_profile'
                    />
                  </div>
                  <div className='d-flex flex-column flex-grow-1'>
                    <span className='text-gray-800 fw-bold'>{notification.title}</span>
                    <span className='text-gray-500 fs-8 mt-1'>
                      {notification.notification_type}
                    </span>
                    <span className='text-muted fw-bold fs-7 mt-1'>
                      {convertCreatedAtToTimeAgo(notification.created_at)}
                    </span>
                  </div>
                  {!notification.is_read && (
                    <span
                      className='badge cursor-pointer'
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen075.svg'
                        className='svg-icon-2'
                      />
                    </span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className='text-center py-10'>No notifications found</div>
          )}
        </div>
      </div>
    </div>
  )
}

export {NotificationDropdown}
