import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {HospitalPage} from './HospitalPage'
import {useIntl} from 'react-intl'

const HospitalPageWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'HOSPITAL_INFORMATION'})}</PageTitle>
      <HospitalPage />
    </>
  )
}

export default HospitalPageWrapper
