/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ShiftModel} from '../../core/_models'

type Props = {
  user: ShiftModel
}

const ShiftInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <div className='text-gray-800 mb-1'>
        {user.type}
      </div>

      {/* <span>{user.email}</span> */}
    </div>
  </div>
)

export {ShiftInfoCell}
