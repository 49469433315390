import axios, { AxiosResponse } from 'axios'
import { UsersQueryResponse } from '../../Patient-Medical-background/MedicalBackgroundList/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const PRESCRIPTION_URL = `${API_URL}/doctor-prescription`
const PATIENT_MEDICAL_BACK = `${API_URL}/patient-medical-background`
// const PAST_VISITS = `${API_URL}/patient-appointment`
const PAST_VISITS = `${API_URL}/patient-appointment/patients`
const PAST_VISITS_DATA = `${API_URL}/doctor-prescription`

const createPrescription = (
  presc_data:any 
) => {
  return axios
    .post(PRESCRIPTION_URL, presc_data)
}

const getMedicalBackground = (patient_id:number,hosId:number) => {
  return axios
    .get(`${PATIENT_MEDICAL_BACK}?${`limit=10&hospital_id=${hosId}&patient_id=${patient_id}`}`)
}

const getPastVisits = (patient_id: number) => {
    const loginDataformlocal = localStorage.getItem('loginData')
  const localData = JSON.parse(String(loginDataformlocal))

  let url = `${PAST_VISITS}/${patient_id}?order=DESC&sort=appointment_date`

  if(localData?.data.user_type==='DOCTOR'){
    const doctor_id=localData.data.personal_details.id;
    url += `&doctor_id=${doctor_id}`;
  }
  if(localData?.data.user_type === 'STAFF'){
    const roleName = localData?.data?.role.name
    const roleUP = roleName.toUpperCase();
    const personalId = localData?.data?.personal_details?.id
    if (roleUP === 'DOCTOR') {
      url += `&doctor_id=${personalId}`;
    }   
  }
  return axios.get(url);
};

const getPastVisitsData = (appointment_id: number) =>{
  return axios
  .get(`${PAST_VISITS_DATA}/pastvisit/${appointment_id}`)
}

export {createPrescription,getMedicalBackground,getPastVisits,getPastVisitsData}
