import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {DoctorAppointment, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/doctor-appointment`
const PATIENT_APPOINTMENT_URL = `${API_URL}/patient-appointment`
const GET_DOCTOR_URL = `${API_URL}/doctor`

const getAppointments = (query: string, id:any,hosId:number): Promise<UsersQueryResponse> => {
  return axios
    // .get(`${DOCTOR_APPOINTMENT_URL}?${query}&doctorId=${id}`)
    .get(`${PATIENT_APPOINTMENT_URL}?${query ? `${query}&limit=10&doctor_id=${id}&hospital_id=${hosId}` : `limit=10&doctorId=${id}&hospital_id=${hosId}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<DoctorAppointment | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<DoctorAppointment>>) => response.data)
    .then((response: Response<DoctorAppointment>) => response.data)
}

const createUser = (user: any): Promise<DoctorAppointment | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<DoctorAppointment>>) => response.data)
    .then((response: Response<DoctorAppointment>) => response.data)
}

const updateUser = (user: DoctorAppointment): Promise<DoctorAppointment | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<DoctorAppointment>>) => response.data)
    .then((response: Response<DoctorAppointment>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getDoctorsData = (query?: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_DOCTOR_URL}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}


export {getAppointments, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser,getDoctorsData}
