import {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import ChiefComplaints from './ChiefComplaints'
import Examination from './Examination'
import Diagnosis from './Diagnosis'
import DpMedication from './DpMedication'
import DpProcedure from './DpProcedure'
import Investigation from './Investigation'
import Advice from './Advice'
import DpAttachment from './DpAttachment'
import DpFooter from '../DpFooter'

const GeneratePrescription = () => {
  const [activeItem, setActiveItem] = useState('complaints')
  const [visibleSection, setVisibleSection] = useState('complaints')

  const sectionRefs = {
    complaints: useRef(null),
    examination: useRef(null),
    diagnosis: useRef(null),
    medication: useRef(null),
    procedure: useRef(null),
    investigation: useRef(null),
    advice: useRef(null),
    attachment: useRef(null),
  }

  useEffect(() => {
    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          setVisibleSection(entry.target.id)
          setActiveItem(entry.target.id)
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1,
    })

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const hash = window.location.hash.substring(1)
    if (hash && hash !== activeItem) {
      setActiveItem(hash)
    } else {
      setActiveItem('complaints')
    }
  }, [])

  const smoothScroll = (target: string) => {
    const element = document.getElementById(target)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  // const handleItemClick = (target: string) => {
  //   setActiveItem(target)
  //   smoothScroll(target)
  //   window.location.hash = target
  // }
  const handleItemClick = (target: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    setActiveItem(target)
    smoothScroll(target)
    // window.location.hash = target
  }

  return (
    <div className='dp-content-wrapper'>
      <div className='dp-content-left'>
        <ul className='d-flex align-items-start justify-content-start justify-content-lg-center flex-row flex-lg-column m-0 p-0 list-unstyled'>
          <li className={`dp-sidebar-item ${activeItem === 'complaints' ? 'active' : ''}`}>
            <Link
              to='#complaints'
              title='Chief Complaints'
              onClick={(event) => handleItemClick('complaints', event)}
            >
              <i className='fa-solid fa-file-circle-plus'></i>Chief Complaints
            </Link>
          </li>
          <li className={`dp-sidebar-item ${activeItem === 'examination' ? 'active' : ''}`}>
            <Link
              to='#examination'
              title='Examination'
              onClick={(event) => handleItemClick('examination', event)}
            >
              <i className='fa-solid fa-notes-medical'></i>Examination
            </Link>
          </li>
          <li className={`dp-sidebar-item ${activeItem === 'diagnosis' ? 'active' : ''}`}>
            <Link
              to='#diagnosis'
              title='Diagnosis'
              onClick={(event) => handleItemClick('diagnosis', event)}
            >
              <i className='fa-solid fa-stethoscope'></i>Diagnosis
            </Link>
          </li>
          <li className={`dp-sidebar-item ${activeItem === 'medication' ? 'active' : ''}`}>
            <Link
              to='#medication'
              title='Medication'
              onClick={(event) => handleItemClick('medication', event)}
            >
              <i className='fa-solid fa-capsules'></i>Medication
            </Link>
          </li>
          <li className={`dp-sidebar-item ${activeItem === 'procedure' ? 'active' : ''}`}>
            <Link
              to='#procedure'
              title='Procedure'
              onClick={(event) => handleItemClick('procedure', event)}
            >
              <i className='fa-solid fa-syringe'></i>Procedure
            </Link>
          </li>
          <li className={`dp-sidebar-item ${activeItem === 'investigation' ? 'active' : ''}`}>
            <Link
              to='#investigation'
              title='Investigation'
              onClick={(event) => handleItemClick('investigation', event)}
            >
              <i className='fa-solid fa-magnifying-glass-plus'></i>Investigation
            </Link>
          </li>
          <li className={`dp-sidebar-item ${activeItem === 'advice' ? 'active' : ''}`}>
            <Link to='#Advice' title='Advice' onClick={(event) => handleItemClick('advice', event)}>
              <i className='fa-solid fa-comment-medical'></i>Advice
            </Link>
          </li>
          <li className={`dp-sidebar-item ${activeItem === 'attachment' ? 'active' : ''}`}>
            <Link
              to='#attachment'
              title='Attachment'
              onClick={(event) => handleItemClick('attachment', event)}
            >
              <i className='fa-solid fa-paperclip'></i>Attachment
            </Link>
          </li>
        </ul>
      </div>
      <div className='dp-content-right'>
        <div
          className={`dp-right-item ${visibleSection === 'complaints' ? 'shadow-lg' : ''}`}
          id='complaints'
          ref={sectionRefs.complaints}
        >
          <ChiefComplaints />
        </div>
        <div
          className={`dp-right-item ${visibleSection === 'examination' ? 'shadow-lg' : ''}`}
          id='examination'
          ref={sectionRefs.examination}
        >
          <Examination />
        </div>
        <div
          className={`dp-right-item ${visibleSection === 'diagnosis' ? 'shadow-lg' : ''}`}
          id='diagnosis'
          ref={sectionRefs.diagnosis}
        >
          <Diagnosis />
        </div>
        <div
          className={`dp-right-item ${visibleSection === 'medication' ? 'shadow-lg' : ''}`}
          id='medication'
          ref={sectionRefs.medication}
        >
          <DpMedication />
        </div>
        <div
          className={`dp-right-item ${visibleSection === 'procedure' ? 'shadow-lg' : ''}`}
          id='procedure'
          ref={sectionRefs.procedure}
        >
          <DpProcedure />
        </div>
        <div
          className={`dp-right-item ${visibleSection === 'investigation' ? 'shadow-lg' : ''}`}
          id='investigation'
          ref={sectionRefs.investigation}
        >
          <Investigation />
        </div>
        <div
          className={`dp-right-item ${visibleSection === 'advice' ? 'shadow-lg' : ''}`}
          id='advice'
          ref={sectionRefs.advice}
        >
          <Advice />
        </div>
        <div
          className={`dp-right-item ${visibleSection === 'attachment' ? 'shadow-lg' : ''}`}
          id='attachment'
          ref={sectionRefs.attachment}
        >
          <DpAttachment />
        </div>
      </div>
      <DpFooter />
    </div>
  )
}

export default GeneratePrescription
