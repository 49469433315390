/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useContext, useEffect, useState} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const PayrollsListPagination = () => {
  const intl = useIntl()
  const pagination = useQueryResponsePagination()
  const [currentPage, setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {selectedSearchTerm, setCurrentPagination} = useContext(loginContext)
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()

  useEffect(() => {
    if (selectedSearchTerm.length > 0) {
      setCurrentPage(1) // Set current page to 1 if there is a search term
    } else {
      setCurrentPage(pagination?.pagination?.meta?.currentPage || 1) // Set current page to the stored value if no search term
    }
  }, [selectedSearchTerm, pagination?.pagination?.meta?.currentPage])

  const updatePage = (page: number | null) => {
    if (!page || isLoading || currentPage === page) {
      return
    }

    setCurrentPage(page)
    updateState({page, items_per_page: pagination?.pagination?.meta?.items_per_page || 10})
  }

  useEffect(() => {
    setCurrentPagination(currentPage - 1)
  }, [currentPage])

  const handlePrevClick = () => {
    updatePage(currentPage - 1) // Update the page to previous page
  }

  const handleNextClick = () => {
    updatePage(currentPage + 1) // Update the page to next page
  }

  const pageItems = []

  if (pagination?.pagination) {
    for (let i = 1; i <= pagination?.pagination?.meta?.totalPages; i++) {
      pageItems.push({
        pageNum: i,
      })
    }
  }
  const showDots = pageItems.length > 3

  return (
    <>
      <>
        {pagination && pagination?.data?.length > 0 ? (
          <div className='row'>
            <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-end justify-content-md-end'>
              <div id='kt_table_users_paginate' className='d-flex align-items-center'>
                <a
                  className={clsx('p-3 page-link prev_button', {
                    disabled: currentPage === 1,
                  })}
                  onClick={handlePrevClick}
                  style={{cursor: 'pointer', fontWeight: 'bold'}}
                >
                  {intl.formatMessage({id: 'GENERAL.PREVIOUS'})}
                </a>
                <ul className='pagination'>
                  {pageItems &&
                    pageItems?.map((item: any, index: number) => {
                      if (showDots && index === 1 && currentPage > 3) {
                        return (
                          <li key={`dot-start`} className='page-item  disabled'>
                            <span className='page-link page_dots '>...</span>
                          </li>
                        )
                      }
                      if (
                        showDots &&
                        index === pageItems.length - 2 &&
                        currentPage < pageItems.length - 2
                      ) {
                        return (
                          <li key={`dot-end`} className='page-item pagination_dots disabled'>
                            <span className='page-link page_dots'>...</span>
                          </li>
                        )
                      }
                      if (
                        (index === 1 && currentPage === 3) ||
                        (index === pageItems.length - 2 && currentPage === pageItems.length - 2)
                      ) {
                        return (
                          <li key={item.pageNum} className='page-item'>
                            <a
                              className={clsx('p-5 page-link', {
                                active: item?.pageNum === currentPage,
                              })}
                              onClick={() => updatePage(item?.pageNum)}
                              style={{cursor: 'pointer'}}
                            >
                              {item?.pageNum}
                            </a>
                          </li>
                        )
                      }
                      if (
                        index === 0 ||
                        index === pageItems.length - 1 ||
                        Math.abs(currentPage - item.pageNum) <= 1
                      ) {
                        return (
                          <li key={item.pageNum} className='page-item'>
                            <a
                              className={clsx('p-5 page-link', {
                                active: item?.pageNum === currentPage,
                              })}
                              onClick={() => updatePage(item?.pageNum)}
                              style={{cursor: 'pointer'}}
                            >
                              {item?.pageNum}
                            </a>
                          </li>
                        )
                      }
                      return null
                    })}
                </ul>
                <a
                  className={clsx('p-3 page-link next_button', {
                    disabled: currentPage === pagination?.pagination?.meta?.totalPages,
                  })}
                  onClick={handleNextClick}
                  style={{cursor: 'pointer', fontWeight: 'bold'}}
                >
                  {intl.formatMessage({id: 'GENERAL.NEXT'})}
                </a>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    </>
  )
}

export {PayrollsListPagination}
