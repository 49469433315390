import React, {useEffect, useState} from 'react'
import PrescriptionPreview from './components/PrescriptionPreview'
import SubmitConsultation from './components/SubmitConsultation'
// import {useLocation} from 'react-router-dom'

const DpFooter = () => {
  const [showPreview, setShowPreview] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  // const location = useLocation()

  const handlePreview = () => {
    setShowPreview(true)
  }

  const handlePreviewClose = () => {
    setShowPreview(false)
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked)
  }

  useEffect(() => {
    if (showPreview) {
      document.body.classList.add('preview-open')
      document.querySelector('.presc_preview_wrapper')?.classList.add('active')
    } else {
      document.body.classList.remove('preview-open')
      document.querySelector('.presc_preview_wrapper')?.classList.remove('active')
    }

    return () => {
      document.body.classList.remove('preview-open')
      document.querySelector('.presc_preview_wrapper')?.classList.remove('active')
    }
  }, [showPreview])

  // if (location.pathname === '/dp-past-visits') {
  //   console.log('location', location)
  //   return null
  // }

  return (
    <div className='dp-footer justify-content-center justify-content-sm-start justify-content-md-end shadow-lg gap-3'>
      {/* Share Prescription */}
      <div className='share-prescription d-flex align-items-center gap-3'>
        <input type='checkbox' id='share-prescription-checkbox' onChange={handleCheckboxChange} />
        <label htmlFor='share-prescription-checkbox'>Share Prescription With Patient</label>
      </div>

      {/* Preview Prescription */}
      <div className='preview-prescription'>
        <button className='btn btn-outline' onClick={handlePreview}>
          Preview Prescription
        </button>
      </div>

      {/* End Consultation */}
      <div className='end-consultation'>
        <SubmitConsultation isCheckboxChecked={isCheckboxChecked} />
      </div>

      {showPreview && (
        <PrescriptionPreview
          handlePreviewClose={handlePreviewClose}
          isCheckboxChecked={isCheckboxChecked}
        />
      )}
    </div>
  )
}

export default DpFooter
