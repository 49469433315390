/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC} from 'react'
import {Inventory} from '../../core/_models'

type Props = {
  user: Inventory
}

const InvManagementStatus: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p
        className={`mb-1 badge ${
          user?.status === 'Low Quantity'
            ? 'badge-light-danger'
            : user?.status === 'High Quantity'
            ? 'badge-light-success'
            : user?.status === 'Average Quantity'
            ? 'badge-light-warning'
            : ''
        }`}
      >
        {user?.status}
      </p>
    </div>
  </div>
)

export {InvManagementStatus}
