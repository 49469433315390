// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {Salary} from '../../core/_models'
import {GrossSalary} from './GrossSalary'
import {ThroughPaySlip} from './ThroughPaySlip'
import {SalaryMonthAndYear} from './SalaryMonthAndYear'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<Salary>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'SALARY.STRUCTURE.SELECT.STAFF.MEMBER'})}
          className='min-w-125px'
        />
      )
    },
    id: 'staff',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.GROSS.SALARY'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'gross_salary',
    Cell: ({...props}) => <GrossSalary user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'SALARY.STRUCTURE.PAY.THROUGH.SLIP'})}
          className='min-w-125px'
        />
      )
    },
    id: 'through_pay_slip',
    Cell: ({...props}) => <ThroughPaySlip user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.SALARY.MONTH.YEAR'})}
          className='min-w-125px'
        />
      )
    },
    id: 'SalaryMonthAndYear',
    Cell: ({...props}) => <SalaryMonthAndYear user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'staff-salary-strcuture') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px pe-5'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'staff-salary-strcuture') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? (
        <UserActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />
      ) : null
    },
  },
]

export {usersColumns}
