import axios, {AxiosResponse} from 'axios'
import { Response} from '../../../../../_jmh/helpers'
import {PatientBiling, PatientBillingQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const PATIENT_BILLING_URL = `${API_URL}/patient-billing`
const GET_USERS_URL = `${API_URL}/patient`

export const getPatientName = (id?: any): Promise<PatientBillingQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?hospital_id=${id}`)
    .then((d: AxiosResponse<PatientBillingQueryResponse>) => d.data)
}

const getPatientBilling = (query: string, id: number): Promise<PatientBillingQueryResponse> => {
  return axios
    .get(
      `${PATIENT_BILLING_URL}?${
        query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`
      }`
    )
    .then((d: AxiosResponse<PatientBillingQueryResponse>) => d.data)
}

const getPatientBillingById = (bill_number: any): Promise<PatientBiling | undefined> => {
  return axios
    .get(`${PATIENT_BILLING_URL}/${bill_number}`)
    .then((response: AxiosResponse<Response<PatientBiling>>) => response.data)
    .then((response: Response<PatientBiling>) => response.data)
}

const getPatientInvoiceById = (bill_number: any): Promise<PatientBiling | undefined> => {
  return axios
    .post(`${PATIENT_BILLING_URL}/${bill_number}`)
    .then((response: AxiosResponse<Response<PatientBiling>>) => response.data)
    .then((response: Response<PatientBiling>) => response.data)
}
const getPatientBillingReport = (bill_number: any): Promise<PatientBiling | undefined> => {
  return axios
    .get(`${PATIENT_BILLING_URL}/csv?hospital_id=${bill_number}`)
    .then((response: AxiosResponse<Response<PatientBiling>>) => response.data)
    .then((response: Response<PatientBiling>) => response.data)
}

const createPatientBiling = (user: PatientBiling): Promise<PatientBiling | undefined> => {
  return axios
    .post(PATIENT_BILLING_URL, user)
}

const updatePatientBiling = (user: PatientBiling): Promise<PatientBiling | undefined> => {
  return axios
    .patch(`${PATIENT_BILLING_URL}/${user?.bill_number}`, user)
}

// const deletePatientBilling = (Id: ID): Promise<void> => {
//   return axios.delete(`${PATIENT_BILLING_URL}`,{data: { ids : [Id]}}).then(() => {})
// }

export {
  getPatientBilling,
  getPatientBillingById,
  getPatientInvoiceById,
  getPatientBillingReport,
  createPatientBiling,
  updatePatientBiling,
}
