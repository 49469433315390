// @ts-nocheck
import {Column} from 'react-table'
import {AuditLogsSRnoCell} from './AuditLogsSRnoCell'
import {AuditLogsCustomHeader} from './AuditLogsCustomHeader'
import {AuditLog} from '../../core/_models'
import {AuditLogsUserNameCell} from './AuditLogsUserNameCell'
import {AuditLogsUserRoleCell} from './AuditLogsUserRoleCell'
import {AuditLogsActionPerformed} from './AuditLogsActionPerformed'
import {AuditLogsDateTimeCell} from './AuditLogsDateTimeCell'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<AuditLog>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AuditLogsCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.SR.NO'})}
          className='min-w-125px'
        />
      )
    },
    id: 'sr_no',
    Cell: ({...props}) => <AuditLogsSRnoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AuditLogsCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'user_name',
    Cell: ({...props}) => <AuditLogsUserNameCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AuditLogsCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ROLE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'user_role',
    Cell: ({...props}) => <AuditLogsUserRoleCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AuditLogsCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION.PERFORMED'})}
          className='min-w-125px'
        />
      )
    },
    id: 'description',
    Cell: ({...props}) => <AuditLogsActionPerformed user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AuditLogsCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DATE.TIME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'created_at',
    Cell: ({...props}) => <AuditLogsDateTimeCell user={props.data[props.row.index]} />,
  },
]

export {usersColumns}
