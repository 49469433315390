/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {Payroll} from '../../core/_models'
import {deletePayroll, getPaySlip} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
  user: Payroll
}

const UserActionsCell: FC<Props> = ({id, user}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false) // Loading state
  const pagination = useQueryResponsePagination()
  const [setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const [isDelete, setIsDelete] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const page = pagination?.pagination?.meta?.currentPage || 1

  const handleRemove = (id: any) => {
    if (id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          title: `Are you sure you want to delete Payroll ?`,
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Close',
          reverseButtons: true,
        })
        .then(async (result: any) => {
          if (result.isConfirmed) {
            try {
              await deletePayroll(Number(id))

              // Make the API call to delete the doctor's availability
              swalWithBootstrapButtons.fire('Deleted!', `Payroll has been deleted.`, 'success')
              setIsDelete((val) => !val)
              if (page > 1) {
                const updatedData = await queryClient.getQueryData<any>([
                  `${QUERIES.USERS_LIST}-${query}`,
                ])
                if (updatedData?.data?.length > 0) {
                  setCurrentPage(page)
                } else {
                  setCurrentPage(page - 1)
                  updateState({
                    page: page - 1,
                    items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
                  })
                }
              }
            } catch (error) {
              swalWithBootstrapButtons.fire('Error', 'Failed to delete Payroll', 'error')
            }
          }
        })
    }
  }

  // const deleteItem = useMutation(() => deletePayroll(Number(id)), {
  //   onSuccess: async () => {
  //     await queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //     if (page > 1) {
  //       const updatedData = await queryClient.getQueryData<any>([`${QUERIES.USERS_LIST}-${query}`])
  //       if (updatedData?.data?.length > 0) {
  //         setCurrentPage(page)
  //       } else {
  //         setCurrentPage(page - 1)
  //         updateState({
  //           page: page - 1,
  //           items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
  //         })
  //       }
  //     }
  //     toast.error('Payroll Deleted Successfully')
  //   },
  // })

  useEffect(() => {
    refetch()
  }, [refetch, isDelete])

  const handleDownload = async (id: any) => {
    setIsLoading(true) // Set loading state to true

    try {
      const getInvoiceData: any = await getPaySlip(id)
      const uint8Array = new Uint8Array(getInvoiceData?.data)

      const blob = new Blob([uint8Array], {type: 'application/pdf'})

      // Create a temporary URL for the blob
      const url = URL.createObjectURL(blob)

      // Create a temporary link element
      const link = document.createElement('a')
      link.href = url
      link.download = 'payslip.pdf'

      // Programmatically click the link to trigger the download
      link.click()

      // Clean up the temporary URL and link
      URL.revokeObjectURL(url)
      link.remove()
    } catch (error) {
      console.log('Error occurred while downloading invoice:', error)
    } finally {
      setIsLoading(false) // Set loading state to false
    }
  }

  const {loginData, setLoginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'staff-payroll') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  const view = data?.filter((item: any) => {
    if (item.permission.route === ':id' && item.permission.method === 'GET') {
      return item
    }
  })

  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE') {
      return item
    }
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {edit.length ? (
          <>
            {user?.approval !== 'APPROVED' && (
              <>
                <div className='menu-item px-3'>
                  <Link to={`/staff/payroll/edit-payroll/${id}`} className='menu-link px-3'>
                    {intl.formatMessage({id: 'GENERAL.EDIT'})}
                  </Link>
                </div>
              </>
            )}
          </>
        ) : (
          ''
        )}

        {/* end::Menu item */}
        {user?.approval === 'APPROVED' && (
          <>
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                data-kt-users-table-filter='delete_row'
                download
                onClick={() => handleDownload(user.id)}
              >
                {intl.formatMessage({id: 'GENERAL.PAY.SLIP'})}
              </a>
            </div>
          </>
        )}

        {view.length ? (
          <div className='menu-item px-3'>
            <Link className='menu-link px-3' to={`/staff/payroll/view-payroll/${user?.id}`}>
              {intl.formatMessage({id: 'GENERAL.VIEW'})}
            </Link>
          </div>
        ) : (
          ''
        )}

        {/* begin::Menu item */}
        {deleteData.length ? (
          <>
            {user?.approval !== 'APPROVED' && (
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => handleRemove(user?.id)}
                >
                  {intl.formatMessage({id: 'GENERAL.DELETE'})}
                </a>
              </div>
            )}
          </>
        ) : (
          ''
        )}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}
