import {useContext, useEffect, useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {NotificationModel} from '../core/_models'
import {UsersListLoading} from '../components/loading/NotificationListLoading'
import {NotificationListPagination} from '../components/pagination/NotificationListPagination'
import {KTCardBody} from '../../../../../_jmh/helpers'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

const UsersTable = () => {
  const intl = useIntl()
  const isLoading = useQueryResponseLoading()
  const {readNotification, setReadNotification, loginData} = useContext(loginContext)
  const users = useQueryResponseData()

  // Filter the users array to include only the notifications of the logged-in user
  const filteredUsers = useMemo(
    () => users.filter((user) => user.to === loginData?.data?.personal_details?.id),
    [users, loginData]
  )

  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: filteredUsers,
  })

  useEffect(() => {
    setReadNotification(readNotification)
  }, [readNotification])

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<NotificationModel>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<NotificationModel>, i) => {
                prepareRow(row)
                return (
                  <>
                    <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  </>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <NotificationListPagination />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {UsersTable}
