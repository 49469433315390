/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {loginContext} from '../../context/context'
import {GetHospitalById} from '../hospitals/core/_requests'
import HospitalCard from './HospitalCard'

// Create a context for the handleState function
const HospitalContext1 = React.createContext<any>(null)

const HospitalPage: React.FC = () => {
  const [hospitalListData, setHospitalListData] = useState<any>(null)
  const [updated, setupdated] = useState(hospitalListData)
  const {selectedHospitalId} = useContext(loginContext)

  const getHospitalListById = async () => {
    const responseHospitals: any = await GetHospitalById(selectedHospitalId)
    setHospitalListData(responseHospitals)
  }

  function handleState(val: any) {
    setupdated(val)
  }

  useEffect(() => {
    getHospitalListById()
  }, [updated, selectedHospitalId])

  return (
    <>
      <HospitalContext1.Provider value={{handleState}}>
        {hospitalListData && (
          <div className='col-12' key={hospitalListData.id}>
            <HospitalCard cardData={hospitalListData} />
          </div>
        )}
      </HospitalContext1.Provider>
    </>
  )
}

const useHospitalContext = () => useContext(HospitalContext1)

export {HospitalPage, useHospitalContext}
