/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useRef, useState} from 'react'
import {KTSVG, QUERIES, stringifyRequestQuery} from '../../../helpers'
import {AssetDropdown} from '../../content/dropdown/AssetDropdownButton'
import {loginContext} from '../../../../app/context/context'
import {useQuery} from 'react-query'
import moment from 'moment'
import {getAssetMaintedData} from '../core/request'
import {useQueryRequest} from '../../../../app/pages/asset-maintenance/assetMaintenance-List/core/QueryRequestProvider'
import {useIntl} from 'react-intl'

type Props = {
  className: string
}

const AssetLists: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const {state} = useQueryRequest()
  const [query] = useState<string>(
    stringifyRequestQuery({...state, start_date: startDate, end_date: endDate})
  )
  const {selectedAssetMaitenance, setSelectedAssetMaitenance, selectedHospitalId} =
    useContext(loginContext)
  const [dateRange, setDateRange] = useState<Date[] | null>([])
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const colors = ['primary', 'success', 'danger', 'warning', 'info']

  const {data: response, refetch} = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    () => {
      return getAssetMaintedData(query, selectedHospitalId)
    },
    {cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    refetch()
    if (response?.data) {
      setSelectedAssetMaitenance(response?.data)
    }
  }, [selectedHospitalId, response])

  const handleApplyFilter = () => {
    let filtered = response?.data
    if (startDate && endDate) {
      filtered = filtered?.filter((asset: any) =>
        moment(asset?.date).isBetween(moment(startDate), moment(endDate), null, '[]')
      )
    }
    setSelectedAssetMaitenance(filtered)
  }
  const handleReset = () => {
    setStartDate(null)
    setEndDate(null)
    setDateRange(null)
    return setSelectedAssetMaitenance(response?.data)
  }

  return (
    <div className={`card ${className}`}>
      <div className=''>
        <div className='gy-7 gs-7'>
          <div
            className='d-flex flex-wrap gap-3 gap-sm-0 align-item-center justify-content-between border-0 px-9 py-9'
            // style={{padding: '2rem 2.25rem 0rem'}}
          >
            <h4 className=' d-flex align-items-center justify-content-center flex-column mb-0'>
              <span className='card-label fw-bold text-dark'>
                {intl.formatMessage({id: 'ASSET.ASSET.MAINTENANCE'})}
              </span>
            </h4>
            <div className='d-flex'>
              <div className='d-flex'>
                <div className='d-flex gap-2 card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary btn-active-primary px-2'
                    onClick={() => {
                      const filtered = response?.data?.filter((asset: any) =>
                        moment(asset?.date).isAfter(moment())
                      )
                      setSelectedAssetMaitenance(filtered)
                    }}
                  >
                    {intl.formatMessage({id: 'GENERAL.UPCOMING'})}
                  </button>

                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary btn-active-primary px-2'
                    onClick={() => {
                      const filtered = response?.data?.filter((asset: any) =>
                        moment(asset?.date).isSame(moment(), 'day')
                      )
                      setSelectedAssetMaitenance(filtered)
                    }}
                  >
                    {intl.formatMessage({id: 'GENERAL.TODAY'})}
                  </button>

                  <button
                    type='reset'
                    onClick={handleReset}
                    className='btn btn-sm btn-icon btn-color-muted btn-active-light-primary'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen072.svg' className='svg-icon-2' />
                  </button>

                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen071.svg' className='svg-icon-2' />
                  </button>
                  <AssetDropdown
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onApply={handleApplyFilter}
                  />
                </div>
              </div>
            </div>
          </div>

          {selectedAssetMaitenance && selectedAssetMaitenance.length > 0 ? (
            <div className='card-body pt-9 overflow-y-scroll h-100 mh-300px d-block w-100 '>
              {selectedAssetMaitenance.map((asset: any, index: any) => (
                <div className='d-flex align-items-center mb-10' key={index}>
                  <span
                    className={`bullet bullet-vertical h-40px bg-${colors[index % colors.length]}`}
                  ></span>
                  <div className='flex-grow-1 ms-5'>
                    <h4 className='text-gray-800  fw-bold fs-6'> {asset?.asset_id?.name}</h4>
                    <span className='text-muted fw-semibold d-block'>{asset?.type}</span>
                  </div>
                  <span
                    className={`badge badge-light-${colors[index % colors.length]} fs-8 fw-bold`}
                  >
                    {moment(asset?.date).format('DD-MM-YYYY')}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className='d-flex justify-content-center align-items-center mt-10 py-2'>
              <span className='fw-bold'>
                {intl.formatMessage({id: 'STAFF_DASHBOARD.NO.ASSET'})}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {AssetLists}
