/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {loginContext} from '../../../../../context/context'

type Props = {
  id: ID
}

const SpecialityActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const {loginData} = useContext(loginContext)
  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'speciality') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  return (
    <>
      {edit.length ? (
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm px-4'
          onClick={openEditModal}
          // data-kt-menu-trigger='click'
          // data-kt-menu-placement='bottom-end'
        >
          <span>
            <i className='bi bi-pencil-square fs-2'></i>
          </span>
        </a>
      ) : (
        ''
      )}

      {/* begin::Menu */}
      {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-2'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div>
      </div> */}
      {/* end::Menu */}
    </>
  )
}

export {SpecialityActionsCell}
