/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC, useState, useEffect} from 'react'
import {Appointment} from '../../core/_models'

type Props = {
  user: Appointment
}

const AppointmentSlot: FC<Props> = ({user}) => {
  const [slotValue, setSlotValue] = useState('')

  useEffect(() => {
    if (user?.type === 'NOW') {
      setSlotValue(moment(user?.appointment_details?.[0]?.created_at).format('HH:mm'))
    } else {
      setSlotValue(user?.appointment_details?.[0]?.slot ?? '')
    }
  }, [user])

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column text-gray-800 mb-1'>{slotValue}</div>
    </div>
  )
}

export {AppointmentSlot}
