/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {loginContext} from '../../../../app/context/context'
import {useContext, useEffect, useState} from 'react'
import {getPermission} from '../../../../app/pages/roles-and-permissions/Roleslist/core/_requests'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  const {loginData} = useContext(loginContext)
  const [getAllPermissions, setGetAllPermissions] = useState([])
  const {selectedPatientTab, setSelectedPatientTab} = useContext(loginContext)
  const [tab, setTab] = useState(selectedPatientTab)

  function checkPermission(moduleName: string): boolean {
    const permissions = loginData?.data?.permissions || []
    const modulePermissions = permissions.filter(
      (permission: any) => permission.module?.name === moduleName
    )
    return modulePermissions.length > 0
  }

  useEffect(() => {
    ;(async () => {
      const permission = await getPermission()
      setGetAllPermissions(permission)
    })()
  }, [])

  const superAdminPermissions = getAllPermissions.flatMap((item: any) =>
    item.permissions.map((p: any) => ({
      module_id: item.id,
      permission_id: p.id,
      module: {
        id: item.id,
        name: item.name,
      },
      permission: p,
    }))
  )

  if (loginData?.data?.user_type === 'SUPER_ADMIN') {
    loginData.data.permissions = superAdminPermissions
  }

  const handleMenuItemClick = (tabName: any) => {
    setTab(tabName)
    setSelectedPatientTab(tabName)
  }
  useEffect(() => {
    setSelectedPatientTab(tab)
  }, [tab, selectedPatientTab])

  const allowedModules = new Set(
    loginData?.data?.user_type === 'SUPER_ADMIN'
      ? getAllPermissions.map((item: any) => item.name)
      : loginData?.data?.permissions
          ?.filter((item: any) => item.permission.route === '' && item.permission.method === 'GET')
          .map((item: any) => item.module.name)
  )

  const appointment_list = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-appointment') {
      return item?.module_id
    }
  })

  const add = appointment_list?.filter((item: any) => {
    if (item.permission.method === 'GET' && item.permission.route === 'appointment') {
      return item
    }
  })

  return (
    <div
      className='aside-menu-main'
      style={{
        maxHeight: 'calc(100vh - 160px)',
        overflowY: 'auto',
        scrollbarColor: '#4d4d69 transparent',
      }}
    >
      {/* Appointments */}
      <AsideMenuItem
        to='doctor-all-information'
        icon='/media/icons/duotune/general/gen081.svg'
        title={intl.formatMessage({id: 'DOCTOR.ALL_INFORMATION'})}
        moduleName=''
      />

      {/* dashboard */}
      {loginData?.data?.role?.id === '1' ? (
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/general/gen076.svg'
          title={intl.formatMessage({id: 'MENU.CALENDER'})}
          moduleName=''
        />
      ) : (
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/general/gen076.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          moduleName=''
        />
      )}

      {/* Role and Permission */}
      {allowedModules.has('roles') && (
        <AsideMenuItem
          to='/roles-permissions'
          icon='/media/icons/duotune/general/gen077.svg'
          title={intl.formatMessage({id: 'ROLE.PERMISSION'})}
          moduleName='role'
        />
      )}

      {/* Patient  */}
      {checkPermission('patient') ||
      checkPermission('patient-insurance') ||
      checkPermission('patient-appointment') ||
      checkPermission('patient-billing') ||
      checkPermission('ad-patient') ||
      checkPermission('patient-medication') ||
      checkPermission('patient-medical-notes') ||
      checkPermission('patient-vitals') ||
      checkPermission('patient-medical-background') ? (
        <AsideMenuItemWithSub
          to='/patient-data'
          title={intl.formatMessage({id: 'GENERAL.PATIENT'})}
          icon='/media/icons/duotune/medicine/med013.svg'
        >
          {allowedModules.has('patient') && (
            <AsideMenuItem
              to='/patient-data/patient-management'
              title={intl.formatMessage({id: 'PATIENT.PATIENT_MANAGEMENT'})}
              hasBullet={true}
              moduleName='patient'
            />
          )}
          {allowedModules.has('patient-insurance') && (
            <AsideMenuItem
              to='/patient-data/patient-insurance'
              title={intl.formatMessage({id: 'PATIENT.INSURNCE'})}
              hasBullet={true}
              moduleName='patient-insurance'
            />
          )}

          {allowedModules.has('patient-appointment') && (
            <AsideMenuItem
              to='/patient-data/patient-appointment'
              title={intl.formatMessage({id: 'PATIENT.APPOINTMENT'})}
              hasBullet={true}
              moduleName='patient-appointment'
            />
          )}

          {allowedModules.has('patient-billing') && (
            <AsideMenuItem
              to='/patient-data/patient-billing'
              title={intl.formatMessage({id: 'PATIENT.BILLING'})}
              hasBullet={true}
              moduleName='patient-billing'
            />
          )}

          {allowedModules.has('ad-patient') && (
            <AsideMenuItem
              to='/patient-data/ad-patient'
              title={intl.formatMessage({id: 'PATIENT.ADT'})}
              hasBullet={true}
              moduleName='ad-patient'
            />
          )}
          {checkPermission('patient-medication') ||
          checkPermission('patient-medical-notes') ||
          checkPermission('patient-vitals') ||
          checkPermission('patient-medical-background') ||
          checkPermission('patient') ? (
            <AsideMenuItemWithSub
              to='/patient-data/patient-details'
              title={intl.formatMessage({id: 'PATIENT.DETAILS'})}
              hasBullet={true}
            >
              {allowedModules.has('patient-medication') && (
                <AsideMenuItem
                  to='/patient-data/patient-details/medications'
                  title={intl.formatMessage({id: 'PATIENT_DETAILS.MEDICATIONS'})}
                  hasBullet={true}
                  onClick={() => handleMenuItemClick('Medications')}
                  moduleName='patient-medication'
                />
              )}
              {allowedModules.has('patient-medical-notes') && (
                <AsideMenuItem
                  to='/patient-data/patient-details/medical-notes'
                  title={intl.formatMessage({id: 'PATIENT_DETAILS.MEDICAL.NOTES'})}
                  hasBullet={true}
                  onClick={() => handleMenuItemClick('Medical-Notes')}
                  moduleName='patient-medical-notes'
                />
              )}
              {allowedModules.has('patient-vitals') && (
                <AsideMenuItem
                  to='/patient-data/patient-details/patient-vital'
                  title={intl.formatMessage({id: 'PATIENT_DETAILS.PATIENT.VITAL'})}
                  hasBullet={true}
                  onClick={() => handleMenuItemClick('Patient-Vital')}
                  moduleName='patient-vitals'
                />
              )}
              {allowedModules.has('patient-medical-background') && (
                <AsideMenuItem
                  to='/patient-data/patient-details/medical-background'
                  title={intl.formatMessage({id: 'PATIENT_DETAILS.MEDICAL.BACKGROUND'})}
                  hasBullet={true}
                  onClick={() => handleMenuItemClick('Medical-Background')}
                  moduleName='patient-medical-background'
                />
              )}
              {allowedModules.has('patient') && (
                <AsideMenuItem
                  to='/patient-data/patient-details/attachments'
                  title={intl.formatMessage({id: 'PATIENT_DETAILS.ATTACHMENTS'})}
                  hasBullet={true}
                  onClick={() => handleMenuItemClick('Attachments')}
                  moduleName='patient'
                />
              )}
            </AsideMenuItemWithSub>
          ) : (
            ''
          )}
        </AsideMenuItemWithSub>
      ) : (
        ''
      )}

      {/* staff  */}
      {checkPermission('staff') ||
      checkPermission('shift') ||
      checkPermission('staff-salary-strcuture') ||
      checkPermission('staff-payroll') ? (
        <AsideMenuItemWithSub
          to='staff'
          icon='/media/icons/duotune/medicine/med012.svg'
          title={intl.formatMessage({id: 'STAFF.STAFF'})}
        >
          {allowedModules.has('staff') && (
            <AsideMenuItem
              to='staff-management'
              title={intl.formatMessage({id: 'STAFF.STAFF.MANAGEMENT'})}
              moduleName='staff'
              hasBullet={true}
            />
          )}
          {allowedModules.has('shift') && (
            <AsideMenuItem
              to='/staff/shift-management'
              title={intl.formatMessage({id: 'SHIFT.SHIFT.MANAGEMENT'})}
              moduleName='shift'
              hasBullet={true}
            />
          )}
          {allowedModules.has('staff-salary-strcuture') && (
            <AsideMenuItem
              to='/staff/salary-structure'
              title={intl.formatMessage({id: 'STAFF.SALARY.STRUCTURE'})}
              moduleName='staff-salary-strcuture'
              hasBullet={true}
            />
          )}
          {allowedModules.has('staff-payroll') && (
            <AsideMenuItem
              to='/staff/payroll'
              title={intl.formatMessage({id: 'STAFF.PAYROLL'})}
              moduleName='staff-payroll'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : (
        ''
      )}
      {/* patient-appointment */}

      {/* Doctor  */}
      {loginData?.data?.role?.id === '1' && allowedModules.has('doctor-availability') ? (
        <AsideMenuItem
          to='doctor-availability'
          title={intl.formatMessage({id: 'DOCTOR.DOCTOR.AVAILABILITY'})}
          icon='/media/icons/duotune/general/gen082.svg'
          moduleName='doctor-availability'
          // hasBullet={true}
        />
      ) : checkPermission('doctor') ||
        checkPermission('doctor-availability') ||
        // checkPermission('doctor-appointment') ||
        checkPermission('patient-appointment') ? (
        <AsideMenuItemWithSub
          to='/doctor'
          icon='/media/icons/duotune/medicine/med011.svg'
          title={intl.formatMessage({id: 'DOCTOR.DOCTOR'})}
        >
          {allowedModules.has('doctor') && (
            <AsideMenuItem
              to='doctor-management'
              title={intl.formatMessage({id: 'DOCTOR.DOCTOR.MANAGEMENT'})}
              moduleName='doctor'
              hasBullet={true}
            />
          )}
          {allowedModules.has('doctor-availability') && (
            <AsideMenuItem
              to='doctor-availability'
              title={intl.formatMessage({id: 'DOCTOR.DOCTOR.AVAILABILITY'})}
              moduleName='doctor-availability'
              hasBullet={true}
            />
          )}

          {/* {allowedModules.has('doctor-appointment') && (
        <AsideMenuItem
          to='doctor-appointment'
          title={intl.formatMessage({ id: 'DOCTOR.APPOINTMENTS' })}
          moduleName='doctor-appointment'
          hasBullet={true}
        />
      )} */}
          {/* {allowedModules.has('patient-appointment') && add.length > 0 && (
        <AsideMenuItem
          to='doctor-all-information'
          title={intl.formatMessage({ id: 'DOCTOR.ALL_INFORMATION' })}
          moduleName='doctor-all-information'
          hasBullet={true}
        />
      )} */}
        </AsideMenuItemWithSub>
      ) : (
        ''
      )}

      {/* Asset  */}
      {checkPermission('asset') ||
      checkPermission('asset-category') ||
      checkPermission('asset-maintenance') ? (
        <AsideMenuItemWithSub
          to='/asset'
          icon='/media/icons/duotune/general/gen078.svg'
          title={intl.formatMessage({id: 'ASSET.ASSET'})}
        >
          {allowedModules.has('asset-category') && (
            <AsideMenuItem
              to='asset-category'
              title={intl.formatMessage({id: 'ASSET.ASSET.CATEGORY'})}
              moduleName='asset-category'
              hasBullet={true}
            />
          )}

          {allowedModules.has('asset') && (
            <AsideMenuItem
              to='asset-management'
              title={intl.formatMessage({id: 'ASSET.ASSET.MANAGEMENT'})}
              moduleName='asset'
              hasBullet={true}
            />
          )}

          {allowedModules.has('asset-maintenance') && (
            <AsideMenuItem
              to='asset-maintenance'
              title={intl.formatMessage({id: 'ASSET.ASSET.MAINTENANCE'})}
              moduleName='asset-maintenance'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : (
        ''
      )}

      {/* Inventory  */}
      {checkPermission('inventory-category') ||
      checkPermission('inventory') ||
      checkPermission('assign-inventory') ? (
        <AsideMenuItemWithSub
          to='/inventory'
          icon='/media/icons/duotune/general/gen079.svg'
          title={intl.formatMessage({id: 'GENERAL.INVENTORY'})}
        >
          {allowedModules.has('inventory-category') && (
            <AsideMenuItem
              to='inventory/inventory-category'
              title={intl.formatMessage({id: 'INVENTORY.INVENTORY_CATEGORY'})}
              moduleName='inventory-category'
              hasBullet={true}
            />
          )}
          {allowedModules.has('inventory') && (
            <AsideMenuItem
              to='inventory/inventory-management'
              title={intl.formatMessage({id: 'INVENTORY.INVENTORY_MANAGEMENT'})}
              moduleName='inventory'
              hasBullet={true}
            />
          )}
          {allowedModules.has('inventory') && (
            <AsideMenuItem
              to='inventory/purchase-inventory'
              title={intl.formatMessage({id: 'INVENTORY.PURCHASE_INVENTORY'})}
              moduleName='inventory'
              hasBullet={true}
            />
          )}

          {allowedModules.has('assign-inventory') && (
            <AsideMenuItem
              to='inventory/assign-inventory'
              title={intl.formatMessage({id: 'INVENTORY.ASSIGN_INVENTORY'})}
              moduleName='assign-inventory'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : (
        ''
      )}

      {loginData?.data?.role?.id === '1' ? (
        <AsideMenuItem
          to='master/operation-theatre'
          title={intl.formatMessage({id: 'OPERATION_THEATRE'})}
          moduleName='operation-theatre'
          icon='/media/icons/duotune/general/gen083.svg'
          // hasBullet={true}
        />
      ) : checkPermission('hospital') ||
        checkPermission('speciality') ||
        checkPermission('hospital-room') ||
        checkPermission('operation-theatre') ||
        checkPermission('audit-logs') ||
        checkPermission('patient-medicine-master') ? (
        <AsideMenuItemWithSub
          to='/master'
          icon='/media/icons/duotune/general/gen080.svg'
          title={intl.formatMessage({id: 'GENERAL.MASTER'})}
        >
          {allowedModules.has('hospital') && (
            <AsideMenuItem
              to='master/hospitals'
              title={intl.formatMessage({id: 'HOSPITAL_INFORMATION'})}
              moduleName='hospital'
              hasBullet={true}
            />
          )}
          {allowedModules.has('speciality') && (
            <AsideMenuItem
              to='master/specialities'
              title={intl.formatMessage({id: 'SPECIALITIES'})}
              moduleName='speciality'
              hasBullet={true}
            />
          )}
          {allowedModules.has('hospital-room') && (
            <AsideMenuItem
              to='master/hospital-room'
              title={intl.formatMessage({id: 'ROOM_MANAGEMENT'})}
              moduleName='hospital-room'
              hasBullet={true}
            />
          )}
          {allowedModules.has('operation-theatre') && (
            <AsideMenuItem
              to='master/operation-theatre'
              title={intl.formatMessage({id: 'OPERATION_THEATRE'})}
              moduleName='operation-theatre'
              hasBullet={true}
            />
          )}
          {allowedModules.has('audit-logs') && (
            <AsideMenuItem
              to='master/audit-logs'
              title={intl.formatMessage({id: 'AUDIT_LOGS'})}
              moduleName='audit-logs'
              hasBullet={true}
            />
          )}
          {allowedModules.has('patient-medicine-master') && (
            <AsideMenuItem
              to='master/patient-medicine-master'
              title={intl.formatMessage({id: 'MEDICINE_MANAGEMENT'})}
              moduleName='patient-medicine-master'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : (
        ''
      )}

      {/* Master   */}
      {/* {checkPermission('hospital') ||
      checkPermission('speciality') ||
      checkPermission('hospital-room') ||
      checkPermission('operation-theatre') ||
      checkPermission('audit-logs') ||
      checkPermission('patient-medicine-master') ? (
        <AsideMenuItemWithSub
          to='/master'
          icon='/media/icons/duotune/general/gen080.svg'
          title={intl.formatMessage({id: 'GENERAL.MASTER'})}
        >
          {allowedModules.has('hospital') && (
            <AsideMenuItem
              to='master/hospitals'
              title={intl.formatMessage({id: 'HOSPITAL_INFORMATION'})}
              moduleName='hospital'
              hasBullet={true}
            />
          )}
          {allowedModules.has('speciality') && (
            <AsideMenuItem
              to='master/specialities'
              title={intl.formatMessage({id: 'SPECIALITIES'})}
              moduleName='speciality'
              hasBullet={true}
            />
          )}
          {allowedModules.has('hospital-room') && (
            <AsideMenuItem
              to='master/hospital-room'
              title={intl.formatMessage({id: 'ROOM_MANAGEMENT'})}
              moduleName='hospital-room'
              hasBullet={true}
            />
          )}
          {allowedModules.has('operation-theatre') && (
            <AsideMenuItem
              to='master/operation-theatre'
              title={intl.formatMessage({id: 'OPERATION_THEATRE'})}
              moduleName='operation-theatre'
              hasBullet={true}
            />
          )}
          {allowedModules.has('audit-logs') && (
            <AsideMenuItem
              to='master/audit-logs'
              title={intl.formatMessage({id: 'AUDIT_LOGS'})}
              moduleName='audit-logs'
              hasBullet={true}
            />
          )}
          {allowedModules.has('patient-medicine-master') && (
            <AsideMenuItem
              to='master/patient-medicine-master'
              title={intl.formatMessage({id: 'MEDICINE_MANAGEMENT'})}
              moduleName='patient-medicine-master'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : (
        ''
      )} */}
    </div>
  )
}
