import axios, {AxiosResponse} from 'axios'

import {ID, Response} from '../../../../../_jmh/helpers'
import {Medication, MedicationQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const MEDICATION_URL = `${API_URL}/patient-medication`
const GET_MEDICINE_URL = `${API_URL}/patient-medicine-master`
const getMedicine = (): Promise<MedicationQueryResponse> => {
  return axios
    .get(`${GET_MEDICINE_URL}`)
    .then((d: AxiosResponse<MedicationQueryResponse>) => d.data)
}

const getMedicineById = (id: ID): Promise<MedicationQueryResponse | undefined> => {
  return axios
    .get(`${GET_MEDICINE_URL}/${id}`)
    .then((response: AxiosResponse<Response<MedicationQueryResponse>>) => response.data)
    .then((response: Response<MedicationQueryResponse>) => response.data)
}

const getPatientMedication = (
  query: string,
  id: number,
  hosId: number,
  appointId: any
): Promise<MedicationQueryResponse> => {
  return axios
    .get(
      `${MEDICATION_URL}?${
        query
          ? `${query}&order=DESC&sort=assigned_date&limit=10&patient_id=${id}&hospital_id=${hosId}&appointment_id=${appointId}`
          : `limit=10&hospital_id=${hosId}&patient_id=${id}`
      }`
    )
    .then((d: AxiosResponse<MedicationQueryResponse>) => d.data)
}

const getPatientMedicationById = (id: ID): Promise<Medication | undefined> => {
  return axios
    .get(`${MEDICATION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Medication>>) => response.data)
    .then((response: Response<Medication>) => response.data)
}

const createPatientMedication = (user: any): Promise<Medication | undefined> => {
  return axios.post(MEDICATION_URL, user)
}

const updatePatientMedication = (user: Medication): Promise<Medication | undefined> => {
  return axios.patch(`${MEDICATION_URL}/${user.id}`, user)
}

export {
  getPatientMedication,
  getMedicine,
  getMedicineById,
  getPatientMedicationById,
  createPatientMedication,
  updatePatientMedication,
}
