import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AsideMinimizeState {
  asideMinimize: boolean;
}

const initialState: AsideMinimizeState = {
  asideMinimize: false,
};

const asideMinimizeSlice = createSlice({
  name: 'asideMinimize',
  initialState,
  reducers: {
    setAsideMinimize(state, action: PayloadAction<boolean>) {
      state.asideMinimize = action.payload;
    },
    resetAsideMinimize(state) {
      state.asideMinimize = false;
    }
  },
});

export const { setAsideMinimize, resetAsideMinimize } = asideMinimizeSlice.actions;
export default asideMinimizeSlice.reducer;
