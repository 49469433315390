import {useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_jmh/helpers'
import Slider from 'react-slick'
import {getLandingFacilityData} from './core/_request'
import {useIntl} from 'react-intl'

const LandingOurFacility = () => {
  const sliderRef = useRef<any>(null)
  const intl = useIntl()
  const [facilityData, setFacilityData] = useState<any>(null)

  const fetchfacilityData = () => {
    getLandingFacilityData().then((res) => {
      setFacilityData(res?.data?.data)
    })
  }
  useEffect(() => {
    fetchfacilityData()
  }, [])

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  }

  const truncateDescription = (description: string, wordCount: number) => {
    const words = description?.split(' ')
    if (words?.length > wordCount) {
      return words.slice(0, wordCount).join(' ') + '...'
    }
    return description
  }

  return (
    <section className='landing_our_facilities section-padding mt-75' id='facility'>
      <div className='landing_container'>
        <div className='of_container'>
          <div className='row'>
            <div className='col-8 position-relative'>
              <div
                className='of_dots'
                style={{
                  backgroundImage: `url('${toAbsoluteUrl('/media/landing_asset/Dots.svg')}')`,
                }}
              ></div>
              <div className='row'>
                <Slider ref={sliderRef} {...settings}>
                  {facilityData?.map((item: any, index: number) => {
                    return (
                      <div className='col-4' key={index}>
                        <div className='of_item'>
                          <div>
                            <div className='of_defaultcard'>
                              <figure>
                                <img src={item?.image} alt={`img ${item?.title}`} />
                              </figure>
                              <div className='of_card_text_content'>
                                <h6>{item?.title}</h6>
                                <p>{truncateDescription(item?.description, 15)}</p>
                              </div>
                              <div className='of_card_button'>
                                <button type='button'>
                                  {intl.formatMessage({id: 'GENERAL.SEE.DETAILS'})}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
            <div className='col-4'>
              <div className='position-relative h-100'>
                <div className='of_right'></div>
                <div className='of_content position-relative'>
                  <KTSVG path='/media/landing_asset/of_icon.svg' />
                  <h3>{intl.formatMessage({id: 'LANDING_PAGE.FACILITY.TITLE'})}</h3>
                </div>
                <div className='custom-slider-buttons'>
                  <button
                    className='custom-prev-button'
                    onClick={() => sliderRef.current!.slickPrev()}
                  >
                    <i className='fa-solid fa-chevron-left'></i>
                  </button>
                  <button
                    className='custom-next-button'
                    onClick={() => sliderRef.current!.slickNext()}
                  >
                    <i className='fa-solid fa-chevron-right'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingOurFacility
