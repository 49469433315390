/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deletePatientDoc} from '../../core/_requests'
import {loginContext} from '../../../../../context/context'

type Props = {
  id: ID
}

const PatientDocActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {loginData} = useContext(loginContext)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(
    () =>
      deletePatientDoc(id).then((res: any) => {
        toast.error(res?.data?.message)
      }),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      },
    }
  )

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient') {
      return item?.module_id
    }
  })

  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE' && item.permission.route === 'delete-document') {
      return item
    }
  })

  return (
    <>
      {/* {deleteData.length ? ( */}
      <button
        // href='#'
        className='btn btn-light-danger btn-active-danger btn-sm px-4 py-2 me-5  justify-content-end'
        // onClick={openEditModal}
        // data-kt-menu-trigger='click'
        // data-kt-menu-placement='bottom-end'
        onClick={async () => await deleteItem.mutateAsync()}
      >
        <KTSVG
          path='/media/icons/duotune/general/gen027.svg'
          className='svg-icon-muted m-0 fs-3'
          data-kt-users-table-filter='delete_row'
        />
      </button>
      {/* ) : (
        ''
      )} */}

      {/* <i className='bi bi-pencil-square fs-2'></i> */}

      {/* Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
         */}
      {/* <div className='menu-item px-2'>
          <a className='menu-link px-4 justify-content-end' onClick={openEditModal}>
            Edit
            <i className='bi bi-pencil-square pe-3'></i>
          </a>
        </div> */}
      {/* begin::Menu */}
      {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-100px py-2'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div>
      </div> */}
      {/* end::Menu */}
    </>
  )
}

export {PatientDocActionsCell}
