import React from 'react'
import {useIntl} from 'react-intl'

interface DoctorDetailsModalProps {
  showDoctorDetailsModal: boolean
  selectedDoctor: any
  closeDoctorDetailsModal: () => void
  specialityData: any
}

const DoctorDetailsModal: React.FC<DoctorDetailsModalProps> = ({
  showDoctorDetailsModal,
  selectedDoctor,
  closeDoctorDetailsModal,
  specialityData,
}) => {
  const intl = useIntl()

  const filteredSpecialities = specialityData.filter((speciality: any) =>
    selectedDoctor.speciality_ids.includes(speciality.id)
  )
  const specialityNames = filteredSpecialities.map((speciality: any) => speciality.name)

  return (
    <>
      <div
        className={`modal fade${showDoctorDetailsModal ? ' show' : ''}`}
        id='doctorDetailsModal'
        tabIndex={-1}
        style={{display: showDoctorDetailsModal ? 'block' : 'none'}}
        onClick={closeDoctorDetailsModal}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='modal-header'>
              <h2 className='modal-title'>{intl.formatMessage({id: 'DOCTOR.DETAILS'})}</h2>
              <button
                type='button'
                className='btn-close'
                onClick={closeDoctorDetailsModal}
              ></button>
            </div>
            <div className='modal-body'>
              <>
                <div className='d-flex flex-wrap align-align-items-start gap-3'>
                  {selectedDoctor?.image && (
                    <div className='doctor_img mb-5'>
                      <img
                        className='rounded-1'
                        src={selectedDoctor?.image}
                        alt={`img ${selectedDoctor?.full_name}`}
                      />
                    </div>
                  )}
                  <div className='landing_doctor_info'>
                    <p className='text-capitalize'>
                      <b>{intl.formatMessage({id: 'GENERAL.NAME'})} </b> :
                      {selectedDoctor?.full_name}
                    </p>
                    <p>
                      <b>{intl.formatMessage({id: 'GENERAL.SPECIALITY'})} </b>:
                      {specialityNames.join(', ')}
                    </p>
                    <p>
                      <b>{intl.formatMessage({id: 'GENERAL.QUALIFICATION'})} </b> :{' '}
                      {selectedDoctor?.qualification}
                    </p>
                    <p>
                      <b>{intl.formatMessage({id: 'DOCTOR.YEARS.OF.EXPERIENCE'})} </b> :{' '}
                      {selectedDoctor?.years_of_experience}
                    </p>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {showDoctorDetailsModal && <div className='modal-backdrop fade show'></div>}
    </>
  )
}

export default DoctorDetailsModal
