import {ID, Response} from '../../../../../_jmh/helpers'

export type Salary = {
  id?: ID
  gross_salary?: number | null | undefined
  staff_id?: number | null | undefined
  hospital_id?: number | null | undefined
  professional_tax?: number | null | undefined
  salary_month?: number | null | undefined
  salary_year?: number | null | undefined
  salary_year_and_month?: number | null | undefined
  basic_salary?: number | null | undefined
  house_rent_allowance?: number | null | undefined
  medical_insurance_allowance?: number | null | undefined
  conveyance_transportation_allowance?: number | null | undefined
  meal_allowance?: number | null | undefined
  entertainment_allowance?: number | null | undefined
  variable_pay?: number | null | undefined
  other_allowance?: number | null | undefined
  bonus?: number | null | undefined
  incentive?: number | null | undefined
  through_pay_slip?: number | null | undefined
  EPF_deduction?: number | null | undefined
  initials?: {
    label?: string
    state?: string
  }

  staff?: {
    id?: string
    full_name?: string
    gender?: string
    image?: string
    date_of_birth?: string
    permanent_address?: string
    contact_number?: string
    job_title?: string
    speciality_id?: string
    user_id?: string
    shift_id?: string
    status?: string
    created_at?: string
    updated_at?: string
  }
}

export type UsersQueryResponse = Response<Array<Salary>>

export const initialUser: Salary = {
  gross_salary: undefined,
  professional_tax: undefined,
  salary_month: undefined,
  salary_year_and_month: undefined,
}
