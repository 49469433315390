import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {NotificationListWrapper} from './notification-list/NotificationList'
import {useIntl} from 'react-intl'

const NotificationPageView: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'NOTIFICATION.NOTIFICATIONS'})}
      </PageTitle>
      <NotificationListWrapper />
    </>
  )
}

export default NotificationPageView
