// @ts-nocheck
import {Column} from 'react-table'
import {RoleActionsCell} from './RoleActionsCell'
import {RoleCustomHeader} from './RoleCustomHeader'
import {RoleInfoCell} from './RoleInfoCell'
import {User} from '../../core/_models'
import RoleDescription from './RoleDescription'
import {FormattedMessage} from 'react-intl'

const rolesColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <RoleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GENERAL.NAME' />}
        className='min-w-125px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => <RoleInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <RoleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GENERAL.DESCRIPTION' />}
        className='min-w-125px'
      />
    ),
    accessor: 'description',
    Cell: ({...props}) => <RoleDescription user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <RoleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GENERAL.ACTION' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <RoleActionsCell id={props.data[props.row.index].id} />,
  },
]

export {rolesColumns}
