/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Medicine} from '../../core/_models'

type Props = {
  medicine: Medicine
}

const MedicineFrequencyCell: FC<Props> = ({medicine}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>{medicine?.frequency}</span>
      </div>
    </div>
  )
}

export {MedicineFrequencyCell}
