import {AppointmentListToolbar} from './AppointmentListToolbar'
import {AppointmentsListSearchComponent} from './AppointmentsListSearchComponent'

const AppointmentsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <AppointmentsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <AppointmentListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {AppointmentsListHeader}
