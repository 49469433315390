import { ID, Response } from "../../../../../_jmh/helpers"

export type Medication = {
  
  
  id?: ID
  medication_name?: string
  dosage?:string
  frequency?:string
  duration?:number | null
  instruction?: string
  created_at?: string
  updated_at?: string
  assigned_date?: string
  initials?: {
    label: string
    state: string
  }
  hospital_id?: {
    id: string,
    name: string,
    address: string,
    contact_person: string,
    contact_number: number,
    status: string,
  } | number,
  patient_id?: {
    id: string,
    hospital_id:string
    full_name: string,
    gender: string,
    contact_number:number
    date_of_birth:string
    permanent_address: string,
    email:string,
    alternate_contact_number:string,
    blood_group:string
    status:string
  } |number,
  medicine_id?: {
    id: number,
    name: string,
    frequency: string,
    duration: number,
    type: string,
    instruction: string,
    status: string,
    created_at: string,
    updated_at: string
},

medication_data?: Array<{
  medication_name?: string
  duration: number | null
  frequency: string
  medicine_id: number
  assigned_date: string | null
}>

}

export type MedicationQueryResponse = Response<Array<Medication>>

export const initialUser: Medication = {
  medication_name: '',
  frequency:'',
  duration:null,
  instruction:'', 
  assigned_date: '',
  dosage:'',
  medication_data: [
    {
      medication_name: '',
      duration: null,
      frequency: '',
      medicine_id: 1,
      assigned_date: '',
    },
  ],

}
