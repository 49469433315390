import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {MedicalNotesListHeader} from './components/header/MedicalNotesListHeader'
import {MedicalNotesTable} from './table/MedicalNotesTable'
import {MedicalNoteEditModal} from './Medical-Notes-edit-modal/MedicalNoteEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const MedicalNotesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <MedicalNotesListHeader />
        <MedicalNotesTable id={undefined} />
      </KTCard>
      {itemIdForUpdate !== undefined && <MedicalNoteEditModal />}
    </>
  )
}

const MedicalNotesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <MedicalNotesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MedicalNotesListWrapper}
