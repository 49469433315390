/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Payroll} from '../../core/_models'

type Props = {
  user: Payroll
}

const GrossSalary: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden'></div>
      <div className='d-flex flex-column'>
        <span className='text-gray-800  mb-1'>{user?.gross_salary}</span>
      </div>
    </div>
  )
}

export {GrossSalary}
