import {useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {loginContext} from '../../context/context'
import {Table} from 'react-bootstrap'
import {getPatientVitalByPatientId} from './core/_request'
import ReactPaginate from 'react-paginate'
import moment from 'moment'

const OpVitals = ({selectedAppointment}: any) => {
  const intl = useIntl()
  const [vitalsData, setVitalsData] = useState<any>(null)
  const {loginData} = useContext(loginContext)
  const limit = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const pageCount = Math.max(Math.ceil(totalCount / limit), 1)

  const fetchVitalData = () => {
    getPatientVitalByPatientId(
      loginData?.data?.patient_id,
      loginData?.data?.hospital_id,
      selectedAppointment
        ? selectedAppointment?.value === 'all'
          ? ''
          : selectedAppointment?.value
        : ''
    ).then((res: any) => {
      setVitalsData(res?.data)
      setTotalCount(res?.pagination?.meta?.totalItems)
    })
  }

  useEffect(() => {
    fetchVitalData()
  }, [currentPage, selectedAppointment])

  const handlePageClick = (selectedPage: any) => {
    setCurrentPage(selectedPage?.selected + 1)
  }

  return (
    <div className='op_inner_wrapper py-5'>
      <div className='d-flex justify-content-between align-items-center mb-25'>
        <h2 className='op_head_title m-0'>Your Vitals</h2>
      </div>

      <Table striped className='table-striped'>
        <thead>
          <tr>
            <th>Hospital Name</th>
            <th>Blood Pressure</th>
            <th>Temperature</th>
            <th>Respiratory Rate</th>
            <th>Pulse</th>
            <th>Date & Time</th>
          </tr>
        </thead>
        <tbody>
          {vitalsData && vitalsData.length > 0 ? (
            vitalsData.map((item: any) => {
              const createdAt = moment.utc(item?.date_time)
              const date = createdAt.format('DD-MM-YYYY')
              const time = createdAt.format('HH:mm')
              return (
                <tr key={item.id}>
                  <td>{item.patient_id?.hospital?.name}</td>
                  <td>{item.blood_pressure}</td>
                  <td>{item.temprature}</td>
                  <td>{item.Respiratory_rate}</td>
                  <td>{item.pulse}</td>
                  <td>
                    {date} {time}
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={6}>
                <div className='d-flex text-center fs-5 text-dark fw-bold w-100 align-content-center justify-content-center'>
                  {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {vitalsData?.length >= 1 && (
        <nav className='op_pagination'>
          <ReactPaginate
            breakLabel='...'
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName='pagination'
            activeClassName='active'
            previousLabel='Previous'
            nextLabel='Next'
          />
        </nav>
      )}
    </div>
  )
}

export default OpVitals
