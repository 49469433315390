import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {AssetCategoryModule, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ASSETCATEGORY_URL = `${API_URL}/asset-category`
const GET_ASSETCATEGORY_URL = `${API_URL}/asset-category`

const getAssetCategory = (query: string, id: number): Promise<UsersQueryResponse> => {
  return axios
    .get(
      `${GET_ASSETCATEGORY_URL}?${
        query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`
      }`
    )
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const createAssetCategory = (
  asset: AssetCategoryModule
): Promise<AssetCategoryModule | undefined> => {
  return axios
    .post(ASSETCATEGORY_URL, asset)
}

const getAssetCategoryById = (id: ID): Promise<AssetCategoryModule | undefined> => {
  return axios
    .get(`${ASSETCATEGORY_URL}/${id}`)
    .then((response: AxiosResponse<Response<AssetCategoryModule>>) => response.data)
    .then((response: Response<AssetCategoryModule>) => response.data)
}

const updateAssetCategory = (
  asset: AssetCategoryModule
): Promise<AssetCategoryModule | undefined> => {
  return axios
    .patch(`${ASSETCATEGORY_URL}/${asset.id}`, asset)
}

export {getAssetCategory, updateAssetCategory, createAssetCategory, getAssetCategoryById}
