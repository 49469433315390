/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {PatientBiling} from '../../core/_models'
import {getPatientInvoiceById} from '../../core/_requests'
import {PatientBillingListLoading} from '../../components/loading/PatientBillingListLoading'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
  user: PatientBiling
}

const PatientBillingActionsCell: FC<Props> = ({id, user}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [isLoading, setIsLoading] = useState(false) // Loading state

  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (billId: any) => {
    navigate(`/patient-data/patient-billing/billingForm/${billId}`)
  }

  // const deleteItem = useMutation(() => deleteMedicalNote(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  // })

  const handleDownload = async (id: any) => {
    setIsLoading(true) // Set loading state to true

    try {
      const getInvoiceData: any = await getPatientInvoiceById(id)
      const uint8Array = new Uint8Array(getInvoiceData?.data)

      const blob = new Blob([uint8Array], {type: 'application/pdf'})

      // Create a temporary URL for the blob
      const url = URL.createObjectURL(blob)

      // Create a temporary link element
      const link = document.createElement('a')
      link.href = url
      link.download = 'invoice.pdf'

      // Programmatically click the link to trigger the download
      link.click()

      // Clean up the temporary URL and link
      URL.revokeObjectURL(url)
      link.remove()
    } catch (error) {
      console.log('Error occurred while downloading invoice:', error)
    } finally {
      setIsLoading(false) // Set loading state to false
    }
  }

  const {loginData, setLoginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-billing') {
      return item?.module_id
    }
  })

  const add = data?.filter((item: any) => {
    if (item.permission.method === 'POST') {
      return item
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm '
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </button>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-100px py-2'
        data-kt-menu='true'
      >
        {edit.length ? (
          <div className='menu-item px-2'>
            <a
              className='menu-link px-4 justify-content-between'
              onClick={(e) => openEditModal(user?.bill_number)}
            >
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </a>
          </div>
        ) : (
          ''
        )}

        {add.length ? (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={(e) => handleDownload(user?.bill_number)}>
              {intl.formatMessage({id: 'GENERAL.INVOICE'})}
            </a>
          </div>
        ) : (
          ''
        )}
      </div>
      {isLoading && <PatientBillingListLoading />} {/* Render the loader */}
      {/* end::Menu */}
    </>
  )
}

export {PatientBillingActionsCell}
