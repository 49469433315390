import {useContext, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_jmh/helpers'
import {useAuth} from '../core/Auth'
import {loginContext} from '../../../context/context'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const {setLoginData, setSelectedHospitalId} = useContext(loginContext)
  const navigate = useNavigate()
  const intl = useIntl()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL.ERROR'}))
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.EMAIL_ERROR'})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'VALIDATION.MIN_PASS_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.PASSWORD_ERROR'}))
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      }),
  })

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const responseUser = await login(values.email, values.password)

        const auth: any = responseUser?.data

        if (responseUser?.status === 200) {
          setLoginData(auth)

          localStorage.setItem('loginData', JSON.stringify(auth))

          const {data} = auth

          const userData: any = {
            id: data?.id,
            email: data?.email,
            isPasswordChange: data?.is_password_change,
            userType: data?.userType,
            status: data?.status,
            access_token: data?.access_token,
          }
          saveAuth(userData)

          if (auth?.data?.is_password_change === true) {
            navigate('/change-password')
          } else {
            setCurrentUser(auth?.data?.access_token)
            if (auth?.data?.user_type === 'PATIENT') {
              navigate('/')
            } else {
              navigate('/doctor-all-information')
            }
          }

          if (auth?.data.user_type === 'SUPER_ADMIN') {
            setSelectedHospitalId(1)
          } else {
            setSelectedHospitalId(auth?.data?.hospital?.id)
          }
        }
      } catch (error: any) {
        saveAuth(undefined)
        setStatus(error?.response?.data?.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='login-form'>
        <div className='container'>
          <div className='row login-form-bg'>
            <div className='col-md-6 col-sm-12 p-0'>
              <div className='login-vector'>
                <img
                  src={toAbsoluteUrl('/media/auth/login.png')}
                  alt='Doctor Vector'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className=' col-md-6 col-sm-12 p-0'>
              <form
                className='login-input-form form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className='text-center'>
                  <h1 className='mt-5 font-weight-bold'>
                    {intl.formatMessage({id: 'GENERAL.SIGN.IN'})}
                  </h1>
                </div>

                {formik.status ? (
                  <div className='alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  ''
                )}

                <div className='input-group'>
                  <label className='form-label fs-6 fw-bolder'>
                    {intl.formatMessage({id: 'GENERAL.EMAIL'})}
                  </label>

                  <div className='position-relative'>
                    <input
                      placeholder={intl.formatMessage({id: 'GENERAL.EMAIL'})}
                      {...formik.getFieldProps('email')}
                      className={clsx('form-control form-control-lg form-control-solid')}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='input-group'>
                  <label className='form-label fs-6 fw-bolder'>
                    {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                  </label>

                  <div className='position-relative'>
                    <input
                      placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx('form-control form-control-lg form-control-solid')}
                    />

                    <button
                      className='btn position-absolute top-0 end-0 bottom-0'
                      type='button'
                      id='show-password'
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <i className='bi bi-eye-fill'></i>
                      ) : (
                        <i className='bi bi-eye-slash-fill'></i>
                      )}
                    </button>
                  </div>

                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='input-group'>
                  <div className='d-flex justify-content-end'>
                    <Link to='/forgot-password' className='link-primary fs-6 fw-bolder'>
                      {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})} ?
                    </Link>
                  </div>
                </div>

                <div className='input-group text-center w-auto'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className='indicator-label fw-bolder fs-4'>
                        {intl.formatMessage({id: 'GENERAL.CONTINUE'})}
                      </span>
                    )}
                    {loading && (
                      <span
                        className='indicator-progress fw-bolder fs-4'
                        style={{display: 'block'}}
                      >
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

    // <div className='d-flex justify-content-center  align-items-center h-100'>
    //   <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
    //     <form
    //       className='form w-100'
    //       onSubmit={formik.handleSubmit}
    //       noValidate
    //       id='kt_login_signin_form'
    //     >
    //       <div className='text-center mb-10'>
    //         <h1 className='text-dark mb-3'>Sign In</h1>
    //       </div>

    //       {formik.status ? (
    //         <div className='mb-lg-15 alert alert-danger'>
    //           <div className='alert-text font-weight-bold'>{formik.status}</div>
    //         </div>
    //       ) : (
    //         ''
    //       )}

    //       <div className='fv-row mb-10'>
    //         <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
    //         <input
    //           placeholder='Email'
    //           {...formik.getFieldProps('email')}
    //           className={clsx('form-control form-control-lg form-control-solid')}
    //           type='email'
    //           name='email'
    //           autoComplete='off'
    //         />
    //         {formik.touched.email && formik.errors.email && (
    //           <div className='fv-plugins-message-container'>
    //             <div className='fv-help-block'>
    //               <span role='alert'>{formik.errors.email}</span>
    //             </div>
    //           </div>
    //         )}
    //       </div>

    //       <div className='fv-row mb-10'>
    //         <div className='d-flex justify-content-between mt-n5'>
    //           <div className='d-flex flex-stack mb-2'>
    //             <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
    //           </div>
    //         </div>
    //         <div className='position-relative'>
    //           <input
    //             placeholder='Password'
    //             type={showPassword ? 'text' : 'password'}
    //             autoComplete='off'
    //             {...formik.getFieldProps('password')}
    //             className={clsx('form-control form-control-lg form-control-solid')}
    //           />

    //           <button
    //             className='btn position-absolute top-0 end-0'
    //             type='button'
    //             id='show-password'
    //             onClick={handleTogglePassword}
    //           >
    //             {showPassword ? (
    //               <i className='bi bi-eye-fill'></i>
    //             ) : (
    //               <i className='bi bi-eye-slash-fill'></i>
    //             )}
    //           </button>
    //         </div>

    //         {formik.touched.password && formik.errors.password && (
    //           <div className='fv-plugins-message-container'>
    //             <div className='fv-help-block'>
    //               <span role='alert'>{formik.errors.password}</span>
    //             </div>
    //           </div>
    //         )}
    //       </div>

    //       <div className='d-flex justify-content-between mb-2'>
    //         <div className='mb-2'>
    //           <Link
    //             to='/forgot-password'
    //             className='link-primary fs-6 fw-bolder'
    //             style={{marginLeft: '5px'}}
    //           >
    //             Forgot Password ?
    //           </Link>
    //         </div>
    //       </div>

    //       <div className='text-center'>
    //         <button
    //           type='submit'
    //           id='kt_sign_in_submit'
    //           className='btn btn-lg btn-primary w-100 mb-5'
    //           disabled={formik.isSubmitting || !formik.isValid}
    //         >
    //           {!loading && <span className='indicator-label'>Continue</span>}
    //           {loading && (
    //             <span className='indicator-progress' style={{display: 'block'}}>
    //               Please wait...
    //               <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //             </span>
    //           )}
    //         </button>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  )
}
