/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Medicine} from '../../core/_models'

type Props = {
  medicine: Medicine
}

const MedicineNameCell: FC<Props> = ({medicine}) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <div className='menu-link px-3 text-gray-800 mb-1'>{medicine?.name}</div>
        </div>
      </div>
    </>
  )
}

export {MedicineNameCell}
