import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {HospitalRoomListHeader} from './components/header/HospitalRoomListHeader'
import {HospitalRoomTable} from './table/HospitalRoomTable'
import {HospitalRoomEditModal} from './HospitalRoom-edit-modal/HospitalRoomEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const HospitalRoomList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <HospitalRoomListHeader />
        <HospitalRoomTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <HospitalRoomEditModal />}
    </>
  )
}

const HospitalRoomListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <HospitalRoomList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {HospitalRoomListWrapper}
