import React, {FC, useState} from 'react'
import DatePicker from 'react-datepicker'
import {useIntl} from 'react-intl'
interface Props {
  setEndDate: any
  setStartDate: any
  startDate: any
  endDate: any
  handleApply: any
}

const OperationsFilter: FC<Props> = ({setEndDate, setStartDate, startDate, endDate}) => {
  const [dateRange, setDateRange] = useState<Date[] | null>([startDate, endDate])
  const intl = useIntl()

  const handleDateChange = (update: Date | [Date | null, Date | null] | null) => {
    if (Array.isArray(update)) {
      const [startDate, endDate] = update
      setStartDate(startDate)
      setEndDate(endDate)
      setDateRange(update as Date[] | null)
    }
  }
  return (
    <>
      <label className='form-label fw-bold'>
        {intl.formatMessage({id: 'GENERAL.OPERATION.TITLE'})}
      </label>
      <div className='position-relative mb-3'>
        <DatePicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          name='dateRange'
          selectsEnd={endDate}
          selectsStart={startDate}
          dateFormat='dd-MM-yyyy'
          className='form-control form-control-solid cursor-pointer mb-3 mb-lg-0'
          onChange={handleDateChange}
          shouldCloseOnSelect={false}
          autoComplete='off'
          onKeyDown={(e) => {
            e.preventDefault()
          }}
        />
        <span
          className='DatePicker_icon cursor-pointer'
          onClick={() => document.getElementsByName('dateRange')[0].focus()}
        >
          <div className='fa fa-calendar'></div>
        </span>
      </div>
    </>
  )
}

export {OperationsFilter}
