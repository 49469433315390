import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_jmh/layout/MasterLayout'
import {Dashboard} from '../pages/dashboard/dashboard'
import {ProfileWrapper} from '../pages/my-profile/MyProfileWrapper'
import {ChnagepasswordWrapper} from '../pages/Changepassword/Changepassword'
import {SupportFeedbackWrapper} from '../pages/support-feedback/SupportFeedbackWrapper'
import {ProfileDetailsSettings} from '../pages/my-profile/Profile/components/ProfileDetailsSettings'
import NotificationPageView from '../pages/notification/NotificationPageView'
import HospitalPageWrapper from '../pages/hospitals/HospitalPageWrapper'
import SpecialityPageWrapper from '../pages/Speciality-management/SpecialityPageWrapper'
import AssetCategoryPageWrapper from '../pages/asset-category/AssetCategoryPageWrapper'
import AssetPageWrapper from '../pages/asset-management/AssetPageWrapper'
import {AssetView} from '../pages/asset-management/ViewAsset/AssetView'
import {AssetMaintenanceWrapper} from '../pages/asset-maintenance/AssetMaintenance'
import {RolesPermissionsWrapper} from '../pages/roles-and-permissions/Rolespermission'
import {DoctorWrapper} from '../pages/Doctor/DoctorWrapper'
import {StaffWrapper} from '../pages/staff-management/StaffWrapper'
import StaffView from '../pages/staff-management/ViewPage/StaffView'
import Rolespermission from '../pages/roles-and-permissions/Permission/Rolespermission'
import DoctorView from '../pages/Doctor/ViewPage/DoctorView'
import InventoryCategoryPageWrapper from '../pages/inventory-category/InventoryCategoryPageWrapper'
import ShiftPageWrapper from '../pages/shift-management/shiftPageWrapper'
import InvManagementPageWrapper from '../pages/Inventory-management/InvManagementListWrapper'
import PurchaseInvPageWrapper from '../pages/purchase-inventory/PurchaseInvPageWrapper'
import HospitalRoomPageWrapper from '../pages/HospitalRoom/HospitalRoomPageWrapper'
import AssignInvPageWrapper from '../pages/assign-inventory/AssignInvPageWrapper'
import {PatientWrapper} from '../pages/Patient-management/PatientWrapper'
import PatientInsurancePageWrapper from '../pages/patient-insurance/PatientInsurancePageWrapper'
import {PatientDetails} from '../pages/Patient-management/PatientDetails/PatientDetails'
import PatientView from '../pages/Patient-management/ViewPatient/PatientView'
import {DoctorAvalabilityWrapper} from '../pages/DoctorAvalability/DoctorAvalabilityWrapper'
import {PatientAppointmentWrapper} from '../pages/Patient-appointment/PatientAppointmentWrapper'
import {PatientAppointmentDoctorWrapper} from '../pages/PatientAppointment-Doctor/PatientAppointmentDoctorWrapper'
import {OperationTheaterWrapper} from '../pages/OperationTheater-Management/OperationTheater'
import {SalaryWrapper} from '../pages/Staff Salary/SalaryWrapper'
import SalaryAddForm from '../pages/Staff Salary/salarys-list/Pages/SalaryAddForm'
import EditSalaryForm from '../pages/Staff Salary/salarys-list/Pages/EditSalaryForm'
import ViewSalary from '../pages/Staff Salary/salarys-list/Pages/ViewSalary'
import {AtdWrapper} from '../pages/ATD-Patient/AtdWrapper'
import Adtview from '../pages/ATD-Patient/ATDS-list/ViewPage/Adtview'
import AuditLogsPageWrapper from '../pages/AuditLogs/AuditLogsPageWrapper'
import PatientBillingPageWrapper from '../pages/Patient-Billing/PatientBillingPageWrapper'
import {BillingForm} from '../pages/Patient-Billing/PatientBillingList/PatientBillEditPages/BillingForm'
import {PayrollWrapper} from '../pages/Staff-Payroll/PayrollWrapper'
import PayrollForm from '../pages/Staff-Payroll/Payrolls-list/Pages/PayrollForm'
import StaffPayroll from '../pages/Staff-Payroll/Payrolls-list/Pages/StaffPayrollView'
import {MedicineMasterWrapper} from '../pages/medicine-master/MedicineMasterWrapper'
import DpLayoutWrapper from '../pages/doctor-prescription/DpLayoutWrapper'
import DoctorAllInformation from '../pages/doctor-all-information/DoctorInfoWrapper'

const SuperAdminPrivateRoute = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/my-profile' element={<ProfileWrapper />} />
        <Route path='/change-password' element={<ChnagepasswordWrapper />} />
        <Route path='/support-feedback' element={<SupportFeedbackWrapper />} />
        <Route path='/my-profile/update-profile' element={<ProfileDetailsSettings />} />
        <Route path='/notification' element={<NotificationPageView />} />
        <Route path='master/hospitals' element={<HospitalPageWrapper />} />
        <Route path='master/specialities' element={<SpecialityPageWrapper />} />
        <Route path='/asset-category' element={<AssetCategoryPageWrapper />} />
        <Route path='/asset-management' element={<AssetPageWrapper />} />
        <Route path='asset-management/asset-view/:id' element={<AssetView />} />
        <Route path='/asset-maintenance' element={<AssetMaintenanceWrapper />} />
        <Route path='/roles-permissions' element={<RolesPermissionsWrapper />} />
        <Route path='/doctor-management' element={<DoctorWrapper />} />
        <Route path='/staff-management' element={<StaffWrapper />} />
        <Route path='/staff-management/staff-view/:id' element={<StaffView />} />
        <Route path='/roles-permissions/permissions/:id' element={<Rolespermission />} />
        <Route path='doctor-management/view/:id' element={<DoctorView />} />
        <Route path='/inventory/inventory-category' element={<InventoryCategoryPageWrapper />} />
        <Route path='/staff/shift-management' element={<ShiftPageWrapper />} />
        <Route path='/inventory/inventory-management' element={<InvManagementPageWrapper />} />
        <Route path='/inventory/purchase-inventory' element={<PurchaseInvPageWrapper />} />
        <Route path='master/hospital-room' element={<HospitalRoomPageWrapper />} />
        <Route path='/inventory/assign-inventory' element={<AssignInvPageWrapper />} />
        <Route path='/patient-data/patient-management' element={<PatientWrapper />} />
        <Route path='/patient-data/patient-insurance' element={<PatientInsurancePageWrapper />} />
        <Route path='/patient-data/patient-details/:id' element={<PatientDetails />} />
        <Route path='/patient-data/patient-management/patient-view/:id' element={<PatientView />} />
        <Route path='/doctor-availability' element={<DoctorAvalabilityWrapper />} />
        <Route path='/patient-data/patient-appointment' element={<PatientAppointmentWrapper />} />
        {/* <Route path='/doctor-appointment' element={<PatientAppointmentDoctorWrapper />} /> */}
        <Route path='master/operation-theatre' element={<OperationTheaterWrapper />} />
        <Route path='/staff/salary-structure' element={<SalaryWrapper />} />
        <Route path='/staff/salary-structure/add-employee-salary' element={<SalaryAddForm />} />
        <Route
          path='/staff/salary-structure/edit-employee-salary/:id'
          element={<EditSalaryForm />}
        />
        <Route path='/staff/salary-structure/view-employee-salary/:id' element={<ViewSalary />} />
        <Route path='patient-data/ad-patient' element={<AtdWrapper />} />
        <Route path='patient-data/ad-patient-view/:id' element={<Adtview />} />
        <Route path='master/audit-logs' element={<AuditLogsPageWrapper />} />
        <Route path='/patient-data/patient-billing' element={<PatientBillingPageWrapper />} />
        <Route path='/patient-data/patient-billing/billingForm' element={<BillingForm />} />
        <Route path='/patient-data/patient-billing/billingForm/:id' element={<BillingForm />} />
        <Route path='/staff/payroll' element={<PayrollWrapper />} />
        <Route path='/staff/payroll/add-payroll' element={<PayrollForm />} />
        <Route path='/staff/payroll/edit-payroll/:id' element={<PayrollForm />} />
        <Route path='/staff/payroll/view-payroll/:id' element={<StaffPayroll />} />
        <Route path='master/patient-medicine-master' element={<MedicineMasterWrapper />} />
        <Route path='/patient-data/patient-details/medications' element={<PatientDetails />} />
        <Route path='/patient-data/patient-details/attachments' element={<PatientDetails />} />
        <Route path='/patient-data/patient-details/medical-notes' element={<PatientDetails />} />
        <Route path='/patient-data/patient-details/patient-vital' element={<PatientDetails />} />
        <Route
          path='/patient-data/patient-details/medical-background'
          element={<PatientDetails />}
        />
        <Route path='doctor-all-information' element={<DoctorAllInformation />} />
      </Route>
      <Route path='doctor-prescription' element={<DpLayoutWrapper />} />

      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

export default SuperAdminPrivateRoute
