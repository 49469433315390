/* eslint-disable array-callback-return */
import {useEffect, useState} from 'react'
import {getMedicine} from '../../medications/medicationList/core/_requests'
import {useIntl} from 'react-intl'
import Select from 'react-select'

interface Medicine {
  id: string
  name: string
  frequency: string
  duration: number
  duration_unit: string
  type: string
  instruction: string | null
}

const DpMedication = () => {
  const intl = useIntl()
  const [medicineData, setMedicineData] = useState<Medicine[]>([])
  const [dosage, setDosage] = useState('')
  const [dosageUnit, setDosageUnit] = useState('ml')
  const [medicationData, setMedicationData] = useState([
    {
      prescribed_medicine: null,
      dosage: '',
      dosage_unit: 'ml',
      frequency: '',
      timing: '',
      duration: '',
      duration_unit: 'days',
    },
  ])
  const [defaultFrequencyOptions, setDefaultFrequencyOptions] = useState<any>([])
  const [frequencyOptionsMap, setFrequencyOptionsMap] = useState<{[key: number]: any}>({})
  const medication_dosage_unit_option = ['ml', 'tsp', 'tbsp', 'units']
  const medication_duration_unit_option = [
    {value: 'days', label: 'Days'},
    {value: 'weeks', label: 'Weeks'},
    {value: 'months', label: 'Months'},
    {value: 'years', label: 'Years'},
  ]
  const [isSelectFocused, setIsSelectFocused] = useState(false)

  useEffect(() => {
    const frequencyInitialArray = ['Once a day', 'Twice a day', 'Thrice a day']
    const initialOptions: any = frequencyInitialArray.map((label) => ({
      value: label,
      label: label,
    }))
    setDefaultFrequencyOptions(initialOptions)
    const initialFrequencyOptionsMap: {[key: number]: any} = {}
    medicationData.forEach((_, index) => {
      initialFrequencyOptionsMap[index] = initialOptions
    })
    setFrequencyOptionsMap(initialFrequencyOptionsMap)
  }, [])

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem('prescriptionData')
    if (sessionStorageData) {
      const prescriptionData = JSON.parse(sessionStorageData)
      const updatedPrescriptionData = {
        ...prescriptionData,
        medication: medicationData.filter(
          (medication) =>
            medication.prescribed_medicine ||
            medication.dosage ||
            medication.frequency ||
            medication.timing ||
            medication.duration
        ),
      }
      sessionStorage.setItem('prescriptionData', JSON.stringify(updatedPrescriptionData))
    }
  }, [medicationData])

  const fetchMedicine = async () => {
    const getMedicineList: any = await getMedicine()
    setMedicineData(getMedicineList?.data)
  }

  useEffect(() => {
    fetchMedicine()
  }, [])

  const handleMedicineChange = (selectedOption: any, index: any) => {
    const updatedMedicationData = [...medicationData]
    updatedMedicationData[index].prescribed_medicine = selectedOption

    const selectedMedicine = medicineData.find(
      (medicine: any) => medicine.name === selectedOption.value
    )
    if (selectedMedicine) {
      updatedMedicationData[index].dosage = ''
      updatedMedicationData[index].dosage_unit = 'ml'
      updatedMedicationData[index].frequency = ''
      updatedMedicationData[index].timing = selectedMedicine.instruction || ''
      updatedMedicationData[index].duration = selectedMedicine.duration.toString() || ''
      updatedMedicationData[index].duration_unit = selectedMedicine.duration_unit || 'days'
    } else {
      updatedMedicationData[index].dosage = ''
      updatedMedicationData[index].dosage_unit = 'ml'
      updatedMedicationData[index].frequency = ''
      updatedMedicationData[index].timing = ''
      updatedMedicationData[index].duration = ''
      updatedMedicationData[index].duration_unit = 'days'
    }
    setMedicationData(updatedMedicationData)

    if (index === updatedMedicationData.length - 1 && selectedOption) {
      setMedicationData([
        ...updatedMedicationData,
        {
          prescribed_medicine: null,
          dosage: '',
          dosage_unit: 'ml',
          frequency: '',
          timing: '',
          duration: '',
          duration_unit: 'days',
        },
      ])
    }
    handleSelectBlur()
  }

  const handleDosageChange = (value: any, index: number) => {
    setDosage(value)
    updateMedicationData(index, 'dosage', value)
    setFrequencyOptionsMap((prevMap) => ({
      ...prevMap,
      [index]: [],
    }))
    generateFrequencyOptions(index, value, dosageUnit)
  }

  const handleDosageUnitChange = (value: any, index: number) => {
    setDosageUnit(value)
    updateMedicationData(index, 'dosage_unit', value)
    setFrequencyOptionsMap((prevMap) => ({
      ...prevMap,
      [index]: [],
    }))
    generateFrequencyOptions(index, dosage, value)
  }

  const updateMedicationData = (index: number, field: string, value: any) => {
    const updatedMedicationData: any = [...medicationData]
    updatedMedicationData[index][field] = value
    setMedicationData(updatedMedicationData)
  }

  const generateFrequencyOptions = (index: number, dosage: any, dosageUnit: any) => {
    const combinations = [
      `0-0-${dosage}${dosageUnit}`,
      `0-${dosage}${dosageUnit}-0`,
      `${dosage}${dosageUnit}-0-0`,
      `0-${dosage}${dosageUnit}-${dosage}${dosageUnit}`,
      `${dosage}${dosageUnit}-0-${dosage}${dosageUnit}`,
      `${dosage}${dosageUnit}-${dosage}${dosageUnit}-0`,
      `${dosage}${dosageUnit}-${dosage}${dosageUnit}-${dosage}${dosageUnit}`,
    ]
    const options: any = combinations.map((combination) => ({
      value: combination,
      label: combination,
    }))
    const allOptions = [...options, ...defaultFrequencyOptions]
    setFrequencyOptionsMap((prevMap) => ({
      ...prevMap,
      [index]: allOptions,
    }))
  }

  function mapDataToNodesMedicine(data: any) {
    let parent: any = []
    data?.map((item: any) => {
      parent.push({value: item?.name, label: item?.name, id: item?.id, type: item?.type})
    })
    return parent
  }
  const optionsMedicines: any = mapDataToNodesMedicine(medicineData)
  const options = optionsMedicines.map((medicine: any) => ({
    value: medicine.value,
    label: `${medicine.label} (${medicine.type})`,
    id: medicine.id,
    type: medicine.type,
  }))

  const timingInitialArray = [
    'After meal',
    'Before meal',
    'After food',
    'In morning',
    'In afternoon',
    'At night',
    'Bed time',
    'Empty stomach',
    'Anytime of day',
    'Before lunch',
    'After breakfast',
    'After dinner',
    'After lunch',
    'During several pain',
    'After every 3 hour',
    'After every 4 hour',
  ]
  const timingOptions = timingInitialArray.map((label) => ({
    value: label,
    label: label,
  }))

  const handleFrequencyChange = (selectedOption: any, index: number) => {
    const updatedMedicationData = [...medicationData]
    updatedMedicationData[index].frequency = selectedOption ? selectedOption.value : ''
    updateMedicationData(index, 'frequency', updatedMedicationData[index].frequency)
  }

  const handleTimingChange = (selectedOption: any, index: number) => {
    const updatedMedicationData = [...medicationData]
    updatedMedicationData[index].timing = selectedOption ? selectedOption.value : ''
    updateMedicationData(index, 'timing', updatedMedicationData[index].timing)
  }

  const handleDurationChange = (value: any, index: number) => {
    const updatedMedicationData = [...medicationData]
    updatedMedicationData[index].duration = value
    updateMedicationData(index, 'duration', value)
  }

  const handleDurationUnitChange = (value: any, index: number) => {
    const updatedMedicationData = [...medicationData]
    updatedMedicationData[index].duration_unit = value
    updateMedicationData(index, 'duration_unit', value)
  }

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  const handleSelectFocus = () => {
    setIsSelectFocused(true)
    document.body.classList.add('select_focused') // Add class to body
  }

  const handleSelectBlur = () => {
    setIsSelectFocused(false)
    document.body.classList.remove('select_focused') // Remove class from body
  }

  return (
    <div className='dp-medication-table-wrapper'>
      <h4>
        {intl.formatMessage({
          id: 'PRESCRIPTION_MEDICATION_TITLE',
        })}
      </h4>

      <table className='table table-bordered table-light dp-medication-table'>
        <thead>
          <tr>
            <th scope='col'>
              {intl.formatMessage({
                id: 'PRESCRIPTION_MEDICATION_COLUMN_PRESCRIBED_MEDICINE',
              })}
            </th>

            <th scope='col'>
              {intl.formatMessage({
                id: 'PRESCRIPTION_MEDICATION_COLUMN_DOSAGE',
              })}
            </th>
            <th scope='col'>
              {intl.formatMessage({
                id: 'PRESCRIPTION_MEDICATION_COLUMN_FREQUENCY',
              })}
            </th>
            <th scope='col'>
              {intl.formatMessage({
                id: 'PRESCRIPTION_MEDICATION_COLUMN_TIMING',
              })}
            </th>
            <th scope='col'>
              {intl.formatMessage({
                id: 'PRESCRIPTION_MEDICATION_COLUMN_DURATION',
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {medicationData.map((item, index) => (
            <tr key={index}>
              <td>
                <Select
                  options={options}
                  placeholder={intl.formatMessage({id: 'MEDICATION.SELECT.ADD_MEDICINE'})}
                  styles={{
                    ...customStyles,
                    dropdownIndicator: (provided) => ({...provided, minHeight: '40px'}),
                  }}
                  onChange={(value) => handleMedicineChange(value, index)}
                  onFocus={handleSelectFocus}
                  onBlur={handleSelectBlur}
                />
              </td>
              <td className='dpmedication-item has-dropdown'>
                <div className='input-group'>
                  <input
                    className='form-control'
                    type='number'
                    value={item.dosage}
                    onChange={(e) => handleDosageChange(e.target.value, index)}
                    placeholder='3'
                    list='dosage_values'
                    name='dosage_values'
                    id='dosage_value'
                  />
                  <datalist id='dosage_values'>
                    <option value='1' />
                    <option value='2' />
                    <option value='3' />
                    <option value='4' />
                    <option value='5' />
                    <option value='6' />
                  </datalist>

                  <div className='input-group-append'>
                    <select
                      className='custom-select'
                      value={item.dosage_unit}
                      onChange={(e) => handleDosageUnitChange(e.target.value, index)}
                      id='medication_dosage_unit'
                    >
                      {medication_dosage_unit_option?.map((item) => {
                        return <option value={item}>{item}</option>
                      })}
                    </select>
                  </div>
                </div>
              </td>

              <td>
                <select
                  value={item.frequency}
                  onChange={(e) => handleFrequencyChange({value: e.target.value}, index)}
                  className='custom-select-frequency'
                >
                  <option value=''>
                    {intl.formatMessage({id: 'MEDICATION.FREQUENCY_PLACEHOLDER'})}
                  </option>
                  {frequencyOptionsMap[index]?.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  value={item.timing}
                  onChange={(e) => handleTimingChange({value: e.target.value}, index)}
                  className='custom-select-timimg'
                >
                  <option value=''>
                    {intl.formatMessage({id: 'MEDICATION.TIMING_PLACEHOLDER'})}
                  </option>
                  {timingOptions.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>

              <td className='dpmedication-item has-dropdown'>
                <div className='input-group'>
                  <input
                    className='form-control'
                    type='number'
                    value={item.duration}
                    onChange={(e) => handleDurationChange(e.target.value, index)}
                    placeholder='3'
                    list='duration_values'
                    name='duration_values'
                    id='duration_value'
                  />
                  <datalist id='duration_values'>
                    <option value='1' />
                    <option value='2' />
                    <option value='3' />
                    <option value='4' />
                    <option value='5' />
                    <option value='6' />
                  </datalist>

                  <div className='input-group-append'>
                    <select
                      className='custom-select'
                      value={item.duration_unit}
                      onChange={(e) => handleDurationUnitChange(e.target.value, index)}
                      id='medication_duration_unit'
                    >
                      {medication_duration_unit_option?.map((item) => {
                        return <option value={item.value}>{item.label}</option>
                      })}
                    </select>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DpMedication
