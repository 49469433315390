import moment from 'moment'
import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getDoctorByID} from '../core/_requests'
import {DoctorEditModalForm} from './DoctorEditModalForm'

const DoctorEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: doctor,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getDoctorByID(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (doctor && doctor['date_of_birth']) {
    const dob = moment(doctor.date_of_birth).format('DD-MM-YYYY')
    doctor['date_of_birth'] = dob
  }

  if (!itemIdForUpdate) {
    return (
      <DoctorEditModalForm isUserLoading={isLoading} doctor={{id: undefined, signature: null}} />
    )
  }

  if (!isLoading && !error && doctor) {
    return <DoctorEditModalForm isUserLoading={isLoading} doctor={doctor} />
  }

  return null
}

export {DoctorEditModalFormWrapper}
