import axios, {AxiosResponse} from 'axios'
import {DoctorsQueryResponse} from '../../Doctor/DoctorList/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const DOCTOR_URL = `${API_URL}/doctor-availability`
const REPEAT_DOCTOR_URL = `${API_URL}/doctor-availability/create`
const GET_SPECIALITY_URL = `${API_URL}/speciality`
const GET_ROLES_URL = `${API_URL}/roles`
const CREATE_DOCTOR_URL = `${API_URL}/doctor-availability`

const GET_DOCTOR_URL = `${API_URL}/doctor`



const getDoctorData = (id: number,hos_id?:number) => {
  return axios
    .get(`${CREATE_DOCTOR_URL}?${`doctorId=${id}&hospital_id=${hos_id}`}`)
    .then((d: any) => d.data)
    .then((response: any) => response)
}

const getDoctors = (query?: string): Promise<DoctorsQueryResponse> => {
  return axios.get(`${GET_DOCTOR_URL}`).then((d: AxiosResponse<DoctorsQueryResponse>) => d.data)
}

// const getDoctorByID = (id: ID): Promise<Doctor | undefined> => {
//   return axios
//     .get(`${DOCTOR_URL}/${id}`)
//     .then((response: AxiosResponse<Response<Doctor>>) => response.data)
//     .then((response: Response<Doctor>) => response.data)
// }

const createDoctorAvaliblity = (doctor: any) => {
  return axios.post(DOCTOR_URL, doctor)
}

const createRepeatDoctorAvaliblity = (doctor: any) => { 
  return axios
    .post(REPEAT_DOCTOR_URL, doctor)
}





// const updateDoctor = (doctor: any, id:any): Promise<Doctor | undefined> => {
//   return axios
//     .patch(`${DOCTOR_URL}/${id}`, doctor)
//     .then((response: AxiosResponse<Response<any>>) => response.data)
//     .then((response: Response<any>) => response.data)
// }

const deleteDoctorAvaliblity = (id?: any): Promise<void> => {
  return axios.delete(`${DOCTOR_URL}/${id}`).then(() => {})
}

// const deleteSelectedDoctors = (doctorIds: Array<ID>): Promise<void> => {
//   const requests = doctorIds.map((id) => axios.delete(`${DOCTOR_URL}/${id}`))
//   return axios.all(requests).then(() => {})
// }

// const getSpeciality = (): Promise<DoctorsQueryResponse> => {
//     return axios
//       .get(`${GET_SPECIALITY_URL}`)
//       .then((d: AxiosResponse<DoctorsQueryResponse>) => d.data)
// }

// const getRoles = (): Promise<DoctorsQueryResponse> => {
//   return axios
//     .get(`${GET_ROLES_URL}`)
//     .then((d: AxiosResponse<DoctorsQueryResponse>) => d.data)
// }

export {createDoctorAvaliblity,createRepeatDoctorAvaliblity,getDoctorData,getDoctors,deleteDoctorAvaliblity}
