import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersTable} from './table/UsersTable'
import {AppointmentEditModal} from './appointment-edit-modal/AppointmentEditModal'
import {KTCard} from '../../../../_jmh/helpers'
import {AppointmentsListHeader} from './components/header/AppointmentsListHeader'

const AppointmentsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <AppointmentsListHeader />
        <UsersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AppointmentEditModal />}
    </>
  )
}

const AppointmensListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AppointmentsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AppointmensListWrapper}
