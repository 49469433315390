/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {HospitalRoom} from '../../core/_models'

type Props = {
  user: HospitalRoom
}

const HospitalRoomStatusCell: FC<Props> = ({user}) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <p
            className={`mb-1 badge ${
              user.availability_status === 'AVAILABLE'
                ? 'badge-light-success'
                : user.availability_status === 'NOT AVAILABLE'
                ? 'badge-light-danger'
                : ''
            }`}
          >
            {user?.availability_status}
          </p>
        </div>
      </div>
    </>
  )
}

export {HospitalRoomStatusCell}
