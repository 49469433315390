import {createContext, useEffect, useState} from 'react'
export const loginContext = createContext<any>(null)

function Context({children}: {children: React.ReactNode}) {
  const [loginData, setLoginData] = useState<any>({
    id: '',
    email: '',
    is_password_change: true,
    user_type: '',
    role: {
      id: '',
      name: '',
    },
    hospital: {
      id: '',
      name: '',
    },
    personal_details: {
      id: '',
      full_name: '',
      gender: '',
      image: null,
      date_of_birth: '',
      permanent_address: '',
      contact_number: '',
      visiting_doctor: false,
      qualification: '',
      years_of_experience: '',
      user_id: '',
      speciality_id: '',
      status: '',
      speciality: {
        id: '',
        name: '',
        description: '',
        status: '',
      },
    },
    permissions: [
      {
        id: '',
        module_id: '',
        permission_id: '',
        role_id: '',
        hospital_id: '',
        status: '',
        module: {
          id: '',
          name: '',
        },
        permission: {
          id: '',
          name: '',
          route: '',
          method: '',
          module_id: '',
        },
      },
    ],
  })

  const [selectedHospitalId, setSelectedHospitalId] = useState(1)
  const [selectedPatientId, setSelectedPatientId] = useState(null)
  const [selectedPatientTab, setSelectedPatientTab] = useState(null)
  const [selectedAssetMaitenance, setSelectedAssetMaitenance] = useState(null)
  const [selectedInventoryData, setSelectedInventoryData] = useState(null)
  const [assignData, setAssignData] = useState(null)
  const [selectedPatientData, setSelectedPatientData] = useState(null)
  const [selectedDoctorData, setSelectedDoctorData] = useState(null)
  const [tabSelection, setTabSelection] = useState('NOW')
  const [doctorAppointmentId, setDoctorAppointmentId] = useState('')
  const [selectedSearchTerm, setSelectedSearchTerm] = useState('')
  const [currentPagination, setCurrentPagination] = useState(0)
  const [readNotification, setReadNotification] = useState('')
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [selectedAppointmentData, setSelectedAppointmentData] = useState(null)
  const [selectedOperationData, setSelectedOperationData] = useState(null)

  useEffect(() => {
    const storedLoginData = localStorage.getItem('loginData')
    if (storedLoginData) {
      setLoginData(JSON.parse(storedLoginData))
    }
  }, [])

  return (
    <loginContext.Provider
      value={{
        loginData,
        setLoginData,
        selectedHospitalId,
        setSelectedHospitalId,
        selectedPatientId,
        setSelectedPatientId,
        selectedPatientTab,
        setSelectedPatientTab,
        selectedAssetMaitenance,
        setSelectedAssetMaitenance,
        selectedInventoryData,
        setSelectedInventoryData,
        selectedPatientData,
        setSelectedPatientData,
        tabSelection,
        setTabSelection,
        doctorAppointmentId,
        setDoctorAppointmentId,
        selectedDoctorData,
        setSelectedDoctorData,
        selectedSearchTerm,
        setSelectedSearchTerm,
        currentPagination,
        setCurrentPagination,
        readNotification,
        setReadNotification,
        assignData,
        setAssignData,
        selectedPatient,
        setSelectedPatient,
        selectedAppointmentData,
        setSelectedAppointmentData,
        selectedOperationData,
        setSelectedOperationData,
        selectedAppointment,
        setSelectedAppointment,
      }}
    >
      {children}
    </loginContext.Provider>
  )
}

export default Context
