import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_jmh/helpers'
import {Table} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {loginContext} from '../../context/context'
import {getPatientDoc} from './core/_request'
import ReactPaginate from 'react-paginate'
import {toast} from 'react-toastify'

const OpAttachments = ({selectedAppointment}: any) => {
  const intl = useIntl()
  const [attachmentData, setAttachmentData] = useState<any>(null)
  const {loginData} = useContext(loginContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const limit = 10
  const pageCount = Math.max(Math.ceil(totalCount / limit), 1)
  let extension = ''

  const fetchAttachmentData = () => {
    getPatientDoc(
      loginData?.data?.patient_id,
      loginData?.data?.hospital_id,
      selectedAppointment
        ? selectedAppointment?.value === 'all'
          ? ''
          : selectedAppointment?.value
        : ''
    ).then((res: any) => {
      setAttachmentData(res?.data)
      setTotalCount(res?.pagination?.meta?.totalItems)
    })
  }

  useEffect(() => {
    fetchAttachmentData()
  }, [currentPage, selectedAppointment])

  const handlePageClick = (selectedPage: any) => {
    setCurrentPage(selectedPage?.selected + 1)
  }

  const handleDownload = async (e: any, item_url: any) => {
    e.preventDefault()
    extension = item_url?.split('.').pop()?.toLowerCase()

    if (item_url) {
      try {
        const response = await fetch(item_url)
        const blob = await response.blob()
        const downloadUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')

        let fileName = item_url
          ? item_url
              .substring(item_url.lastIndexOf('/') + 1, item_url.lastIndexOf('.'))
              .split('_')
              .pop()
          : ''

        if (extension === 'jpg' || extension === 'jpeg') {
          fileName += '.jpg'
        } else if (extension === 'pdf') {
          fileName += '.pdf'
        } else {
          toast.error('Unsupported file format')
          return
        }

        link.href = downloadUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error('Failed to download file:', error)
      }
    }
  }

  return (
    <div className='op_inner_wrapper py-5'>
      <div className='d-flex justify-content-between align-items-center mb-25'>
        <h2 className='op_head_title m-0'>Your Attachments</h2>
      </div>

      <Table striped className='table-striped'>
        <thead>
          <tr>
            <th style={{width: '90%'}}>Documents</th>
            <th style={{width: '10%'}}>Action</th>
          </tr>
        </thead>
        <tbody>
          {attachmentData && attachmentData.length > 0 ? (
            attachmentData.map((item: any) => {
              const url: any = item.path
              const extension = url?.split('.').pop()?.toLowerCase()

              return (
                <tr key={item.id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex flex-column'>
                        {item.path && item.path !== null && item.path?.length > 0 ? (
                          <>
                            {extension === 'pdf' ? (
                              <KTSVG
                                path='/media/svg/files/pdf.svg'
                                className='svg-icon-primary svg-icon-3hx'
                              />
                            ) : (
                              <>
                                {extension === 'jpg' || extension === 'jpeg' ? (
                                  <>
                                    <div style={{width: '75px', height: '75px'}}>
                                      <img
                                        className='w-100 h-100 object-fit-cover'
                                        src={item?.path}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='btn btn-light-primary btn-active-primary btn-sm px-4 me-4 justify-content-end'
                      onClick={(e) => handleDownload(e, item?.path)}
                    >
                      Download
                    </a>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={5}>
                <div className='d-flex text-center fs-5 text-dark fw-bold w-100 align-content-center justify-content-center'>
                  {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {attachmentData?.length >= 1 && (
        <nav className='op_pagination'>
          <ReactPaginate
            breakLabel='...'
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName='pagination'
            activeClassName='active'
            previousLabel='Previous'
            nextLabel='Next'
          />
        </nav>
      )}
    </div>
  )
}

export default OpAttachments
