import {useQuery} from 'react-query'
import {MedicationEditModalForm} from './MedicationEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getPatientMedicationById} from '../core/_requests'
import moment from 'moment'

const MedicationEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getPatientMedicationById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (user && user['assigned_date']) {
    const formatePurchaseDate = moment(user.assigned_date).format('DD-MM-YYYY')
    user['assigned_date'] = formatePurchaseDate
  }

  if (!itemIdForUpdate) {
    return (
      <MedicationEditModalForm
        isUserLoading={isLoading}
        user={{id: undefined, medication_data: []}}
      />
    )
  }

  if (!isLoading && !error && user) {
    return <MedicationEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export {MedicationEditModalFormWrapper}
