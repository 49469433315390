/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Operationlist} from '../../core/_models'

type Props = {
  user: Operationlist
}

const UserInfoCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <p className='text-gray-800 mb-1'>{user?.patient?.full_name}</p>
    </div>
  )
}

export {UserInfoCell}
