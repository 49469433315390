import {useContext} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {useIntl} from 'react-intl'

const MedicalNotesListToolbar = () => {
  const intl = useIntl()
  const {selectedAppointment} = useContext(loginContext)
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }
  // const {loginData, setLoginData} = useContext(loginContext)

  // const data = loginData?.data?.permissions?.filter((item: any) => {
  //   if (item.module.name === 'patient-medical-notes') {
  //     return item?.module_id
  //   }
  // })

  // const add = data?.filter((item: any) => {
  //   if (item.permission.method === 'POST') {
  //     return item
  //   }
  // })

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <SpecialityListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      {/* {add.length ? ( */}
      <button
        type='button'
        className='btn btn-primary'
        onClick={openAddUserModal}
        disabled={selectedAppointment?.value == 'all'}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'MEDICAL_NOTES.ADD.MEDICAL.NOTES'})}
      </button>
      {/* ) : (
        ''
      )} */}

      {/* end::Add user */}
    </div>
  )
}

export {MedicalNotesListToolbar}
