import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../../_jmh/layout/core'
import {getPayrollById} from '../core/_requests'
import {useIntl} from 'react-intl'
import {Payroll} from '../core/_models'

type Props = {
  isUserLoading: boolean
  user: Payroll
}

const StaffPayroll: FC<any> = ({user}) => {
  const {id} = useParams()
  const [allData, SetAllData] = useState(user)
  const intl = useIntl()

  useEffect(() => {
    ;(async () => {
      const allsalaryData: any = await getPayrollById(Number(id))
      SetAllData(allsalaryData)
    })()
  }, [])

  const handleBackClick = () => {
    window.history.back() // Redirect to the previous page
  }

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'VIEW.STAFF.PAYROLL'})}</PageTitle>

      <div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
        <div className='card card-flush py-4 flex-row-fluid shadow'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>
                {allData?.staff?.full_name}&nbsp;({allData?.salary_month}/{allData?.salary_year})
              </h2>
            </div>

            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </button>
            </div>
          </div>

          <div className='card-body pt-5'>
            <div className='row'>
              <div className='col-4 ps-0'>
                <h4 className=''>{intl.formatMessage({id: 'GENERAL.GROSS.SALARY'})}</h4>
                <p className=''>{allData?.gross_salary}</p>
                <h4 className=''>
                  {intl.formatMessage({id: 'SALARY.STRUCTURE.MEDICAL.ALLOWANCE'})}
                </h4>
                <p className=''>{allData?.medical_insurance_allowance}</p>
                <h4 className=''>
                  {intl.formatMessage({id: 'SALARY.STRUCTURE.ENTERTAINMENT.ALLOWANCE'})}
                </h4>
                <p className=''>{allData?.entertainment_allowance}</p>
              </div>
              <div className='col-4'>
                <h4 className=''>{intl.formatMessage({id: 'SALARY.STRUCTURE.BASIC.SALARY'})}</h4>
                <p className=''>{allData?.basic_salary}</p>
                <h4 className=''>{intl.formatMessage({id: 'STAFF.PAYROLL.CTA'})}</h4>
                <p className=''>{allData?.conveyance_transportation_allowance}</p>
                <h4 className=''>{intl.formatMessage({id: 'STAFF.PAYROLL.PAYABLE.SALARY'})}</h4>
                <p className=''>{allData?.payable_salary}</p>
              </div>
              <div className='col-4'>
                <h4 className=''>
                  {intl.formatMessage({id: 'SALARY.STRUCTURE.HOUSE.RENT.ALLOWANCE'})}
                </h4>
                <p className=''>{allData?.house_rent_allowance}</p>
                <h4 className=''>{intl.formatMessage({id: 'SALARY.STRUCTURE.MEAL.ALLOWANCE'})}</h4>
                <p className=''>{allData?.meal_allowance}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-10 gap-10'>
        <div className='card card-flush py-4 flex-row-fluid col-4 ps-0 shadow'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>{intl.formatMessage({id: 'SALARY.STRUCTURE.BENEFITS'})}</h2>
            </div>
          </div>

          <div className='card-body pt-0 py-0'>
            <div className='row'>
              <div className='col-6 ps-0'>
                <h4 className=''>{intl.formatMessage({id: 'SALARY.STRUCTURE.BONUS'})}</h4>
                <p className=''>{allData?.bonus}</p>
              </div>
              <div className='col-6'>
                <h4 className=''>{intl.formatMessage({id: 'SALARY.STRUCTURE.INCENTIVE'})}</h4>
                <p className=''>{allData?.incentive}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 ps-0'>
                <h4 className=''>{intl.formatMessage({id: 'STAFF.PAYROLL.LEAVE.ENCASHMENT'})}</h4>
                <p className=' '> {allData?.leave_encashment}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-flush py-4 flex-row-fluid col-4 shadow'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>{intl.formatMessage({id: 'SALARY.STRUCTURE.DEDUCTIONS'})}</h2>
            </div>
          </div>

          <div className='card-body pt-0 py-0'>
            <div className='row'>
              <div className='col-4 ps-0'>
                {allData?.EPF_deduction ? (
                  <>
                    <h4 className=''>{intl.formatMessage({id: 'SALARY.STRUCTURE.EPF'})}</h4>
                    <p className=''>{allData?.EPF_deduction}</p>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className='col-8'>
                <h4 className=''>
                  {intl.formatMessage({id: 'SALARY.STRUCTURE.PROFESSIONAL.TAX'})}
                </h4>
                <p className=''>{allData?.professional_tax}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-flush py-4 flex-row-fluid col-3 shadow'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>{intl.formatMessage({id: 'GENERAL.THIS.MONTH'})}</h2>
            </div>
          </div>

          <div className='card-body pt-0'>
            <div className='row'>
              <div className='col-6 ps-0'>
                <h4 className=''>{intl.formatMessage({id: 'STAFF.PAYROLL.WD'})}</h4>
                <p className=''>{allData?.working_days}</p>
              </div>
              <div className='col-6'>
                <h4 className=''>{intl.formatMessage({id: 'STAFF.PAYROLL.PL'})}</h4>
                <p className=''>{allData?.paid_leaves}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-6 ps-0'>
                <h4 className=''>{intl.formatMessage({id: 'STAFF.PAYROLL.EWD'})}</h4>
                <p className=' '> {allData?.employee_working_days}</p>
              </div>
              <div className='col-6'>
                <h4 className=''>{intl.formatMessage({id: 'STAFF.PAYROLL.UPL'})}</h4>
                <p className=' '> {allData?.unpaid_leaves}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {allData && (
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title fw-bold fs-2'>Staff Payroll</h3>
            <div className='card-toolbar'>
              <Link type='button' className='btn btn-sm btn-primary' to={'/payroll'}>
                Back
              </Link>
            </div>
          </div>
          <div className='card-body card-scroll'>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <div className='fw-bold fs-4 mt-5'> Staff Member:</div>
                <div className='text-gray-600 fs-5'>{allData?.staff?.full_name}</div>
                <div className='fw-bold fs-4 mt-5'>Salary Month/Year:</div>
                <div className='text-gray-600 fs-5'>
                  {allData?.salary_month}/{allData?.salary_year}
                </div>
                <div className='fw-bold fs-4 mt-5'>Basic Salary:</div>
                <div className='text-gray-600 fs-5'>{allData?.basic_salary}</div>
                <div className='fw-bold fs-4 mt-5'>Gross Salary:</div>
                <div className='text-gray-600 fs-5'>{allData?.gross_salary}</div>
                <div className='fw-bold fs-4 mt-5'>House Rent Allowance:</div>
                <div className='text-gray-600 fs-5'>{allData?.house_rent_allowance}</div>
                <div className='fw-bold fs-4 mt-5'>Medical Insurance Allowance:</div>
                <div className='text-gray-600 fs-5'>{allData?.medical_insurance_allowance}</div>
                <div className='fw-bold fs-4 mt-5'>Conveyance Transportation Allowance:</div>
                <div className='text-gray-600 fs-5'>
                  {allData?.conveyance_transportation_allowance}
                </div>
                <div className='fw-bold fs-4 mt-5'>Meal Allowance:</div>
                <div className='text-gray-600 fs-5'>{allData?.meal_allowance}</div>
                <div className='fw-bold fs-4 mt-5'>Entertainment Allowance:</div>
                <div className='text-gray-600 fs-5'>{allData?.entertainment_allowance}</div>
                {allData?.bonus ? (
                  <>
                    <div className='fw-bold fs-4 mt-5'>Bonus:</div>
                    <div className='text-gray-600 fs-5'>{allData?.bonus}</div>
                  </>
                ) : (
                  ''
                )}
                {allData?.incentive ? (
                  <>
                    <div className='fw-bold fs-4 mt-5'>Incentive:</div>
                    <div className='text-gray-600 fs-5'>{allData?.incentive}</div>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className='col-md-6 col-sm-12'>
                {allData?.EPF_deduction ? (
                  <>
                    <div className='fw-bold fs-4 mt-5'>EPF Deduction:</div>
                    <div className='text-gray-600 fs-5'>{allData?.EPF_deduction}</div>{' '}
                  </>
                ) : (
                  ''
                )}
                <div className='fw-bold fs-4 mt-5'>Professional Tax:</div>
                <div className='text-gray-600 fs-5'>{allData?.professional_tax}</div>
                <div className='fw-bold fs-4 mt-5'>Working Days:</div>
                <div className='text-gray-600 fs-5'>{allData?.working_days}</div>
                <div className='fw-bold fs-4 mt-5'>Employee Working Days:</div>
                <div className='text-gray-600 fs-5'>{allData?.employee_working_days}</div>
                <div className='fw-bold fs-4 mt-5'>Paid Leaves:</div>
                <div className='text-gray-600 fs-5'>{allData?.paid_leaves}</div>
                <div className='fw-bold fs-4 mt-5'>Unpaid Leaves:</div>
                <div className='text-gray-600 fs-5'>{allData?.paid_leaves}</div>
                <div className='fw-bold fs-4 mt-5'>Leave Encashment:</div>
                <div className='text-gray-600 fs-5'>{allData?.leave_encashment}</div>
                <div className='fw-bold fs-4 mt-5'> Payable Salary:</div>
                <div className='text-gray-600 fs-5'>{allData?.payable_salary}</div>
                <div className='fw-bold fs-4 mt-5'> Approval:</div>
                <div className='text-gray-600 fs-5'>{allData?.approval}</div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}

export default StaffPayroll
