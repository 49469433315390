import {PatientUploadDocListToolbar} from './PatientUploadDocListToolbar'

const PatientUploadDocListHeader = () => {
  return (
    <div className='card-header border-0 px-0 justify-content-end'>
      {/* <PatientUploadDocListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <PatientUploadDocListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PatientUploadDocListHeader}
