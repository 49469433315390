import React, {FC} from 'react'
import {KTSVG} from '../../../_jmh/helpers'
import {DashboardFilterDropdown} from '../../../_jmh/partials/content/dropdown/DashboardFilterDropdown'
import {useIntl} from 'react-intl'

interface Props {
  appstartDate: any
  setAppStartDate: any
  setAppEndDate: any
  appendDate: any
  handleApply: any
  opestartDate: any
  opeendDate: any
  setOpeStartDate: any
  setOpeEndDate: any
  patientName: any
  setPatientName: any
}

const FilterButton: FC<Props> = ({
  setAppEndDate,
  setAppStartDate,
  appstartDate,
  appendDate,
  handleApply,
  opestartDate,
  opeendDate,
  setOpeStartDate,
  setOpeEndDate,
  patientName,
  setPatientName,
}) => {
  const intl = useIntl()

  return (
    <>
      <button
        type='button'
        // value={selectedHospital || selectedHospitalId}
        className='rotate btn btn-sm btn-bg-primary text-white btn-active-primary head_hospital_list w-auto min-w-150px'
        // onClick={handleSelectionChange}
        style={{justifyContent: 'space-between', gap: '20px'}}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        // disabled={hospitalListData?.data?.length > 0 ? false : true}
      >
        {intl.formatMessage({id: 'FILTER.OPTIONS'})}
        <div className='svg-icon  rotate-180 me-0'>
          <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-3 text-white' />
        </div>
      </button>
      <DashboardFilterDropdown
        appstartDate={appstartDate}
        appendDate={appendDate}
        setAppStartDate={setAppStartDate}
        setAppEndDate={setAppEndDate}
        handleApply={handleApply}
        opestartDate={opestartDate}
        opeendDate={opeendDate}
        setOpeStartDate={setOpeStartDate}
        setOpeEndDate={setOpeEndDate}
        patientName={patientName}
        setPatientName={setPatientName}
      />
    </>
  )
}

export {FilterButton}
