import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {ThemeModeType, useThemeMode, systemMode} from './ThemeModeProvider'
import {useEffect, useState} from 'react'
import moment from 'moment'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const ThemeModeSwitcher = ({toggleBtnClass = '', toggleBtnIconClass = 'svg-icon-2'}: Props) => {
  const {mode, updateMode, updateMenuMode} = useThemeMode()
  const calculatedMode = mode === 'system' ? systemMode : mode
  const [userInitiatedChange, setUserInitiatedChange] = useState(false)

  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
    setUserInitiatedChange(false)
  }

  const handleSwitchModeClick = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
    setUserInitiatedChange((val) => !val)
  }

  // const setThemeModeBasedOnTime = async () => {
  //   try {
  //     const currentTime = new Date()
  //     const currentHour = parseInt(moment(currentTime).format('HH'), 10)

  //     const isDaytime = currentHour >= 6 && currentHour < 18
  //     const newMode = isDaytime ? 'light' : 'dark'

  //     if (calculatedMode !== newMode) {
  //       if (!userInitiatedChange) {
  //         switchMode(newMode)
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error getting user timezone:', error)
  //   }
  // }

  // useEffect(() => {
  //   setThemeModeBasedOnTime()

  //   const intervalId = setInterval(() => {
  //     setThemeModeBasedOnTime()
  //   }, 1000)

  //   return () => clearInterval(intervalId)
  // }, [userInitiatedChange, mode])

  return (
    <>
      <button
        className={clsx('btn btn-icon', toggleBtnClass)}
        type='button'
        id='themeModeDropdown'
        // data-bs-toggle='dropdown'
        // aria-expanded='false'
        onClick={() => {
          const newMode = calculatedMode === 'light' ? 'dark' : 'light'
          handleSwitchModeClick(newMode)
          // setUserInitiatedChange(true)
        }}
      >
        {/* {calculatedMode === 'dark' && (
          <KTSVG
            path='/media/icons/duotune/general/gen061.svg'
            className={clsx('theme-light-hide', toggleBtnIconClass)}
          />
        )}

        {calculatedMode === 'light' && (
          <KTSVG
            path='/media/icons/duotune/general/gen060.svg'
            className={clsx('theme-dark-hide', toggleBtnIconClass)}
          />
        )} */}
      </button>
    </>
  )
}

export {ThemeModeSwitcher}
