import {GoogleMap, InfoWindow, Marker, useLoadScript} from '@react-google-maps/api'
import {useEffect, useState} from 'react'
import {getHospitals} from './core/_request'
import {useIntl} from 'react-intl'

const LandingOurClinic = () => {
  const [hospitalData, setHospitalData] = useState<any>(null)
  const [activeMarker, setActiveMarker] = useState(null)
  const intl = useIntl()
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: 'AIzaSyDHLEbWXXCZmJsB5k3Z0q1QCbqjfoVFK2w',
  })
  const [markers, setMarkers] = useState<any>([])

  const fetchHospitals = () => {
    getHospitals().then((res) => {
      setHospitalData(res?.data?.data)

      const formattedMarkers = res?.data?.data?.map((item: any) => ({
        id: item?.id,
        name: item?.name,
        position: {lat: Number(item?.lat), lng: Number(item?.long)},
      }))

      setMarkers(formattedMarkers)
    })
  }

  useEffect(() => {
    fetchHospitals()
  }, [])

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }

  const handleOnLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds()
    markers.forEach(({position}: any) => bounds.extend(position))
    map.fitBounds(bounds)
  }

  return (
    <section className='landing_our_clinic section-padding' id='clinics'>
      <div className='landing_container'>
        <div className='loc_container position-relative'>
          <div className='row'>
            <div className='col-5'>
              <div className='oc_back'></div>
              <div className='oc_left'>
                <h2>{intl.formatMessage({id: 'LANDING_PAGE.CLINICS.TITLE'})}</h2>
                <div className='oc_list'>
                  {hospitalData?.map((item: any, index: number) => {
                    return (
                      <div className='oc_item' key={index}>
                        <h6>{item?.name}</h6>
                        <div className='row g-0'>
                          <div className='col-6'>
                            <div className='d-flex align-items-center'>
                              <span className='oc_icon'>
                                <i className='fa-solid fa-phone'></i>
                              </span>
                              <span className='oc_text'>+{item?.contact_number}</span>
                            </div>
                          </div>
                          <div className='col-6'>
                            <div className='d-flex align-items-center'>
                              <span className='oc_icon'>
                                <i className='fa-solid fa-envelope-open'></i>
                              </span>
                              <span className='oc_text'>{item?.email}</span>
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='d-flex align-items-center mt-4'>
                              <span className='oc_icon'>
                                <i className='fa-solid fa-location-dot'></i>
                              </span>
                              <span className='oc_text'>{item?.address}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className='col-7'>
              <div className='oc_right'>
                {isLoaded ? (
                  <div>
                    <GoogleMap
                      onLoad={handleOnLoad}
                      onClick={() => setActiveMarker(null)}
                      mapContainerStyle={{height: '650px'}}
                    >
                      {markers?.map(
                        ({
                          id,
                          name,
                          position,
                        }: {
                          id: string
                          name: string
                          position: {lat: number; lng: number}
                        }) => {
                          if (
                            typeof position.lat !== 'number' ||
                            typeof position.lng !== 'number'
                          ) {
                            console.error(
                              `Invalid position for marker with id ${id}. Lat and Lng must be numbers.`
                            )
                            return null
                          }

                          return (
                            <Marker
                              key={id}
                              position={position}
                              onClick={() => handleActiveMarker(id)}
                            >
                              {activeMarker === id ? (
                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                  <div>{name}</div>
                                </InfoWindow>
                              ) : null}
                            </Marker>
                          )
                        }
                      )}
                    </GoogleMap>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingOurClinic
