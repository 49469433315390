import {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, ActivityDrawer} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../app/redux/store'
import {setAsideMinimize} from '../../app/redux/asideMinimizeSlice'

const MasterLayout = () => {
  const location = useLocation()
  // const [asideMinimize, setAsideMinimize] = useState(false)
  const asideMinimize = useSelector((state: RootState) => state.asideMinimize.asideMinimize)
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  // const toggleAsideMinimize = () => {
  //   setAsideMinimize(!asideMinimize)
  // }

  const toggleAsideMinimize = () => {
    dispatch(setAsideMinimize(!asideMinimize))
  }

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault asideMinimize={asideMinimize} setAsideMinimize={setAsideMinimize} />
        <div
          className={`wrapper d-flex flex-column flex-row-fluid ${
            asideMinimize ? 'Asidemini' : 'Psidemax'
          } `}
          id='kt_wrapper'
        >
          {/* <HeaderWrapper toggleAsideMinimize={toggleAsideMinimize} /> */}
          {/* {!hideHeader &&  */}
          <HeaderWrapper toggleAsideMinimize={toggleAsideMinimize} />
          {/* } */}

          <div
            id='kt_content'
            className={`content d-flex flex-column flex-column-fluid ${
              location.pathname === '/doctor-all-information' ? '' : ''
            }`}
          >
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
