import {PayrollListToolbar} from './PayrollListToolbar'
import {PayrollsListSearchComponent} from './PayrollsListSearchComponent'

const PayrollsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <PayrollsListSearchComponent />
      <div className='card-toolbar'>
        <PayrollListToolbar />
      </div>
    </div>
  )
}

export {PayrollsListHeader}
