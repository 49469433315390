import {MedicineMasterListToolbar} from './MedicineMasterListToolbar'
import {MedicineMasterSearchComponent} from './MedicineMasterSearchComponent'

const MedicineMasterListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <MedicineMasterSearchComponent />
      <div className='card-toolbar'>
        <MedicineMasterListToolbar />
      </div>
    </div>
  )
}

export {MedicineMasterListHeader}
