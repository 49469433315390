import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {AuditLogsListWrapper} from './AuditLogsList/AuditLogsList'
import {useIntl} from 'react-intl'

const AuditLogsPageWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'AUDIT_LOGS'})}</PageTitle>
      <AuditLogsListWrapper />
    </>
  )
}

export default AuditLogsPageWrapper
