import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import { Staff,StaffQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const STAFF_URL = `${API_URL}/staff`
const GET_SPECIALITY_URL = `${API_URL}/speciality`
const GET_ROLES_URL = `${API_URL}/roles`
const GET_SHIFT_URL = `${API_URL}/shift`
const GET_STAFF_URL = `${API_URL}/staff`


const getStaff = (query: string,id:number): Promise<StaffQueryResponse> => {
  return axios
    .get(`${GET_STAFF_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<StaffQueryResponse>) => d.data)
}

const getStaffByID = (id: ID): Promise<Staff | undefined> => {
  return axios
    .get(`${STAFF_URL}/${id}`)
    .then((response: AxiosResponse<Response<Staff>>) => response.data)
    .then((response: Response<Staff>) => response.data)
}

const getStaffReport = (id: ID): Promise<Staff | undefined> => {
  return axios
    .get(`${STAFF_URL}/csv?hospital_id=${id}`)
    .then((response: AxiosResponse<Response<Staff>>) => response.data)
    .then((response: Response<Staff>) => response.data)
}

const createStaff = (Staff: any): Promise<Staff | undefined> => { 
  return axios
    .post(STAFF_URL, Staff)
}

const updateStaff = (Staff: any, id:any): Promise<Staff | undefined> => {
  return axios
    .patch(`${STAFF_URL}/${id}`, Staff)
}

const deleteStaff = (StaffId: ID): Promise<void> => {
  return axios.delete(`${STAFF_URL}/${StaffId}`)
}

const deleteSelectedStaff = (StaffIds: Array<ID>): Promise<void> => {
  const requests = StaffIds.map((id) => axios.delete(`${STAFF_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getSpeciality = (): Promise<StaffQueryResponse> => {
    return axios
      .get(`${GET_SPECIALITY_URL}`)
      .then((d: AxiosResponse<StaffQueryResponse>) => d.data)
}

const getRoles = (): Promise<StaffQueryResponse> => {
  return axios
    .get(`${GET_ROLES_URL}`)
    .then((d: AxiosResponse<StaffQueryResponse>) => d.data)
}

const getShift = (): Promise<StaffQueryResponse> => {
  return axios
    .get(`${GET_SHIFT_URL}`)
    .then((d: AxiosResponse<StaffQueryResponse>) => d.data)
}

export {getStaff,getShift, deleteStaff,getStaffReport, deleteSelectedStaff, getStaffByID, createStaff, updateStaff,getSpeciality,getRoles}
