/* eslint-disable react/jsx-no-duplicate-props */
import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialUser, Operationlist} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {
  createOpration,
  getHospitalRooms,
  getPatients,
  getRoomByID,
  updateOperation,
} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {OperationsListLoading} from '../components/loading/OperationsListLoading'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'
import {getDoctorDataAva} from '../../../Patient-appointment/appointments-list/core/_requests'

type Props = {
  isUserLoading: boolean
  user: Operationlist
}

function mapDataToNodes(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({
      value: item?.full_name,
      label: item?.full_name,
      id: item?.id,
    })
  })

  return parent
}

function mapDataToNodesDoctor(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({
      value: item?.doctor?.full_name,
      label: item?.doctor?.full_name,
      id: item?.doctor?.id,
    })
  })

  return parent
}

function mapDataToNodesRoom(data: any) {
  let parent: any = []

  data?.map((item: any) => {
    parent.push({value: item?.room_number, label: item?.room_number, id: item?.id})
  })

  return parent
}

const OperationEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [patientData, setPatientData] = useState(null)
  const [doctorData, setDoctorData] = useState([])
  const [patientRoom, setPatientRoom] = useState(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedRoom, setSelectedRoomId] = useState<any>(null)
  const [roomType, setRoomType] = useState<any>(null)
  const {selectedHospitalId} = useContext(loginContext)

  const [userForEdit] = useState<Operationlist>({
    ...user,
    patient_id: user.patient_id || initialUser.patient_id,
    doctor_ids: user.doctor_ids || initialUser.doctor_ids,
    hospital_room_id: user.hospital_room_id || initialUser.hospital_room_id,
    operation_start_date: user.operation_start_date || initialUser.operation_start_date,
    operation_end_date: user.operation_end_date || initialUser.operation_end_date,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  let editUserSchema
  const MAX_OPERATION_DURATION_HOURS = 24

  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      operation_start_date: Yup.date().required(
        intl.formatMessage({id: 'VALIDATION.SELECT.START_DATE'})
      ),
      operation_end_date: Yup.date()
        .required(intl.formatMessage({id: 'VALIDATION.SELECT.END_DATE'}))
        .min(
          Yup.ref('operation_start_date'),
          intl.formatMessage({id: 'VALIDATION.END_DATE.AFTER.START_DATE'})
        )
        .test(
          'maxDuration',
          intl.formatMessage({id: 'VALIDATION.OPERATION_DURATION.EXCEEDS.24_HOURS'}),
          function (value) {
            const startDate: any = this.resolve(Yup.ref('operation_start_date'))
            if (startDate && value) {
              const startMoment = moment(startDate)
              const endMoment = moment(value)
              const durationHours = endMoment.diff(startMoment, 'hours')
              return durationHours <= MAX_OPERATION_DURATION_HOURS
            }
            return true
          }
        ),
    })
  } else {
    editUserSchema = Yup.object().shape({
      patient_id: Yup.string().required(intl.formatMessage({id: 'VALIDATION.ATD.SELECT_PATIENT'})),
      doctor_ids: Yup.array()
        .min(1, intl.formatMessage({id: 'VALIDATION.SELECT.AT_LEAST_ONE_DOCTOR'}))
        .required(intl.formatMessage({id: "VALIDATION.SELECT.DOCTOR'S"})),
      hospital_room_id: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.SELECT.PATIENT_ROOM'})
      ),
      operation_start_date: Yup.date().required(
        intl.formatMessage({id: 'VALIDATION.SELECT.START_DATE'})
      ),
      operation_end_date: Yup.date()
        .required(intl.formatMessage({id: 'VALIDATION.SELECT.END_DATE'}))
        .min(
          Yup.ref('operation_start_date'),
          intl.formatMessage({id: 'VALIDATION.END_DATE.AFTER.START_DATE'})
        )
        .test(
          'maxDuration',
          intl.formatMessage({id: 'VALIDATION.OPERATION_DURATION.EXCEEDS.24_HOURS'}),
          function (value) {
            const startDate: any = this.resolve(Yup.ref('operation_start_date'))
            if (startDate && value) {
              const startMoment = moment(startDate)
              const endMoment = moment(value)
              const durationHours = endMoment.diff(startMoment, 'hours')
              return durationHours <= MAX_OPERATION_DURATION_HOURS
            }
            return true
          }
        ),
    })
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      values.doctor_ids = values?.doctor_ids?.map((v) => Number(v))
      const startDate = moment(values['operation_start_date'], 'DD-MM-YYYY HH:mm:ss').format(
        'DD-MM-YYYY HH:mm:ss'
      )
      const endDate = moment(values['operation_end_date'], 'DD-MM-YYYY HH:mm:ss').format(
        'DD-MM-YYYY HH:mm:ss'
      )

      values['operation_start_date'] = startDate
      values['operation_end_date'] = endDate

      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          delete values?.allocated_doctor
          delete values?.doctor_ids
          delete values?.hospital_room
          delete values?.hospital
          delete values?.hospital_id
          delete values?.hospital_room_id
          delete values?.patient
          delete values?.patient_id
          delete values?.updated_at
          delete values?.created_at

          await updateOperation(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          await createOpration({...values, hospital_id: Number(selectedHospitalId)}).then(
            (res: any) => {
              toast.success(res?.data?.message)
            }
          )
        }
      } catch (ex: any) {
        toast.warning(ex?.response?.data?.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const fecthPatient = async () => {
    const allPatient: any = await getPatients()

    const allActivePatients = allPatient?.data?.filter((item: any) => {
      return item?.status === 'ACTIVE' && item?.hospital_id == selectedHospitalId
    })
    if (allActivePatients) {
      setPatientData(allActivePatients)
    }
  }

  useEffect(() => {
    fecthPatient()
  }, [])

  const fetchDoctor = async () => {
    const allDoctorData1: any = await getDoctorDataAva(selectedHospitalId, 'NOW')

    const uniqueAppointments = Object.values(
      allDoctorData1?.data?.data.reduce((acc: any, appointment: any) => {
        if (!acc[appointment.doctor_id]) {
          acc[appointment.doctor_id] = appointment
        }
        return acc
      }, {})
    )

    const activeDoctorData: any = uniqueAppointments?.filter((item: any) => {
      return item?.hospital_id == selectedHospitalId
    })
    if (allDoctorData1) {
      setDoctorData(activeDoctorData)
    }
  }

  useEffect(() => {
    fetchDoctor()
  }, [])

  const fetchRoom = async () => {
    const allRoomData: any = await getHospitalRooms()

    const allAvailableRooms = allRoomData?.data?.filter((item: any) => {
      return (
        item?.availability_status === 'AVAILABLE' && item?.hospital_id?.id == selectedHospitalId
      )
    })

    if (allAvailableRooms) {
      setPatientRoom(allAvailableRooms)
    }
  }

  useEffect(() => {
    fetchRoom()
  }, [])

  const options: any = mapDataToNodes(patientData)
  const optionsDoctor: any = mapDataToNodesDoctor(doctorData)
  const Optionpatient: any = mapDataToNodesRoom(patientRoom)

  const handleDoctorChange = (selectedDoctor: any) => {
    formik.setFieldValue('hospital_room_id', Number(selectedDoctor.id))
    setSelectedRoomId(selectedDoctor)
  }

  useEffect(() => {
    ;(async () => {
      if (selectedRoom) {
        const allRoomData: any = await getRoomByID(Number(selectedRoom?.id))
        setRoomType(allRoomData)
      }
    })()
  }, [selectedRoom])

  const currentDate = new Date()

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <>
            <div className='row'>
              <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.PATIENT'})}
                </label>

                <Select
                  id='patient_id'
                  name='patient_id'
                  options={options}
                  styles={customStyles}
                  value={options.find((option: any) => option.id === formik.values.patient_id)}
                  onChange={(option) => formik.setFieldValue('patient_id', Number(option?.id))}
                  placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.PATIENT'})}
                  isDisabled={itemIdForUpdate ? true : false}
                  className='select_arrow_remove'
                />
                {formik.touched.patient_id && formik.errors.patient_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.patient_id}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-7 col-12 col-sm-6'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.DOCTOR(S)'})}
                </label>
                <Select
                  isMulti
                  id='doctor_ids'
                  name='doctor_ids'
                  options={optionsDoctor}
                  styles={customStyles}
                  value={optionsDoctor.filter((option: any) =>
                    formik?.values?.doctor_ids?.includes(option.id)
                  )}
                  onChange={(selectedOptions) => {
                    const selectedDoctorIds = selectedOptions.map((option) => option.id)
                    formik.setFieldValue('doctor_ids', selectedDoctorIds)
                  }}
                  className={`basic-multi-select select_arrow_remove`}
                  classNamePrefix='select'
                  placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.DOCTOR(S)'})}
                  isDisabled={itemIdForUpdate ? true : false}
                />

                {formik.touched.doctor_ids && formik.errors.doctor_ids && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.doctor_ids}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.ROOM'})}
                </label>

                <Select
                  id='hospital_room_id'
                  name='hospital_room_id'
                  options={Optionpatient}
                  styles={customStyles}
                  value={Optionpatient.find(
                    (option: any) => option.id === formik.values.hospital_room_id
                  )}
                  onChange={handleDoctorChange}
                  placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.ROOM'})}
                  className='select_arrow_remove'
                  isDisabled={itemIdForUpdate ? true : false}
                />
                {formik.touched.hospital_room_id && formik.errors.hospital_room_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.hospital_room_id}</span>
                    </div>
                  </div>
                )}
              </div>

              {selectedRoom ? (
                <div className='fv-row mb-7 col-12 col-sm-6'>
                  <label className='fw-bold fs-6 mb-2'>
                    {intl.formatMessage({id: 'GENERAL.ROOM.TYPE'})}
                  </label>
                  <input
                    type='text'
                    value={roomType?.type}
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    disabled
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </>
          {/* )} */}

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-4 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'OPERATION_THEATRE.START.DATE.TIME'})}
              </label>
              <div className='position-relative'>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('operation_start_date')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy HH:mm'
                  name='operation_start_date'
                  timeInputLabel='Time:'
                  selected={startDate}
                  onChange={(date) => {
                    formik.setFieldValue('operation_start_date', date)
                    setStartDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  showTimeInput
                  shouldCloseOnSelect={false}
                  placeholderText={intl.formatMessage({id: 'OPERATION_THEATRE.START.DATE.TIME'})}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                />
                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('operation_start_date')[0].focus()}
                >
                  <i className='fa fa-calendar'></i>
                </span>
              </div>
              {formik.touched.operation_start_date && formik.errors.operation_start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.operation_start_date}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-4'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'OPERATION_THEATRE.END.DATE.TIME'})}
              </label>
              <div className='position-relative'>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('operation_end_date')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy HH:mm'
                  name='operation_end_date'
                  timeInputLabel='Time:'
                  selected={endDate}
                  onChange={(date) => {
                    formik.setFieldValue('operation_end_date', date)
                    setEndDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  showTimeInput
                  placeholderText={intl.formatMessage({id: 'OPERATION_THEATRE.END.DATE.TIME'})}
                  shouldCloseOnSelect={false}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  minDate={itemIdForUpdate ? currentDate : startDate}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('operation_end_date')[0].focus()}
                >
                  <i className='fa fa-calendar'></i>
                </span>
              </div>
              {formik.touched.operation_end_date && formik.errors.operation_end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.operation_end_date}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-4'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'OPERATION_THEATRE.OPERATION.TYPE'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.OPERATION.TYPE'})}
                {...formik.getFieldProps('operation_type')}
                type='text'
                name='operation_type'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
            </div>
          </div>

          <div className='row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
            </label>

            <textarea
              {...formik.getFieldProps('operation_description')}
              className='form-control no-resize'
              placeholder={intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
              name='operation_description'
              id='exampleFormControlTextarea1'
              disabled={formik.isSubmitting || isUserLoading}
              rows={2}
            ></textarea>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <OperationsListLoading />}
    </>
  )
}

export {OperationEditModalForm}
