import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/NotificationListHeader'
import {UsersTable} from './table/NotificationTable'
import {KTCard} from '../../../../_jmh/helpers'

const NotificationList = () => {
  return (
    <>
      <KTCard className='shadow'>
        <UsersListHeader />
        <UsersTable />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <UserEditModal />} */}
    </>
  )
}

const NotificationListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <NotificationList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {NotificationListWrapper}
