import {useQuery} from 'react-query'
import {ShiftEditModalForm} from './ShiftEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getShiftById} from '../core/_requests'

const ShiftEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getShiftById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  //   if(user && user['days_off']) {
  //    const formatePurchaseDate = user.days_off
  //     user['days_off'] = formatePurchaseDate
  //  }

  //  if(user && user['disposal_date']) {
  //   const formateDisposalDate = moment(user.disposal_date).format('YYYY-MM-DD')
  //    user['disposal_date'] = formateDisposalDate
  // }

  // @latest
  if (user?.days_off && !Array.isArray(user?.days_off)) {
    user.days_off = (user.days_off as string).split(',')
  }

  if (!itemIdForUpdate) {
    return (
      <ShiftEditModalForm
        isUserLoading={isLoading}
        user={{
          id: undefined,
          type: '',
          start_time: '',
          end_time: '',
          working_hours: 0,
          days_off: [],
        }}
      />
    )
  }

  if (!isLoading && !error && user) {
    return <ShiftEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export {ShiftEditModalFormWrapper}
