import {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {login, patientLogin} from '../../core/_requests'
import {useAuth} from '../../core/Auth'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import Select from 'react-select'
import {getHospitals} from '../../../../pages/landing-page/core/_request'

const initialValues = {
  email: '',
  password: '',
}

type PatientLoginProps = {
  onRegisterClick: () => void
  onForgotPasswordClick: () => void
  onPasswordChangeClick: () => void
  doctorId?: any
  patientSelectedHospitalId?: any
}

export const PatientLogin: React.FC<PatientLoginProps> = ({
  onRegisterClick,
  onForgotPasswordClick,
  onPasswordChangeClick,
  doctorId,
  patientSelectedHospitalId,
}) => {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [patientLoginModalVisible, setPatientLoginModalVisible] = useState(true) // Initial visibility
  const [showPassword, setShowPassword] = useState(false)
  const {setLoginData, setSelectedHospitalId} = useContext(loginContext)
  const [hospitalData, setHospitalData] = useState<any>(null)
  const [patientSelectedHospital, setPatientSelectedHospital] = useState<any>(null)
  const navigate = useNavigate()
  const intl = useIntl()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      })
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL.ERROR'}))
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.EMAIL_ERROR'})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'VALIDATION.MIN_PASS_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.PASSWORD_ERROR'}))
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      }),
  })
  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }
  const handleRegisterClick = () => {
    onRegisterClick()
  }

  const fetchHospitals = () => {
    getHospitals().then((res) => {
      const hospitals = res?.data?.data
      if (hospitals && hospitals.length > 0) {
        const formattedHospitals = hospitals.map((item: any) => ({
          value: item?.id,
          label: item?.name,
          id: item?.id,
        }))

        setHospitalData(formattedHospitals)

        const selectedHospital = formattedHospitals.find(
          (hospital: any) => hospital.id === patientSelectedHospitalId
        )

        if (selectedHospital) {
          setPatientSelectedHospital(selectedHospital)
        }
      }
    })
  }

  const handleHospitalChange = (selectedOption: any) => {
    setPatientSelectedHospital(selectedOption)
  }

  useEffect(() => {
    fetchHospitals()
  }, [])

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      try {
        const responseUser = await patientLogin(
          values.email,
          values.password,
          Number(patientSelectedHospital?.id || patientSelectedHospitalId)
        )
        const auth: any = responseUser?.data

        if (responseUser?.status === 200) {
          localStorage.setItem('loginData', JSON.stringify(auth))
          if (auth?.data?.is_password_change === true) {
            onPasswordChangeClick()
          } else if (auth?.data?.user_type == 'PATIENT') {
            navigate('/patient-booking', {
              state: {
                doctorId: doctorId,
                patientSelectedHospitalId: patientSelectedHospital?.id || patientSelectedHospitalId,
              },
            })
            setLoginData(auth)
            setCurrentUser(auth?.data?.access_token)
            toast.success(responseUser?.data?.message)
          }

          if (auth?.data.user_type === 'SUPER_ADMIN') {
            setSelectedHospitalId(1)
          } else {
            setSelectedHospitalId(auth?.data?.hospital?.id)
          }
        }

        const {data} = auth

        const userData: any = {
          id: data?.id,
          email: data?.email,
          isPasswordChange: data?.is_password_change,
          userType: data?.userType,
          status: data?.status,
          access_token: data?.access_token,
        }
        saveAuth(userData)
      } catch (error: any) {
        saveAuth(undefined)
        setStatus(error?.response?.data?.message)
        setSubmitting(false)
        setLoading(false)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      {patientLoginModalVisible && (
        <div className='login-form'>
          <div className='row'>
            <div className='col-12 p-0'>
              <form
                className={clsx('form w-100', {
                  'form-not-clickable': formik.isSubmitting,
                })}
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                {formik.status ? (
                  <div className='alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className='input-group mb-4'>
                  <label className='form-label fs-6 fw-bolder w-100'>
                    {intl.formatMessage({id: 'GENERAL.HOSPITAL.SELECT_HOSPITAL'})}
                  </label>
                  <Select
                    className='w-100'
                    value={patientSelectedHospital || null}
                    onChange={handleHospitalChange}
                    options={hospitalData}
                    styles={customStyles}
                    placeholder={intl.formatMessage({id: 'GENERAL.HOSPITAL.SELECT_HOSPITAL'})}
                  />

                  {patientSelectedHospital === null && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>
                          {intl.formatMessage({id: 'GENERAL.HOSPITAL_REQUIRED.ERROR'})}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='input-group mb-4'>
                  <label className='form-label fs-6 fw-bolder w-100'>
                    {intl.formatMessage({id: 'GENERAL.EMAIL'})}
                  </label>

                  <div className='position-relative w-100'>
                    <input
                      placeholder={intl.formatMessage({id: 'GENERAL.EMAIL'})}
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid grey_input '
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='input-group mb-4'>
                  <label className='form-label fs-6 fw-bolder w-100'>
                    {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                  </label>

                  <div className='position-relative w-100 grey_input'>
                    <input
                      placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid grey_input '
                      )}
                    />

                    <button
                      className='btn position-absolute top-0 end-0 bottom-0'
                      type='button'
                      id='show-password'
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <i className='bi bi-eye-fill'></i>
                      ) : (
                        <i className='bi bi-eye-slash-fill'></i>
                      )}
                    </button>
                  </div>

                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='text-center w-auto'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className='indicator-label fw-bolder fs-4'>
                        {intl.formatMessage({id: 'GENERAL.CONTINUE'})}
                      </span>
                    )}
                    {loading && (
                      <span
                        className='indicator-progress fw-bolder fs-4'
                        style={{display: 'block'}}
                      >
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                <div className='input-group mt-5'>
                  <div className='d-flex justify-content-between w-100'>
                    <Link
                      to='/'
                      className='link-primary fs-6 fw-bolder'
                      onClick={(e) => {
                        e.preventDefault()
                        handleRegisterClick()
                      }}
                    >
                      {intl.formatMessage({id: 'AUTH.GENERAL.REGISTER'})}
                    </Link>
                    <Link
                      to='/forgot-password'
                      className='link-primary fs-6 fw-bolder'
                      onClick={(e) => {
                        e.preventDefault()
                        onForgotPasswordClick()
                      }}
                    >
                      {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
