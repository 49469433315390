import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {AssignInv, AssignInvQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const ASSIGN_INV_URL = `${API_URL}/assign-inventory`
const GET_ASSIGN_INV_URL = `${API_URL}/assign-inventory/`
const INVENTORY_URL = `${API_URL}/inventory`
const GET_INVENTORY_CAT_URL = `${API_URL}/inventory-category`
const HOSPITAL_ROOM_URL = `${API_URL}/hospital-room`


const getAssignInv = (query: string,id:number): Promise<AssignInvQueryResponse> => {
  return axios
    .get(`${GET_ASSIGN_INV_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<AssignInvQueryResponse>) => d.data)
}

const getAssignInvById = (id: ID): Promise<AssignInv | undefined> => {
  return axios
    .get(`${ASSIGN_INV_URL}/${id}`)
    .then((response: AxiosResponse<Response<AssignInv>>) => response.data)
    .then((response: Response<AssignInv>) => response.data)
}

const createAssignInv = (user: AssignInv): Promise<AssignInv | undefined> => {
  return axios
    .post(ASSIGN_INV_URL, user)
}

const updateAssignInv = (user: AssignInv): Promise<AssignInv | undefined> => {
 const {assigned_quantity } = user
  return axios
    .patch(`${ASSIGN_INV_URL}/${user.id}`, {assigned_quantity})
}

export  const getInventory = (): Promise<AssignInvQueryResponse> => {
  return axios
    .get(`${INVENTORY_URL}`)
    .then((d: AxiosResponse<AssignInvQueryResponse>) => d.data)
}

export const getCategoryList = (): Promise<AssignInvQueryResponse> => {
  return axios
    .get(`${GET_INVENTORY_CAT_URL}`)
    .then((d: AxiosResponse<AssignInvQueryResponse>) => d.data)
}
export const getRoomsList = (): Promise<AssignInvQueryResponse> => {
  return axios
    .get(`${HOSPITAL_ROOM_URL}`)
    .then((d: AxiosResponse<AssignInvQueryResponse>) => d.data)
}

const deleteRoom = (Id: ID): Promise<void> => {
  return axios.delete(`${ASSIGN_INV_URL}/${Id}`,{data: { id: [Id]}}).then(() => {})
}

export {getAssignInv, getAssignInvById, createAssignInv, updateAssignInv,deleteRoom}
