import {SpecialityListToolbar} from './SpecialityListToolbar'
import {SpecialityListSearchComponent} from './SpecialityListSearchComponent'

const SpecialityListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <SpecialityListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <SpecialityListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SpecialityListHeader}
