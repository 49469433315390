import {ID, Response} from '../../../../../_jmh/helpers'

export interface HospitalId {
  id?: ID;
  name?: string;
  address?: string;
  contact_person: string;
  contact_number: string;
  status: string;
  created_at: string;
  updated_at: string;
} 


export type ShiftModel = {
    id?: ID;
    type?: string;
    start_time?: string;
    end_time?: string;
    working_hours?: number;
    status?: string;
    created_at?: string;
    updated_at?: string;
    hospital_id?: (HospitalId) | number;
    days_off?: Array<string>;
    // days_off?: string
  } 
  
  
export type ShiftQueryResponse = Response<Array<ShiftModel>>

export const initialShift: ShiftModel = {
  type: '',
  start_time: '',
  end_time: '',
}