import {useContext, useState} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {getAssetMaintenanceReport} from '../../core/_requests'
import {AssetMaintenanceLoading} from '../loading/AssetMaintenanceLoading'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

const AssetMaintenanceListToolbar = () => {
  const intl = useIntl()
  const tabledata = useQueryResponseData()
  const {setItemIdForUpdate} = useListView()
  const {selectedHospitalId} = useContext(loginContext)
  const [isLoading, setIsLoading] = useState(false)
  const {loginData} = useContext(loginContext)
  const openAddAssetModal = () => {
    setItemIdForUpdate(null)
  }

  const handleReportDownload = async () => {
    try {
      const getReportData: any = await getAssetMaintenanceReport(selectedHospitalId)
      const uint8Array = new Uint8Array(getReportData?.data)

      const blob = new Blob([uint8Array], {type: 'text/csv'})

      // Create a temporary URL for the blob
      const url = URL.createObjectURL(blob)

      // Create a temporary link element
      const link = document.createElement('a')
      link.href = url
      link.download = 'asset_maintenance_report.csv'

      // Programmatically click the link to trigger the download
      link.click()
      console.log(uint8Array)
      // Clean up the temporary URL and link
      URL.revokeObjectURL(url)
      link.remove()
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setIsLoading(false) // Set loading state to false
    }
  }

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'asset-maintenance') {
      return item?.module_id
    }
  })

  const add = data?.filter((item: any) => {
    if (item.permission.method === 'POST') {
      return item
    }
  })

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className='btn btn-primary me-3'
        disabled={tabledata?.length > 0 ? false : true}
        onClick={handleReportDownload}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'GENERAL.EXPORT'})}
      </button>
      {add.length ? (
        <button type='button' className='btn btn-primary' onClick={openAddAssetModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'ASSET.MAINTENANCE.ADD.ASSET.MAINTENANCE'})}
        </button>
      ) : (
        ''
      )}
      {isLoading && <AssetMaintenanceLoading />}
    </div>
  )
}

export {AssetMaintenanceListToolbar}
