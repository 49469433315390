/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {deletePatient} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {Patient} from '../../core/_models'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
  patient?: Patient
}

const PatientActionsCell: FC<Props> = ({id, patient}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {
    loginData,
    setSelectedPatient,
    setSelectedPatientId,
    selectedPatientId,
    selectedPatientTab,
  } = useContext(loginContext)
  const pagination = useQueryResponsePagination()
  const [currentPage, setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const [isDelete, setIsDelete] = useState(false)
  const intl = useIntl()

  const handlePatientIdChange = (id: any) => {
    setSelectedPatientId(id)
    setSelectedPatient({value: id, label: patient?.full_name})
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [selectedPatientId])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const page = pagination?.pagination?.meta?.currentPage || 1

  // const deleteItem = useMutation(() => deletePatient(id), {
  //   onSuccess: async () => {
  //     await queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //     if (page > 1) {
  //       const updatedData = await queryClient.getQueryData<any>([`${QUERIES.USERS_LIST}-${query}`])
  //       if (updatedData?.data?.length > 0) {
  //         setCurrentPage(page)
  //       } else {
  //         setCurrentPage(page - 1)
  //         updateState({
  //           page: page - 1,
  //           items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
  //         })
  //       }
  //     }

  //     toast.error('Patient Deleted')
  //   },
  // })

  useEffect(() => {
    refetch()
  }, [selectedPatientId, refetch, isDelete])

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH' && item.permission.route === ':id') {
      return item
    }
  })

  const view = data?.filter((item: any) => {
    if (item.permission.route === ':id' && item.permission.method === 'GET') {
      return item
    }
  })

  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE' && item.permission.route === ':id') {
      return item
    }
  })

  const handleRemove = (id: any) => {
    if (id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          title: `Are you sure you want to delete Patient ?`,
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Close',
          reverseButtons: true,
        })
        .then(async (result: any) => {
          if (result.isConfirmed) {
            try {
              await deletePatient(id)

              // Make the API call to delete the doctor's availability
              swalWithBootstrapButtons.fire('Deleted!', `Patient has been deleted.`, 'success')
              setIsDelete((val) => !val)
              if (page > 1) {
                const updatedData = await queryClient.getQueryData<any>([
                  `${QUERIES.USERS_LIST}-${query}`,
                ])
                if (updatedData?.data?.length > 0) {
                  setCurrentPage(page)
                } else {
                  setCurrentPage(page - 1)
                  updateState({
                    page: page - 1,
                    items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
                  })
                }
              }
            } catch (ex: any) {
              if (ex.response.data.message) {
                toast.error(ex.response.data.message)
              }
            }
          }
        })
    }
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {edit.length ? (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </a>
          </div>
        ) : (
          ''
        )}
        {/* end::Menu item */}

        {view.length ? (
          <div className='menu-item px-3'>
            <Link
              to={`/patient-data/patient-management/patient-view/${id}`}
              className='menu-link px-3'
            >
              {intl.formatMessage({id: 'GENERAL.VIEW'})}
            </Link>
          </div>
        ) : (
          ''
        )}

        <div className='menu-item px-3'>
          <Link
            to={`/patient-data/patient-details/${selectedPatientTab}`}
            onClick={() => handlePatientIdChange(id)}
            className='menu-link px-3'
          >
            {intl.formatMessage({id: 'GENERAL.DETAILS'})}
          </Link>
        </div>

        {/* begin::Menu item */}
        {deleteData.length ? (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={() => handleRemove(patient?.id)}
            >
              {intl.formatMessage({id: 'GENERAL.DELETE'})}
            </a>
          </div>
        ) : (
          ''
        )}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {PatientActionsCell}
