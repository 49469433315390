import {useContext, useEffect, useState} from 'react'
import {loginContext} from '../../context/context'
import {Table} from 'react-bootstrap'
import {getPatientInsuranceById} from './core/_request'
import {KTSVG} from '../../../_jmh/helpers'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {OpInsuranceModal} from './OpModals/OpInsuranceModal'
import OpLayout from './OpLayout'

const OpPatientInsurance = () => {
  const intl = useIntl()
  const [insuranceData, setInsuranceData] = useState<any>(null)
  const {loginData} = useContext(loginContext)
  const [insuranceId, setInsuranceId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const fetchInsuranceData = () => {
    getPatientInsuranceById(searchTerm, loginData?.data?.patient_id).then((res: any) => {
      setInsuranceData(res)
    })
  }

  useEffect(() => {
    fetchInsuranceData()
  }, [searchTerm])

  const handleDownload = async (e: any, url: any, extension: any) => {
    e.preventDefault()

    if (url) {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        const downloadUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')

        let fileName = url
          ? url
              .substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'))
              .split('_')
              .pop()
          : ''

        if (extension === 'jpg' || extension === 'jpeg') {
          fileName += '.jpg'
        } else if (extension === 'pdf') {
          fileName += '.pdf'
        } else {
          toast.error('Unsupported file format')
          return
        }

        link.href = downloadUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error('Failed to download file:', error)
      }
    }
  }

  const openAddModal = () => {
    setIsModalOpen(true)
  }

  const openEditModal = (ins_id: any) => {
    setInsuranceId(ins_id)
    setIsModalOpen(true)
  }

  const closeEditModal = () => {
    setInsuranceId(null)
    setIsModalOpen(false)
  }

  return (
    <OpLayout>
      <div className='op_inner_wrapper p-5'>
        <div className='d-flex justify-content-between align-items-center mb-25'>
          <h2 className='op_head_title m-0'>Your Insurance</h2>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='search'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button type='button' className='btn btn-primary' onClick={openAddModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'PATIENT_INSURANCE.ADD.PATIENT.INSURANCE'})}
          </button>
        </div>

        <Table striped className='table-striped'>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Type</th>
              <th>Policy Number</th>
              <th>Documents</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {insuranceData && insuranceData.length > 0 ? (
              insuranceData.map((item: any) => {
                const url: any = item.insurance_upload
                const extension = url?.split('.').pop()?.toLowerCase()

                return (
                  <tr key={item.id}>
                    <td>{item.company_name}</td>
                    <td>{item.type}</td>
                    <td>{item.policy_number}</td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex flex-column'>
                          {item.insurance_upload &&
                          item.insurance_upload !== null &&
                          item.insurance_upload?.length > 0 ? (
                            <>
                              {extension === 'pdf' ? (
                                <a
                                  href='#'
                                  onClick={(e) =>
                                    handleDownload(e, item?.insurance_upload, extension)
                                  }
                                >
                                  <KTSVG
                                    path='/media/svg/files/pdf.svg'
                                    className='svg-icon-primary svg-icon-3hx'
                                  />
                                </a>
                              ) : (
                                <>
                                  {extension === 'jpg' || extension === 'jpeg' ? (
                                    <>
                                      <a
                                        href={item?.insurance_upload}
                                        target='_blank'
                                        style={{width: '50px', height: '50px'}}
                                      >
                                        <img
                                          className='w-100 h-100 object-fit-cover'
                                          src={item?.insurance_upload}
                                        />
                                      </a>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='btn btn-light btn-active-light-primary btn-sm px-4 me-4 justify-content-end'
                        onClick={() => openEditModal(item?.id)}
                      >
                        <span>
                          <i className='bi bi-pencil-square fs-2'></i>
                        </span>
                      </a>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {isModalOpen && (
          <OpInsuranceModal
            show={isModalOpen}
            insuranceId={insuranceId}
            closeModal={closeEditModal}
            patientId={loginData?.data?.patient_id}
            fetchInsuranceData={fetchInsuranceData}
          />
        )}
      </div>
    </OpLayout>
  )
}

export default OpPatientInsurance
