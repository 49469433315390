// @ts-nocheck
import {Column} from 'react-table'
import {Patient} from '../../core/_models'
import {MedicineNameCell} from './MedicineNameCell'
import {MedicineCustomHeader} from './MedicineCustomHeader'
import {MedicineMasterActionsCell} from './MedicineMasterActionsCell'
import {MedicineFrequencyCell} from './MedicineFrequencyCell'
import {MedicineDurationCell} from './MedicineDurationCell'
import {MedicineStatusCell} from './MedicineStatusCell'
import {MedicineTypeCell} from './MedicineTypeCell'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const patientsColumns: ReadonlyArray<Column<Patient>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <MedicineCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.MEDICINE.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'name',
    Cell: ({...props}) => <MedicineNameCell medicine={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <MedicineCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.MEDICINE.TYPE'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'type',
    Cell: ({...props}) => <MedicineTypeCell medicine={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <MedicineCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.FREQUENCY'})}
          className='min-w-125px'
        />
      )
    },
    id: 'frequency',
    Cell: ({...props}) => <MedicineFrequencyCell medicine={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <MedicineCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DURATION'})}
          className='min-w-125px'
        />
      )
    },
    id: 'duration',
    Cell: ({...props}) => <MedicineDurationCell medicine={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <MedicineCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.INSTRUCTION'})}
          className='min-w-125px'
        />
      )
    },
    id: 'instruction',
    Cell: ({...props}) => <MedicineStatusCell medicine={props.data[props.row.index]} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <MedicineCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px pe-5'
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'patient-medicine-master') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH'
            // item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <MedicineCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px'
          />
        ) : (
          ''
        )
      } else {
        return null
      }
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <MedicineMasterActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'patient-medicine-master') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH'
            // item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <MedicineMasterActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        ) : null
      } else {
        return null
      }
    },
  },
]

export {patientsColumns}
