import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {MedicalBackgroundTable} from './table/MedicalBackgroundTable'

// import {KTCard} from '../../../../../_metronic/helpers'

const MedicalBackgroundList = () => {
  return (
    <>
      <div className='Medical_back_main'>
        {/* <PatientVitalListHeader /> */}
        <MedicalBackgroundTable />
      </div>
      {/* {itemIdForUpdate !== undefined && <MedicalBackgroundEditModal user={data} />} */}
    </>
  )
}

const MedicalBackgroundListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <MedicalBackgroundList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MedicalBackgroundListWrapper}
