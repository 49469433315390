import { ID, Response } from "../../../../../_jmh/helpers"

export type PatientInsurance = {
  id?: ID
  subscriber_name?: string
  company_name?: string
  type?: string
  policy_number?: string
  starting_date?: string
  ending_date?: string
  insurance_upload?: string
  created_at?: string
  updated_at?: string
  initials?: {
    label: string
    state: string
  }
  patient_id?: {
    id: string,
    hospital_id: string,
    full_name: string,
    gender: string,
    contact_number: string,
    date_of_birth: string,
    permanent_address: null,
    email: string ,
    alternate_contact_number: null,
    blood_group: string,
    status: string, 
  } 
  hospital_id?:{
    id: number
  } | any
  
}

export type UsersQueryResponse = Response<Array<PatientInsurance>>

export const initialUser: PatientInsurance = {
  subscriber_name: '',
  company_name: '',
  type: '',
  policy_number: '',
  starting_date: '',
  ending_date: '',
  insurance_upload: undefined,
  // patient_id: {
  //   id: "",
  //   hospital_id: "",
  //   full_name: "",
  //   gender: "",
  //   contact_number: "",
  //   date_of_birth: "",
  //   permanent_address: null,
  //   email: "",
  //   alternate_contact_number: null,
  //   blood_group: "",
  //   status: ""
  // }
}
