import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AuditLogsListHeader} from './components/header/AuditLogsListHeader'
import {AuditLogsTable} from './table/AuditLogsTable'

import {KTCard} from '../../../../_jmh/helpers'

const AuditLogsList = () => {
  return (
    <>
      <KTCard className='shadow'>
        <AuditLogsListHeader />
        <AuditLogsTable />
      </KTCard>
    </>
  )
}

const AuditLogsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AuditLogsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AuditLogsListWrapper}
