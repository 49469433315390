import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {OperationsListHeader} from './components/header/OperationsListHeader'
import {OperationsTable} from './table/OperationsTable'
import {OperationEditModal} from './user-edit-modal/OperationEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const OperationsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <OperationsListHeader />
        <OperationsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <OperationEditModal />}
    </>
  )
}

const OperationsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OperationsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {OperationsListWrapper}
