/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useContext} from 'react'
import noUiSlider from 'nouislider'
import {useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {ThemeModeSwitcher} from '../../../partials'
import {AsideToolbar} from '../aside/AsideToolbar'
import HospitalSelect from '../../../../app/pages/hospitals/HospitalSelect'
import {loginContext} from '../../../../app/context/context'

const HeaderToolbar = () => {
  const {classes} = useLayout()
  const {setSelectedHospitalId} = useContext(loginContext)

  const handleHospitalIdChange = (selectedHospitalId: any) => {
    setSelectedHospitalId(selectedHospitalId)
  }

  useEffect(() => {
    const rangeSlider = document.querySelector('#kt_toolbar_slider')
    const rangeSliderValueElement = document.querySelector('#kt_toolbar_slider_value')

    if (!rangeSlider || !rangeSliderValueElement) {
      return
    }

    // @ts-ignore
    noUiSlider.create(rangeSlider, {
      start: [5],
      connect: [true, false],
      step: 1,
      format: {
        to: function (value) {
          const val = +value
          return Math.round(val).toString()
        },
        from: function (value) {
          return value
        },
      },
      range: {
        min: [1],
        max: [10],
      },
    })

    // @ts-ignore
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      rangeSliderValueElement.innerHTML = values[handle]
    })

    const handle = rangeSlider.querySelector('.noUi-handle')
    if (handle) {
      handle.setAttribute('tabindex', '0')
    }

    // @ts-ignore
    handle.addEventListener('click', function () {
      // @ts-ignore
      this.focus()
    })

    // @ts-ignore
    handle.addEventListener('keydown', function (event) {
      // @ts-ignore
      const value = Number(rangeSlider.noUiSlider.get())
      // @ts-ignore
      switch (event.which) {
        case 37:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value - 1)
          break
        case 39:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value + 1)
          break
      }
    })
    return () => {
      // @ts-ignore
      rangeSlider.noUiSlider.destroy()
    }
  }, [])

  return (
    <>
      <div className='toolbar d-flex align-items-stretch'>
        <div className={`${classes.headerContainer.join(' ')} py-6 py-lg-0`}>
          <div className='d-flex flex-wrap align-items-center justify-content-between gap-3 gap-sm-0 h-100'>
            <div className='header_toolbar_one_third order-0'>
              <DefaultTitle />
            </div>
            {/* <div className='header_toolbar_one_third order-2 order-sm-1 text-center'></div> */}
            <div className='w-100 header_toolbar_one_third order-1 order-sm-2 dropdwn_css'>
              <div className='d-flex pt-3 pt-lg-0 justify-content-end'>
                <div className='d-flex align-items-center gap-3'>
                  <HospitalSelect onChange={handleHospitalIdChange} />
                  {/* <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon theme_switch_button_icon btn-active-icon-primary' /> */}
                  <AsideToolbar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {HeaderToolbar}
