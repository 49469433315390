import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'

import {InvManagementListWrapper} from './InventoryManagementList/InvManagementList'
import {useIntl} from 'react-intl'

const InvManagementPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'INVENTORY.INVENTORY_MANAGEMENT'})}
      </PageTitle>
      <InvManagementListWrapper />
    </>
  )
}

export default InvManagementPageWrapper
