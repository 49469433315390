/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useMemo, useState} from 'react'

import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'

import {loginContext} from '../../../../context/context'
import {MedicalBackgroundEditModalForm} from '../Patient-medical-back-edit-model/MedicalBackgroundEditModalForm'
import {useIntl} from 'react-intl'

const MedicalBackgroundTable = () => {
  const intl = useIntl()
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const [showModal, setShowModal] = useState(false)
  const [displayData, setDisplayData] = useState<any>([])

  const handleSave = async () => {
    // Perform logic to save updated card data
    setShowModal(false)
  }

  const handleModalClose = () => {
    setShowModal(false)
    // Reset form data here
  }

  let filterData: any
  // Filter data by patientId
  useEffect(() => {
    if (data) {
      filterData = data
      // .filter((item) => {
      //   if (typeof item.patient_id === 'number') {
      //     return item.patient_id === selectedPatientId
      //   } else {
      //     return item.patient_id && item.patient_id.id === id
      //   }
      // })
      setDisplayData(filterData)
    }
  }, [data])

  const {loginData, setLoginData} = useContext(loginContext)

  const permissionData = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-medical-background') {
      return item?.module_id
    }
  })

  const edit = permissionData?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  return (
    <>
      <div className='card h-100'>
        <div className='px-0 py-0 border-0'>
          <h2 className='d-flex flex-end card-title'>
            {edit.length ? (
              <button
                className='btn btn-light-primary btn-active-primary btn-sm px-4 me-2 mt-2 justify-content-end'
                onClick={() => setShowModal(true)}
                // data-bs-toggle='modal'
                // data-bs-target={`#kt_modal_${selectedPatientId ? selectedPatientId : id}`}
              >
                <span>
                  <i className='bi bi-pencil-square fs-2'></i>
                </span>
              </button>
            ) : (
              ''
            )}
          </h2>
        </div>
        <div className='card-body d-flex flex-column p-0'>
          <div className='card-content'>
            <div className='p-5 pt-0'>
              <div className='row'>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.MEDICAL.HISTORY'})}:
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.medical_history || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.FAMILY.HISTORY'})}:
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.family_history || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.ONGOING.MEDICATION'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.ongoing_medication || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.ALLERGIES'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.allergies || ''} </p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.HABIT.HISTORY'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.habit_history || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.SURGERIES'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.surgeries || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.IMMUNIZATION'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.immunization || ''}</p>
                </div>
              </div>
            </div>
          </div>
          <MedicalBackgroundEditModalForm
            showModal={showModal}
            cardData={displayData?.[0] || []}
            onSave={handleSave}
            onClose={handleModalClose}
            isUserLoading={isLoading}
          />
        </div>
      </div>
    </>

    // <KTCardBody className='py-4'>
    //   <div className='table-responsive'>
    //     <table
    //       id='kt_table_users'
    //       className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
    //       {...getTableProps()}
    //     >
    //       <thead>
    //         <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
    //           {headers.map((column: ColumnInstance<PatientVital>) => (
    //             <CustomHeaderColumn key={column.id} column={column} />
    //           ))}
    //         </tr>
    //       </thead>
    //       <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
    //         {rows.length > 0 ? (
    //           rows.map((row: Row<PatientVital>, i) => {
    //             prepareRow(row)
    //             return <CustomRow row={row} key={`row-${i}-${row.id}`} />
    //           })
    //         ) : (
    //           <tr>
    //             <td colSpan={7}>
    //               <div className='d-flex text-center w-100 align-content-center justify-content-center'>
    //                 No matching records found
    //               </div>
    //             </td>
    //           </tr>
    //         )}
    //       </tbody>
    //     </table>
    //     <PatientVitalListPagination />
    //   </div>
    //   {isLoading && <PatientVitalListLoading />}
    // </KTCardBody>
  )
}

export {MedicalBackgroundTable}
