/* eslint-disable array-callback-return */
import {useContext, useEffect, useMemo} from 'react'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {MedicationListLoading} from '../components/loading/MedicationListLoading'
import {MedicationListPagination} from '../components/pagination/MedicationListPagination'
import {KTCardBody} from '../../../../../_jmh/helpers'
import moment from 'moment'
import {MenuComponent} from '../../../../../_jmh/assets/ts/components'
import {useListView} from '../core/ListViewProvider'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

const MedicationTable = () => {
  const intl = useIntl()
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const {setItemIdForUpdate} = useListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (id: any) => {
    setItemIdForUpdate(id)
  }

  const colors = ['primary', 'success', 'danger', 'warning', 'info']
  const groupedData = useMemo(() => {
    const grouped: any = {}

    data.forEach((item) => {
      const year = moment(item.assigned_date).format('YYYY')
      const month = moment(item.assigned_date).format('MMM')

      if (!grouped[year]) {
        grouped[year] = {}
      }

      if (!grouped[year][month]) {
        grouped[year][month] = []
      }

      grouped[year][month].push(item)
    })

    return grouped
  }, [data])

  const sortedYears = Object.keys(groupedData).sort((a: any, b: any) => b - a)

  const {loginData} = useContext(loginContext)

  const permissionData = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-medication') {
      return item?.module_id
    }
  })
  const edit = permissionData?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  return (
    <KTCardBody className='py-4 px-0'>
      <div className='ps-10 px-1'>
        <div
          className={`row border border-dashed border-gray-300 rounded min-w-800px px-1 py-2 mb-3 bg-light`}
        >
          <div className='col-3'>
            <div className='fs-6 text-muted'>
              {intl.formatMessage({id: 'GENERAL.MEDICATION.NAME'})}
            </div>
          </div>
          <div className='col-2'>
            <div className='fs-6 text-muted'>
              {intl.formatMessage({id: 'MEDICATION.ASSIGN.DATE'})}
            </div>
          </div>
          <div className='col-2'>
            <div className='fs-6 text-muted'>{intl.formatMessage({id: 'GENERAL.DOSAGE'})}</div>
          </div>
          <div className='col-2'>
            <div className='fs-6 text-muted'>{intl.formatMessage({id: 'GENERAL.FREQUENCY'})}</div>
          </div>
          <div className='col-2'>
            <div className='fs-6 text-muted'>{intl.formatMessage({id: 'GENERAL.DURATION'})}</div>
          </div>
          <div className='col-1'>
            <div className='fs-6 text-muted'>{intl.formatMessage({id: 'GENERAL.ACTION'})}</div>
          </div>
        </div>
      </div>
      <div className='timeline'>
        {sortedYears.length > 0 ? (
          <>
            {sortedYears.map((year, index: any) => (
              <div key={`year-${year}`} className='timeline-item'>
                <div
                  className='timeline-line w-20px'
                  style={{marginTop: '18px', left: '-1px'}}
                ></div>
                <div className='timeline-badge'>
                  <i className={`fa fa-genderless text-${colors[index % colors.length]} fs-1`}></i>
                </div>
                <div className='timeline-content '>
                  <div className='mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      <h4>{year}</h4>

                      {Object.keys(groupedData[year])
                        .sort((a, b) => moment(b, 'MMM').diff(moment(a, 'MMM')))
                        .map((month) => (
                          <div key={`month-${year}-${month}`} className='mb-4'>
                            <h5>{month}</h5>

                            {groupedData[year][month].map((item: any, i: any) => (
                              <div key={`row-${i}`} className='row  '>
                                <div
                                  className={`row border border-dashed border-gray-300 rounded min-w-800px px-1 py-2 mb-3 bg-light-${
                                    colors[index % colors.length]
                                  } bg-hover-secondary text-hover-dark text-${
                                    colors[index % colors.length]
                                  }
                                  } `}
                                >
                                  <div className='col-3'>
                                    <span className='fs-4 fw-semibold'>{item.medication_name}</span>
                                  </div>
                                  <div className='col-2'>
                                    <span className={`fs-4   fw-semibold `}>
                                      {moment(item.assigned_date).format('DD-MM-YYYY')}
                                    </span>
                                  </div>
                                  <div className='col-2'>
                                    <div className='fs-4  fw-semibold'>{item.dosage}</div>
                                  </div>
                                  <div className='col-2'>
                                    <div className='fs-4  fw-semibold'>{item.frequency}</div>
                                  </div>
                                  <div className='col-2'>
                                    <div className='fs-4  fw-semibold'>{item.duration}</div>
                                  </div>
                                  <div className='col-1'>
                                    <div className='d-flex gap-2 cursor-pointer'>
                                      {edit.length ? (
                                        <div
                                          className='border-0'
                                          onClick={() => {
                                            openEditModal(item.id)
                                          }}
                                        >
                                          <i
                                            className={`fa-regular fa-pen-to-square text-${
                                              colors[index % colors.length]
                                            } fs-2`}
                                          ></i>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
            {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
          </div>
        )}
      </div>
      <MedicationListPagination />
      {isLoading && <MedicationListLoading />}
    </KTCardBody>
  )
}

export {MedicationTable}
