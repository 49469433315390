import {useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_jmh/helpers'
import {getDoctors} from './core/_request'
import PatientRegistrationModal from './modals/PatientRegistrationModal'
import PatientVerifyOtpModal from './modals/PatientVerifyOtpModal'
import PatientLoginModal from './modals/PatientLoginModal'
import PatientForgotPasswordModal from './modals/PatientForgotPasswordModal'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import PatientChangePasswordModal from './modals/PatientChangePasswordModal'
import {useIntl} from 'react-intl'
import Search from '../../../../public/media/landing_asset/searchicon.png'

type RegistrationRef = {
  resetForm: () => void
}

const LandingBanner = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [isPlaying, setIsPlaying] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlayContainerVisible, setPlayContainerVisible] = useState(false)
  const [doctorData, setDoctorData] = useState<any>(null)
  const [searchInput, setSearchInput] = useState('')
  const intl = useIntl()
  const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false)
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const [showPatientLoginModal, setShowPatientLoginModal] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const registrationFormRef = useRef<RegistrationRef | null>(null)
  const [emailValue, setEmailValue] = useState('')
  const [doctorId, setDoctorId] = useState<any>(null)
  const [patientSelectedHospitalId, setPatientSelectedHospitalId] = useState<any>(null)
  const [showNoDataMsg, setShowNoDataMsg] = useState(false)

  const togglePlayPause = () => {
    const video = videoRef.current
    if (video?.paused) {
      video.play()
      setIsPlaying(true)
    } else {
      video?.pause()
      setIsPlaying(false)
    }
  }

  const handleMouseEnter = () => {
    if (isPlaying) {
      setPlayContainerVisible(true)
    } else {
      setPlayContainerVisible(true)
    }
  }

  const handleMouseLeave = () => {
    if (isPlaying) {
      setPlayContainerVisible(false)
    } else {
      setPlayContainerVisible(true)
    }
  }

  const fetchDoctors = () => {
    if (searchInput.trim() !== '') {
      getDoctors(searchInput).then((res) => {
        setDoctorData(res?.data?.data)
      })
    } else {
      setDoctorData(null)
    }
  }

  const handleSearchButtonClick = () => {
    setShowNoDataMsg(true)
    fetchDoctors()
  }

  useEffect(() => {
    if (searchInput?.length === 0) {
      setDoctorData(null)
    }
    setShowNoDataMsg(false)
  }, [searchInput])

  const handleBookNowClick = (userDoctorId: any, userHospitalId: any) => {
    setDoctorId(userDoctorId)
    setPatientSelectedHospitalId(userHospitalId)
    if (currentUser) {
      navigate('/patient-booking', {
        state: {doctorId: userDoctorId, patientSelectedHospitalId: userHospitalId},
      })
    } else {
      setShowPatientLoginModal(true)
    }
  }

  const handleRegistrationSuccess = (email: string) => {
    setEmailValue(email)
    setShowRegistrationModal(false)
    setShowVerifyOtpModal(true)
  }

  const closeVerifyOtpModal = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.resetForm()
    }
    setShowVerifyOtpModal(false)
  }

  const closeRegistrationModal = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.resetForm()
    }
    setShowRegistrationModal(false)
  }

  const handleLoginLinkClick = () => {
    setShowRegistrationModal(false)
    setShowPatientLoginModal(true)
  }

  const handleRegisterLinkClick = () => {
    setShowPatientLoginModal(false)
    setShowRegistrationModal(true)
  }

  const handleForgotPasswordLinkClick = () => {
    setShowPatientLoginModal(false)
    setShowForgotPasswordModal(true)
  }

  const handlePasswordChangeModal = () => {
    setShowPatientLoginModal(false)
    setShowChangePasswordModal(true)
  }

  return (
    <section className='landing_banner' id='homeBanner'>
      <div className='landing_container'>
        <div className='inner'>
          <div className='banner_inner_content'>
            <div className='row'>
              <div className='col-6'>
                <div className='banner_left_content'>
                  <h3>{intl.formatMessage({id: 'LANDING_PAGE.BANNER_TEXT_ONE'})}</h3>
                  <h5>{intl.formatMessage({id: 'LANDING_PAGE.BANNER_TEXT_TWO'})}</h5>
                  <div className='landing_search_container position-relative'>
                    <input
                      className='landing_search_input'
                      id='searchInput'
                      placeholder={intl.formatMessage({
                        id: 'LANDING_PAGE.BANNER.SEARCH.PLACEHOLDER',
                      })}
                      type='text'
                      value={searchInput}
                      autoComplete='off'
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <div className='landing_search_btn'>
                      <button type='button' onClick={handleSearchButtonClick}>
                        <img
                          src={toAbsoluteUrl('/media/landing_asset/searchicon.png')}
                          alt='Logo'
                        />
                      </button>
                    </div>
                  </div>

                  {searchInput.trim() !== '' && doctorData?.length > 0 ? (
                    <div className='landing_search_result_container'>
                      {doctorData?.map((doctor: any) => (
                        <div className='landing_doctor_search_item' key={doctor?.id}>
                          <div className='landing_doctor_search_item_name'>
                            <p>
                              <span>{doctor?.full_name}</span>&nbsp;
                              <span>({doctor?.user?.hospital?.name})</span>
                            </p>
                          </div>
                          <div className='landing_banner_search_book_btn'>
                            <button
                              type='button'
                              onClick={() =>
                                handleBookNowClick(doctor?.id, doctor?.user?.hospital?.id)
                              }
                            >
                              {intl.formatMessage({id: 'GENERAL.BOOKNOW'})}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    showNoDataMsg &&
                    searchInput?.length > 0 && (
                      <div className='landing_search_result_container'>
                        <p className='m-0 text-center fs-5'>
                          <b>{intl.formatMessage({id: 'LANDING_PAGE.DATA.NOTFOUND'})}</b>
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className='col-6'>
                <div className='oval1'></div>
                <div className='oval2'></div>
                <div className='banner_right_content'>
                  <div
                    className='banner_video_content overflow-hidden position-relative'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <video
                      ref={videoRef}
                      width='100%'
                      height='100%'
                      autoPlay
                      loop
                      muted
                      onClick={togglePlayPause}
                    >
                      <source
                        src={toAbsoluteUrl('/media/landing_asset/banner_video.mp4')}
                        type='video/mp4'
                      />
                      {intl.formatMessage({id: 'GENERAL.VIDEO_NOTFOUND'})}
                    </video>
                    <div
                      className={`playcontainer ${isPlayContainerVisible ? 'visible' : 'hide'}`}
                      onClick={togglePlayPause}
                    >
                      {isPlaying ? (
                        <KTSVG className='video_pause_icon' path='/media/landing_asset/pause.svg' />
                      ) : (
                        <KTSVG className='video_play_icon' path='/media/landing_asset/play.svg' />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showRegistrationModal && (
        <PatientRegistrationModal
          show={showRegistrationModal}
          onClose={closeRegistrationModal}
          onSuccess={handleRegistrationSuccess}
          onLoginClick={handleLoginLinkClick}
          registrationFormRef={registrationFormRef}
        />
      )}

      {showVerifyOtpModal && (
        <PatientVerifyOtpModal
          show={showVerifyOtpModal}
          onClose={closeVerifyOtpModal}
          emailValue={emailValue}
          doctorId={doctorId}
          patientSelectedHospitalId={patientSelectedHospitalId}
        />
      )}

      {showPatientLoginModal && (
        <PatientLoginModal
          show={showPatientLoginModal}
          onClose={() => setShowPatientLoginModal(false)}
          onRegisterClick={handleRegisterLinkClick}
          onForgotPasswordClick={handleForgotPasswordLinkClick}
          onPasswordChangeClick={handlePasswordChangeModal}
          doctorId={doctorId}
          patientSelectedHospitalId={patientSelectedHospitalId}
        />
      )}

      {showChangePasswordModal && (
        <PatientChangePasswordModal
          show={showChangePasswordModal}
          onClose={() => setShowChangePasswordModal(false)}
          onSubmit={() => {
            setShowChangePasswordModal(false)
          }}
        />
      )}

      {showForgotPasswordModal && (
        <PatientForgotPasswordModal
          show={showForgotPasswordModal}
          onClose={() => setShowForgotPasswordModal(false)}
        />
      )}
    </section>
  )
}

export default LandingBanner
