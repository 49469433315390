import {FC} from 'react'
import {User} from '../../core/_models'

type Props = {
  user: User
}

const RoleDescription: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>{user?.description}</span>
      </div>
    </div>
  )
}

export default RoleDescription
