/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AssetMaintenanceModel} from '../../core/_models'
type Props = {
  asset: AssetMaintenanceModel
}
const AssetMaintenanceStatus: FC<Props> = ({asset}) => {
  return (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <div className='text-gray-800 mb-1'>
        <div className='text-center'>
          <span className={`badge ${asset?.maintenance_status === 'PENDING' ? 'badge-light-danger' : 'badge-light-success'} ${asset?.maintenance_status === 'IN MAINTENANCE' ? 'badge-light-warning' : 'badge-light-success'}`}>
              {asset?.maintenance_status}
          </span>
        </div>
      </div>
    </div>
  </div>
  )
}
export {AssetMaintenanceStatus} 