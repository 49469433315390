import {useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {loginContext} from '../../context/context'
import {getMedicalBackground} from './core/_request'
import {OpMedicalBackModal} from './OpModals/OpMedicalBackModal'

const OpMedicalBackground = () => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const [displayData, setDisplayData] = useState<any>([])
  const {loginData} = useContext(loginContext)
  const [getListUpdate, setGetListUpdate] = useState(false)

  const handleSave = async () => {
    setShowModal(false)
    setGetListUpdate((val) => !val)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  useEffect(() => {
    fetchMedicalBackgroundData()
  }, [getListUpdate])

  const fetchMedicalBackgroundData = () => {
    getMedicalBackground(loginData?.data?.patient_id, loginData?.data?.hospital_id).then(
      (res: any) => {
        setDisplayData(res?.data?.data)
      }
    )
  }
  return (
    <div className='op_inner_wrapper py-5'>
      <div className='card h-100'>
        <div className='d-flex justify-content-between align-items-center mb-25'>
          <h2 className='op_head_title m-0'>Your Medical Background</h2>
          <button
            className='btn btn-light-primary btn-active-primary btn-sm px-4 me-2 mt-2 justify-content-end'
            onClick={() => setShowModal(true)}
          >
            <span>
              <i className='bi bi-pencil-square fs-2'></i>
            </span>
          </button>
        </div>

        <div className='card-body d-flex flex-column p-0'>
          <div className='card-content'>
            <div className='p-5 pt-0'>
              <div className='row'>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.MEDICAL.HISTORY'})}:
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.medical_history || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.FAMILY.HISTORY'})}:
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.family_history || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.ONGOING.MEDICATION'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.ongoing_medication || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.ALLERGIES'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.allergies || ''} </p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.HABIT.HISTORY'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.habit_history || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.SURGERIES'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.surgeries || ''}</p>
                </div>
                <div className='mb_field col-12'>
                  <h4 className='text-muted'>
                    {intl.formatMessage({id: 'MEDICAL_BACKGROUND.IMMUNIZATION'})} :
                  </h4>
                  <p className='text-dark'>{displayData?.[0]?.immunization || ''}</p>
                </div>
              </div>
            </div>
          </div>
          <OpMedicalBackModal
            showModal={showModal}
            cardData={displayData?.[0] || []}
            onSave={handleSave}
            onClose={handleModalClose}
            patientId={loginData?.data?.patient_id}
          />
        </div>
      </div>
    </div>
  )
}

export default OpMedicalBackground
