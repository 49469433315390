import {useQuery} from 'react-query'
import {OperationEditModalForm} from './OperationEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {getOperationById} from '../core/_requests'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import moment from 'moment'

const OperationEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getOperationById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (user && user.allocated_doctor) {
    const doctors = user?.allocated_doctor?.map((item) => item.doctor)
    const doctorFullNames = doctors?.map((doctor) => doctor.id) as unknown as number[]
    user.doctor_ids = doctorFullNames
  }
  if (user && user.operation_start_date) {
    const startDate = moment.utc(user.operation_start_date).format('DD-MM-YYYY H:mm')
    user.operation_start_date = startDate

    const endDate = moment.utc(user.operation_end_date).format('DD-MM-YYYY H:mm')
    user.operation_end_date = endDate
  }

  if (!itemIdForUpdate) {
    return <OperationEditModalForm isUserLoading={isLoading} user={{id: undefined}} />
  }

  if (!isLoading && !error && user) {
    return <OperationEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export {OperationEditModalFormWrapper}
