/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

type Props = {
  id: ID
}

const PatientInsuranceActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {loginData, setLoginData} = useContext(loginContext)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-insurance') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  const view = data?.filter((item: any) => {
    if (item.permission.route === ':id' && item.permission.method === 'GET') {
      return item
    }
  })

  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE') {
      return item
    }
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm px-4 me-4 justify-content-end'
        onClick={openEditModal}
        // data-kt-menu-trigger='click'
        // data-kt-menu-placement='bottom-end'
      >
        {edit.length > 0 ? (
          <span>
            <i className='bi bi-pencil-square fs-2'></i>
          </span>
        ) : (
          ''
        )}

        {/* Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
         */}
        {/* <div className='menu-item px-2'>
          <a className='menu-link px-4 justify-content-end' onClick={openEditModal}>
            Edit
            <i className='bi bi-pencil-square pe-3'></i>
          </a>
        </div> */}
      </a>
      {/* begin::Menu */}
      {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-100px py-2'
        data-kt-menu='true'
      >
        
        <div className='menu-item px-2'>
          <a className='menu-link px-4 justify-content-between' onClick={openEditModal}>
            Edit
            <i className='bi bi-pencil-square pe-3'></i>
          </a>
        </div>
        

        
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div>
        
      </div> */}
      {/* end::Menu */}
    </>
  )
}

export {PatientInsuranceActionsCell}
