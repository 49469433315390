import {useContext, useEffect, useState} from 'react'
import {IoBagHandleSharp} from 'react-icons/io5'
import {FaRegCalendarCheck, FaUserClock} from 'react-icons/fa'
import {AiOutlineSchedule} from 'react-icons/ai'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineCancel,
  MdOutlineCheckCircleOutline,
  MdOutlineSchedule,
} from 'react-icons/md'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import {getAllAppointments} from '../../core/_requests'
import {DocInfoTable} from '../../table/DocInfoTable'
import {useIntl} from 'react-intl'
import {BsPlusCircleFill} from 'react-icons/bs'
import {AppointmentEditModalForm} from '../../../../Patient-appointment/appointments-list/appointment-edit-modal/AppointmentEditModalForm'
import {useQuery} from 'react-query'
import {useListView} from '../../core/ListViewProvider'
import {QUERIES, isNotEmpty, toAbsoluteUrl} from '../../../../../../_jmh/helpers'
import {getAppointmentById} from '../../../../Patient-appointment/appointments-list/core/_requests'
import {loginContext} from '../../../../../context/context'
import {AppointmentEditModalHeader} from '../../../../Patient-appointment/appointments-list/appointment-edit-modal/AppointmentEditModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../redux/store'
import {setActiveIndex} from '../../../../../redux/activeIndexSlice'

interface AppointmentCount {
  BOOKED: number
  CANCELLED: number
  COMPLETED: number
  ACTIVE: number
  WAITING: number
  total_appointments: number
}

const DocInfoHeader = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  // const [activeIndex, setActiveIndex] = useState(0)
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
  const [showPicker, setShowPicker] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [isActiveClicked, setIsActiveClicked] = useState(false)
  const [sortConfig, setSortConfig] = useState<{sort: string | null; order: string | null}>({
    sort: null,
    order: null,
  })
  const [appointmentCount, setAppointmentCount] = useState<AppointmentCount>({
    BOOKED: 0,
    CANCELLED: 0,
    COMPLETED: 0,
    ACTIVE: 0,
    WAITING: 0,
    total_appointments: 0,
  })
  const [hospital_id, setHospitalId] = useState<number>(0)
  const [showModal, setShowModal] = useState(false)
  const {loginData} = useContext(loginContext)

  useEffect(() => {
    setHospitalId(loginData?.data?.hospital?.id)
  }, [setHospitalId])

  const {tabSelection, setTabSelection} = useContext(loginContext)
  const dispatch = useDispatch()
  const activeIndex = useSelector((state: RootState) => state.activeIndex.activeIndex)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [showModal])

  useEffect(() => {
    fetchAppointments()
  }, [activeIndex, selectedDate, isActiveClicked])

  let filter = ''

  switch (activeIndex) {
    case 0:
      filter = ''
      break
    case 1:
      filter = 'SCHEDULED'
      break
    case 2:
      filter = 'WAITING'
      break
    case 3:
      filter = 'ACTIVE'
      break
    case 4:
      filter = 'COMPLETED'
      break
    case 5:
      filter = 'CANCELLED'
      break
    default:
      break
  }

  const formattedDate = selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : ''

  const fetchAppointments = async () => {
    try {
      const response = await getAllAppointments(
        hospital_id,
        filter,
        formattedDate || '',
        sortConfig.sort,
        sortConfig.order
      )
      setAppointments(response.data.data)
      setAppointmentCount(response.data.appointmentcount)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCardClick = (index: number) => {
    dispatch(setActiveIndex(index))
    setIsActiveClicked(index === 3)
  }

  const handleDateChange = (amount: number) => {
    if (selectedDate) {
      const newDate = new Date(selectedDate)
      newDate.setDate(selectedDate.getDate() + amount)
      setSelectedDate(newDate)
    }
  }

  const handleOpenAppointment = () => {
    setShowModal(true)
  }

  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getAppointmentById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  return (
    <div className='mt-5'>
      <div className='position-relative' style={{marginTop: '-12px'}}>
        <div className='row'>
          <div className='col-12' style={{marginTop: '24px'}}>
            <div
              className='bg-white-200 p-4 rounded-md mb-4 d-flex align-items-center calndr_de'
              style={{borderRadius: '5px'}}
            >
              <div className='d-flex align-items-center cled'>
                <FaRegCalendarCheck
                  onClick={() => setShowPicker(!showPicker)}
                  style={{fontSize: '1.5rem', cursor: 'pointer'}}
                />
                <span className='mx-2' style={{fontSize: '1.25rem'}}>
                  <MdKeyboardArrowLeft
                    onClick={() => handleDateChange(-1)}
                    className='cursor-pointer'
                  />
                  {selectedDate
                    ? selectedDate.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : 'Select Date'}{' '}
                  <MdKeyboardArrowRight
                    onClick={() => handleDateChange(1)}
                    className='cursor-pointer'
                  />
                </span>
              </div>
              <div className='ms-auto d-flex align-items-center gap-5 cursor-pointer'>
                {/* <BsPlusCircleFill
                  style={{height: '30px', width: '30px', color: '009ef7'}}
                  onClick={() => handleOpenAppointment()}
                /> */}
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => handleOpenAppointment()}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                  {intl.formatMessage({id: 'PATIENT.APPOINTMENTS'})}
                </button>
                {/* <button
                  className='btn'
                  style={{backgroundColor: '#009ef7', color: 'white'}}
                  onClick={() => navigate('/dashboard')}
                >
                  {intl.formatMessage({id: 'GENERAL.BACK'})}
                </button> */}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`row position-absolute top-0 start-0 end-0 ${
            showPicker ? 'd-block' : 'd-none'
          }`}
          style={{zIndex: 1000, marginTop: '43px'}}
        >
          <div className='col-12'>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date)
                setShowPicker(false)
              }}
              inline
              onClickOutside={() => setShowPicker(false)}
            />
          </div>
        </div>
      </div>
      <div className='row bg-f'>
        <div
          className={`col-md-2  ${activeIndex === 0 ? 'active' : ''}`}
          onClick={() => handleCardClick(0)}
        >
          <div className='bg-gray-200 p-4 rounded-md shadow-md'>
            <div className='bg-clr'>
              <img src={toAbsoluteUrl('/media/landing_asset/AllAppointments.png')} />
            </div>

            <div className='d-flex justify-content-center mb-4'>
              <h2 className='text-3xl font-bold'>
                {appointmentCount.total_appointments ? appointmentCount.total_appointments : '0'}
              </h2>
            </div>
            <p className='d-flex align-items-center icnsz'>
              <span className='spantxt' style={{fontSize: '12px'}}>
                {intl.formatMessage({id: 'GENERAL.ALL_APPOINTMENTS'})}
              </span>
            </p>
          </div>
        </div>
        <div
          className={`col-md-2 ${activeIndex === 1 ? 'active' : ''}`}
          onClick={() => handleCardClick(1)}
          style={{borderRadius: '15px'}}
        >
          <div className='bg-gray-200 p-4 rounded-md shadow-md'>
            <div className='bg-clr'>
              <img src={toAbsoluteUrl('/media/landing_asset/Scheduled.png')} />
            </div>
            <div className='d-flex justify-content-center mb-4'>
              <h2 className='text-3xl font-bold'>
                {appointmentCount.BOOKED ? appointmentCount.BOOKED : '0'}
              </h2>
            </div>
            <p className='d-flex align-items-center'>
              <span className='spantxt' style={{fontSize: '12px'}}>
                {intl.formatMessage({id: 'GENERAL.SCHEDULED'})}
              </span>
            </p>
          </div>
        </div>
        <div
          className={`col-md-2 ${activeIndex === 2 ? 'active' : ''}`}
          onClick={() => handleCardClick(2)}
          style={{borderRadius: '15px'}}
        >
          <div className='bg-gray-200 p-4 rounded-md shadow-md'>
            <div className='bg-clr'>
              <img src={toAbsoluteUrl('/media/landing_asset/Waiting.png')} />
            </div>

            <div className='d-flex justify-content-center mb-4'>
              <h2 className='text-3xl font-bold'>
                {appointmentCount.WAITING ? appointmentCount.WAITING : '0'}
              </h2>
            </div>
            <p className='d-flex align-items-center'>
              <span className='spantxt' style={{fontSize: '12px'}}>
                {intl.formatMessage({id: 'GENERAL.WAITING_IN_CLINIC'})}
              </span>
            </p>
          </div>
        </div>
        <div
          className={`col-md-2 ${activeIndex === 3 ? 'active' : ''}`}
          onClick={() => handleCardClick(3)}
          style={{borderRadius: '15px'}}
        >
          <div className='bg-gray-200 p-4 rounded-md shadow-md'>
            <div className='bg-clr'>
              <img src={toAbsoluteUrl('/media/landing_asset/Active.png')} />
            </div>

            <div className='d-flex justify-content-center mb-4'>
              <h2 className='text-3xl font-bold'>
                {appointmentCount.ACTIVE ? appointmentCount.ACTIVE : '0'}
              </h2>
            </div>
            <p className='d-flex align-items-center'>
              <span
                className='spantxt'
                style={{fontSize: '12px'}}
                onClick={() => setIsActiveClicked(true)}
              >
                {intl.formatMessage({id: 'GENERAL.ACTIVE.STATUS'})}
              </span>
            </p>
          </div>
        </div>
        <div
          className={`col-md-2 ${activeIndex === 4 ? 'active' : ''}`}
          onClick={() => handleCardClick(4)}
          style={{borderRadius: '15px'}}
        >
          <div className='bg-gray-200 p-4 rounded-md shadow-md'>
            <div className='bg-clr'>
              <img src={toAbsoluteUrl('/media/landing_asset/Completed.png')} />
            </div>

            <div className='d-flex justify-content-center mb-4'>
              <h2 className='text-3xl font-bold'>
                {appointmentCount.COMPLETED ? appointmentCount.COMPLETED : '0'}
              </h2>
            </div>
            <p className='d-flex align-items-center'>
              <span className='spantxt' style={{fontSize: '12px'}}>
                {' '}
                {intl.formatMessage({id: 'GENERAL.COMPLETED'})}
              </span>
            </p>
          </div>
        </div>
        <div
          className={`col-md-2 ${activeIndex === 5 ? 'active' : ''}`}
          onClick={() => handleCardClick(5)}
          style={{borderRadius: '15px'}}
        >
          <div className='bg-gray-200 p-4 rounded-md shadow-md'>
            <div className='bg-clr'>
              <img src={toAbsoluteUrl('/media/landing_asset/Cancelled.png')} />
            </div>
            <div className='d-flex justify-content-center mb-4'>
              <h2 className='text-3xl font-bold'>
                {appointmentCount.CANCELLED ? appointmentCount.CANCELLED : '0'}
              </h2>
            </div>
            <p className='d-flex align-items-center'>
              <span className='spantxt' style={{fontSize: '12px'}}>
                {' '}
                {intl.formatMessage({id: 'GENERAL.CANCELLED'})}
              </span>
            </p>
          </div>
        </div>
      </div>
      <DocInfoTable
        appointments={appointments}
        fetchAppointments={fetchAppointments}
        setSortConfig={setSortConfig}
        sortConfig={sortConfig}
        activeIndex={activeIndex}
      />
      {showModal && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div
              className={
                tabSelection === 'NOW'
                  ? 'modal-dialog modal-dialog-centered mw-650px'
                  : 'modal-dialog modal-xl modal-dialog-centered'
              }
            >
              <div className='modal-content'>
                <AppointmentEditModalHeader showModal={setShowModal} />
                <div className='modal-body scroll-y mb-2 pt-2'>
                  <AppointmentEditModalForm
                    isUserLoading={isLoading}
                    user={{id: undefined}}
                    showModal={setShowModal}
                    fetchAppointments={fetchAppointments}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </div>
  )
}

export default DocInfoHeader
