import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {DoctorListWrapper} from './DoctorList/Doctorlist'

const DoctorPage = () => (
  <>
    <div className=''>
      <DoctorListWrapper />
    </div>
  </>
)

const DoctorWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'DOCTOR.DOCTOR.MANAGEMENT'})}</PageTitle>
      <DoctorPage />
    </>
  )
}

export {DoctorWrapper}
