import React, {useRef} from 'react'
import {Registration} from '../../../modules/auth/components/PatientComponents/Registration'
import {useIntl} from 'react-intl'

type PatientRegistrationModalProps = {
  show: boolean
  onClose: () => void
  onLoginClick: () => void
  onSuccess: (email: string) => void
  registrationFormRef: React.MutableRefObject<RegistrationRef | null>
}

type RegistrationRef = {
  resetForm: () => void
}

const PatientRegistrationModal: React.FC<PatientRegistrationModalProps> = ({
  show,
  onClose,
  onLoginClick,
  onSuccess,
  registrationFormRef,
}) => {
  const intl = useIntl()
  return (
    <>
      <div
        className={`modal fade${show ? ' show' : ''}`}
        onClick={onClose}
        style={{display: show ? 'block' : 'none'}}
      >
        <div className='modal-dialog'>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='modal-header'>
              <h2 className='modal-title'>{intl.formatMessage({id: 'GENERAL.REGISTRATION'})}</h2>
              <button type='button' className='btn-close' onClick={onClose}></button>
            </div>
            <div className='modal-body'>
              <Registration
                ref={registrationFormRef}
                onSuccess={onSuccess}
                onLoginClick={onLoginClick}
              />
            </div>
          </div>
        </div>
      </div>
      {show && <div className='modal-backdrop fade show'></div>}
    </>
  )
}

export default PatientRegistrationModal
