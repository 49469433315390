import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {PurchaseInventory, PurchaseInvQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const INVENTORY_URL = `${API_URL}/inventory`
const GET_INVENTORY_CAT_URL = `${API_URL}/inventory-category`
const GET_PURCHASE_INVENTORY_URL = `${API_URL}/inventory/purchase`

export const getInventory = (): Promise<PurchaseInvQueryResponse> => {
  return axios.get(`${INVENTORY_URL}`).then((d: AxiosResponse<PurchaseInvQueryResponse>) => d.data)
}

export const getCategoryList = (): Promise<PurchaseInvQueryResponse> => {
  return axios
    .get(`${GET_INVENTORY_CAT_URL}`)
    .then((d: AxiosResponse<PurchaseInvQueryResponse>) => d.data)
}

const getPurchaseInventory = (query: string, id: number): Promise<PurchaseInvQueryResponse> => {
  return axios
    .get(
      `${GET_PURCHASE_INVENTORY_URL}?${
        query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`
      }`
    )
    .then((d: AxiosResponse<PurchaseInvQueryResponse>) => d.data)
}

const getPurchaseInventoryById = (id: ID): Promise<PurchaseInventory | undefined> => {
  return axios
    .get(`${GET_PURCHASE_INVENTORY_URL}/${id}`)
    .then((response: AxiosResponse<Response<PurchaseInventory>>) => response.data)
    .then((response: Response<PurchaseInventory>) => response.data)
}

const createPurchaseInventory = (user: any): Promise<PurchaseInventory | undefined> => {
  return axios.post(INVENTORY_URL, user)
}

const updatePurchaseInventory = (user: any): Promise<PurchaseInventory | undefined> => {
  return axios.patch(`${GET_PURCHASE_INVENTORY_URL}/${user.id}`, user)
}

const deletePurchaseInventory = (Id: ID): Promise<void> => {
  return axios.delete(`${INVENTORY_URL}`, {data: {ids: [Id]}})
}

export {
  getPurchaseInventory,
  deletePurchaseInventory,
  getPurchaseInventoryById,
  createPurchaseInventory,
  updatePurchaseInventory,
}
