import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {resetPassword} from '../core/_requests'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_jmh/helpers'
import {useIntl} from 'react-intl'

const initialValues = {
  newPassword: '',
  confirmNewPassword: '',
}

const ResetPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [newPasswordShow, setNewPasswordShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const token: any = location.search.replace('?', '')
  const intl = useIntl()
  let navigate = useNavigate()

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        intl.formatMessage({id: 'VALIDATION.NEW_PASSWORD'})
      )
      .required(intl.formatMessage({id: 'VALIDATION.REQ.NEW_PASSWORD'})),
    confirmNewPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({id: 'VALIDATION.PASSWORD_MATCHED'})
      )
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED.CONFIRM_PASSWORD'})),
  })

  useEffect(() => {
    if (!token) {
      navigate('/')
    }
  }, [])

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleToggleNewPassword = () => {
    setNewPasswordShow(!newPasswordShow)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const responseUser = await resetPassword(
          values.newPassword,
          values.confirmNewPassword,
          token
        )

        const statusCode: any = responseUser?.status
        if (statusCode === 200) {
          toast.success('Password changed successfully')
          setLoading(false)
          setSubmitting(false)
          setTimeout(() => {
            navigate('/')
          }, 1500)
        }
      } catch (error: any) {
        if (error.response.status === 400) {
          setStatus(error?.response?.data?.message)
        }

        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='reset-form'>
        <div className='container'>
          <div className='row reset-form-bg'>
            <div className='col-md-6 col-sm-12 p-0'>
              <div className='login-vector'>
                <img
                  src={toAbsoluteUrl('/media/auth/login.png')}
                  alt='Doctor Vector'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className=' col-md-6 col-sm-12 p-0'>
              <form
                className='login-input-form form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className='text-center'>
                  <h1 className='mb-3 mt-5'>
                    {intl.formatMessage({id: 'GENERAL.RESET_PASSWORD'})}
                  </h1>
                </div>

                {formik.status ? (
                  <div className='alert alert-danger mx-5'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  ''
                )}

                <div className='input-group'>
                  <label className='form-label fs-6 fw-bolder'>
                    {intl.formatMessage({id: 'GENERAL.NEW_PASSWORD'})}
                  </label>

                  <div className='position-relative'>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      autoComplete='off'
                      {...formik.getFieldProps('newPassword')}
                      className={clsx('form-control form-control-lg form-control-solid')}
                      placeholder={intl.formatMessage({id: 'GENERAL.NEW_PASSWORD'})}
                    />

                    <button
                      className='btn position-absolute top-0 end-0'
                      type='button'
                      id='show-password'
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <i className='bi bi-eye-fill'></i>
                      ) : (
                        <i className='bi bi-eye-slash-fill'></i>
                      )}
                    </button>

                    {formik.touched.newPassword && formik.errors.newPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.newPassword}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='input-group'>
                  <label className='form-label fs-6 fw-bolder'>
                    {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
                  </label>

                  <div className='position-relative'>
                    <input
                      type={newPasswordShow ? 'text' : 'password'}
                      autoComplete='off'
                      {...formik.getFieldProps('confirmNewPassword')}
                      className={clsx('form-control form-control-lg form-control-solid')}
                      placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
                    />

                    <button
                      className='btn position-absolute top-0 end-0'
                      type='button'
                      id='show-password'
                      onClick={handleToggleNewPassword}
                    >
                      {newPasswordShow ? (
                        <i className='bi bi-eye-fill'></i>
                      ) : (
                        <i className='bi bi-eye-slash-fill'></i>
                      )}
                    </button>
                    {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.confirmNewPassword}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'GENERAL.CONTINUE'})}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='d-flex justify-content-center  align-items-center h-100'>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='text-center mb-20'>
              <h1 className='text-dark mb-3 mt-5'>Reset Password</h1>
            </div>

            {formik.status ? (
              <div className='mb-10 alert alert-danger mx-5'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              ''
            )}

            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password</label>
                </div>
              </div>
              <div className='position-relative'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='off'
                  {...formik.getFieldProps('newPassword')}
                  className={clsx('form-control form-control-lg form-control-solid')}
                />

                <button
                  className='btn position-absolute top-0 end-0'
                  type='button'
                  id='show-password'
                  onClick={handleTogglePassword}
                >
                  {showPassword ? (
                    <i className='bi bi-eye-fill'></i>
                  ) : (
                    <i className='bi bi-eye-slash-fill'></i>
                  )}
                </button>
              </div>

              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                    Confirm Password
                  </label>
                </div>
              </div>
              <div className='position-relative'>
                <input
                  type={newPasswordShow ? 'text' : 'password'}
                  autoComplete='off'
                  {...formik.getFieldProps('confirmNewPassword')}
                  className={clsx('form-control form-control-lg form-control-solid')}
                />

                <button
                  className='btn position-absolute top-0 end-0'
                  type='button'
                  id='show-password'
                  onClick={handleToggleNewPassword}
                >
                  {newPasswordShow ? (
                    <i className='bi bi-eye-fill'></i>
                  ) : (
                    <i className='bi bi-eye-slash-fill'></i>
                  )}
                </button>
              </div>

              {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirmNewPassword}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div> */}
    </>
  )
}

export default ResetPasswordForm
