import LandingHeader from './LandingHeader'
import LandingFooter from './LandingFooter'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

const PageNotfound = () => {
  const intl = useIntl()
  return (
    <>
      <LandingHeader />
      <section className='page_not_found section-padding'>
        <div className='landing_container'>
          <div className='page_not_found_wrapper'>
            <div className='row align-items-center'>
              <div className='col-5'>
                <div className='pnf_left'>
                  <h2>{intl.formatMessage({id: 'GENERAL.NOTFOUND_NUMBER'})}</h2>
                </div>
              </div>
              <div className='col-7'>
                <div className='pnf_right'>
                  <h3>{intl.formatMessage({id: 'GENERAL.PAGE.NOTFOUND'})}</h3>
                  <p>{intl.formatMessage({id: 'GENERAL.STAY_OR_BEGINNING'})}</p>
                  <Link to='/' className='pnf_button'>
                    {intl.formatMessage({id: 'GENERAL.BACK_TO_HOME'})}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LandingFooter />
    </>
  )
}

export default PageNotfound
