import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialAppointment, Appointment} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {
  createAppointment,
  getDoctorData1,
  getDoctorDataAva,
  getPatientsforAppointment,
  updateAppointment,
} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import Select from 'react-select'
import moment from 'moment'
import {getPatientById} from '../../../Patient-management/PatientList/core/_requests'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  startOfDay,
  isSameWeek,
} from 'date-fns'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: Appointment
  showModal: (value: boolean) => void
  fetchAppointments: (value?: object) => void
  // tabSelection: any
}

function mapDataToNodes(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({value: item?.full_name, label: item?.full_name, id: item?.id})
  })

  return parent
}

function mapDataToNodesDoctor(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({
      value: item?.doctor?.full_name,
      label: item?.doctor?.full_name,
      id: item?.doctor?.id,
    })
  })

  return parent
}

const LaterAppointment: FC<Props> = ({user, isUserLoading, showModal, fetchAppointments}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [newPatient, setNewPatient] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null)
  const [patientData, setPatientData] = useState(null)
  const [dob, setDob] = useState<Date | null>(null)
  const [doctorData, setDoctorData] = useState([])
  const [selectedData, setSelectedData] = useState<any>(null)
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth))
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [timeSlot, setTimeSlot] = useState<any>(null)
  const [selectedSlots, setSelectedSlots] = useState<string[]>([])
  const currentDate = moment().format('YYYY-MM-DD')
  const {loginData} = useContext(loginContext)

  const {tabSelection, setTabSelection, selectedHospitalId} = useContext(loginContext)

  const [userForEdit] = useState<Appointment>({
    ...user,
    patient_id: user.patient_id || initialAppointment.patient_id,
    date: user.date || initialAppointment.date,
    slot: user.slot || initialAppointment.slot,
    description: user.description || initialAppointment.description,
    doctor_id: user.doctor_id || initialAppointment.doctor_id,
    full_name: user?.patient?.full_name || initialAppointment.patient?.full_name,
    gender: user?.patient?.gender || initialAppointment.patient?.gender,
    contact_number: user?.patient?.contact_number || initialAppointment.patient?.contact_number,
    alternate_contact_number:
      user?.patient?.alternate_contact_number ||
      initialAppointment.patient?.alternate_contact_number,
    date_of_birth: user?.patient?.date_of_birth || initialAppointment?.patient?.date_of_birth,
    email: user?.patient?.email || initialAppointment?.patient?.email,
    blood_group: user?.patient?.blood_group || initialAppointment?.patient?.blood_group,
    status: user?.patient?.status || initialAppointment?.patient?.status,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
    setTabSelection('NOW')
    showModal(false)
  }

  let editUserSchema
  if (newPatient === false) {
    editUserSchema = Yup.object().shape({
      patient_id: Yup.string().required('Please select Patient'),
      description: Yup.string().required('Description is required'),
    })
  } else {
    editUserSchema = Yup.object().shape({
      full_name: Yup.string()
        .min(3, 'Minimum 3 Characters')
        .max(50, 'Maximum 50 Characters')
        .required('Patient Full Name is required')
        .matches(/^[A-Za-z\s]+$/, 'Number not allowed'),
      email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 Characters')
        .max(50, 'Maximum 50 Characters'),
      contact_number: Yup.string()
        .matches(/^\d{10}$/, 'Contact number must be a valid 10-digit number')
        .required('Contact number is required'),
      alternate_contact_number: Yup.string().matches(
        /^\d{10}$/,
        'Alternate contact number must be a valid 10-digit number'
      ),
      date_of_birth: Yup.string().required('Date Of Birth is required'),
      blood_group: Yup.string()
        .required('Blood Group is required')
        .notOneOf([''], 'Blood Group is required'),
      status: Yup.string().required('Status is required').notOneOf([''], 'Status is required'),
    })
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const date_Data = moment(selectedDate).format('DD-MM-YYYY')
      values['appointment_date'] = date_Data
      if (loginData?.data?.user_type !== 'SUPER_ADMIN') {
        values['doctor_id'] = Number(loginData?.data?.personal_details?.id)
      }

      if (selectedSlots?.length > 0) {
        values['slot'] = selectedSlots
      } else {
        toast.error('Please select atleast one slot before saving')
        return false
      }

      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          // await updateAppointment(values)
        } else {
          delete values['date_of_birth']

          // Find the appropriate doctor appointment id based on current time
          const matchingSlots = timeSlot?.data?.data.filter((slot: any) => {
            const startTime = moment(`${slot.date} ${slot.start_time}`, 'YYYY-MM-DD HH:mm')
            const endTime = moment(`${slot.date} ${slot.end_time}`, 'YYYY-MM-DD HH:mm')

            return selectedSlots.some((selectedTime) => {
              const selectedDateTime = moment(`${slot.date} ${selectedTime}`, 'YYYY-MM-DD HH:mm')
              return selectedDateTime.isBetween(startTime, endTime, null, '[]')
            })
          })
          let doctorAppointmentId
          if (matchingSlots?.length > 0) {
            doctorAppointmentId = matchingSlots[0]?.id
          }

          await createAppointment(
            {
              ...values,
              hospital_id: Number(selectedHospitalId),
              type: tabSelection,
            },
            {doctor_appointment_id: doctorAppointmentId}
          ).then((res: any) => {
            toast.success(res?.data?.message)
            fetchAppointments()
            showModal(false)
          })
        }
      } catch (ex: any) {
        toast.warning(ex.response?.data?.message)
        showModal(false)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleChangeOption = (selectedOption: any) => {
    formik.setFieldValue('patient_id', Number(selectedOption.id))
    setSelectedOption(selectedOption)
  }

  const handleDoctorChange = (selectedDoctor: any) => {
    formik.setFieldValue('doctor_id', Number(selectedDoctor.id))
    setSelectedDoctor(selectedDoctor)
  }

  const fecthPatient = async () => {
    const allPatient: any = await getPatientsforAppointment()

    const activePatientData = allPatient?.data?.filter((item: any) => {
      return item?.status === 'ACTIVE' && item?.hospital_id == selectedHospitalId
    })
    setPatientData(activePatientData)
  }

  const fetchDoctor = async () => {
    const allDoctorData: any = await getDoctorDataAva(selectedHospitalId)

    const uniqueAppointments = Object.values(
      allDoctorData?.data?.data.reduce((acc: any, appointment: any) => {
        if (!acc[appointment.doctor_id]) {
          acc[appointment.doctor_id] = appointment
        }
        return acc
      }, {})
    )

    const activeDoctorData: any = uniqueAppointments?.filter((item: any) => {
      return item?.hospital_id == selectedHospitalId
    })
    if (allDoctorData) {
      setDoctorData(activeDoctorData)
    }
  }

  const options: any = mapDataToNodes(patientData)
  const optionsDoctor: any = mapDataToNodesDoctor(doctorData)

  useEffect(() => {
    fetchDoctor()
  }, [])

  useEffect(() => {
    fecthPatient()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (selectedOption) {
        const allDoctorData: any = await getPatientById(selectedOption?.id)
        setSelectedData(allDoctorData)
      }
    })()
  }, [selectedOption])

  const past_date_disable = new Date(moment().format('YYYY-MM-DD'))
  const future_date_disable = new Date(moment().format('YYYY-MM-DD'))

  // const changeWeekHandle = (btnType: any) => {
  //   if (btnType === 'prev') {
  //     setCurrentMonth(subWeeks(currentMonth, 1))
  //     setCurrentWeek(getWeek(subWeeks(currentMonth, 1)))
  //   }
  //   if (btnType === 'next') {
  //     setCurrentMonth(addWeeks(currentMonth, 1))
  //     setCurrentWeek(getWeek(addWeeks(currentMonth, 1)))
  //   }
  // }
  const changeWeekHandle = (btnType: 'prev' | 'next') => {
    setSelectedSlots([])
    setCurrentMonth((prevMonth) => {
      const today = startOfDay(new Date())
      const currentSelectedDate = selectedDate ?? today

      let newSelectedDate: Date = today

      if (btnType === 'prev') {
        const prevWeek = subWeeks(currentSelectedDate, 1)
        newSelectedDate = isSameWeek(prevWeek, prevMonth)
          ? today
          : startOfWeek(prevWeek, {weekStartsOn: 1})
      } else if (btnType === 'next') {
        const nextWeek = addWeeks(currentSelectedDate, 1)
        newSelectedDate = isSameWeek(nextWeek, prevMonth)
          ? today
          : startOfWeek(nextWeek, {weekStartsOn: 1})
      }

      setCurrentWeek(getWeek(newSelectedDate, {weekStartsOn: 1}))
      setSelectedDate(newSelectedDate)

      return prevMonth
    })
  }

  const onDateClickHandle = (day: any, dayStr: any) => {
    setSelectedSlots([])
    setSelectedDate(day)
  }

  const renderHeader = () => {
    const dateFormat = 'MMM yyyy'
    const currentWeek = getWeek(selectedDate, {weekStartsOn: 1})

    return (
      <div className='row flex-middle mt-5'>
        {/* <div className='col col-center'>
          <span>{format(currentMonth, dateFormat)}</span>
        </div> */}
        <div className='col-4 col-start'>
          <div className='icon' onClick={() => changeWeekHandle('prev')}>
            <i className='fa-solid fa-circle-arrow-left fs-2'></i>
          </div>
        </div>
        <div className='col-4 col-center'>
          <span>Week {currentWeek} &nbsp;</span>
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className='col-4 col-end' onClick={() => changeWeekHandle('next')}>
          <div className='icon'>
            <i className='fa-solid fa-circle-arrow-right fs-2'></i>
          </div>
        </div>
      </div>
    )
  }

  const renderDays = () => {
    const dateFormat = 'EEE'
    const days = []
    let startDate = startOfWeek(currentMonth, {weekStartsOn: 1})
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className='col col-center' key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      )
    }
    return <div className='days row'>{days}</div>
  }

  const renderCells = () => {
    const startDate = startOfWeek(selectedDate, {weekStartsOn: 1})
    const endDate = lastDayOfWeek(selectedDate, {weekStartsOn: 1})
    // const startDate = startOfWeek(currentMonth, {weekStartsOn: 1})
    // const endDate = lastDayOfWeek(currentMonth, {weekStartsOn: 1})
    const dateFormat = 'd'
    const rows = []
    let days = []
    let day = startDate
    let formattedDate = ''
    const currentDate = new Date()

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat)
        const cloneDay = day
        days.push(
          <div
            className={`col cell ${
              isSameDay(day, currentDate) || day > currentDate ? '' : 'disabled'
            } ${isSameDay(day, selectedDate) ? 'selected' : ''}`}
            onClick={() => {
              if (day > currentDate) {
                const dayStr = format(cloneDay, 'ccc dd MMM yy')
                onDateClickHandle(cloneDay, dayStr)
                setSelectedDate(cloneDay)
              }
            }}
          >
            <span className='number'>{formattedDate}</span>
            <span className='bg'>{formattedDate}</span>
          </div>
        )
        day = addDays(day, 1)
      }

      rows.push(<div className='row'>{days}</div>)
      days = []
    }

    return <div className='body'>{rows}</div>
  }

  const getPatientavailabilityData = async (doctorId: any) => {
    if (doctorId) {
      const day = moment(selectedDate).format('dddd').toUpperCase()
      const date = moment(selectedDate).format('DD-MM-YYYY')

      const doctorSlotsData = await getDoctorData1(doctorId, date)
      setTimeSlot(doctorSlotsData)
    }
  }

  useEffect(() => {
    if (loginData?.data?.user_type === 'SUPER_ADMIN' || loginData?.data?.user_type === 'STAFF') {
      getPatientavailabilityData(selectedDoctor?.id)
    } else if (loginData?.data?.user_type === 'DOCTOR') {
      getPatientavailabilityData(loginData?.data?.personal_details?.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loginData?.data?.user_type,
    loginData?.data?.personal_details?.id,
    selectedDoctor,
    selectedDate,
  ])

  const now = new Date()
  const start: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
  const end: any = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0)

  // Calculate the total number of 1-hour intervals between start and end times
  const totalIntervals = Math.floor((end - start) / (60 * 60 * 1000))

  // Generate an array of timestamps with 1-hour intervals
  const timestamps: any = []
  for (let i = 0; i < totalIntervals; i++) {
    const timestamp = new Date(start.getTime() + i * 60 * 60 * 1000)
    timestamps.push(timestamp)
  }

  // Group timestamps by hour
  const timestampsByHour: any = {}
  timestamps.forEach((timestamp: any) => {
    const hour = timestamp.getHours()
    if (!timestampsByHour[hour]) {
      timestampsByHour[hour] = []
    }
    timestampsByHour[hour].push(timestamp)
  })

  let test: any = []

  timestamps?.map((timestamps: any) => {
    test.push({
      time: moment(timestamps).format('HH:mm'),
    })
  })

  let dayForCheck: any
  dayForCheck = moment(selectedDate).format('YYYY-MM-DD').toUpperCase()

  const timestamps1 = test
    .filter((timeObj: any) => {
      const time = timeObj.time
      return timeSlot?.data?.data?.some((timeSlot: any) => {
        const startHour = timeSlot.start_time.split(':')[0]
        const startMinute = timeSlot.start_time.split(':')[1]
        return (
          (startHour <= time && timeSlot.end_time > time && timeSlot.date === dayForCheck) ||
          (startHour < time &&
            startMinute >= '30' &&
            timeSlot.end_time > time &&
            timeSlot.date === dayForCheck)
        )
      })
    })
    .map((timeObj: any) => timeObj.time)

  function generateSlotForFifteenMin(selectedDate: any) {
    const slotForFifteenmin = []

    for (let i = 0; i < timestamps1.length; i++) {
      const hourSlots = []
      const [hour, minute] = timestamps1[i].split(':')
      const startTime = moment(selectedDate).hour(hour).minute(minute).second(0)
      const endTime = moment(startTime).add(1, 'hour')

      while (startTime < endTime) {
        const slotStartTime = startTime.format('HH:mm')
        startTime.add(15, 'minutes')
        let slotEndTime = startTime.format('HH:mm')

        if (slotEndTime.slice(-2) === '30') {
          slotEndTime = moment(slotEndTime, 'HH:mm').subtract(15, 'minutes').format('HH:mm')
        } else if (slotEndTime.slice(-2) === '00') {
          slotEndTime = moment(slotEndTime, 'HH:mm').subtract(1, 'minute').format('HH:mm')
        }

        if (checkTimeSlot(selectedDate, slotStartTime, slotEndTime)) {
          hourSlots.push(slotStartTime)
        }
      }

      slotForFifteenmin.push(hourSlots)
    }

    return slotForFifteenmin
  }

  function checkTimeSlot(selectedDate: any, slotStartTime: any, slotEndTime: any) {
    const date = moment(selectedDate, 'YYYY-MM-DD')
    for (const slot of timeSlot?.data?.data) {
      const slotDate = moment(slot.date, 'YYYY-MM-DD')
      const start = moment(slot.start_time, 'HH:mm')
      const end = moment(slot.end_time, 'HH:mm')
      const slotStart = moment(slotStartTime, 'HH:mm')
      const slotEnd = moment(slotEndTime, 'HH:mm')

      if (
        slotStart.isSameOrAfter(start) &&
        slotEnd.isSameOrBefore(end) &&
        slotDate.isSame(date, 'day')
      ) {
        return true
      }
    }

    return false
  }
  const date = moment(selectedDate).format('YYYY-MM-DD')

  // Example usage
  const selectedDate1 = date
  const slotForFifteenmin = generateSlotForFifteenMin(selectedDate1)

  function timeToMinutes(timeStr: any) {
    const [hours, minutes] = timeStr.split(':').map(Number)
    return hours * 60 + minutes
  }
  const shift: any = []
  let currentShiftStart = null
  let currentShiftEnd = null

  for (const timeRange of slotForFifteenmin) {
    if (timeRange.length === 0) continue

    const currentStartTime = timeRange[0]
    const currentEndTime = timeRange[timeRange.length - 1]

    if (currentShiftStart === null) {
      currentShiftStart = currentStartTime
      currentShiftEnd = currentEndTime
    } else {
      const gapMinutes = timeToMinutes(currentStartTime) - timeToMinutes(currentShiftEnd)
      if (gapMinutes > 15) {
        shift.push([currentShiftStart, currentShiftEnd])
        currentShiftStart = currentStartTime
      }
      currentShiftEnd = currentEndTime
    }
  }

  if (currentShiftStart !== null && currentShiftEnd !== null) {
    shift.push([currentShiftStart, currentShiftEnd])
  }

  // const handleSlotClick = (slot: string) => {
  //   if (selectedSlots.includes(slot)) {
  //     setSelectedSlots(selectedSlots.filter((selectedSlot) => selectedSlot !== slot))
  //   } else {
  //     setSelectedSlots([...selectedSlots, slot])
  //   }
  // }

  const handleSlotClick = (slot: any) => {
    const isSelected = selectedSlots?.includes(slot)
    let updatedSelectedSlots = []
    let selectedShift: any = null
    let selectedSlotShift = null
    let isDifferentShiftSelected = false
    let inBetweenBooked

    const sameDateBookedSlots = timeSlot?.data?.slots.map((s: any) => s.slot).sort()

    if (isSelected) {
      updatedSelectedSlots = selectedSlots?.filter((selectedSlot) => selectedSlot < slot).sort()
    } else if (selectedSlots?.length === 0) {
      updatedSelectedSlots = [slot].sort()
    } else {
      const sortedSlots = selectedSlots?.concat(slot).sort()
      const reverseSortedSlot = selectedSlots?.concat(slot)
      const clickedSlotIndex = sortedSlots?.findIndex((selectedSlot) => selectedSlot === slot)

      if (clickedSlotIndex > 0 && clickedSlotIndex < sortedSlots?.length - 1) {
        updatedSelectedSlots = [slot].sort()
      } else {
        const startSlot = sortedSlots?.[0]

        const endSlot = sortedSlots[sortedSlots?.length - 1]

        const startSlotIndex = slotForFifteenmin?.findIndex((timeSlots) =>
          timeSlots?.includes(startSlot)
        )

        const endSlotIndex = slotForFifteenmin?.findIndex((timeSlots) =>
          timeSlots?.includes(endSlot)
        )

        // Find the selected shift for the clicked slot
        selectedShift = shift.find(
          (shiftRange: any) =>
            timeToMinutes(shiftRange[0]) <= timeToMinutes(slot) &&
            timeToMinutes(shiftRange[1]) >= timeToMinutes(slot)
        )

        // Find the selected shift for each currently selected slot
        const selectedSlotShifts = selectedSlots.map((selectedSlot) =>
          shift.find(
            (shiftRange: any) =>
              timeToMinutes(shiftRange[0]) <= timeToMinutes(selectedSlot) &&
              timeToMinutes(shiftRange[1]) >= timeToMinutes(selectedSlot)
          )
        )

        // Check if any selected slots belong to a different shift
        isDifferentShiftSelected = selectedSlotShifts?.some(
          (slotShift) => slotShift !== selectedShift
        )

        inBetweenBooked = sameDateBookedSlots.filter(
          (bookedSlot: any) => bookedSlot > startSlot && bookedSlot < endSlot
        )

        const lastInBetweenBooked = inBetweenBooked[inBetweenBooked.length - 1]
        const firstInBetweenBooked = inBetweenBooked[0]

        // If different shift is selected, remove slots from the first shift
        if (isDifferentShiftSelected) {
          updatedSelectedSlots = selectedSlots?.filter(
            (selectedSlot) =>
              selectedSlotShifts[selectedSlots.indexOf(selectedSlot)] === selectedShift
          )
        } else {
          const slotsInRange = slotForFifteenmin
            .slice(startSlotIndex, endSlotIndex + 1)
            .flatMap((timeSlots, index) => {
              if (index === 0 && startSlotIndex < endSlotIndex) {
                return timeSlots.filter((timeSlot) => timeSlot >= startSlot && timeSlot < endSlot)
              } else if (index === endSlotIndex - startSlotIndex) {
                if (startSlotIndex === endSlotIndex) {
                  return timeSlots?.filter(
                    (timeSlot) => timeSlot > startSlot && timeSlot <= endSlot
                  )
                } else {
                  return timeSlots?.filter((timeSlot) => timeSlot <= endSlot)
                }
              } else {
                return timeSlots
              }
            })
            .filter((timeSlot, index, arr) => {
              return (
                timeSlot !== startSlot &&
                timeSlot !== endSlot &&
                index === arr?.findIndex((slot) => slot === timeSlot)
              )
            })

          if (firstInBetweenBooked || lastInBetweenBooked) {
            if (reverseSortedSlot[0] > reverseSortedSlot[reverseSortedSlot?.length - 1]) {
              updatedSelectedSlots = [startSlot, ...slotsInRange, endSlot]
                .sort()
                .filter((timeSlot) => timeSlot < firstInBetweenBooked)
            } else {
              updatedSelectedSlots = [startSlot, ...slotsInRange, endSlot]
                .sort()
                .filter((timeSlot) => timeSlot > lastInBetweenBooked)
            }
          } else {
            updatedSelectedSlots = [startSlot, ...slotsInRange, endSlot]
          }
        }
      }
    }

    setSelectedSlots(updatedSelectedSlots)
  }

  let bloodGroupText = ''
  if (selectedData?.blood_group === 'A_POSITIVE') {
    bloodGroupText = 'A+'
  } else if (selectedData?.blood_group === 'B_POSITIVE') {
    bloodGroupText = 'B+'
  } else if (selectedData?.blood_group === 'O_POSITIVE') {
    bloodGroupText = 'O+'
  } else if (selectedData?.blood_group === 'AB_POSITIVE') {
    bloodGroupText = 'AB+'
  } else if (selectedData?.blood_group === 'A_NEGATIVE') {
    bloodGroupText = 'A-'
  } else if (selectedData?.blood_group === 'B_NEGATIVE') {
    bloodGroupText = 'B-'
  } else if (selectedData?.blood_group === 'O_NEGATIVE') {
    bloodGroupText = 'O-'
  } else if (selectedData?.blood_group === 'AB_NEGATIVE') {
    bloodGroupText = 'AB-'
  }

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-6 appointment-form'>
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div
              className='d-flex flex-column me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {(loginData?.data?.user_type === 'SUPER_ADMIN' ||
                loginData?.data?.user_type === 'STAFF') && (
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>
                    {intl.formatMessage({id: 'PATIENT_APPOINTMENT.DOCTORS'})}
                  </label>
                  <Select
                    value={selectedDoctor}
                    onChange={handleDoctorChange}
                    options={optionsDoctor}
                    styles={customStyles}
                    placeholder={intl.formatMessage({id: 'DOCTOR.APPOINTMENT.SELECT.DOCTOR'})}
                  />
                  {formik.touched.doctor_id && formik.errors.doctor_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.doctor_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GENERAL.PATIENT'})}
                </label>

                <Select
                  // {...formik.getFieldProps('patient_id')}
                  value={selectedOption}
                  onChange={handleChangeOption}
                  // name='patient_id'
                  options={options}
                  styles={customStyles}
                  placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.PATIENT'})}
                />
                {formik.touched.patient_id && formik.errors.patient_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.patient_id}</span>
                    </div>
                  </div>
                )}
              </div>

              {selectedData ? (
                <>
                  <div className='row'>
                    <div className='fv-row mb-7 col-6'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                      </label>
                      <div>{selectedData?.contact_number}</div>
                    </div>

                    <div className='fv-row mb-7 col-6'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.DOB'})}
                      </label>
                      <div>{moment(selectedData?.date_of_birth).format('DD-MM-YYYY')}</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='fv-row mb-7 col-6'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.EMAIL'})}
                      </label>
                      <div>{selectedData?.email === null ? '' : selectedData?.email}</div>
                    </div>

                    <div className='fv-row mb-7 col-6'>
                      <label className='fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'GENERAL.BLOOD.GROUP'})}
                      </label>
                      <div>{bloodGroupText}</div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}

              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'PATIENT_APPOINTMENT.APPOINTMENT.DESCRIPTION'})}
                </label>

                <textarea
                  {...formik.getFieldProps('descriptions')}
                  className='form-control'
                  name='description'
                  id='exampleFormControlTextarea1'
                  disabled={formik.isSubmitting || isUserLoading}
                  rows={3}
                  placeholder={intl.formatMessage({
                    id: 'PATIENT_APPOINTMENT.APPOINTMENT.DESCRIPTION',
                  })}
                ></textarea>

                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.description}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={formik.isSubmitting || isUserLoading}
              >
                {intl.formatMessage({id: 'GENERAL.CLOSE'})}
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={
                  isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
                {(formik.isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className='col-md-6'>
          <div className='calendar m-5'>
            {renderHeader()}
            {renderDays()}
            {renderCells()}
          </div>

          <ul className='nav nav-tabs nav-line-tabs border-bottom border-secondary m-5 fs-6'>
            <li className='nav-item'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_APPOINTMENT.SLOT.TIME'})}
              </label>
            </li>
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              {timestamps1.length > 0 ? (
                <div className='timeline'>
                  {timestamps1.map((timestamp: any, index: number) => (
                    <div className='timeline-item' key={index}>
                      <div className='timeline-label'>{timestamp}</div>
                      <div className='timeline-bar'></div>
                      <div className='d-flex gap-4'>
                        {slotForFifteenmin[index].map((slot: string, index2: number) => {
                          const slotDateTime = moment(`${currentDate} ${slot}`, 'YYYY-MM-DD HH:mm')

                          const isBooked = timeSlot?.data?.slots?.some((slotData: any) => {
                            const slotDateTime = slotData.slot
                            return slot === slotDateTime
                          })

                          const isSlotDisabled =
                            slotDateTime.isBefore(moment()) &&
                            moment(selectedDate).isSame(moment(), 'day')

                          const slotClassName = isBooked
                            ? 'border bg-gray-500 rounded-3 btn px-10 py-3'
                            : selectedSlots.includes(slot)
                            ? 'border bg-primary rounded-3 btn btn-active-primary px-10 py-3'
                            : isSlotDisabled
                            ? 'border bg-gray rounded-3 btn px-10 py-3'
                            : 'border border-secondary rounded-3 btn btn-active-primary px-10 py-3'

                          // const slotClassName = isBooked
                          //   ? 'border bg-gray-400 rounded-3 btn px-10 py-3'
                          //   : selectedSlots.includes(slot)
                          //   ? 'border bg-primary rounded-3 btn btn-active-primary px-10 py-3'
                          //   : 'border border-secondary rounded-3 btn btn-active-primary px-10 py-3'
                          return (
                            <div
                              key={index2}
                              className={`${slotClassName} ${
                                isBooked || isSlotDisabled ? 'text-secondary' : ''
                              }`}
                              style={{
                                pointerEvents: isBooked || isSlotDisabled ? 'none' : 'auto',
                              }}
                              onClick={() => handleSlotClick(slot)}
                            >
                              {slot}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className='timeline'>
                  <label className='fw-bold fs-6 m-auto'>
                    {intl.formatMessage({id: 'PATIENT_APPOINTMENT.TIME.SLOT.NOT.AVAILABLE'})}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {LaterAppointment}
