import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {MedicalNotes, MedicalNotesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const MEDICAL_NOTES_URL = `${API_URL}/patient-medical-notes`

const getMedicalNotes = (
  query: string,
  id: number,
  hosId: number,
  appointId:any
): Promise<MedicalNotesQueryResponse> => {
  return (
    axios
      // .get(`${MEDICAL_NOTES_URL}?patient_id=${id}&${query}`)
      .get(
        `${MEDICAL_NOTES_URL}?${
          query
            ? `${query}&sort=date&order=DESC&limit=10&patient_id=${id}&hospital_id=${hosId}&appointment_id=${appointId}`
            : `sort=date&order=DESC&limit=10&hospital_id=${hosId}&patient_id=${id}`
        }`
      )
      .then((d: AxiosResponse<MedicalNotesQueryResponse>) => d.data)
  )
}

const getMedicalNotesById = (id: ID): Promise<MedicalNotes | undefined> => {
  return axios
    .get(`${MEDICAL_NOTES_URL}/${id}`)
    .then((response: AxiosResponse<Response<MedicalNotes>>) => response.data)
    .then((response: Response<MedicalNotes>) => response.data)
}

const createMedicalNotes = (user: any): Promise<MedicalNotes | undefined> => {
  return axios
    .post(MEDICAL_NOTES_URL, user)
}

const updateMedicalNotes = (user: MedicalNotes): Promise<MedicalNotes | undefined> => {
  return axios
    .patch(`${MEDICAL_NOTES_URL}/${user.id}`, user)
}

const deleteMedicalNote = (id: any): Promise<void> => {
  return axios.delete(`${MEDICAL_NOTES_URL}`, {data: {ids: [id]}}).then(() => {})
}

export {
  getMedicalNotes,
  getMedicalNotesById,
  createMedicalNotes,
  updateMedicalNotes,
  deleteMedicalNote,
}
