import {useEffect, useRef, useState} from 'react'
import {getDoctors, getHospitals, getSpeciality} from './core/_request'
import Select from 'react-select'
import {useIntl} from 'react-intl'
import DoctorDetailsModal from './modals/DoctorDetailsModal'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import PatientRegistrationModal from './modals/PatientRegistrationModal'
import PatientVerifyOtpModal from './modals/PatientVerifyOtpModal'
import PatientLoginModal from './modals/PatientLoginModal'
import PatientForgotPasswordModal from './modals/PatientForgotPasswordModal'
import PatientChangePasswordModal from './modals/PatientChangePasswordModal'
import {KTSVG, toAbsoluteUrl} from '../../../_jmh/helpers'
type RegistrationRef = {
  resetForm: () => void
}

const LandingDoctors = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('all')
  const [hospitalData, setHospitalData] = useState<any>(null)
  const [patientSelectedHospital, setPatientSelectedHospital] = useState<any>(null)
  const [specialityData, setSpecialityData] = useState<any>(null)
  const [doctorData, setDoctorData] = useState<any>(null)
  const [showDoctorDetailsModal, setShowDoctorDetailsModal] = useState(false)
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null)
  const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false)
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const [showPatientLoginModal, setShowPatientLoginModal] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const registrationFormRef = useRef<RegistrationRef | null>(null)
  const [emailValue, setEmailValue] = useState('')
  const [doctorId, setDoctorId] = useState<any>(null)
  const [patientSelectedHospitalId, setPatientSelectedHospitalId] = useState<any>(null)

  const openDoctorDetailsModal = (doctor: any) => {
    setSelectedDoctor(doctor)
    setShowDoctorDetailsModal(true)
  }

  const closeDoctorDetailsModal = () => {
    setSelectedDoctor(null)
    setShowDoctorDetailsModal(false)
  }

  const handleHospitalChange = (selectedOption: any) => {
    setPatientSelectedHospitalId(selectedOption?.id)
    setPatientSelectedHospital(selectedOption)
    setActiveTab('all') // Set activeTab to 'all' when the hospital changes
  }

  useEffect(() => {
    fetchHospitals()
    fetchDoctors()
  }, [])

  useEffect(() => {
    // Set patientSelectedHospital to the first value of hospitalData on load
    if (hospitalData?.length > 0 && !patientSelectedHospital) {
      setPatientSelectedHospital(hospitalData[0])
      setPatientSelectedHospitalId(hospitalData[0]?.id)
    }
  }, [hospitalData, patientSelectedHospital])

  useEffect(() => {
    // Fetch specialityData only when patientSelectedHospital has a value
    if (patientSelectedHospital) {
      fetchSpeciality()
    }
  }, [patientSelectedHospital])

  const fetchHospitals = () => {
    getHospitals().then((res) => {
      setHospitalData(
        res?.data?.data?.map((item: any) => {
          return {value: item?.id, label: item?.name, id: item?.id}
        })
      )
    })
  }

  const fetchSpeciality = () => {
    getSpeciality().then((res) => {
      const filteredSpecialities = res?.data?.data?.filter(
        (item: any) => item?.hospital_id?.id == patientSelectedHospital?.value
      )
      setSpecialityData(filteredSpecialities)
    })
  }

  const fetchDoctors = () => {
    getDoctors().then((res) => {
      setDoctorData(res?.data?.data)
    })
  }

  // const getThemeFromLocalStorage = () => {
  //   return localStorage.getItem('kt_theme_mode_menu')
  // }

  // const theme = getThemeFromLocalStorage()

  // const customStyles = {
  //   option: (provided: any, state: any) => ({
  //     ...provided,
  //     backgroundColor: theme === 'dark' ? '#424242' : state.isSelected ? '#009ef7' : '#fff',
  //     color: theme === 'dark' ? '#fff' : state.isSelected ? '#000' : '#000',
  //   }),
  // }

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  const uniqueSpecialities = [
    {name: 'all'},
    ...Array.from(new Set(specialityData?.map((item: any) => item)) || []),
  ]
  const [specialityID, setSpecialityID] = useState(null)
  const handleTabChange = (tabId: any) => {
    setSpecialityID(tabId.id)
    setActiveTab(tabId.name)
  }

  const filteredDoctors =
    activeTab === 'all'
      ? doctorData?.filter(
          (doctor: any) => doctor?.user?.hospital?.id === patientSelectedHospital?.value
        )
      : doctorData?.filter(
          (doctor: any) =>
            doctor?.speciality_ids?.includes(specialityID) &&
            doctor?.user?.hospital?.id === patientSelectedHospital?.value
        )

  const handleBookNowClick = (userId: any) => {
    setDoctorId(userId)
    if (currentUser) {
      navigate('/patient-booking', {
        state: {doctorId: userId, patientSelectedHospitalId: patientSelectedHospitalId},
      })
    } else {
      setShowPatientLoginModal(true)
    }
  }

  const handleRegistrationSuccess = (email: string) => {
    setEmailValue(email)
    // Close the registration modal
    setShowRegistrationModal(false)
    // Open the VerifyOtp modal
    setShowVerifyOtpModal(true)
  }

  const closeVerifyOtpModal = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.resetForm()
    }
    // Close the VerifyOtp modal
    setShowVerifyOtpModal(false)
  }

  const closeRegistrationModal = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.resetForm()
    }
    // Close the registration modal
    setShowRegistrationModal(false)
  }

  const handleLoginLinkClick = () => {
    setShowRegistrationModal(false) // Close the registration modal
    setShowPatientLoginModal(true) // Open the login modal
  }

  const handleRegisterLinkClick = () => {
    setShowPatientLoginModal(false) // Close the login modal
    setShowRegistrationModal(true) // Open the registration modal
  }

  const handleForgotPasswordLinkClick = () => {
    setShowPatientLoginModal(false) // Close the login modal
    setShowForgotPasswordModal(true) // Open the forgot password modal
  }

  const handlePasswordChangeModal = () => {
    setShowPatientLoginModal(false) // Close the login modal
    setShowChangePasswordModal(true) // Open the change password modal
  }

  return (
    <section className='landing_doctors section-padding' id='ask-doctors'>
      <div className='landing_container'>
        <div className='landing_doctor_container'>
          <div className='landing_doctor_back'></div>
          <div className='row align-items-center'>
            <div className='col-3'>
              <div className='landing_doctor_left'>
                <h2>{intl.formatMessage({id: 'LANDING_PAGE.DOCTORS_TITLE'})}</h2>
                <div className='fv-row mb-7 col-12 ps-0'>
                  <Select
                    value={patientSelectedHospital}
                    onChange={handleHospitalChange}
                    options={hospitalData}
                    styles={customStyles}
                    placeholder={intl.formatMessage({id: 'GENERAL.HOSPITAL.SELECT_HOSPITAL'})}
                  />
                </div>
                <ul className='nav nav-pills flex-column'>
                  {uniqueSpecialities.map((item: any, index: number) => (
                    <li className='nav-item' key={index}>
                      <button
                        className={`nav-link ${activeTab === item.name ? 'active' : ''}`}
                        onClick={() => handleTabChange(item)}
                      >
                        {item.name === 'all'
                          ? intl.formatMessage({id: 'ECOMMERCE.COMMON.ALL'})
                          : item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-9'>
              <div className='row'>
                {filteredDoctors?.length > 0 ? (
                  filteredDoctors.map((doctor: any) => (
                    <div className='col-8 offset-md-3' key={doctor.id}>
                      <div className='landing_doctor_item'>
                        <div className='profile'>
                          <div className='person d-flex align-items-center'>
                            <div className='avatar-img avatar'>
                              {/* <img src={doctor.image} alt={doctor.full_name} /> */}
                              <img
                                src={toAbsoluteUrl('/media/landing_asset/profile.jpg')}
                                alt={doctor.full_name}
                              />
                            </div>
                            <div className='name'>
                              <h4>{doctor?.full_name}</h4>
                              <p>{doctor?.speciality?.name}</p>
                            </div>
                          </div>
                          <hr className='divider' />
                          <div className='landing_doctor_item_action d-flex justify-content-between align-items-center'>
                            <div className='property'>
                              <span onClick={() => openDoctorDetailsModal(doctor)}>
                                {intl.formatMessage({id: 'GENERAL.SEE.DETAILS'})}
                              </span>
                            </div>
                            <button
                              className='chat_btn'
                              onClick={() => handleBookNowClick(doctor?.id)}
                            >
                              {intl.formatMessage({id: 'GENERAL.BOOKNOW'})}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='no_data shadow'>
                    <img
                      src={toAbsoluteUrl('/media/landing_asset/doctor_1152593.png')}
                      alt='Logo'
                    />
                    <h2>{intl.formatMessage({id: 'LANDING_PAGE.DOCTOR.NOT_AVAILABLE'})}</h2>
                  </div>
                )}
                {showDoctorDetailsModal && (
                  <DoctorDetailsModal
                    showDoctorDetailsModal={showDoctorDetailsModal}
                    selectedDoctor={selectedDoctor}
                    closeDoctorDetailsModal={closeDoctorDetailsModal}
                    specialityData={specialityData}
                  />
                )}

                {showRegistrationModal && (
                  <PatientRegistrationModal
                    show={showRegistrationModal}
                    onClose={closeRegistrationModal}
                    onSuccess={handleRegistrationSuccess}
                    onLoginClick={handleLoginLinkClick}
                    registrationFormRef={registrationFormRef}
                  />
                )}

                {showVerifyOtpModal && (
                  <PatientVerifyOtpModal
                    show={showVerifyOtpModal}
                    onClose={closeVerifyOtpModal}
                    emailValue={emailValue}
                    doctorId={doctorId}
                    patientSelectedHospitalId={patientSelectedHospitalId}
                  />
                )}

                {showPatientLoginModal && (
                  <PatientLoginModal
                    show={showPatientLoginModal}
                    onClose={() => setShowPatientLoginModal(false)}
                    onRegisterClick={handleRegisterLinkClick}
                    onForgotPasswordClick={handleForgotPasswordLinkClick}
                    onPasswordChangeClick={handlePasswordChangeModal}
                    doctorId={doctorId}
                    patientSelectedHospitalId={patientSelectedHospitalId}
                  />
                )}

                {showChangePasswordModal && (
                  <PatientChangePasswordModal
                    show={showChangePasswordModal}
                    onClose={() => setShowChangePasswordModal(false)}
                    onSubmit={() => {
                      setShowChangePasswordModal(false)
                    }}
                  />
                )}

                {showForgotPasswordModal && (
                  <PatientForgotPasswordModal
                    show={showForgotPasswordModal}
                    onClose={() => setShowForgotPasswordModal(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingDoctors
