// @ts-nocheck
import {Column} from 'react-table'
import {AssetCategoryInfoCell} from './AssetCategoryInfoCell'
import {AssetCategoryActionsCell} from './AssetCategoryActionsCell'
import {AssetCategoryCustomHeader} from './AssetCategoryCustomHeader'
import {AssetCategory} from '../../core/_models'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<AssetCategory>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetCategoryCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ASSET.CATEGORY.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'name',
    Cell: ({...props}) => <AssetCategoryInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'asset-category') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <AssetCategoryCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'asset-category') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? <AssetCategoryActionsCell id={props.data[props.row.index].id} /> : null
    },
  },
]

export {usersColumns}
