import moment from 'moment'
import {FC} from 'react'
import {KTSVG} from '../../../_jmh/helpers'

const ShowAllEvents: FC<any> = ({onClose, selectedDay}) => {
  return (
    <>
      <div
        className='modal fade show d-block modal-lg'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3>
                All Events of{' '}
                {selectedDay && selectedDay.length > 0
                  ? moment(selectedDay?.[0]?.start).format('DD-MM-YYYY')
                  : ''}
              </h3>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={onClose}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y'>
              <div className='row'>
                <div
                  className='d-flex flex-wrap justify-content-between'
                  style={{height: '100%', maxHeight: '400px'}}
                >
                  {selectedDay &&
                    selectedDay.map((event: any, index: any) => (
                      <div className='more-items' key={index}>
                        <div className='more-item-inner'>
                          <p>
                            <strong>{event?.eventTitle} :</strong>
                          </p>
                          <p>
                            <strong>Patient Name:</strong> {event?.patientName}
                          </p>
                          <p>
                            <strong>Doctor Name:</strong> {event?.doctorName}
                          </p>
                          <p>
                            <strong> Time:</strong>
                            {moment(event?.start?.toString()).format('  HH:mm')} to
                            {moment(event?.end?.toString()).format(' HH:mm')}
                          </p>
                          {event?.eventTitle === 'Patient Operation Details' && (
                            <p>
                              <strong>Room Number: </strong> {event?.venue}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ShowAllEvents
