import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {ShiftModel, ShiftQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const Shift_URL = `${API_URL}/Shift`
const GET_Shift_URL = `${API_URL}/Shift`

const getShift = (query: string,id:number): Promise<ShiftQueryResponse> => {
  return axios
    .get(`${GET_Shift_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<ShiftQueryResponse>) => d.data)
}

const getShiftById = (id: ID): Promise<ShiftModel | undefined> => {
  return axios
    .get(`${Shift_URL}/${id}`)
    .then((response: AxiosResponse<Response<ShiftModel>>) => response.data)
    .then((response: Response<ShiftModel>) => response.data)
}

const createShift = (Shift: ShiftModel): Promise<ShiftModel | undefined> => {
  return axios
    .post(Shift_URL, Shift)
}

const updateShift = (Shift: any): Promise<ShiftModel | undefined> => {
  return axios
    .patch(`${Shift_URL}/${Shift.id}`, Shift)
}

const deleteShift = (ShiftId: ID): Promise<void> => {
  return axios.delete(`${Shift_URL}`, {data: { ids: [ShiftId]}})
}

const deleteSelectedShift = (ShiftIds: Array<ID>): Promise<void> => {
  const requests = ShiftIds.map((id) => axios.delete(`${Shift_URL}/${id}`))
  return axios.all(requests).then(() => {})
}



export {getShift, deleteShift, deleteSelectedShift, getShiftById, createShift, updateShift}