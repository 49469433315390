import { ID, Response } from "../../../../../_jmh/helpers"

export type PatientVital = {
  id?: ID
  blood_pressure?: string | null
  height?: number | null
  weight?: number | null
  temprature?:number | null
  Respiratory_rate?:number | null
  pulse?:number | null
  date_time ?: string
  description?: string
  created_at?: string
  updated_at?: string
  hospital_id?: number
  initials?: {
    label: string
    state: string
  }
}

export type UsersQueryResponse = Response<Array<PatientVital>>

export const initialUser: PatientVital = {
  blood_pressure: '' || null,
  height:null,
  weight:null,
  temprature:null,
  Respiratory_rate:null,
  pulse:null,
  date_time: ''
}
