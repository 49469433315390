/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Doctor} from '../../core/_models'

type Props = {
  user: Doctor
}

const DoctorStatusCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800  mb-1'>
          {user?.status === 'ACTIVE' && (
            <span className='badge badge-light-success'>{user?.status}</span>
          )}
          {user?.status === 'INACTIVE' && (
            <span className='badge badge-light-warning'>{user?.status}</span>
          )}
        </span>
      </div>
    </div>
  )
}

export {DoctorStatusCell}
