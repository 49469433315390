import React, {useState} from 'react'
import {KTCardBody} from '../../../../_jmh/helpers'
import {useContext, useEffect, useMemo} from 'react'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {MenuComponent} from '../../../../_jmh/assets/ts/components'
import {useListView} from '../../Doctor/DoctorList/core/ListViewProvider'
import {MedicationListPagination} from '../../medications/medicationList/components/pagination/MedicationListPagination'
import {MedicationListLoading} from '../../medications/medicationList/components/loading/MedicationListLoading'
import {useQueryResponseLoading} from '../../Doctor/DoctorList/core/QueryResponseProvider'
import PastPrescriptionPreview from './PastPrescriptionPreview'
import {getPastVisits} from '../core/_request'
import {DpContext} from '../DpLayoutWrapper'
import {HiOutlineCurrencyRupee} from 'react-icons/hi'
import {IoDocumentTextOutline} from 'react-icons/io5'
import {
  getDocumentsAppointment,
  getInvoiceAppointment,
} from '../../doctor-all-information/Infolist/core/_requests'
import {DocInfoLoading} from '../../doctor-all-information/Infolist/components/loading/DocInfoLoading'

const DpPastVisits = () => {
  const intl = useIntl()
  const isLoading = useQueryResponseLoading()
  const [isDisplay, setIsDisplay] = useState(false)
  const {setItemIdForUpdate} = useListView()
  const {selectedPatientData} = useContext(DpContext)
  const [extractedData, setExtractedData] = useState<any[]>([])
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<number | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')
  const [loadingPdf, setLoadingPdf] = useState(false)

  useEffect(() => {
    if (selectedPatientData?.patient_id) {
      fetchPastvisitsData()
    }
  }, [selectedPatientData?.patient_id])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const fetchPastvisitsData = () => {
    getPastVisits(selectedPatientData?.patient_id).then((res: any) => {
      const visitsData = res?.data?.data

      const extractedData = visitsData.map((appointment: any) => ({
        patient_id: appointment?.patient_id,
        appointment_id: appointment?.id,
        appointment_date: appointment?.appointment_details?.[0]?.appointment_date,
        slot: appointment?.appointment_details?.[0]?.slot,
        created_at: appointment?.created_at,
        status: appointment?.status,
        doc_name: appointment?.appointment_details?.[0]?.doctor?.full_name,
        contact_number: appointment?.appointment_details?.[0]?.doctor?.contact_number,
      }))
      setExtractedData(extractedData)
    })
  }

  const openEditModal = (id: any) => {
    setItemIdForUpdate(id)
    setSelectedAppointmentId(id)
    setIsDisplay(true)
  }

  const colors = ['primary', 'success', 'danger', 'warning', 'info']
  const groupedData = useMemo(() => {
    const grouped: any = {}

    extractedData.forEach((item: any) => {
      const year = moment(item.appointment_date).format('YYYY')
      const month = moment(item.appointment_date).format('MMM')

      if (!grouped[year]) {
        grouped[year] = {}
      }

      if (!grouped[year][month]) {
        grouped[year][month] = []
      }

      grouped[year][month].push(item)
    })

    return grouped
  }, [extractedData])

  const sortedYears = Object.keys(groupedData).sort((a: any, b: any) => b - a)

  useEffect(() => {
    if (isDisplay) {
      document.body.classList.add('preview-open')
      document.querySelector('.presc_preview_wrapper')?.classList.add('active')
    } else {
      document.body.classList.remove('preview-open')
      document.querySelector('.presc_preview_wrapper')?.classList.remove('active')
    }

    return () => {
      document.body.classList.remove('preview-open')
      document.querySelector('.presc_preview_wrapper')?.classList.remove('active')
    }
  }, [isDisplay])

  const handlePreviewClose = () => {
    setIsDisplay(false)
  }

  const getStatusClassName = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return 'badge badge-light-success'
      case 'COMPLETED':
        return 'badge badge-light-primary'
      case 'CANCELLED':
        return 'badge badge-light-danger'
      case 'WAITING':
        return 'badge badge-light-warning'
      default:
        return 'badge badge-light-success'
    }
  }

  const handleviewpdf = async (id: number) => {
    try {
      setLoadingPdf(true) // Show loader
      const response = await getInvoiceAppointment(id)
      const pdfData = new Blob([response.data], {type: 'application/pdf'})
      const url = URL.createObjectURL(pdfData)
      setPdfUrl(url)
      setShowModal(true)
    } catch (error) {
      console.error('Error fetching PDF:', error)
    } finally {
      setLoadingPdf(false) // Hide loader
    }
  }

  const handleviewdocument = async (id: number) => {
    try {
      setLoadingPdf(true) // Show loader
      const response = await getDocumentsAppointment(id)
      const pdfData = new Blob([response.data], {type: 'application/pdf'})
      const url = URL.createObjectURL(pdfData)
      setPdfUrl(url)
      setShowModal(true)
    } catch (error) {
      console.error('Error fetching PDF:', error)
    } finally {
      setLoadingPdf(false) // Hide loader
    }
  }

  const calculateSlotTiming = (appointment: any): string => {
    let smallestSlot = ''
    let largestSlot = ''

    const sortedAppointments = appointment

    if (sortedAppointments) {
      smallestSlot = sortedAppointments?.slot
      if (smallestSlot === null) {
        const now = moment()
        smallestSlot = now.format('HH:mm')
        largestSlot = moment(smallestSlot, 'HH:mm').add(15, 'minutes').format('HH:mm')
      } else {
        largestSlot = moment(smallestSlot, 'HH:mm').add(15, 'minutes').format('HH:mm')
      }
    }

    return `${moment(smallestSlot, 'HH:mm').format('hh:mm A')}`
  }

  return (
    <KTCardBody className='py-4 px-0'>
      <div className='px-1'>
        <div
          className={`row border border-dashed border-gray-300 rounded min-w-800px px-4 py-2 mb-3 bg-light`}
        >
          <div className='col-2'>
            <div className='fs-6 text-muted'>
              {intl.formatMessage({id: 'PAST_VISIT.APPOINTMENT_ID'})}
            </div>
          </div>
          <div className='col-2'>
            <div className='fs-6 text-muted' style={{marginLeft: '15px'}}>
              {intl.formatMessage({id: 'PAST_VISIT.APPOINTMENT_DATE'})}
            </div>
          </div>

          <div className='col-2'>
            <div className='fs-6 text-muted' style={{marginLeft: '60px'}}>
              {intl.formatMessage({id: 'PAST_VISIT.BOOK DATE'})}
            </div>
          </div>
          <div className='col-2'>
            <div className='fs-6 text-muted' style={{marginLeft: '26px'}}>
              {intl.formatMessage({id: 'PAST_VISIT.STATUS'})}
            </div>
          </div>
          <div className='col-2'>
            <div className='fs-6 text-muted' style={{marginLeft: '17px'}}>
              {intl.formatMessage({id: 'PAST_VISIT.DOCTOR_NAME'})}
            </div>
          </div>
          <div className='col-1'>
            <div className='fs-6 text-muted'>{intl.formatMessage({id: 'GENERAL.PRESCRIBE'})}</div>
          </div>
          <div className='col-1'>
            <div className='fs-6 text-muted'>{intl.formatMessage({id: 'PAST_VISIT.ACTION'})}</div>
          </div>
        </div>
      </div>
      <div className='timeline'>
        {sortedYears.length > 0 ? (
          <>
            {sortedYears.map((year, yearIndex) => (
              <div key={`year-${year}`} className='timeline-item'>
                <div
                  className='timeline-line w-20px'
                  style={{marginTop: '18px', left: '-1px'}}
                ></div>
                <div className='timeline-badge'>
                  <i
                    className={`fa fa-genderless fs-1 text-${colors[yearIndex % colors.length]}`}
                  ></i>
                </div>
                <div className='timeline-content'>
                  <div className='mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      <h4>{year}</h4>
                      {Object.keys(groupedData[year])
                        .sort((a, b) => moment(b, 'MMM').diff(moment(a, 'MMM')))
                        .map((month) => (
                          <div key={`month-${year}-${month}`} className='mb-4'>
                            <h5>{month}</h5>
                            {groupedData[year][month].map((item: any, index: any) => (
                              <div
                                key={`row-${year}-${month}-${index}`}
                                className='row border border-dashed border-gray-300 rounded min-w-800px px-1 py-2 mb-3 bg-light'
                              >
                                <div className='col-2'>
                                  <span className='fs-4 fw-semibold'>{item.appointment_id}</span>
                                </div>
                                <div className='col-2'>
                                  <span className='fs-4 fw-semibold'>
                                    {moment(item.appointment_date).format('DD-MM-YYYY')}
                                  </span>
                                  <span className='fs-4 fw-semibold' style={{marginLeft: '15px'}}>
                                    {calculateSlotTiming(item)}
                                  </span>
                                </div>
                                <div className='col-2'>
                                  <span className='fs-4 fw-semibold' style={{marginLeft: '40px'}}>
                                    {moment(item.created_at).format('DD-MM-YYYY')}
                                  </span>
                                </div>
                                <div className='col-2'>
                                  <span className={`badge ${getStatusClassName(item.status)} fs-6`}>
                                    {item.status}
                                  </span>
                                </div>
                                <div className='col-2'>
                                  <span className='fs-4 fw-semibold'>Dr.{item.doc_name}</span>
                                </div>
                                <div
                                  className={`col-1 icon_cursor ${
                                    item.status === 'COMPLETED' || item.status === 'CANCELLED'
                                      ? ''
                                      : 'disabled'
                                  }`}
                                >
                                  <IoDocumentTextOutline
                                    style={{height: '26px', width: '26px', marginLeft: '5px'}}
                                    className='document_icon'
                                    onClick={() => handleviewdocument(item.appointment_id)}
                                    title='Medication'
                                  />
                                  <span style={{marginLeft: '5px'}}>
                                    <HiOutlineCurrencyRupee
                                      style={{height: '26px', width: '26px'}}
                                      className='rupee_icon'
                                      onClick={() => handleviewpdf(item.appointment_id)}
                                      title='Invoice'
                                    />
                                  </span>
                                </div>
                                <div
                                  className={`col-1 ${
                                    item.status === 'COMPLETED' || item.status === 'CANCELLED'
                                      ? ''
                                      : 'disabled'
                                  }`}
                                >
                                  <div className='d-flex gap-2 cursor-pointer'>
                                    <div
                                      className='border-0'
                                      onClick={() => openEditModal(item.appointment_id)}
                                    >
                                      <i
                                        className={`fa-regular fa-eye text-primary fs-2`}
                                        title='View Prescription'
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
            {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
          </div>
        )}
      </div>
      {loadingPdf && <DocInfoLoading />}
      {showModal && (
        <>
          <div className='modal-backdrop fade show'></div>
          <div
            className='modal fade show'
            style={{display: 'block'}}
            aria-labelledby='staticBackdropLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content' style={{width: '130%', marginLeft: '-12%'}}>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropLabel'>
                    {intl.formatMessage({id: 'PDF_PREVIEW'})}
                  </h5>
                  <button
                    type='button'
                    className='btn-close'
                    onClick={() => setShowModal(false)}
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body'>
                  {pdfUrl && (
                    <embed src={pdfUrl} type='application/pdf' width='100%' height='500px' />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isDisplay && (
        <PastPrescriptionPreview
          handlePreviewClose={handlePreviewClose}
          appointmentId={selectedAppointmentId}
        />
      )}
      <MedicationListPagination />
      {isLoading && <MedicationListLoading />}
    </KTCardBody>
  )
}
export default DpPastVisits
