/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PurchaseInventory} from '../../core/_models'

type Props = {
  user: PurchaseInventory
}

const PurchaseInvInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>{user?.name}</p>
    </div>
  </div>
)

export {PurchaseInvInfoCell}
