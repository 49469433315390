import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import { Doctor,DoctorsQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const DOCTOR_URL = `${API_URL}/doctor`
const GET_SPECIALITY_URL = `${API_URL}/speciality`
const GET_ROLES_URL = `${API_URL}/roles`
const CREATE_DOCTOR_URL = `${API_URL}/doctor`


const getDoctors = (query: string,id:number): Promise<DoctorsQueryResponse> => {
  return axios
    .get(`${CREATE_DOCTOR_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<DoctorsQueryResponse>) => d.data)
}

const getDoctorByID = (id: ID): Promise<Doctor | undefined> => {
  return axios
    .get(`${DOCTOR_URL}/${id}`)
    .then((response: AxiosResponse<Response<Doctor>>) => response.data)
    .then((response: Response<Doctor>) => response.data)
}

const createDoctor = (doctor: any): Promise<Doctor | undefined> => { 
  return axios
    .post(DOCTOR_URL, doctor)
}

const updateDoctor = (doctor: any, id:any): Promise<Doctor | undefined> => {
  return axios
    .patch(`${DOCTOR_URL}/${id}`, doctor)
}

const deleteDoctor = (doctorId: ID): Promise<void> => {
  return axios.delete(`${DOCTOR_URL}/${doctorId}`).then(() => {})
}

const deleteSelectedDoctors = (doctorIds: Array<ID>): Promise<void> => {
  const requests = doctorIds.map((id) => axios.delete(`${DOCTOR_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getSpeciality = (): Promise<DoctorsQueryResponse> => {
    return axios
      .get(`${GET_SPECIALITY_URL}`)
      .then((d: AxiosResponse<DoctorsQueryResponse>) => d.data)
}

const getRoles = (): Promise<DoctorsQueryResponse> => {
  return axios
    .get(`${GET_ROLES_URL}`)
    .then((d: AxiosResponse<DoctorsQueryResponse>) => d.data)
}

export {getDoctors, deleteDoctor, deleteSelectedDoctors, getDoctorByID, createDoctor, updateDoctor,getSpeciality,getRoles}
