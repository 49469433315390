/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {AuditLog} from '../../core/_models'

type Props = {
  user: AuditLog
}

const AuditLogsSRnoCell: FC<Props> = ({user}) => {
  const users = useQueryResponseData()
  const {currentPagination} = useContext(loginContext)

  const pageSize = 10

  return (
    <>
      {users && (
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            <p className='text-gray-800 mb-1'>
              {currentPagination * pageSize + users?.indexOf(user) + 1}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export {AuditLogsSRnoCell}
