import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_jmh/helpers'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const intl = useIntl()

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL.ERROR'}))
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.EMAIL_ERROR'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      // setTimeout(() => {
      requestPassword(values.email)
        .then(({data: {result}}) => {
          setHasErrors(false)
          setLoading(false)
          setSubmitting(false)
          setIsFormDirty(false)
        })
        .catch((ex) => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
        })
      // }, 1000)
    },
  })

  return (
    <>
      <div className='forgot-form'>
        <div className='container'>
          <div className='row forgot-form-bg'>
            <div className='col-md-6 col-sm-12 p-0'>
              <div className='login-vector'>
                <img
                  src={toAbsoluteUrl('/media/auth/login.png')}
                  alt='Doctor Vector'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className=' col-md-6 col-sm-12 p-0'>
              <form
                className='forgot-input-form form w-100'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='text-center'>
                  <h1 className='font-weight-bold'>
                    {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})} ?
                  </h1>
                  <div className='text-gray-400 fw-bold fs-4'>
                    {intl.formatMessage({id: 'GENREAL.FORGOT.PAGE'})}
                  </div>
                </div>

                {hasErrors === true && (
                  <div className='alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                      {intl.formatMessage({id: 'VALIDATION.WRONG_EMAIL'})}
                    </div>
                  </div>
                )}

                {hasErrors === false && (
                  <div className='bg-light-info p-8 rounded'>
                    <div className='text-info'>
                      {intl.formatMessage({id: 'VALIDATION.RESET.PASSWORD_LINK'})}
                    </div>
                  </div>
                )}

                <div className='input-group'>
                  <label className='form-label fw-bolder fs-6'>
                    {intl.formatMessage({id: 'GENERAL.EMAIL'})}
                  </label>

                  <div className='position-relative'>
                    <input
                      type='email'
                      placeholder={intl.formatMessage({id: 'PLACEHOLDER.ENTER.EMAIL_ADDRESS'})}
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      value={emailValue}
                      onChange={(e) => {
                        formik.handleChange(e) // Update formik state
                        setEmailValue(e.target.value) // Update emailValue state
                        setIsFormDirty(true)
                      }}
                      className={clsx(
                        'form-control form-control-lg form-control-solid'
                        // {'is-invalid': formik.touched.email && formik.errors.email},
                        // {
                        //   'is-valid': formik.touched.email && !formik.errors.email,
                        // }
                      )}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='d-flex justify-content-center align-items-center'>
                  <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-lg btn-primary fw-bolder me-4'
                    disabled={formik.isSubmitting || !formik.isValid || !isFormDirty}
                  >
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                    </span>
                    {loading && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <Link to='/'>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-lg btn-primary fw-bolder me-4'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='d-flex justify-content-center  align-items-center h-100'>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>Forgot Password ?</h1>

              <div className='text-gray-400 fw-bold fs-4'>
                Enter your email to reset your password.
              </div>
            </div>

            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Please enter a valid email address
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>
                  Password reset link has been sent to your email, please check your email.
                </div>
              </div>
            )}

            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
              <input
                type='email'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('email')}
                value={emailValue}
                onChange={(e) => {
                  formik.handleChange(e) // Update formik state
                  setEmailValue(e.target.value) // Update emailValue state
                  setIsFormDirty(true)
                }}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
                disabled={formik.isSubmitting || !formik.isValid || !isFormDirty}
              >
                <span className='indicator-label'>Submit</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>{' '}
            </div>
          </form>
        </div>
      </div> */}
    </>
  )
}
