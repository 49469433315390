export const formatCost = (cost: number): string => {
  const formattedCost = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(cost)

  return `${formattedCost}`
}

export const convertCreatedAtToTimeAgo = (created_at: string): string => {
  const createdAtDate = new Date(created_at);
  const currentTime = new Date();
  const timeDifference = Math.floor((currentTime.getTime() - createdAtDate.getTime()) / 1000); // in seconds

  const minutes = Math.floor(timeDifference / 60);
  const hours = Math.floor(timeDifference / 3600);
  const days = Math.floor(timeDifference / 86400);
  const months = Math.floor(timeDifference / 2592000);

  if (months >= 1) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days >= 1) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours >= 1) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes >= 1) {
    return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
  } else {
    return "Just now";
  }
}