import React from 'react'
import OtpVerify from '../../../modules/auth/components/PatientComponents/OtpVerify'
import {useIntl} from 'react-intl'

type PatientVerifyOtpModalProps = {
  show: boolean
  onClose: () => void
  emailValue: string
  doctorId?: any
  patientSelectedHospitalId?: any
}

const PatientVerifyOtpModal: React.FC<PatientVerifyOtpModalProps> = ({
  show,
  onClose,
  emailValue,
  doctorId,
  patientSelectedHospitalId,
}) => {
  const intl = useIntl()
  return (
    <>
      <div
        className={`modal fade${show ? ' show' : ''}`}
        onClick={onClose}
        style={{display: show ? 'block' : 'none'}}
      >
        <div className='modal-dialog'>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='modal-header'>
              <h2 className='modal-title'>{intl.formatMessage({id: 'GENERAL.OTP.VERIFY'})}</h2>
              <button type='button' className='btn-close' onClick={onClose}></button>
            </div>
            <div className='modal-body'>
              <OtpVerify
                emailValue={emailValue}
                doctorId={doctorId}
                patientSelectedHospitalId={patientSelectedHospitalId}
              />
            </div>
          </div>
        </div>
      </div>
      {show && <div className='modal-backdrop fade show'></div>}
    </>
  )
}

export default PatientVerifyOtpModal
