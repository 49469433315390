import {FC, useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, KTSVG} from '../../../../../_jmh/helpers'
import {initialUser, PatientDoc} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {PatientUploadDocListLoading} from '../components/loading/PatientUploadDocListLoading'
import {createPatientDoc, updatePatientDoc} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: PatientDoc
}

const editUserSchema = Yup.object().shape({})

const PatientUploadDocEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [errorStatus, setErrorStatus] = useState('')
  const SUPPORTED_FILE_TYPES = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']
  const {selectedPatientId, selectedAppointment} = useContext(loginContext)
  const [inputKey, setInputKey] = useState<number>(0)
  const {id} = useParams()

  const [userForEdit] = useState<PatientDoc>({
    ...user,
    path: user.path || initialUser.path,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const handleFileChange = (event: any) => {
    // setErrorStatus('')
    const file = event.target.files?.[0]

    if (file) {
      if (SUPPORTED_FILE_TYPES.includes(file.type)) {
        setSelectedFile(file)
        formik.setFieldValue('path', file)
        setErrorStatus('')
      } else {
        setErrorStatus('Unsupported file format')
      }
    }
  }
  const handleRemoveFile = () => {
    formik.setFieldValue('path', null)
    setSelectedFile(null)
    setInputKey((prevKey) => prevKey + 1)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updatePatientDoc(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          const formData = new FormData()
          formData.append('file', values?.path as any)
          formData.append('patient_id', selectedPatientId ? selectedPatientId : id)
          formData.append(
            'appointment_id',
            selectedAppointment
              ? selectedAppointment?.value === 'all'
                ? ''
                : String(selectedAppointment?.value)
              : ''
          )

          await createPatientDoc(formData).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_user_form3'
        className={clsx('form', {
          'form-not-clickable': formik.isSubmitting,
        })}
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <div className='patient_attachment d-flex'>
              <label
                htmlFor='file'
                className='patient_attachment_btn w-100 btn btn-active-light-primary'
              >
                <KTSVG
                  path='/media/icons/duotune/files/fil022.svg'
                  className='svg-icon-muted svg-icon-2hx'
                />
                <span>{intl.formatMessage({id: 'GENERAL.UPLOAD'})}</span>
              </label>

              <input
                key={inputKey}
                type='file'
                id='file'
                name='path'
                onChange={handleFileChange}
                accept='.pdf,.jpg,.jpeg,.png'
              />
            </div>
            <div className='mt-2 text-muted'>
              {intl.formatMessage({id: 'GENERAL.ONLY.FILE.FORMATS'})}
            </div>

            {selectedFile && (
              <div className='form-selected-file my-5'>
                <p>{selectedFile.name}</p>
                <span className='remove-file' onClick={handleRemoveFile}>
                  <i className='fa-solid fa-xmark fs-2'></i>
                </span>
              </div>
            )}

            {errorStatus !== '' ? (
              <>
                <div className='text-danger my-2'>
                  <div className='font-weight-bold'>{errorStatus}</div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty ||
              selectedFile === null
            }
          >
            <span className='indicator-label'> {intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <PatientUploadDocListLoading />}
    </>
  )
}

export {PatientUploadDocEditModalForm}
