import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {loginContext} from '../../../../context/context'
import {useContext} from 'react'

const DoctorEditModalHeader = () => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {loginData} = useContext(loginContext)

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>
        {itemIdForUpdate || loginData?.data?.user_type === 'DOCTOR'
          ? intl.formatMessage({id: 'DOCTOR.EDIT.DOCTOR'})
          : intl.formatMessage({id: 'DOCTOR.ADD.DOCTOR'})}
      </h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

export {DoctorEditModalHeader}
