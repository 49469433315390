import {useIntl} from 'react-intl'
import {loginContext} from '../../../../app/context/context'
import {useContext, useEffect, useState} from 'react'
import {getPermission} from '../../../../app/pages/roles-and-permissions/Roleslist/core/_requests'
import {Link, useLocation} from 'react-router-dom'
export function AsideMenuIconMain() {
  const intl = useIntl()
  const {loginData} = useContext(loginContext)
  const [getAllPermissions, setGetAllPermissions] = useState([])
  const {selectedPatientTab, setSelectedPatientTab} = useContext(loginContext)
  const [tab, setTab] = useState(selectedPatientTab)
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)
  const [nestedHoveredItem, setNestedHoveredItem] = useState<string | null>(null)
  const location = useLocation()
  const currentPath = location.pathname

  function checkPermission(moduleName: string): boolean {
    const permissions = loginData?.data?.permissions || []
    const modulePermissions = permissions.filter(
      (permission: any) => permission?.module?.name === moduleName
    )
    return modulePermissions.length > 0
  }

  useEffect(() => {
    ;(async () => {
      const permission = await getPermission()
      setGetAllPermissions(permission)
    })()
  }, [])

  const superAdminPermissions = getAllPermissions.flatMap((item: any) =>
    item.permissions.map((p: any) => ({
      module_id: item.id,
      permission_id: p.id,
      module: {
        id: item.id,
        name: item.name,
      },
      permission: p,
    }))
  )

  if (loginData?.data?.user_type === 'SUPER_ADMIN') {
    loginData.data.permissions = superAdminPermissions
  }

  const handleMenuItemClick = (tabName: any) => {
    setTab(tabName)
    setSelectedPatientTab(tabName)
  }

  useEffect(() => {
    setSelectedPatientTab(tab)
  }, [tab, selectedPatientTab])

  const allowedModules = new Set(
    loginData?.data?.user_type === 'SUPER_ADMIN'
      ? getAllPermissions.map((item: any) => item.name)
      : loginData?.data?.permissions
          ?.filter((item: any) => item.permission.route === '' && item.permission.method === 'GET')
          .map((item: any) => item.module.name)
  )

  const isChildActive = (paths: any) => paths.some((path: any) => currentPath.startsWith(path))

  return (
    <>
      <li
        className='d-flex d-lg-none align-items-center justify-content-center'
        style={{height: '55px'}}
      >
        <Link to='/'>
          <h1 className='m-0' style={{color: 'white'}}>
            {process.env.REACT_APP_ORGANIZATION_NAME}
          </h1>
        </Link>
      </li>

      <li className='nav-item mt-4'>
        <div className={`nav-item-inner`}>
          <Link className='nav-link' to='/doctor-all-information'>
            <img src='/media/icons/duotune/general/gen081.svg' alt='appointments icon' />
          </Link>
        </div>
      </li>

      <li className='nav-item'>
        <div className={`nav-item-inner ${currentPath === '/dashboard' && 'active'}`}>
          <Link className='nav-link' to='/dashboard'>
            <img src='/media/icons/duotune/general/gen076.svg' alt='dashboard icon' />
          </Link>
        </div>
      </li>

      {loginData?.data?.user_type === 'SUPER_ADMIN' && (
        <li className='nav-item'>
          <div className={`nav-item-inner ${currentPath === '/roles-permissions' && 'active'}`}>
            <Link className='nav-link' to='/roles-permissions'>
              <img src='/media/icons/duotune/general/gen077.svg' alt='Role icon' />
            </Link>
          </div>
        </li>
      )}

      {(checkPermission('patient') ||
        checkPermission('patient-insurance') ||
        checkPermission('patient-appointment') ||
        checkPermission('patient-billing') ||
        checkPermission('ad-patient') ||
        checkPermission('patient-medication') ||
        checkPermission('patient-medical-notes') ||
        checkPermission('patient-vitals') ||
        checkPermission('patient-medical-background')) && (
        <li className='nav-item dropdown'>
          <div
            className={`nav-item-inner ${
              isChildActive([
                '/patient-data/patient-management',
                '/patient-data/patient-insurance',
                '/patient-data/patient-appointment',
                '/patient-data/patient-billing',
                '/patient-data/ad-patient',
                '/patient-data/patient-details/medications',
                '/patient-data/patient-details/medical-notes',
                '/patient-data/patient-details/patient-vital',
                '/patient-data/patient-details/medical-background',
                '/patient-data/patient-details/attachments',
              ])
                ? 'active'
                : ''
            }`}
          >
            <Link
              className='nav-link'
              to='/patient-data'
              onMouseEnter={() => setHoveredItem('patient')}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={(e) => e.preventDefault()}
            >
              <img src='/media/icons/duotune/medicine/med013.svg' alt='' />
            </Link>
            <div
              className={`dropdown-menu ${hoveredItem === 'patient' ? 'show' : ''}`}
              onMouseEnter={() => setHoveredItem('patient')}
              onMouseLeave={() => setHoveredItem(null)}
            >
              {allowedModules.has('patient') && (
                <Link
                  className={`dropdown-item ${
                    currentPath === '/patient-data/patient-management' && 'active'
                  }`}
                  to='/patient-data/patient-management'
                >
                  {intl.formatMessage({id: 'PATIENT.PATIENT_MANAGEMENT'})}
                </Link>
              )}

              {allowedModules.has('patient-insurance') && (
                <Link
                  className={`dropdown-item ${
                    currentPath === '/patient-data/patient-insurance' && 'active'
                  }`}
                  to='/patient-data/patient-insurance'
                >
                  {intl.formatMessage({id: 'PATIENT.INSURNCE'})}
                </Link>
              )}

              {allowedModules.has('patient-appointment') && (
                <Link
                  className={`dropdown-item ${
                    currentPath === '/patient-data/patient-appointment' && 'active'
                  }`}
                  to='/patient-data/patient-appointment'
                >
                  {intl.formatMessage({id: 'PATIENT.APPOINTMENT'})}
                </Link>
              )}

              {allowedModules.has('patient-billing') && (
                <Link
                  className={`dropdown-item ${
                    currentPath === '/patient-data/patient-billing' && 'active'
                  }`}
                  to='/patient-data/patient-billing'
                >
                  {intl.formatMessage({id: 'PATIENT.BILLING'})}
                </Link>
              )}

              {allowedModules.has('ad-patient') && (
                <Link
                  className={`dropdown-item ${
                    currentPath === '/patient-data/ad-patient' && 'active'
                  }`}
                  to='/patient-data/ad-patient'
                >
                  {intl.formatMessage({id: 'PATIENT.ADT'})}
                </Link>
              )}

              <div className='sub-dropdown dropdown'>
                <Link
                  className='dropdown-item sub-dropdown-item'
                  to='/patient-data/patient-details'
                  onMouseEnter={() => setNestedHoveredItem('patient-details')}
                  onMouseLeave={() => setNestedHoveredItem(null)}
                  onClick={(e) => e.preventDefault()}
                >
                  <span className='dropdown-item-title'>
                    {intl.formatMessage({id: 'PATIENT.DETAILS'})}
                  </span>
                  <span className='dropdown-icon-arrow'>
                    <i className='fa-solid fa-angle-right'></i>
                  </span>
                </Link>
                {nestedHoveredItem === 'patient-details' && (
                  <div
                    className={`dropdown-menu ${
                      nestedHoveredItem === 'patient-details' ? 'show' : ''
                    }`}
                    onMouseEnter={() => setNestedHoveredItem('patient-details')}
                    onMouseLeave={() => setNestedHoveredItem(null)}
                  >
                    {allowedModules.has('patient-medication') && (
                      <Link
                        className={`dropdown-item ${
                          currentPath === '/patient-data/patient-details/medications' && 'active'
                        }`}
                        to='/patient-data/patient-details/medications'
                      >
                        {intl.formatMessage({id: 'PATIENT_DETAILS.MEDICATIONS'})}
                      </Link>
                    )}

                    {allowedModules.has('patient-medical-notes') && (
                      <Link
                        className={`dropdown-item ${
                          currentPath === '/patient-data/patient-details/medical-notes' && 'active'
                        }`}
                        to='/patient-data/patient-details/medical-notes'
                      >
                        {intl.formatMessage({id: 'PATIENT_DETAILS.MEDICAL.NOTES'})}
                      </Link>
                    )}

                    {allowedModules.has('patient-vitals') && (
                      <Link
                        className={`dropdown-item ${
                          currentPath === '/patient-data/patient-details/patient-vital' && 'active'
                        }`}
                        to='/patient-data/patient-details/patient-vital'
                      >
                        {intl.formatMessage({id: 'PATIENT_DETAILS.PATIENT.VITAL'})}
                      </Link>
                    )}

                    {allowedModules.has('patient-medical-background') && (
                      <Link
                        className={`dropdown-item ${
                          currentPath === '/patient-data/patient-details/medical-background' &&
                          'active'
                        }`}
                        to='/patient-data/patient-details/medical-background'
                      >
                        {intl.formatMessage({id: 'PATIENT_DETAILS.MEDICAL.BACKGROUND'})}
                      </Link>
                    )}

                    {allowedModules.has('patient') && (
                      <Link
                        className={`dropdown-item ${
                          currentPath === '/patient-data/patient-details/attachments' && 'active'
                        }`}
                        to='/patient-data/patient-details/attachments'
                      >
                        {intl.formatMessage({id: 'PATIENT_DETAILS.ATTACHMENTS'})}
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </li>
      )}

      {(checkPermission('staff') ||
        checkPermission('shift') ||
        checkPermission('staff-salary-strcuture') ||
        checkPermission('staff-payroll')) && (
        <li className='nav-item'>
          <div
            className={`nav-item-inner ${
              isChildActive([
                '/staff-management',
                '/staff/shift-management',
                '/staff/salary-structure',
                '/staff/payroll',
              ])
                ? 'active'
                : ''
            }`}
          >
            <Link
              className='nav-link'
              to='staff'
              onMouseEnter={() => setHoveredItem('staff')}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={(e) => e.preventDefault()}
            >
              <img src='/media/icons/duotune/medicine/med012.svg' alt='' />
              {/* <span className='menu-title'>{intl.formatMessage({id: 'STAFF.S  TAFF'})}</span> */}
            </Link>

            {hoveredItem === 'staff' && (
              <div
                className={`dropdown-menu show ${hoveredItem === 'staff' ? 'show' : ''}`}
                onMouseEnter={() => setHoveredItem('staff')}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {allowedModules.has('staff') && (
                  <Link
                    className={`dropdown-item ${currentPath === '/staff-management' && 'active'}`}
                    to='/staff-management'
                  >
                    {intl.formatMessage({id: 'STAFF.STAFF.MANAGEMENT'})}
                  </Link>
                )}

                {allowedModules.has('shift') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/staff/shift-management' && 'active'
                    }`}
                    to='/staff/shift-management'
                  >
                    {intl.formatMessage({id: 'SHIFT.SHIFT.MANAGEMENT'})}
                  </Link>
                )}

                {allowedModules.has('staff-salary-strcuture') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/staff/salary-structure' && 'active'
                    }`}
                    to='/staff/salary-structure'
                  >
                    {intl.formatMessage({id: 'STAFF.SALARY.STRUCTURE'})}
                  </Link>
                )}

                {allowedModules.has('staff-payroll') && (
                  <Link
                    className={`dropdown-item ${currentPath === '/staff/payroll' && 'active'}`}
                    to='/staff/payroll'
                  >
                    {intl.formatMessage({id: 'STAFF.PAYROLL'})}
                  </Link>
                )}
              </div>
            )}
          </div>
        </li>
      )}

      {(checkPermission('doctor') ||
        checkPermission('doctor-availability') ||
        checkPermission('patient-appointment')) && (
        <li className='nav-item dropdown'>
          <div
            className={`nav-item-inner ${
              isChildActive([
                '/doctor-management',
                '/doctor-availability',
                // '/doctor-appointment',
                '/doctor-all-information',
              ])
                ? 'active'
                : ''
            }`}
          >
            <Link
              className='nav-link'
              to='/doctor'
              onMouseEnter={() => setHoveredItem('doctor')}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={(e) => e.preventDefault()}
            >
              <img src='/media/icons/duotune/medicine/med011.svg' alt='' />
            </Link>
            {hoveredItem === 'doctor' && (
              <div
                className={`dropdown-menu show ${hoveredItem === 'doctor' ? 'show' : ''}`}
                onMouseEnter={() => setHoveredItem('doctor')}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {allowedModules.has('doctor') && (
                  <Link
                    className={`dropdown-item ${currentPath === '/doctor-management' && 'active'}`}
                    to='/doctor-management'
                  >
                    {intl.formatMessage({id: 'DOCTOR.DOCTOR.MANAGEMENT'})}
                  </Link>
                )}

                {allowedModules.has('doctor-availability') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/doctor-availability' && 'active'
                    }`}
                    to='/doctor-availability'
                  >
                    {intl.formatMessage({id: 'DOCTOR.DOCTOR.AVAILABILITY'})}
                  </Link>
                )}

                {allowedModules.has('doctor-all-information') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/doctor-all-information' && 'active'
                    }`}
                    to='/doctor-all-information'
                  >
                    {intl.formatMessage({id: 'DOCTOR.ALL_INFORMATION'})}
                  </Link>
                )}
              </div>
            )}
          </div>
        </li>
      )}

      {(checkPermission('asset') ||
        checkPermission('asset-category') ||
        checkPermission('asset-maintenance')) && (
        <li className='nav-item dropdown'>
          <div
            className={`nav-item-inner ${
              isChildActive(['/asset-management', '/asset-category', '/asset-maintenance'])
                ? 'active'
                : ''
            }`}
          >
            <Link
              className='nav-link'
              to='/asset'
              onMouseEnter={() => setHoveredItem('asset')}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={(e) => e.preventDefault()}
            >
              <img src='/media/icons/duotune/general/gen078.svg' alt='' />
            </Link>
            {hoveredItem === 'asset' && (
              <div
                className={`dropdown-menu ${hoveredItem === 'asset' ? 'show' : ''}`}
                onMouseEnter={() => setHoveredItem('asset')}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {allowedModules.has('asset') && (
                  <Link
                    className={`dropdown-item ${currentPath === '/asset-management' && 'active'}`}
                    to='/asset-management'
                  >
                    {intl.formatMessage({id: 'ASSET.ASSET.MANAGEMENT'})}
                  </Link>
                )}

                {allowedModules.has('asset-category') && (
                  <Link
                    className={`dropdown-item ${currentPath === '/asset-category' && 'active'}`}
                    to='/asset-category'
                  >
                    {intl.formatMessage({id: 'ASSET.ASSET.CATEGORY'})}
                  </Link>
                )}

                {allowedModules.has('asset-maintenance') && (
                  <Link
                    className={`dropdown-item ${currentPath === '/asset-maintenance' && 'active'}`}
                    to='/asset-maintenance'
                  >
                    {intl.formatMessage({id: 'ASSET.ASSET.MAINTENANCE'})}
                  </Link>
                )}
              </div>
            )}
          </div>
        </li>
      )}

      {(checkPermission('inventory-category') ||
        checkPermission('inventory') ||
        checkPermission('assign-inventory')) && (
        <li className='nav-item dropdown'>
          <div
            className={`nav-item-inner ${
              isChildActive([
                '/inventory/inventory-category',
                '/inventory/inventory-management',
                '/inventory/assign-inventory',
              ])
                ? 'active'
                : ''
            }`}
          >
            <Link
              className='nav-link'
              to='/inventory'
              onMouseEnter={() => setHoveredItem('inventory')}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={(e) => e.preventDefault()}
            >
              <img src='/media/icons/duotune/general/gen079.svg' alt='' />
            </Link>
            {hoveredItem === 'inventory' && (
              <div
                className={`dropdown-menu ${hoveredItem === 'inventory' ? 'show' : ''}`}
                onMouseEnter={() => setHoveredItem('inventory')}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {allowedModules.has('inventory-category') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/inventory/inventory-category' && 'active'
                    }`}
                    to='/inventory/inventory-category'
                  >
                    {intl.formatMessage({id: 'INVENTORY.INVENTORY_CATEGORY'})}
                  </Link>
                )}

                {allowedModules.has('inventory') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/inventory/inventory-management' && 'active'
                    }`}
                    to='/inventory/inventory-management'
                  >
                    {intl.formatMessage({id: 'INVENTORY.INVENTORY_MANAGEMENT'})}
                  </Link>
                )}

                {allowedModules.has('assign-inventory') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/inventory/assign-inventory' && 'active'
                    }`}
                    to='/inventory/assign-inventory'
                  >
                    {intl.formatMessage({id: 'INVENTORY.ASSIGN_INVENTORY'})}
                  </Link>
                )}

                {allowedModules.has('inventory') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/inventory/purchase-inventory' && 'active'
                    }`}
                    to='/inventory/purchase-inventory'
                  >
                    {intl.formatMessage({id: 'INVENTORY.PURCHASE_INVENTORY'})}
                  </Link>
                )}
              </div>
            )}
          </div>
        </li>
      )}

      {(checkPermission('hospital') ||
        checkPermission('speciality') ||
        checkPermission('hospital-room') ||
        checkPermission('operation-theatre') ||
        checkPermission('audit-logs') ||
        checkPermission('patient-medicine-master')) && (
        <li className='nav-item dropdown'>
          <div
            className={`nav-item-inner ${
              isChildActive([
                '/master/hospitals',
                '/master/specialities',
                '/master/hospital-room',
                '/master/operation-theatre',
                '/master/audit-logs',
                '/master/patient-medicine-master',
              ])
                ? 'active'
                : ''
            }`}
          >
            <Link
              className='nav-link'
              to='/master'
              onMouseEnter={() => setHoveredItem('master')}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={(e) => e.preventDefault()}
            >
              <img src='/media/icons/duotune/general/gen080.svg' alt='' />
            </Link>
            {hoveredItem === 'master' && (
              <div
                className={`dropdown-menu ${hoveredItem === 'master' ? 'show' : ''}`}
                onMouseEnter={() => setHoveredItem('master')}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {allowedModules.has('hospital') && (
                  <Link
                    className={`dropdown-item ${currentPath === '/master/hospitals' && 'active'}`}
                    to='/master/hospitals'
                  >
                    {intl.formatMessage({id: 'HOSPITAL_INFORMATION'})}
                  </Link>
                )}

                {allowedModules.has('speciality') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/master/specialities' && 'active'
                    }`}
                    to='/master/specialities'
                  >
                    {intl.formatMessage({id: 'SPECIALITIES'})}
                  </Link>
                )}

                {allowedModules.has('hospital-room') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/master/hospital-room' && 'active'
                    }`}
                    to='/master/hospital-room'
                  >
                    {intl.formatMessage({id: 'ROOM_MANAGEMENT'})}
                  </Link>
                )}

                {allowedModules.has('operation-theatre') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/master/operation-theatre' && 'active'
                    }`}
                    to='/master/operation-theatre'
                  >
                    {intl.formatMessage({id: 'OPERATION_THEATRE'})}
                  </Link>
                )}

                {allowedModules.has('audit-logs') && (
                  <Link
                    className={`dropdown-item ${currentPath === '/master/audit-logs' && 'active'}`}
                    to='/master/audit-logs'
                  >
                    {intl.formatMessage({id: 'AUDIT_LOGS'})}
                  </Link>
                )}

                {allowedModules.has('patient-medicine-master') && (
                  <Link
                    className={`dropdown-item ${
                      currentPath === '/master/patient-medicine-master' && 'active'
                    }`}
                    to='/master/patient-medicine-master'
                  >
                    {intl.formatMessage({id: 'MEDICINE_MANAGEMENT'})}
                  </Link>
                )}
              </div>
            )}
          </div>
        </li>
      )}
    </>
  )
}
