import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {Operationlist, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`

const GET_PATIENT_URL = `${API_URL}/patient`
const GET_DOCTOR_URL = `${API_URL}/doctor`
const GET_HOSPITAL_ROOM_URL = `${API_URL}/hospital-room`
const GET_ROOM_ID = `${API_URL}/hospital-room`
const OPERATION_URL = `${API_URL}/operation-theatre`

const getPatients = (query?: string): Promise<UsersQueryResponse> => {
  return axios.get(`${GET_PATIENT_URL}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getDoctors = (query?: string): Promise<UsersQueryResponse> => {
  return axios.get(`${GET_DOCTOR_URL}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getHospitalRooms = (query?: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_HOSPITAL_ROOM_URL}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getRoomByID = (id?: ID): Promise<any> => {
  return axios
    .get(`${GET_ROOM_ID}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getOperations = (query: string, id: number): Promise<UsersQueryResponse> => {
  return axios
    .get(`${OPERATION_URL}?${query ? `${query}&hospital_id=${id}` : `hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getOperationById = (id: ID): Promise<Operationlist | undefined> => {
  return axios
    .get(`${OPERATION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Operationlist>>) => response.data)
    .then((response: Response<Operationlist>) => response.data)
}

const createOpration = (user: Operationlist): Promise<any> => {
  return axios.post(OPERATION_URL, user)
}

const updateOperation = (user: Operationlist): Promise<Operationlist | undefined> => {
  return axios.patch(`${OPERATION_URL}/${user.id}`, user)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getOperations,
  deleteUser,
  deleteSelectedUsers,
  getOperationById,
  createOpration,
  updateOperation,
  getPatients,
  getDoctors,
  getHospitalRooms,
  getRoomByID,
}
