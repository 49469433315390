import React from 'react'
import {PatientLogin} from '../../../modules/auth/components/PatientComponents/PatientLogin'
import {useIntl} from 'react-intl'

type PatientLoginModalProps = {
  show: boolean
  onClose: () => void
  onRegisterClick: () => void
  onForgotPasswordClick: () => void
  onPasswordChangeClick: () => void
  doctorId?: any
  patientSelectedHospitalId?: any
}

const PatientLoginModal: React.FC<PatientLoginModalProps> = ({
  show,
  onClose,
  onRegisterClick,
  onForgotPasswordClick,
  onPasswordChangeClick,
  doctorId,
  patientSelectedHospitalId,
}) => {
  const intl = useIntl()
  return (
    <>
      <div
        className={`modal fade${show ? ' show' : ''}`}
        onClick={onClose}
        style={{display: show ? 'block' : 'none'}}
      >
        <div className='modal-dialog'>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='modal-header'>
              <h2 className='modal-title'>{intl.formatMessage({id: 'GENERAL.SIGN.IN'})}</h2>
              <button type='button' className='btn-close' onClick={onClose}></button>
            </div>
            <div className='modal-body'>
              <PatientLogin
                onRegisterClick={onRegisterClick}
                onForgotPasswordClick={onForgotPasswordClick}
                doctorId={doctorId}
                patientSelectedHospitalId={patientSelectedHospitalId}
                onPasswordChangeClick={onPasswordChangeClick}
              />
            </div>
          </div>
        </div>
      </div>
      {show && <div className='modal-backdrop fade show'></div>}
    </>
  )
}

export default PatientLoginModal
