import {useContext, useEffect, useState} from 'react'
import {DpContext} from '../DpLayoutWrapper'
import {createPrescription} from '../core/_request'
import {toast} from 'react-toastify'
import {Modal} from 'react-bootstrap'

interface SubmitConsultationProps {
  isCheckboxChecked: boolean
}

const SubmitConsultation: React.FC<SubmitConsultationProps> = ({isCheckboxChecked}) => {
  const {attachmentFiles} = useContext(DpContext)
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [prescriptionData, setPrescriptionData] = useState<any>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // useEffect(() => {
  //   const prescriptionDataString = sessionStorage.getItem('prescriptionData')
  //   if (!prescriptionDataString) {
  //     console.error('Prescription data not found in sessionStorage')
  //     return
  //   }
  //   const prescriptionData = JSON.parse(prescriptionDataString)
  //   if (prescriptionData) {
  //     setEmail(prescriptionData?.email)
  //     setPrescriptionData(prescriptionData)
  //   }
  // }, [sessionStorage.prescriptionData])

  const handleConsultation = (shareEmail: any) => {
    if (isSubmitting) {
      return // Prevent multiple submissions
    }

    setIsSubmitting(true)

    const prescriptionDataString = sessionStorage.getItem('prescriptionData')
    if (!prescriptionDataString) {
      console.error('Prescription data not found in sessionStorage')
      setIsSubmitting(false)
      return
    }

    const prescriptionData = JSON.parse(prescriptionDataString)
    if (!prescriptionData) {
      console.error('Invalid prescription data in sessionStorage')
      setIsSubmitting(false)
      return
    }

    const formData = new FormData()
    formData.append('appointment_id', prescriptionData.appointment_id)
    formData.append('doctor_id', prescriptionData.doctor_id)
    formData.append('patient_id', prescriptionData.patient_id)
    formData.append('hospital_id', prescriptionData.hospital_id)
    formData.append('chief_complaints', JSON.stringify(prescriptionData.chief_complaints))

    const examination = prescriptionData?.examination
    const examinationData = {
      temperature: examination?.temperature?.temperature_value?.toString(),
      temperature_unit: examination?.temperature?.temperature_unit,
      height: examination?.height?.height_value?.toString(),
      height_unit: examination?.height?.height_unit,
      weight: examination?.weight?.weight_value?.toString(),
      weight_unit: examination?.weight?.weight_unit,
      pulse: examination?.pulse?.toString(),
      respiratory_rate: examination?.respiratory_rate?.toString(),
      blood_pressure: examination?.blood_pressure,
      spO2: examination?.spO2?.toString(),
    }

    // Convert the examination object to JSON string
    const examinationJSON = JSON.stringify(examinationData)

    // Append examination object to formData
    formData.append('examination', examinationJSON)

    formData.append('examination_notes', JSON.stringify(prescriptionData?.examination_notes))
    formData.append('diagnosis', JSON.stringify(prescriptionData?.diagnosis))

    // Convert arrays to JSON strings before appending to formData
    formData.append(
      'medication',
      JSON.stringify(prescriptionData?.medication === null ? [] : prescriptionData?.medication)
    )
    formData.append(
      'procedure',
      JSON.stringify(prescriptionData?.procedure === null ? [] : prescriptionData?.procedure)
    )
    formData.append(
      'investigation',
      JSON.stringify(
        prescriptionData?.investigation === null ? [] : prescriptionData?.investigation
      )
    )

    formData.append('advice', JSON.stringify(prescriptionData?.advice))

    if (attachmentFiles && attachmentFiles.length > 0) {
      attachmentFiles.forEach((file: File) => {
        formData.append('attachments', file)
      })
    }

    if (shareEmail) {
      formData.append('email', email)
    } else {
      formData.append('email', '')
    }

    createPrescription(formData)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setIsSubmitting(false)
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          const {message} = error.response.data
          toast.error(error.response.data.message)
        } else {
          toast.error('Failed to create prescription')
        }
        setIsSubmitting(false)
      })
  }

  const handleSubmitConsultation = () => {
    if (isCheckboxChecked) {
      handleOpenModal()
    } else {
      handleConsultation(false)
    }
  }

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setEmail(value)

    if (!isValidEmail(value)) {
      setEmailError('Invalid email format')
    } else {
      setEmailError('')
    }
  }

  const handleEmailBlur = () => {
    // Perform email validation onBlur
    if (!isValidEmail(email)) {
      setEmailError('Invalid email format')
    } else {
      setEmailError('')
    }
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSharing = () => {
    handleConsultation(true)
    handleCloseModal()
  }

  return (
    <>
      <button className='btn' onClick={handleSubmitConsultation}>
        End Consultation
      </button>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton className='border-0'></Modal.Header>
        <Modal.Body>
          <h3>Add Patient's Email Id to share Rx</h3>
          <p className='text-muted'>
            Enter the email you would like to share the prescription with:
          </p>
          <input
            className='form-control'
            type='email'
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            placeholder='Enter your email'
          />
          {emailError && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{emailError}</span>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <button
            className='w-100 btn border-0 text-white with_share_btn'
            disabled={emailError.length > 0}
            onClick={handleSharing}
          >
            Share & End
          </button>
          <button
            className='without_share_btn w-100 btn border-0 text-center'
            onClick={() => handleConsultation(false)}
          >
            End Without Sharing
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SubmitConsultation
