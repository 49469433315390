import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_jmh/helpers'
import {PatientBiling} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {PatientBillingListLoading} from '../components/loading/PatientBillingListLoading'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useParams} from 'react-router-dom'
import {createPatientBiling, getPatientName, updatePatientBiling} from '../core/_requests'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: PatientBiling
}

const editUserSchema = Yup.object().shape({
  patient_full_name: Yup.string().required('Patient Full Name required'),
  billing_date: Yup.date().required('Billing date is required'),
  billing_head: Yup.string().required('Billing head is required'),
  cost: Yup.string().required('Cost is required'),
  quantity: Yup.string().required('Quantity is required'),
  total_cost: Yup.string().required('Total cost is required'),
  total_billing_cost: Yup.string().required('total billing cost is required'),
  payment_method: Yup.string().required('Mode of payment is required'),
  payment_status: Yup.string().required('Payment status required'),
})

const PatientBillingEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {selectedPatientId} = useContext(loginContext)
  const [patientFullname, setPatientFullName] = useState([])

  const {id} = useParams()

  const [userForEdit] = useState<PatientBiling>({
    ...user,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    ;(async () => {
      const patientNameData: any = await getPatientName()
      setPatientFullName(patientNameData?.data)
    })()
  }, [])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.bill_number)) {
          await updatePatientBiling(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          await createPatientBiling({
            ...values,
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        toast.error(ex.response.data.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form3' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'GENERAL.PATIENT.FULL.NAME'})}
            </label>
            <select
              className='form-select'
              aria-label='Select example'
              // value={formik.values}
              {...formik.getFieldProps('patient_full_name')}
              name='patient_full_name'
            >
              <option>{intl.formatMessage({id: 'PATIENT_INSURANCE.SELECT.PATIENT.NAME'})}</option>
              {patientFullname &&
                patientFullname?.map((item: any) => {
                  return (
                    <>
                      <option value={item?.id}>{item?.full_name}</option>
                    </>
                  )
                })}
            </select>
            {/* {formik.touched.patient_full_name && formik.errors.patient_full_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.patient_full_name}</span>
                </div>
              </div>
            )} */}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'PATIENT_BILLING.BILLING.HEAD'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'PATIENT_BILLING.ENTER.BILLING.HEAD'})}
              {...formik.getFieldProps('billing_detail')}
              type='text'
              name='title'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
            />
            {formik.touched.billing_head && formik.errors.billing_head && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.billing_head}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'PATIENT_BILLING.COST.PER.HEAD'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'PATIENT_BILLING.ENTER.COST.PER.HEAD'})}
              {...formik.getFieldProps('cost_per_head')}
              type='text'
              name='cost_per_head'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
            />
            {formik.touched.cost_per_head && formik.errors.cost_per_head && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cost_per_head}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'PATIENT_BILLING.QUANTITY'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'PATIENT_BILLING.ENTER.QUANTITY'})}
              {...formik.getFieldProps('cost_per_head')}
              type='text'
              name='cost_per_head'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
            />
            {formik.touched.cost_per_head && formik.errors.cost_per_head && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cost_per_head}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <PatientBillingListLoading />}
    </>
  )
}

export {PatientBillingEditModalForm}
