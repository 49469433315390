import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ShiftListHeader} from './components/header/ShiftListHeader'
import {ShiftTable} from './table/ShiftTable'
import {ShiftEditModal} from './Shift-edit-modal/ShiftEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const AssetList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <ShiftListHeader />
        <ShiftTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ShiftEditModal />}
    </>
  )
}

const AssetListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AssetList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AssetListWrapper}
