import axios, { AxiosResponse } from 'axios'
import {  Response } from '../../../../_jmh/helpers'
import { PatientInsurance } from '../../patient-insurance/PatientInsuranceList/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const APPOINTMENT_URL = `${API_URL}/patient-appointment/patients`
const PATIENT_INSURANCE_URL = `${API_URL}/patient-insurance`
const MEDICATION_URL = `${API_URL}/patient-medication`
const MEDICAL_NOTES_URL = `${API_URL}/patient-medical-notes`
const PATIENT_VITAL = `${API_URL}/patient-vitals`
const PATIENT_MEDICAL_BACK = `${API_URL}/patient-medical-background`
const PATIENT_URL = `${API_URL}/patient/documents`

const getAppointmentById = (id: number,pageNum:number,searchParam:string) => {
  return axios
    .get(`${APPOINTMENT_URL}/${id}?limit=10&page=${pageNum}&search=${searchParam}&order=DESC`)
    .then((response) => response.data)  
}

const getPatientInsuranceById = (searchQuery:string,id: number,pageNum?:number): Promise<PatientInsurance | undefined> => {
  return axios
    .get(`${PATIENT_INSURANCE_URL}/${id}?search=${searchQuery}`)
    .then((response: AxiosResponse<Response<PatientInsurance>>) => response.data)
    .then((response: Response<PatientInsurance>) => response.data)
}

const getPatientInsuranceByInsuranceId = (patient_id: number,ins_id:number): Promise<PatientInsurance | undefined> => {
  return axios
    .get(`${PATIENT_INSURANCE_URL}/${patient_id}/insurance?insurance_id=${ins_id}`)
    .then((response: AxiosResponse<Response<PatientInsurance>>) => response.data)
    .then((response: Response<PatientInsurance>) => response.data)
}


const getPatientMedication = (
  searchQuery:string,
  patient_id: number,
  hosId: number,
  appoint_id: number
) => {
  return (
    axios
      .get(
        `${MEDICATION_URL}?search=${searchQuery ? searchQuery: ''}&${`limit=10&hospital_id=${hosId}&patient_id=${patient_id}&appointment_id=${appoint_id}`
        }`
      )
  )
}

const getPatientMedicalNotes = (
  searchQuery:string,
  id: number,
  hosId: number,
  appoint_id: number
)  => {
  return (
    axios
      .get(
        `${MEDICAL_NOTES_URL}?search=${searchQuery}&sort=date&order=DESC&limit=10&patient_id=${id}&hospital_id=${hosId}&appointment_id=${appoint_id}`
      )
      .then((d => d.data)
  ))
}

const getPatientVitalByPatientId = (patient_id:number,hosId:number,appoint_id: number) => {
  return axios
    // .get(`${PATIENT_VITAL}?patient_id=${id}&${query}`)
    .get(`${PATIENT_VITAL}?limit=10&hospital_id=${hosId}&patient_id=${patient_id}&appointment_id=${appoint_id}`)
    .then((d => d.data))
}

const getMedicalBackground = (patient_id:number,hosId:number) => {
  return axios
    .get(`${PATIENT_MEDICAL_BACK}?${`limit=10&hospital_id=${hosId}&patient_id=${patient_id}`}`)
}

const updateMedicalBackground = (data: any) => {
  return axios
    .patch(`${PATIENT_MEDICAL_BACK}`, data)
}

const getPatientDoc = (id:number,hosId:number,appoint_id: number) => {
  return axios
    .get(`${PATIENT_URL}?limit=10&hospital_id=${hosId}&patient_id=${id}&appointment_id=${appoint_id}`)
    .then((d) => d.data)
}

const getAppointmentByPatientId = (id: number) => {
  return axios
    .get(`${APPOINTMENT_URL}/${id}?order=DESC`)
    .then((response) => response.data)  
}

export {
  getAppointmentById,
  getPatientInsuranceById,
  getPatientInsuranceByInsuranceId,
  getPatientMedication,
  getPatientMedicalNotes,
  getPatientVitalByPatientId,
  getMedicalBackground,
  updateMedicalBackground,
  getPatientDoc,
  getAppointmentByPatientId
}
