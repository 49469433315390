import {useQuery} from 'react-query'
import {AssetMaintenanceEditModalForm} from './AssetMaintenanceEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getAssetMaintenanceById} from '../core/_requests'
import moment from 'moment'
const AssetMaintenanceEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: assetMaintenance,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-assetMaintenance-${itemIdForUpdate}`,
    () => {
      return getAssetMaintenanceById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (assetMaintenance && assetMaintenance['date']) {
    const formatePurchaseDate = moment(assetMaintenance.date).format('DD-MM-YYYY')
    assetMaintenance['date'] = formatePurchaseDate
  }
  if (!itemIdForUpdate) {
    return (
      <AssetMaintenanceEditModalForm
        isAssetLoading={isLoading}
        assetMaintenance={{id: undefined}}
      />
    )
  }
  if (!isLoading && !error && assetMaintenance) {
    return (
      <AssetMaintenanceEditModalForm
        isAssetLoading={isLoading}
        assetMaintenance={assetMaintenance}
      />
    )
  }
  return null
}
export {AssetMaintenanceEditModalFormWrapper}
