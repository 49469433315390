import React, {FC, useState} from 'react'
import {HiOutlineCurrencyRupee} from 'react-icons/hi'
import {IoDocumentTextOutline} from 'react-icons/io5'
import {Appointment} from '../../core/_models'
import {
  getDocumentsAppointment,
  getInvoiceAppointment,
} from '../../../../doctor-all-information/Infolist/core/_requests'
import {DocInfoLoading} from '../../../../doctor-all-information/Infolist/components/loading/DocInfoLoading'
import {useIntl} from 'react-intl'

type Props = {
  user: Appointment
}

const UserQuickAction: FC<Props> = ({user}) => {
  const intl = useIntl()
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const handleviewpdf = async (id: any) => {
    try {
      setLoadingPdf(true) // Show loader
      const response = await getInvoiceAppointment(id)
      const pdfData = new Blob([response.data], {type: 'application/pdf'})
      const url = URL.createObjectURL(pdfData)
      setPdfUrl(url)
      setShowModal(true)
    } catch (error) {
      console.error('Error fetching PDF:', error)
    } finally {
      setLoadingPdf(false) // Hide loader
    }
  }

  const handleviewdocument = async (id: any) => {
    try {
      setLoadingPdf(true) // Show loader
      const response = await getDocumentsAppointment(id)
      const pdfData = new Blob([response.data], {type: 'application/pdf'})
      const url = URL.createObjectURL(pdfData)
      setPdfUrl(url)
      setShowModal(true)
    } catch (error) {
      console.error('Error fetching PDF:', error)
    } finally {
      setLoadingPdf(false) // Hide loader
    }
  }

  return (
    <>
      <div>
        <IoDocumentTextOutline
          style={{height: '26px', width: '26px', marginLeft: '5px'}}
          className={`document_icon cursor-pointer ${
            user.status === 'COMPLETED' || user.status === 'CANCELLED' ? '' : 'disabled'
          }`}
          onClick={() => {
            handleviewdocument(user.id)
          }}
        />
        <HiOutlineCurrencyRupee
          style={{height: '26px', width: '26px'}}
          className={`rupee_icon cursor-pointer ${
            user.status === 'COMPLETED' || user.status === 'CANCELLED' ? '' : 'disabled'
          }`}
          onClick={() => {
            handleviewpdf(user.id)
          }}
        />
      </div>

      {loadingPdf && <DocInfoLoading />}
      {showModal && (
        <>
          <div className='modal-backdrop fade show'></div>
          <div
            className='modal fade show'
            style={{display: 'block'}}
            aria-labelledby='staticBackdropLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content' style={{width: '130%', marginLeft: '-12%'}}>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropLabel'>
                    {intl.formatMessage({id: 'PDF_PREVIEW'})}
                  </h5>
                  <button
                    type='button'
                    className='btn-close'
                    onClick={() => setShowModal(false)}
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body'>
                  {pdfUrl && (
                    <embed src={pdfUrl} type='application/pdf' width='100%' height='500px' />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default UserQuickAction
