import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Appointment, initialAppointment} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {getDoctorDataAva, updateAppointment} from '../core/_requests'
import {AppointmentsListLoading} from '../components/loading/AppointmentsListLoading'
import Select from 'react-select'
import {EditLater} from './EditLater'
import {loginContext} from '../../../../context/context'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: Appointment
}

function mapDataToNodesDoctor(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({
      value: item?.doctor?.full_name,
      label: item?.doctor?.full_name,
      id: item?.doctor?.id,
    })
  })

  return parent
}

const EditAppoinmentData: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [doctorData, setDoctorData] = useState([])
  const {tabSelection, setTabSelection, selectedHospitalId} = useContext(loginContext)

  const [userForEdit] = useState<Appointment>({
    ...user,
    patient_id: user.patient_id || initialAppointment.patient_id,
    date: user.date || initialAppointment.date,
    slot: user.slot || initialAppointment.slot,
    description: user.description || initialAppointment.description,
    doctor_id: user?.doctor_id || initialAppointment.doctor_id,
    full_name: user?.patient?.full_name || initialAppointment.patient?.full_name,
    gender: user?.patient?.gender || initialAppointment.patient?.gender,
    contact_number: user?.patient?.contact_number || initialAppointment.patient?.contact_number,
    alternate_contact_number:
      user?.patient?.alternate_contact_number ||
      initialAppointment.patient?.alternate_contact_number,
    date_of_birth: user?.patient?.date_of_birth || initialAppointment?.patient?.date_of_birth,
    email: user?.patient?.email || initialAppointment?.patient?.email,
    blood_group: user?.patient?.blood_group || initialAppointment?.patient?.blood_group,
    status: user?.patient?.status || initialAppointment?.patient?.status,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  let editUserSchema
  editUserSchema = Yup.object().shape({
    // patient_id: Yup.string().required('Please select Patient'),
    // doctor_id: Yup.string().required('Please select Doctor'),
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      //   delete values['appointment_details']
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateAppointment(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        toast.warning(ex.response?.data?.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const fetchDoctor = async () => {
    const allDoctorData: any = await getDoctorDataAva(selectedHospitalId)

    const uniqueAppointments = Object.values(
      allDoctorData?.data?.data.reduce((acc: any, appointment: any) => {
        if (!acc[appointment.doctor_id]) {
          acc[appointment.doctor_id] = appointment
        }
        return acc
      }, {})
    )

    const activeDoctorData: any = uniqueAppointments?.filter((item: any) => {
      return item?.hospital_id == selectedHospitalId
    })
    if (allDoctorData) {
      setDoctorData(activeDoctorData)
    }
    // const allDoctorData: any = await getDoctors()
    // setDoctorData(allDoctorData?.data)
  }

  useEffect(() => {
    fetchDoctor()
  }, [])

  const handleDoctorChange = (selectedDoctor: any) => {
    formik.setFieldValue('doctor_id', Number(selectedDoctor.id))
    setSelectedDoctor(selectedDoctor)
  }

  const optionsDoctor: any = mapDataToNodesDoctor(doctorData)

  const handleTabChange = (value: any) => {
    if (value === 'NOW') {
      if (!isLaterTabDisabled) {
        setTabSelection(value)
      }
    } else if (value === 'LATER') {
      if (!isNowTabDisabled) {
        setTabSelection(value)
      }
    }
  }

  const matchingAppointment = user?.appointment_details?.find((item: any) => {
    const matchingDoctor =
      doctorData && doctorData?.find((doctor: any) => item?.doctor_id === doctor?.doctor?.id)
    return matchingDoctor !== undefined
  })

  const matchingDoctor: any = doctorData.find(
    (doctor: any) => matchingAppointment?.doctor_id === doctor?.doctor?.id
  )

  const filteredData =
    matchingDoctor !== undefined
      ? {
          id: matchingDoctor?.doctor?.id,
          full_name: matchingDoctor?.doctor?.full_name,
          label: matchingDoctor?.doctor?.full_name,
        }
      : {}

  useEffect(() => {
    if (user.type === 'NOW') {
      setTabSelection('NOW')
    } else if (user.type === 'LATER') {
      setTabSelection('LATER')
    }
  }, [user.type, setTabSelection])

  const isNowTabDisabled = user.type === 'LATER'
  const isLaterTabDisabled = user.type === 'NOW'

  return (
    <>
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 border-0'>
        <li className='nav-item'>
          {!isNowTabDisabled ? (
            <a
              className={`nav-link ${tabSelection === 'NOW' ? 'active' : ''}`}
              data-bs-toggle='tab'
              href='#kt_tab_pane_1'
              onClick={() => handleTabChange('NOW')}
            >
              {intl.formatMessage({id: 'GENERAL.NOW'})}
            </a>
          ) : (
            <span className='nav-link disabled'>{intl.formatMessage({id: 'GENERAL.NOW'})}</span>
          )}
        </li>
        <li className='nav-item'>
          {!isLaterTabDisabled ? (
            <a
              className={`nav-link ${tabSelection === 'LATER' ? 'active' : ''}`}
              data-bs-toggle='tab'
              href='#kt_tab_pane_2'
              onClick={() => handleTabChange('LATER')}
            >
              {intl.formatMessage({id: 'GENERAL.LATER'})}
            </a>
          ) : (
            <span className='nav-link disabled'>{intl.formatMessage({id: 'GENERAL.LATER'})}</span>
          )}
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div
          className={`tab-pane fade ${tabSelection === 'NOW' ? 'show active' : ''}`}
          id='kt_tab_pane_1'
          role='tabpanel'
        >
          {tabSelection === 'NOW' && (
            <>
              <form
                id='kt_modal_add_user_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className='d-flex flex-column  me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'PATIENT_APPOINTMENT.DOCTORS'})}
                    </label>
                    <Select
                      value={selectedDoctor ? selectedDoctor : filteredData}
                      onChange={handleDoctorChange}
                      options={optionsDoctor ? optionsDoctor : filteredData}
                      placeholder={intl.formatMessage({id: 'DOCTOR.APPOINTMENT.SELECT.DOCTOR'})}
                    />
                    {formik.touched.doctor_id && formik.errors.doctor_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.doctor_id}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'PATIENT_APPOINTMENT.APPOINTMENT.DESCRIPTION'})}
                    </label>

                    <textarea
                      {...formik.getFieldProps('description')}
                      className='form-control'
                      name='description'
                      id='exampleFormControlTextarea1'
                      disabled={formik.isSubmitting || isUserLoading}
                      rows={3}
                      placeholder={intl.formatMessage({
                        id: 'PATIENT_APPOINTMENT.APPOINTMENT.DESCRIPTION',
                      })}
                    ></textarea>
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => cancel()}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={formik.isSubmitting || isUserLoading}
                  >
                    {intl.formatMessage({id: 'GENERAL.CLOSE'})}
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={
                      isUserLoading ||
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !formik.touched ||
                      !formik.dirty
                    }
                  >
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'GENERAL.SAVE'})}
                    </span>
                    {(formik.isSubmitting || isUserLoading) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
              {(formik.isSubmitting || isUserLoading) && <AppointmentsListLoading />}
            </>
          )}
        </div>
        <div
          className={`tab-pane fade ${tabSelection === 'LATER' ? 'show active' : ''}`}
          id='kt_tab_pane_2'
          role='tabpanel'
        >
          {/* dfgdfg */}
          {tabSelection === 'LATER' ? <EditLater isUserLoading={false} user={user} /> : ''}
        </div>
      </div>
    </>
  )
}

export {EditAppoinmentData}
