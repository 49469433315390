// @ts-nocheck
import {Column} from 'react-table'
import {PatientBillingFullnameCell} from './PatientBillingFullnameCell'
import {PatientBillingActionsCell} from './PatientBillingActionsCell'
import {PatientBillingCustomHeader} from './PatientBillingCustomHeader'
import {PatientBiling} from '../../core/_models'
import {PatientBillNumberCell} from './PatientBillNumberCell'
import {PatientBillingDateCell} from './PatientBillingDateCell'
import {PatientBillingStatus} from './PatientBillingStatus'
import {PatientBillingModeOfPayment} from './PatientBillingModeOfPayment'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<PatientBiling>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientBillingCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'patient',
    Cell: ({...props}) => <PatientBillingFullnameCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientBillingCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.BILL.NUMBER'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'bill_number',
    Cell: ({...props}) => <PatientBillNumberCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientBillingCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.BILLING.DATE'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'billing_date',
    Cell: ({...props}) => <PatientBillingDateCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientBillingCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.MODE.OF.PAYMENT'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'payment_method',
    Cell: ({...props}) => <PatientBillingModeOfPayment user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientBillingCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.BILLING.STATUS'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'status',
    Cell: ({...props}) => <PatientBillingStatus user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'patient-billing') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <PatientBillingCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px pe-5'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'patient-billing') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? (
        <PatientBillingActionsCell
          user={props.data[props.row.index]}
          id={props.data[props.row.index].id}
        />
      ) : null
    },
  },
]

export {usersColumns}
