/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AssetModel} from '../../core/_models'

type Props = {
  user: AssetModel
}

const AssetInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <div className='text-gray-800 mb-1'>{user?.name}</div>
    </div>
  </div>
)

export {AssetInfoCell}
