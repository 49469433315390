import React, {useContext, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../core/Auth'
import {changePassword} from '../../core/_requests'
import {loginContext} from '../../../../context/context'

interface PatientChangePasswordProps {
  onSubmit: () => void // Add this line
}

interface ChangePasswordFormValues {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const initialValues: ChangePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

export const PatientChangePassword: React.FC<PatientChangePasswordProps> = ({onSubmit}) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShownewPassword] = useState(false)
  const [showconfirmPassword, setShowconfirmPassword] = useState(false)
  const {setLoginData} = useContext(loginContext)
  let navigate = useNavigate()
  const {setCurrentUser} = useAuth()

  const handleOldPassword = () => {
    setShowOldPassword(!showOldPassword)
  }

  const handleNewPassword = () => {
    setShownewPassword(!showNewPassword)
  }

  const handleConfirmPassword = () => {
    setShowconfirmPassword(!showconfirmPassword)
  }

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required(intl.formatMessage({id: 'VALIDATION.CURRENT_PASSWORD'})),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        intl.formatMessage({id: 'VALIDATION.NEW_PASSWORD'})
      )
      .required(intl.formatMessage({id: 'VALIDATION.REQ.NEW_PASSWORD'})),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({id: 'VALIDATION.PASSWORD_MATCHED'})
      )
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED.CONFIRM_PASSWORD'})),
  })

  const tokenForChangePassword: any = localStorage.getItem('kt-auth-react-v')
  const token = JSON.parse(tokenForChangePassword)

  useEffect(() => {
    if (!token) {
      navigate('/')
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        await changePassword(
          values.oldPassword,
          values.newPassword,
          values.confirmPassword,
          token?.access_token
        ).then((res: any) => {
          const getLocalKey: any = localStorage.getItem('kt-auth-react-v')
          const responseLocalSorage = JSON.parse(getLocalKey)
          responseLocalSorage.isPasswordChange = false
          localStorage.setItem('kt-auth-react-v', JSON.stringify(responseLocalSorage))
          const authDataString: string | null = localStorage?.getItem('loginData')

          if (authDataString !== null) {
            const authData: any = JSON.parse(authDataString)
            setCurrentUser(authData?.data?.access_token)
            setLoginData(authData)
          }

          toast.success(res?.data?.message)
          setLoading(false)
          setSubmitting(false)
          resetForm()
          navigate('/profile')
        })
      } catch (error: any) {
        setStatus(error?.response?.data?.message)
        setSubmitting(false)
        setLoading(false)
      }
      onSubmit()
    },
  })

  return (
    <>
      <div className='row'>
        <div className='col-12 p-0'>
          <form
            className='form w-100'
            style={{margin: '0 auto'}}
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {formik.status ? (
              <div className='mb-10 alert alert-danger mx-5'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              ''
            )}

            <div className='input-group mb-3'>
              <label className='form-label fs-6 fw-bolder w-100'>
                {intl.formatMessage({id: 'GENERAL.CURRENT_PASSWORD'})}
              </label>

              <div className='position-relative w-100'>
                <input
                  type={showOldPassword ? 'text' : 'password'}
                  autoComplete='off'
                  {...formik.getFieldProps('oldPassword')}
                  className={clsx('form-control form-control-lg form-control-solid pe-15')}
                  placeholder={intl.formatMessage({id: 'GENERAL.CURRENT_PASSWORD'})}
                />

                <button
                  className='btn position-absolute top-0 end-0'
                  type='button'
                  id='show-password'
                  onClick={handleOldPassword}
                >
                  {showOldPassword ? (
                    <i className='bi bi-eye-fill'></i>
                  ) : (
                    <i className='bi bi-eye-slash-fill'></i>
                  )}
                </button>

                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.oldPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='input-group mb-3'>
              <label className='form-label fw-bolder fs-6 mb-0 w-100'>
                {intl.formatMessage({id: 'GENERAL.NEW_PASSWORD'})}
              </label>
              <div className='position-relative w-100'>
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  autoComplete='off'
                  {...formik.getFieldProps('newPassword')}
                  className={clsx('form-control form-control-lg form-control-solid pe-15')}
                  placeholder={intl.formatMessage({id: 'GENERAL.NEW_PASSWORD'})}
                />

                <button
                  className='btn position-absolute top-0 end-0'
                  type='button'
                  id='show-password'
                  onClick={handleNewPassword}
                >
                  {showNewPassword ? (
                    <i className='bi bi-eye-fill'></i>
                  ) : (
                    <i className='bi bi-eye-slash-fill'></i>
                  )}
                </button>

                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.newPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='input-group mb-3'>
              <label className='form-label fw-bolder fs-6 mb-0 w-100'>
                {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
              </label>

              <div className='position-relative w-100'>
                <input
                  type={showconfirmPassword ? 'text' : 'password'}
                  autoComplete='off'
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx('form-control form-control-lg form-control-solid pe-15')}
                  placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
                />

                <button
                  className='btn position-absolute top-0 end-0'
                  type='button'
                  id='show-password'
                  onClick={handleConfirmPassword}
                >
                  {showconfirmPassword ? (
                    <i className='bi bi-eye-fill'></i>
                  ) : (
                    <i className='bi bi-eye-slash-fill'></i>
                  )}
                </button>

                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirmPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='input-group text-center w-100 justify-content-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'GENERAL.SAVE'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
