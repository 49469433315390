import { ID, Response } from "../../../../../_jmh/helpers"

export type AssignInv = {
  id?: ID
  name?: string
  assigned_quantity?: number
  description?: string
  created_at?: string
  updated_at?: string
  hospital_id?: {
    id: string,
    name: string,
    address: string,
    contact_person: string,
    contact_number: number,
    status: string,
  } | number
  initials?: {
    label: string
    state: string
  }
  room_id? : {
    id ?: string,
    room_number?: string,
    type?: string
    description?: string
    cost?: number
    maximum_capacity?: number
    availability_status?: string
    status?: string
  } | number
  inventory_id?: {
    id: string,
    name: string,
    description: string
    available_quantity: number,
    assigned_quantity: number,
    minimum_quantity: number,
    maximum_quantity: number, 
  } | number
}

export type AssignInvQueryResponse = Response<Array<AssignInv>>

export const initialUser: AssignInv = {
    name: '',
    // room_id:{
    //   id: ''
    // }
    // assigned_quantity : 0,
}
