import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {AssetMaintenanceModel, AssetQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ASSETMAINTENANCE_URL = `${API_URL}/asset-maintenance`
const GET_ASSETMAINTENANCE_URL = `${API_URL}/asset-maintenance`
const ASSETNAME_URL = `${API_URL}/asset`

const getAssetMaintenance = (query: string,id:number): Promise<AssetQueryResponse> => {
  return axios
    .get(`${GET_ASSETMAINTENANCE_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<AssetQueryResponse>) => d.data)
}

const getAssetMaintenanceById = (id: ID): Promise<AssetMaintenanceModel | undefined> => {
  return axios
    .get(`${ASSETMAINTENANCE_URL}/${id}`)
    .then((response: AxiosResponse<Response<AssetMaintenanceModel>>) => response.data)
    .then((response: Response<AssetMaintenanceModel>) => response.data)
}

const getAssetMaintenanceReport = (id: ID): Promise<AssetMaintenanceModel | undefined> => {
  
  return axios
    .get(`${ASSETMAINTENANCE_URL}/csv?hospital_id=${id}`)
    .then((response: AxiosResponse<Response<AssetMaintenanceModel>>) => response.data)
    .then((response: Response<AssetMaintenanceModel>) => response.data)
}

const createAssetMaintenance = (assetMaintenance: any): Promise<AssetMaintenanceModel | undefined> => {
  return axios
    .post(ASSETMAINTENANCE_URL, assetMaintenance)
}

const updateAssetMaintenance = (assetMaintenance: any): Promise<AssetMaintenanceModel | undefined> => {
  return axios
    .patch(`${ASSETMAINTENANCE_URL}/${assetMaintenance.id}`, assetMaintenance)
}

const deleteAssetMaintenance = (assetId: ID): Promise<void> => {
  return axios.delete(`${ASSETMAINTENANCE_URL}/${assetId}`).then(() => {})
}

const deleteSelectedAssetMaintenance = (assetIds: Array<ID>): Promise<void> => {
  const requests = assetIds.map((id) => axios.delete(`${ASSETMAINTENANCE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const getAsset = (): Promise<AssetQueryResponse> => {
  return axios
    .get(`${ASSETNAME_URL}`)
    .then((d: AxiosResponse<AssetQueryResponse>) => d.data)
}

export {getAssetMaintenance,getAsset, deleteAssetMaintenance,getAssetMaintenanceReport, deleteSelectedAssetMaintenance, getAssetMaintenanceById, createAssetMaintenance, updateAssetMaintenance}
