import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTSVG, isNotEmpty} from '../../../../../_jmh/helpers'
import {initialUser, Medication} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {MedicationListLoading} from '../components/loading/MedicationListLoading'
import {
  createPatientMedication,
  getMedicine,
  getMedicineById,
  updatePatientMedication,
} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import {loginContext} from '../../../../context/context'
import {useParams} from 'react-router-dom'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: Medication
}

// Static Values of Buttons
const defaultFrequencies = ['1-1-0', '1-0-0', '1-1-1', '0-0-1', '5ml-0-5ml', '2.5ml-0-2.5ml']
const defaultDuration = [1, 3, 5, 7, 9, 15, 30]
const defaultInstruction = ['Before meal', 'After meal']

const editUserSchema = Yup.object().shape({})

const MedicationEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [assignDate, setAssignDate] = useState<Date | null>(null)
  const {selectedPatientId, selectedHospitalId, selectedAppointment} = useContext(loginContext)
  const {id} = useParams()
  const [medicineData, setMedicineData] = useState([])
  const [activeInstruction, setActiveInstruction] = useState<any>(null)
  const [activeInstructionValue, setActiveInstructionValue] = useState<any>(user?.instruction)
  const [activeFrequency, setActiveFrequency] = useState<any>(null)
  const [activeFrequencyValue, setActiveFrequencyValue] = useState<any>(user?.frequency)
  const [activeDuration, setActiveDuration] = useState<any>(null)
  const [activeDurationValue, setActiveDurationValue] = useState<any>(user?.duration)
  const [addItemLength, setAddItemLength] = useState(0)
  const [checkFilled, setCheckFilled] = useState(false)

  const [medication_data, setMedicationdata] = useState<any>([
    {medicine_id: '', frequency: '', duration: null, assigned_date: '', instruction: '', type: ''},
  ])
  const [fieldErrors, setFieldErrors] = useState<Array<{[key: string]: string}>>([{}])

  const [userForEdit] = useState<Medication>({
    ...user,
    medication_name: user.medication_name || initialUser.medication_name,
    medicine_id: user.medicine_id || initialUser.medicine_id,
    frequency: user.frequency || initialUser.frequency,
    duration: user.duration || initialUser.duration,
    assigned_date: user.assigned_date || initialUser.assigned_date,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      if (!validateForm()) {
        setSubmitting(false)
        return
      } else {
        try {
          if (isNotEmpty(values.id)) {
            delete values?.medicine_id
            delete values?.medication_data

            values['frequency'] = medication_data?.[0]?.frequency || user?.frequency
            values['duration'] = Number(medication_data?.[0]?.duration) || user?.duration
            values['instruction'] = medication_data?.[0]?.instruction

            await updatePatientMedication(values).then((res: any) => {
              toast.success(res?.data?.message)
            })
          } else {
            delete values?.assigned_date
            delete values?.duration
            delete values?.frequency
            delete values?.instruction
            delete values?.medication_name
            values['medication_data'] = medication_data
            await createPatientMedication({
              ...values,
              hospital_id: Number(selectedHospitalId),
              patient_id: Number(selectedPatientId ? selectedPatientId : id),
              appointment_id: selectedAppointment
                ? selectedAppointment?.value === 'all'
                  ? ''
                  : Number(selectedAppointment?.value)
                : '',
            }).then((res: any) => {
              toast.success(res?.data?.message)
            })
          }
        } catch (ex: any) {
          if (ex.response.data.message) {
            toast.error(ex.response.data.message)
          }
        } finally {
          setSubmitting(true)
          cancel(true)
        }
      }
    },
  })
  const past_date_disable = new Date(moment().format('YYYY-MM-DD'))

  const fetchMedicine = async () => {
    const getMedicineList: any = await getMedicine()
    setMedicineData(getMedicineList?.data)
  }

  useEffect(() => {
    fetchMedicine()
  }, [])

  function mapDataToNodesMedicine(data: any) {
    let parent: any = []
    data?.map((item: any) => {
      parent.push({value: item?.name, label: item?.name, id: item?.id, type: item?.type})
    })

    return parent
  }

  const optionsMedicines: any = mapDataToNodesMedicine(medicineData)

  const options = optionsMedicines.map((medicine: any) => ({
    value: medicine.value,
    label: `${medicine.label} (${medicine.type})`,
    id: medicine.id,
    type: medicine.type,
  }))

  const handleMedicineChange = async (value: any, index: number) => {
    const getMedicineIdData: any = await getMedicineById(value.id)

    const updatedItems = [...medication_data]
    updatedItems[index].medicine_id = getMedicineIdData?.id
    updatedItems[index].frequency = getMedicineIdData?.frequency
    updatedItems[index].duration = getMedicineIdData?.duration
    updatedItems[index].instruction = getMedicineIdData?.instruction
    updatedItems[index].medication_name = getMedicineIdData?.name
    updatedItems[index].type = getMedicineIdData?.type

    setMedicationdata(updatedItems)

    formik.setFieldValue(`medication_data[${index}].frequency`, getMedicineIdData?.frequency)
    formik.setFieldValue(`medication_data[${index}].duration`, getMedicineIdData?.duration)
    formik.setFieldValue(`medication_data[${index}].instruction`, getMedicineIdData?.instruction)

    if (!medication_data[index].medicine_id && !formik.values?.medicine_id?.id) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          medicine_id: intl.formatMessage({id: 'VALIDATION.REQ.MEDICATION_NAME'}),
        },
      }))
    } else {
      setFieldErrors((prevErrors) => {
        const errors = {...prevErrors}
        delete errors[index]?.medicine_id
        return errors
      })
      setFieldErrors((prevErrors) => {
        const errors = {...prevErrors}
        delete errors[index]?.frequency
        return errors
      })
      setFieldErrors((prevErrors) => {
        const errors = {...prevErrors}
        delete errors[index]?.duration
        return errors
      })
    }
  }

  const handleMedicineBlur = (value: string, index: number) => {
    if (!medication_data[index].medicine_id && !formik.values?.medicine_id?.id) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          medicine_id: intl.formatMessage({id: 'VALIDATION.REQ.MEDICATION_NAME'}),
        },
      }))
    } else {
      setFieldErrors((prevErrors) => {
        const errors = {...prevErrors}
        delete errors[index]?.medicine_id
        return errors
      })
    }
  }

  const handleFrequencyChange = (frequency: string, index: number) => {
    const regex = /^(\d+(?:\.\d+)?[a-zA-Z]*-){2}\d+(?:\.\d+)?[a-zA-Z]*$/
    const updatedItems = [...medication_data]
    updatedItems[index].frequency = frequency
    setMedicationdata(updatedItems)

    setActiveFrequency(frequency)
    setActiveFrequencyValue(frequency)
    formik.setFieldValue(`medication_data[${index}].frequency`, frequency)

    if (!medication_data[index].frequency) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          frequency: intl.formatMessage({id: 'VALIDATION.REQ.FREQUENCY'}),
        },
      }))
    } else if (!frequency.match(regex)) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          frequency: intl.formatMessage({id: 'VALIDATION.INVALID.FREQUENCY'}),
        },
      }))
    } else {
      setFieldErrors((prevErrors) => {
        const errors = {...prevErrors}
        delete errors[index]?.frequency
        return errors
      })
    }
  }

  const handleDurationChange = (duration: number, index: number) => {
    const updatedItems = [...medication_data]
    updatedItems[index].duration = duration
    setMedicationdata(updatedItems)

    setActiveDuration(duration)
    setActiveDurationValue(duration)
    formik.setFieldValue(`medication_data[${index}].duration`, duration)

    if (!medication_data[index].duration && !duration) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          duration: intl.formatMessage({id: 'VALIDATION.REQ.DURATION'}),
        },
      }))
    } else {
      setFieldErrors((prevErrors) => {
        const errors = {...prevErrors}
        delete errors[index]?.duration
        return errors
      })
    }
  }

  const handleInstructionChange = (instruction: string, index: number) => {
    const updatedItems = [...medication_data]
    updatedItems[index].instruction = instruction
    setMedicationdata(updatedItems)

    setActiveInstruction(instruction)
    setActiveInstructionValue(instruction)
    formik.setFieldValue(`medication_data[${index}].instruction`, instruction)
  }

  const addItem = () => {
    if (!checkFilled) {
      return
    }

    const newItem = {
      medicine_id: '',
      frequency: '',
      duration: null,
      assigned_date: '',
      instruction: '',
      type: '',
    }

    setMedicationdata([...medication_data, newItem])

    setAddItemLength(addItemLength + 1)

    formik.setFieldValue(`medication_data[${medication_data.length}].medicine_id`, '')
    formik.setFieldValue(`medication_data[${medication_data.length}].frequency`, '')
    formik.setFieldValue(`medication_data[${medication_data.length}].duration`, null)
    formik.setFieldValue(`medication_data[${medication_data.length}].assigned_date`, '')
    formik.setFieldValue(`medication_data[${medication_data.length}].instruction`, '')

    setFieldErrors((prevErrors) => {
      const errors = {...prevErrors}
      errors[medication_data.length] = {}
      return errors
    })
  }

  const validateForm = () => {
    let isValid = true
    medication_data.forEach((item: any, index: any) => {
      if (!item.medicine_id && !formik.values?.medicine_id?.id) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            medicine_id: intl.formatMessage({id: 'VALIDATION.REQ.MEDICATION_NAME'}),
          },
        }))
        isValid = false
      }
      if (!item.frequency && !formik.values?.frequency) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            frequency: intl.formatMessage({id: 'VALIDATION.REQ.FREQUENCY'}),
          },
        }))
        isValid = false
      }
      if (!item.duration && !formik.values?.duration) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            duration: intl.formatMessage({id: 'VALIDATION.REQ.DURATION'}),
          },
        }))
        isValid = false
      }
      if (!item.assigned_date && !formik.values?.assigned_date) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            assigned_date: intl.formatMessage({id: 'VALIDATION.REQ.ASSIGN_DATE'}),
          },
        }))
        isValid = false
      }
    })
    return isValid
  }

  const handleDelete = (index: any, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const updatedItems = [...medication_data]
    updatedItems.splice(index, 1)

    formik.setFieldValue(`medication_data[${index}].medicine_id`, '')
    formik.setFieldValue(`medication_data[${index}].frequency`, '')
    formik.setFieldValue(`medication_data[${index}].duration`, null)
    formik.setFieldValue(`medication_data[${index}].assigned_date`, '')
    formik.setFieldValue(`medication_data[${index}].instruction`, '')

    setMedicationdata(updatedItems)

    setAddItemLength(addItemLength - 1)

    setFieldErrors((prevErrors) => {
      const updatedErrors: any = {...prevErrors}
      delete updatedErrors[index.toString()]

      Object.keys(updatedErrors).forEach((key) => {
        const currIndex = parseInt(key, 10)
        if (currIndex > index) {
          updatedErrors[(currIndex - 1).toString()] = updatedErrors[key]
          delete updatedErrors[key]
        }
      })

      return updatedErrors
    })
  }

  const handleSubmit = () => {
    if (!validateForm()) {
      return
    }
    formik.submitForm()
  }

  const hasErrors = Object.values(fieldErrors).some((error) => Object.keys(error).length > 0)

  const isFieldsFilled = (index: any) => {
    const medicationItem = medication_data[index]
    return (
      medicationItem.medicine_id &&
      medicationItem.frequency &&
      medicationItem.duration &&
      medicationItem.assigned_date
    )
  }

  useEffect(() => {
    const allItemsFilled = medication_data.every((item: any, index: any) => isFieldsFilled(index))
    setCheckFilled(allItemsFilled)
  }, [medication_data])

  const isButtonEnabled = medication_data.every((_: any, index: any) => isFieldsFilled(index))

  return (
    <>
      <form id='kt_modal_add_user_form5' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='main-container'>
            {medication_data &&
              medication_data?.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    <div className='row align-items-center'>
                      <div className='fv-row mb-10 col-6 ps-0'>
                        <label className='required fw-bold fs-6 mb-2'>
                          {intl.formatMessage({id: 'GENERAL.MEDICATION.NAME'})}
                        </label>
                        <Select
                          value={
                            user?.medicine_id?.id
                              ? {
                                  value: user.medicine_id.id,
                                  label: `${user.medicine_id.name} (${user?.medicine_id?.type})`,
                                }
                              : item?.medication_name && item?.type
                              ? {
                                  value: item?.medicine_id,
                                  label: `${item.medication_name} (${item?.type})`,
                                }
                              : null
                          }
                          onChange={(e) => {
                            handleMedicineChange(e, index)
                          }}
                          onBlur={() => handleMedicineBlur(item.medicine_id, index)}
                          options={options}
                          placeholder={intl.formatMessage({id: 'MEDICATION.SELECT.MEDICINE'})}
                          isDisabled={itemIdForUpdate ? true : false}
                          styles={{
                            dropdownIndicator: (provided) => ({...provided, minHeight: '40px'}),
                          }}
                        />
                        {fieldErrors[index]?.medicine_id && (
                          <div className='fv-help-block1 position-absolute'>
                            {fieldErrors[index]?.medicine_id}
                          </div>
                        )}
                      </div>

                      {itemIdForUpdate ? (
                        ''
                      ) : (
                        <>
                          <div className='fv-row mb-10 col-5'>
                            <label className='required fw-bold fs-6 mb-2'>
                              {intl.formatMessage({id: 'MEDICATION.ASSIGN.DATE'})}
                            </label>

                            <div className='position-relative'>
                              <ReactDatePicker
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                {...formik.getFieldProps(`medication_data[${index}].assigned_date`)}
                                dropdownMode='select'
                                className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                                dateFormat='dd-MM-yyyy'
                                name={`medication_data[${index}].assigned_date`}
                                minDate={past_date_disable}
                                selected={medication_data[index].assignDate}
                                onChange={(date) => {
                                  if (date === null) {
                                    setFieldErrors((prevErrors) => ({
                                      ...prevErrors,
                                      [index]: {
                                        ...prevErrors[index],
                                        assigned_date: intl.formatMessage({
                                          id: 'VALIDATION.REQ.ASSIGN_DATE',
                                        }),
                                      },
                                    }))
                                  } else {
                                    formik.setFieldValue(
                                      `medication_data[${index}].assigned_date`,
                                      moment(date).format('DD-MM-YYYY')
                                    )
                                    setAssignDate(date)
                                    setFieldErrors((prevErrors) => {
                                      const errors = {...prevErrors}
                                      delete errors[index]?.assigned_date
                                      return errors
                                    })
                                  }

                                  const updatedItems = [...medication_data]
                                  updatedItems[index].assigned_date =
                                    moment(date).format('DD-MM-YYYY')
                                  setMedicationdata(updatedItems)
                                }}
                                onBlur={() => {
                                  if (
                                    !formik.values.medication_data ||
                                    !formik.values.medication_data[index]?.assigned_date
                                  ) {
                                    setFieldErrors((prevErrors) => ({
                                      ...prevErrors,
                                      [index]: {
                                        ...prevErrors[index],
                                        assigned_date: intl.formatMessage({
                                          id: 'VALIDATION.REQ.ASSIGN_DATE',
                                        }),
                                      },
                                    }))
                                  } else {
                                    setFieldErrors((prevErrors) => {
                                      const errors = {...prevErrors}
                                      delete errors[index]?.assigned_date
                                      return errors
                                    })
                                  }
                                }}
                                autoComplete='off'
                                disabled={formik.isSubmitting || isUserLoading}
                                placeholderText={intl.formatMessage({id: 'MEDICATION.ASSIGN.DATE'})}
                              />

                              <span
                                className='DatePicker_icon cursor-pointer'
                                onClick={() =>
                                  document
                                    .getElementsByName(`medication_data[${index}].assigned_date`)[0]
                                    .focus()
                                }
                              >
                                <div className='fa fa-calendar'></div>
                              </span>
                            </div>

                            {fieldErrors[index]?.assigned_date && (
                              <div className='fv-help-block1 position-absolute'>
                                {fieldErrors[index]?.assigned_date}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {itemIdForUpdate ? (
                        ''
                      ) : (
                        <div className='col-1'>
                          <button
                            className='costperhead_delete btn btn-light-danger btn-active-danger btn-sm px-4 py-3'
                            style={{minHeight: '43px', marginTop: '-5px'}}
                            onClick={(e) => handleDelete(index, e)}
                            disabled={addItemLength === 0 ? true : false}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-muted m-0 fs-3'
                            />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-7'>
                      <label
                        htmlFor={`frequency-${index}`}
                        className='required fw-bold fs-6 mt-3 form-label'
                      >
                        {intl.formatMessage({id: 'GENERAL.FREQUENCY'})}
                      </label>
                      <div className='d-flex'>
                        <div className='d-flex flex-wrap gap-2'>
                          {defaultFrequencies.map((item2) => {
                            return (
                              <button
                                key={item2}
                                type='button'
                                className={clsx(
                                  'btn btn-light-primary btn-active-primary rounded-pill btn-sm',
                                  itemIdForUpdate
                                    ? defaultFrequencies.includes(activeFrequencyValue) &&
                                        item2 === activeFrequencyValue &&
                                        'active'
                                    : defaultFrequencies.includes(item.frequency) &&
                                        item2 === item.frequency &&
                                        'active'
                                )}
                                onClick={() => handleFrequencyChange(item2, index)}
                              >
                                {item2}
                              </button>
                            )
                          })}
                          <input
                            id={`frequency-${index}`}
                            className={clsx(
                              'form-control form-control-solid btn-sm ms-2 rounded-4 mw-160',
                              activeFrequency &&
                                defaultFrequencies.includes(activeFrequency) &&
                                'active'
                            )}
                            onChange={(e) => handleFrequencyChange(e.target.value, index)}
                            onBlur={() => {
                              const regex = /^(\d+(?:\.\d+)?[a-zA-Z]*-){2}\d+(?:\.\d+)?[a-zA-Z]*$/
                              const fieldValue = activeFrequencyValue

                              if (!fieldValue && !medication_data[index].frequency) {
                                setFieldErrors((prevErrors) => ({
                                  ...prevErrors,
                                  [index]: {
                                    ...prevErrors[index],
                                    frequency: intl.formatMessage({id: 'VALIDATION.REQ.FREQUENCY'}),
                                  },
                                }))
                              } else if (!fieldValue.match(regex)) {
                                setFieldErrors((prevErrors) => ({
                                  ...prevErrors,
                                  [index]: {
                                    ...prevErrors[index],
                                    frequency: intl.formatMessage({
                                      id: ' VALIDATION.INVALID.FREQUENCY',
                                    }),
                                  },
                                }))
                              } else {
                                setFieldErrors((prevErrors) => {
                                  const errors = {...prevErrors}
                                  delete errors[index]?.frequency
                                  return errors
                                })
                              }
                            }}
                            value={itemIdForUpdate ? activeFrequencyValue : item.frequency}
                            placeholder={intl.formatMessage({id: 'MEDICATION.S-O-S'})}
                            type='text'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                      {fieldErrors[index]?.frequency && (
                        <div className='fv-help-block1'>{fieldErrors[index]?.frequency}</div>
                      )}
                    </div>
                    <div className='fv-row mb-7'>
                      <label
                        htmlFor={`duration-${index}`}
                        className='form-label required fw-bold fs-6 mt-3'
                      >
                        {intl.formatMessage({id: 'GENERAL.DURATION'})}
                      </label>
                      <div className='d-flex'>
                        <div className='d-flex flex-wrap gap-2'>
                          {defaultDuration.map((item3: any) => {
                            return (
                              <button
                                key={item3}
                                type='button'
                                className={clsx(
                                  ' btn btn-light-primary btn-active-primary rounded-pill btn-sm',
                                  itemIdForUpdate
                                    ? defaultDuration.includes(activeDurationValue) &&
                                        item3 === activeDurationValue &&
                                        'active'
                                    : defaultDuration.includes(item.duration) &&
                                        item3 === item.duration &&
                                        'active'
                                )}
                                onClick={() => handleDurationChange(item3, index)}
                              >
                                {item3}
                              </button>
                            )
                          })}
                          <input
                            id={`duration-${index}`}
                            className={clsx(
                              'form-control form-control-solid btn-sm ms-2 rounded-4 mw-160',

                              activeDuration && defaultDuration.includes(activeDuration) && 'active'
                            )}
                            type='number'
                            onChange={(e: any) =>
                              handleDurationChange(Number(e.target.value), index)
                            }
                            onBlur={() => {
                              const fieldValue = activeDurationValue

                              if (!fieldValue && !medication_data[index].duration) {
                                setFieldErrors((prevErrors) => ({
                                  ...prevErrors,
                                  [index]: {
                                    ...prevErrors[index],
                                    duration: intl.formatMessage({id: 'VALIDATION.REQ.DURATION'}),
                                  },
                                }))
                              } else {
                                setFieldErrors((prevErrors) => {
                                  const errors = {...prevErrors}
                                  delete errors[index]?.duration
                                  return errors
                                })
                              }
                            }}
                            value={itemIdForUpdate ? activeDurationValue : item.duration}
                            placeholder={intl.formatMessage({
                              id: 'MEDICINE_MANAGEMENT.CUSTOM.DURATION',
                            })}
                            min={0}
                            autoComplete='off'
                            onKeyPress={(e) => {
                              if (e.key === '-' || e.key === '+') {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>
                      {fieldErrors[index]?.duration && (
                        <div className='fv-help-block1'>{fieldErrors[index]?.duration}</div>
                      )}
                    </div>
                    <div className='fv-row mb-7  '>
                      <div className='d-flex gap-2'>
                        <label className='form-label fw-bold fs-6 mt-3'>
                          {intl.formatMessage({id: 'GENERAL.INSTRUCTION'})}
                        </label>
                        <div className='d-flex'>
                          <div className='d-flex flex-wrap gap-2'>
                            {defaultInstruction.map((defaultItem) => (
                              <button
                                key={defaultItem}
                                type='button'
                                className={clsx(
                                  'btn btn-light-primary btn-active-primary rounded-pill btn-sm',
                                  itemIdForUpdate
                                    ? defaultInstruction.includes(activeInstructionValue) &&
                                        defaultItem === activeInstructionValue &&
                                        'active'
                                    : defaultInstruction.includes(item.instruction) &&
                                        defaultItem === item.instruction &&
                                        'active'
                                )}
                                onClick={() => handleInstructionChange(defaultItem, index)}
                              >
                                {defaultItem}
                              </button>
                            ))}
                            <input
                              {...formik.getFieldProps(`medication_data[${index}].instruction`)}
                              className={clsx(
                                'form-control form-control-solid btn-sm ms-2 rounded-4 mw-160',
                                activeInstruction &&
                                  defaultInstruction.includes(activeInstruction) &&
                                  'active'
                              )}
                              value={itemIdForUpdate ? activeInstructionValue : item.instruction}
                              onChange={(e) => handleInstructionChange(e.target.value, index)}
                              placeholder={intl.formatMessage({
                                id: 'MEDICINE_MANAGEMENT.CUSTOM.INSTRUCTIONS',
                              })}
                              type='text'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {itemIdForUpdate ? '' : <div className='separator my-10'></div>}
                  </div>
                )
              })}
          </div>

          {itemIdForUpdate ? (
            ''
          ) : (
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-success'
                onClick={addItem}
                disabled={!isButtonEnabled}
              >
                {intl.formatMessage({id: 'MEDICATION.ADD.ITEM'})}
              </button>
            </div>
          )}
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='button'
            className='btn btn-primary'
            onClick={handleSubmit}
            disabled={
              isUserLoading || formik.isSubmitting || !formik.dirty || !formik.isValid || hasErrors
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <MedicationListLoading />}
    </>
  )
}

export {MedicationEditModalForm}
