import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialUser, ATD} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {AtdsListLoading} from '../components/loading/AtdsListLoading'
import {
  createAdtPatient,
  getDoctors,
  getHospitalRoom,
  getPatientsforAppointment,
  updateAtdPatient,
} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {loginContext} from '../../../../context/context'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: ATD
}

function mapDataToNodes(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({value: item?.full_name, label: item?.full_name, id: item?.id})
  })

  return parent
}

function mapDataToNodesDoctor(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({value: item?.full_name, label: item?.full_name, id: item?.id})
  })

  return parent
}

function mapDataToNodesRoom(data: any) {
  let parent: any = []
  data?.map((item: any) => {
    parent.push({value: item?.room_number, label: item?.room_number, id: item?.id})
  })

  return parent
}

const AtdEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [patientData, setPatientData] = useState([])
  const [doctorData, setDoctorData] = useState([])
  const [roomData, setRoomData] = useState([])
  const [selectePatient, setSelectePatient] = useState<any>(null)
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null)
  const [selectedRoom, setSelectedRoom] = useState<any>(null)
  const [selDateTime, setSelDateTime] = useState<Date | null>(null)
  const {selectedHospitalId} = useContext(loginContext)

  const [userForEdit] = useState<ATD>({
    ...user,
    doctor_id: user?.doctor_id || initialUser?.doctor_id,
    patient_id: user?.patient_id || initialUser?.patient_id,
    hospital_id: user?.hospital_id || initialUser?.hospital_id,
    hospital_room_id: user?.hospital_room_id || initialUser?.hospital_room_id,
    reason: user?.reason || initialUser?.reason,
    admission_date: user?.admission_date || initialUser?.admission_date,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  let editUserSchema
  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      // admission_date: Yup.date().required('Please select Admission Date & Time'),
    })
  } else {
    editUserSchema = Yup.object().shape({
      patient_id: Yup.string().required(intl.formatMessage({id: 'VALIDATION.ATD.SELECT_PATIENT'})),
      doctor_id: Yup.string().required(intl.formatMessage({id: 'VALIDATION.ATD.SELECT_DOCTOR'})),
      hospital_room_id: Yup.string().required(
        intl.formatMessage({id: 'VALIDATION.ATD.SELECT_ROOM'})
      ),
      admission_date: Yup.date().required(
        intl.formatMessage({id: 'VALIDATION.ATD.SELECT_ADMISSION_DATE_TIME'})
      ),
      reason: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const admissionDateTime = moment(values['admission_date'], 'DD-MM-YYYY H:mm').format(
        'DD-MM-YYYY H:mm'
      )
      const dischargeDateTime = moment(values['discharge_date'], 'DD-MM-YYYY H:mm').format(
        'DD-MM-YYYY H:mm'
      )
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          delete values?.ad_patient_transfer
          delete values?.discharge_date
          delete values?.hospital_id
          delete values?.doctor_id
          delete values?.hospital_room_id
          delete values?.patient
          delete values?.patient_id
          delete values?.hospital
          values['admission_date'] = admissionDateTime

          await updateAtdPatient(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          delete values?.id
          values['hospital_id'] = Number(selectedHospitalId)
          values['admission_date'] = admissionDateTime
          if (values?.discharge_date) {
            values['discharge_date'] = dischargeDateTime
          }
          await createAdtPatient(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        toast.warning(ex.response?.data?.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const fetchPatient = async () => {
    const allPatientData: any = await getPatientsforAppointment()

    const activePatientData = allPatientData?.data?.filter((item: any) => {
      return item?.status === 'ACTIVE' && item?.hospital?.id == selectedHospitalId
    })

    setPatientData(activePatientData)
  }

  const fetchDoctor = async () => {
    const allDoctorData: any = await getDoctors()

    const activeDoctorData = allDoctorData?.data?.filter((item: any) => {
      return item?.status === 'ACTIVE' && item?.user?.hospital_id == selectedHospitalId
    })

    setDoctorData(activeDoctorData)
  }

  const fetchAllRoom = async () => {
    const allRoomData: any = await getHospitalRoom()

    const activeRoomData = allRoomData?.data?.filter((item: any) => {
      return (
        item?.availability_status === 'AVAILABLE' && item?.hospital_id?.id == selectedHospitalId
      )
    })

    setRoomData(activeRoomData)
  }

  useEffect(() => {
    fetchPatient()
  }, [])

  useEffect(() => {
    fetchDoctor()
  }, [])

  useEffect(() => {
    fetchAllRoom()
  }, [])

  const options: any = mapDataToNodes(patientData)
  const optionsDoctor: any = mapDataToNodesDoctor(doctorData)
  const optionsRoom: any = mapDataToNodesRoom(roomData)

  const handleChangeOption = (selectedOption: any) => {
    formik.setFieldValue('patient_id', Number(selectedOption.id))
    setSelectePatient(selectedOption?.id)
  }

  const handleDoctorChange = (selectedDoctor: any) => {
    formik.setFieldValue('doctor_id', Number(selectedDoctor.id))
    setSelectedDoctor(selectedDoctor?.id)
  }

  const handleRoomChange = (selectedRoom: any) => {
    formik.setFieldValue('hospital_room_id', Number(selectedRoom.id))
    setSelectedRoom(selectedRoom?.id)
  }

  const maxDate = new Date(moment().format('YYYY-MM-DD'))

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#03A9F4' : 'white',
      color: state.isFocused ? '#fff' : 'black',
      fontWeight: 'bold',
    }),
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            {itemIdForUpdate ? (
              ''
            ) : (
              <>
                <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
                  <label className='required fw-bold fs-6 mb-2'>
                    {intl.formatMessage({id: 'GENERAL.PATIENT'})}
                  </label>
                  <Select
                    // value={selectedDoctor ? selectedDoctor : filteredData}
                    // {...formik.getFieldProps('patient_id')}
                    onChange={handleChangeOption}
                    options={options}
                    styles={customStyles}
                    placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.PATIENT'})}
                    name='patient_id'
                  />
                  {formik.touched.patient_id && formik.errors.patient_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.patient_id}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7 col-12 col-sm-6'>
                  <label className='required fw-bold fs-6 mb-2'>
                    {intl.formatMessage({id: 'PATIENT_APPOINTMENT.DOCTORS'})}
                  </label>
                  <Select
                    // value={selectedDoctor}
                    onChange={handleDoctorChange}
                    options={optionsDoctor}
                    styles={customStyles}
                    placeholder={intl.formatMessage({id: 'DOCTOR.APPOINTMENT.SELECT.DOCTOR'})}
                  />
                  {formik.touched.doctor_id && formik.errors.doctor_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.doctor_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div className='row'>
            {itemIdForUpdate ? (
              ''
            ) : (
              <>
                <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
                  <label className='required fw-bold fs-6 mb-2'>
                    {intl.formatMessage({id: 'GENERAL.ROOM.NUMBER'})}
                  </label>
                  <Select
                    // value={selectedDoctor}
                    onChange={handleRoomChange}
                    options={optionsRoom}
                    styles={customStyles}
                    placeholder={intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.ROOM'})}
                  />
                  {formik.touched.hospital_room_id && formik.errors.hospital_room_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.hospital_room_id}</span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className={`fv-row mb-7 col-12 col-sm-6 ${itemIdForUpdate ? 'ps-0' : ''}`}>
              <label className={`required fw-bold fs-6 mb-2`}>
                {intl.formatMessage({id: 'GENERAL.ADMISSION.DATE.TIME'})}
              </label>
              <div className='position-relative'>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('admission_date')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy H:mm'
                  name='admission_date'
                  timeInputLabel='Time:'
                  // minDate={past_date_disable}
                  selected={selDateTime}
                  maxDate={maxDate}
                  onChange={(date) => {
                    formik.setFieldValue('admission_date', date)
                    setSelDateTime(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  showTimeInput
                  shouldCloseOnSelect={false}
                  placeholderText={intl.formatMessage({id: 'GENERAL.ADMISSION.DATE.TIME'})}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                />
                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('admission_date')[0].focus()}
                >
                  <i className='fa fa-calendar'></i>
                </span>
              </div>
              {formik.touched.admission_date &&
                formik.values.admission_date &&
                formik.errors.admission_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.admission_date}</span>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className='row'></div>
          <div className='fv-row mb-7 col-12'>
            <label className='fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'PATIENT_ATD.REASON.FOR.ADMISSION'})}
            </label>

            <textarea
              {...formik.getFieldProps('reason')}
              className='form-control no-resize'
              name='reason'
              id='exampleFormControlTextarea1'
              disabled={formik.isSubmitting || isUserLoading}
              rows={3}
              placeholder={intl.formatMessage({id: 'PATIENT_ATD.REASON.FOR.ADMISSION'})}
            ></textarea>

            {formik.touched.reason && formik.errors.reason && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.reason}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <AtdsListLoading />}
    </>
  )
}

export {AtdEditModalForm}
