import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../_jmh/helpers'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

const ContactUs = () => {
  const [termsAgreed, setTermsAgreed] = useState(false)
  const intl = useIntl()
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      company_name: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQ.NAME'})),
      email: Yup.string()
        .email(intl.formatMessage({id: 'VALIDATION.EMAIL.ERROR'}))
        .required(intl.formatMessage({id: 'VALIDATION.REQ.EMAIL'})),
      phone_number: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQ.PHONE_NUMBER'})),
      company_name: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQ.COMPANY_NAME'})),
      message: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQ.MESSAGE'})),
    }),
    onSubmit: (values) => {
      if (!termsAgreed) {
        toast.error(intl.formatMessage({id: 'VALIDATION.TEAMS_&_CONDITIONS'}))
        return
      }
      toast.success(intl.formatMessage({id: 'VALIDATION.FORM_SUBMITTED'}))
    },
  })

  const handleFocus = <T extends keyof typeof formik.values>(field: T) => {
    formik.setFieldTouched(field, true)
    formik.setFieldValue(field, formik.values[field] as any)
  }
  return (
    <div className='contact_us_main position-relative overflow-hidden'>
      <div className='landing_container'>
        <div className='contacu_us_wrapper'>
          <div className='contact_us_top py-5 w-100'>
            <Link to='/'>
              <KTSVG
                className='contact_us_home_icon'
                path='/media/landing_asset/contact_home.svg'
              />
            </Link>
          </div>
          <div className='contact_us_middle'>
            <div className='contact_us_middle_content'>
              <div className='contact_us_top_text_content text-center'>
                <h1>{intl.formatMessage({id: 'GENERAL.CONTACT_US'})}</h1>
                <p>{intl.formatMessage({id: 'LANDING_PAGE.CONTACT_US.TEXT'})}</p>
              </div>
              <div className='contact_us_form'>
                <form onSubmit={formik.handleSubmit}>
                  <div className='row'>
                    <div className='col-6 contact_field'>
                      <label
                        htmlFor='name'
                        className={`form-label ${
                          formik.touched.name || formik.values.name ? 'active' : ''
                        }`}
                      >
                        {intl.formatMessage({id: 'LANDING_PAGE.ENTER_NAME'})}
                      </label>
                      <input
                        type='text'
                        className={`form-control `}
                        placeholder={intl.formatMessage({id: 'LANDING_PAGE.ENTER_NAME'})}
                        id='name'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onFocus={() => handleFocus('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-6 contact_field'>
                      <label
                        htmlFor='email'
                        className={`form-label ${
                          formik.touched.email || formik.values.email ? 'active' : ''
                        }`}
                      >
                        {intl.formatMessage({id: 'LANDING_PAGE.ENTER_EMAIL'})}
                      </label>
                      <input
                        type='email'
                        className={`form-control`}
                        placeholder={intl.formatMessage({id: 'LANDING_PAGE.ENTER_EMAIL'})}
                        id='email'
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onFocus={() => handleFocus('email')}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-6 contact_field'>
                      <label
                        htmlFor='phone_number'
                        className={`form-label ${
                          formik.touched.phone_number || formik.values.phone_number ? 'active' : ''
                        }`}
                      >
                        {intl.formatMessage({id: 'LANDING_PAGE.ENTER_PHONE_NUMBER'})}
                      </label>
                      <input
                        type='number'
                        className={`form-control`}
                        placeholder={intl.formatMessage({id: 'PLACEHOLDER.PHONE_NUMBER'})}
                        id='phone_number'
                        name='phone_number'
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onFocus={() => handleFocus('phone_number')}
                        onKeyPress={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                            e.preventDefault()
                          }
                        }}
                      />
                      {formik.touched.phone_number && formik.errors.phone_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.phone_number}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-6 contact_field'>
                      <label
                        htmlFor='company_name'
                        className={`form-label ${
                          formik.touched.company_name || formik.values.company_name ? 'active' : ''
                        }`}
                      >
                        {intl.formatMessage({id: 'LANDING_PAGE.ENTER_COMPANY'})}
                      </label>
                      <input
                        type='text'
                        className={`form-control`}
                        placeholder={intl.formatMessage({id: 'LANDING_PAGE.ENTER_COMPANY'})}
                        id='company_name'
                        name='company_name'
                        value={formik.values.company_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onFocus={() => handleFocus('company_name')}
                      />
                      {formik.touched.company_name && formik.errors.company_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.company_name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-12 contact_field'>
                      <textarea
                        className={`form-control`}
                        placeholder={intl.formatMessage({id: 'LANDING_PAGE.ENTER_MESSAGE'})}
                        id='message'
                        name='message'
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        rows={5} // Set the number of rows
                      />
                      {formik.touched.message && formik.errors.message && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.message}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='contact_form_bottom'>
                    <div className='row align-items-center'>
                      <div className='col-6'>
                        <div className='form-check contact_agreement'>
                          <input
                            type='checkbox'
                            className={`form-check-input mt-2`}
                            id='termsAndConditions'
                            name='termsAndConditions'
                            checked={termsAgreed}
                            onChange={() => setTermsAgreed(!termsAgreed)}
                          />
                          <label className='form-check-label' htmlFor='termsAndConditions'>
                            {intl.formatMessage({id: 'LANDING_PAGE.READ_ACCEPT.TERMS'})} &nbsp;
                            <Link to='/'>
                              <span>
                                {intl.formatMessage({id: 'LANDING_PAGE.SERVICE_PRIVACY_POLICY'})}
                              </span>
                            </Link>
                          </label>
                        </div>
                      </div>
                      <div className='col-6 text-end'>
                        <button
                          type='submit'
                          className='btn btn-primary contact_submit_btn'
                          disabled={!formik.isValid}
                        >
                          {intl.formatMessage({id: 'LANDING_PAGE.SEND_BUTTON'})}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
