import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {InventoryCategory, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const INVENTORY_CATEGORY_URL = `${API_URL}/inventory-category`
const GET_INVENTORY_CATEGORY_URL = `${API_URL}/inventory-category/`

const getInventoryCategory = (query: string,id:number): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_INVENTORY_CATEGORY_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}


const getInventoryCategoryById = (id: ID): Promise<InventoryCategory | undefined> => {
  return axios
    .get(`${INVENTORY_CATEGORY_URL}/${id}`)
    .then((response: AxiosResponse<Response<InventoryCategory>>) => response.data)
    .then((response: Response<InventoryCategory>) => response.data)
}

const createInventoryCategory = (user: InventoryCategory): Promise<InventoryCategory | undefined> => {
  return axios
    .post(INVENTORY_CATEGORY_URL, user)
}

const updateInventoryCategory = (user: InventoryCategory): Promise<InventoryCategory | undefined> => {
  const data = {
    name: user.name
  }
  return axios
    .patch(`${INVENTORY_CATEGORY_URL}/${user.id}`, data)
}

export {getInventoryCategory, getInventoryCategoryById, createInventoryCategory, updateInventoryCategory}
