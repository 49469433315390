import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, KTSVG} from '../../../../../_jmh/helpers'
import {initialUser, PatientInsurance} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {PatientInsuranceListLoading} from '../components/loading/PatientInsuranceListLoading'
import {createPatientInsurance, getPatientList, updatePatientInsurance} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import axios from 'axios'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: PatientInsurance
}
let editUserSchema

const PatientInsuranceEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const API_URL = process.env.REACT_APP_API_URL
  const PATIENT_INSURANCE_URL = `${API_URL}/patient-insurance`
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [patientName, setPatientName] = useState([])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>()
  const [preview, setPreview] = useState<string | null>('')
  const [extension, setExtension] = useState(
    user.insurance_upload?.split('.').pop()?.toLowerCase() || null
  )
  const [isRemoveFile, setIsRemoveFile] = useState(false)
  // const [previewPDF, setPreviewPDF] = useState('')
  const {selectedHospitalId} = useContext(loginContext)

  const [errorStatus, setErrorStatus] = useState('')
  const SUPPORTED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/jpg']
  const url: any = user.insurance_upload

  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      // patient_id: Yup.string().required('Patient ID is required'),
      subscriber_name: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .required('Subscriber Name is required'),
      company_name: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .required('Company Name is required'),
      type: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .required('Type is required'),
      policy_number: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .required('Policy Number is required')
        .min(5, 'Policy Number must be at least 5 characters'),
      starting_date: Yup.string().required('Starting Date is required'),
      ending_date: Yup.string().required('Ending Date is required'),
    })
  } else {
    editUserSchema = Yup.object().shape({
      patient_id: Yup.string()
        .required('Patient ID is required')
        .notOneOf([''], 'Patient ID is required'),
      subscriber_name: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .required('Subscriber Name is required'),
      company_name: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .required('Company Name is required'),
      type: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .required('Type is required'),
      policy_number: Yup.string()
        .test(
          'noLeadingSpaces',
          'Invalid Input, Please avoid leading spaces at the beginning of the field',
          (value) => {
            return !value || value.trimStart() === value
          }
        )
        .required('Policy Number is required')
        .min(5, 'Policy Number must be at least 5 characters'),
      starting_date: Yup.date().required('Starting Date is required'),
      ending_date: Yup.date().required('Ending Date is required'),
    })
  }

  const [userForEdit] = useState<PatientInsurance>({
    ...user,
    patient_id: user.patient_id || initialUser.patient_id,
    subscriber_name: user.subscriber_name || initialUser.subscriber_name,
    company_name: user.company_name || initialUser.company_name,
    type: user.type || initialUser.type,
    policy_number: user.policy_number || initialUser.policy_number,
    starting_date: user.starting_date || initialUser.starting_date,
    ending_date: user.ending_date || initialUser.ending_date,
    insurance_upload: user.insurance_upload || initialUser.insurance_upload,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    ;(async () => {
      const patientNameData: any = await getPatientList()

      const activePatientData = patientNameData?.data?.filter((item: any) => {
        return item?.status === 'ACTIVE' && item?.hospital_id == selectedHospitalId
      })

      if (activePatientData) {
        setPatientName(activePatientData)
      }
    })()
  }, [selectedHospitalId])

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0]

    if (file) {
      if (SUPPORTED_FILE_TYPES.includes(file.type)) {
        // File format is supported
        setSelectedFile(file)
        const fileExtension = file.name.split('.').pop().toLowerCase()
        setSelectedFile(file)
        setExtension(fileExtension)

        setErrorStatus('')

        const reader = new FileReader()
        reader.onloadend = () => {
          setPreview(reader.result as string)
        }
        reader.readAsDataURL(file)
        formik.setFieldValue('insurance_upload', file)
      } else {
        // File format is not supported
        setSelectedFile(null)
        formik.setFieldValue('insurance_upload', null)
        setErrorStatus('Unsupported file format')
        // Reset preview values
        setPreview(null)
      }
    }
  }

  const handleRemoveFileApi = (Id: any) => {
    return axios
      .delete(`${PATIENT_INSURANCE_URL}/${Id}`, {data: {id: [Id]}})
      .then(() => {
        setPreview(null)
        setSelectedFile(null)
        setExtension(null)
        formik.setFieldValue('insurance_upload', null)
        // Success
      })
      .catch((error) => {
        console.error('Error occured while deleting the data')
        // Handle error
      })
  }
  const handleRemoveFile = () => {
    setExtension(null)
    setPreview(null)
    setSelectedFile(null)
    formik.setFieldValue('insurance_upload', null)
    setIsRemoveFile(true)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (errorStatus === '') {
        setSubmitting(true)
        try {
          if (isNotEmpty(values.id)) {
            if (isRemoveFile) {
              handleRemoveFileApi(user.id)
            } else {
              const formData = new FormData()
              // formData.append('patient_id', values.patient_id as any)
              formData.append('subscriber_name', values.subscriber_name as string)
              formData.append('company_name', values.company_name as string)
              formData.append('type', values.type as string)
              formData.append('policy_number', values.policy_number as string)

              formData.append(
                'starting_date',
                (values['starting_date'] = moment(values['starting_date'], 'DD-MM-YYYY').format(
                  'DD-MM-YYYY'
                ) as any)
              )
              formData.append(
                'ending_date',
                (values['ending_date'] = moment(values['ending_date'], 'DD-MM-YYYY').format(
                  'DD-MM-YYYY'
                ) as any)
              )

              formData.append('insurance_upload', values?.insurance_upload as any)

              formData.append('hospital_id', Number(selectedHospitalId) as any)

              await updatePatientInsurance(values.id, formData).then((res: any) => {
                toast.success(res?.data?.message)
              })
            }
          } else {
            const formData = new FormData()
            formData.append('patient_id', values.patient_id as any)
            formData.append('subscriber_name', values.subscriber_name as string)
            formData.append('company_name', values.company_name as string)
            formData.append('type', values.type as string)
            formData.append('policy_number', values.policy_number as string)

            formData.append(
              'starting_date',
              (values['starting_date'] = moment(values['starting_date'], 'DD-MM-YYYY').format(
                'DD-MM-YYYY'
              ) as any)
            )
            formData.append(
              'ending_date',
              (values['ending_date'] = moment(values['ending_date'], 'DD-MM-YYYY').format(
                'DD-MM-YYYY'
              ) as any)
            )

            formData.append('insurance_upload', values?.insurance_upload as any)
            formData.append('hospital_id', Number(selectedHospitalId) as any)

            await createPatientInsurance(formData).then((res: any) => {
              toast.success(res?.data?.message)
            })
          }
        } catch (ex: any) {
          if (ex.response.data.message) {
            toast.error(ex.response.data.message)
          }
        } finally {
          setSubmitting(true)
          cancel(true)
        }
      }
    },
  })

  const future_date_disable = new Date(moment().format('YYYY-MM-DD'))
  const past_date_disable = new Date(moment().format('YYYY-MM-DD'))

  return (
    <>
      <form
        id='kt_modal_add_user_form3'
        className={clsx('form', {
          'form-not-clickable': formik.isSubmitting,
        })}
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <>
              <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'OPERATION_THEATRE.SELECT.PATIENT'})}
                </label>
                <select
                  className='form-select select_arrow_remove'
                  aria-label='Select example'
                  {...formik.getFieldProps('patient_id.id')}
                  name='patient_id'
                  disabled={itemIdForUpdate ? true : false}
                >
                  <option value=''>
                    {intl.formatMessage({id: 'PATIENT_INSURANCE.SELECT.PATIENT.NAME'})}
                  </option>
                  {patientName &&
                    patientName?.map((item: any) => {
                      return (
                        <option key={item.id} value={item?.id}>
                          {item?.full_name}
                        </option>
                      )
                    })}
                </select>
                {formik.touched.patient_id && formik.errors.patient_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.patient_id}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_INSURANCE.SUBCRIBER.NAME'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'PATIENT_INSURANCE.SUBCRIBER.NAME'})}
                {...formik.getFieldProps('subscriber_name')}
                type='text'
                name='subscriber_name'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.subscriber_name && formik.errors.subscriber_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.subscriber_name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.COMPANY.NAME'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.COMPANY.NAME'})}
                {...formik.getFieldProps('company_name')}
                type='text'
                name='company_name'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.company_name && formik.errors.company_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.company_name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.TYPE'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.TYPE'})}
                {...formik.getFieldProps('type')}
                type='text'
                name='type'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.type && formik.errors.type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.type}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.POLICY.NUMBER'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.POLICY.NUMBER'})}
                {...formik.getFieldProps('policy_number')}
                type='text'
                name='policy_number'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.policy_number && formik.errors.policy_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.policy_number}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_INSURANCE.STARTING.DATE'})}
              </label>
              <div className='position-relative'>
                <ReactDatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('starting_date')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy'
                  name='starting_date'
                  maxDate={future_date_disable}
                  selected={startDate}
                  onChange={(date) => {
                    formik.setFieldValue('starting_date', date)
                    setStartDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  placeholderText={intl.formatMessage({id: 'PATIENT_INSURANCE.STARTING.DATE'})}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('starting_date')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>
              {formik.touched.starting_date && formik.errors.starting_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.starting_date}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_INSURANCE.ENDING.DATE'})}
              </label>

              <div className='position-relative'>
                <ReactDatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('ending_date')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy'
                  name='ending_date'
                  minDate={past_date_disable}
                  selected={endDate}
                  onChange={(date) => {
                    formik.setFieldValue('ending_date', date)
                    setEndDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  placeholderText={intl.formatMessage({id: 'PATIENT_INSURANCE.ENDING.DATE'})}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('ending_date')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>

              {formik.touched.ending_date && formik.errors.ending_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.ending_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <div className='image-input image-input-outline mx-3' data-kt-image-input='true'>
              {extension === 'pdf' || (selectedFile && selectedFile?.type === 'application/pdf') ? (
                <div className='image-input-wrapper w-100px h-100px d-flex align-items-center'>
                  <KTSVG path='/media/svg/files/pdf.svg' className='svg-pdf svg-icon-primary' />
                </div>
              ) : (
                <>
                  {extension === 'jpg' || extension === 'jpeg' ? (
                    <>
                      <div
                        className='image-input-wrapper w-100px h-100px'
                        style={{backgroundImage: `url('${preview || user.insurance_upload}')`}}
                      ></div>
                    </>
                  ) : extension === null ? (
                    <div className='image-input-wrapper w-100px h-100px d-flex align-items-center justify-content-center'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil022.svg'
                        className='svg-icon-muted svg-icon-4hx'
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}

              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                // data-bs-toggle='tooltip'
                // title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input
                  key={preview}
                  type='file'
                  name='image'
                  id='image'
                  accept='.pdf,.jpg,.jpeg'
                  onChange={handleFileChange}
                />
                <input type='hidden' name='image' value={user.insurance_upload || ''} />
              </label>

              {user.insurance_upload && !selectedFile && extension !== null && (
                <span
                  className='doc_view_btn btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  // data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  // title='Open file in new tab'
                  onClick={() => window.open(user.insurance_upload, '_blank')}
                >
                  <i className='bi bi-eye-fill fs-7'></i>
                </span>
              )}

              {extension !== null ? (
                <span
                  className='btn btn-icon btn-circle doc_remove_btn btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='remove'
                  onClick={handleRemoveFile}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              ) : (
                ''
              )}
            </div>

            <div className='mt-5 text-muted'>
              {intl.formatMessage({id: 'GENERAL.FORMATS.SUPPORTED'})}
            </div>
            {errorStatus !== '' && (
              <>
                <div className='text-danger mx-0 my-3'>
                  <div className='font-weight-bold'>{errorStatus}</div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              !!errorStatus ||
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'> {intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <PatientInsuranceListLoading />}
    </>
  )
}

export {PatientInsuranceEditModalForm}
