import {useLocation, useNavigate} from 'react-router-dom'
import DpBody from './DpBody'
import DpFooter from './DpFooter'
import DpHeader from './DpHeader'
import {createContext, useEffect, useState} from 'react'
import {getAppointmentById} from '../Patient-appointment/appointments-list/core/_requests'
export const DpContext = createContext<any>(null)

const DpLayoutWrapper = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedPatientData, setSelectedPatientData] = useState()
  const {id}: {id?: number} = location.state as {id?: number}
  const [attachmentFiles, setAttachmentFiles] = useState(null)
  const [previousLocation, setPreviousLocation] = useState<number | null>(null)

  useEffect(() => {
    if (location.state && (location.state as {activeIndex: number}).activeIndex !== undefined) {
      // Save the previous location to state
      setPreviousLocation((location.state as {activeIndex: number}).activeIndex)
    }
  }, [location])

  const handleBack = () => {
    // Navigate back to the previous location
    navigate('/doctor-all-information', {state: {activeIndex: previousLocation}})
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const patientData: any = await getAppointmentById(id)
        setSelectedPatientData(patientData)

        sessionStorage.setItem(
          'prescriptionData',
          JSON.stringify({
            appointment_id: patientData.appointment_details[0].appointment_id,
            doctor_id: patientData.appointment_details[0].doctor_id,
            patient_id: patientData.patient_id,
            hospital_id: patientData?.hospital_id,
            chief_complaints: [],
            examination: null,
            examination_notes: [],
            diagnosis: [],
            medication: null,
            procedure: null,
            investigation: null,
            advice: [],
            email: patientData?.patient?.email,
          })
        )
      } catch (error) {
        console.error('Error fetching patient data:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <DpContext.Provider value={{attachmentFiles, setAttachmentFiles, selectedPatientData}}>
      <div className='container-fluid py-5 dp-wrapper'>
        <div className='d-flex justify-content-between align-items-center'>
          <h1>Generate Prescription</h1>
          <button
            className='btn dp-back-button'
            // onClick={() => navigate('/doctor-all-information')}
            onClick={handleBack}
          >
            Back
          </button>
        </div>
        <DpHeader selectedPatientData={selectedPatientData} />
        <DpBody />
      </div>
    </DpContext.Provider>
  )
}

export default DpLayoutWrapper
