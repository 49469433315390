// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {NotificationDescription} from './NotificationDescription'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {NotificationModel} from '../../core/_models'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<NotificationModel>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return <UserSelectionHeader tableProps={props} className='w-30px px-2' />
    },
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.NOTIFICATION'})}
          className='min-w-125px'
        />
      )
    },
    id: 'notification_type',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
          className='min-w-125px'
        />
      )
    },
    id: 'description',
    Cell: ({...props}) => <NotificationDescription user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.TIME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'created_at',
    Cell: ({...props}) => <UserLastLoginCell created_at={props.data[props.row.index].created_at} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className=' min-w-100px'
        />
      )
    },
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
