/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Doctor} from '../../core/_models'

type Props = {
  user: Doctor
}

const DoctorInfoCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800  mb-1'>{user.full_name}</span>
      </div>
    </div>
  )
}

export {DoctorInfoCell}
