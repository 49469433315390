/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {KTSVG, QUERIES, stringifyRequestQuery} from '../../../helpers'
import {loginContext} from '../../../../app/context/context'
import {useQuery} from 'react-query'
import {getInventoryData} from '../core/request'
import {useQueryRequest} from '../../../../app/pages/Inventory-management/InventoryManagementList/core/QueryRequestProvider'
import {useIntl} from 'react-intl'

type Props = {
  className: string
}

const InventoryLists: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const [query] = useState<string>('')
  const {selectedInventoryData, setSelectedInventoryData, selectedHospitalId} =
    useContext(loginContext)
  const [showLowQuantity, setShowLowQuantity] = useState(false)
  const [showHighQuantity, setShowHighQuantity] = useState(false)
  const colors = ['primary', 'success', 'danger', 'warning', 'info']

  const {data: response, refetch} = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    () => {
      return getInventoryData(query, selectedHospitalId)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    refetch()
  }, [selectedHospitalId])

  useEffect(() => {
    setSelectedInventoryData(response?.data)
  }, [response])

  const handleShowLowQuantity = () => {
    setShowLowQuantity(true)
  }
  const handleShowHighQuantity = () => {
    setShowLowQuantity(false)
    setShowHighQuantity(true)
  }

  const filteredInventoryData = useMemo(() => {
    if (showLowQuantity) {
      return selectedInventoryData?.filter((inventory: any) => inventory?.status === 'Low Quantity')
    } else if (showHighQuantity) {
      return selectedInventoryData?.filter(
        (inventory: any) => inventory?.status === 'High Quantity'
      )
    }
    return selectedInventoryData
  }, [selectedInventoryData, showLowQuantity, showHighQuantity])

  const handleReset = () => {
    setShowLowQuantity(false)
    setShowHighQuantity(false)
    return setSelectedInventoryData(response?.data)
  }

  return (
    <div className={`card ${className}`}>
      <div>
        <div className='gy-7 gs-7'>
          <div className='d-flex align-item-center justify-content-between border-0 px-9 py-9'>
            <h4 className=' d-flex align-items-center justify-content-center flex-column mb-0'>
              <span className='card-label fw-bold text-dark'>
                {intl.formatMessage({id: 'STAFF_DASHBOARD.INVENTORY.LIST'})}
              </span>
            </h4>
            <div className='d-flex'>
              <div className='d-flex gap-2 card-toolbar '>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary btn-active-primary p-2'
                  onClick={handleShowLowQuantity}
                >
                  {intl.formatMessage({id: 'STAFF_DASHBOARD.LOW.QUANTITY'})}
                </button>

                <button
                  type='button'
                  className='btn btn-sm btn-light-primary btn-active-primary p-2'
                  onClick={handleShowHighQuantity}
                >
                  {intl.formatMessage({id: 'STAFF_DASHBOARD.HIGH.QUANTITY'})}
                </button>

                <button
                  type='reset'
                  onClick={handleReset}
                  className='btn btn-sm btn-icon btn-color-muted btn-active-light-primary mt-1'
                >
                  <KTSVG path='/media/icons/duotune/general/gen072.svg' className='svg-icon-2' />
                </button>
              </div>
            </div>
          </div>

          {filteredInventoryData && filteredInventoryData.length > 0 ? (
            <div className='card-body pt-0 overflow-y-scroll h-100 mh-300px d-block w-100 mb-9'>
              {filteredInventoryData?.map((inventory: any, index: any) => (
                <div
                  className={`d-flex align-items-center bg-light-${
                    colors[index % colors.length]
                  } rounded p-3 mb-7`}
                  key={index}
                >
                  <KTSVG
                    path='/media/icons/duotune/abstract/abs027.svg'
                    className={`svg-icon-muted svg-icon-2hx text-${
                      colors[index % colors.length]
                    } me-3`}
                  />
                  <div className='flex-grow-1 me-2'>
                    <div
                      className={`fw-bold text-gray-800 text-hover-${
                        colors[index % colors.length]
                      } fs-6`}
                    >
                      {inventory.name}
                    </div>
                    <span className='text-muted fw-semibold d-block'>{inventory.status}</span>
                  </div>
                  <span className={`fw-bold text-${colors[index % colors.length]} py-1`}>
                    {inventory?.available_quantity}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className='d-flex justify-content-center align-items-center mt-10 py-2'>
                <span className='fw-bold'>
                  {intl.formatMessage({id: 'STAFF_DASHBOARD.NO.INVENTORY'})}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export {InventoryLists}
