import LandingHeader from './LandingHeader'
import LandingBanner from './LandingBanner'
import LandingAbout from './LandingAbout'
import LandingOurFacility from './LandingOurFacility'
import LandingSpeciality from './LandingSpeciality'
import LandingDoctors from './LandingDoctors'
import LandingTestimonials from './LandingTestimonials'
import LandingCallToAction from './LandingCallToAction'
import LandingOurClinic from './LandingOurClinic'
import LandingFooter from './LandingFooter'
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'

const LandingPage = () => {
  const [showPageNav, setShowPageNav] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const landingBannerHeight = document.getElementById('homeBanner')?.clientHeight || 0
      const showNavThreshold = landingBannerHeight
      setShowPageNav(scrollPosition > showNavThreshold)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <div className='landing_container_main w-100 position-relative overflow-hidden'>
        <LandingHeader />
        <LandingBanner />
        <LandingAbout />
        <LandingOurFacility />
        <LandingSpeciality />
        <LandingDoctors />
        <LandingTestimonials />
        <LandingCallToAction />
        <LandingOurClinic />
        <LandingFooter />
      </div>
      {showPageNav && (
        <div className='page-nav'>
          <div className='scrollNav'>
            <Link to='/#homeBanner' className='landing_scroll_btn' title='To Top'>
              <i className='fa-solid fa-arrow-up'></i>
            </Link>
          </div>
          <ul className='landing_scroll_content p-0 m-0 list-unstyled'>
            <li>
              <Link to='/#about'>
                <span title='About'></span>
              </Link>
            </li>
            <li>
              <Link to='/#facility'>
                <span title='Facility'></span>
              </Link>
            </li>
            <li>
              <Link to='/#ask-doctors'>
                <span title='Ask Doctors'></span>
              </Link>
            </li>
            <li>
              <Link to='/#clinics'>
                <span title='Clinics'></span>
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default LandingPage
