import {HospitalRoomListToolbar} from './HospitalRoomListToolbar'
import {HospitalRoomListSearchComponent} from './HospitalRoomListSearchComponent'

const HospitalRoomListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <HospitalRoomListSearchComponent />
      <div className='card-toolbar'>
        <HospitalRoomListToolbar />
      </div>
    </div>
  )
}

export {HospitalRoomListHeader}
