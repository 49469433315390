import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getStaffByID} from '../core/_requests'
import {StaffEditModalForm} from './StaffEditModalForm'
import moment from 'moment'
const StaffEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: Staff,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getStaffByID(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )
  if (Staff && Staff['date_of_birth']) {
    const dob = moment(Staff.date_of_birth).format('DD-MM-YYYY')
    Staff['date_of_birth'] = dob
  }

  if (!itemIdForUpdate) {
    return <StaffEditModalForm isUserLoading={isLoading} Staff={{id: undefined}} />
  }
  if (!isLoading && !error && Staff) {
    return <StaffEditModalForm isUserLoading={isLoading} Staff={Staff} />
  }
  return null
}
export {StaffEditModalFormWrapper}
