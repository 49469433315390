import {PatientListToolbar} from './PatientListToolbar'
import {PatientsListSearchComponent} from './PatientsListSearchComponent'

const PatientsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <PatientsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <PatientListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PatientsListHeader}
