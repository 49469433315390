import {FC} from 'react'
import {AssetModel} from '../../core/_models'

type Props = {
  user: AssetModel
}

const AssetStatusCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>    
    <div className='d-flex flex-column'>
      <div  className='text-gray-800  mb-1'>
      <div className='text-center'>
          <span className={`badge ${user?.asset_status === 'IN MAINTENANCE' ? 'badge-light-primary' : user?.asset_status === 'BROKEN' ? 'badge-light-danger' : user?.asset_status === 'INACTIVE' ? 'badge-light-warning' : user?.asset_status === 'ACTIVE' ? 'badge-light-success' : ''}`}>
              {user?.asset_status}
          </span>
        </div>
      </div>
    </div>
  </div>
)

export {AssetStatusCell}
