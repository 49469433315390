/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useEffect, useState} from 'react'
import {useQueryResponseLoading} from '../core/QueryResponseProvider'
import {KTCardBody} from '../../../../../_jmh/helpers'
import {useIntl} from 'react-intl'
import {DocInfoLoading} from '../components/loading/DocInfoLoading'
import {DocInfoPagination} from '../components/pagination/DocInfoPagination'
import {
  deleteFetchAppointmentsByFilter,
  fetchAppointmentsByFilter,
  getDocumentsAppointment,
  getInvoiceAppointment,
} from '../core/_requests'
import {BiSort} from 'react-icons/bi'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import {HiDotsVertical} from 'react-icons/hi'
import {TbFileInvoice} from 'react-icons/tb'
import {HiOutlineCurrencyRupee} from 'react-icons/hi'
import {IoDocumentTextOutline} from 'react-icons/io5'
import {MdOutlineNotStarted} from 'react-icons/md'
import InfiniteScroll from 'react-infinite-scroll-component'
import InfiniteLoading from '../components/loading/InfiniteLoading'
import {loginContext} from '../../../../context/context'

const DocInfoTable = ({
  appointments,
  fetchAppointments,
  setSortConfig,
  sortConfig,
  activeIndex,
}: any) => {
  const intl = useIntl()
  const isLoading = useQueryResponseLoading()
  const navigate = useNavigate()
  const [loadingPdf, setLoadingPdf] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const [hasMore, setHasMore] = useState(true)
  const [items, setItems] = useState(appointments.slice(0, 6))

  const {loginData} = useContext(loginContext)

  const fetchMoreData = () => {
    if (items.length >= appointments.length) {
      setHasMore(false)
      return
    }

    // Simulate loading delay
    setTimeout(() => {
      setItems(items.concat(appointments.slice(items.length, items.length + 5)))
    }, 500)
  }

  useEffect(() => {
    setItems(appointments.slice(0, 7))
    setHasMore(true)
  }, [appointments])

  const handleStatusChange = async (id: any, status: string) => {
    try {
      const changesAction = await fetchAppointmentsByFilter(id, status)
      fetchAppointments()
      const message = changesAction.data.message
      toast.success(message || 'Status updated successfully')
    } catch (error: any) {
      console.error(error)
      const errorMessage = error.response?.data?.message || 'Failed to update status'
      toast.error(errorMessage)
    }
  }

  const handleDeleteChanges = async (id: any, status: string) => {
    try {
      const changesAction = await deleteFetchAppointmentsByFilter(id, status)
      fetchAppointments()
      const message = changesAction.data.message
      toast.success(message || 'Status updated successfully')
    } catch (error: any) {
      console.error(error)
      const errorMessage = error.response?.data?.message || 'Failed to update status'
      toast.error(errorMessage)
    }
  }

  const handleSort = async () => {
    let newSortConfig = {sort: 'token', order: 'ASC'}

    if (sortConfig.sort === 'token' && sortConfig.order === 'ASC') {
      newSortConfig = {sort: 'token', order: 'DESC'}
    }

    setSortConfig(newSortConfig)
    await fetchAppointments()
  }

  const handleSortStatus = async () => {
    let newSortConfig = {sort: 'status', order: 'DESC'}

    if (sortConfig.sort === 'status' && sortConfig.order === 'DESC') {
      newSortConfig = {sort: 'status', order: 'ASC'}
    }

    setSortConfig(newSortConfig)
    await fetchAppointments()
  }

  const handleSortTime = async () => {
    let newSortConfig = {sort: 'type', order: 'ASC'}

    if (sortConfig.sort === 'type' && sortConfig.order === 'ASC') {
      newSortConfig = {sort: 'type', order: 'DESC'}
    }

    setSortConfig(newSortConfig)
    await fetchAppointments()
  }

  const isDropdownItemDisabled = (status: string, action: string): boolean => {
    switch (status) {
      case 'COMPLETED':
      case 'CANCELLED':
        return action !== 'ACTIVE' // Disable all except 'ACTIVE'
      case 'ACTIVE':
        return action === 'ACTIVE'
      case 'BOOKED':
        return action !== 'CANCELLED' && action !== 'WAITING' // Disable all except 'CANCELLED'
      case 'WAITING':
        return action !== 'ACTIVE' && action !== 'CANCELLED' // Disable 'COMPLETED' and 'CANCELLED'
      default:
        return false
    }
  }

  const handleNavigatePast = (id: number) => {
    navigate(`/doctor-prescription`, {state: {id, activeIndex, tab: 'nav-past-visit'}})
  }

  const handleNavigate = (id: number) => {
    navigate(`/doctor-prescription`, {state: {id, activeIndex}})
  }

  const handleNavigateActive = async (id: number, status: string, appointmentDate: string) => {
    const today = new Date().toISOString().split('T')[0] // Get today's date in 'YYYY-MM-DD' format
    if (today === appointmentDate) {
      navigate(`/doctor-prescription`, {state: {id: id}})
      try {
        const changesAction = await fetchAppointmentsByFilter(id, status)
        fetchAppointments()
        const message = changesAction.data.message
        toast.success(message)
      } catch (error: any) {
        console.error(error)
        const errorMessage = error.response?.data?.message || 'Failed to update status'
        toast.error(errorMessage)
      }
    } else if (today > appointmentDate) {
      toast.warning('Cannot start prescription for appointments scheduled on a past date')
    } else {
      toast.warning('Cannot start prescription for appointments scheduled on a future date')
    }
  }

  const calculateAge = (dob: any) => {
    if (dob == null) {
      return '--'
    }
    const birthDateObj = new Date(dob)
    const currentDate = new Date()
    let age = currentDate.getFullYear() - birthDateObj.getFullYear()
    if (
      currentDate.getMonth() < birthDateObj.getMonth() ||
      (currentDate.getMonth() === birthDateObj.getMonth() &&
        currentDate.getDate() < birthDateObj.getDate())
    ) {
      age--
    }
    return age
  }

  const getStatusClassName = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return 'badge badge-light-success'
      case 'COMPLETED':
        return 'badge badge-light-primary'
      case 'CANCELLED':
        return 'badge badge-light-danger'
      case 'WAITING':
        return 'badge badge-light-warning'
      default:
        return 'badge badge-light-success'
    }
  }

  const calculateSlotTiming = (appointment: any) => {
    let smallestSlot
    let largestSlot

    const sortedAppointments = appointment.appointment_details?.sort((a: any, b: any) => {
      if (a.appointment_date === b.appointment_date) {
        return a.slot.localeCompare(b.slot)
      } else {
        return a.appointment_date.localeCompare(b.appointment_date)
      }
    })

    if (sortedAppointments?.length === 1) {
      smallestSlot = sortedAppointments?.[0]?.slot || '00:00' // Default value if slot is null or undefined
      largestSlot = moment(smallestSlot, 'HH:mm').add(15, 'minutes').format('HH:mm')
    } else {
      smallestSlot = sortedAppointments?.[0]?.slot || '00:00' // Default value if slot is null or undefined
      largestSlot = sortedAppointments?.[sortedAppointments.length - 1]?.slot || '00:00' // Default value if slot is null or undefined
      largestSlot = moment(largestSlot, 'HH:mm').add(15, 'minutes').format('HH:mm')
    }

    if (appointment.type === 'NOW') {
      return '--'
    }

    return `${moment(smallestSlot, 'HH:mm').format('hh:mm A')}`
  }

  const handleDownloadInvoice = async (id: number) => {
    try {
      const response = await getInvoiceAppointment(id)
      const uint8Array = new Uint8Array(response.data)

      const blob = new Blob([uint8Array], {type: 'application/pdf'})

      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `invoice_${id}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  }

  const handleviewpdf = async (id: number) => {
    try {
      setLoadingPdf(true) // Show loader
      const response = await getInvoiceAppointment(id)
      const pdfData = new Blob([response.data], {type: 'application/pdf'})
      const url = URL.createObjectURL(pdfData)
      setPdfUrl(url)
      setShowModal(true)
    } catch (error) {
      console.error('Error fetching PDF:', error)
    } finally {
      setLoadingPdf(false) // Hide loader
    }
  }

  const handleviewdocument = async (id: number) => {
    try {
      setLoadingPdf(true) // Show loader
      const response = await getDocumentsAppointment(id)
      const pdfData = new Blob([response.data], {type: 'application/pdf'})
      const url = URL.createObjectURL(pdfData)
      setPdfUrl(url)
      setShowModal(true)
    } catch (error) {
      console.error('Error fetching PDF:', error)
    } finally {
      setLoadingPdf(false) // Hide loader
    }
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <div style={{padding: '0 10px'}}>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer bg-table'
              style={{borderRadius: '5px'}}
            >
              <thead className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th
                  style={{width: '10%'}}
                  className='table_token_th cursor-pointer'
                  onClick={() => handleSort()}
                >
                  {intl.formatMessage({id: 'GENERAL.TOKEN_NO'})}
                  <span className='sort-icon cursor-pointer'>
                    <BiSort />
                  </span>
                </th>
                {/* <th style={{width: '20%'}}>{intl.formatMessage({id: 'GENERAL.PATIENT_NAME'})}</th> */}
                {activeIndex === 4 || activeIndex === 5 ? (
                  <th style={{width: '20%'}} className='patient_name_th'>
                    {intl.formatMessage({id: 'GENERAL.PATIENT_NAME'})}
                  </th>
                ) : (
                  <th style={{width: '20%'}} className='patient_name_th'>
                    {intl.formatMessage({id: 'GENERAL.PATIENT_NAME'})}
                  </th>
                )}
                {/* {activeIndex === 4 || activeIndex === 5 ? ( */}
                <th style={{width: '15%'}} className='doctor_name_th'>
                  {intl.formatMessage({id: 'GENERAL.DOCTOR_NAME'})}
                </th>
                {/* ) : loginData?.data?.user_type === 'DOCTOR' || */}
                {/* (loginData?.data?.user_type === 'STAFF' &&
                    loginData?.data?.role.name.toUpperCase() === 'DOCTOR') ? null : (
                  <th className='doctor_name_th'>
                    {intl.formatMessage({id: 'GENERAL.DOCTOR_NAME'})}
                  </th>
                )} */}

                <th style={{width: '15%'}} className='phone_th'>
                  {intl.formatMessage({id: 'GENERAL.PHONE_NO'})}
                </th>
                <th
                  style={{width: '15%'}}
                  className='cursor-pointer slot_time_th'
                  onClick={() => handleSortTime()}
                >
                  {intl.formatMessage({id: 'GENERAL.SLOT'})}
                  <span className='sort-icon cursor-pointer'>
                    <BiSort />
                  </span>
                </th>

                {activeIndex === 3 || activeIndex === 4 || activeIndex === 5 ? (
                  <th style={{width: '10%'}}>{intl.formatMessage({id: 'GENERAL.PRESCRIBE'})}</th>
                ) : activeIndex === 4 || activeIndex === 5 ? null : (
                  <th
                    style={{width: '10%'}}
                    className='cursor-pointer status_th'
                    onClick={() => handleSortStatus()}
                  >
                    {intl.formatMessage({id: 'GENERAL.STATUS'})}
                    <span className='sort-icon cursor-pointer'>
                      <BiSort />
                    </span>
                  </th>
                )}

                {activeIndex === 0 || activeIndex === 1 || activeIndex === 2 ? (
                  <th className='Start_css_th'>
                    {intl.formatMessage({id: 'GENERAL.START_ACTIVE'})}
                  </th>
                ) : (
                  ''
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}>{intl.formatMessage({id: 'GENERAL_ACTION'})}</th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}

                {activeIndex === 3 ||
                activeIndex === 0 ||
                activeIndex === 1 ||
                activeIndex === 2 ? null : (
                  <th style={{width: '10%'}}></th>
                )}
              </thead>

              <tbody className='fw-bold text-center text-gray-800'>
                {appointments?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={
                        activeIndex === 0 ||
                        activeIndex === 1 ||
                        activeIndex === 2 ||
                        activeIndex === 3
                          ? 7
                          : 7
                      }
                    >
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
                      </div>
                    </td>
                  </tr>
                ) : (
                  items.map((e: any) => (
                    <tr key={e.id} className='table_tr'>
                      <td className='token_css mt-3'>{e.token.toString().padStart(2, '0')}</td>
                      <td className='patient_name'>
                        {e.patient.full_name}
                        <br />
                        <span className='text-gray-400'>
                          {e.patient.gender === 'MALE' ? 'M' : 'F'}{' '}
                          {e.patient.date_of_birth && (
                            <span>{calculateAge(e.patient.date_of_birth)} years</span>
                          )}
                        </span>
                      </td>
                      <td className='doctor_name'>{e.appointment_details[0].doctor.full_name}</td>
                      {/* {loginData?.data?.user_type === 'DOCTOR' ||
                      (loginData?.data?.user_type === 'STAFF' &&
                        loginData?.data?.role.name.toUpperCase() === 'DOCTOR') ? null : (
                        <td className='doctor_name'>{e.appointment_details[0].doctor.full_name}</td>
                      )} */}

                      <td className='phone_no'>
                        {e.patient.contact_number ? e.patient.contact_number : '--'}
                      </td>
                      <td>{calculateSlotTiming(e)}</td>

                      {activeIndex === 3 || activeIndex === 4 || activeIndex === 5 ? (
                        <td className='btn mt-3 display_icon_css' style={{width: '125px'}}>
                          {activeIndex === 4 || activeIndex === 5 ? (
                            <>
                              <i
                                className='fa-solid fa-prescription-bottle-medical fs-1 prescription_icon'
                                onClick={() => handleNavigatePast(e.id)}
                                title='Prescription'
                              ></i>
                              <IoDocumentTextOutline
                                style={{height: '26px', width: '26px', marginLeft: '5px'}}
                                className='document_icon'
                                onClick={() => handleviewdocument(e.id)}
                                title='Medication'
                              />
                            </>
                          ) : (
                            <i
                              className='fa-solid fa-prescription-bottle-medical fs-1 prescription_icon'
                              onClick={() => handleNavigate(e.id)}
                              title='Prescription'
                            ></i>
                          )}

                          <span style={{marginLeft: '5px'}}>
                            <HiOutlineCurrencyRupee
                              style={{height: '26px', width: '26px'}}
                              className='rupee_icon'
                              onClick={() => handleviewpdf(e.id)}
                              title='Invoice'
                            />
                          </span>
                        </td>
                      ) : activeIndex === 4 || activeIndex === 5 ? null : (
                        <td>
                          <span className={`badge ${getStatusClassName(e.status)}`}>
                            {e.status}
                          </span>
                        </td>
                      )}
                      {activeIndex === 0 || activeIndex === 1 || activeIndex === 2 ? (
                        <td
                          className={`cursor-pointer Start_css position-relative ${
                            e.status === 'COMPLETED' || e.status === 'CANCELLED' ? 'disabled' : ''
                          }`}
                          style={{width: '80px', marginTop: '24px'}}
                          onClick={() =>
                            handleNavigateActive(
                              e.id,
                              'ACTIVE',
                              e.appointment_details[0].appointment_date
                            )
                          }
                          title='Start Prescription'
                        >
                          <MdOutlineNotStarted style={{height: '20px', width: '20px'}} />
                          <span style={{marginLeft: '1px'}}>
                            {intl.formatMessage({id: 'GENERAL.START'})}
                          </span>
                        </td>
                      ) : (
                        ''
                      )}
                      {activeIndex === 3 ||
                      activeIndex === 0 ||
                      activeIndex === 1 ||
                      activeIndex === 2 ? null : (
                        <td
                          className='cursor-pointer  Invoice_css position-relative'
                          style={{width: '100px', marginTop: '-39px', left: '116%'}}
                          onClick={() => handleDownloadInvoice(e.id)}
                          title='Print Invoice'
                        >
                          <span>
                            <TbFileInvoice style={{height: '20px', width: '20px'}} />
                          </span>
                          <span className='text_invoice'>
                            {intl.formatMessage({id: 'GENERAL.INVOICE'})}
                          </span>
                        </td>
                      )}

                      {activeIndex == 4 || activeIndex === 5 ? null : (
                        <td role='cell' className='min-w-30px'>
                          <div className=''>
                            <HiDotsVertical
                              className={`${
                                e.status === 'COMPLETED' || e.status === 'CANCELLED'
                                  ? 'disabled'
                                  : ''
                              }`}
                              id='dropdownMenuButton'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                              style={{cursor: 'pointer'}}
                            />
                            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                              {(() => {
                                const appointmentDate = new Date(
                                  e.appointment_details[0].appointment_date
                                )
                                const today = new Date()
                                if (appointmentDate.toDateString() === today.toDateString()) {
                                  return (
                                    <>
                                      <li>
                                        <a
                                          className={`dropdown-item ${
                                            isDropdownItemDisabled(e.status, 'ACTIVE')
                                              ? 'disabled'
                                              : ''
                                          }`}
                                          href='#'
                                          onClick={() => handleStatusChange(e.id, 'ACTIVE')}
                                        >
                                          {intl.formatMessage({id: 'GENERAL.ACTIVE.STATUS'})}
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className={`dropdown-item ${
                                            isDropdownItemDisabled(e.status, 'WAITING')
                                              ? 'disabled'
                                              : ''
                                          }`}
                                          href='#'
                                          onClick={() => handleStatusChange(e.id, 'WAITING')}
                                        >
                                          {intl.formatMessage({id: 'GENERAL.WAITING'})}
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className={`dropdown-item ${
                                            isDropdownItemDisabled(e.status, 'COMPLETED')
                                              ? 'disabled'
                                              : ''
                                          }`}
                                          href='#'
                                          onClick={() => handleDeleteChanges(e.id, 'COMPLETED')}
                                        >
                                          {intl.formatMessage({id: 'GENERAL.COMPLETED'})}
                                        </a>
                                      </li>
                                    </>
                                  )
                                }
                              })()}
                              <li>
                                <a
                                  className={`dropdown-item ${
                                    isDropdownItemDisabled(e.status, 'CANCELLED') ? 'disabled' : ''
                                  }`}
                                  href='#'
                                  onClick={() => handleDeleteChanges(e.id, 'CANCELLED')}
                                >
                                  {intl.formatMessage({id: 'GENERAL.CANCELLED'})}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <DocInfoPagination />
        {isLoading && <DocInfoLoading />}
      </KTCardBody>
      {items.length < appointments.length && (
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<InfiniteLoading />}
          scrollThreshold={0.9} // Load more if scrolled 90% down
          // endMessage={<p>No more data</p>}
          children={null}
        />
      )}

      <ToastContainer />
      {loadingPdf && <DocInfoLoading />}
      {showModal && (
        <>
          <div className='modal-backdrop fade show'></div>
          <div
            className='modal fade show'
            style={{display: 'block'}}
            aria-labelledby='staticBackdropLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content' style={{width: '130%', marginLeft: '-12%'}}>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropLabel'>
                    {intl.formatMessage({id: 'PDF_PREVIEW'})}
                  </h5>
                  <button
                    type='button'
                    className='btn-close'
                    onClick={() => setShowModal(false)}
                    aria-label='Close'
                  ></button>
                </div>
                <div className='modal-body'>
                  {pdfUrl && (
                    <embed src={pdfUrl} type='application/pdf' width='100%' height='500px' />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {DocInfoTable}
