import { ID, Response } from '../../../../../_jmh/helpers'

export interface Staff {
    id?: ID;
    full_name?: string;
    gender?: string;
    image?: any;
    email?: string;
    date_of_birth?: string;
    permanent_address?: string;
    contact_number?: number;
    job_title?: string;
    role_id?: string;
    speciality_ids?: string[];
    user_id?: string;
    shift_id?: string;
    status?: string;
    created_at?: string;
    updated_at?: string;
    speciality?: {
        id?: ID;
        name?: string;
        description?: string;
        status?: string;
        created_at?: string;
        updated_at?: string;
      }
    user?: {
        id?: ID;
        email?: string;
        password?: string;
        is_password_change?: boolean;
        user_type?: string;
        status?: string;
        hospital_id?: string;
        role_id?: string;
        created_at?: string;
        updated_at?: string;
        role?: {
            id?: string;
            name?: string;
            description?: string;
            status?: string;
            created_at?: string;
            updated_at?: string;
        };
        hospital?:  {
            id?: ID;
            name?: string;
            address?: string;
            contact_person?: string;
            contact_number?: string;
            status?: string;
            created_at?: string;
            updated_at?: string;
          }
      };
    shift?:  {
        id?: ID;
        type?: string;
        start_time?: string;
        end_time?: string;
        working_hours?: number;
        days_off?: string;
        status?: string;
        created_at?: string;
        updated_at?: string;
      }
  }
 
export type StaffQueryResponse = Response<Array<Staff>>

export const initialStaff: Staff = {
    full_name: '',
    user : {
        email: '',
        role : {
            name : '',
        },
    },
    status : '',
    email: '',
    image:'',
    permanent_address: '',
}
