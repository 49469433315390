/* eslint-disable jsx-a11y/anchor-is-valid */

import moment from 'moment'
import {FC} from 'react'
import {AuditLog} from '../../core/_models'

type Props = {
  user: AuditLog
}

const AuditLogsDateTimeCell: FC<Props> = ({user}) => {
  const createdAt = moment(user.created_at)
  const date = createdAt.format('DD-MM-YYYY')
  const time = createdAt.format('HH:mm')

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <p className='text-gray-800 mb-1'>
          {date} {time}
        </p>
      </div>
    </div>
  )
}

export {AuditLogsDateTimeCell}
