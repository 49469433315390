import {DoctorListToolbar} from './DoctorListToolbar'
import {DoctorsListSearchComponent} from './DoctorsListSearchComponent'

const DoctorsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <DoctorsListSearchComponent />
      <div className='card-toolbar'>
        <DoctorListToolbar />
      </div>
    </div>
  )
}

export {DoctorsListHeader}
