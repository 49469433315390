import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import { StaffQueryResponse } from '../../../staff-management/StaffList/core/_models'
import {Payroll, UsersQueryResponse} from './_models'
import { Salary } from '../../../Staff Salary/salarys-list/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const GET_STAFF_PAYROLL = `${API_URL}/staff-payroll`
const SALARY_STURUCTURE = `${API_URL}/staff-salary-strcuture`
const GET_STAFF_URL1 = `${API_URL}/staff`

const getPayrolls = (query: string,id:number): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_STAFF_PAYROLL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getPayrollById = (id: ID): Promise<Payroll | undefined> => {
  return axios
    .get(`${GET_STAFF_PAYROLL}/${id}`)
    .then((response: AxiosResponse<Response<Payroll>>) => response.data)
    .then((response: Response<Payroll>) => response.data)
}

const getPayrollReport = (id: ID): Promise<Payroll | undefined> => {
  return axios
    .get(`${GET_STAFF_PAYROLL}/csv?hospital_id=${id}`)
    .then((response: AxiosResponse<Response<Payroll>>) => response.data)
    .then((response: Response<Payroll>) => response.data)
}

const getPaySlip = (id: ID): Promise<Payroll | undefined> => {
  return axios
    .post(`${GET_STAFF_PAYROLL}/payslip/${id}`)
    .then((response: AxiosResponse<Response<Payroll>>) => response.data)
    .then((response: Response<Payroll>) => response.data)
}

const createPayroll = (user: Payroll): Promise<Payroll | undefined> => {
  return axios
    .post(GET_STAFF_PAYROLL, user)
}
const ApproveRejectPayroll = (id:ID,user: Payroll): Promise<Payroll | undefined> => {
  return axios
    .post(`${GET_STAFF_PAYROLL}/${id}`,{approve:user})
}

const updatePayroll = (user: Payroll, id?:any): Promise<Payroll | undefined> => {
  return axios
    .patch(`${GET_STAFF_PAYROLL}/${id}`, user)
}

const deletePayroll = (Id: ID): Promise<void> => {
  return axios.delete(`${GET_STAFF_PAYROLL}`,{data: { ids: [Id]}}).then(() => {})
}


const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getStaffData = (id?:number): Promise<StaffQueryResponse> => {
  return axios
    .get(`${GET_STAFF_URL1}?${`limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<StaffQueryResponse>) => d.data)
}


// const getStaffData = (query?: string): Promise<StaffQueryResponse> => {
//   return axios
//     .get(`${GET_STAFF_URL}`)
//     .then((d: AxiosResponse<StaffQueryResponse>) => d.data)
// }

// {{APP}}/staff-salary-strcuture?staff_id=1&salary_month=1&salary_year=2023

const getSalaryById = (id?: ID, month?:any,year?:any): Promise<Salary | undefined> => {
  return axios
    .get(`${SALARY_STURUCTURE}?staff_id=${id}&salary_month=${month}&salary_year=${year}`)
    .then((response: AxiosResponse<Response<Salary>>) => response.data)
    .then((response: Response<Salary>) => response.data)
}

export {getPayrolls,getPaySlip,ApproveRejectPayroll, deletePayroll,getSalaryById, deleteSelectedUsers, getPayrollReport,getPayrollById, createPayroll, updatePayroll,getStaffData}
