import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_jmh/helpers'
import {
  createDoctorAvaliblity,
  createRepeatDoctorAvaliblity,
  deleteDoctorAvaliblity,
  getDoctorData,
  getDoctors,
} from '../core/_requests'
import {toast} from 'react-toastify'
import {loginContext} from '../../../context/context'
import moment from 'moment'
import Swal from 'sweetalert2'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import CommonLoader from '../../../../_jmh/Utils/CommonLoader'
import {useIntl} from 'react-intl'

const initialTimes = [
  {
    date: moment().format('DD-MM-YYYY'),
    slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
  },
  {
    date: moment().add(1, 'days').format('DD-MM-YYYY'),
    slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
  },
  {
    date: moment().add(2, 'days').format('DD-MM-YYYY'),
    slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
  },
  {
    date: moment().add(3, 'days').format('DD-MM-YYYY'),
    slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
  },
  {
    date: moment().add(4, 'days').format('DD-MM-YYYY'),
    slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
  },
  {
    date: moment().add(5, 'days').format('DD-MM-YYYY'),
    slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
  },
  {
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    slots: [
      {start_time: null, end_time: null, isChanged: false, id: null, doc_appointment_id: null},
    ],
  },
]

function AvalabilityTable() {
  const [times, setTimes] = useState(initialTimes)
  const [getAllData, setGetAllData] = useState<any>([])
  const [refetchId, setRefetchId] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const {selectedDoctorData, setSelectedDoctorData, selectedHospitalId, loginData} =
    useContext(loginContext)
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [repeatForAll, setRepeatForAll] = useState(false)
  const [isRepeatCheckboxDisabled, setIsRepeatCheckboxDisabled] = useState(true)
  const intl = useIntl()

  useEffect(() => {
    ;(async () => {
      const allDoctorData: any = await getDoctors()

      const activeDoctorData = allDoctorData?.data?.filter((item: any) => {
        return item?.status === 'ACTIVE' && item?.user?.hospital_id == selectedHospitalId
      })

      if (activeDoctorData) {
        setSelectedOption(activeDoctorData?.[0]?.id)
      }

      setGetAllData(activeDoctorData)
    })()
  }, [selectedHospitalId])

  const doctorData =
    loginData?.data?.user_type === 'DOCTOR' && loginData?.data?.personal_details?.id

  function generateAvailabilityJSON(dayData: any) {
    const availability: any = {
      doctor_id: doctorData ? Number(doctorData) : Number(selectedOption),
      hospital_id: Number(selectedHospitalId),
      time: [],
    }

    // Loop through each slot in the provided dayData
    dayData?.slots.forEach((slot: any) => {
      // Add an object to the `time` array for this slot
      if (slot.start_time !== null || slot.end_time !== null) {
        availability.time.push({
          date: dayData.date,
          start_time: slot.start_time,
          end_time: slot.end_time,
          isChanged: false,
          id: slot.id,
          doc_appointment_id: slot?.doc_appointment_id,
        })
      }
    })

    times.forEach((day: any) => {
      // Check if slots.length is greater than 0 and the date is different from dayData.date
      if (day.slots.length > 0 && day.date !== dayData.date) {
        // Loop through each slot in the day
        day.slots.forEach((slot: any) => {
          // Add an object to the `time` array for this slot
          if ((slot.start_time !== null || slot.end_time !== null) && slot.id !== null) {
            availability.time.push({
              date: day.date,
              start_time: slot.start_time,
              end_time: slot.end_time,
              isChanged: slot.isChanged || false,
              id: slot.id,
              doc_appointment_id: slot?.doc_appointment_id,
            })
          }
        })
      }
    })

    return availability
  }

  function generateRepeatAvailabilityJSON(times: any) {
    const availability: any = {
      doctor_id: doctorData ? Number(doctorData) : Number(selectedOption),
      hospital_id: Number(selectedHospitalId),
      time: [],
    }

    // Loop through each day in `times`
    times?.forEach((day: any) => {
      // Loop through each slot in the day
      day.slots.forEach((slot: any) => {
        if (slot.start_time !== null || slot.end_time !== null) {
          availability.time.push({
            date: day.date,
            start_time: slot.start_time,
            end_time: slot.end_time,
            isChanged: false,
            id: slot.id,
            doc_appointment_id: slot?.doc_appointment_id,
          })
        }
      })
    })

    return availability
  }

  // const availabilityJSON = generateAvailabilityJSON(times)
  const repeatAvailabilityJSON = generateRepeatAvailabilityJSON(times)

  const handleTimeChange = (
    dayIndex: number,
    slotIndex: number,
    field: string,
    value: moment.Moment | null
  ) => {
    const newTimes: any = [...times]
    if (value !== null) {
      newTimes[dayIndex].slots[slotIndex][field] = moment(value).format('HH:mm')
    } else {
      newTimes[dayIndex].slots[slotIndex][field] = null
    }
    newTimes[dayIndex].slots[slotIndex]['isChanged'] = true
    setTimes(newTimes)
  }

  const handleAddTime = (dayIndex: any) => {
    const newTimes = [...times]

    const newSlot = {
      start_time: null,
      end_time: null,
      isChanged: false,
      id: null,
      doc_appointment_id: null,
    }

    newTimes[dayIndex] = {
      ...newTimes[dayIndex],
      slots: newTimes[dayIndex].slots.concat(newSlot),
    }

    if (repeatForAll) {
      for (let i = dayIndex + 1; i < newTimes.length; i++) {
        if (newTimes[i].slots.length === 0) {
          newTimes[i] = {
            ...newTimes[i],
            slots: newTimes[i].slots.map((slot) => ({
              ...slot,
              start_time: slot.start_time || newSlot.start_time,
              end_time: slot.end_time || newSlot.end_time,
              isChanged: false,
            })),
          }
        }
      }
    }

    setTimes(newTimes)

    setIsRepeatCheckboxDisabled(false)
  }

  const handleRemove = (id: any, slotIndex: any, dayIndex: any) => {
    if (id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          title: intl.formatMessage({id: 'VALIDATION.DELETE_ACTION'}),
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({id: 'GENERAL.DELETE'}),
          cancelButtonText: intl.formatMessage({id: 'GENERAL.CLOSE'}),
          reverseButtons: true,
        })
        .then(async (result: any) => {
          if (result.isConfirmed) {
            try {
              await deleteDoctorAvaliblity(id)
              const newTimes = [...times]
              newTimes[dayIndex].slots.splice(slotIndex, 1)
              setTimes(newTimes)

              swalWithBootstrapButtons.fire(
                intl.formatMessage({id: 'GENERAL.DELETED'}),
                intl.formatMessage({id: 'GENERAL.DOCTOR_AVAILABILITY.DELETED'}),
                'success'
              )
            } catch (error) {
              swalWithBootstrapButtons.fire(
                intl.formatMessage({id: 'GENERAL.ERROR'}),
                intl.formatMessage({id: 'GENERAL.FAILED.DOCTOR_AVAILABILITY.DELETE_ACTION'}),
                'error'
              )
            }
          }
        })
    } else {
      const newTimes = [...times]
      newTimes[dayIndex].slots.splice(slotIndex, 1)
      setTimes(newTimes)
    }
  }
  const handleRepeatChange = async (event: any) => {
    setRepeatForAll(event.target.checked)
    if (event.target.checked) {
      await createRepeatDoctorAvaliblity(repeatAvailabilityJSON).then((res: any) => {
        toast.success(res?.data?.message)
        listData()
        setRepeatForAll(false)
      })
      const updatedTime = times?.map((day) => ({
        ...day,
        slots: day.slots.map((slot) => ({
          ...slot,
          isChanged: false,
        })),
      }))
      setTimes(updatedTime)
    }
  }

  const handlesubmit = async (dayIndex: number) => {
    try {
      const dayData = times[dayIndex]
      const dayAvailabilityJSON = generateAvailabilityJSON(dayData)
      await createDoctorAvaliblity(dayAvailabilityJSON).then((res: any) => {
        toast.success(res?.data?.message)

        const updatedTime = times?.map((day) => ({
          ...day,
          slots: day.slots.map((slot) => ({
            ...slot,
            isChanged: false,
          })),
        }))
        setTimes(updatedTime)
        setIsRepeatCheckboxDisabled(false)
      })
    } catch (error: any) {
      toast.warning(error.response?.data?.message)
    } finally {
      setRefetchId(!refetchId)
    }
  }

  const listData = async () => {
    setLoading(true)
    try {
      const allData = await getDoctorData(
        doctorData ? Number(doctorData) : Number(selectedOption),
        selectedHospitalId
      )
      setData(allData)
      setLoading(false)
      if (allData) {
        const initialTimes = [
          {
            date: moment().format('DD-MM-YYYY'),
            slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
          },
          {
            date: moment().add(1, 'days').format('DD-MM-YYYY'),
            slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
          },
          {
            date: moment().add(2, 'days').format('DD-MM-YYYY'),
            slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
          },
          {
            date: moment().add(3, 'days').format('DD-MM-YYYY'),
            slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
          },
          {
            date: moment().add(4, 'days').format('DD-MM-YYYY'),
            slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
          },
          {
            date: moment().add(5, 'days').format('DD-MM-YYYY'),
            slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
          },
          {
            date: moment().add(6, 'days').format('DD-MM-YYYY'),
            slots: [{start_time: null, end_time: null, isChanged: false, id: null}],
          },
        ]
        setTimes(initialTimes)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listData()
  }, [selectedOption, refetchId])

  useEffect(() => {
    const tData: any = []
    if (data?.data?.data?.length > 0) {
      data?.data?.data &&
        data?.data?.data.forEach((d: any) => {
          const obj = {
            date: moment(d.date).format('DD-MM-YYYY'),
            slots: [
              {
                start_time: d.start_time,
                end_time: d.end_time,
                id: d.id,
                doc_appointment_id: d.doc_appointment_id,
              },
            ],
          }

          if (tData.length) {
            let foundDay = false
            tData.forEach((td: any) => {
              if (td.date === d.date) {
                td.slots.push(obj.slots[0])
                foundDay = true
              }
            })
            if (!foundDay) {
              tData.push(obj)
            }
          } else {
            tData.push(obj)
          }
        })

      const test: any = []

      tData?.forEach((t: any) => {
        times?.forEach((time) => {
          if (time.date === t.date) {
            test.push({
              date: t.date,
              slots: t.slots,
            })
          }
        })

        const mergedArray: any = []

        initialTimes.forEach((initialTime) => {
          const slots: any = []

          test.forEach((secondTime: any) => {
            if (secondTime.date === initialTime.date) {
              slots.push(...secondTime.slots)
            }
          })

          mergedArray.push({
            date: initialTime.date,
            slots: slots,
          })
        })

        setTimes(mergedArray)
      })
    }
  }, [data])

  useEffect(() => {
    setSelectedDoctorData(times)
  }, [times, selectedHospitalId, setSelectedDoctorData])

  function handleSelectChange(event: any) {
    setSelectedOption(event.target.value)
  }

  useEffect(() => {
    if (selectedOption === '' && getAllData.length > 0) {
      setSelectedOption(getAllData[0]?.id)
    }
  }, [selectedOption, getAllData])

  const [disabled, setDisabled] = useState(true)
  const permissionData = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'doctor-availability') {
      return item?.module_id
    }
  })

  const add = permissionData?.filter((item: any) => {
    if (item.permission.method === 'POST') {
      return item
    }
  })

  useEffect(() => {
    if (add && add.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [add])

  useEffect(() => {
    const firstDayHasEmptySlot = times?.length > 0 && times?.[0]?.slots.length === 0
    if (times.length > 0) {
      const firstDaySlots = times[0].slots
      const otherDaysSlots = times.slice(1).map((day) => day.slots)

      const hasDifferences = otherDaysSlots.some((daySlots) => {
        if (firstDaySlots.length !== daySlots.length) {
          return true
        }

        for (let i = 0; i < firstDaySlots.length; i++) {
          const firstSlot = firstDaySlots[i]
          const otherSlot = daySlots[i]

          if (
            firstSlot.start_time !== otherSlot.start_time ||
            firstSlot.end_time !== otherSlot.end_time
          ) {
            return true
          }
        }

        return false
      })

      setIsRepeatCheckboxDisabled(firstDayHasEmptySlot || !hasDifferences)
    }
  }, [times])

  return (
    <div className='card card-custom shadow'>
      {loading ? (
        <div className='d-flex align-items-center justify-content-center' style={{height: '100vh'}}>
          <CommonLoader />
        </div>
      ) : (
        <>
          {loginData?.data?.user_type === 'DOCTOR' ? (
            ''
          ) : (
            <form className='d-flex justify-content-between align-items-center flex-wrap'>
              <select
                className='form-select form-select-solid mt-5 w-25'
                aria-label='Select example'
                onChange={handleSelectChange}
                value={selectedOption}
              >
                {getAllData &&
                  getAllData?.map((item: any, ind: any) => (
                    <option key={ind} value={item?.id}>
                      {item?.full_name}
                    </option>
                  ))}
              </select>
              <div className='repeat_time_wrapper d-flex justify-content-between gap-3'>
                <input
                  type='checkbox'
                  id='repeat-checkbox'
                  checked={repeatForAll}
                  onChange={handleRepeatChange}
                  disabled={isRepeatCheckboxDisabled}
                />
                <label htmlFor='repeat-checkbox'>Repeat for all ?</label>
              </div>
            </form>
          )}
          <div className='card-body TimePicker-card'>
            {times?.map((time, dayIndex) => {
              const currentDate = moment().add(dayIndex, 'days').format('DD-MM-YYYY')
              return (
                <div className='doctorAvalablity' key={dayIndex}>
                  <div className='days'>
                    <p>{currentDate}</p>
                  </div>
                  <div className='card time'>
                    {time?.slots.length > 0 &&
                      time?.slots.map((slot, slotIndex) => {
                        return (
                          <div className='picker me-2 mb-2' key={slotIndex}>
                            <TimePicker
                              value={slot.start_time ? moment(slot.start_time, 'HH:mm') : undefined}
                              onChange={(value) =>
                                handleTimeChange(dayIndex, slotIndex, 'start_time', value)
                              }
                              disabled={disabled}
                              showSecond={false}
                              minuteStep={15}
                              format='HH:mm'
                              className='shadow'
                              placeholder={intl.formatMessage({id: 'GENERAL.SELECT.TIME'})}
                            />
                            to
                            <TimePicker
                              value={slot.end_time ? moment(slot.end_time, 'HH:mm') : undefined}
                              onChange={(value) =>
                                handleTimeChange(dayIndex, slotIndex, 'end_time', value)
                              }
                              disabled={disabled}
                              showSecond={false}
                              minuteStep={15}
                              format='HH:mm'
                              className='shadow'
                              placeholder={intl.formatMessage({id: 'GENERAL.SELECT.TIME'})}
                            />
                            <div className='d-flex align-items-center'>
                              {add.length ? (
                                <button
                                  className='btn btn-icon btn-circle btn-color-danger'
                                  onClick={() => handleRemove(slot?.id, slotIndex, dayIndex)}
                                >
                                  <span className='mx-2'>
                                    <i className='fa-solid fa-xmark fs-2'></i>
                                  </span>
                                </button>
                              ) : (
                                ''
                              )}
                              {slot.start_time && slot.end_time && slot.isChanged ? (
                                <button
                                  className='btn btn-icon btn-circle btn-color-success'
                                  style={{marginLeft: '-15px'}}
                                  onClick={() => handlesubmit(dayIndex)}
                                >
                                  <span className='mx-2'>
                                    <i className='fa-solid fa-check fs-2'></i>
                                  </span>
                                </button>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )
                      })}
                    {add.length ? (
                      <button
                        className='btn btn-icon btn-circle btn-color-primary mt-4'
                        style={{marginLeft: '5px'}}
                        onClick={() => handleAddTime(dayIndex)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen035.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default AvalabilityTable
