import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios'
import {loginContext} from '../../context/context'
import {KTSVG} from '../../../_jmh/helpers'

interface Hospital {
  id: number
  name: string
}

interface HospitalSelectProps {
  onChange: (selectedHospital: string) => void
}

const HospitalSelect: React.FC<HospitalSelectProps> = ({onChange}) => {
  const {loginData} = useContext(loginContext)
  const [hospitalListData, setHospitalListData] = useState<any>(null)
  const [selectedHospital, setSelectedHospital] = useState<string>('1')
  const API_URL = process.env.REACT_APP_API_URL
  const GET_HOSPITAL = `${API_URL}/hospital`
  const GET_HOSPITAL_BY_ID = `${API_URL}/hospital`
  const {selectedHospitalId} = useContext(loginContext)

  useEffect(() => {
    if (loginData?.data?.user_type === 'DOCTOR' && loginData?.data?.hospital?.id) {
      setSelectedHospital(loginData?.data?.hospital?.id.toString())
      onChange(loginData?.data?.hospital?.id.toString())
    }
    if (loginData?.data?.user_type === 'SUPER_ADMIN') {
      axios
        .get<any>(GET_HOSPITAL)
        .then((response) => {
          setHospitalListData(response.data)
        })
        .catch((error) => {})
    } else {
      axios
        .get<any>(`${GET_HOSPITAL_BY_ID}/${loginData?.data?.hospital?.id}`)
        .then((response) => {
          setHospitalListData(response.data)
        })
        .catch((error) => {})
    }
  }, [])

  const handleSelectionChange = (id: any) => {
    setSelectedHospital(id)
    onChange(id)
  }

  return (
    <>
      {loginData?.data?.user_type !== 'PATIENT' && (
        <div>
          <button
            type='button'
            value={selectedHospital || selectedHospitalId}
            className='rotate  btn-bg-secondary btn-active-primary head_hospital_list w-auto min-w-150px gap-3'
            // onClick={handleSelectionChange}
            style={{justifyContent: 'space-between'}}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            disabled={hospitalListData?.data?.length > 0 ? false : true}
          >
            {loginData?.data?.user_type === 'SUPER_ADMIN' ? (
              <>
                {Array.isArray(hospitalListData?.data) &&
                  hospitalListData?.data?.find(
                    (hospital: Hospital) => hospital.id.toString() === selectedHospital
                  )?.name}

                <div className='svg-icon rotate-180 me-0'>
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-3' />
                </div>
              </>
            ) : (
              <>{selectedHospital && loginData?.data?.hospital?.name}</>
            )}
          </button>
          <div
            className=' menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-250px '
            data-kt-menu='true'
          >
            <div className='menu-item px-3'>
              {hospitalListData?.data?.length > 0 ? (
                <>
                  {hospitalListData?.data?.map((hospital: Hospital) => (
                    <div
                      className='menu-link px-3 my-3'
                      key={hospital.id}
                      onClick={() => handleSelectionChange(hospital?.id)}
                    >
                      {hospital.name}
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <option value={hospitalListData?.data?.id?.toString()}>
                    {hospitalListData?.data?.name}
                  </option>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default HospitalSelect
