import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const ALL_APPOINTMENT = `${API_URL}/patient-appointment/appointment`;

const getAllAppointments = (hospital_id: number,  filter: string, appointmentDate: string, sort?: string | null, order?: string | null) => {
 
  const loginDataformlocal = localStorage.getItem('loginData')
  const localData = JSON.parse(String(loginDataformlocal))
  if(localData?.data.user_type === 'SUPER_ADMIN'){
    hospital_id=1;
  }
  else{
    hospital_id=localData.data.hospital.id
  }
  let url = `${ALL_APPOINTMENT}?hospital_id=${hospital_id}&filter=${filter}&appointment_date=${appointmentDate}`;
  
  if (sort && order) {
    url += `&sort=${sort}&order=${order}`
  }
  
  if(localData?.data.user_type==='DOCTOR'){
    const doctor_id=localData.data.personal_details.id;
    url += `&doctor_id=${doctor_id}`;
  }
  if(localData?.data.user_type === 'STAFF'){
    const roleName = localData?.data?.role.name
    const roleUP = roleName.toUpperCase();
    const personalId = localData?.data?.personal_details?.id
    if (roleUP === 'DOCTOR') {
      url += `&doctor_id=${personalId}`;
    }   
  }
  
  return axios.get(url);
};


const fetchAppointmentsByFilter = async (id: number, filter: string) => {
  const url = `${API_URL}/patient-appointment/status/${id}?status=${filter}`;
  return axios.patch(url);
};

const deleteFetchAppointmentsByFilter = async (id: number, filter: string) => {
  const url = `${API_URL}/patient-appointment/${id}?status=${filter}`;
  return axios.delete(url);
};

const getInvoiceAppointment = async (id:number) => {
  const url = `${API_URL}/patient-appointment/patient-invoice/${id}`;
  return axios.get(url, { responseType: 'arraybuffer' });
}

const getDocumentsAppointment = async (id:number) => {
  const url = `${API_URL}/patient-appointment/patient-medication/${id}`;
  return axios.get(url, { responseType: 'arraybuffer' });
}

export { getAllAppointments,fetchAppointmentsByFilter, deleteFetchAppointmentsByFilter ,getInvoiceAppointment,getDocumentsAppointment};
