// @ts-nocheck
import {Column} from 'react-table'
import {Patient} from '../../core/_models'
import {PatientInfoCell} from './PatientInfoCell'
import {PatientCustomHeader} from './PatientCustomHeader'
import {PatientActionsCell} from './PatientActionsCell'
import {PatientContactNumberCell} from './PatientContactNumberCell'
import {PatientEmailCell} from './PatientEmailCell'
import {PatientStatusCell} from './PatientStatusCell'
import {FormattedMessage} from 'react-intl'

const patientsColumns: ReadonlyArray<Column<Patient>> = [
  {
    Header: (props) => (
      <PatientCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GENERAL.PATIENT.FULL.NAME' />}
        className='min-w-125px'
      />
    ),
    id: 'full_name',
    Cell: ({...props}) => <PatientInfoCell patient={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <PatientCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GENERAL.CONTACT.NUMBER' />}
        className='min-w-125px'
      />
    ),
    accessor: 'contact_number',
    Cell: ({...props}) => <PatientContactNumberCell patient={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <PatientCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GENERAL.EMAIL' />}
        className='min-w-125px'
      />
    ),
    id: 'email',
    Cell: ({...props}) => <PatientEmailCell patient={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <PatientCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GENERAL.STATUS' />}
        className='min-w-125px'
      />
    ),
    id: 'Status',
    Cell: ({...props}) => <PatientStatusCell patient={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <PatientCustomHeader
        tableProps={props}
        title={<FormattedMessage id='GENERAL.ACTION' />}
        className='text-end min-w-100px pe-5'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <PatientActionsCell
        id={props.data[props.row.index].id}
        patient={props.data[props.row.index]}
      />
    ),
  },
  // {
  //   Header: (props) => {
  //     const {loginData} = useContext(loginContext)

  //     const data1: any = loginData?.data?.permissions?.filter((item: any) => {
  //       if (item.module.name === 'doctor') {
  //         return item?.module_id
  //       }
  //     })

  //     const Get = data1?.filter((item: any) => {
  //       if (
  //         (item.permission.method === 'GET' && item.permission.route === ':id') ||
  //         item.permission.method === 'POST' ||
  //         item.permission.method === 'PATCH' ||
  //         item.permission.method === 'DELETE'
  //       ) {
  //         return item
  //       }
  //     })

  //     return Get.length ? (
  //       <PatientCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //     ) : (
  //       ''
  //     )
  //   },
  //   id: 'actions',
  //   Cell: ({...props}) => {
  //     const {loginData} = useContext(loginContext)

  //     const data1: any = loginData?.data?.permissions?.filter((item: any) => {
  //       if (item.module.name === 'patient') {
  //         return item?.module_id
  //       }
  //     })

  //     const Get = data1?.filter((item: any) => {
  //       if (
  //         (item.permission.method === 'GET' && item.permission.route === ':id') ||
  //         item.permission.method === 'POST' ||
  //         item.permission.method === 'PATCH' ||
  //         item.permission.method === 'DELETE'
  //       ) {
  //         return item
  //       }
  //     })
  //     return Get.length ? <PatientActionsCell id={props.data[props.row.index].id} /> : null
  //   },
  // },
]

export {patientsColumns}
