/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AssetModel} from '../../core/_models'
import moment from 'moment'

type Props = {
  asset: AssetModel
}

const AssetPurchaseDateCell: FC<Props> = ({asset}) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <div className='text-gray-800 mb-1'>
            {moment(asset?.purchase_date).format('DD-MM-YYYY')}
          </div>
        </div>
      </div>
    </>
  )
}

export {AssetPurchaseDateCell}
