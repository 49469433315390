import {KTCard} from '../../../../_jmh/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AssetMaintenanceListHeader} from './components/header/AssetMaintenanceListHeader'
import {AssetMaintenanceTable} from './table/AssetMaintenanceTable'
import {AssetMaintenanceEditModal} from './assetMaintenance-edit-modal/AssetMaintenanceEditModal'

const AssetMaintenanceList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <AssetMaintenanceListHeader />
        <AssetMaintenanceTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AssetMaintenanceEditModal />}
    </>
  )
}

const AssetMaintenanceListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AssetMaintenanceList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AssetMaintenanceListWrapper}
