import {PurchaseInvListToolbar} from './PurchaseInvListToolbar'
import {PurchaseInvListSearchComponent} from './PurchaseInvListSearchComponent'

const PurchaseInvListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <PurchaseInvListSearchComponent />
      <div className='card-toolbar'>
        <PurchaseInvListToolbar />
      </div>
    </div>
  )
}

export {PurchaseInvListHeader}
