import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {PatientsQueryResponse} from '../../../Patient-management/PatientList/core/_models'
import {Appointment, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
const GET_PATIENT_URL = `${API_URL}/patient`
const APPOINTMENT_URL = `${API_URL}/patient-appointment`
const CREATE_DOCTOR_URL = `${API_URL}/doctor-availability`

const getPatientAppointments = (query: string, id: number): Promise<UsersQueryResponse> => {
  return axios
    .get(
      `${APPOINTMENT_URL}?${
        query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`
      }`
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
    
}

const getAppointmentById = (id: ID): Promise<Appointment | undefined> => {
  return axios
    .get(`${APPOINTMENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Appointment>>) => response.data)
    .then((response: Response<Appointment>) => response.data)
}

const getAppointmentReport = (id: ID): Promise<Appointment | undefined> => {
  return axios
    .get(`${APPOINTMENT_URL}/csv?hospital_id=${id}`)
    .then((response: AxiosResponse<Response<Appointment>>) => response.data)
    .then((response: Response<Appointment>) => response.data)
}

const createAppointment = (user: Appointment,doctor_appointment_id?:any): Promise<Appointment | undefined> => {
  const data = {...user, ...doctor_appointment_id}
  return axios
    .post(APPOINTMENT_URL, data)
}

const updateAppointment = (user: Appointment): Promise<Appointment | undefined> => {
  return axios
    .patch(`${APPOINTMENT_URL}/${user.id}`, user)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${APPOINTMENT_URL}/${userId}?status=CANCELLED`).then(() => {})
}

const completedUser = (userId: ID): Promise<void> => {
  return axios.delete(`${APPOINTMENT_URL}/${userId}?status=COMPLETED`)
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getPatientsforAppointment = (query?: string): Promise<PatientsQueryResponse> => {
  return axios.get(`${GET_PATIENT_URL}`).then((d: AxiosResponse<PatientsQueryResponse>) => d.data)
}

const getDoctorData1 = (id?: number, date?: any) => {
  return axios
    .get(`${CREATE_DOCTOR_URL}?${`doctorId=${id}&date=${date}`}`)
    .then((d: any) => d.data)
    .then((response: any) => response)
}

const getDoctorDataAva = (hos_id?: any, type?: string) => {
  return axios
    .get(`${CREATE_DOCTOR_URL}?${`hospital_id=${hos_id}&type=${type}`}`)
    .then((d: any) => d.data)
    .then((response: any) => response)
}

export {
  getPatientAppointments,
  deleteUser,
  deleteSelectedUsers,
  getAppointmentReport,
  getAppointmentById,
  createAppointment,
  updateAppointment,
  getPatientsforAppointment,
  getDoctorData1,
  getDoctorDataAva,
  completedUser,
}
