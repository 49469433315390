import axios, {AxiosResponse} from 'axios'
import { Response } from '../../../../../_jmh/helpers'
import {MedicalBackground, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const PATIENT_MEDICAL_BACK = `${API_URL}/patient-medical-background`


const getMedicalBackground = (query: string,id:number,hosId:number): Promise<UsersQueryResponse> => {
  return axios
    // .get(`${PATIENT_MEDICAL_BACK}?patient_id=${id}&${query}`)
    .get(`${PATIENT_MEDICAL_BACK}?${query ? `${query}&limit=10&patient_id=${id}&hospital_id=${hosId}` : `limit=10&hospital_id=${hosId}&patient_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}


const updateMedicalBackground = (user: any): Promise<MedicalBackground | undefined> => {
  return axios
    .patch(`${PATIENT_MEDICAL_BACK}`, user)
}

export {getMedicalBackground, updateMedicalBackground}
