import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {AssetMaintenanceListWrapper} from './assetMaintenance-List/AssetMaintenanceListWrapper'

const AssetMaintenance = () => (
  <>
    <div className=''>
      <AssetMaintenanceListWrapper />
    </div>
  </>
)

const AssetMaintenanceWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ASSET.ASSET.MAINTENANCE'})}</PageTitle>
      <AssetMaintenance />
    </>
  )
}

export {AssetMaintenanceWrapper}
