import {ID, Response} from '../../../../../_jmh/helpers'

export type MedicalNotes = {
  id?: ID
  title?: string
  description?: string
  created_at?: string
  updated_at?: string
  hospital_id?: number
  date?: string
  patient_id?:
    | {
        id: string
        hospital_id: string
        full_name: string
        gender: string
        contact_number: number
        date_of_birth: string
        permanent_address: string
        email: string
        alternate_contact_number: string
        blood_group: string
        status: string
      }
    | number
}

export type MedicalNotesQueryResponse = Response<Array<MedicalNotes>>

export const initialUser: MedicalNotes = {
  title: '',
  description: '',
  date: '',
}
