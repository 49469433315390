import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialUser, MedicalNotes} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {MedicalNotesListLoading} from '../components/loading/MedicalNotesListLoading'
import {createMedicalNotes, updateMedicalNotes} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: MedicalNotes
}

const editUserSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Minimum 3 Characters')
    .max(50, 'Maximum 50 Characters')
    .required('Medical note title is required')
    .test(
      'noLeadingSpaces',
      'Invalid Input, Please avoid leading spaces at the beginning of the field',
      (value) => {
        return !value || value.trimStart() === value
      }
    ),
})

const MedicalNoteEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {selectedPatientId, selectedAppointment} = useContext(loginContext)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const {id} = useParams()

  const [userForEdit] = useState<MedicalNotes>({
    ...user,
    title: user.title || initialUser.title,
    description: user.description || initialUser.description,
    // date: user.date || initialUser.date,
    date: moment(user.date).format('DD MMM,YY') || initialUser.date, // Format the date as 'YYYY-MM-DD'
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          values['date'] =
            moment(values?.date).format('DD-MM-YYYY') || moment(new Date()).format('DD-MM-YYYY')
          await updateMedicalNotes(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          values['date'] =
            moment(values?.date).format('DD-MM-YYYY') || moment(new Date()).format('DD-MM-YYYY')
          await createMedicalNotes({
            ...values,
            patient_id: Number(selectedPatientId ? selectedPatientId : id),
            appointment_id: selectedAppointment
              ? selectedAppointment?.value === 'all'
                ? ''
                : Number(selectedAppointment?.value)
              : '',
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(false)
        cancel(true)
      }
    },
  })

  useEffect(() => {
    setSelectedDate(new Date())
  }, [])

  // const minDate = new Date()
  // const maxDate = addDays(new Date(), 2)
  const maxDate = new Date()

  return (
    <>
      <form id='kt_modal_add_user_form3' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* Rest of the form */}
          <div className='row'>
            <div className='col-3'>
              <div className='position-relative'>
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('date')}
                  dropdownMode='select'
                  className='form-control border-0 cursor-pointer mb-3 mb-lg-0'
                  dateFormat='dd MMM,yy'
                  name='date'
                  // maxDate={minDate}
                  maxDate={maxDate}
                  selected={selectedDate}
                  onChange={(date) => {
                    formik.setFieldValue('date', moment(date).format('DD MMM,YY'))

                    setSelectedDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('date')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>
            </div>
            <div className='col-3'>
              <span className='form-control border-0'>
                {formik.values.date}
                {moment(formik.values.date).isSame(new Date(), 'day') && ' (Today)'}
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <input
                placeholder={intl.formatMessage({id: 'MEDICAL_NOTES.NEW.NOTE'})}
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className={clsx('form-control mb-3 mb-lg-0')}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />

              {formik.touched.title && formik.errors.title && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.title}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-9'>
              <textarea
                rows={15}
                cols={50}
                placeholder={intl.formatMessage({id: 'MEDICAL_NOTES.START.TYPING'})}
                {...formik.getFieldProps('description')}
                name='description'
                className={clsx('form-control resize-none mb-3 mb-lg-0')}
                autoComplete='off'
              />
            </div>
          </div>
        </div>
        <div className='text-end pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'> {intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* Rest of the form */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <MedicalNotesListLoading />}
    </>
  )
}

export {MedicalNoteEditModalForm}
