import {useContext, useState} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {AssetListLoading} from '../loading/AssetListLoading'
import {getAssetReport} from '../../core/_requests'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

const AssetListToolbar = () => {
  const intl = useIntl()
  const tabledata = useQueryResponseData()
  const {selectedHospitalId} = useContext(loginContext)
  const {setItemIdForUpdate} = useListView()
  const [isLoading, setIsLoading] = useState(false)
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const handleReportDownload = async () => {
    try {
      const getReportData: any = await getAssetReport(selectedHospitalId)
      const uint8Array = new Uint8Array(getReportData?.data)
      const blob = new Blob([uint8Array], {type: 'text/csv'})
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'asset_report.csv'
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const {loginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'asset') {
      return item?.module_id
    }
  })

  const add = data?.filter((item: any) => {
    if (item.permission.method === 'POST') {
      return item
    }
  })

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className='btn btn-primary me-3'
        disabled={tabledata?.length > 0 ? false : true}
        onClick={handleReportDownload}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'GENERAL.EXPORT'})}
      </button>
      {add.length ? (
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'ASSET.ADD.ASSET'})}
        </button>
      ) : (
        ''
      )}
      {isLoading && <AssetListLoading />}
    </div>
  )
}

export {AssetListToolbar}
