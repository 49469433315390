import axios, {AxiosResponse} from 'axios'
import {  Response } from '../../../../../_jmh/helpers'
import { ProfileDetails } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const UPDATE_PROFILE_URL = `${API_URL}/user/update-profile`
const PROFILE_PICTURE = `${API_URL}/user/profile-image`
  
export const updateMyProfile = (ProfileDetails: any): Promise<ProfileDetails | undefined> => {    
  sessionStorage.setItem('profileDetails', JSON.stringify(ProfileDetails));
  return axios
    .patch(`${UPDATE_PROFILE_URL}`, ProfileDetails)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

export const updateProfilePicture = (image: any): Promise<any | undefined> => {    
  return axios
    .post(`${PROFILE_PICTURE}`, image)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}