/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {loginContext} from '../../../../../context/context'

type Props = {
  id: ID
}

const InvManagementActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const {loginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'inventory') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH' && item.permission.route === ':id') {
      return item
    }
  })
  return (
    <>
      {edit.length ? (
        <button
          className='btn btn-light btn-active-light-primary btn-sm me-3'
          onClick={openEditModal}
        >
          <span>
            <i className='bi bi-pencil-square fs-2'></i>
          </span>
        </button>
      ) : (
        ''
      )}
    </>
  )
}

export {InvManagementActionsCell}
