import {useContext, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {isNotEmpty, QUERIES} from '../../../../_jmh/helpers'
import {assignPermission, getPermission, getUserById} from '../Roleslist/core/_requests'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {loginContext} from '../../../context/context'
import {PageTitle} from '../../../../_jmh/layout/core'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'

function Rolespermission() {
  const location = useLocation()
  const pathname = location.pathname
  const id: any = pathname.split('/').pop()
  const enabledQuery: boolean = isNotEmpty(id)
  const [checked, setChecked] = useState<any>([])
  const [expanded, setExpanded] = useState([])
  const [getAllPermissions, setGetAllPermissions] = useState([])
  const {loginData, selectedHospitalId} = useContext(loginContext)
  const intl = useIntl()
  const [hasChanges, setHasChanges] = useState(false)

  const {
    isLoading,
    data: role,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${id}`,
    () => {
      return getUserById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {},
    }
  )

  const checkHandler = (value: any, checked: any) => {
    setChecked(value)
    setHasChanges(true)
  }
  const expandHandler = (permission: any) => {
    setExpanded(permission)
  }

  const permissionArray: any[] = []
  useEffect(() => {
    ;(async () => {
      const permission = await getPermission()
      setGetAllPermissions(permission)
      const data = permission.map((e: any) => {
        return e.name
      })
      expandHandler(data)
      setExpanded([])

      if (loginData?.data?.user_type === 'SUPER_ADMIN') {
        permission?.forEach((e: any) => {
          e.permissions?.forEach((ele: any) => {
            role?.permissions?.forEach((item: any) => {
              if (item?.role_id == id && item?.permission_id == ele?.id) {
                permissionArray.push(ele?.name)
              }
            })
          })
        })
      } else {
        loginData &&
          permission &&
          loginData.data?.permissions?.forEach((item: any) => {
            permission?.forEach((e: any) => {
              e.permissions?.map((ele: any) => {
                if (item?.role_id == id && item?.permission_id == ele?.id) {
                  permissionArray.push(item?.permission?.name)
                }
              })
            })
          })
      }

      setChecked(permissionArray)
    })()
  }, [role])

  function mapDataToNodes(data: any) {
    let parent: any = []
    data.map((item: any) => {
      let child: any = []

      item?.permissions?.map((item2: any) => {
        child.push({value: item2?.name, label: item2?.name, permission_id: item2?.id})
      })

      parent.push({value: item?.name, label: item?.name, children: child, module_id: item?.id})
    })

    return parent
  }

  const nodes: any = mapDataToNodes(getAllPermissions)

  let persmission_Array: any = []

  for (const iterator of checked) {
    nodes.map((e: any) => {
      e?.children.map((i: any) => {
        if (iterator === i?.value) {
          const test = {
            module_id: e?.module_id,
            permission_id: i?.permission_id,
          }
          persmission_Array.push(test)
        }
      })
    })
  }

  const handleRemove = (id: any) => {
    if (id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          title: `Are you sure you want to update permissions ?`,
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Update',
          cancelButtonText: 'Close',
          reverseButtons: true,
        })
        .then(async (result: any) => {
          const body = {
            role_id: Number(id),
            hospital_id: Number(selectedHospitalId),
            permissions: persmission_Array,
          }
          if (result.isConfirmed) {
            try {
              await assignPermission(id, body).then((res: any) => {
                setHasChanges(false)
                swalWithBootstrapButtons.fire('Updated!', `Permission has been updated.`, 'success')
              })
            } catch (ex: any) {
              if (ex.response.data.message) {
                toast.error(ex.response.data.message)
              }
            }
          }
        })
    }
  }

  // useEffect(() => {
  //   refetch()
  // }, [refetch, isDelete])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ROLE.PERMISSION'})}</PageTitle>
      {role && (
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>
              {role?.name} {intl.formatMessage({id: 'GENERAL.ROLE'})}{' '}
            </h3>
            <div className='card-toolbar'>
              <Link type='button' className='btn btn-sm btn-primary' to={'/roles-permissions'}>
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </Link>
            </div>
          </div>
          <div className='card-body'>
            <CheckboxTree
              checked={checked}
              expanded={expanded}
              nodes={nodes}
              optimisticToggle={false}
              onCheck={checkHandler}
              onExpand={expandHandler}
            />
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-primary mb-5 float-end mx-5'
              onClick={() => handleRemove(id)}
              disabled={!hasChanges}
            >
              {intl.formatMessage({id: 'GENERAL.SAVE'})}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Rolespermission
