import {useContext} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const AssignInvListToolbar = () => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const {loginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'assign-inventory') {
      return item?.module_id
    }
  })

  const add = data?.filter((item: any) => {
    if (item.permission.method === 'POST') {
      return item
    }
  })
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {add.length ? (
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'ASSIGN_INVENTORY.ADD.ASSIGN.QUANTITY'})}
        </button>
      ) : (
        ''
      )}
    </div>
  )
}

export {AssignInvListToolbar}
