import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {PageTitle} from '../../../../../_jmh/layout/core'
import {Payroll, initialUser} from '../core/_models'
import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {
  createPayroll,
  getPayrollById,
  getSalaryById,
  getStaffData,
  updatePayroll,
} from '../core/_requests'
import {PayrollsListLoading} from '../components/loading/PayrollsListLoading'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import {calculateSalaryPayrollComponents} from './PayrollConstan'
import {loginContext} from '../../../../context/context'
import {toast} from 'react-toastify'

type Props = {
  isUserLoading: boolean
  user: Payroll
}

const PayrollForm: FC<any> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [staffAll, setStaffAll] = useState<any>([])
  const [startDate, setStartDate] = useState<Date | null>()
  const [grossSalary, setGrossSalary] = useState<any>(null)
  const [bonus, setBonus] = useState<number>(0)
  const [incentive, setIncentive] = useState<number>(0)
  const [workingDays, setWorkingDays] = useState<number>(0)
  const [weekOffDays, setweekOffDays] = useState<number>(0)
  const [calenderDays, setCalenderDays] = useState<number>(0)
  const [ews, setEws] = useState<number>(0)
  const [pl, setPl] = useState<number>(0)
  const [upl, setUpl] = useState<number>(0)
  const [professionalTax, setProfessionalTax] = useState<any>(null)
  const [leaveEncashment, setLeaveEncashment] = useState<any>(null)
  const [structureId, setStructureId] = useState<any>(null)
  const [salaryState, setSalaryState] = useState<any>({})
  const [epfDeduction, setEpfDeduction] = useState<any>(0)
  const {selectedHospitalId} = useContext(loginContext)
  const [editData, setEditData] = useState<any>(null)
  const {id} = useParams()
  const [isStaffChanged, setIsStaffChanged] = useState(false)
  const [isFormModify, setIsFormModify] = useState(false)

  let editUserSchema

  if (id) {
    editUserSchema = Yup.object().shape({
      gross_salary: Yup.number()
        .required(intl.formatMessage({id: 'VALIDATION.PAYROLL.GROSS_SALARY'}))
        .min(0, intl.formatMessage({id: 'VALIDATION.PAYROLL.GROSS_SALARY_POSITIVE_ERROR'}))
        .typeError(intl.formatMessage({id: 'VALIDATION.PAYROLL.GROSS_SALARY_NUMBER_ERROR'})),
      working_days: Yup.number()
        .required(intl.formatMessage({id: 'VALIDATION.PAYROLL.WORKING_DAYS'}))
        .min(0, intl.formatMessage({id: 'VALIDATION.PAYROLL.WORKING_DAYS_POSITIVE_ERROR'}))
        .max(
          calenderDays,
          intl.formatMessage({id: 'VALIDATION.PAYROLL.WORKING_DAYS_CALENDER_DAYS_ERROR'}) +
            `${calenderDays}`
        )
        .test(
          'valid-working-days',
          intl.formatMessage({id: 'VALIDATION.PAYROLL.WORKING_DAYS_SUM_ERROR'}),
          function (value) {
            const weekOffsValue = this.parent.week_offs || 0
            const totalDays = value + weekOffsValue
            const isWeekOffsTouched = this.parent.week_offs !== undefined
            const isWorkingDaysTouched = this.parent.working_days !== undefined

            if (isWeekOffsTouched && isWorkingDaysTouched) {
              const isLastBlurredFieldWeekOffs = this.parent.lastBlurredField === 'week_offs'

              if (totalDays !== calenderDays) {
                if (!isLastBlurredFieldWeekOffs) {
                  throw new Yup.ValidationError(
                    this.createError({
                      path: 'working_days',
                      message: intl.formatMessage({
                        id: 'VALIDATION.PAYROLL.WORKING_DAYS_SUM_ERROR',
                      }),
                    })
                  )
                }
              }
            }
            return true
          }
        ),

      week_offs: Yup.number()
        .required(intl.formatMessage({id: 'VALIDATION.PAYROLL.WEEK_OFF'}))
        .min(0, intl.formatMessage({id: 'VALIDATION.PAYROLL.WEEK_OFF_POSITIVE_ERROR'}))
        .max(calenderDays, `Week off days must be less than or equal to ${calenderDays}`)
        .test(
          'valid-week-offs',
          intl.formatMessage({
            id: 'VALIDATION.PAYROLL.WORKING_DAYS_SUM_ERROR',
          }),
          function (value) {
            const workingDaysValue = this.parent.working_days || 0
            const totalDays = value + workingDaysValue
            const isWeekOffsTouched = this.parent.week_offs !== undefined
            const isWorkingDaysTouched = this.parent.working_days !== undefined

            if (isWeekOffsTouched && isWorkingDaysTouched) {
              const isLastBlurredFieldWeekOffs = this.parent.lastBlurredField === 'week_offs'

              if (totalDays !== calenderDays) {
                if (isLastBlurredFieldWeekOffs) {
                  throw new Yup.ValidationError(
                    this.createError({
                      path: 'week_offs',
                      message: intl.formatMessage({
                        id: 'VALIDATION.PAYROLL.WORKING_DAYS_SUM_ERROR',
                      }),
                    })
                  )
                }
              }
            }

            return true
          }
        ),

      employee_working_days: Yup.number()
        .required('Employee Working Days is required')
        .min(0, 'Employee Working Days must be greater than or equal to 0')
        .max(Yup.ref('working_days'), 'Employee Working Days cannot exceed Working Days')
        .test(
          'valid-employee-working-days',
          'Sum of employee working days, paid leaves, and unpaid leaves must be equal to working days',
          function (value) {
            const paidLeaves = this.parent.paid_leaves || 0
            const unpaidLeaves = this.parent.unpaid_leaves || 0
            const totalDays = value + paidLeaves + unpaidLeaves
            return totalDays === this.parent.working_days
          }
        ),

      paid_leaves: Yup.number()
        .required('Paid Leave is required')
        .min(0, 'Paid Leave must be greater than or equal to 0')
        .max(Yup.ref('working_days'), 'Paid Leave cannot exceed Working Days'),

      unpaid_leaves: Yup.number()
        .required('Unpaid Leave is required')
        .min(0, 'Unpaid Leave must be greater than or equal to 0')
        .max(Yup.ref('working_days'), 'Unpaid Leave cannot exceed Working Days'),

      professional_tax: Yup.number()
        .required('Professional Tax is required')
        .min(0, 'Professional Tax must be greater than or equal to 0')
        .typeError('Professional Tax must be a number')
        .test(
          'professional-tax-validation',
          'Professional Tax must be less than the gross salary',
          function (value: any) {
            const grossSalary: any = this.parent.gross_salary
            return value <= grossSalary
          }
        ),

      bonus: Yup.number().min(0, 'Bonus must be greater than or equal to 0'),
      incentive: Yup.number().min(0, 'Incentive must be greater than or equal to 0'),
      leave_encashment: Yup.number().min(0, 'Leave Encashment must be greater than or equal to 0'),
      EPF_deduction: Yup.number()
        .min(0, 'Epf  must be greater than or equal to 0')
        .when('gross_salary', (grossSalary, schema) =>
          grossSalary
            ? schema
                .min(0, 'EPF deduction must be greater than or equal to 0')
                .test(
                  'Epf-validation',
                  'EPF deduction must be less than the gross salary',
                  function (value: any) {
                    return value === null || value === undefined || value < grossSalary
                  }
                )
            : schema
        ),
    })
  } else {
    editUserSchema = Yup.object().shape({
      staff_id: Yup.string().required('Please select staff member'),
      salary_year_and_month: Yup.mixed().test(
        'valid-date',
        'Please select month & year',
        function (value) {
          if (!value) {
            return false // Required field
          }
          const date: any = new Date(value)
          return !isNaN(date) && date instanceof Date
        }
      ),

      working_days: Yup.number()
        .required('Working days is required')
        .min(0, 'Working days must be greater than or equal to 0')
        .max(calenderDays, `Working days must be less than or equal to ${calenderDays}`)
        .test(
          'valid-working-days',
          'Sum of working days and week-off days must be equal to calendar days',
          function (value) {
            const weekOffsValue = this.parent.week_offs || 0
            const totalDays = value + weekOffsValue
            const isWeekOffsTouched = this.parent.week_offs !== undefined
            const isWorkingDaysTouched = this.parent.working_days !== undefined

            if (isWeekOffsTouched && isWorkingDaysTouched) {
              const isLastBlurredFieldWeekOffs = this.parent.lastBlurredField === 'week_offs'

              if (totalDays !== calenderDays) {
                if (!isLastBlurredFieldWeekOffs) {
                  throw new Yup.ValidationError(
                    this.createError({
                      path: 'working_days',
                      message:
                        'Sum of working days and week-off days must be equal to calendar days',
                    })
                  )
                }
              }
            }

            return true
          }
        ),

      week_offs: Yup.number()
        .required('Week off days is required')
        .min(0, 'Week off days must be greater than or equal to 0')
        .max(calenderDays, `Week off days must be less than or equal to ${calenderDays}`)
        .test(
          'valid-week-offs',
          'Sum of working days and week-off days must be equal to calendar days',
          function (value) {
            const workingDaysValue = this.parent.working_days || 0
            const totalDays = value + workingDaysValue
            const isWeekOffsTouched = this.parent.week_offs !== undefined
            const isWorkingDaysTouched = this.parent.working_days !== undefined

            if (isWeekOffsTouched && isWorkingDaysTouched) {
              const isLastBlurredFieldWeekOffs = this.parent.lastBlurredField === 'week_offs'

              if (totalDays !== calenderDays) {
                if (isLastBlurredFieldWeekOffs) {
                  throw new Yup.ValidationError(
                    this.createError({
                      path: 'week_offs',
                      message:
                        'Sum of working days and week-off days must be equal to calendar days',
                    })
                  )
                }
              }
            }

            return true
          }
        ),

      gross_salary: Yup.number()
        .required('Gross Salary is required')
        .min(0, 'Gross Salary must be greater than or equal to 0')
        .typeError('Gross Salary must be a number'),

      employee_working_days: Yup.number()
        .required('Employee Working Days is required')
        .min(0, 'Employee Working Days must be greater than or equal to 0')
        .max(Yup.ref('working_days'), 'Employee Working Days cannot exceed Working Days')
        .test(
          'valid-employee-working-days',
          'Sum of employee working days, paid leaves, and unpaid leaves must be equal to working days',
          function (value) {
            const paidLeaves = this.parent.paid_leaves || 0
            const unpaidLeaves = this.parent.unpaid_leaves || 0
            const totalDays = value + paidLeaves + unpaidLeaves
            return totalDays === this.parent.working_days
          }
        ),

      paid_leaves: Yup.number()
        .required('Paid Leave is required')
        .min(0, 'Paid Leave must be greater than or equal to 0')
        .max(Yup.ref('working_days'), 'Paid Leave cannot exceed Working Days'),

      unpaid_leaves: Yup.number()
        .required('Unpaid Leave is required')
        .min(0, 'Unpaid Leave must be greater than or equal to 0')
        .max(Yup.ref('working_days'), 'Unpaid Leave cannot exceed Working Days'),

      professional_tax: Yup.number()
        .required('Professional Tax is required')
        .min(0, 'Professional Tax must be greater than or equal to 0')
        .typeError('Professional Tax must be a number')
        .test(
          'professional-tax-validation',
          'Professional Tax must be less than the gross salary',
          function (value: any) {
            const grossSalary: any = this.parent.gross_salary

            return value <= grossSalary
          }
        ),
      bonus: Yup.number().min(0, 'Bonus must be greater than or equal to 0'),
      incentive: Yup.number().min(0, 'Incentive must be greater than or equal to 0'),
      leave_encashment: Yup.number().min(0, 'Leave Encashment must be greater than or equal to 0'),
      EPF_deduction: Yup.number()
        .min(0, 'Epf  must be greater than or equal to 0')
        .when('gross_salary', (grossSalary, schema) =>
          grossSalary
            ? schema
                .min(0, 'EPF deduction must be greater than or equal to 0')
                .test(
                  'Epf-validation',
                  'EPF deduction must be less than the gross salary',
                  function (value: any) {
                    return value === null || value === undefined || value < grossSalary
                  }
                )
            : schema
        ),
    })
  }

  const [userForEdit] = useState<Payroll>({
    ...user,
    staff_id: user?.staff_id || initialUser?.staff_id,
    salary_year_and_month: user?.salary_year_and_month || initialUser?.salary_year_and_month,
    gross_salary: user?.gross_salary || initialUser?.gross_salary,
    // employee_working_days: editData?.employee_working_days || '', // Set the default value here
  })

  const cancel = (withRefresh?: boolean) => {
    window.history.back()
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const getStaff = async () => {
    const allData = await getStaffData(selectedHospitalId)
    const activeData: any =
      allData?.data &&
      allData?.data.filter((item: any) => item?.user?.hospital_id == selectedHospitalId)

    setStaffAll(activeData)
  }

  useEffect(() => {
    getStaff()
  }, [selectedHospitalId])

  useEffect(() => {
    const startDate1 = moment(startDate, 'ddd MMM DD YYYY HH:mm:ss Z')
    const daysInMonth = startDate1.daysInMonth()
    setCalenderDays(daysInMonth)
  }, [startDate])

  useEffect(() => {
    ;(async () => {
      if (id) {
        const singleStaffPayrollData: any = await getPayrollById(Number(id))

        setEditData(singleStaffPayrollData)
      }
    })()
  }, [id])

  useEffect(() => {
    ;(async () => {
      if (structureId) {
        const dateStr = startDate
        const date = moment(dateStr)
        const month = date.format('MM')
        const year = date.format('YYYY')
        const singleStaffPayrollData: any = await getSalaryById(structureId, month, year)

        if (singleStaffPayrollData.length === 0) {
          setEditData([])
          setGrossSalary('')
        } else {
          setEditData(singleStaffPayrollData)
          setGrossSalary(singleStaffPayrollData?.gross_salary)
        }
        setIsStaffChanged((val) => !val)
      }
    })()
  }, [structureId, startDate])

  useEffect(() => {
    let dataToUse = editData

    if (Array.isArray(editData) && editData.length > 0) {
      dataToUse = editData[0]
    }

    if (dataToUse) {
      formik.setValues({
        ...formik.values,
        employee_working_days: dataToUse.employee_working_days || 0,
        paid_leaves: dataToUse.paid_leaves || 0,
        unpaid_leaves: dataToUse?.unpaid_leaves || 0,
        gross_salary: dataToUse.gross_salary || 0,
        bonus: dataToUse.bonus || 0,
        incentive: dataToUse.incentive || 0,
        professional_tax: dataToUse.professional_tax || 0,
        leave_encashment: dataToUse.leave_encashment || 0,
        EPF_deduction: dataToUse.EPF_deduction || 0,
        working_days: dataToUse?.working_days || 0,
        week_offs: dataToUse?.week_offs || 0,
        basic_salary: dataToUse?.basic_salary || 0,
      })

      const salaryComponents = calculateSalaryPayrollComponents(
        dataToUse?.gross_salary,
        dataToUse?.professional_tax,
        dataToUse?.bonus,
        dataToUse?.incentive,
        dataToUse?.employee_working_days,
        dataToUse?.paid_leaves,
        dataToUse?.unpaid_leaves,
        dataToUse?.leave_encashment,
        dataToUse?.working_days
      )

      setSalaryState(salaryComponents)
      setGrossSalary(dataToUse?.gross_salary)
      setProfessionalTax(dataToUse?.professional_tax)
      setBonus(dataToUse?.bonus)
      setIncentive(dataToUse?.incentive)
      setLeaveEncashment(dataToUse?.leave_encashment)
      setEws(dataToUse?.employee_working_days)
      setPl(dataToUse?.paid_leaves)
      setUpl(dataToUse?.unpaid_leaves)
      setWorkingDays(dataToUse?.working_days)

      // setEpfDeduction(salaryComponents?.epfDeduction1?.toFixed(2))
    }
  }, [editData, startDate, structureId])

  useEffect(() => {
    const salaryComponents = calculateSalaryPayrollComponents(
      grossSalary > 0 ? grossSalary : 0,
      professionalTax ? professionalTax : 0,
      bonus ? bonus : 0,
      incentive ? incentive : 0,
      ews ? ews : 0,
      pl ? pl : 0,
      upl ? upl : 0,

      leaveEncashment ? leaveEncashment : 0,
      workingDays ? workingDays : 0
    )

    if (salaryComponents) {
      setSalaryState(salaryComponents)
      setEpfDeduction(salaryComponents?.epfDeduction1?.toFixed(2))
    }
  }, [grossSalary, bonus, incentive, ews, leaveEncashment, pl, upl, workingDays])

  useEffect(() => {
    const salaryComponents = calculateSalaryPayrollComponents(
      grossSalary > 0 ? grossSalary : 0,
      professionalTax ? professionalTax : 0,
      bonus ? bonus : 0,
      incentive ? incentive : 0,
      ews ? ews : 0,
      pl ? pl : 0,
      upl ? upl : 0,
      leaveEncashment ? leaveEncashment : 0,
      workingDays ? workingDays : 0,
      epfDeduction > 0 ? epfDeduction : 0
    )

    if (salaryComponents) {
      setSalaryState(salaryComponents)
    }
  }, [professionalTax])

  useEffect(() => {
    const salaryComponents = calculateSalaryPayrollComponents(
      grossSalary > 0 ? grossSalary : 0,
      professionalTax ? professionalTax : 0,
      bonus ? bonus : 0,
      incentive ? incentive : 0,
      ews ? ews : 0,
      pl ? pl : 0,
      upl ? upl : 0,
      leaveEncashment ? leaveEncashment : 0,
      workingDays ? workingDays : 0,
      epfDeduction > 0 ? epfDeduction : null
    )

    if (salaryComponents) {
      setSalaryState(salaryComponents)
    }
  }, [epfDeduction])

  useEffect(() => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()
    const defaultDate = new Date(currentYear, currentMonth, 1)

    formik.setFieldValue('salary_year_and_month', defaultDate)
    setStartDate(defaultDate)
  }, [])

  const HandleStaffchange = (event: any) => {
    formik.handleChange(event)
    if (event.target.value === '') {
      const salaryComponents = calculateSalaryPayrollComponents(0, 0, 0, 0, 0, 0, 0, 0, 0)
      if (salaryComponents) {
        setEditData(salaryComponents)
      }
      setGrossSalary(0)
      formik.setFieldValue('working_days', 0)
      formik.setFieldValue('week_offs', 0)
      formik.setFieldValue('employee_working_days', 0)
      formik.setFieldValue('paid_leaves', 0)
      formik.setFieldValue('unpaid_leaves', 0)

      setProfessionalTax(0)
    } else {
      setStructureId(event.target.value)
      // setIsStaffChanged((val) => !val)
    }
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        if (isNotEmpty(id)) {
          delete values?.staff_id
          delete values?.salary_structure_id
          delete values?.salary_year
          delete values?.salary_month
          delete values?.salary_year_and_month

          values['working_days'] = salaryState?.wd
          values['approval'] = editData[0]?.approval

          values['week_offs'] = weekOffDays ? weekOffDays : editData[0]?.week_offs
          values['basic_salary'] = salaryState?.basicSalary || Number(editData[0]?.basic_salary)
          values['incentive'] = incentive
          values['bonus'] = bonus
          values['house_rent_allowance'] = salaryState?.houseRentAllowance
          values['medical_insurance_allowance'] = salaryState?.medicalInsuranceAllowance
          values['conveyance_transportation_allowance'] =
            salaryState?.conveyanceTransportationAllowance
          // eslint-disable-next-line no-self-assign
          values['leave_encashment'] = values.leave_encashment
          values['meal_allowance'] = salaryState?.mealAllowance
          values['entertainment_allowance'] = salaryState?.entertainmentAllowance
          values['payable_salary'] = salaryState?.payableSalary

          await updatePayroll(values, id).then((res: any) => {
            toast.success(res?.data?.message)
            navigate('/staff/payroll')
          })
        } else {
          const dateStr = values?.salary_year_and_month
          const date = moment(dateStr)
          const month = date.format('MM')
          const year = date.format('YYYY')

          values['salary_year'] = Number(year)
          values['salary_month'] = Number(month)
          values['gross_salary'] = Number(grossSalary) || Number(editData[0]?.gross_salary)
          values['staff_id'] = Number(editData[0]?.staff?.id) || Number(structureId)
          values['salary_structure_id'] = Number(editData[0]?.id)
          // if (selectedOption) {
          //   values['approval'] = selectedOption
          // }
          if (isNaN(bonus)) {
            values['bonus'] = 0
          } else {
            values['bonus'] = bonus
          }
          if (isNaN(incentive)) {
            values['incentive'] = 0
          } else {
            values['incentive'] = incentive
          }
          values['calender_days'] = Number(calenderDays)
          values['basic_salary'] = salaryState?.basicSalary || Number(editData[0]?.basic_salary)
          values['house_rent_allowance'] = salaryState?.houseRentAllowance
          values['medical_insurance_allowance'] = salaryState?.medicalInsuranceAllowance
          values['conveyance_transportation_allowance'] =
            salaryState?.conveyanceTransportationAllowance
          values['meal_allowance'] = salaryState?.mealAllowance
          values['entertainment_allowance'] = salaryState?.entertainmentAllowance
          values['payable_salary'] = salaryState?.payableSalary || editData[0]?.through_pay_slip
          values['hospital_id'] = Number(selectedHospitalId)
          values['EPF_deduction'] = Number(epfDeduction)

          delete values?.salary_year_and_month
          delete values?.approval

          await createPayroll(values).then((res: any) => {
            toast.success(res?.data?.message)
            navigate('/staff/payroll')
          })
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
          setTimeout(() => {
            window.history.back()
          }, 1000)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleSubmitWithValidation = () => {
    formik.validateForm().then(() => {
      if (formik.isValid) {
        formik.handleSubmit()
      }
    })
  }

  const handleBackClick = () => {
    window.history.back()
  }

  return (
    <>
      {id ? (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'EDIT.STAFF.PAYROLL'})}</PageTitle>
      ) : (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ADD.STAFF.PAYROLL'})}</PageTitle>
      )}

      <div className='card card-custom card-flush'>
        <div className='card-header justify-content-end'>
          <div className='card-toolbar'>
            <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
              {intl.formatMessage({id: 'GENERAL.BACK'})}
            </button>
          </div>
        </div>
        <div className='card-body py-5'>
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='d-flex flex-column me-n7 pe-7'>
              {id ? (
                ''
              ) : (
                <>
                  <div className='row'>
                    <div className='col-4'>
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>
                          {intl.formatMessage({id: 'SALARY.STRUCTURE.SELECT.STAFF.MEMBER'})}
                        </label>
                        <select
                          className='form-select'
                          aria-label='Select example'
                          {...formik.getFieldProps('staff_id')}
                          onChange={(e) => HandleStaffchange(e)}
                        >
                          <option value=''>
                            {intl.formatMessage({id: 'SALARY.STRUCTURE.SELECT.STAFF.MEMBER'})}
                          </option>

                          {staffAll &&
                            staffAll?.map((item: any) => {
                              return (
                                <option key={item.id} value={item?.id}>
                                  {item?.full_name}
                                </option>
                              )
                            })}
                        </select>

                        {formik.touched.staff_id && formik.errors.staff_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.staff_id}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>
                          {intl.formatMessage({id: 'SALARY.STRUCTURE.SALARY.MONTH.YEAR'})}
                        </label>
                        <div className='position-relative'>
                          <DatePicker
                            showMonthYearPicker
                            {...formik.getFieldProps('salary_year_and_month')}
                            dropdownMode='select'
                            className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer bg-white border-secondary'
                            dateFormat='MM/yyyy'
                            name='salary_year_and_month'
                            selected={startDate}
                            onChange={(date) => {
                              formik.setFieldValue('salary_year_and_month', date)
                              setStartDate(date)
                            }}
                            autoComplete='off'
                            onKeyDown={(e) => {
                              e.preventDefault()
                            }}
                            disabled={formik.isSubmitting || isUserLoading}
                            placeholderText={intl.formatMessage({
                              id: 'SALARY.STRUCTURE.SALARY.MONTH.YEAR',
                            })}
                          />
                          <span
                            className='DatePicker_icon cursor-pointer'
                            onClick={() =>
                              document.getElementsByName('salary_year_and_month')[0].focus()
                            }
                          >
                            <div className='fa fa-calendar'></div>
                          </span>
                        </div>
                        {formik.touched.salary_year_and_month &&
                          formik.errors.salary_year_and_month && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.salary_year_and_month}</span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className='row'>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({
                        id: 'STAFF.PAYROLL.CALENDER.DAY',
                      })}
                    </label>

                    <input
                      placeholder={intl.formatMessage({
                        id: 'STAFF.PAYROLL.CALENDER.DAY',
                      })}
                      {...formik.getFieldProps('calender_days')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      name='calender_days'
                      autoComplete='off'
                      value={calenderDays}
                      disabled
                    />
                    {formik.touched.calender_days && formik.errors.calender_days && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.calender_days}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'STAFF.PAYROLL.WORKING.DAYS'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'STAFF.PAYROLL.WORKING.DAYS'})}
                      {...formik.getFieldProps('working_days')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      name='working_days'
                      autoComplete='off'
                      min={0}
                      disabled={formik.isSubmitting || isUserLoading}
                      value={formik.values.working_days}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setWorkingDays(parseInt(e.target.value))

                        if (editData?.working_days == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.working_days && formik.errors.working_days && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.working_days}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'STAFF.PAYROLL.WEEKDAYS.OFF'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'STAFF.PAYROLL.WEEKDAYS.OFF'})}
                      {...formik.getFieldProps('week_offs')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      min={0}
                      name='week_offs'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                      value={formik.values.week_offs}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setweekOffDays(parseInt(e.target.value))
                        if (editData?.week_offs == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.week_offs && formik.errors.week_offs && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.week_offs}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'STAFF.PAYROLL.EWD'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'STAFF.PAYROLL.EMPLOYEE.WORKING.DAYS'})}
                      {...formik.getFieldProps('employee_working_days')}
                      type='number'
                      name='employee_working_days'
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      autoComplete='off'
                      min={0}
                      disabled={formik.isSubmitting || isUserLoading}
                      value={formik.values.employee_working_days}
                      onChange={(e) => {
                        formik.handleChange(e)
                        const ews = e.target.value.trim()
                        setEws(parseInt(ews))
                        if (editData?.employee_working_days == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.employee_working_days && formik.errors.employee_working_days && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.employee_working_days}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'STAFF.PAYROLL.PL'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'STAFF.PAYROLL.PAID.LEAVES'})}
                      {...formik.getFieldProps('paid_leaves')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      name='paid_leaves'
                      autoComplete='off'
                      min={0}
                      value={formik.values.paid_leaves}
                      disabled={formik.isSubmitting || isUserLoading}
                      onChange={(e) => {
                        formik.handleChange(e)
                        const pl = e.target.value
                        setPl(parseFloat(pl))
                        if (editData?.paid_leaves == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                    />

                    {formik.touched.paid_leaves && formik.errors.paid_leaves && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.paid_leaves}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'STAFF.PAYROLL.UPL'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'STAFF.PAYROLL.UNPAID.LEAVES'})}
                      {...formik.getFieldProps('unpaid_leaves')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      name='unpaid_leaves'
                      autoComplete='off'
                      min={0}
                      disabled={formik.isSubmitting || isUserLoading}
                      value={formik.values.unpaid_leaves}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setUpl(parseInt(e.target.value))
                        if (editData?.unpaid_leaves == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                    />

                    {formik.touched.unpaid_leaves && formik.errors.unpaid_leaves && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.unpaid_leaves}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({
                        id: 'SALARY.STRUCTURE.ADD.GROSS.SALARY',
                      })}
                    </label>

                    <input
                      placeholder={intl.formatMessage({
                        id: 'SALARY.STRUCTURE.TOTAL.SALARY',
                      })}
                      {...formik.getFieldProps('gross_salary')}
                      type='number'
                      name='gross_salary'
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      autoComplete='off'
                      min={0}
                      disabled={formik.isSubmitting || isUserLoading}
                      value={grossSalary}
                      // value={
                      //   formik.values.gross_salary !== null &&
                      //   formik.values.gross_salary !== undefined
                      //     ? formik.values.gross_salary.toString()
                      //     : editData?.gross_salary !== null && editData?.gross_salary !== undefined
                      //     ? String(editData?.gross_salary)
                      //     : ''
                      // }
                      onChange={(e) => {
                        formik.handleChange(e)
                        setGrossSalary(e.target.value)
                        if (editData?.gross_salary == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault() // Prevent the 'e' character from being entered
                        }
                      }}
                    />
                    {formik.touched.gross_salary && formik.errors.gross_salary && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.gross_salary}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.BASIC.SALARY.50%'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.BASIC.SALARY'})}
                      {...formik.getFieldProps('basic_salary')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      value={salaryState?.basicSalary?.toFixed(2)}
                      type='number'
                      name='basic_salary'
                      autoComplete='off'
                      disabled
                    />
                  </div>
                </div>

                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.HOUSE.RENT.ALLOWANCE.13%'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'SALARY.STRUCTURE.HOUSE.RENT.ALLOWANCE',
                      })}
                      {...formik.getFieldProps('house_rent_allowance')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      value={salaryState?.houseRentAllowance?.toFixed(2)}
                      name='house_rent_allowance'
                      autoComplete='off'
                      disabled
                    />
                  </div>
                </div>

                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.MEDICAL.ALLOWANCE.12%'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({
                        id: 'SALARY.STRUCTURE.MEDICAL.ALLOWANCE',
                      })}
                      {...formik.getFieldProps('medical_insurance_allowance')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      value={salaryState?.medicalInsuranceAllowance?.toFixed(2)}
                      name='medical_insurance_allowance'
                      autoComplete='off'
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({
                        id: 'STAFF.PAYROLL.CTA.11%',
                      })}
                    </label>

                    <input
                      placeholder={intl.formatMessage({
                        id: 'SALARY.STRUCTURE.CONVEYANCE.ALLOWANCE',
                      })}
                      {...formik.getFieldProps('conveyance_transportation_allowance')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      value={salaryState?.conveyanceTransportationAllowance?.toFixed(2)}
                      name='conveyance_transportation_allowance'
                      autoComplete='off'
                      disabled
                    />
                  </div>
                </div>

                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.MEAL.ALLOWANCE.9%'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.MEAL.ALLOWANCE'})}
                      {...formik.getFieldProps('meal_allowance')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      value={salaryState?.mealAllowance?.toFixed(2)}
                      name='meal_allowance'
                      autoComplete='off'
                      disabled
                    />
                  </div>
                </div>

                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.ENTERTAINMENT.ALLOWANCE.5%'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'SALARY.STRUCTURE.ENTERTAINMENT.ALLOWANCE',
                      })}
                      {...formik.getFieldProps('entertainment_allowance')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      value={salaryState?.entertainmentAllowance?.toFixed(2)}
                      name='entertainment_allowance'
                      autoComplete='off'
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.BONUS'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.BONUS'})}
                      {...formik.getFieldProps('bonus')}
                      type='number'
                      name='bonus'
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      min={0}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                      value={
                        formik.values.bonus !== null && formik.values.bonus !== undefined
                          ? formik.values.bonus
                          : editData?.bonus !== null && editData?.bonus !== undefined
                          ? editData?.bonus
                          : ''
                      }
                      onChange={(e) => {
                        formik.handleChange(e)
                        setBonus(parseInt(e.target.value))
                        if (editData?.bonus == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                    />
                    {formik.touched.bonus && formik.errors.bonus && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.bonus}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.INCENTIVE'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.INCENTIVE'})}
                      {...formik.getFieldProps('incentive')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      name='incentive'
                      min={0}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                      value={
                        formik.values.incentive !== null && formik.values.incentive !== undefined
                          ? formik.values.incentive.toString()
                          : editData?.incentive !== null && editData?.incentive !== undefined
                          ? String(editData?.incentive)
                          : ''
                      }
                      onChange={(e) => {
                        formik.handleChange(e)
                        const inputIncentive = e.target.value
                        setIncentive(parseInt(inputIncentive))
                        if (editData?.incentive == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                    />

                    {formik.touched.incentive && formik.errors.incentive && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.incentive}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'STAFF.PAYROLL.LEAVE.ENCASHMENT'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'STAFF.PAYROLL.LEAVE.ENCASHMENT'})}
                      {...formik.getFieldProps('leave_encashment')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      name='leave_encashment'
                      min={0}
                      autoComplete='off'
                      value={
                        formik.values.leave_encashment
                        // formik.values.leave_encashment !== null &&
                        // formik.values.leave_encashment !== undefined
                        //   ? formik.values.leave_encashment
                        //   : editData?.leave_encashment !== null &&
                        //     editData?.leave_encashment !== undefined
                        //   ? editData?.leave_encashment
                        //   : 0
                      }
                      onChange={(e) => {
                        formik.handleChange(e)
                        setLeaveEncashment(parseInt(e.target.value))
                        if (editData?.leave_encashment == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.leave_encashment && formik.errors.leave_encashment && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.leave_encashment}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.PROFESSIONAL.TAX'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.PROFESSIONAL.TAX'})}
                      {...formik.getFieldProps('professional_tax')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      name='professional_tax'
                      autoComplete='off'
                      min={0}
                      value={salaryState?.professionalTax1}
                      // formik.values.professional_tax !== null &&
                      //   formik.values.professional_tax !== undefined
                      //     ? formik.values.professional_tax.toString()
                      //     : editData?.professional_tax !== null &&
                      //       editData?.professional_tax !== undefined
                      //     ? String(editData?.professional_tax)
                      //     : ''
                      onChange={(e) => {
                        formik.handleChange(e)
                        setProfessionalTax(parseInt(e.target.value))
                        if (editData?.professional_tax == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.professional_tax && formik.errors.professional_tax && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.professional_tax}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.EPF.DEDUCTION.12%'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'SALARY.STRUCTURE.EPF.DEDUCTION'})}
                      {...formik.getFieldProps('EPF_deduction')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      min={0}
                      name='EPF_deduction'
                      autoComplete='off'
                      value={formik.values.EPF_deduction || epfDeduction}
                      // value={
                      //   formik.values.EPF_deduction !== null &&
                      //   formik.values.EPF_deduction !== undefined
                      //     ? formik.values.EPF_deduction
                      //     : editData?.EPF_deduction !== null &&
                      //       editData?.EPF_deduction !== undefined
                      //     ? String(editData?.EPF_deduction.toFixed(2))
                      //     : ''
                      // }
                      // onChange={handleEpfDeduction}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setEpfDeduction(e.target.value)
                        if (editData?.EPF_deduction == e.target.value) {
                          setIsFormModify(false)
                        } else {
                          setIsFormModify(true)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault()
                        }
                      }}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.EPF_deduction && formik.errors.EPF_deduction && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.EPF_deduction}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'STAFF.PAYROLL.PAYABLE.SALARY'})}
                    </label>

                    <input
                      placeholder={intl.formatMessage({id: 'STAFF.PAYROLL.PAYABLE.SALARY'})}
                      {...formik.getFieldProps('payable_salary')}
                      className={clsx('form-control form-control mb-3 mb-lg-0')}
                      type='number'
                      name='payable_salary'
                      value={salaryState?.payableSalary?.toFixed(2)}
                      autoComplete='off'
                      // onChange={(e) => {
                      //   formik.handleChange(e)
                      //   setPayableSalary(parseInt(e.target.value))
                      // }}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={formik.isSubmitting || isUserLoading}
              >
                {intl.formatMessage({id: 'GENERAL.CLOSE'})}
              </button>

              {id ? (
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={isUserLoading || formik.isSubmitting || !isFormModify}
                  onClick={handleSubmitWithValidation}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'GENERAL.SAVE'})}
                  </span>
                  {(formik.isSubmitting || isUserLoading) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              ) : (
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={isUserLoading || formik.isSubmitting || !formik.dirty}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'GENERAL.SAVE'})}
                  </span>
                  {(formik.isSubmitting || isUserLoading) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
            </div>
          </form>
          {(formik.isSubmitting || isUserLoading) && <PayrollsListLoading />}
        </div>
      </div>
    </>
  )
}

export default PayrollForm
