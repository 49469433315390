import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {PatientBillingListWrapper} from './PatientBillingList/PatientBillingList'
import {useIntl} from 'react-intl'

const PatientBillingPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PATIENT.BILLING'})}</PageTitle>
      <div className='row g-5 g-xl-8'>
        <PatientBillingListWrapper />
      </div>
    </>
  )
}

export default PatientBillingPageWrapper
