/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC} from 'react'

import {Operationlist} from '../../core/_models'

type Props = {
  user: Operationlist
}

const DoctorName: FC<Props> = ({user}) => {
  const filteredDoctors = user?.allocated_doctor?.filter(
    (item) => item?.doctor?.full_name.length > 1
  )

  const fullNames = filteredDoctors?.map((item) => item?.doctor?.full_name).join(', ')

  return (
    <div className='d-flex align-items-center'>
      <p className='text-gray-800 mb-1'>{fullNames}</p>
    </div>
  )
}

export {DoctorName}
