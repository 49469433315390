/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

type Props = {
  id: ID
}

const MedicineMasterActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {loginData} = useContext(loginContext)
  const {refetch} = useQueryResponse()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-medicine-master') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH' && item.permission.route === ':id') {
      return item
    }
  })

  return (
    <>
      {edit.length ? (
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm px-4'
          onClick={openEditModal}
        >
          <span>
            <i className='bi bi-pencil-square fs-2'></i>
          </span>
        </a>
      ) : (
        ''
      )}
    </>
  )
}

export {MedicineMasterActionsCell}
