import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialUser, AssignInv} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {AssignInvListLoading} from '../components/loading/AssignInvListLoading'
import {createAssignInv, getInventory, getRoomsList, updateAssignInv} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: AssignInv
}

const AssignInvEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [invName, setInvName] = useState([])
  const [roomList, setRoomList] = useState([])
  const {selectedHospitalId} = useContext(loginContext)

  let editUserSchema
  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      // inventory_id: Yup.number().required('Invetory ID is required'),
      assigned_quantity: Yup.number()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.ASSIGNED_QUANTITY'}))
        .min(1, intl.formatMessage({id: 'VALIDATION.MIN.ASSIGNED_QUANTITY'})),
      // room_id: Yup.number().required('Room ID is required'),
    })
  } else {
    editUserSchema = Yup.object().shape({
      inventory_id: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.INVENTORY_NAME'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.REQ.INVENTORY_NAME'})),
      assigned_quantity: Yup.number()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.ASSIGNED_QUANTITY'}))
        .min(1, intl.formatMessage({id: 'VALIDATION.MIN.ASSIGNED_QUANTITY'})),
      room_id: Yup.string()
        .required(intl.formatMessage({id: 'VALIDATION.REQ.HOSPITAL_ROOM_NO'}))
        .notOneOf([''], intl.formatMessage({id: 'VALIDATION.REQ.HOSPITAL_ROOM_NO'})),
    })
  }

  const [userForEdit] = useState<AssignInv>({
    ...user,
    assigned_quantity: user.assigned_quantity || initialUser.assigned_quantity,
    room_id: user.room_id || initialUser.room_id,
    inventory_id: user.inventory_id || initialUser.inventory_id,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  useEffect(() => {
    ;(async () => {
      const invNameData: any = await getInventory()

      const filterInv = invNameData?.data?.filter((item: any) => {
        return item?.hospital_id?.id == selectedHospitalId && item?.status !== 'DELETED'
      })

      setInvName(filterInv)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const roomNumberdata: any = await getRoomsList()

      const filterRoom = roomNumberdata?.data?.filter((item: any) => {
        return (
          item?.availability_status === 'AVAILABLE' && item?.hospital_id?.id == selectedHospitalId
        )
      })

      setRoomList(filterRoom)
    })()
  }, [])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateAssignInv(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          await createAssignInv({
            ...values,
            hospital_id: Number(selectedHospitalId),
            inventory_id: Number(values.inventory_id),
            room_id: Number(values.room_id),
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        toast.error(ex?.response?.data?.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form3' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column scroll-y me-n7 pe-7' id='kt_modal_add_user_scroll'>
          {itemIdForUpdate ? (
            ''
          ) : (
            <>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'PURCHASE_INVENTORY.NAME.INVENTORY'})}
                </label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  // value={formik.values}
                  {...formik.getFieldProps('inventory_id')}
                  name='inventory_id'
                >
                  <option value=''>
                    {intl.formatMessage({id: 'ASSIGN_INVENTORY.SELECT.INVENTORY.NAME'})}
                  </option>
                  {invName &&
                    invName?.map((item: any) => {
                      return (
                        <option key={item.id} value={item?.id}>
                          {item?.name}
                        </option>
                      )
                    })}
                </select>
                {formik.touched.inventory_id && formik.errors.inventory_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.inventory_id}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'GENERAL.ASSIGNED.QUANTITY'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'GENERAL.ASSIGNED.QUANTITY'})}
              {...formik.getFieldProps('assigned_quantity')}
              type='number'
              name='assigned_quantity'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              // disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.assigned_quantity && formik.errors.assigned_quantity && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.assigned_quantity}</span>
                </div>
              </div>
            )}
          </div>

          {itemIdForUpdate ? (
            ''
          ) : (
            <>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'GENERAL.HOSPITAL.ROOM.NUMBER'})}
                </label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  // value={formik.values}
                  {...formik.getFieldProps('room_id')}
                  name='room_id'
                >
                  <option value=''>
                    {intl.formatMessage({id: 'ASSIGN_INVENTORY.SELECT.ROOM.NUMBER'})}
                  </option>
                  {roomList &&
                    roomList?.map((item: any) => {
                      return (
                        <option key={item.id} value={item?.id}>
                          {item?.room_number}
                        </option>
                      )
                    })}
                </select>
                {formik.touched.room_id && formik.errors.room_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.room_id}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'> {intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <AssignInvListLoading />}
    </>
  )
}

export {AssignInvEditModalForm}
