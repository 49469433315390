import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {StaffDashboardCalendar} from './StaffDashboardCalendar'

const StaffDashboardPage = () => (
  <>
    <div>
      <StaffDashboardCalendar />
    </div>
  </>
)

const StaffDashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <StaffDashboardPage />
    </>
  )
}

export {StaffDashboardWrapper}
