/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Payroll} from '../../core/_models'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {ApproveRejectPayroll} from '../../core/_requests'
import {toast} from 'react-toastify'

type Props = {
  user: Payroll
}

const Approval: FC<Props> = ({user}) => {
  const {refetch} = useQueryResponse()
  const [isApprove, setIsApprove] = useState(false)
  const [isReject, setIsReject] = useState(false)

  //Call list Api again on State Changes Approved or Rejected
  useEffect(() => {
    refetch()
  }, [isApprove, isReject])

  const handleApprove = async (id: any, value: any) => {
    try {
      await ApproveRejectPayroll(id, value).then((res: any) => {
        toast.success(res?.data?.message)
        setIsApprove((val) => !val)
      })
    } catch (ex: any) {}
  }

  const handleReject = async (id: any, value: any) => {
    try {
      await ApproveRejectPayroll(id, value).then((res: any) => {
        toast.success(res?.data?.message)
        setIsReject((val) => !val)
      })
    } catch (ex: any) {}
  }
  return (
    <>
      <div className='d-flex align-items-center'>
        {user?.approval === 'PENDING' && (
          <div className='d-flex'>
            <button
              className='btn btn-icon btn-circle btn-color-success btn-light-success lh-0 me-2'
              onClick={() => handleApprove(user.id, true)}
              title='Approve'
            >
              <span className='mx-2'>
                <i className='fa-solid fa-check fs-2'></i>
              </span>
            </button>
            <button
              className='btn btn-icon btn-circle btn-color-danger btn-light-danger lh-0'
              onClick={() => handleReject(user.id, false)}
              title='Reject'
            >
              <span className='mx-2'>
                <i className='fa-solid fa-xmark fs-2'></i>
              </span>
            </button>
          </div>
        )}
      </div>
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-circle symbol-50px overflow-hidden'></div>
        <div className='d-flex flex-column'>
          {/* {user?.approval === 'PENDING' && (
            <span className='badge badge-light-warning'>{user?.approval}</span>
          )} */}
          {user?.approval === 'REJECTED' && (
            <span className='badge badge-light-danger'>{user?.approval}</span>
          )}
          {user?.approval === 'APPROVED' && (
            <span className='badge badge-light-success'>{user?.approval}</span>
          )}
        </div>
      </div>
    </>
  )
}

export {Approval}
