import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {AssetModel, AssetQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ASSET_URL = `${API_URL}/asset`
const GET_ASSET_URL = `${API_URL}/asset`
const ASSETCATEGORYNAME_URL = `${API_URL}/asset-category`

const getAsset = (query: string,id:number): Promise<AssetQueryResponse> => {
  return axios
    .get(`${GET_ASSET_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<AssetQueryResponse>) => d.data)
}

const getAssetById = (id: ID): Promise<AssetModel | undefined> => {
  return axios
    .get(`${ASSET_URL}/${id}`)
    .then((response: AxiosResponse<Response<AssetModel>>) => response.data)
    .then((response: Response<AssetModel>) => response.data)
}

const getAssetReport = (id: ID): Promise<AssetModel | undefined> => {
  return axios
    .get(`${ASSET_URL}/csv?hospital_id=${id}`)
    .then((response: AxiosResponse<Response<AssetModel>>) => response.data)
    .then((response: Response<AssetModel>) => response.data)
}

const createAsset = (asset: AssetModel): Promise<AssetModel | undefined> => {
  return axios
    .post(ASSET_URL, asset)
}

const updateAsset = (assetCRUD: AssetModel ): Promise<AssetModel | undefined> => {
  return axios
    .patch(`${ASSET_URL}/${assetCRUD.id}`, assetCRUD)
}

const deleteAsset = (assetId: ID): Promise<void> => {
  return axios.delete(`${ASSET_URL}`, {data: { ids: [assetId]}}).then(() => {})
}

const deleteSelectedAsset = (assetIds: Array<ID>): Promise<void> => {
  const requests = assetIds.map((id) => axios.delete(`${ASSET_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getAssetName = (): Promise<AssetQueryResponse> => {
  return axios
    .get(`${ASSETCATEGORYNAME_URL}`)
    .then((d: AxiosResponse<AssetQueryResponse>) => d.data)
}

export {getAsset, deleteAsset, deleteSelectedAsset,getAssetReport, getAssetById, createAsset, updateAsset,getAssetName}