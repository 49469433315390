/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {deleteShift} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../../context/context'
import {ShiftModel} from '../../core/_models'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
  user: ShiftModel
}

const ShiftActionsCell: FC<Props> = ({id, user}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const pagination = useQueryResponsePagination()
  const [currentPage, setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const [isDelete, setIsDelete] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const page = pagination?.pagination?.meta?.currentPage || 1

  // const deleteItem = useMutation(() => deleteShift(id), {
  //   onSuccess: async () => {
  //     await queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //     if (page > 1) {
  //       const updatedData = await queryClient.getQueryData<any>([`${QUERIES.USERS_LIST}-${query}`])
  //       if (updatedData?.data?.length > 0) {
  //         setCurrentPage(page)
  //       } else {
  //         setCurrentPage(page - 1)
  //         updateState({
  //           page: page - 1,
  //           items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
  //         })
  //       }
  //     }
  //     toast.error('Shift Deleted')
  //   },
  // })

  const handleRemove = async (id: any) => {
    if (id) {
      try {
        await deleteShift(id).then((res: any) => {
          toast.success(res?.data?.message)
        })

        if (page > 1) {
          const updatedData = await queryClient.getQueryData<any>([
            `${QUERIES.USERS_LIST}-${query}`,
          ])
          if (updatedData?.data?.length > 0) {
            setCurrentPage(page)
          } else {
            setCurrentPage(page - 1)
            updateState({
              page: page - 1,
              items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
            })
          }
        }

        setIsDelete((val) => !val)
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      }
    }
  }

  useEffect(() => {
    refetch()
  }, [refetch, isDelete])

  const {loginData, setLoginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'shift') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE') {
      return item
    }
  })

  return (
    <>
      <div
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </div>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-2'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {edit.length ? (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </a>
          </div>
        ) : (
          ''
        )}

        {/* end::Menu item */}

        {/* begin::Menu item */}
        {deleteData.length ? (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              // onClick={async () => await deleteItem.mutateAsync()}
              onClick={() => handleRemove(user?.id)}
            >
              {intl.formatMessage({id: 'GENERAL.DELETE'})}
            </a>
          </div>
        ) : (
          ''
        )}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ShiftActionsCell}
