import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {HospitalRoomListWrapper} from './HospitalRoomList/HospitalRoomList'
import {useIntl} from 'react-intl'

const HospitalRoomPageWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ROOM_MANAGEMENT'})}</PageTitle>
      <HospitalRoomListWrapper />
    </>
  )
}

export default HospitalRoomPageWrapper
