import moment from 'moment'
import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
// import {UserEditModalForm} from './UserEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getPatientById} from '../core/_requests'
import {PatientEditModalForm} from './PatientEditModalForm'

const PatientEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: patient,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getPatientById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (patient && patient?.date_of_birth) {
    const dob = moment(patient.date_of_birth).format('DD-MM-YYYY')
    patient.date_of_birth = dob
  }

  if (!itemIdForUpdate) {
    return <PatientEditModalForm isUserLoading={isLoading} patient={{id: undefined}} />
  }

  if (!isLoading && !error && patient) {
    return <PatientEditModalForm isUserLoading={isLoading} patient={patient} />
  }

  return null
}

export {PatientEditModalFormWrapper}
