// @ts-nocheck
import {Column} from 'react-table'

import {PatientVitalActionsCell} from './PatientVitalActionsCell'
import {PatientVital} from '../../core/_models'
import {PatientVitalCustomHeader} from './PatientVitalCustomHeader'
import {PatientBloodPressureInfo} from './PatientBloodPressureInfo'
import {PatientTemperatureInfo} from './PatientTemperatureInfo'
import {PatientRespiratoryRateInfo} from './PatientRespiratoryRateInfo'
import {PatientPulseInfo} from './PatientPulseInfo'
import {PatientVitalDateTimeInfo} from './PatientVitalDateTimeInfo'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<PatientVital>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientVitalCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.BLOOD.PRESSURE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'blood_pressure',
    Cell: ({...props}) => <PatientBloodPressureInfo user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientVitalCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.TEMPERATURE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'temprature',
    Cell: ({...props}) => <PatientTemperatureInfo user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientVitalCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.RESPIRATORY.RATE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'Respiratory_rate',
    Cell: ({...props}) => <PatientRespiratoryRateInfo user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientVitalCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.PULSE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'pulse',
    Cell: ({...props}) => <PatientPulseInfo user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientVitalCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DATE.TIME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'date_time',
    Cell: ({...props}) => <PatientVitalDateTimeInfo user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <PatientVitalCustomHeader
  //       tableProps={props}
  //       title='Actions'
  //       className='text-end min-w-100px'
  //     />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => (
  //     <PatientVitalActionsCell
  //       user={props.data[props.row.index]}
  //       id={props.data[props.row.index].id}
  //     />
  //   ),
  // },

  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <PatientVitalCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px pe-5'
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'patient-vitals') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH'
            // item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <PatientVitalCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px'
          />
        ) : (
          ''
        )
      } else {
        return null
      }
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <PatientVitalActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'patient-vitals') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH'
            // item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <PatientVitalActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        ) : null
      } else {
        return null
      }
    },
  },
]

export {usersColumns}
