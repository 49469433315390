import {FC} from 'react'
import {AssetMaintenanceModel} from '../../core/_models'

type Props = {
  asset: AssetMaintenanceModel
}

const AssetMaintenanceInfoCell: FC<Props> = ({asset}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <div className='text-gray-800  mb-1'>
          {typeof asset?.asset_id === 'object' ? asset?.asset_id?.name : ''}
        </div>
      </div>
    </div>
  )
}

export {AssetMaintenanceInfoCell}
