import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {StaffListWrapper} from './StaffList/Stafflist'

const StaffPage = () => (
  <>
    <div className=''>
      <StaffListWrapper />
    </div>
  </>
)

const StaffWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'STAFF.STAFF.MANAGEMENT'})}</PageTitle>
      <StaffPage />
    </>
  )
}

export {StaffWrapper}
