import {KTCard} from '../../../../_jmh/helpers'
import {PatientsListHeader} from './components/header/PatientsListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PatientEditModal} from './patient-edit-modal/PatientEditModal'
import {PatientsTable} from './table/PatientsTable'

const PatientsList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard className='shadow'>
        <PatientsListHeader />
        <PatientsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <PatientEditModal />}
    </>
  )
}

const PatientListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PatientsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PatientListWrapper}
