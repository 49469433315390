// @ts-nocheck
import {Column} from 'react-table'
import {PatientDocInfoCell} from './PatientDocInfoCell'
import {PatientDocActionsCell} from './PatientDocActionsCell'
import {PatientDocCustomHeader} from './PatientDocCustomHeader'
import {PatientDoc} from '../../core/_models'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<PatientDoc>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <PatientDocCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DOCUMENTS'})}
          className='min-w-125px'
        />
      )
    },
    id: 'path',
    Cell: ({...props}) => <PatientDocInfoCell user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <PatientDocCustomHeader
  //       tableProps={props}
  //       title='Actions'
  //       className='text-end min-w-100px pe-5'
  //     />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => (
  //     <PatientDocActionsCell
  //       user={props.data[props.row.index]}
  //       id={props.data[props.row.index].id}
  //     />
  //   ),
  // },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <PatientDocCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px pe-5'
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'patient') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            // item.permission.method === 'PATCH' ||
            item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <PatientDocCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px'
          />
        ) : (
          ''
        )
      } else {
        return null
      }
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <PatientDocActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'patient') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            // item.permission.method === 'PATCH' ||
            item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <PatientDocActionsCell
            id={props.data[props.row.index].id}
            user={props.data[props.row.index]}
          />
        ) : null
      } else {
        return null
      }
    },
  },
]

export {usersColumns}
