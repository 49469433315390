/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ATD} from '../../core/_models'

type Props = {
  user: ATD
}

const DoctorData: FC<Props> = ({user}) => {
  const latestData =
    user &&
    user?.ad_patient_transfer?.sort((a: any, b: any) => {
      const dateA = new Date(a.created_at)
      const dateB = new Date(b.created_at)
      return dateB.getTime() - dateA.getTime()
    })

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>{latestData?.[0]?.doctor?.full_name}</span>
      </div>
    </div>
  )
}

export {DoctorData}
