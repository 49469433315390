import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import { StaffQueryResponse } from '../../../staff-management/StaffList/core/_models'
import {Salary, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
const GET_STAFF_URL = `${API_URL}/staff`
const SALARY_STURUCTURE = `${API_URL}/staff-salary-strcuture`

const getSalarys = (query: string,id:number): Promise<UsersQueryResponse> => {
  return axios
    .get(`${SALARY_STURUCTURE}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getSalaryById = (id: ID): Promise<Salary | undefined> => {
  return axios
    .get(`${SALARY_STURUCTURE}/${id}`)
    .then((response: AxiosResponse<Response<Salary>>) => response.data)
    .then((response: Response<Salary>) => response.data)
}

const createSalary = (user: Salary): Promise<Salary | undefined> => {
  return axios
    .post(SALARY_STURUCTURE, user)
}

const updateSalary = (user: Salary, id:any): Promise<Salary | undefined> => {
  return axios
    .patch(`${SALARY_STURUCTURE}/${id}`, user)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${SALARY_STURUCTURE}`,{data: { ids: [userId]}})
  // return axios.delete(`${SALARY_STURUCTURE}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getStaffData = (query?: string): Promise<StaffQueryResponse> => {
  return axios
    .get(`${GET_STAFF_URL}`)
    .then((d: AxiosResponse<StaffQueryResponse>) => d.data)
}

export {getSalarys, deleteUser, deleteSelectedUsers, getSalaryById, createSalary, updateSalary,getStaffData}
