/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Appointment} from '../../core/_models'

type Props = {
  user: Appointment
}

const AppointmentStatus: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {user.status === 'CANCELLED' && (
          <span className='badge badge-light-danger'>{user?.status}</span>
        )}
        {user.status === 'BOOKED' && (
          <span className='badge badge-light-success'>{user?.status}</span>
        )}
        {user.status === 'RESCHEDULED' && (
          <span className='badge badge-light-warning'>{user?.status}</span>
        )}
        {user.status === 'COMPLETED' && (
          <span className='badge badge-light-primary'>{user?.status}</span>
        )}
        {user.status === 'ACTIVE' && (
          <span className='badge badge-light-success'>{user?.status}</span>
        )}
        {user.status === 'WAITING' && (
          <span className='badge badge-light-warning'>{user?.status}</span>
        )}
      </div>
    </div>
  )
}

export {AppointmentStatus}
