import { ID, Response } from "../../../../../_jmh/helpers"

export type User = {
  id?: ID
  name?: string
  description?: string
  created_at?: string
  updated_at?: string
  hospital_id?: number
  initials?: {
    label: string
    state: string
  }
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
    name: '',
    description: ""
}
