import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import AvalabilityTable from './Table/AvalabilityTable'

const DoctorDataPage = () => (
  <>
    <div className='row g-5 g-xl-8 '>
      <AvalabilityTable />
    </div>
  </>
)

const DoctorAvalabilityWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'DOCTOR.DOCTOR.AVAILABILITY'})}
      </PageTitle>
      <DoctorDataPage />
    </>
  )
}

export {DoctorAvalabilityWrapper}
