import { ID, Response } from "../../../../../_jmh/helpers"

export type HospitalRoom = {
  id?: ID
  name?: string
  description?: string
  room_number : string
  type?: string
  cost?: number
  maximum_capacity?:number
  availability_status?: string
  created_at?: string
  updated_at?: string
  hospital_id?: number
  initials?: {
    label: string
    state: string
  }
}

export type HospitalRoomQueryResponse = Response<Array<HospitalRoom>>

export const initialUser: HospitalRoom = {
    room_number:'',
    cost: 0,
    maximum_capacity: 0,
    availability_status:'',
    description : ''
}
