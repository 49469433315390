/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {PatientNameFilter} from './DoctorDashboardFilter/PatientNameFilter'
import {AppointmentFilter} from './DoctorDashboardFilter/AppointmentFilter'
import {OperationsFilter} from './DoctorDashboardFilter/OperationsFilter'
import {useIntl} from 'react-intl'

interface Props {
  setAppEndDate: any
  setAppStartDate: any
  appstartDate: any
  appendDate: any
  handleApply: any
  opestartDate: any
  opeendDate: any
  setOpeStartDate: any
  setOpeEndDate: any
  patientName: any
  setPatientName: any
}

const DashboardFilterDropdown: FC<Props> = ({
  setAppEndDate,
  setAppStartDate,
  appstartDate,
  appendDate,
  handleApply,
  opestartDate,
  opeendDate,
  setOpeStartDate,
  setOpeEndDate,
  patientName,
  setPatientName,
}) => {
  const intl = useIntl()
  return (
    <>
      <div
        className={`dashboard-dropdown menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary rounded-20 fw-bold w-300px`}
        data-kt-menu='true'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>
            {intl.formatMessage({id: 'FILTER.OPTIONS'})}
          </div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div>
            <PatientNameFilter patientName={patientName} setPatientName={setPatientName} />
          </div>

          <div>
            <AppointmentFilter
              appstartDate={appstartDate}
              appendDate={appendDate}
              setAppStartDate={setAppStartDate}
              setAppEndDate={setAppEndDate}
              handleApply={handleApply}
            />
          </div>

          <div>
            <OperationsFilter
              startDate={opestartDate}
              endDate={opeendDate}
              setStartDate={setOpeStartDate}
              setEndDate={setOpeEndDate}
              handleApply={handleApply}
            />
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='submit'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => handleApply(opestartDate, opeendDate)}
            >
              {intl.formatMessage({id: 'GENERAL.APPLY.BUTTON'})}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {DashboardFilterDropdown}
