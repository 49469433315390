// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {Payroll} from '../../core/_models'
import {UserCustomHeader} from './UserCustomHeader'
import {GrossSalary} from './GrossSalary'
import {MonthYear} from './MonthYear'
import {Approval} from './Approval'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<Payroll>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.STAFF.MEMBER.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'staff',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.GROSS.SALARY'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'gross_salary',
    Cell: ({...props}) => <GrossSalary user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Payable Salary' className='min-w-125px' />
  //   ),
  //   id: 'payable_salary',
  //   Cell: ({...props}) => <Payable user={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.MONTH.YEAR'})}
          className='min-w-125px'
        />
      )
    },
    id: 'two_steps',
    Cell: ({...props}) => <MonthYear user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Approval State' className='min-w-125px' />
  //   ),
  //   accessor: 'approval_state',
  //   Cell: ({...props}) => <ApprovalState user={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.APPROVAL'})}
          className='min-w-125px ps-7'
        />
      )
    },
    accessor: 'approval',
    Cell: ({...props}) => <Approval user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'staff-payroll') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px pe-5'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'staff-payroll') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? (
        <UserActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />
      ) : null
    },
  },
]

export {usersColumns}
