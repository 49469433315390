import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PatientInsuranceListHeader} from './components/header/PatientInsuranceListHeader'
import {PatientInsuranceTable} from './table/PatientInsuranceTable'
import {PatientInsuranceEditModal} from './PatientInsurance-edit-modal/PatientInsuranceEditModal'
import {KTCard} from '../../../../_jmh/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const PatientInsuranceList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <PatientInsuranceListHeader />
        <PatientInsuranceTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <PatientInsuranceEditModal />}
    </>
  )
}

const PatientInsuranceListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PatientInsuranceList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PatientInsuranceListWrapper}
