import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {requestPassword} from '../../core/_requests'

const initialValues = {
  email: '',
}

export function PatientForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const intl = useIntl()

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL.ERROR'}))
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.EMAIL_ERROR'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      setHasErrors(undefined)
      requestPassword(values.email)
        .then(() => {
          setHasErrors(false)
          setLoading(false)
          setSubmitting(false)
          setIsFormDirty(false)
        })
        .catch(() => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='login-form'>
        <div className='row'>
          <div className='col-12 p-0'>
            <form
              className={clsx('form w-100', {
                'form-not-clickable': formik.isSubmitting, // Add a CSS class to disable pointer events
              })}
              noValidate
              id='kt_login_password_reset_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='text-center mb-5 '>
                <h2 className='fw-bold fs-1'>
                  {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})} ?
                </h2>
                <div className='fw-bold fs-4'>
                  {intl.formatMessage({id: 'GENREAL.FORGOT.PAGE'})}
                </div>
              </div>

              {hasErrors === true && (
                <div className='alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    {intl.formatMessage({id: 'VALIDATION.WRONG_EMAIL'})}
                  </div>
                </div>
              )}

              {hasErrors === false && (
                <div className='bg-light-info p-4 mb-5 rounded'>
                  <div className='text-info text-center'>
                    {intl.formatMessage({id: 'VALIDATION.RESET.PASSWORD_LINK'})}
                  </div>
                </div>
              )}

              <div className='input-group mb-5'>
                <label className='form-label fw-bolder fs-6 w-100'>
                  {intl.formatMessage({id: 'GENERAL.EMAIL'})}
                </label>

                <div className='position-relative w-100'>
                  <input
                    type='email'
                    placeholder={intl.formatMessage({id: 'PLACEHOLDER.ENTER.EMAIL_ADDRESS'})}
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    value={emailValue}
                    onChange={(e) => {
                      formik.handleChange(e)
                      setEmailValue(e.target.value)
                      setIsFormDirty(true)
                    }}
                    className={clsx('form-control form-control-lg form-control-solid grey_input')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex justify-content-center align-items-center'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder me-4'
                  disabled={formik.isSubmitting || !formik.isValid || !isFormDirty}
                >
                  {!loading && (
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
