import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {PurchaseInvListWrapper} from './PurchaseInventoryList/PurchaseInvList'
import {useIntl} from 'react-intl'

const PurchaseInvPageWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'INVENTORY.PURCHASE_INVENTORY'})}
      </PageTitle>
      <PurchaseInvListWrapper />
    </>
  )
}

export default PurchaseInvPageWrapper
