import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {PatientVital, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const PATIENT_VITAL = `${API_URL}/patient-vitals`

const getPatientVital = (query: string,id:number,hosId:number,appointId:any): Promise<UsersQueryResponse> => {
  return axios
    // .get(`${PATIENT_VITAL}?patient_id=${id}&${query}`)
    .get(`${PATIENT_VITAL}?${query ? `${query}&limit=10&patient_id=${id}&hospital_id=${hosId}&appointment_id=${appointId}` : `limit=10&hospital_id=${hosId}&patient_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}


const getPatientVitalById = (id: ID): Promise<PatientVital | undefined> => {
  return axios
    .get(`${PATIENT_VITAL}/${id}`)
    .then((response: AxiosResponse<Response<PatientVital>>) => response.data)
    .then((response: Response<PatientVital>) => response.data)
}

const createPatientVital = (user: any): Promise<PatientVital | undefined> => {
  return axios
    .post(PATIENT_VITAL, user)
}

const updatePatientVital = (user: any): Promise<PatientVital | undefined> => {
  return axios
    .patch(`${PATIENT_VITAL}/${user.id}`, user)
}

export {getPatientVital, getPatientVitalById, createPatientVital, updatePatientVital}
