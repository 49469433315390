import {FC, useContext, useEffect, useState} from 'react'
import {Patient} from '../../core/_models'
import {Link} from 'react-router-dom'
import {loginContext} from '../../../../../context/context'

type Props = {
  patient: Patient
}

const PatientInfoCell: FC<Props> = ({patient}) => {
  const {setSelectedPatientId, setSelectedPatient, selectedPatientTab, setSelectedPatientTab} =
    useContext(loginContext)
  const [tab, setTab] = useState(selectedPatientTab)
  const defaultAllowTabs = [
    'Medications',
    'Medical-Notes',
    'Patient-Vital',
    'Medical-Background',
    'Attachments',
  ]
  const [allowedTabs, setAllowedTabs] = useState<string[]>(defaultAllowTabs)
  const [updatedAllowedTabs, setUpdatedAllowedTabs] = useState<any>([])

  const handlePatientIdChange = (id: any) => {
    setSelectedPatientId(id)
    setSelectedPatient({value: id, label: patient?.full_name})
  }

  // custom
  useEffect(() => {
    setSelectedPatientTab(tab)
  }, [tab])

  const {loginData} = useContext(loginContext)

  function checkPermission(
    loginData: any,
    moduleName: string,
    route?: string,
    method?: string
  ): boolean {
    const permissions = loginData?.data?.permissions

    const modulePermissions = permissions?.filter((permission: any) => {
      if (route && method) {
        return (
          permission?.module?.name === moduleName &&
          permission?.permission?.route === route &&
          permission?.permission?.method === method
        )
      }
      return permission?.module?.name === moduleName
    })
    return modulePermissions?.length > 0
  }

  const hasPermission1 = checkPermission(loginData, 'patient-medication')
  const hasPermission2 = checkPermission(loginData, 'patient-medical-notes')
  const hasPermission3 = checkPermission(loginData, 'patient-vitals')
  const hasPermission4 = checkPermission(loginData, 'patient-medical-background')
  const hasPermission5 = checkPermission(loginData, 'patient')

  useEffect(() => {
    if (hasPermission1) {
      updatedAllowedTabs.push('Medications')
    }
    if (hasPermission2) {
      updatedAllowedTabs.push('Medical-Notes')
    }
    if (hasPermission3) {
      updatedAllowedTabs.push('Patient-Vital')
    }
    if (hasPermission4) {
      updatedAllowedTabs.push('Medical-Background')
    }
    if (hasPermission5) {
      updatedAllowedTabs.push('Attachments')
    }

    setAllowedTabs(updatedAllowedTabs)

    if (loginData?.data?.user_type === 'SUPER_ADMIN') {
      setTab(updatedAllowedTabs[0]?.toLocaleLowerCase())
      setSelectedPatientTab(updatedAllowedTabs[0]?.toLocaleLowerCase())
    } else if (selectedPatientTab === null && updatedAllowedTabs.length > 0) {
      setTab(updatedAllowedTabs[0]?.toLocaleLowerCase())
      setSelectedPatientTab(updatedAllowedTabs[0]?.toLocaleLowerCase())
    }

    // setTab(updatedAllowedTabs[0])
    // setPageTitle(updatedAllowedTabs[0])
  }, [
    hasPermission1,
    hasPermission2,
    hasPermission3,
    hasPermission4,
    hasPermission5,
    updatedAllowedTabs,
  ])

  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <Link
            to={`/patient-data/patient-details/${selectedPatientTab}`}
            onClick={() => handlePatientIdChange(patient?.id)}
            className='menu-link px-3 text-gray-800 text-hover-primary mb-1'
          >
            {patient.full_name}
          </Link>
        </div>
      </div>
    </>
  )
}

export {PatientInfoCell}
