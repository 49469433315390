import {useQuery} from 'react-query'
import {AppointmentEditModalForm} from './AppointmentEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {getAppointmentById} from '../core/_requests'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {EditAppoinmentData} from '../EditAppointment/EditAppoinmentData'
import {useState} from 'react'

const AppointmentEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const [showModal, setShowModal] = useState(false)
  const fetchAppointments = () => {}
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getAppointmentById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <AppointmentEditModalForm
        isUserLoading={isLoading}
        user={{id: undefined}}
        showModal={setShowModal}
        fetchAppointments={fetchAppointments}
      />
    )
  }

  if (!isLoading && !error && user) {
    return <EditAppoinmentData isUserLoading={isLoading} user={user} />
  }
  // if (!isLoading && !error && user) {
  //   return <EditLater isUserLoading={isLoading} user={user} />
  // }

  return null
}

export {AppointmentEditModalFormWrapper}
