import React, {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {PatientInsuranceListWrapper} from './PatientInsuranceList/PatientInsuranceList'
import {useIntl} from 'react-intl'

const PatientInsurancePageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PATIENT.INSURNCE'})}</PageTitle>
      <PatientInsuranceListWrapper />
    </>
  )
}

export default PatientInsurancePageWrapper
