import {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {PatientBillingListLoading} from '../loading/PatientBillingListLoading'
import {getPatientBillingReport} from '../../core/_requests'
import {useQueryResponseData} from '../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'

const PatientBillingListToolbar = () => {
  const intl = useIntl()
  const tabledata = useQueryResponseData()
  const {setItemIdForUpdate} = useListView()
  const {loginData, selectedHospitalId} = useContext(loginContext)
  const [isLoading, setIsLoading] = useState(false)

  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const handleReportDownload = async () => {
    try {
      const getReportData: any = await getPatientBillingReport(selectedHospitalId)
      const uint8Array = new Uint8Array(getReportData?.data)

      const blob = new Blob([uint8Array], {type: 'text/csv'})

      // Create a temporary URL for the blob
      const url = URL.createObjectURL(blob)

      // Create a temporary link element
      const link = document.createElement('a')
      link.href = url
      link.download = 'patient_billing_report.csv'

      // Programmatically click the link to trigger the download
      link.click()

      // Clean up the temporary URL and link
      URL.revokeObjectURL(url)
      link.remove()
    } catch (error) {
      console.log('Error occurred while downloading inventory report:', error)
    } finally {
      setIsLoading(false) // Set loading state to false
    }
  }

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'patient-billing') {
      return item?.module_id
    }
  })

  const add = data?.filter((item: any) => {
    if (item.permission.method === 'POST') {
      return item
    }
  })

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <SpecialityListFilter /> */}
      {/* begin::Export */}
      <button
        type='button'
        className='btn btn-primary me-3'
        disabled={tabledata?.length > 0 ? false : true}
        onClick={handleReportDownload}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'GENERAL.EXPORT'})}
      </button>
      {/* end::Export */}
      {/* begin::Add user */}
      {add.length ? (
        <Link to='/patient-data/patient-billing/billingForm' className='btn btn-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'PATIENT_BILLING.ADD.BILL'})}
        </Link>
      ) : (
        ''
      )}
      {/* end::Add user */}
      {isLoading && <PatientBillingListLoading />} {/* Render the loader */}
    </div>
  )
}

export {PatientBillingListToolbar}
