import { FC, useEffect, useState } from 'react';
import { convertCreatedAtToTimeAgo } from '../../../../../../_jmh/Utils/HelperFunction';

type Props = {
  created_at?: string;
};

const UserLastLoginCell: FC<Props> = ({ created_at }) => {
  const [currentTime, setCurrentTime] = useState(Date.now().toString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now().toString());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const timeAgo = convertCreatedAtToTimeAgo(created_at || currentTime);

  return <div className='badge badge-light fw-bolder'>{timeAgo}</div>;
};

export { UserLastLoginCell };
