import {ShiftListToolbar} from './ShiftListToolbar'
import {ShiftListSearchComponent} from './ShiftListSearchComponent'

const ShiftListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ShiftListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <ShiftListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ShiftListHeader}
