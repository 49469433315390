/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Appointment} from '../../core/_models'

type Props = {
  user: Appointment
}

const UserInfoCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column text-gray-800  mb-1'>
        <span>{user?.patient?.full_name}</span>
      </div>
    </div>
  )
}

export {UserInfoCell}
