import axios from 'axios'
import { UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const PATIENT_LOGIN_URL = `${API_URL}/auth/patient-login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const VERIFY_URL = `${API_URL}/auth/verify`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const CHANGE_PASSWORD = `${API_URL}/user/change-password`
export const RESET_PASSWORD = `${API_URL}/auth/reset-password/`
export const SUPPORT_FEEDBACK = `${API_URL}/user/support-feedback`

// Server should return AuthModel
export function login(email: string, password: string,hospital_id? : number) {
  return axios.post<UserModel>(LOGIN_URL, {
    email,
    password,
    hospital_id
  })
}

export function patientLogin(email: string, password: string,hospital_id? : number) {
  return axios.post<UserModel>(PATIENT_LOGIN_URL, {
    email,
    password,
    hospital_id
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  user_type: string,
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    user_type,
  })
}

export function verify_register(
  email: string,
  otp: string,
) {
  return axios.post(VERIFY_URL, {
    email,
    otp
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function getUserByWithoutToken(token: string) {
  return token
}

export function changePassword(
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
  token: string
) {
  return axios.post<{result: boolean}>(CHANGE_PASSWORD, {
    oldPassword,
    newPassword,
    confirmPassword,
  })
}

export function supportFeedback(
  subject: string,
  description: string
){
  return axios.post<{result:boolean}>(SUPPORT_FEEDBACK, {
    subject,
    description,
  })
}

export function resetPassword(
  password: string,
  confirmPassword: string,
  token:string
) {
  return axios.post(RESET_PASSWORD + `${token}`, {
    password,
    confirmPassword,
  })
}
