/* eslint-disable react-hooks/rules-of-hooks */
import {useContext} from 'react'
import {loginContext} from '../../context/context'
import {DoctorDashboardWrapper} from '../doctor-dashboard/DoctorDashboard'
import {DashboardWrapper} from '../staff-dashboard/DashboardWrapper'
import LandingPage from '../landing-page'

const Dashboard = () => {
  const {loginData} = useContext(loginContext)
  return (
    <div>
      {loginData?.data?.user_type === 'DOCTOR' ? (
        <DoctorDashboardWrapper />
      ) : loginData?.data?.user_type === 'STAFF' ? (
        <DashboardWrapper />
      ) : loginData?.data?.user_type === 'PATIENT' ? (
        <LandingPage />
      ) : (
        <DashboardWrapper />
      )}
    </div>
  )
}

export {Dashboard}
