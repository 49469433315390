import moment from 'moment'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {isNotEmpty, QUERIES, toAbsoluteUrl} from '../../../../../_jmh/helpers'
import {getAtdPatienById} from '../core/_requests'
import {useIntl} from 'react-intl'

function Adtview() {
  const intl = useIntl()
  const location = useLocation()
  const pathname = location.pathname
  const id: any = pathname.split('/').pop()
  const enabledQuery: boolean = isNotEmpty(id)

  const {data: PatientData} = useQuery(
    `${QUERIES.USERS_LIST}-user-${id}`,
    () => {
      return getAtdPatienById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {},
    }
  )

  const AdmissionDate = moment.utc(PatientData?.admission_date).format('DD-MM-YYYY H:mm')
  let DischargeDate
  if (PatientData?.discharge_date !== null) {
    DischargeDate = moment.utc(PatientData?.discharge_date).format('DD-MM-YYYY H:mm')
  }

  const handleBackClick = () => {
    window.history.back()
  }

  return (
    <>
      <div className='card'>
        <div className='card-header card-header-stretch'>
          <div className='card-title d-flex align-items-center'>
            <i className='ki-duotone ki-calendar-8 fs-1 text-primary me-3 lh-0'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
              <span className='path4'></span>
              <span className='path5'></span>
              <span className='path6'></span>
            </i>

            <h3 className='fw-bold m-0 text-gray-800'>
              {intl.formatMessage({id: 'PATIENT.VIEW.PATIENT.DETAILS'})}
            </h3>
          </div>
          <div className='card-toolbar d-flex align-items-center'>
            <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
              {intl.formatMessage({id: 'GENERAL.BACK'})}
            </button>
          </div>
        </div>
        <div className='card-body pt-0'>
          <div className='tab-content'>
            <div
              id='kt_activity_today'
              className='card-body p-0 tab-pane fade active show'
              role='tabpanel'
              aria-labelledby='kt_activity_today_tab'
            >
              <h3 className='fw-bold fs-6 mt-10'>
                {intl.formatMessage({id: 'PATIENT_ATD.PATIENT.ADMISSION.DETAILS'})}
              </h3>
              <div className='row border border-dashed border-gray-300'>
                <div className='col-md-4 col-sm-12 mb-5'>
                  <div className='fw-bold fs-4 mt-5'>
                    {intl.formatMessage({id: 'GENERAL.PATIENT.NAME'})}
                  </div>
                  <div className='text-gray-600 fs-5'>{PatientData?.patient?.full_name}</div>

                  <div className='fw-bold fs-4 mt-5'>
                    {intl.formatMessage({id: 'GENERAL.ADMISSION.DATE.TIME'})}
                  </div>
                  <div className='text-gray-600 fs-5'>{AdmissionDate}</div>
                </div>
                <div className='col-md-4 col-sm-12'>
                  <div className='fw-bold fs-4 mt-5'>
                    {intl.formatMessage({id: 'GENERAL.DOCTOR.NAME'})}
                  </div>
                  <div className='text-gray-600 fs-5'>
                    {PatientData?.ad_patient_transfer?.[0]?.doctor?.full_name}
                  </div>

                  <div className='fw-bold fs-4 mt-5'>
                    {intl.formatMessage({id: 'PATIENT_ATD.REASON.FOR.ADMISSION'})}
                  </div>
                  <div className='text-gray-600 fs-5'>{PatientData?.reason}</div>
                </div>
                <div className='col-md-4 col-sm-12'>
                  <div className='fw-bold fs-4 mt-5'>
                    {intl.formatMessage({id: 'GENERAL.HOSPITAL.ROOM.NUMBER'})}
                  </div>
                  <div className='text-gray-600 fs-5'>
                    {PatientData?.ad_patient_transfer?.[0]?.hospital_room_id}
                  </div>
                  <div className='fw-bold fs-4 mt-5'>
                    {intl.formatMessage({id: 'PATIENT_ATD.DISCHARGE.DATE'})}
                  </div>
                  <div className='text-gray-600 fs-5'>{DischargeDate}</div>
                </div>
              </div>

              <h3 className='fw-bold fs-6 mt-10'>
                {intl.formatMessage({id: 'PATIENT_ATD.PATIENT.TRANSFER.DETAILS'})}
              </h3>
              <div className='border border-dashed border-gray-300 p-10'>
                {PatientData?.ad_patient_transfer?.map((item: any) => {
                  let Transfer
                  if (item?.transfer_date !== null) {
                    Transfer = moment.utc(item?.transfer_date).format('DD-MM-YYYY H:mm')
                  }

                  const matchData =
                    moment.utc(item?.admission_date).format('DD-MM-YYYY H:mm') === AdmissionDate
                  const status = matchData ? 'Admited' : 'Transferred'
                  return (
                    <>
                      <div className='timeline'>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>

                          <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                            <div className='symbol-label bg-secondary'>
                              <img
                                src={toAbsoluteUrl('/media/icons/duotune/abstract/abs027.svg')}
                                style={{filter: 'invert(1) brightness(0.5)'}}
                                className='round-5'
                              />
                            </div>
                          </div>

                          <div className='timeline-content mb-10 mt-n1'>
                            <div className='pe-3 mb-5'>
                              <div className='fs-5 fw-semibold mb-2'>
                                {PatientData?.patient?.full_name} {status}{' '}
                                {intl.formatMessage({id: 'PATIENT_ATD.TO.DOCTOR'})}{' '}
                                <strong>{item?.doctor?.full_name}</strong>{' '}
                                {intl.formatMessage({id: 'PATIENT_ATD.IN.ROOM.NO'})}{' '}
                                <strong>{item?.hospital_room_id}</strong>
                              </div>

                              {Transfer && (
                                <div className='d-flex align-items-center mt-1 fs-6'>
                                  <div className='text-muted me-2 fs-7'>
                                    {intl.formatMessage({id: 'GENERAL.TRANSFER.DATE.TIME'})}{' '}
                                    {Transfer}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className='overflow-auto'>
                              {item?.transfer_reason && (
                                <>
                                  <div className='d-flex align-items-center border border-dashed border-gray-300 rounded px-7  py-3 mb-5'>
                                    <span className='fs-5 text-dark fw-semibold w-375px'>
                                      {item?.transfer_reason}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Adtview
