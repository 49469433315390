import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AtdsListHeader} from './components/header/AtdsListHeader'
import {AtdsTable} from './table/AtdsTable'
import {AtdEditModal} from './ATD-edit-modal/AtdEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const AtdsList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard className='shadow'>
        <AtdsListHeader />
        <AtdsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AtdEditModal />}
    </>
  )
}

const AtdsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AtdsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AtdsListWrapper}
