import {ID, Response} from '../../../../../_jmh/helpers'
export type AssetMaintenanceModel = {
  
  id?: ID;
  type?: string;
  cost?: number;
  description?: string;
  date?: string;
  maintenance_status?: string;
  status?: string;
  created_at?: string;
  updated_at?: string;
  asset_id?: {
      id?: ID;
      name?: string;
      category_id?: {
            id?: ID;
            name?: string;
      } | number;
      hospital_id?: {
        id?: ID;
        name?: string;
      } | number;
  } 
}
export type AssetQueryResponse = Response<Array<AssetMaintenanceModel>>
export const initialAssetMaintenance: AssetMaintenanceModel = {
  type: '',
  status: '',
  date: '',
  description:'',
  // cost : 0,
  maintenance_status:'',
}