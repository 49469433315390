import { ID, Response } from "../../../../../_jmh/helpers"

export type MedicalBackground = {
  id?: ID
  medical_history?: string
  family_history?: string
  ongoing_medication?: string
  allergies?:string
  habit_history?:string
  surgeries?:string
  immunization?: string
  created_at?: string
  updated_at?: string
  hospital_id?: number
  initials?: {
    label: string
    state: string
  }
  patient_id?: {
    id?: string,
    hospital_id?:string
    full_name?: string,
    gender?: string,
    contact_number?:number
    date_of_birth?:string
    permanent_address?: string,
    email?:string,
    alternate_contact_number?:string,
    blood_group?:string
    status?:string
  },
}

export type UsersQueryResponse = Response<Array<MedicalBackground>>

export const initialUser: MedicalBackground = {
  medical_history: '',
  family_history:'',
  ongoing_medication:'',
  allergies:'',
  habit_history:'',
  surgeries:'',
  immunization:''
}
