import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {supportFeedback} from '../../modules/auth/core/_requests'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'

interface supportFeedbackFormValues {
  subject: string
  description: string
}

const initialValues: supportFeedbackFormValues = {
  subject: '',
  description: '',
}

const SupportFeedbackEditModalForm = () => {
  const intl = useIntl()
  let navigate = useNavigate()
  const SupportFeedbackSchema = Yup.object().shape({
    subject: Yup.string()
      .required(intl.formatMessage({id: 'VALIDATION.SUPPORT_SUBJECT'}))
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      }),
    description: Yup.string()
      .required(intl.formatMessage({id: 'VALIDATION.SUPPORT_DESCRIPTION'}))
      .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
        return !value || value.trimStart() === value
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: SupportFeedbackSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      try {
        await supportFeedback(values.subject, values.description).then((res: any) => {
          toast.success(res?.data?.message)
          setSubmitting(false)
          resetForm()
          setTimeout(() => {
            navigate('/dashboard')
          }, 1500)
        })
      } catch (error: any) {
        setStatus(error?.response?.data?.message)
        setSubmitting(false)
      } finally {
        formik.resetForm()
      }
    },
  })

  return (
    <div>
      <form
        className='form w-50 justify-content-center'
        style={{margin: '0 auto'}}
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='card card-custom'>
          <div className='text-center mb-10 mt-5'>
            <h1 className='text-dark mb-3'>
              {intl.formatMessage({id: 'SUPPORT.FEEDBACK.SEND.EMAIL'})}
            </h1>
          </div>

          {formik.status ? (
            <div className='mb-10 alert alert-danger mx-5'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}
          <div className='card-toolbar p-5'>
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  <label className='required form-label fw-bolder text-dark fs-6 mb-0'>
                    {intl.formatMessage({id: 'SUPPORT.FEEDBACK.SUBJECT'})}
                  </label>
                </div>
              </div>
              <div className='position-relative'>
                <input
                  type='text'
                  placeholder={intl.formatMessage({id: 'SUPPORT.FEEDBACK.ENTER.SUBJECT'})}
                  autoComplete='off'
                  {...formik.getFieldProps('subject')}
                  className={clsx('form-control form-control-lg form-control-solid')}
                />
              </div>

              {formik.touched.subject && formik.errors.subject && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.subject}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  <label className='required form-label fw-bolder text-dark fs-6 mb-0'>
                    {intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
                  </label>
                </div>
              </div>
              <div className='position-relative'>
                <textarea
                  className={clsx(
                    'form-control form-control-lg form-control-solid mb-3 mb-lg-0 no-resize'
                  )}
                  {...formik.getFieldProps('description')}
                  name='description'
                  autoComplete='off'
                  placeholder={intl.formatMessage({id: 'SUPPORT.FEEDBACK.ENTER.DESCRIPTION'})}
                />
              </div>

              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.description}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-50 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'SUPPORT.FEEDBACK.SEND.FEEDBACK'})}
              </span>
              {(formik.isSubmitting || !formik.isValid) && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
export {SupportFeedbackEditModalForm}
