import {useContext} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useIntl} from 'react-intl'
import {loginContext} from '../../../../../context/context'

const PatientVitalListToolbar = () => {
  const intl = useIntl()
  const {selectedAppointment} = useContext(loginContext)
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  // const {loginData, setLoginData} = useContext(loginContext)

  // const data = loginData?.data?.permissions?.filter((item: any) => {
  //   if (item.module.name === 'patient-vitals') {
  //     return item?.module_id
  //   }
  // })

  // const add = data?.filter((item: any) => {
  //   if (item.permission.method === 'POST') {
  //     return item
  //   }
  // })

  return (
    <>
      {/* {add.length ? ( */}
      <button
        type='button'
        className='btn btn-primary'
        onClick={openAddUserModal}
        disabled={selectedAppointment?.value == 'all'}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'PATIENT_VITAL.ADD.PATIENT.VITAL'})}
      </button>
      {/* ) : (
        ''
      )} */}
    </>
  )
}

export {PatientVitalListToolbar}
