/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {HospitalRoom} from '../../core/_models'
import {formatCost} from '../../../../../../_jmh/Utils/HelperFunction'

type Props = {
  user: HospitalRoom
}

const HospitalRoomCostCell: FC<Props> = ({user}) => {
  const currency: any = user.cost
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          <p className='text-gray-800 mb-1'> {formatCost(currency)}</p>
        </div>
      </div>
    </>
  )
}

export {HospitalRoomCostCell}
