/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_jmh/helpers'
import {loginContext} from '../../../../../context/context'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {deleteDoctor} from '../../core/_requests'
import {toast} from 'react-toastify'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import Swal from 'sweetalert2'
import {Doctor} from '../../core/_models'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
  user: Doctor
}

const DoctorActionsCell: FC<Props> = ({id, user}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {loginData, setLoginData} = useContext(loginContext)
  const pagination = useQueryResponsePagination()
  const [currentPage, setCurrentPage] = useState(pagination?.pagination?.meta?.currentPage || 1)
  const {updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const [isDelete, setIsDelete] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const page = pagination?.pagination?.meta?.currentPage || 1

  const handleRemove = (id: any) => {
    if (id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          title: intl.formatMessage({id: 'VALIDATION.DELETE_ACTION'}),
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: intl.formatMessage({id: 'GENERAL.DELETE'}),
          cancelButtonText: intl.formatMessage({id: 'GENERAL.CLOSE'}),
          reverseButtons: true,
        })
        .then(async (result: any) => {
          if (result.isConfirmed) {
            try {
              await deleteDoctor(id)

              swalWithBootstrapButtons.fire(
                intl.formatMessage({id: 'GENERAL.DELETED'}),
                intl.formatMessage({id: 'GENERAL.DOCTOR.DELETED'}),
                'success'
              )
              setIsDelete((val) => !val)
              if (page > 1) {
                const updatedData = await queryClient.getQueryData<any>([
                  `${QUERIES.USERS_LIST}-${query}`,
                ])
                if (updatedData?.data?.length > 0) {
                  setCurrentPage(page)
                } else {
                  setCurrentPage(page - 1)
                  updateState({
                    page: page - 1,
                    items_per_page: pagination?.pagination?.meta?.items_per_page || 10,
                  })
                }
              }
            } catch (ex: any) {
              if (ex.response.data.message) {
                toast.error(ex.response.data.message)
              }
            }
          }
        })
    }
  }

  useEffect(() => {
    refetch()
  }, [refetch, isDelete])

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'doctor') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  const view = data?.filter((item: any) => {
    if (item.permission.route === ':id' && item.permission.method === 'GET') {
      return item
    }
  })

  const deleteData = data?.filter((item: any) => {
    if (item.permission.method === 'DELETE') {
      return item
    }
  })

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* {edit.length ? (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </a>
          </div>
        ) : (
          ''
        )} */}
        {edit.length ? (
          <div className='menu-item px-3'>
            <a
              className={`menu-link px-3 ${
                loginData?.data?.personal_details?.id !== user.id &&
                loginData?.data?.user_type !== 'SUPER_ADMIN'
                  ? 'disabled-link'
                  : ''
              }`}
              onClick={
                loginData?.data?.personal_details?.id === user.id ||
                loginData?.data?.user_type === 'SUPER_ADMIN'
                  ? openEditModal
                  : undefined
              }
              style={{
                pointerEvents:
                  loginData?.data?.personal_details?.id !== user.id &&
                  loginData?.data?.user_type !== 'SUPER_ADMIN'
                    ? 'none'
                    : 'auto',
              }}
            >
              {intl.formatMessage({id: 'GENERAL.EDIT'})}
            </a>
          </div>
        ) : (
          ''
        )}

        {view.length ? (
          <div className='menu-item px-3'>
            <Link to={`/doctor-management/view/${id}`} className='menu-link px-3'>
              {intl.formatMessage({id: 'GENERAL.VIEW'})}
            </Link>
          </div>
        ) : (
          ''
        )}
        {deleteData.length ? (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={() => handleRemove(user?.id)}
            >
              {intl.formatMessage({id: 'GENERAL.DELETE'})}
            </a>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export {DoctorActionsCell}
