import {useQuery} from 'react-query'
import {Link, useLocation} from 'react-router-dom'
import {isNotEmpty, QUERIES} from '../../../../_jmh/helpers'
import {getPatientById} from '../PatientList/core/_requests'
import {useIntl} from 'react-intl'
import moment from 'moment'

function PatientView() {
  const location = useLocation()
  const pathname = location.pathname
  const id: any = pathname.split('/').pop()
  const enabledQuery: boolean = isNotEmpty(id)
  const intl = useIntl()

  const {
    isLoading,
    data: patientData,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${id}`,
    () => {
      return getPatientById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {},
    }
  )

  const dob = moment(patientData?.date_of_birth).format('DD-MM-YYYY')

  let bloodGroupText = ''
  if (patientData?.blood_group === 'A_POSITIVE') {
    bloodGroupText = 'A+'
  } else if (patientData?.blood_group === 'B_POSITIVE') {
    bloodGroupText = 'B+'
  } else if (patientData?.blood_group === 'O_POSITIVE') {
    bloodGroupText = 'O+'
  } else if (patientData?.blood_group === 'AB_POSITIVE') {
    bloodGroupText = 'AB+'
  } else if (patientData?.blood_group === 'A_NEGATIVE') {
    bloodGroupText = 'A-'
  } else if (patientData?.blood_group === 'B_NEGATIVE') {
    bloodGroupText = 'B-'
  } else if (patientData?.blood_group === 'O_NEGATIVE') {
    bloodGroupText = 'O-'
  } else if (patientData?.blood_group === 'AB_NEGATIVE') {
    bloodGroupText = 'AB-'
  }

  return (
    <>
      {patientData && (
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title fw-bold fs-2'>
              {intl.formatMessage({id: 'PATIENT.VIEW.PATIENT.DETAILS'})}
            </h3>
            <div className='card-toolbar'>
              <Link
                type='button'
                className='btn btn-sm btn-primary'
                to={'/patient-data/patient-management'}
              >
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </Link>
            </div>
          </div>
          <div className='card-body card-scroll'>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.PATIENT.FULL.NAME'})}:
                </div>
                <div className='text-gray-600 fs-5'>{patientData?.full_name}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.EMAIL'})}:
                </div>
                <div className='text-gray-600 fs-5'>{patientData?.email}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.GENDER'})}:
                </div>
                <div className='text-gray-600 fs-5'>{patientData?.gender}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.BLOOD.GROUP'})}:
                </div>
                <div className='text-gray-600 fs-5'>{bloodGroupText}</div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='fw-bold fs-4 mt-5'>{intl.formatMessage({id: 'GENERAL.DOB'})}:</div>
                <div className='text-gray-600 fs-5'>{dob}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}:
                </div>
                <div className='text-gray-600 fs-5'>{patientData?.permanent_address}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}:
                </div>
                <div className='text-gray-600 fs-5'>{patientData?.contact_number}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.ALTERNATE.CONTACT.NUMBER'})}:
                </div>
                <div className='text-gray-600 fs-5'>{patientData?.alternate_contact_number}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PatientView
