import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../../_jmh/layout/core'
import {Salary} from '../core/_models'
import {getSalaryById} from '../core/_requests'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: Salary
}

const ViewSalary: FC<any> = ({user}) => {
  const {id} = useParams()
  const [allData, SetAllData] = useState(user)
  const intl = useIntl()

  useEffect(() => {
    ;(async () => {
      const allsalaryData: any = await getSalaryById(Number(id))
      SetAllData(allsalaryData)
    })()
  }, [])

  const handleBackClick = () => {
    window.history.back()
  }

  return (
    <div className='salary_view_data'>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'VIEW.SALARY.DATA'})}</PageTitle>
      {allData && (
        <>
          <div className='salary_structure_inner '>
            <div className='card card-flush card-custom py-4 shadow'>
              <div className='card-header'>
                <h2 className='card-title fw-bold fs-2'>
                  {allData?.staff?.full_name} ( {allData?.salary_month}/{allData?.salary_year})
                </h2>
                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    onClick={handleBackClick}
                  >
                    {intl.formatMessage({id: 'GENERAL.BACK'})}
                  </button>
                </div>
              </div>
              <div className='card-body card-scroll pt-5'>
                <div className='row'>
                  <div className='col-4 ps-0'>
                    <h4 className='mt-5'>{intl.formatMessage({id: 'GENERAL.GROSS.SALARY'})}:</h4>
                    <p className=''>{allData?.gross_salary}</p>
                    <h4 className=' mt-5'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.BASIC.SALARY'})}:
                    </h4>
                    <p className=''>{allData?.basic_salary}</p>
                    <h4 className=' mt-5'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.VARIABLE.PAY'})}:
                    </h4>
                    <p className=''>{allData?.variable_pay}</p>
                  </div>
                  <div className='col-4'>
                    <h4 className=' mt-5'>
                      {intl.formatMessage({
                        id: 'SALARY.STRUCTURE.HOUSE.RENT.ALLOWANCE',
                      })}
                      :
                    </h4>
                    <p className=''>{allData?.house_rent_allowance}</p>
                    <h4 className=' mt-5'>
                      {intl.formatMessage({
                        id: 'SALARY.STRUCTURE.MEDICAL.ALLOWANCE',
                      })}
                      :
                    </h4>
                    <p className=''>{allData?.medical_insurance_allowance}</p>
                    <h4 className=' mt-5'>
                      {intl.formatMessage({
                        id: 'SALARY.STRUCTURE.CONVEYANCE.ALLOWANCE',
                      })}
                      :
                    </h4>
                    <p className=''>{allData?.conveyance_transportation_allowance}</p>
                  </div>
                  <div className='col-4'>
                    <h4 className=' mt-5'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.MEAL.ALLOWANCE'})}:
                    </h4>
                    <p className=''>{allData?.meal_allowance}</p>
                    <h4 className=' mt-5'>
                      {intl.formatMessage({
                        id: 'SALARY.STRUCTURE.ENTERTAINMENT.ALLOWANCE',
                      })}
                      :
                    </h4>
                    <p className=''>{allData?.entertainment_allowance}</p>
                    <h4 className=' mt-5'>
                      {intl.formatMessage({id: 'SALARY.STRUCTURE.PAY.THROUGH.SLIP'})}:
                    </h4>
                    <p className=''>{allData?.through_pay_slip}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row gap-10 mt-10'>
              <div className='card card-flush card-custom flex-row-fluid col-5 py-4 ps-0 shadow'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>{intl.formatMessage({id: 'SALARY.STRUCTURE.BENEFITS'})}</h2>
                  </div>
                </div>
                <div className='card-body py-0 '>
                  <div className='row'>
                    <div className='col-6 ps-0'>
                      <h4 className='mt-5'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.BONUS'})}:
                      </h4>
                      <p className=''>{allData?.bonus ? allData?.bonus : 0}</p>
                    </div>
                    <div className='col-6'>
                      <h4 className='mt-5'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.INCENTIVE'})}:
                      </h4>
                      <p className=''>{allData?.incentive ? allData?.incentive : 0}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 ps-0'>
                      <h4 className='mt-5'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.OTHER.ALLOWANCE'})}:
                      </h4>
                      <p className=''>{allData?.other_allowance}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card card-flush card-custom flex-row-fluid  col-5 py-4 shadow'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>{intl.formatMessage({id: 'SALARY.STRUCTURE.DEDUCTIONS'})}</h2>
                  </div>
                </div>
                <div className='card-body py-0 '>
                  <div className='row'>
                    <div className='col-6 ps-0'>
                      <h4 className='mt-5'>{intl.formatMessage({id: 'SALARY.STRUCTURE.EPF'})}</h4>
                      <p className=''>{allData?.EPF_deduction ? allData?.EPF_deduction : 0}</p>
                    </div>
                    <div className='col-6'>
                      <h4 className='mt-5'>
                        {intl.formatMessage({id: 'SALARY.STRUCTURE.PROFESSIONAL.TAX'})}:
                      </h4>
                      <p className=''>
                        {allData?.professional_tax ? allData?.professional_tax : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ViewSalary
