/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AssetModel} from '../../core/_models'

type Props = {
  user: AssetModel
}

const AssetCategoryCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <div className='text-gray-800  mb-1'>
          {typeof user?.category_id === 'object' ? user?.category_id?.name : ''}
        </div>
      </div>
    </div>
  )
}

export {AssetCategoryCell}
