/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {loginContext} from '../../../../app/context/context'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {getTotalData} from '../core/request'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  onClick?: any
  src?: any
}
type GetData = {
  total_number_of_doctors?: number
  total_number_of_staff?: number
  total_number_of_patients?: number
}

const DashboardCard: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  onClick,
  src,
}) => {
  const intl = useIntl()
  const [getdata, setGetData] = useState<GetData>({})

  const {selectedHospitalId} = useContext(loginContext)

  useEffect(() => {
    ;(async () => {
      const totalData: any = await getTotalData(selectedHospitalId)
      setGetData(totalData)
    })()
  }, [selectedHospitalId])

  return (
    <>
      <a href='' className={`card  hoverable ${className} `} onClick={onClick}>
        <div className='card-body'>
          <img
            src={toAbsoluteUrl('/media/landing_asset/img-status-4.svg')}
            alt='img'
            className='img-fluid img-bg'
          />
          <div className={`text-${titleColor} fw-bold fs-2 mb-5 `}>{title}</div>
          <div className='d-flex gap-6 dahbordg'>
            <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />
            <div className='fw-bold fs-2 text-white mt-1 afterline'>
              {title === intl.formatMessage({id: 'DOCTOR.DETAILS'})
                ? getdata?.total_number_of_doctors
                : title === intl.formatMessage({id: 'STAFF.DETAILS'})
                ? getdata?.total_number_of_staff
                : title === intl.formatMessage({id: 'PATIENT.DETAILS'})
                ? getdata?.total_number_of_patients
                : ''}
            </div>
          </div>

          <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
        </div>
      </a>
    </>
  )
}

export {DashboardCard}
