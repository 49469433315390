import {toAbsoluteUrl} from '../../../_jmh/helpers'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

const LandingFooter = () => {
  const intl = useIntl()
  return (
    <footer className='landing_footer'>
      <div className='landing_container'>
        <div className='footer_container'>
          <div className='row'>
            <div className='col-3'>
              <div className='footer_left'>
                <div className='landing_footer_logo'>
                  <Link to='/'>
                    <img src={toAbsoluteUrl('/media/landing_asset/landing_footer_logo.png')} />
                    <h6>{intl.formatMessage({id: 'GENERAL.LOGO'})}</h6>
                  </Link>
                </div>
                <p className='mb-5'>{intl.formatMessage({id: 'LANDING_PAGE.FOOTER.TEXT'})}</p>
                <p className='landing_copyright pt-5 mb-0'>
                  © {intl.formatMessage({id: 'GENERAL.LOGO'})} &nbsp;{new Date().getFullYear()}
                </p>
              </div>
            </div>
            <div className='col-6'>
              <div className='row'>
                <div className='col-4'>
                  <div className='landing_foote_middle_content'>
                    <h6>{intl.formatMessage({id: 'GENERAL.COMPANY'})}</h6>
                    <ul className='list-unstyled'>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.TEAM'})}</Link>
                      </li>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.HISTORY'})}</Link>
                      </li>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.CONTACT_US'})}</Link>
                      </li>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.LOCATIONS'})}</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='col-4'>
                  <div className='landing_foote_middle_content'>
                    <h6>{intl.formatMessage({id: 'GENERAL.SERVICES'})}</h6>
                    <ul className='list-unstyled'>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.RESOURCE'})}</Link>
                      </li>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.RESOURCE_NAME'})}</Link>
                      </li>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.ANOTHER_RESOURCE'})}</Link>
                      </li>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.FINAL_RESOURCE'})}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='landing_foote_middle_content'>
                    <h6>{intl.formatMessage({id: 'AUTH.GENERAL.LEGAL'})}</h6>
                    <ul className='list-unstyled'>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.PRIVACY_POLICY'})}</Link>
                      </li>
                      <li>
                        <Link to='/'>{intl.formatMessage({id: 'GENERAL.TERMS_OF_USE'})}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='landing_footer_right'>
                <div className='landing_social_media'>
                  <ul className='list-unstyled d-flex align-item-center flex-wrap'>
                    <li className='landing_social_item'>
                      <Link to='/'>
                        <span>
                          <i className='fa-brands fa-facebook-f'></i>
                        </span>
                      </Link>
                    </li>
                    <li className='landing_social_item'>
                      <Link to='/'>
                        <span>
                          <i className='fa-brands fa-linkedin'></i>
                        </span>
                      </Link>
                    </li>

                    <li className='landing_social_item'>
                      <Link to='/'>
                        <span>
                          <i className='fa-brands fa-twitter'></i>
                        </span>
                      </Link>
                    </li>
                    <li className='landing_social_item'>
                      <Link to='/'>
                        <span>
                          <i className='fa-brands fa-instagram'></i>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default LandingFooter
