import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {PatientDoc, PatientDocQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const PATIENT_URL = `${API_URL}/patient/documents`
const PATIENT_UPLOAD_URL = `${API_URL}/patient/upload-document`
const PATIENT_DELETE_URL = `${API_URL}/patient/delete-document`



const getPatientDoc = (query: string,id:number,hosId:number,appointId:any): Promise<PatientDocQueryResponse> => {
  return axios
    .get(`${PATIENT_URL}?${query ? `${query}&limit=10&patient_id=${id}&hospital_id=${hosId}&appointment_id=${appointId}` : `limit=10&hospital_id=${hosId}&patient_id=${id}`}`)
    .then((d: AxiosResponse<PatientDocQueryResponse>) => d.data)
}

const getPatientDocById = (id: ID): Promise<PatientDoc | undefined> => {
  return axios
    .get(`${PATIENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<PatientDoc>>) => response.data)
    .then((response: Response<PatientDoc>) => response.data)
}

const createPatientDoc = (user: any): Promise<PatientDoc | undefined> => {
  return axios
    .post(PATIENT_UPLOAD_URL, user)
}

const updatePatientDoc = (user: PatientDoc): Promise<PatientDoc | undefined> => {
  
  return axios
    .patch(`${PATIENT_URL}/${user.id}`,user)
}

const deletePatientDoc = (Id: ID): Promise<void> => {
  return axios.delete(`${PATIENT_DELETE_URL}`,{data: { ids: [Id]}})
}



export {getPatientDoc, getPatientDocById, createPatientDoc, updatePatientDoc, deletePatientDoc}
