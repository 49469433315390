import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_jmh/helpers'
import {Medicine, PatientsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const PATIENT_URL = `${API_URL}/patient-medicine-master`
const GET_USERS_URL = `${API_URL}/patient-medicine-master`
const UPLOAD_DOC = `${API_URL}/patient-medicine-master/upload-csv`

const getMedicine = (query: string, id: number): Promise<PatientsQueryResponse> => {
  return axios
    .get(
      `${GET_USERS_URL}?${
        query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`
      }`
    )
    .then((d: AxiosResponse<PatientsQueryResponse>) => d.data)
}

const getMedicineById = (id: ID): Promise<Medicine | undefined> => {
  return axios
    .get(`${PATIENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Medicine>>) => response.data)
    .then((response: Response<Medicine>) => response.data)
}

const getMedicineReport = (id: ID): Promise<Medicine | undefined> => {
  return axios
    .get(`${PATIENT_URL}/csv?hospital_id=${id}`)
    .then((response: AxiosResponse<Response<Medicine>>) => response.data)
    .then((response: Response<Medicine>) => response.data)
}

const createMedicine = (user: Medicine): Promise<Medicine | undefined> => {
  return axios
    .post(PATIENT_URL, user)
}

const uploadDocument = (user: any): Promise<Medicine | undefined> => {
  return axios
    .post(UPLOAD_DOC, user)
    .then((response: AxiosResponse<Response<Medicine>>) => response.data)
    .then((response: Response<Medicine>) => response.data)
}

const updateMedicine = (user: Medicine): Promise<Medicine | undefined> => {
  return axios
    .patch(`${PATIENT_URL}/${user.id}`, user)
}

const deleteMedicine = (userId: ID): Promise<void> => {
  return axios.delete(`${PATIENT_URL}/${userId}`)
}

const deleteSelectedMedicine = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${PATIENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getMedicine,
  deleteMedicine,
  getMedicineReport,
  deleteSelectedMedicine,
  getMedicineById,
  createMedicine,
  updateMedicine,
  uploadDocument,
}
