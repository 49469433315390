import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AssetListHeader} from './components/header/AssetListHeader'
import {AssetTable} from './table/AssetTable'
import {AssetEditModal} from './Asset-edit-modal/AssetEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const AssetList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <AssetListHeader />
        <AssetTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AssetEditModal />}
    </>
  )
}

const AssetListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AssetList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AssetListWrapper}
