/* eslint-disable jsx-a11y/anchor-is-valid */

import moment from 'moment'
import {FC} from 'react'

import {Operationlist} from '../../core/_models'

type Props = {
  user: Operationlist
}

const StartDate: FC<Props> = ({user}) => {
  const start_date = moment(user?.operation_start_date).format('DD-MM-YYYY')
  return (
    <div className='d-flex align-items-center'>
      <p className='text-gray-800 mb-1'>{start_date}</p>
    </div>
  )
}

export {StartDate}
