import {FC} from 'react'
import {AssetModel} from '../../core/_models'

type Props = {
  user: AssetModel
}

const AssetPurchaseCostCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <div className='text-gray-800  mb-1'>
          {user?.purchase_cost ? '₹' + user?.purchase_cost : ''}
        </div>
      </div>
    </div>
  )
}

export {AssetPurchaseCostCell}
