// @ts-nocheck
import {Column} from 'react-table'
import {AssetInfoCell} from './AssetInfoCell'
import {AssetActionsCell} from './AssetActionsCell'
import {AssetCustomHeader} from './AssetCustomHeader'
import {Asset} from '../../core/_models'
import {AssetCategoryCell} from './AssetCategoryCell'
import {AssetPurchaseDateCell} from './AssetPurchaseDateCell'
import {AssetPurchaseCostCell} from './AssetPurchaseCostCell'
import {AssetStatusCell} from './AssetStatusCell'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<Asset>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ASSET.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'name',
    Cell: ({...props}) => <AssetInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ASSET.CATEGORY.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'category_id',
    Cell: ({...props}) => <AssetCategoryCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.PURCHASE.DATE'})}
          className='min-w-125px'
        />
      )
    },
    id: 'purchase_date',
    Cell: ({...props}) => <AssetPurchaseDateCell asset={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.PURCHASE.COST'})}
          className='min-w-125px'
        />
      )
    },
    id: 'purchase_cost',
    Cell: ({...props}) => <AssetPurchaseCostCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <AssetCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.STATUS'})}
          className='min-w-125px'
        />
      )
    },
    id: 'status',
    Cell: ({...props}) => <AssetStatusCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'asset') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })
      return Get.length ? (
        <AssetCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.ACTION'})}
          className='text-end min-w-100px pe-5'
        />
      ) : (
        ''
      )
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)

      const data1: any = loginData?.data?.permissions?.filter((item: any) => {
        if (item.module.name === 'asset') {
          return item?.module_id
        }
      })

      const Get = data1?.filter((item: any) => {
        if (
          (item.permission.method === 'GET' && item.permission.route === ':id') ||
          item.permission.method === 'POST' ||
          item.permission.method === 'PATCH' ||
          item.permission.method === 'DELETE'
        ) {
          return item
        }
      })

      return Get.length ? (
        <AssetActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />
      ) : null
    },
  },
]

export {usersColumns}
