/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Staff} from '../../core/_models'

type Props = {
  user: Staff
}

const StaffRoleCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <div className='text-gray-800 mb-1'>
          {user?.user?.role?.name}
        </div>
      </div>
    </div>
  )
}

export {StaffRoleCell}
