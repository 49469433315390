/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ShiftModel} from '../../core/_models'

type Props = {
  Shift: ShiftModel
  
}

const ShiftEndTimeCell: FC<Props> = ({Shift}) => {
  return (
    <>
    
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <div className='text-gray-800 mb-1'>
        {/* {formatDate(myDate)} */}
        {Shift.end_time}
      </div> 
    </div>
  </div>
  </>
  )
}

export {ShiftEndTimeCell}