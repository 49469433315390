// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {Operationlist} from '../../core/_models'
import {DoctorName} from './DoctorName'
import {RoomNumber} from './RoomNumber'
import {StartDate} from './StartDate'
import {useContext} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const usersColumns: ReadonlyArray<Column<Operationlist>> = [
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.PATIENT.NAME'})}
          className='min-w-125px'
        />
      )
    },
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.DOCTOR.NAME'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'doctor_name',
    Cell: ({...props}) => <DoctorName user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.HOSPITAL.ROOM.NUMBER'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'hospital_room_number',
    Cell: ({...props}) => <RoomNumber user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return (
        <UserCustomHeader
          tableProps={props}
          title={intl.formatMessage({id: 'GENERAL.START.DATE'})}
          className='min-w-125px'
        />
      )
    },
    accessor: 'start_date',
    Cell: ({...props}) => <StartDate user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <UserCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px pe-5'
          />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'operation-theatre') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH'
            // item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <UserCustomHeader
            tableProps={props}
            title={intl.formatMessage({id: 'GENERAL.ACTION'})}
            className='text-end min-w-100px'
          />
        ) : (
          ''
        )
      } else {
        return null
      }
    },
    id: 'actions',
    Cell: ({...props}) => {
      const {loginData} = useContext(loginContext)
      const userType = loginData?.data?.user_type

      if (userType === 'SUPER_ADMIN') {
        return (
          <UserActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />
        )
      } else if (userType === 'DOCTOR' || userType === 'STAFF') {
        const data1: any = loginData?.data?.permissions?.filter((item: any) => {
          if (item.module.name === 'operation-theatre') {
            return item?.module_id
          }
        })

        const Get = data1?.filter((item: any) => {
          if (
            (item.permission.method === 'GET' && item.permission.route === ':id') ||
            item.permission.method === 'POST' ||
            item.permission.method === 'PATCH'
            // item.permission.method === 'DELETE'
          ) {
            return item
          }
        })

        return Get.length ? (
          <UserActionsCell id={props.data[props.row.index].id} user={props.data[props.row.index]} />
        ) : null
      } else {
        return null
      }
    },
  },
]

export {usersColumns}
