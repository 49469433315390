/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_jmh/assets/ts/components'
import {ID, KTSVG} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ATD, dischargeDate, TransferData} from '../../core/_models'
import DischargeModal from '../../Discharge/DischargeModal'
import TransferModal from '../../Transfer/TransferModal'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  id: ID
  user: ATD
  transfer: TransferData
  discharge: dischargeDate
}

const UserActionsCell: FC<Props> = ({id, user, transfer, discharge}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showDischargeModal, setShowDischargeModal] = useState(false)

  const {loginData} = useContext(loginContext)

  const data = loginData?.data?.permissions?.filter((item: any) => {
    if (item.module.name === 'ad-patient') {
      return item?.module_id
    }
  })

  const edit = data?.filter((item: any) => {
    if (item.permission.method === 'PATCH') {
      return item
    }
  })

  const view = data?.filter((item: any) => {
    if (item.permission.route === ':id' && item.permission.method === 'GET') {
      return item
    }
  })

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {edit.length ? (
          <>
            {user?.discharge_date ? (
              ''
            ) : (
              <>
                <div className='menu-item px-3'>
                  <a className='menu-link px-3' onClick={openEditModal}>
                    {intl.formatMessage({id: 'GENERAL.EDIT'})}
                  </a>
                </div>
                <div className='menu-item px-3'>
                  <a className='menu-link px-3' onClick={() => setShowTransferModal(true)}>
                    {intl.formatMessage({id: 'GENERAL.TRANSFER'})}
                  </a>
                </div>
              </>
            )}

            {user?.discharge_date === null ? (
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => setShowDischargeModal(true)}
                >
                  {intl.formatMessage({id: 'GENERAL.DISCHARGE'})}
                </a>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}

        {view.length ? (
          <div className='menu-item px-3'>
            <Link to={`/patient-data/ad-patient-view/${id}`} className='menu-link px-3'>
              {intl.formatMessage({id: 'GENERAL.VIEW'})}
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>

      <TransferModal
        show={showTransferModal}
        onClose={() => setShowTransferModal(false)}
        id={id}
        isUserLoading={false}
        user={user}
        transfer={transfer}
      />

      <DischargeModal
        show={showDischargeModal}
        onClose={() => setShowDischargeModal(false)}
        id={id}
        isUserLoading={false}
        discharge={discharge}
      />
    </>
  )
}

export {UserActionsCell}
