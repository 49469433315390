import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {AppointmensListWrapper} from './appointments-list/AppointmentsList'

const AppointmentPage = () => (
  <>
    <div className=''>
      <AppointmensListWrapper />
    </div>
  </>
)

const PatientAppointmentWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PATIENT.APPOINTMENT'})}</PageTitle>
      <AppointmentPage />
    </>
  )
}

export {PatientAppointmentWrapper}
