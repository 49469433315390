import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialUser, PurchaseInventory} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {PurchaseInvListLoading} from '../components/loading/PurchaseInvListLoading'
import {
  createPurchaseInventory,
  getCategoryList,
  getInventory,
  updatePurchaseInventory,
} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import {loginContext} from '../../../../context/context'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: PurchaseInventory
}

const PurchaseInvEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [catName, setCatName] = useState([])
  const [invName, setInvName] = useState([])
  const [purchaseDate, setPurchaseDate] = useState<Date | null>(null)
  const {selectedHospitalId} = useContext(loginContext)

  let editUserSchema
  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      description: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
      available_quantity: Yup.number()
        .typeError(intl.formatMessage({id: 'VALIDATION.PUR_INV.AVAILABLE_QUANTITY_NUMBER'}))
        .required(intl.formatMessage({id: 'VALIDATION.PUR_INV.REQ.AVAILABLE_QUANTITY'}))
        .min(1, intl.formatMessage({id: 'VALIDATION.PUR_INV.AVAILABLE_QUANTITY.GREATER_0'})),
    })
  } else {
    editUserSchema = Yup.object().shape({
      name: Yup.string()
        .test('noLeadingSpaces', intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}), (value) => {
          return !value || value.trimStart() === value
        })
        .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
        .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
        .matches(
          /^(?=(.*[A-Za-z]){2})[A-Za-z0-9\s]+$/,
          intl.formatMessage({id: 'VALIDATION.REQ.TWO_CHAR'})
        )
        .required(intl.formatMessage({id: 'VALIDATION.REQ.INV_NAME'})),
      description: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
      available_quantity: Yup.number()
        .typeError(intl.formatMessage({id: 'VALIDATION.PUR_INV.AVAILABLE_QUANTITY_NUMBER'}))
        .required(intl.formatMessage({id: 'VALIDATION.PUR_INV.REQ.AVAILABLE_QUANTITY'}))
        .min(1, intl.formatMessage({id: 'VALIDATION.PUR_INV.AVAILABLE_QUANTITY.GREATER_0'})),
      purchase_date: Yup.date().required(intl.formatMessage({id: 'VALIDATION.REQ.PURCHASE_DATE'})),
      category_id: Yup.number().required(intl.formatMessage({id: 'VALIDATION.REQ.CATEGORY_NAME'})),
      manufacturer_name: Yup.string().test(
        'noLeadingSpaces',
        intl.formatMessage({id: 'VALIDATION.SPACING_ERROR'}),
        (value) => {
          return !value || value.trimStart() === value
        }
      ),
    })
  }

  const [userForEdit] = useState<PurchaseInventory>({
    ...user,
    name: user.name || initialUser.name,
    available_quantity: user.available_quantity || initialUser.available_quantity,
    purchase_date: user.purchase_date || initialUser.purchase_date,
    category_id: user.category_id || initialUser.category_id,
    description: user.description || initialUser.description,
  })

  useEffect(() => {
    ;(async () => {
      const invCategoryData: any = await getCategoryList()

      const filterCategory = invCategoryData?.data?.filter((item: any) => {
        return item?.hospital_id?.id == selectedHospitalId
      })
      setCatName(filterCategory)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const invNameData: any = await getInventory()
      setInvName(invNameData?.data)
    })()
  }, [])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const formData = new FormData()
      formData.append(
        'purchase_date',
        (values['purchase_date'] = moment(values['purchase_date'], 'DD-MM-YYYY').format(
          'DD-MM-YYYY'
        ) as any)
      )
      try {
        if (isNotEmpty(values.id)) {
          delete values?.category_id
          delete values?.hospital_id
          await updatePurchaseInventory({...values, formData}).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          await createPurchaseInventory({
            ...values,
            formData,
            hospital_id: Number(selectedHospitalId),
            category_id: Number(values.category_id),
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const future_date_disable = new Date(moment().format('YYYY-MM-DD'))

  return (
    <>
      <form id='kt_modal_add_user_form2' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PURCHASE_INVENTORY.NAME.INVENTORY'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'PURCHASE_INVENTORY.NAME.INVENTORY'})}
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  `form-control ${itemIdForUpdate ? '' : ' form-control-solid'}  mb-3 mb-lg-0`
                )}
                autoComplete='off'
                disabled={itemIdForUpdate ? true : false}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'INVENTORY.INVENTORY_CATEGORY'})}
              </label>
              <select
                className='form-select select_arrow_remove'
                aria-label='Select example'
                {...formik.getFieldProps('category_id.id')}
                name='category_id'
                disabled={itemIdForUpdate ? true : false}
              >
                <option value=''>
                  {intl.formatMessage({id: 'PURCHASE_INVENTORY.SELECT.INVENTORY.CATEGORY.NAME'})}
                </option>
                {catName &&
                  catName?.map((item: any) => {
                    return (
                      <option key={item.id} value={item?.id}>
                        {item?.name}
                      </option>
                    )
                  })}
              </select>
              {formik.touched.category_id && formik.errors.category_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.category_id}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
            </label>
            <textarea
              placeholder={intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
              {...formik.getFieldProps('description')}
              name='description'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0 no-resize')}
              autoComplete='off'
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.AVAILABLE.QUANTITY'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.AVAILABLE.QUANTITY'})}
                {...formik.getFieldProps('available_quantity')}
                type='number'
                name='available_quantity'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.available_quantity && formik.errors.available_quantity && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.available_quantity}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.PURCHASE.DATE'})}
              </label>

              <div className='position-relative'>
                <ReactDatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  {...formik.getFieldProps('purchase_date')}
                  dropdownMode='select'
                  className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                  dateFormat='dd-MM-yyyy'
                  name='purchase_date'
                  maxDate={future_date_disable}
                  selected={purchaseDate}
                  onChange={(date) => {
                    formik.setFieldValue('purchase_date', date)
                    setPurchaseDate(date)
                  }}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  placeholderText={intl.formatMessage({id: 'GENERAL.PURCHASE.DATE'})}
                />

                <span
                  className='DatePicker_icon cursor-pointer'
                  onClick={() => document.getElementsByName('purchase_date')[0].focus()}
                >
                  <div className='fa fa-calendar'></div>
                </span>
              </div>
              {formik.touched.purchase_date && formik.errors.purchase_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.purchase_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.PURCHASE.COST'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.PURCHASE.COST'})}
                {...formik.getFieldProps('purchase_cost')}
                type='number'
                name='purchase_cost'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PURCHASE_INVENTORY.MANUFACTURER.NAME'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'PURCHASE_INVENTORY.MANUFACTURER.NAME'})}
                {...formik.getFieldProps('manufacturer_name')}
                type='text'
                name='manufacturer_name'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.manufacturer_name && formik.errors.manufacturer_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.manufacturer_name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <PurchaseInvListLoading />}
    </>
  )
}

export {PurchaseInvEditModalForm}
