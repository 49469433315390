import {ChangeEvent, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import TagsInput from 'react-tagsinput'

const Examination = () => {
  const intl = useIntl()
  const [examinationTags, setExaminationTags] = useState<any>([])
  const [examinationInputValue, setExaminationInputValue] = useState<any>('')
  const [temperatureValue, setTemperatureValue] = useState<number>()
  const [temperatureUnit, setTemperatureUnit] = useState('°F')
  const [pulseValue, setPulseValue] = useState<number>()
  const [respiratoryRate, setRespiratoryRate] = useState<number>()
  const [bloodPressure, setBloodPressure] = useState<string>()
  const [spO2, setSpO2] = useState<number>()
  const [heightValue, setHeightValue] = useState<number>()
  const [heightUnit, setHeightUnit] = useState('cm')
  const [weightValue, setWeightValue] = useState<number>()
  const [weightUnit, setWeightUnit] = useState('kg')

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem('prescriptionData')
    if (sessionStorageData) {
      const prescriptionData = JSON.parse(sessionStorageData)
      const updatedPrescriptionData = {
        ...prescriptionData,
        examination: {
          ...prescriptionData.examination,
          temperature: {
            temperature_value: temperatureValue,
            temperature_unit: temperatureUnit,
          },
          pulse: pulseValue,
          respiratory_rate: respiratoryRate,
          blood_pressure: bloodPressure,
          spO2: spO2,
          height: {
            height_value: heightValue,
            height_unit: heightUnit,
          },
          weight: {
            weight_value: weightValue,
            weight_unit: weightUnit,
          },
        },
        examination_notes: examinationTags, // Include examination notes here
      }
      sessionStorage.setItem('prescriptionData', JSON.stringify(updatedPrescriptionData))
    }
  }, [
    temperatureValue,
    temperatureUnit,
    pulseValue,
    respiratoryRate,
    bloodPressure,
    spO2,
    heightValue,
    heightUnit,
    weightValue,
    weightUnit,
    examinationTags,
  ])

  const handleExaminationHisoryTagRemove = (tag: any) => {
    setExaminationTags(examinationTags.filter((t: any) => t !== tag))
  }

  const handleExaminationInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExaminationInputValue(e.target.value)
  }

  const handleExaminationHistoryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (examinationInputValue.trim() !== '') {
        setExaminationTags([...examinationTags, examinationInputValue.trim()])
        setExaminationInputValue('')
      }
      e.preventDefault()
    }
  }

  const handleTemperatureInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTemperatureValue(parseInt(e.target.value))
  }

  const handleTemperatureUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTemperatureUnit(e.target.value)
  }

  const handlePulseInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPulseValue(parseInt(e.target.value))
  }

  const handleRespiratoryRateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRespiratoryRate(parseInt(e.target.value))
  }

  const handleBloodPressureChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBloodPressure(e.target.value)
  }

  const handleSpO2Change = (e: ChangeEvent<HTMLInputElement>) => {
    setSpO2(parseInt(e.target.value))
  }

  const handleHeightInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHeightValue(parseInt(e.target.value))
  }

  const handleHeightUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setHeightUnit(e.target.value)
  }

  const handleWeightInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWeightValue(parseInt(e.target.value))
  }

  const handleWeightUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setWeightUnit(e.target.value)
  }

  return (
    <>
      <h4>Examination</h4>
      <div className='examination-wrapper d-flex flex-wrap'>
        <div className='examination_item has-dropdown'>
          <div className='input-group mb-3'>
            <label htmlFor='temp_value'>Temperature</label>
            <input
              className='form-control'
              type='number'
              list='temp_values'
              name='temp_values'
              id='temp_value'
              value={temperatureValue || ''}
              onChange={handleTemperatureInputChange}
            />
            <datalist id='temp_values'>
              <option value='96' />
              <option value='98' />
              <option value='100' />
              <option value='101' />
              <option value='102' />
            </datalist>

            <div className='input-group-append'>
              <select
                className='custom-select'
                id='exam_temperature'
                onChange={handleTemperatureUnitChange}
              >
                <option defaultValue='°F' value='°F'>
                  °F
                </option>
                <option value='°C'>°C</option>
              </select>
            </div>
          </div>
        </div>
        <div className='examination_item'>
          <div className='input-group mb-3'>
            <label htmlFor='pulse_value'>Pulse</label>
            <input
              className='form-control'
              type='number'
              list='pulse_values'
              name='pulse_values'
              id='pulse_value'
              value={pulseValue ?? ''}
              onChange={handlePulseInputChange}
            />
            <datalist id='pulse_values'>
              <option value='40' />
              <option value='60' />
              <option value='80' />
              <option value='100' />
              <option value='120' />
            </datalist>

            <div className='input-group-append'>
              <span className='input-has-value' id='exam_pulse'>
                bpm
              </span>
            </div>
          </div>
        </div>
        <div className='examination_item'>
          <div className='input-group mb-3'>
            <label htmlFor='respiratory_value'>respiratory rate</label>
            <input
              className='form-control'
              type='number'
              list='respiratory_values'
              name='respiratory_values'
              id='respiratory_value'
              value={respiratoryRate ?? ''}
              onChange={handleRespiratoryRateChange}
            />
            <datalist id='respiratory_values'>
              <option value='8' />
              <option value='12' />
              <option value='15' />
              <option value='20' />
              <option value='30' />
            </datalist>

            <div className='input-group-append'>
              <span className='input-has-value' id='exam_respiratory'>
                rpm
              </span>
            </div>
          </div>
        </div>
        <div className='examination_item'>
          <div className='input-group mb-3'>
            <label htmlFor='bloodpressure_value'>Blood Pressure</label>
            <input
              className='form-control'
              type='text'
              list='bloodpressure_values'
              name='bloodpressure_values'
              id='bloodpressure_value'
              value={bloodPressure ?? ''}
              onChange={handleBloodPressureChange}
            />
            <datalist id='bloodpressure_values'>
              <option value='120/80' />
              <option value='130/80' />
              <option value='140/90' />
              <option value='180/120' />
            </datalist>

            <div className='input-group-append'>
              <span className='input-has-value' id='exam_bloodpressure'>
                mmHg
              </span>
            </div>
          </div>
        </div>
        <div className='examination_item'>
          <div className='input-group mb-3'>
            <label htmlFor='sp02_value'>SP02</label>
            <input
              className='form-control'
              type='number'
              list='sp02_values'
              name='sp02_values'
              id='sp02_value'
              value={spO2 ?? ''}
              onChange={handleSpO2Change}
            />
            <datalist id='sp02_values'>
              <option value='95' />
              <option value='96' />
              <option value='97' />
              <option value='98' />
              <option value='99' />
              <option value='100' />
            </datalist>

            <div className='input-group-append'>
              <span className='input-has-value' id='exam_sp02'>
                %
              </span>
            </div>
          </div>
        </div>
        <div className='examination_item has-dropdown'>
          <div className='input-group mb-3'>
            <label htmlFor='height_value'>height</label>
            <input
              className='form-control'
              type='number'
              list='height_values'
              name='height_values'
              id='height_value'
              value={heightValue ?? ''}
              onChange={handleHeightInputChange}
            />
            <datalist id='height_values'>
              <option value='150' />
              <option value='155' />
              <option value='160' />
              <option value='165' />
              <option value='170' />
              <option value='175' />
              <option value='180' />
            </datalist>

            <div className='input-group-append'>
              <select className='custom-select' id='exam_height' onChange={handleHeightUnitChange}>
                <option defaultValue='cm' value='cm'>
                  cm
                </option>
                <option value='m'>m</option>
              </select>
            </div>
          </div>
        </div>
        <div className='examination_item has-dropdown'>
          <div className='input-group mb-3'>
            <label htmlFor='weight_value'>weight</label>
            <input
              className='form-control'
              type='number'
              list='weight_values'
              name='weight_values'
              id='weight_value'
              value={weightValue ?? ''}
              onChange={handleWeightInputChange}
            />
            <datalist id='weight_values'>
              <option value='60' />
              <option value='70' />
              <option value='80' />
              <option value='90' />
              <option value='100' />
            </datalist>

            <div className='input-group-append'>
              <select className='custom-select' id='exam_weight' onChange={handleWeightUnitChange}>
                <option defaultValue='kg' value='kg'>
                  kg
                </option>
                <option value='g'>g</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='examination_notes my-5'>
        <h6>Examination Notes</h6>
        <div className='fv-row mb-2 col-12'>
          <TagsInput
            value={examinationTags}
            onChange={(newTags) => {
              setExaminationTags(newTags)
            }}
            tagProps={{
              className:
                'react-tagsinput-tag btn btn-sm btn-light-primary btn-active-light-primary shadow',
              onRemove: (index: any) => handleExaminationHisoryTagRemove(examinationTags[index]),
            }}
            inputProps={{
              placeholder: intl.formatMessage({
                id: 'GENERAL.ADD.TYPE_YOUR_NOTES_HERE',
              }),
              value: examinationInputValue,
              onChange: handleExaminationInputChange,
              onKeyDown: handleExaminationHistoryKeyDown,
            }}
            className='form-control form-control-solid mb-3 mb-lg-0 no-resize'
          />
        </div>
      </div>
    </>
  )
}

export default Examination
