import {KTSVG} from '../../../../../../_jmh/helpers'
import {useContext, useState} from 'react'
import {loginContext} from '../../../../../context/context'
import {useIntl} from 'react-intl'

const NotificationListToolbar = () => {
  const intl = useIntl()
  const {readNotification, setReadNotification} = useContext(loginContext)
  const [isAllNotificationActive, setAllNotificationActive] = useState(true)
  const {isReadButtonClicked, setReadButtonClicked} = useContext(loginContext)

  const filterData = (query: string) => {
    setReadNotification(query)
    setAllNotificationActive(query === '')
  }

  const handleReadClick = () => {
    filterData('read=1')
    setReadButtonClicked(true)
  }

  const handleUnreadClick = () => {
    filterData('read=2')
  }

  const handleAllNotificationClick = () => {
    filterData('')
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className={`btn btn-light-primary btn-active-primary fw-bold me-2 px-6 ${
          isReadButtonClicked ? 'd-none' : ''
        }`}
        onClick={handleReadClick}
      >
        <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'NOTIFICATION.READ'})}
      </button>
      {/* Render the icon conditionally based on the state */}
      {!isReadButtonClicked && (
        <button
          type='button'
          className='btn btn-light-primary btn-active-primary fw-bold me-2 px-6'
          onClick={handleUnreadClick}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr098.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'NOTIFICATION.UNREAD'})}
        </button>
      )}
      <button
        type='button'
        className={`btn btn-light-primary btn-active-primary fw-bold me-2 px-6 ${
          isAllNotificationActive ? 'active' : ''
        }`}
        onClick={handleAllNotificationClick}
      >
        {intl.formatMessage({id: 'NOTIFICATION.ALL.NOTIFICATIONS'})}
      </button>
    </div>
  )
}

export {NotificationListToolbar}
