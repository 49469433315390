import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PatientUploadDocListHeader} from './components/header/PatientUploadDocListHeader'
import {PatientUploadDocTable} from './table/PatientUploadDocTable'
import {PatientUploadDocEditModal} from './Patient-doc-edit-modal/PatientUploadDocEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const PatientUploadDocList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <PatientUploadDocListHeader />
        <PatientUploadDocTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <PatientUploadDocEditModal />}
    </>
  )
}

const PatientUploadDocListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PatientUploadDocList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PatientUploadDocListWrapper}
