import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {Patient, PatientsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const API_URL = "http://192.168.10.200:3000"

const PATIENT_URL = `${API_URL}/patient`
const GET_USERS_URL = `${API_URL}/patient`
const APPOINTMENT_URL = `${API_URL}/patient-appointment/patients`

const getPatients = (query: string,id:number): Promise<PatientsQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<PatientsQueryResponse>) => d.data)
}

const getPatientById = (id: ID): Promise<Patient | undefined> => {
  return axios
    .get(`${PATIENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Patient>>) => response.data)
    .then((response: Response<Patient>) => response.data)
}

const getPatientReport = (id: ID): Promise<Patient | undefined> => {
  return axios
    .get(`${PATIENT_URL}/csv?hospital_id=${id}`)
    .then((response: AxiosResponse<Response<Patient>>) => response.data)
    .then((response: Response<Patient>) => response.data)
}

const createPatient = (user: Patient): Promise<Patient | undefined> => {
  return axios
    .post(PATIENT_URL, user)
}

const updatePatient = (user: Patient): Promise<Patient | undefined> => {
  return axios
    .patch(`${PATIENT_URL}/${user.id}`, user)
}

const deletePatient = (userId: ID): Promise<void> => {
  return axios.delete(`${PATIENT_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${PATIENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getAppointmentByPatientId = (id: number) => {
  return axios
    .get(`${APPOINTMENT_URL}/${id}?order=DESC`)
    .then((response) => response.data)  
}

export {getPatients, deletePatient,getPatientReport, deleteSelectedUsers, getPatientById, createPatient, updatePatient,getAppointmentByPatientId}
