import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL


const SPECIALITY_URL = `${API_URL}/speciality`
const GET_SPECIALITY_URL = `${API_URL}/speciality/`


const getSpeciality = (query: string,id:number): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_SPECIALITY_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}


const getSpecialityById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${SPECIALITY_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createSpeciality = (user: User): Promise<User | undefined> => {
  return axios
    .post(SPECIALITY_URL, user)
}

const updateSpeciality = (user: User): Promise<User | undefined> => {
  return axios
    .patch(`${SPECIALITY_URL}/${user.id}`, user)
}

export {getSpeciality, getSpecialityById, createSpeciality, updateSpeciality}
