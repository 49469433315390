/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useContext} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, useAuth} from '../modules/auth'
import {App} from '../App'
import {Login} from '../modules/auth/components/Login'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import ResetPasswordForm from '../modules/auth/components/ResetPassWord'
import PrivateRoutes from './PrivateRoutes'
import {ChnagepasswordWrapper} from '../pages/Changepassword/Changepassword'
import LandingPage from '../pages/landing-page'
import {loginContext} from '../context/context'
import PageNotfound from '../pages/landing-page/PageNotfound'
import ContactUs from '../pages/landing-page/ContactUs'
import PatientResetPassword from '../modules/auth/components/PatientComponents/PatientResetPassword'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const {loginData} = useContext(loginContext)

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='contact-us' element={<ContactUs />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/' element={<LandingPage />} />
          <Route path='page-not-found' element={<PageNotfound />} />
          {/* for the patient user */}
          <Route path='error/*' element={<ErrorsPage />} />
          {/* for the other users */}
          <Route path='patient-reset-password' element={<PatientResetPassword />} />

          {currentUser ? (
            <>
              {loginData?.data?.user_type == 'PATIENT' ? (
                <>
                  <Route element={<Navigate to='/' />} />
                </>
              ) : (
                <>
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              )}

              <Route path='/*' element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              {/* <Route path='login/*' element={<AuthPage />} /> */}
              <Route path='login' element={<Login />} />
              <Route path='forgot-password' element={<ForgotPassword />} />
              <Route path='reset-password' element={<ResetPasswordForm />} />
              <Route path='*' element={<Navigate to='/page-not-found' />} />
              <Route path='change-password' element={<ChnagepasswordWrapper />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
