/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import moment from 'moment'
import {AccountHeader} from './ProfileHeader'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_jmh/helpers'

export function Overview() {
  const intl = useIntl()
  const storedLoginData = localStorage.getItem('loginData')
  const loginData = storedLoginData ? JSON.parse(storedLoginData) : null
  const {contact_number, date_of_birth, full_name, gender, permanent_address} =
    loginData?.data?.personal_details || {}

  return (
    <>
      <div className='user_profile_back'>
        <img src={toAbsoluteUrl('/media/landing_asset/user_profile_back.jpg')} alt='Logo' />
      </div>
      <div className='row user_profile_row'>
        <div className='col-4'>
          <AccountHeader />
        </div>
        <div className='col-8'>
          <div className='card shadow-lg mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>
                  {intl.formatMessage({id: 'PROFILE.PROFILE_DETAILS'})}
                </h3>
              </div>
              <Link
                to='/my-profile/update-profile'
                className='btn btn-primary align-self-center p-3'
              >
                <i className='bi bi-pencil-square fs-1 p-0'></i>
              </Link>
            </div>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  {intl.formatMessage({id: 'GENERAL.FULL.NAME'})}
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{full_name}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  {intl.formatMessage({id: 'GENERAL.GENDER'})}
                </label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-dark'>{gender}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  {intl.formatMessage({id: 'GENERAL.DOB'})}
                </label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-dark'>
                    {date_of_birth ? moment(date_of_birth).format('DD-MM-YYYY') : null}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}
                </label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6 text-dark'>{permanent_address}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}
                </label>
                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2 text-dark'>{contact_number}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
