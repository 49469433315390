import {ID, Response} from '../../../../../_jmh/helpers'


export type AssetModel = {
    id?: ID;
    name?: string;
    purchase_date?: string;
    purchase_cost?: number;
    current_value?: number;
    disposal_date?: string;
    description?: string;
    asset_status?: string;
    status?: string;
    created_at?: string;
    updated_at?: string;
    hospital_id?: number;
    category_id?: {
      id?: any;
      name?: string;
      status?: string;
      created_at?: string;
      updated_at?: string;
    } | number;
    address?: string;
    contact_person?: string;
    contact_number?: string;
    formateDate?: string
  }
  
export type AssetQueryResponse = Response<Array<AssetModel>>

export const initialAsset: AssetModel = {
  name: '',
  purchase_date: '',
}