import LandingHeader from '../landing-page/LandingHeader'
import LandingFooter from '../landing-page/LandingFooter'
import {KTCard} from '../../../_jmh/helpers'

const OpLayout = ({children}: any) => {
  return (
    <>
      <LandingHeader />
      <div className='landing_container'>
        <KTCard className='landing_card'>{children}</KTCard>
      </div>
      <LandingFooter />
    </>
  )
}

export default OpLayout
