/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {HeaderToolbar} from './HeaderToolbar'
import {useSelector} from 'react-redux'

interface Props {
  toggleAsideMinimize: () => void
}

export function HeaderWrapper({toggleAsideMinimize}: Props) {
  const {config, classes, attributes} = useLayout()
  const asideMinimize = useSelector((state: any) => state.asideMinimize.asideMinimize)

  const {aside} = config
  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div className='header-brand'>
        <Link to='/'>
          <h1 className='m-0' style={{color: '#000'}}>
            {process.env.REACT_APP_ORGANIZATION_NAME}
          </h1>
          {/* <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default-dark.svg')}
            className='h-25px h-lg-25px'
          /> */}
        </Link>

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className={`btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize ${
              asideMinimize ? 'active' : ''
            }`}
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            onClick={toggleAsideMinimize} // Add onClick handler
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr092.svg'
              className='svg-icon-1 me-n1 minimize-default'
            />
            <KTSVG
              path='/media/icons/duotune/arrows/arr076.svg'
              className='svg-icon-1 minimize-active'
            />
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      <HeaderToolbar />
    </div>
  )
}
