import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {AssignInvListWrapper} from './assignInventoryList/AssignInvList'
import {useIntl} from 'react-intl'

const AssignInvPageWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'INVENTORY.ASSIGN_INVENTORY'})}
      </PageTitle>
      <AssignInvListWrapper />
    </>
  )
}

export default AssignInvPageWrapper
