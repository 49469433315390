/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useImperativeHandle, forwardRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register} from '../../core/_requests'
import {useAuth} from '../../core/Auth'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

type RegistrationRef = {
  resetForm: () => void
}

type RegistrationProps = {
  onSuccess: (email: string) => void
  onLoginClick: () => void
}

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  user_type: '',
  hospital_id: 1,
}

export const Registration = forwardRef<RegistrationRef, RegistrationProps>((props, ref) => {
  const [loading, setLoading] = useState(false)
  const {saveAuth} = useAuth()
  const intl = useIntl()

  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.FIRST_NAME'})),
    email: Yup.string()
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL.ERROR'}))
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.EMAIL_ERROR'})),
    lastname: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_CHAR.ERROR'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.MAX_CHAR.ERROR'}))
      .required(intl.formatMessage({id: 'VALIDATION.LAST_NAME'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      try {
        await register(values.email, values.firstname, values.lastname, 'PATIENT').then(
          (res: any) => {
            setLoading(false)
            toast.success(res?.data?.message)
            props.onSuccess(values.email)
          }
        )
      } catch (error: any) {
        saveAuth(undefined)
        setStatus(error?.response?.data?.message)
        setSubmitting(false)
        setLoading(false)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useImperativeHandle(ref, () => ({
    resetForm() {
      formik.resetForm()
    },
  }))

  return (
    <div className='login-form'>
      <div className='row'>
        <div className='col-sm-12 p-0'>
          <form
            className={clsx('form w-100', {
              'form-not-clickable': formik.isSubmitting,
            })}
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <div className='row'>
              <div className='col-6 ps-0'>
                <label className='form-label fw-bolder text-dark fs-6 w-100'>
                  {intl.formatMessage({id: 'GENERAL.FIRST_NAME.TITLE'})}
                </label>
                <div className='position-relative mb-3 mb-sm-5 w-100'>
                  <input
                    placeholder={intl.formatMessage({id: 'PLACEHOLDER.FIRST_NAME'})}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('firstname')}
                    className={clsx('form-control form-control-lg form-control-solid grey_input')}
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.firstname}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-6 pe-0'>
                <label className='form-label fw-bolder text-dark fs-6 w-100'>
                  {intl.formatMessage({id: 'GENERAL.LAST_NAME.TITLE'})}
                </label>
                <div className='position-relative mb-3 mb-sm-5 w-100'>
                  <input
                    placeholder={intl.formatMessage({id: 'PLACEHOLDER.LAST_NAME'})}
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastname')}
                    className={clsx('form-control form-control-lg form-control-solid grey_input')}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.lastname}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='input-group'>
              <label className='form-label fw-bolder text-dark fs-6 w-100'>
                {intl.formatMessage({id: 'GENERAL.EMAIL'})}
              </label>
              <div className='position-relative mb-3 mb-sm-5 w-100'>
                <input
                  placeholder={intl.formatMessage({id: 'GENERAL.EMAIL'})}
                  type='email'
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid grey_input',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && (
                  <span className='indicator-label fw-bolder fs-4'>
                    {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            <div className='input-group mt-5'>
              <div className='d-flex justify-content-start w-100'>
                <Link
                  to='/'
                  className='link-primary fs-6 fw-bolder'
                  onClick={(e) => {
                    e.preventDefault()
                    props.onLoginClick()
                  }}
                >
                  {intl.formatMessage({id: 'AUTH.LOGIN.LOGIN'})}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
})
