import {FC} from 'react'
import {PageTitle} from '../../../_jmh/layout/core'
import {InventoryCategoryListWrapper} from './InventoryCategoryList/InventoryCategoryList'
import {useIntl} from 'react-intl'

const InventoryCategoryPageWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'INVENTORY.INVENTORY_CATEGORY'})}
      </PageTitle>

      <InventoryCategoryListWrapper />
    </>
  )
}

export default InventoryCategoryPageWrapper
