import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_jmh/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL


const ROLES_URL = `${API_URL}/roles`
const GET_ROLES_URL = `${API_URL}/roles/`
const GET_ALL_PERMISSION = `${API_URL}/permissions`
const GET_ALL_ROLES_AND_PERMISSION = `${API_URL}/role-has-permissions` 
const GET_ID_ROLES_AND_PERMISSION = `${API_URL}/role-has-permissions`

const getUsers = (query: string,id:number): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_ROLES_URL}?${query ? `${query}&limit=10&hospital_id=${id}` : `limit=10&hospital_id=${id}`}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getPermission = ()  => {
  return axios.get(`${GET_ALL_PERMISSION}`).then((response: Response<any>) => response.data?.data)
}

const getUserById = (id: ID): Promise<any | undefined> => {
  return axios
    .get(`${ROLES_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(ROLES_URL, user)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .patch(`${ROLES_URL}/${user.id}`, user)
}


const assignPermission = (id: number,body:any) => {
  return axios
    .patch(`${GET_ALL_ROLES_AND_PERMISSION}/${id}`,body)
    .then((response) => response)
}


const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${ROLES_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${ROLES_URL}/${id}`))
  return axios.all(requests).then(() => {})
}


const getRolesandPermissionByID = (id: number) =>  {
  return axios
  .get(`${GET_ID_ROLES_AND_PERMISSION}/${id}`)
  .then((response) => response.data)

}


export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser,getPermission,assignPermission,getRolesandPermissionByID}
