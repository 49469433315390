import clsx from 'clsx'
import {KTSVG, QUERIES, stringifyRequestQuery, toAbsoluteUrl} from '../../../helpers'
import {setLanguage, useLang} from '../../../i18n/Metronici18n'
import {HeaderUserMenu} from '../../../partials'
import {useContext, useState} from 'react'
import {loginContext} from '../../../../app/context/context'
import {NotificationDropdown} from '../../../partials/content/dropdown/NotificationDropdown'
import {useQuery} from 'react-query'
import {
  getNotificationData,
  updatedNewNotification,
} from '../../../partials/content/dropdown/core/request'
import {useQueryRequest} from '../../../../app/pages/notification/notification-list/core/QueryRequestProvider'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'fr',
    name: 'French',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
]

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const {loginData} = useContext(loginContext)
  const {image} = loginData?.data?.personal_details ?? {}
  const [previewUrl] = useState('')
  const [previewKey] = useState('')
  const [notificationCount, setNotificationCount] = useState(0)
  const {state} = useQueryRequest()
  const [query] = useState<string>(stringifyRequestQuery({...state}))
  const cacheKey = `${QUERIES.USERS_LIST}-user-${query}`
  const [notificationCreatedAt, setNotificationCreatedAt] = useState('')
  const {readNotification} = useContext(loginContext)

  const {data: response, refetch: refetchNotifications} = useQuery(
    cacheKey,
    () => {
      return getNotificationData(readNotification, loginData?.data?.personal_details?.id)
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const unreadNotifications = response?.data?.data?.filter(
          (notification: any) => !notification.is_read && notification.is_new
        )
        setNotificationCount(unreadNotifications?.length)
      },
    }
  )

  const handleViewNotifications = async () => {
    setNotificationCount(0)
    if (response.data && response.data.data && response.data.data.length > 0) {
      const createdAt = response.data.data[0].created_at
      const time = new Date(createdAt).toLocaleTimeString()
      setNotificationCreatedAt(time)
    }
    if (response.data && response.data.data && response.data.data.length > 0) {
      const notificationIds = response.data.data.map((notification: any) => notification.id)
      await updatedNewNotification(notificationIds)
      refetchNotifications()
    }
  }

  return (
    <>
      <div className='aside-user d-flex align-items-sm-center justify-content-center'>
        {loginData?.data?.user_type === 'SUPER_ADMIN' ? (
          ''
        ) : (
          <>
            <button
              type='button'
              className='btn my-5 btn-sm btn-icon btn-color-primary btn-active-light-primary position-relative'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={handleViewNotifications}
            >
              <KTSVG path='/media/icons/duotune/general/gen073.svg' className='svg-icon-2' />
              {notificationCount > 0 && (
                <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                  {notificationCount}
                </span>
              )}
            </button>
            <NotificationDropdown created_at={notificationCreatedAt} />
          </>
        )}

        <div
          className='menu-item'
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <a href='#' className='menu-link py-2 px-0'>
            <span className='menu-title d-inline-block'>
              <span className='fs-8 rounded  px-3 py-3 translate-middle-y top-50 end-0'>
                <img
                  className='w-30px h-30px rounded-1'
                  src={currentLanguage?.flag}
                  alt='metronic'
                />
              </span>
            </span>
          </a>

          <div
            className='menu-sub menu-sub-dropdown w-175px py-4 my-4'
            data-kt-menu='true'
            data-popper-placement='bottom-start'
          >
            {languages.map((l) => (
              <div
                className='menu-item px-3'
                key={l.lang}
                onClick={() => {
                  setLanguage(l.lang)
                }}
              >
                <a
                  href='#'
                  className={clsx('menu-link d-flex px-5', {
                    active: l.lang === currentLanguage?.lang,
                  })}
                >
                  <span className='symbol symbol-20px me-4'>
                    <img className='rounded-1' src={l.flag} alt='metronic' />
                  </span>
                  {l.name}
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className='aside-user-info'>
          <div className='mx-5'>
            <a
              href='#'
              className='btn btn-icon btn-sm btn-active-color-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-start'
              data-kt-menu-overflow='false'
            >
              <div className='user_profile_icon'>
                {/* {loginData?.data?.user_type === 'SUPER_ADMIN' ? (
                  <>
                    <img src={toAbsoluteUrl('/media/landing_asset/admin_profile_pic.png')} alt='' />
                    <HeaderUserMenu />
                  </>
                ) : ( */}
                <>
                  {previewUrl || image ? (
                    <img
                      className='image-input-wrapper'
                      src={previewUrl || image}
                      alt='Preview'
                      key={previewKey}
                    />
                  ) : (
                    <div className='image-input-wrapper'>
                      <img
                        src={toAbsoluteUrl('/media/landing_asset/profile.jpg')}
                        alt='user_profile'
                      />
                    </div>
                  )}
                  <HeaderUserMenu />
                </>
                {/* )} */}
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export {AsideToolbar}
