/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'

import {Salary} from '../../core/_models'

type Props = {
  user: Salary
}

const ThroughPaySlip: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>{user?.through_pay_slip}</span>
      </div>
    </div>
  )
}

export {ThroughPaySlip}
