import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PatientBillingListHeader} from './components/header/PatientBillingListHeader'
import {PatientBillingTable} from './table/PatientBillingTable'
import {PatientBillingEditModal} from './Patient-billing-edit-modal/PatientBillingEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const PatientBillingList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard className='shadow'>
        <PatientBillingListHeader />
        <PatientBillingTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <PatientBillingEditModal />}
    </>
  )
}

const PatientBillingListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PatientBillingList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PatientBillingListWrapper}
