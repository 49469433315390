import {FC, useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialShift, ShiftModel} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {ShiftListLoading} from '../components/loading/ShiftListLoading'
import {createShift, updateShift} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import moment from 'moment'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: ShiftModel
}
type DayOfWeek = {
  day: string
  value: string
}

const editUserSchema = Yup.object().shape({
  type: Yup.string()
    .test(
      'noLeadingSpaces',
      'Invalid Input, Please avoid leading spaces at the beginning of the field',
      (value) => {
        return !value || value.trimStart() === value
      }
    )
    .matches(/^[A-Za-z\s]+$/, 'Number not allowed')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Shift Name is required'),
  start_time: Yup.string().required('Start Time is required'),
  end_time: Yup.string().required('End Time is required'),
  days_off: Yup.array().min(1, 'At least one day off must be selected'),
  // days_off: Yup.string().min(1, 'At least one day off must be selected'),
  working_hours: Yup.string()
    .matches(/^\d{1,2}(\.\d+)?$/, 'Must have 1 to 2 digits')
    .required('Working Hours is required'),
})

const ShiftEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {selectedHospitalId} = useContext(loginContext)

  const [userForEdit] = useState<ShiftModel>({
    ...user,
    id: user.id || initialShift.id,
    type: user.type || initialShift.type,
    start_time: user.start_time || initialShift.start_time,
    end_time: user.end_time || initialShift.end_time,
    working_hours: user.working_hours || initialShift.working_hours,
    days_off: user.days_off || initialShift.days_off,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const daysOfWeek: DayOfWeek[] = [
    {value: 'SUNDAY', day: intl.formatMessage({id: 'GENERAL.SUNDAY'})},
    {value: 'MONDAY', day: intl.formatMessage({id: 'GENERAL.MONDAY'})},
    {value: 'TUESDAY', day: intl.formatMessage({id: 'GENERAL.TUESDAY'})},
    {value: 'WEDNESDAY', day: intl.formatMessage({id: 'GENERAL.WEDNESDAY'})},
    {value: 'THURSDAY', day: intl.formatMessage({id: 'GENERAL.THURSDAY'})},
    {value: 'FRIDAY', day: intl.formatMessage({id: 'GENERAL.FRIDAY'})},
    {value: 'SATURDAY', day: intl.formatMessage({id: 'GENERAL.SATURDAY'})},
  ]

  const formik = useFormik({
    initialValues: {
      ...userForEdit,
      days_off: user.days_off || [],
    },

    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (!values.start_time) {
        formik.setFieldError('start_time', 'Start Time is required')
      }

      if (!values.end_time) {
        formik.setFieldError('end_time', 'End Time is required')
      }
      if (formik.errors.start_time || formik.errors.end_time) {
        setSubmitting(false)
        return
      }

      // // Convert start_time and end_time to moment objects
      // const startTimeMoment = moment(values.start_time, 'HH:mm')
      // const endTimeMoment = moment(values.end_time, 'HH:mm')

      // // Check if end_time is greater than start_time
      // if (!endTimeMoment.isAfter(startTimeMoment)) {
      //   formik.setFieldError('end_time', 'End Time must be greater than Start Time')
      //   setSubmitting(false)
      //   return
      // }

      values['start_time'] = moment(values?.start_time, 'HH:mm').format('HH:mm')
      values['end_time'] = moment(values?.end_time, 'HH:mm').format('HH:mm')
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          delete values.hospital_id
          await updateShift({...values, id: values?.id, days_off: [values?.days_off]}).then(
            (res: any) => {
              toast.success(res?.data?.message)
            }
          )
        } else {
          await createShift({...values, hospital_id: Number(selectedHospitalId)}).then(
            (res: any) => {
              toast.success(res?.data?.message)
            }
          )
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(false)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form3' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.SHIFT.TYPE'})}
              </label>

              <input
                placeholder={intl.formatMessage({id: 'GENERAL.SHIFT.TYPE'})}
                {...formik.getFieldProps('type')}
                type='text'
                name='type'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.type && formik.errors.type},
                  {
                    'is-valid': formik.touched.type && !formik.errors.type,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/\d/g, '')
                }}
              />
              {formik.touched.type && formik.errors.type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.type}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.WORKING.HOURS'})}
              </label>

              <input
                {...formik.getFieldProps('working_hours')}
                placeholder={intl.formatMessage({id: 'GENERAL.WORKING.HOURS'})}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.working_hours && formik.errors.working_hours},
                  {
                    'is-valid': formik.touched.working_hours && !formik.errors.working_hours,
                  }
                )}
                type='number'
                name='working_hours'
                autoComplete='off'
                onKeyPress={(e) => {
                  if (e.key === '-') {
                    e.preventDefault()
                  }
                }}
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.working_hours && formik.errors.working_hours && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.working_hours}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='fv-row mb-7 col-12 col-sm-6 ps-0'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.START.TIME'})}
              </label>

              {/* <input
                {...formik.getFieldProps('start_time')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.start_time && formik.errors.start_time},
                  // {
                  // 'is-valid': formik.touched.start_time && !formik.errors.start_time,
                  // }
                )}
                type='time'
                name='start_time'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              /> */}
              <TimePicker
                className={clsx(
                  'shift-timepicker form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.start_time && formik.errors.start_time},
                  {'is-valid': formik.touched.start_time && !formik.errors.start_time}
                )}
                name='start_time'
                value={
                  formik.values.start_time ? moment(formik.values.start_time, 'HH:mm') : undefined
                }
                showSecond={false}
                minuteStep={15}
                format='HH:mm'
                placeholder={intl.formatMessage({id: 'GENERAL.SELECT.TIME'})}
                onChange={(start_time) => {
                  formik.setFieldValue('start_time', start_time)
                }}
                disabled={formik.isSubmitting || isUserLoading}
                inputReadOnly={true}
              />

              {formik.touched.start_time && formik.errors.start_time && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.start_time}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7 col-12 col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'GENERAL.END.TIME'})}
              </label>
              {/* <input
                {...formik.getFieldProps('end_time')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // {'is-invalid': formik.touched.end_time && formik.errors.end_time},
                  // {
                  //   'is-valid': formik.touched.end_time && !formik.errors.end_time,
                  // }
                )}
                type='time'
                name='end_time'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              /> */}
              <TimePicker
                className={clsx(
                  'shift-timepicker form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.end_time && formik.errors.end_time},
                  {'is-valid': formik.touched.end_time && !formik.errors.end_time}
                )}
                name='end_time'
                value={formik.values.end_time ? moment(formik.values.end_time, 'HH:mm') : undefined}
                showSecond={false}
                minuteStep={15}
                format='HH:mm'
                placeholder={intl.formatMessage({id: 'GENERAL.SELECT.TIME'})}
                onChange={(end_time) => {
                  formik.setFieldValue('end_time', end_time)
                }}
                disabled={formik.isSubmitting || isUserLoading}
                inputReadOnly={true}
              />

              {formik.touched.end_time && formik.errors.end_time && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.end_time}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2 '>
              {intl.formatMessage({id: 'SHIFT.DAYS.OFF'})}
            </label>
            <br />
            <div className='form-check-parent d-flex flex-wrap mt-5'>
              {daysOfWeek.map((day) => (
                <div className='form-check' style={{flex: '0 0 25%'}} key={day.value}>
                  <input
                    {...formik.getFieldProps('days_off')}
                    className='form-check-input fw-bold fs-6 mb-2 text-black'
                    type='checkbox'
                    name='days_off'
                    value={day.value}
                    checked={formik.values.days_off.includes(day.value)}
                    onChange={(e) => {
                      const updatedDaysOff = e.target.checked
                        ? [...formik.values.days_off, e.target.value]
                        : formik.values.days_off.filter((day) => day !== e.target.value)

                      formik.setFieldValue('days_off', updatedDaysOff)
                    }}
                  />
                  <label className='form-check-label fs-6 mb-2 text-dark'>{day.day}</label>
                </div>
              ))}
              {formik.touched.days_off && formik.errors.days_off && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.days_off}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {formik.isSubmitting || isUserLoading ? <ShiftListLoading /> : null}
    </>
  )
}

export {ShiftEditModalForm}
