import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_jmh/layout/core'
import {OperationsListWrapper} from './Operations-list/OperationsList'

const OperationTheater = () => (
  <>
    <div>
      <OperationsListWrapper />
    </div>
  </>
)

const OperationTheaterWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'OPERATION_THEATRE'})}</PageTitle>
      <OperationTheater />
    </>
  )
}

export {OperationTheaterWrapper}
