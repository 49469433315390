/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Patient} from '../../core/_models'

type Props = {
  patient: Patient
}

const PatientStatusCell: FC<Props> = ({patient}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {patient?.status === 'ACTIVE' && (
          <span className='badge badge-light-success'>{patient?.status}</span>
        )}
        {patient?.status === 'INACTIVE' && (
          <span className='badge badge-light-warning'>{patient?.status}</span>
        )}
      </div>
    </div>
  )
}

export {PatientStatusCell}
