import {useQuery} from 'react-query'
import {PurchaseInvEditModalForm} from './PurchaseInvEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_jmh/helpers'
import {useListView} from '../core/ListViewProvider'
import {getPurchaseInventoryById} from '../core/_requests'
import moment from 'moment'

const PurchaseInvEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getPurchaseInventoryById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )
  if (user && user['purchase_date']) {
    const formatePurchaseDate = moment(user.purchase_date).format('DD-MM-YYYY')
    user['purchase_date'] = formatePurchaseDate
  }

  if (!itemIdForUpdate) {
    return (
      <PurchaseInvEditModalForm
        isUserLoading={isLoading}
        user={{
          id: undefined,
        }}
      />
    )
  }

  if (!isLoading && !error && user) {
    return <PurchaseInvEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export {PurchaseInvEditModalFormWrapper}
