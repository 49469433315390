/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import {FormattedMessage} from 'react-intl'

interface CustomToolbarProps {
  onViewChange: (view: string) => void
  label: string
}
class CustomToolbar extends React.Component<CustomToolbarProps> {
  render() {
    return (
      <>
        <div className='rbc-toolbar text-gray-800 fw-bold'>
          <div className='rbc-btn-group appointments-btn-group'>
            <button
              className='text-gray-700 fw-bold btn'
              onClick={() => this.props.onViewChange('PREV')}
              type='button'
            >
              «
            </button>
            <button
              className='text-gray-700 fw-bold btn '
              onClick={() => this.props.onViewChange('NEXT_MONTH')}
              type='button'
            >
              »
            </button>
            <button
              className='text-gray-700 fw-bold btn'
              onClick={() => this.props.onViewChange('TODAY')}
              type='button'
            >
              <FormattedMessage id='GENERAL.TODAY' />
            </button>
          </div>
          <div className='rbc-toolbar-label'>{this.props.label}</div>
          <div className='rbc-btn-group appointments-btn-group'>
            <button
              className='text-gray-700 btn fw-bold'
              onClick={() => this.props.onViewChange('month')}
              type='button'
            >
              <FormattedMessage id='GENERAL.MONTH' />
            </button>
            <button
              className='text-gray-700 btn fw-bold'
              onClick={() => this.props.onViewChange('day')}
              type='button'
            >
              <FormattedMessage id='GENERAL.DAY' />
            </button>

            <button
              className='text-gray-700 fw-bold btn'
              onClick={() => this.props.onViewChange('week')}
              type='button'
            >
              <FormattedMessage id='GENERAL.WEEK' />
            </button>
          </div>
        </div>
      </>
    )
  }
}

export {CustomToolbar}
