import {FC, useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_jmh/helpers'
import {initialUser, PatientVital} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {PatientVitalListLoading} from '../components/loading/PatientVitalListLoading'
import {createPatientVital, updatePatientVital} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import {loginContext} from '../../../../context/context'
import {useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {useIntl} from 'react-intl'

type Props = {
  isUserLoading: boolean
  user: PatientVital
}
let editUserSchema

const PatientVitalEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {selectedPatientId, selectedAppointment} = useContext(loginContext)
  const {id} = useParams()
  const [selDateTime, setSelDateTime] = useState<Date | null>(null)

  if (itemIdForUpdate) {
    editUserSchema = Yup.object().shape({
      blood_pressure: Yup.string()
        .nullable()
        .matches(/^[0-9]+\/[0-9]+$/, 'Blood pressure value must be in "systolic/diastolic" format')
        .test('blood-pressure-range', 'Range should be from 90/60 to 180/120', (value) => {
          if (!value) return true
          const [systolic, diastolic] = value.split('/')
          return (
            Number(systolic) >= 90 &&
            Number(systolic) <= 180 &&
            Number(diastolic) >= 60 &&
            Number(diastolic) <= 120
          )
        })
        .required('Blood pressure is required'),
      Respiratory_rate: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(5, 'Respiratory rate should be greater than or equal to 5')
        .max(35, 'Respiratory rate should be less than or equal to 35')
        .required('Respiratory rate is required'),
      pulse: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(50, 'Pulse should be greater than or equal to 50')
        .max(220, 'Pulse should be less than or equal to 220')
        .required('Pulse is required'),
      temprature: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(90, 'Temprature should be greater than or equal to 90')
        .max(110, 'Temprature should be less than or equal to 110')
        .required('Temprature is required'),
      height: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(50, 'Height should be greater than or equal to 50')
        .max(300, 'Height should be less than or equal to 300'),
      weight: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(0, 'Weight should be greater than or equal to 0')
        .max(400, 'Weight should be less than or equal to 400'),
      // date_time: Yup.date().required('Required field'),
    })
  } else {
    editUserSchema = Yup.object().shape({
      blood_pressure: Yup.string()
        .nullable()
        .matches(/^[0-9]+\/[0-9]+$/, 'Blood pressure value must be in "systolic/diastolic" format')
        .test('blood-pressure-range', 'Range should be from 90/60 to 180/120', (value) => {
          if (!value) return true
          const [systolic, diastolic] = value.split('/')
          return (
            Number(systolic) >= 90 &&
            Number(systolic) <= 180 &&
            Number(diastolic) >= 60 &&
            Number(diastolic) <= 120
          )
        })
        .required('Blood pressure is required'),
      Respiratory_rate: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(5, 'Respiratory rate should be greater than or equal to 5')
        .max(35, 'Respiratory rate should be less than or equal to 35')
        .required('Respiratory rate is required'),
      pulse: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(50, 'Pulse should be greater than or equal to 50')
        .max(220, 'Pulse should be less than or equal to 220')
        .required('Pulse is required'),
      temprature: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(90, 'Temprature should be greater than or equal to 90')
        .max(110, 'Temprature should be less than or equal to 110')
        .required('Temprature is required'),
      height: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(50, 'Height should be greater than or equal to 50')
        .max(300, 'Height should be less than or equal to 300'),
      weight: Yup.number()
        .nullable()
        // .typeError('Invalid format')
        .min(0, 'Weight should be greater than or equal to 0')
        .max(400, 'Weight should be less than or equal to 400'),
      date_time: Yup.date().required('Date & Time is required'),
    })
  }

  const [userForEdit] = useState<PatientVital>({
    ...user,
    blood_pressure: user.blood_pressure || initialUser.blood_pressure,
    height: user.height || initialUser.height,
    weight: user.weight || initialUser.weight,
    temprature: user.temprature || initialUser.temprature,
    Respiratory_rate: user.Respiratory_rate || initialUser.Respiratory_rate,
    pulse: user.pulse || initialUser.pulse,
    date_time: user.date_time || initialUser.date_time,
    // const startDate = moment(user.date_time, 'YYYY-MM-DD H:mm').format('DD-MM-YYYY H:mm')
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const dateTime = moment(values['date_time'], 'DD-MM-YYYY HH:mm:ss').format(
        'DD-MM-YYYY HH:mm:ss'
      )
      values['date_time'] = dateTime

      try {
        if (isNotEmpty(values.id)) {
          if (!values.height) {
            values.height = null
          }
          if (!values.weight) {
            values.weight = null
          }

          await updatePatientVital(values).then((res: any) => {
            toast.success(res?.data?.message)
          })
        } else {
          await createPatientVital({
            ...values,
            patient_id: Number(selectedPatientId ? selectedPatientId : id),
            appointment_id: selectedAppointment
              ? selectedAppointment?.value === 'all'
                ? ''
                : Number(selectedAppointment?.value)
              : '',
          }).then((res: any) => {
            toast.success(res?.data?.message)
          })
        }
      } catch (ex: any) {
        if (ex.response.data.message) {
          toast.error(ex.response.data.message)
        }
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form3' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-6'>
              <div className='fv-row mb-7'>
                <label className={`required fw-bold fs-6 mb-2`}>
                  {intl.formatMessage({id: 'GENERAL.DATE.TIME'})}
                </label>
                <div className='position-relative'>
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    {...formik.getFieldProps('date_time')}
                    dropdownMode='select'
                    className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer'
                    dateFormat='dd-MM-yyyy h:mm'
                    name='date_time'
                    timeInputLabel='Time:'
                    // minDate={past_date_disable}
                    selected={selDateTime}
                    onChange={(date) => {
                      formik.setFieldValue('date_time', date)
                      setSelDateTime(date)
                    }}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isUserLoading}
                    showTimeInput
                    shouldCloseOnSelect={false}
                    placeholderText={intl.formatMessage({id: 'GENERAL.DATE.TIME'})}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                  <span
                    className='DatePicker_icon cursor-pointer'
                    onClick={() => document.getElementsByName('date_time')[0].focus()}
                  >
                    <i className='fa fa-calendar'></i>
                  </span>
                </div>
                {formik.touched.date_time && formik.errors.date_time && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.date_time}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='fv-row mb-7 col-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_VITAL.BLOOD.PRESSURE(MMHG)'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.BLOOD.PRESSURE'})}
                {...formik.getFieldProps('blood_pressure')}
                type='text'
                name='blood_pressure'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
              />
              {formik.touched.blood_pressure && formik.errors.blood_pressure && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.blood_pressure}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_VITAL.TEMPERATURE(F)'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.TEMPERATURE'})}
                {...formik.getFieldProps('temprature')}
                type='number'
                name='temprature'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault() // Prevent the 'e' character from being entered
                  }
                }}
              />
              {formik.touched.temprature && formik.errors.temprature && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.temprature}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='fv-row mb-7 col-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_VITAL.RESPIRATORY.RATE(RPM)'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.RESPIRATORY.RATE'})}
                {...formik.getFieldProps('Respiratory_rate')}
                type='number'
                name='Respiratory_rate'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault() // Prevent the 'e' character from being entered
                  }
                }}
              />
              {formik.touched.Respiratory_rate && formik.errors.Respiratory_rate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.Respiratory_rate}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_VITAL.PULSE(BPM)'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.PULSE'})}
                {...formik.getFieldProps('pulse')}
                type='number'
                name='pulse'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault() // Prevent the 'e' character from being entered
                  }
                }}
              />
              {formik.touched.pulse && formik.errors.pulse && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.pulse}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='fv-row mb-7 col-6'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_VITAL.HEIGHT(CM)'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.HEIGHT'})}
                {...formik.getFieldProps('height')}
                type='number'
                name='height'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault() // Prevent the 'e' character from being entered
                  }
                }}
              />
              {formik.touched.height && formik.errors.height && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.height}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7 col-6'>
              <label className='fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'PATIENT_VITAL.WEIGHT(KG)'})}
              </label>
              <input
                placeholder={intl.formatMessage({id: 'GENERAL.WEIGHT'})}
                {...formik.getFieldProps('weight')}
                type='number'
                name='weight'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'e' || event.key === 'E') {
                    event.preventDefault() // Prevent the 'e' character from being entered
                  }
                }}
              />
              {formik.touched.weight && formik.errors.weight && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.weight}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={
              isUserLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'GENERAL.PLEASE.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <PatientVitalListLoading />}
    </>
  )
}

export {PatientVitalEditModalForm}
