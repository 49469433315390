import {useContext} from 'react'
import {KTSVG} from '../../../../../../_jmh/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useIntl} from 'react-intl'
import {loginContext} from '../../../../../context/context'

const MedicationListToolbar = () => {
  const intl = useIntl()
  const {selectedAppointment} = useContext(loginContext)
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className='btn btn-primary'
        onClick={openAddUserModal}
        disabled={selectedAppointment?.value === 'all'}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'MEDICATION.ADD.MEDICATION'})}
      </button>
    </div>
  )
}

export {MedicationListToolbar}
