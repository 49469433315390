import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {InventoryCategoryListHeader} from './components/header/InventoryCategoryListHeader'
import {InventoryCategoryTable} from './table/InventoryCategoryTable'
import {InventoryCategoryEditModal} from './InventoryCategory-edit-modal/InventoryCategoryEditModal'
import {KTCard} from '../../../../_jmh/helpers'

const InventoryCategoryList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <InventoryCategoryListHeader />
        <InventoryCategoryTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <InventoryCategoryEditModal />}
    </>
  )
}

const InventoryCategoryListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <InventoryCategoryList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {InventoryCategoryListWrapper}
