import {useContext, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {loginContext} from '../../context/context'
import {KTCardBody, KTSVG} from '../../../_jmh/helpers'
import {getPatientMedicalNotes} from './core/_request'
import moment from 'moment'
import ReactPaginate from 'react-paginate'

const OpMedicalNotes = ({selectedAppointment}: any) => {
  const intl = useIntl()
  const {loginData} = useContext(loginContext)
  const [medicalNotesData, setMedicalNotesData] = useState<any>(null)
  const limit = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const pageCount = Math.max(Math.ceil(totalCount / limit), 1)
  const [searchTerm, setSearchTerm] = useState('')
  const colors = ['primary', 'success', 'danger', 'warning', 'info']

  useEffect(() => {
    fetchMedicationData()
  }, [currentPage, searchTerm, selectedAppointment])

  const fetchMedicationData = () => {
    getPatientMedicalNotes(
      searchTerm,
      loginData?.data?.patient_id,
      loginData?.data?.hospital_id,
      selectedAppointment
        ? selectedAppointment?.value === 'all'
          ? ''
          : selectedAppointment?.value
        : ''
    ).then((res: any) => {
      setMedicalNotesData(res?.data)
      setTotalCount(res?.pagination?.meta?.totalItems)
    })
  }

  const groupedData = useMemo(() => {
    const grouped: any = {}

    medicalNotesData &&
      medicalNotesData.forEach((item: any) => {
        const year = moment(item.assigned_date).format('YYYY')
        const month = moment(item.assigned_date).format('MMM')
        if (!grouped[year]) {
          grouped[year] = {}
        }
        if (!grouped[year][month]) {
          grouped[year][month] = []
        }
        grouped[year][month].push(item)
      })

    return grouped
  }, [medicalNotesData])

  const sortedYears = Object.keys(groupedData).sort((a: any, b: any) => b - a)

  const handlePageClick = (selectedPage: any) => {
    setCurrentPage(selectedPage?.selected + 1)
  }

  return (
    <>
      <div className='op_inner_wrapper py-5'>
        <KTCardBody className='py-0 px-0'>
          <div className='d-flex justify-content-between align-items-center mb-25'>
            <h2 className='op_head_title m-0'>Your Medical Notes</h2>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className='ps-10 px-1'>
            <div
              className={`row border border-dashed border-gray-300 rounded min-w-800px px-1 py-2 mb-3 bg-light`}
            >
              <div className='col-2'>
                <div className='fs-6 text-muted'>Hospital name</div>
              </div>
              <div className='col-8'>
                <div className='fs-6 text-muted'>Notes</div>
              </div>
              <div className='col-2'>
                <div className='fs-6 text-muted'>Date</div>
              </div>
            </div>
          </div>
          <div className='timeline'>
            {sortedYears.length > 0 ? (
              <>
                {sortedYears.map((year, index: any) => (
                  <div key={`year-${year}`} className='timeline-item'>
                    <div
                      className='timeline-line w-20px'
                      style={{marginTop: '18px', left: '-1px'}}
                    ></div>
                    <div className='timeline-badge'>
                      <i
                        className={`fa fa-genderless text-${colors[index % colors.length]} fs-1`}
                      ></i>
                    </div>
                    <div className='timeline-content'>
                      <div className='mb-5'>
                        <div className='fs-5 fw-semibold mb-2'>
                          <h4>{year}</h4>
                          {Object.keys(groupedData[year])
                            .sort((a, b) => moment(b, 'MMM').diff(moment(a, 'MMM'))) // Sort months in descending order
                            .map((month) => (
                              <div key={`month-${year}-${month}`} className='mb-4'>
                                <h5>{month}</h5>
                                {groupedData[year][month].map((item: any, i: any) => (
                                  <div key={`row-${i}`} className='row'>
                                    <div className=''>
                                      <div
                                        className={`row border border-dashed border-gray-300 rounded min-w-800px px-1 py-2 mb-3 bg-light-${
                                          colors[index % colors.length]
                                        }`}
                                      >
                                        <div className='col-2'>
                                          <div className='d-flex mt-5'>
                                            <span
                                              className={`fs-5 text-${
                                                colors[index % colors.length]
                                              } fw-semibold w-200px min-w-100px`}
                                            >
                                              {item?.patient_id?.hospital?.name}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='col-8 medicalNote_desc'>
                                          <div className='fs-4 fw-semibold'>{item.title}</div>
                                          <div className='pe-2'>
                                            <span className='fs-7'>{item.description}</span>
                                          </div>
                                        </div>
                                        <div className='col-2'>
                                          <div className='d-flex mt-5'>
                                            <span
                                              className={`fs-5 text-${
                                                colors[index % colors.length]
                                              } fw-semibold w-200px min-w-100px`}
                                            >
                                              {moment(item.date).format('DD-MM-YYYY')}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className='d-flex fs-5 text-dark fw-bold bg-light p-3 text-center w-100 align-content-center justify-content-center'>
                {intl.formatMessage({id: 'GENERAL.NO.MATCHING.RECORDS.FOUND'})}
              </div>
            )}
          </div>
          {medicalNotesData?.length >= 1 && (
            <nav className='op_pagination'>
              <ReactPaginate
                breakLabel='...'
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName='pagination'
                activeClassName='active'
                previousLabel='Previous'
                nextLabel='Next'
              />
            </nav>
          )}
        </KTCardBody>
      </div>
    </>
  )
}

export default OpMedicalNotes
