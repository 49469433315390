/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Patient} from '../../core/_models'

type Props = {
  patient: Patient
}

const PatientContactNumberCell: FC<Props> = ({patient}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>{patient?.contact_number}</span>
      </div>
    </div>
  )
}

export {PatientContactNumberCell}
