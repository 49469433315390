import {KTCard} from '../../../../_jmh/helpers'
import {StaffListHeader} from './components/header/StaffListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {StaffEditModal} from './staff-edit-modal/StaffEditModal'
import {StaffTable} from './table/StaffTable'

const StaffsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard className='shadow'>
        <StaffListHeader />
        <StaffTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <StaffEditModal />}
    </>
  )
}

const StaffListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StaffsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StaffListWrapper}
