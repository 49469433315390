/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useContext} from 'react'
import {initialQueryState, useDebounce} from '../../../../../../_jmh/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {loginContext} from '../../../../../context/context'

const UsersListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const {setSelectedSearchTerm} = useContext(loginContext)

  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({search: debouncedSearchTerm, ...initialQueryState})
    }
    setSelectedSearchTerm(searchTerm)
  }, [debouncedSearchTerm])

  return (
    <div className='card-title'>
      {/* <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search user'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
    </div>
  )
}

export {UsersListSearchComponent}
