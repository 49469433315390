import {useContext, useEffect, useRef, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_jmh/helpers'
import {loginContext} from '../../context/context'
import {useIntl} from 'react-intl'
import {useAuth} from '../../modules/auth'
import {ThemeModeSwitcher} from '../../../_jmh/partials'
import PatientForgotPasswordModal from './modals/PatientForgotPasswordModal'
import PatientChangePasswordModal from './modals/PatientChangePasswordModal'
import PatientLoginModal from './modals/PatientLoginModal'
import PatientVerifyOtpModal from './modals/PatientVerifyOtpModal'
import PatientRegistrationModal from './modals/PatientRegistrationModal'

type RegistrationRef = {
  resetForm: () => void
}

const LandingHeader = () => {
  const [isFixed, setIsFixed] = useState(false)
  const location = useLocation()
  const {loginData} = useContext(loginContext)
  const intl = useIntl()
  const {logout, currentUser} = useAuth()
  const navigate = useNavigate()
  const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false)
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const [showPatientLoginModal, setShowPatientLoginModal] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const registrationFormRef = useRef<RegistrationRef | null>(null)
  const [emailValue, setEmailValue] = useState('')

  const handleAppointmentClick = () => {
    if (currentUser) {
      navigate('/patient-booking')
    } else {
      setShowPatientLoginModal(true)
    }
  }

  const scrollToSection = () => {
    const hash = location.hash
    if (hash) {
      const targetElement = document.querySelector(hash)
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }

  useEffect(() => {
    scrollToSection()
  }, [location])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleRegistrationSuccess = (email: string) => {
    setEmailValue(email)
    setShowRegistrationModal(false)
    setShowVerifyOtpModal(true)
  }

  const closeVerifyOtpModal = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.resetForm()
    }
    setShowVerifyOtpModal(false)
  }

  const closeRegistrationModal = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.resetForm()
    }
    setShowRegistrationModal(false)
  }

  const handleLoginLinkClick = () => {
    setShowRegistrationModal(false)
    setShowPatientLoginModal(true)
  }

  const handleRegisterLinkClick = () => {
    setShowPatientLoginModal(false)
    setShowRegistrationModal(true)
  }

  const handleForgotPasswordLinkClick = () => {
    setShowPatientLoginModal(false)
    setShowForgotPasswordModal(true)
  }

  const handlePasswordChangeModal = () => {
    setShowPatientLoginModal(false)
    setShowChangePasswordModal(true)
  }

  return (
    <>
      <div className={`landing_header ${isFixed ? 'fixed' : ''}`}>
        <div className='landing_container'>
          <div className='d-flex align-items-center justify-content-between'>
            <nav>
              <div className='siteLogo'>
                <div className='landing_main_logo'>
                  <Link to='/'>
                    <img
                      src={toAbsoluteUrl('/media/landing_asset/landing_main_logo.png')}
                      alt='Logo'
                    />
                    <h6> {intl.formatMessage({id: 'GENERAL.LOGO'})}</h6>
                  </Link>
                </div>
              </div>
            </nav>

            <nav className='landing-nav-menu d-flex align-items-center'>
              {loginData ? (
                <>
                  <ul className='list-unstyled m-0 d-flex'>
                    <li>
                      <Link to='/patient-appointment'>Appointments</Link>
                    </li>
                    <li>
                      <Link to='/patient-details'>Medical Details</Link>
                    </li>
                  </ul>
                </>
              ) : (
                <ul className='list-unstyled m-0 d-flex'>
                  <li>
                    <Link to='/#about'>About</Link>
                  </li>
                  <li>
                    <Link to='/#facility'>Facilities</Link>
                  </li>
                  <li>
                    <Link to='/#ask-doctors'>Ask doctors</Link>
                  </li>
                  <li>
                    <Link to='/#clinics'>Clinics</Link>
                  </li>
                  <li>
                    <Link to='/contact-us'>Contact</Link>
                  </li>
                  <li className='nav-item dropdown'>
                    <Link
                      className='nav-link'
                      to='#'
                      id='loginDropdown'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Login
                    </Link>
                    <div className='dropdown-menu login-dropdown' aria-labelledby='loginDropdown'>
                      <Link className='dropdown-item' to='/login'>
                        <img
                          src={toAbsoluteUrl('/media/landing_asset/hospital_login.png')}
                          alt='Logo'
                        />
                        Hospital Login
                      </Link>
                      <a
                        href='javascript:void(0)'
                        className='dropdown-item'
                        onClick={handleAppointmentClick}
                      >
                        <img
                          src={toAbsoluteUrl('/media/landing_asset/patinent_login.png')}
                          alt='Logo'
                        />
                        Patient Login
                      </a>
                    </div>
                  </li>
                </ul>
              )}

              <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon theme_switch_button_icon btn-active-icon-primary' />

              {/* <div className='dropdown'>
              <button
                className='landing_nav_setting'
                type='button'
                id='settingsDropdown'
                data-bs-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <KTSVG path='/media/landing_asset/Settings.svg' />
              </button>
              <div
                className='landing_setting_dropdown dropdown-menu'
                aria-labelledby='settingsDropdown'
              >
                <ThemeModeSwitcherSwitch onSwitchChange={handleSwitchChange} checked={isDarkMode} />
              </div>
            </div> */}
              {currentUser ? (
                loginData?.data?.user_type === 'PATIENT' && (
                  <div className='dropdown'>
                    <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
                      <div className='ProfileIcon'>
                        <button
                          className='landing_profile_btn'
                          type='button'
                          id='profileDropdown'
                          data-bs-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <div className='symbol symbol-50px'>
                            {loginData?.data?.personal_details?.image ? (
                              <img src={loginData?.data?.personal_details?.image} />
                            ) : (
                              <h6 className='user_profile_icon m-0'>
                                {loginData?.data?.full_name
                                  ? loginData?.data?.full_name
                                      .split(' ')
                                      .slice(0, 2)
                                      .map((name: any) => name.charAt(0))
                                      .join('')
                                      .toUpperCase()
                                  : ''}
                              </h6>
                            )}
                          </div>
                        </button>
                        <div className='dropdown-menu' aria-labelledby='profileDropdown'>
                          <div className='menu-item px-3'>
                            {loginData?.data?.user_type === 'SUPER_ADMIN' ? (
                              ''
                            ) : (
                              <>
                                <div className='menu-content d-flex align-items-center px-3'>
                                  <div className='d-flex flex-column'>
                                    <div className='fw-bolder d-flex align-items-center fs-5'>
                                      {loginData?.data?.full_name}
                                    </div>
                                    <div className='fw-bold text-muted  fs-7'>
                                      {loginData?.data?.email}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <>
                            <div className='menu-item px-5'>
                              <Link to='/profile' className='menu-link p-0'>
                                {intl.formatMessage({id: 'GENERAL.MY_PROFILE'})}
                              </Link>
                            </div>
                            <div className='separator my-2'></div>
                            <div className='menu-item px-5'>
                              <Link to='/patient-insurance' className='menu-link p-0'>
                                Your Insurance
                              </Link>
                            </div>
                            <div className='separator my-2'></div>

                            <div className='menu-item px-5'>
                              <Link to='/' onClick={logout} className='menu-link p-0'>
                                {intl.formatMessage({id: 'GENERAL.SIGN_OUT'})}
                              </Link>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <></>
              )}
            </nav>
          </div>
        </div>
      </div>

      {showRegistrationModal && (
        <PatientRegistrationModal
          show={showRegistrationModal}
          onClose={closeRegistrationModal}
          onSuccess={handleRegistrationSuccess}
          onLoginClick={handleLoginLinkClick}
          registrationFormRef={registrationFormRef}
        />
      )}

      {showVerifyOtpModal && (
        <PatientVerifyOtpModal
          show={showVerifyOtpModal}
          onClose={closeVerifyOtpModal}
          emailValue={emailValue}
        />
      )}

      {showPatientLoginModal && (
        <PatientLoginModal
          show={showPatientLoginModal}
          onClose={() => setShowPatientLoginModal(false)}
          onRegisterClick={handleRegisterLinkClick}
          onForgotPasswordClick={handleForgotPasswordLinkClick}
          onPasswordChangeClick={handlePasswordChangeModal}
        />
      )}

      {showChangePasswordModal && (
        <PatientChangePasswordModal
          show={showChangePasswordModal}
          onClose={() => setShowChangePasswordModal(false)}
          onSubmit={() => {
            setShowChangePasswordModal(false)
          }}
        />
      )}

      {showForgotPasswordModal && (
        <PatientForgotPasswordModal
          show={showForgotPasswordModal}
          onClose={() => setShowForgotPasswordModal(false)}
        />
      )}
    </>
  )
}

export default LandingHeader
