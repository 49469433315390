/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import axios from 'axios'
import {useIntl} from 'react-intl'
const API_URL = process.env.REACT_APP_API_URL
const GRAPH_URL = `${API_URL}/patient-billing`

type Props = {
  className: string
}

const StaffChartsWidget: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const [selectedTimeRange, setSelectedTimeRange] = useState<'year' | 'month' | 'week'>('year')
  const [chartData, setChartData] = useState([])
  const [xData, setXData] = useState<string[]>([])
  const [yData, setYData] = useState<number[]>([])
  const currentYear = new Date().getFullYear()

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    // Fetch the year-wise data by default
    handleTimeRangeChange('year')
  }, [])

  const refreshChart = (xData: string[], yData: number[]) => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, xData, yData))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart(xData, yData)
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, xData, yData])

  // Custom Code

  const handleTimeRangeChange = async (timeRange: 'year' | 'month' | 'week') => {
    setSelectedTimeRange(timeRange)

    try {
      // Prepare the parameters to pass to the API based on the selected time range
      const queryString = `?weekly=${timeRange === 'week'}&monthly=${
        timeRange === 'month'
      }&yearly=${timeRange === 'year'}`

      // Make API call using Axios based on the selected time range
      const response = await axios.get(`${GRAPH_URL}/graph${queryString}`)

      // Extract x-axis and y-axis data from the API response
      const {yearlyTotalPayableAmount, monthlyTotalPayableAmount, weeklyTotalPayableAmount} =
        response.data
      let newXData: any = []
      let newYData: any = []

      if (timeRange === 'year') {
        // Year-wise data

        const lastTenYears = Array.from({length: 10}, (_, index) => String(currentYear - index))

        const yearlyDataMap = new Map(
          yearlyTotalPayableAmount.map((item: any) => [
            String(item.year),
            item.total_payable_amount,
          ])
        )
        lastTenYears.sort((a: any, b: any) => a - b)

        newYData = lastTenYears.map((year) => yearlyDataMap.get(year) || null)

        newXData = lastTenYears
      } else if (timeRange === 'month') {
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth() + 1 // Month is 0-based index

        // Create an array of month names from January to the current month of the current year
        const monthNames = Array.from({length: currentMonth}, (_, index) =>
          new Date(currentYear, index).toLocaleString('default', {month: 'short'})
        )

        // Format the x-axis labels as abbreviated month names
        newXData = monthNames

        // Get the monthly total payable amount for the current year
        const yearlyDataMap = new Map(
          monthlyTotalPayableAmount.map((item: any) => [item.month, item.total_payable_amount])
        )

        // Generate the array for y-axis data, using the monthly total payable amount for each month
        newYData = monthNames.map((month) => {
          const monthNumber = monthNames.indexOf(month) + 1 // Month is 1-based index
          return yearlyDataMap.get(monthNumber) || null
        })
      } else {
        // Week-wise data
        const currentYear = new Date().getFullYear()
        const currentWeek = getWeekNumber(new Date())[1]
        const weeks = Array.from({length: currentWeek}, (_, index) => index + 1)

        const weeklyDataMap = new Map(
          weeklyTotalPayableAmount.map((item: any) => [item.week, item.total_payable_amount])
        )

        // Generate the array for x-axis data, showing start date and end date of each week
        newXData = weeks.map((week) => {
          const startDate = getStartDateOfWeek(currentYear, week)
          const endDate = getEndDateOfWeek(currentYear, week)
          return `${formatDate(startDate)} - ${formatDate(endDate)}`
        })

        // Generate the array for y-axis data, using the weekly total payable amount for each week
        newYData = weeks.map((week) => {
          return weeklyDataMap.get(week) || null
        })
      }

      // Update the chart data state and refresh the chart
      setChartData(response.data)
      // Update the chart data state and refresh the chart
      setXData(newXData)
      setYData(newYData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  function getStartDateOfWeek(year: number, week: number): Date {
    const firstDayOfYear = new Date(year, 0, 1)
    const daysToFirstDayOfWeek = (week - 1) * 7
    return new Date(firstDayOfYear.getTime() + daysToFirstDayOfWeek * 24 * 60 * 60 * 1000)
  }

  function getEndDateOfWeek(year: number, week: number): Date {
    const startDate = getStartDateOfWeek(year, week)
    const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000)
    return endDate
  }

  function formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    return `${day}/${month}`
  }

  function getWeekNumber(date: Date): [number, number] {
    const d = new Date(date)
    d.setHours(0, 0, 0, 0)
    d.setDate(d.getDate() + 4 - (d.getDay() || 7))
    const yearStart = new Date(d.getFullYear(), 0, 1)
    const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
    return [d.getFullYear(), weekNo]
  }

  function getChartOptions(height: number, xData: string[], yData: number[]): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-warning')
    const secondaryColor = getCSSVariableValue('--bs-gray-300')

    let xaxisLabel = ''
    if (selectedTimeRange === 'year') {
      xaxisLabel = `Years`
    } else if (selectedTimeRange === 'month') {
      xaxisLabel = `Months (${currentYear})`
    } else {
      xaxisLabel = `Weeks (${currentYear})`
    }

    return {
      series: [
        {
          name: 'Amount',
          data: yData,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: height,
        toolbar: {
          show: false,
        },
        animations: {
          // Add animations object to control chart animations
          enabled: false, // Set enabled to false to disable animations
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          borderRadius: 0,
          dataLabels: {
            position: 'top',
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, {dataPoint}) {
          return val !== null ? '₹' + val : ''
        },
        offsetY: -25,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: xData,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        title: {
          text: xaxisLabel,
          style: {
            color: labelColor,
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },

        title: {
          text: 'Amount (₹)',

          style: {
            color: labelColor,
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return '₹' + val
            //   + ' thousands'
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'STAFF_DASHBOARD.BILLING.AMOUNT'})}
          </span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className={`btn btn-sm btn-color-muted ${
              selectedTimeRange === 'year' ? 'active btn-active-primary active' : ''
            } px-4 me-1`}
            onClick={() => handleTimeRangeChange('year')}
          >
            {intl.formatMessage({id: 'GENERAL.YEAR'})}
          </a>

          <a
            className={`btn btn-sm btn-color-muted  ${
              selectedTimeRange === 'month' ? 'active  btn-active-primary' : ''
            } px-4 me-1`}
            onClick={() => handleTimeRangeChange('month')}
          >
            {intl.formatMessage({id: 'GENERAL.MONTH'})}
          </a>

          <a
            className={`btn btn-sm btn-color-muted ${
              selectedTimeRange === 'week' ? 'active btn-active-primary' : ''
            } px-4`}
            onClick={() => handleTimeRangeChange('week')}
          >
            {intl.formatMessage({id: 'GENERAL.WEEK'})}
          </a>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body graphc'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '400px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {StaffChartsWidget}
