import {AssetListToolbar} from './AssetListToolbar'
import {AssetListSearchComponent} from './AssetListSearchComponent'

const AssetListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <AssetListSearchComponent />
      <div className='card-toolbar'>
        <AssetListToolbar />
      </div>
    </div>
  )
}

export {AssetListHeader}
