import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {isNotEmpty, QUERIES} from '../../../../_jmh/helpers'
import {getSpeciality, getStaffByID} from '../StaffList/core/_requests'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {useContext, useEffect, useState} from 'react'
import {loginContext} from '../../../context/context'

function StaffView() {
  const intl = useIntl()
  const location = useLocation()
  const pathname = location.pathname
  const id: any = pathname.split('/').pop()
  const enabledQuery: boolean = isNotEmpty(id)
  const [speciality, setSpeciality] = useState([])
  const {selectedHospitalId} = useContext(loginContext)
  const {
    isLoading,
    data: StaffData,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${id}`,
    () => {
      return getStaffByID(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {},
    }
  )

  useEffect(() => {
    ;(async () => {
      const specialityData: any = await getSpeciality()
      const filterSpeciality = specialityData?.data?.filter(
        (item: any) => item?.hospital_id?.id == selectedHospitalId
      )
      setSpeciality(filterSpeciality)
    })()
  }, [])

  const filteredSpecialities = speciality.filter(
    (speciality: any) => StaffData && StaffData.speciality_ids?.includes(speciality.id)
  )

  const specialityNames = filteredSpecialities.map((speciality: any) => speciality.name)

  const handleBackClick = () => {
    window.history.back() // Redirect to the previous page
  }

  return (
    <>
      {StaffData && (
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title fw-bold fs-2'>
              {intl.formatMessage({id: 'STAFF.VIEW.STAFF.DETAILS'})}
            </h3>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </button>
            </div>
          </div>
          <div className='card-body card-scroll'>
            <div className='col-md-6 col-sm-12 text-start'>
              <div className='symbol symbol-100px  mb-7 text-center'>
                <img src={StaffData?.image} alt='' />
                <div className='fw-bold fs-4 mt-2'>{StaffData?.full_name}</div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.EMAIL'})}:
                </div>
                <div className='text-gray-600 fs-5'>{StaffData?.user?.email}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.GENDER'})}:
                </div>
                <div className='text-gray-600 fs-5'>{StaffData?.gender}</div>
                <div className='fw-bold fs-4 mt-5'>{intl.formatMessage({id: 'GENERAL.DOB'})}:</div>
                <div className='text-gray-600 fs-5'>
                  {moment(StaffData?.date_of_birth).format('DD-MM-YYYY')}
                </div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}:
                </div>
                <div className='text-gray-600 fs-5'>{StaffData?.permanent_address}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}:
                </div>
                <div className='text-gray-600 fs-5'>{StaffData?.contact_number}</div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'STAFF.JOB.TITLE'})}:
                </div>
                <div className='text-gray-600 fs-5'>{StaffData?.job_title}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.SHIFT.TYPE'})}:
                </div>
                <div className='text-gray-600 fs-5'>{StaffData?.shift?.type}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'STAFF.SPECIALITY'})}:
                </div>
                <div className='text-gray-600 fs-5'>{specialityNames.join(', ')}</div>
                <div className='fw-bold fs-4 mt-5'>{intl.formatMessage({id: 'GENERAL.ROLE'})}:</div>
                <div className='text-gray-600 fs-5'>{StaffData?.user?.role?.name}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default StaffView
