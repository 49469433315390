import moment from 'moment'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {isNotEmpty, QUERIES} from '../../../../_jmh/helpers'
import {getDoctorByID} from '../DoctorList/core/_requests'
import {useIntl} from 'react-intl'

function DoctorView() {
  const intl = useIntl()
  const location = useLocation()
  const pathname = location.pathname
  const id: any = pathname.split('/').pop()
  const enabledQuery: boolean = isNotEmpty(id)

  const {data: doctorData} = useQuery(
    `${QUERIES.USERS_LIST}-user-${id}`,
    () => {
      return getDoctorByID(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {},
    }
  )

  const dob = moment(doctorData?.date_of_birth).format('DD-MM-YYYY')

  const handleBackClick = () => {
    window.history.back()
  }

  return (
    <>
      {doctorData && (
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title fw-bold fs-2'>
              {intl.formatMessage({id: 'DOCTOR.VIEW.DOCTOR.DETAILS'})}
            </h3>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </button>
            </div>
          </div>
          <div className='card-body card-scroll'>
            <div className='col-md-6 col-sm-12 text-start'>
              <div className='symbol symbol-100px  mb-7 text-center'>
                <img src={doctorData?.image} alt='' />
                <div className='fw-bold fs-4 mt-2'>{doctorData?.full_name}</div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.EMAIL'})}:
                </div>
                <div className='text-gray-600 fs-5'>{doctorData?.user?.email}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.GENDER'})}:
                </div>
                <div className='text-gray-600 fs-5'>{doctorData?.gender}</div>
                <div className='fw-bold fs-4 mt-5'>{intl.formatMessage({id: 'GENERAL.DOB'})}:</div>
                <div className='text-gray-600 fs-5'>{dob}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.PERMANENT.ADDRESS'})}:
                </div>
                <div className='text-gray-600 fs-5'>{doctorData?.permanent_address}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'GENERAL.CONTACT.NUMBER'})}:
                </div>
                <div className='text-gray-600 fs-5'>{doctorData?.contact_number}</div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'DOCTOR.VISITING.DOCTOR'})}:
                </div>
                <div className='text-gray-600 fs-5'>
                  {doctorData?.visiting_doctor === false ? 'No' : 'Yes'}
                </div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'DOCTOR.QUALIFICATION'})}:
                </div>
                <div className='text-gray-600 fs-5'>{doctorData?.qualification}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'DOCTOR.YEARS.OF.EXPERIENCE'})}:
                </div>
                <div className='text-gray-600 fs-5'>{doctorData?.years_of_experience}</div>
                <div className='fw-bold fs-4 mt-5'>
                  {intl.formatMessage({id: 'STAFF.SPECIALITY'})}:
                </div>
                {/* <div className='text-gray-600 fs-5'>{doctorData?.speciality?.name}</div> */}
                <div className='text-gray-600 fs-5'>
                  {Array.isArray(doctorData?.speciality) &&
                    (doctorData?.speciality as Array<{id: number; name: string}>).map(
                      (specialty, index) => (
                        <span key={specialty.id}>
                          {specialty.name}
                          {index !==
                          (doctorData?.speciality as Array<{id: number; name: string}>).length - 1
                            ? ' , '
                            : ''}
                        </span>
                      )
                    )}
                </div>
                <div className='fw-bold fs-4 mt-5'>{intl.formatMessage({id: 'GENERAL.ROLE'})}:</div>
                <div className='text-gray-600 fs-5'>{doctorData?.user?.role?.name}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DoctorView
