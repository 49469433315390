import {AuditLogsListSearchComponent} from './AuditLogsListSearchComponent'

const AuditLogsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <AuditLogsListSearchComponent />
    </div>
  )
}

export {AuditLogsListHeader}
