/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC} from 'react'
import {Appointment} from '../../core/_models'

type Props = {
  user: Appointment
}

const AppointmentDate: FC<Props> = ({user}) => {
  const appointmentDate = moment(user?.appointment_details?.[0]?.appointment_date).format(
    'DD-MM-YYYY'
  )
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column text-gray-800  mb-1'>{appointmentDate}</div>
    </div>
  )
}

export {AppointmentDate}
